/*eslint-disable eqeqeq*/
import React from "react";
import VerifyOtp from "../../login/component/verify-otp";
import Modal from "../../../shared/components/modal/modal";
import PageTitle from "../../../config/pageTitle";
import * as AppConstants from "../../../config/AppConstant";
import Slider from "../../../shared/components/slider/slick-sliderHome";
import * as APIController from '../../../api/APIController.js';
import close_icon from "../../../assets/images/gray-close-ic.svg";
import MyiFrame from "../../../features/campaign/components/myiFrame";
import "./dashboard.css";

const Dashboard = (props) => {

	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [SliderImages, setSliderImages] = React.useState([]);
	const [Loading, setLoading] = React.useState(true);
	const handleCloseModal = () => {
		setIsModalOpen(false);
		AppConstants.SetLocalStorage("PopupWelcomeShow", 0);
	};

	React.useEffect(() => {
		wsGetScreenBannerForDropdown()
		if (AppConstants.GetLocalStorage("PopupWelcomeShow") == 1 && localStorage.getItem("headerMenuAdmin") != 1) {
			setIsModalOpen(true)
		}
	}, [props])

	const wsGetScreenBannerForDropdown = () => {
		APIController.GetScreenBannerForDropdown("2")
			.then((response) => {
				if (response.object.message == "") {
					setSliderImages(response.object.data)
					setLoading(false)
				}
			})
	}

	return (
		<>
			<section className='content-right-main'>
				<PageTitle Title={AppConstants.ContactDetais.HomePageTitle} />
				<div className='two-col-sec'>
					<div className='sec-left'>
						<p align="justify" style={{ lineHeight: 1.4, fontWeight: 900, color: '#4b4c4c' }} >Marketing Resources to Engage with Patients and Help Grow Your Practice.</p>
						<p align="justify" style={{ lineHeight: 1.4 }}>The REACH Portal contains an extensive array of valuable marketing tools designed to	promote the family of Bausch + Lomb contact lenses available at your practice and help attract new patients. These include:</p>
						<ul style={{ margin: '20px 0px 20px 20px' }}>
							<li style={{ padding: '5px 0px 5px 15px', lineHeight: 1.4 }}><strong>Social Media Automation</strong> to keep your Facebook & Instagram business pages refreshed with new engaging content.</li>
							<li style={{ padding: '5px 0px 5px 15px', lineHeight: 1.4 }}><strong>Free Bausch + Lomb Assets</strong> featuring Social Posts, Videos, Website Banners, Print Materials.</li>
							<li style={{ padding: '5px 0px 5px 15px', lineHeight: 1.4 }}><strong>Email Marketing</strong> to keep your practice top-of-mind with patients.</li>
							<li style={{ padding: '5px 0px 5px 15px', lineHeight: 1.4 }}><strong>Direct Mail</strong> for sending Biotrue, INFUSE and Bausch + Lomb ULTRA postcards to your practice's mailing list.</li>
							<li style={{ padding: '5px 0px 5px 15px', lineHeight: 1.4 }}><strong>Digital Campaigns</strong> to locally promote your practice on Facebook, Instagram and Google.</li>
						</ul>
						<p align="justify" style={{ lineHeight: 1.4 }} ><strong><em>Click on the left menu to get started!</em></strong></p>
					</div>
					<div className='sec-right image-slider' style={{ display: 'relative' }}>
						{!Loading && <Slider>
							{SliderImages.length && SliderImages.map((object, index) => {
								return (<div className='image-slider'>
									<img src={object.ImageURL} alt={object.ImageURL} />
								</div>)
							})}
						</Slider>}
					</div>
				</div>
			</section>

			{isModalOpen && (
				<Modal show={isModalOpen} handleClose={handleCloseModal} >

					<div className="popup-wrap" style={{ paddingBottom: '0px', display: 'grid' }}>
						<div>
							<div className='box-head' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px' }}>
								<h4>{AppConstants.GetLocalStorage("PopupTitle")}</h4>
							</div>
							<a title="" className="gray-close-ic">
								<img src={close_icon} alt="icon" onClick={handleCloseModal} />
							</a>
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
							<div className="sec-left" style={{ marginTop: '0px' }} >
								<div style={{ letterSpacing: '0px' }}>
									<div dangerouslySetInnerHTML={{ __html: AppConstants.GetLocalStorage("PopupDescription") }} />
								</div>
							</div>
							<div className="sec-right" style={{ padding: '0px', marginBottom: '10px' }}>
								{AppConstants.GetLocalStorage("PopupAssetURL").indexOf('.mp4') != -1
									? !navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')
										? <video controls width="250" autoPlay >
											<source src={AppConstants.GetLocalStorage("PopupAssetURL")} type="video/mp4" />
										</video>
										: <MyiFrame Height={'450px'} Width={'500px'} src={AppConstants.GetLocalStorage("PopupAssetURL")} />
									: <img src={AppConstants.GetLocalStorage("PopupAssetURL")} alt="" />}
							</div>

						</div>
					</div>

				</Modal>
			)}
		</>
	);
};

export default Dashboard;
