import React from "react";

import PageTitle from "../config/pageTitle";

import Loginlogo from "../assets/images/main.png";
import Logo from "../assets/images/logo.svg";
import VSPPremier from "../assets/images/vsp_premier_logo.png";
import VisionSource from "../assets/images/vision_source_logo.png";

import Loginlogoyomi from "../assets/images/logoyomi.png";
import LogoYomi from "../assets/images/logo1yomi.png";

import slider_img1 from "../assets/images/slider-img1.jpg";
import slider_img2 from "../assets/images/slider-img2.jpg";
import slider_img3 from "../assets/images/slider-img3.jpg";
import slider_img4 from "../assets/images/slider-img4.jpg";
import slider_img5 from "../assets/images/slider-img5.jpg";

import home_img1 from "../assets/images/welcomeimage1.jpg";
import home_img2 from "../assets/images/welcomeimage2.png";
import home_img3 from "../assets/images/welcomeimage3.jpg";

import slider_imgymoi1 from "../assets/images/slider-imgyomi1.png";
import slider_imgyomi2 from "../assets/images/slider-imgyomi2.png";

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

import moment from 'moment';

const firebaseConfigCobrand = {
    apiKey: "AIzaSyC2t9CdE-1AnmULws5AduWetVuJCHNSROw",
    authDomain: "cobrandportal.firebaseapp.com",
    databaseURL: "https://cobrandportal.firebaseio.com",
    projectId: "cobrandportal",
    storageBucket: "cobrandportal.appspot.com",
    messagingSenderId: "950530478013",
    appId: "1:950530478013:web:0e19032ec2ea34d5ad4cd4",
    measurementId: "G-N5JPRL2RF1"
};
const firebaseConfigYomi = {
    apiKey: "AIzaSyA-olU2FDw_uqQOIh5LtLXitBbUZPIMKVU",
    authDomain: "yomi-connect.firebaseapp.com",
    projectId: "yomi-connect",
    storageBucket: "yomi-connect.appspot.com",
    messagingSenderId: "70035989202",
    appId: "1:70035989202:web:2738483bef74a29c44c30b",
    measurementId: "G-MGC375956E"
};

export const RoleID = "a560e1ff-e7c9-47b0-af6c-642b28d9548a"
export const VTMRoleID = "5c203c1a-2ae9-4211-b49e-347c73fdae51"
export const IsGoForLive = process.env.REACT_APP_IS_GO_FOR_LIVE;

// client_id:"1049074015636713", // Test
// client_id:"461840625404091",
// client_id: '307760978095007', // Test
// scope: 'public_profile,pages_show_list,pages_manage_posts,instagram_basic,instagram_content_publish'
// client_id: "584550876158294", // Live
export const facebookConfig = {
    client_id: "741594151354721", // Live
    fields: 'email,picture',
    fbscope: 'public_profile,pages_show_list,pages_manage_posts,business_management',
    instascope: 'public_profile,instagram_basic,instagram_content_publish'
}

export const initializeAnalytics = () => {
    const app = GetMainClassName() == "app-main" ? initializeApp(firebaseConfigCobrand) : initializeApp(firebaseConfigYomi);
}
export const LogEvent = (EventName, Params) => {
    const app = GetMainClassName() == "app-main" ? initializeApp(firebaseConfigCobrand) : initializeApp(firebaseConfigYomi);
    const analytics = getAnalytics();
    logEvent(analytics, EventName, Params);
}

export const CreateLogEventObject = (Practice, Brand, Campaign, Product, Location, Theme, Category, Subcategory, FreeAsset, EmailMarketing, SocialAutomation, Amount) => {
    return {
        'practice': Practice,
        'brand': Brand,
        'campaign': Campaign,
        'product': Product,
        'location': Location,
        'theme': Theme,
        'category': Category,
        'subcategory': Subcategory,
        'freeasset': FreeAsset,
        'emailmarketing': EmailMarketing,
        'socialautomation': SocialAutomation,
        'amount': Amount,
    }
}

export const GetMainClassName = () => {
    return 'app-main'
    // if (window.location.origin.indexOf("cobrand.portal.thestaging.cc") > 0 || window.location.origin.indexOf("bauschreach.com") > 0 || window.location.origin.indexOf("mybauschreach.com") > 0 || window.location.origin == "http://localhost:3001" || window.location.origin == "http://192.168.1.8:3001" || window.location.origin == "http://192.168.1.16:3001" || window.location.origin == "http://192.168.1.15:3001") {
    // }
    // else if (window.location.origin.indexOf("yomi.portal.thestaging.cc") > 0 || window.location.origin == "http://localhost:3000" || window.location.origin == "http://192.168.1.8:3000") {
    //     return 'app-yomi'
    // }
}

export const SetLocalStorage = (Key, Value) => {
    localStorage.setItem(Key, Value)
}

export const GetLocalStorage = (Key) => {
    return localStorage.getItem(Key) || '';
}

export const GetPageTitle = () => {
    var PageTitle = GetPageTitleFromURL(GetMainClassName().replace('app-', ''), window.location.pathname);
    if (PageTitle.indexOf('#') != -1) {
        PageTitle = PageTitle.replace('#CampaignName#', GetLocalStorage('localStorageCampaignName'));
        PageTitle = PageTitle.replace('#ProductName#', GetLocalStorage('localStorageProductName'));
        PageTitle = PageTitle.replace('#BrandName#', GetLocalStorage('localStorageBrandName'));
    }
    return PageTitle;
}

export const GetPageTitleFromURL = (TenantName, PageURL) => {
    if (TenantName == 'main') {
        switch (PageURL) {
            case '/digital-product/selection':
                return '#BrandName# #CampaignName#';
            case '/digital-product/selection/digital-bundle':
                return '#CampaignName# #ProductName#';
            case '/digital-product/selection/campaign':
                return 'Step 1: Select your Campaign Theme';
            default:
                break;
        }
    } else if (TenantName == 'yomi') {
        switch (PageURL) {
            case '/digital-product/selection':
                return 'Select a Product to Get Started:';
            case '/digital-product/selection/campaign':
                return 'Step 1: Select your Campaign Theme';
            default:
                break;
        }
    }
}

export const ShowPageTitle = () => {
    return (<PageTitle Title={GetPageTitle()} />)
}

export const LoginLogo = GetMainClassName() == "app-main" ? window.location.toString().split('/').pop() == "vsp" ? VSPPremier : window.location.toString().split('/').pop() == "vs" ? VisionSource : Loginlogo : LogoYomi;
export const SetPasswordLogo = GetMainClassName() == "app-main" ? window.location.toString().split('?')[0].split('/').pop() == "vsp" ? VSPPremier : window.location.toString().split('?')[0].split('/').pop() == "vs" ? VisionSource : Loginlogo : LogoYomi;
export const HeaderLogo = GetMainClassName() == "app-main" ? Logo : LogoYomi;
export const VSPPremierLogo = GetMainClassName() == "app-main" ? Loginlogo : Loginlogoyomi;
export const HomepageURL = GetMainClassName() == "app-main" ? "/home" : "/digital-product/selection";

export const BLLoginSlider = { HeadingText: 'WELCOME TO BAUSCH + LOMB REACH', PtagText: "A practice marketing platform with tools to help you reach patients through innovative technology" }

// export const BLHomeSlider = { Images: [{ ImageUrl: home_img1 }, { ImageUrl: home_img2 }, { ImageUrl: home_img3 }] }

export const AnalyticsDashboardTab =
    [{ TabName: 'Cost Calculator', PageURL: '/analytics/supply-calculator-leads', IsSelect: false, activeTab: 0 },
    { TabName: 'Organic Traffic', PageURL: '/analytics/analyticsDashboard', IsSelect: true, activeTab: 1 },
    { TabName: 'Paid Campaigns', PageURL: '/analytics/analyticsDashboard', IsSelect: true, activeTab: 2 },
    { TabName: 'Social Media', PageURL: '/analytics/analyticsDashboard', IsSelect: false, activeTab: 3 }]

export const ContactDetais = {
    ViewCountries: 'View Countries',
    AnnualSupply: 'Annual Supply Calculator',
    HomePageTitle: 'Welcome to Bausch + Lomb Reach',
    Step1Select: 'Step 1: Select your Campaign Theme',
    Step1Choose: 'Step 1: Choose Your Postcard',
    Step2Select: 'Step 2: Select your Budget and Campaign Duration',
    Step2Confirm: 'Step 2: Select Location for Your Campaign',
    Step2Confirms: 'Step 2: Select Location(s) for Your Campaign',
    Step2Upload: 'Step 4: Upload Your Mailing List',
    Step3Select: 'Step 3: Select Location(s) for Your Campaign',
    Step3Confirm: 'Step 3: Confirm Your Practice Info for Your Postcard',
    MyLocationTitle: 'My Locations',
    SelectLocationTitle: 'Select Your Main Location',
    SocialSelectLocationTitle: 'Social Automation: Select Your Main Location',
    ConfirmLocationTitle: 'Update Subscription: Social Automation',
    CartLocationTitle: 'Select Location(s) for Your Campaign',
    Step3Artwork: 'Step 3: Approve Your Campaign Artwork',
    Step4Artwork: 'Step 4: Approve Your Campaign Artwork',
    ViewNotifications: 'View Notifications',
    YourCart: 'Your Cart',
    YourOrders: 'Your Orders',
    ReportData: 'Report Data',
    OrderDetails: 'Order Details - #',
    NoOrderFound: 'No Order Found',
    HowToReachUs: 'How To Reach Us',
    PrivacyPolicyTitle: 'COBRAND MEDIA ONLINE TERMS AND CONDITIONS',
    CalendarHeading: 'Social Media Calendar',
    AssetDownloadReport: 'Asset Download Report',
    FreeAssetsReport: 'Free Assets Report',
    VTMContactList: 'VTM Contact List',
    ContestSummaryReport: 'Contest Summary Report',
    RegistrationsReport: 'Registrations Report',
    SocialAutomationReport: 'Social Automation Report',
    CampaignOrdersReport: 'Campaign Orders Report',
    EmailMarketingReport: 'Email Marketing Report',
    LoginReport: 'Login Report',
    ThankYouOrder: 'Thank you for your order!',
    ThankYouMessage: 'Thank You. Your Subscription has been Activated.',
    MonthlyBudget: 'Monthly Budget',
    UpdateSubscription: 'Update Subscription: Social Automation',
    CustomPostPageTitle: 'Custom Post Feeds', // Social Automation Admin Feed
    NotCurrentlyAvailable: 'Not Currently Available',
    ViewMasterTemplate: 'View Master Templates',
    ViewLandingPageUser: 'View Landing Page Users',
    NegativeKeyword: 'Keywords Checked'
}

export const CommanText = GetMainClassName() == "app-main" ? {
    ContactUsLine1: "Kindly contact your Bausch + Lomb sales representative for more information about the marketing services available to your practice.",
    ContactUsTerritory: "Vision Care Territory Manager",
    ContactUsLine2: "For questions regarding access and use of the Bausch + Lomb REACH portal, including technical support, please contact: ",
    ContactUsLine3: "Customer Support ",
    ContactUsEmail1: "support@bauschreach.com"
} : {
    ContactUsLine1: "Kindly contact your Yomi Clinical Sales Representative for more information about the clinical services available to your practice.",
    ContactUsTerritory: "Clinical Sales Representative",
    ContactUsLine2: "For questions regarding access and use of the Yomi Connect portal, please contact: ",
    ContactUsLine3: "For Yomi Connect portal technical support, please contact: ",
    ContactUsEmail1: "yomi_portal@neocis.com",
    ContactUsEmail2: "support@yomiconnect.com",
}

export const FooterText = GetMainClassName() == "app-main" ? {
    TextMain1: "Copyright © " + new Date().getFullYear(),
    TextMain2: ". All Rights Reserved. VCCM.0030.USA.22",
    FooterEmailText: " Bausch + Lomb",
    LinkText1: "Terms of Use",
    LinkText2: "Privacy Policy",
    LinkText3: "Contact Us",
    CobrandMediaWebsite: "https://cobrandmedia.com"
} : {
    TextMain1: "Copyright © " + new Date().getFullYear(),
    TextMain2: ". All Rights Reserved.",
    FooterEmailText: " Neocis",
    LinkText1: "Terms of Use",
    LinkText2: "Privacy Policy",
    LinkText3: "Contact Us",
    CobrandMediaWebsite: "https://www.neocis.com/"
}

export const ButtonText = {
    GetStarted: "Get Started",
    Back: 'Back',
    Exit: 'Exit',
    Order: 'Order',
    Customize: 'Customize',
    Select: 'Select',
    Preview: 'Preview',
    Continue: 'Continue',
    Cancel: 'Cancel',
    Delete: 'Delete',
    Upload: 'Upload',
    ConfirmUpdates: 'Confirm Updates',
    AddToCart: 'Add To Cart',
    // AddToCart: 'Continue',
    AddingToCart: 'Adding to Cart...',
    // AddingToCart: 'Continuing...',
    BackToCart: 'Back To Cart',
    UpdateCart: 'Update Cart',
    UpdatingCart: 'Updating Cart...',
    UpdateSubscription: 'Update Subscription',
    UpdatingSubscription: 'Updating Subscription...',
    CopyHTML: 'Copy HTML',
    Done: 'Done',
    CopytoClipboard: 'Copy to Clipboard',
    Download: 'Download',
    Downloading: 'Downloading ...',
    CopyText: 'Copy Text',
    DownloadPostcard: 'Download Postcard',
    SendToPrinter: 'Send To Printer',
    ViewPost: 'View Post',
    AddCalendar: '+ Calendar',
    AddPost: 'Add Post',
    EditPost: 'Edit Post',
    Reschedule: 'Reschedule',
    Edit: 'Edit',
    ReschedulePost: 'Reschedule Post',
    EmailResults: 'Email Results',
    Reset: 'Reset',
}

export const PageHeading = GetMainClassName() == "app-main" ? { HeadingText: "" } : { HeadingText: "" }

export const GetCurrentDate = moment(new Date().toUTCString()).utcOffset(0, false).format('YYYY-MM-DD')

export const graphColors = ["#1588ba", "#02ABAE", "#486e6e", "#0000ff", "#0a5c7f", "#008080",
    "#ec9455", "#c377e4", "#6EC496", "#1073C6", "#5a6e7f", "#646464", "#669999"];
export const graphColorsRGB = ["rgb(21, 136, 186,0.5)", "rgb(2, 171, 174,0.5)", "rgb(72, 110, 110,0.5)", "rgb(0, 0, 255,0.5)", "rgb(10, 92, 127,0.5)", "rgb(0, 128, 128,0.5)",
    "rgb(236, 148, 85,0.5)", "rgb(195, 119, 228,0.5)", "rgb(110, 196, 150, 0.5)", "rgb(16, 115, 198, 0.5)", "rgb(90, 110, 127, 0.5)", "rgb(100, 100, 100, 0.5)", "rgb(102, 153, 153,0.5)"];
export const monthsName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const TermsConditions = GetMainClassName() == "app-main"
    ? {
        TermsConditionsText: <nowrap>
            <div >
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#0000ff' }}>
                    A. COBRAND MEDIA TERMS OF SALE</span>
            </div>
            <div >
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#0000ff' }}>
                    B. COBRAND MEDIA CONDITIONS OF USE</span>
            </div>
            <div>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#0000ff' }}>
                    C. COBRAND MEDIA PRIVACY POLICY</span>
            </div>
            <div style={{ marginTop: "15px" }}>
                <span style={{ fontWeight: 'bold', fontSize: '13px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    THE LEGAL AGREEMENTS SET OUT BELOW GOVERNS YOUR USE OF COBRAND MEDIA SERVICES. IF YOU DO NOT
                    AGREE TO THESE TERMS, DO NOT USE THE SERVICES. REFERENCE TO COBRANDMEDIA.COM HEREIN, IS USED AND
                    REFERS TO ALL DOMAINS, PUBLIC AND PRIVATE, CUSTOM AND GENERIC, OPERATED BY COBRAND MEDIA, THAT
                    USE THESE TERMS AND CONDITIONS AS SHOWN ON THEIR RESPECTIVE WEBSITES.</span>
            </div>
            <div style={{ marginTop: "20px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#0000ff' }}>
                    A. COBRAND MEDIA TERMS OF SALE</span>
            </div>
            <div>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    PAYMENTS, TAXES, AND REFUND POLICY</span>
            </div>
            <div style={{ marginTop: "15px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6 }}>
                    CoBrand Media accepts these forms of payment: certain credit cards issued by U.S. banks, corporate Co-op credits, certain bank
                    account transfer payments, and Allowance Account balances as approved by, and paid for by the corporate sponsor. If a credit card
                    is being used for a transaction, CoBrand Media may obtain pre-approval for an amount up to the amount of the order. Billing occurs
                    at the time of or shortly after your transaction. If an Allowance Account is used for a transaction, the amount is deducted at the time
                    of your transaction. When making purchases, Allowance Account credits are used first. Your credit card is then charged for any remaining balance.</span>
            </div>
            <div style={{ marginTop: "15px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    You agree that you will pay for all products you purchase through CoBrand Media, and that CoBrand Media may charge your credit
                    card for any products purchased and for any additional amounts (including any taxes and late fees, as applicable) that may be
                    accrued by or in connection with your Account. YOU ARE RESPONSIBLE FOR THE TIMELY PAYMENT OF ALL FEES AND FOR
                    PROVIDING COBRAND MEDIA WITH VALID CREDIT CARD DETAILS FOR PAYMENT OF ALL FEES. All fees will be billed to the
                    credit card you designate during the registration process. If you want to designate a different credit card or if there is a change in
                    your credit card status, you must change your information online; this may temporarily disrupt your access to our services while
                    CoBrand Media verifies your new payment information.</span>
            </div>
            <div style={{ marginTop: "15px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    Your total price will include the price of the advertising plus any applicable local or state taxes in effect where your advertising is
                    published. We will charge tax only in states or localities where advertising is taxable.</span>
            </div>
            <div style={{ marginTop: "15px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    All advertising sales are final. With the exception of errors or omissions by CoBrand Media or their service providers, any refund,
                    credit, or adjustment will be made at the sole discretion of CoBrand Media. CoBrand Media seeks to be fair and honest in all
                    business endeavors. If a dispute arises between an advertiser and CoBrand Media, please contact us at
                    CustomerService@CoBrandMedia.com immediately.</span>
            </div>
            <div style={{ marginTop: "20px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    COBRAND MEDIA LIABILITY</span>
            </div>
            <div style={{ marginTop: "15px" }}>
                <span style={{ fontWeight: 'bold', fontSize: '13px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    Liability for any error or omission shall be limited to the actual advertising charges for the item of advertising ordered.
                    COBRAND MEDIA WILL NOT BE LIABLE FOR ANY LOST PROFITS, COSTS OF PROCURING SUBSTITUTE GOODS OR
                    SERVICES, OR BUSINESS INTERRUPTION, OR FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR
                    SPECIAL DAMAGES ARISING OUT OF OR RELATED TO THIS AGREEMENT OR COBRAND MEDIA SERVICES,
                    REGARDLESS OF THE CAUSE OF ACTION (INCLUDING TORT, CONTRACT, NEGLIGENCE, STRICT LIABILITY AND
                    BREACH OF WARRANTY) EVEN IF COBRAND MEDIA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN
                    NO EVENT WILL COBRAND MEDIA’S TOTAL CUMULATIVE LIABILITY IN CONNECTION WITH THIS AGREEMENT OR
                    COBRAND MEDIA SERVICES, FROM ALL CAUSES OF ACTION OF ANY KIND, INCLUDING BUT NOT LIMITED TO TORT,
                    CONTRACT, NEGLIGENCE, STRICT LIABILITY AND BREACH OF WARRANTY, EXCEED THE TOTAL COST PAID FOR THE
                    SERVICES OR PRODUCTS.</span>
            </div>
            <div style={{ marginTop: "20px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#0000ff' }}>
                    B. COBRAND MEDIA CONDITIONS OF USE</span>
            </div>
            <div style={{ marginTop: "15px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    CoBrand Media provides website features to you subject to the following conditions. If you visit or place advertising orders at
                    CoBrandMedia.com, you accept these conditions. Please read them carefully. In addition, when you use any current or future
                    CoBrand Media service you also will be subject to the guidelines, terms and agreements ("Terms") applicable to such service or
                    business. If these conditions are inconsistent with such Terms, the Terms will control.</span>
            </div>
            <div style={{ marginTop: "15px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    PRIVACY</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    Please review our Privacy Policy, which governs your visit to CoBrandMedia.com, to understand our practices.</span>
            </div>
            <div style={{ marginTop: "20px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    ELECTRONIC COMMUNICATIONS</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    When you visit CoBrandMedia.com or send e-mails to us, you are communicating with us electronically. You consent to receive
                    communications from us electronically. We will communicate with you by e-mail or by posting notices on this site. You agree that all
                    agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that
                    such communications be in writing.</span>
            </div>
            <div style={{ marginTop: "20px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    COPYRIGHT</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    All content included on this site, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data
                    compilations, and software, is the property of CoBrand Media or its content suppliers and protected by United States and
                    international copyright laws. The compilation of all content on this site is the exclusive property of CoBrand Media and protected by
                    U.S. and international copyright laws. All software used on this site is the property of CoBrand Media or its software suppliers and
                    protected by United States and international copyright laws.</span>
            </div>
            <div style={{ marginTop: "20px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    TRADEMARKS</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    CoBrandMedia.com graphics, logos, page headers, button icons, scripts, and service names are trademarks, or trade dress of
                    CoBrand Media in the U.S. and/or other countries. CoBrand Media's trademarks and trade dress may not be used in connection
                    with any product or service that is not CoBrand Media's, in any manner that is likely to cause confusion among customers, or in any
                    manner that disparages or discredits CoBrand Media. All other trademarks not owned by CoBrand Media that appear on this site are
                    the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by CoBrand Media.</span>
            </div>
            <div style={{ marginTop: "20px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    PATENTS</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    One or more patents owned by CoBrand Media may apply to this site and to the features and services accessible via the site. </span>
            </div>
            <div>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    Portions of this site may operate under license of one or more patents.</span>
            </div>
            <div style={{ marginTop: "20px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    LICENSE AND SITE ACCESS</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    CoBrand Media grants you a limited license to access and make personal use of this site and not to download (other than page
                    caching) or modify it, or any portion of it, except with express written consent of CoBrand Media. This license does not include any
                    resale or commercial use of this site or its contents; any collection and use of any product listings, descriptions, or prices; any
                    derivative use of this site or its contents; any downloading or copying of account information for the benefit of another agency,
                    merchant, or service provider; or any use of data mining, robots, or similar data gathering and extraction tools. This site or any
                    portion of this site may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial
                    purpose without express written consent of CoBrand Media. You may not frame or utilize framing techniques to enclose any
                    trademark, logo, or other proprietary information (including images, text, page layout, or form) of CoBrand Media without express
                    written consent. You may not use any Meta tags or any other "hidden text" utilizing CoBrand Media's name or trademarks without
                    the express written consent of CoBrand Media. Any unauthorized use terminates the permission or license granted by CoBrand
                    Media. You are granted a limited, revocable, and nonexclusive right to create a hyperlink to the home page of CoBrandMedia.com
                    so long as the link does not portray CoBrand Media, or its products or services in a false, misleading, derogatory, or otherwise
                    offensive matter. You may not use any CoBrand Media logo or other proprietary graphic or trademark as part of the link without
                    express written permission.</span>
            </div>
            <div style={{ marginTop: "20px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    YOUR ACCOUNT</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    If you use this site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to
                    your computer, and you agree to accept responsibility for all activities that occur under your account or password. CoBrand Media
                    reserves the right to refuse service, terminate accounts, remove or edit content, or cancel orders in their sole discretion.</span>
            </div>
            <div style={{ marginTop: "20px" }}>
                <span style={{ fontSize: '13px', letterSpacing: '0.3px', lineHeight: 1.6, fontWeight: 'bold', color: '#000000' }}>
                    DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY
                    THIS SITE AND ALL INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) AND SERVICES
                    INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THIS SITE ARE PROVIDED BY COBRAND MEDIA ON
                    AN "AS IS" AND "AS AVAILABLE" BASIS, UNLESS OTHERWISE SPECIFIED IN WRITING. COBRAND MEDIA MAKES NO
                    REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THIS SITE OR
                    THE INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR SERVICES INCLUDED ON OR
                    OTHERWISE MADE AVAILABLE TO YOU THROUGH THIS SITE, UNLESS OTHERWISE SPECIFIED IN WRITING. YOU
                    EXPRESSLY AGREE THAT YOUR USE OF THIS SITE IS AT YOUR SOLE RISK.</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, fontWeight: 'bold', color: '#000000' }}>
                    TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, COBRAND MEDIA DISCLAIMS ALL WARRANTIES, EXPRESS
                    OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF ADVERTISING EFFECTIVENESS. COBRAND
                    MEDIA DOES NOT WARRANT THAT THIS SITE; INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING
                    SOFTWARE) OR SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THIS SITE; THEIR
                    SERVERS; OR ELECTRONIC COMMUNICATIONS SENT FROM COBRAND MEDIA ARE FREE OF VIRUSES OR OTHER
                    HARMFUL COMPONENTS. COBRAND MEDIA WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE
                    USE OF THIS SITE OR FROM ANY INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR
                    SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THIS SITE, INCLUDING, BUT NOT
                    LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE
                    SPECIFIED IN WRITING.</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, fontWeight: 'bold', color: '#000000' }}>
                    CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF
                    CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR
                    LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.</span>
            </div>
            <div style={{ marginTop: "20px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    DISPUTES</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    In the event of a dispute or non-payment arising out of the performance or interpretation of this Agreement, the parties agree that
                    any action to enforce this Agreement shall be by binding arbitration in the County of San Diego, California, unless mutually agreed
                    otherwise. The initiating party shall choose the arbitration firm to be used, and that firm must be accredited to conduct binding
                    arbitration in the State of California. The decision of this arbitrator shall be binding as a decision rendered by the Superior Court. </span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    Each party shall be responsible for its share of the arbitration fees. In the event a party fails to proceed with arbitration,
                    unsuccessfully challenges the arbitrator's award, or fails to comply with the arbitrator's award, the other party is entitled to costs of
                    suit, including a reasonable attorney's fee for having to compel arbitration or defend or enforce the award. The parties further agree
                    that the arbitrator and/or any subsequent court of competent jurisdiction shall award to the prevailing party and against the losing
                    party, reasonable attorney's fees and costs, including, but not limited to, collection fees and charges, investigation fees, collection
                    management fees, court expenses, and arbitration costs.</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    Any dispute or claim relating in any way to your visit to CoBrandMedia.com or to products or advertising services sold by CoBrand
                    Media or through CoBrandMedia.com will be resolved by binding arbitration, rather than in court, in the County of San Diego,
                    California, except that either party may assert claims in Small Claims Court in San Diego County if those claims qualify. The
                    decision of an arbitrator shall be binding as a decision rendered by the Superior Court. The parties further agree that the arbitrator
                    and/or any subsequent court of competent jurisdiction shall award to the prevailing party and against the losing party, reasonable
                    attorney's fees and costs, including, but not limited to, collection fees and charges, investigation fees, collection management fees,
                    court expenses, and arbitration costs.</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    We also mutually agree that you or we may bring suit in court to enjoin infringement or other misuse of intellectual property rights.</span>
            </div>
            <div style={{ marginTop: "20px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    APPLICABLE LAW</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    By visiting CoBrandMedia.com or one of its custom corporate branded websites, you agree that applicable federal law and the laws
                    of the State of California, without regard to principles of conflict of laws, will govern these Conditions of Use and any dispute of any
                    sort that might arise between you and CoBrand Media.</span>
            </div>
            <div style={{ marginTop: "20px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    SITE POLICIES, MODIFICATION, AND SEVERABILITY</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    Please review our other policies posted on this site. These policies also govern your visit to CoBrandMedia.com. We reserve the
                    right to make changes to our site, policies, and these Conditions of Use at any time. If any of these conditions shall be deemed
                    invalid, void, or for any reason unenforceable, that condition shall be deemed severable and shall not affect the validity and
                    enforceability of any remaining condition.</span>
            </div>
            <div style={{ marginTop: "20px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    CHANGES TO OUR CONDITIONS OF USE POLICY</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    If we decide to change our Conditions of Use policy, we will update the modification date below.</span>
            </div>
            <div >
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    This policy was last modified on December 3, 2018</span>
            </div>
            <div style={{ marginTop: "20px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    YOUR CONSENT</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    By using our site, you consent to our Conditions of Use policy.</span>
            </div>
            <div style={{ marginTop: "20px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    CONTACTING US</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    If there are any questions regarding this Conditions of Use policy you may contact us using the information below.</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span id="_9.8" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    CoBrand Media <span id="_11.8" style={{ fontFamily: 'Raleway', fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}></span> AB Advertising <span id="_11.8" style={{ fontFamily: 'Raleway', fontSize: '11.8px' }}></span> Avenida Encinas, Suite 100 <span id="_11.8" style={{ fontFamily: 'Raleway', fontSize: '11.8px' }}>
                    </span> California 92008 </span>

            </div>
            <div style={{ marginTop: "20px" }}>
                <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#0000ff' }}>
                    C. COBRAND MEDIA PRIVACY POLICY</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    What information do we collect?</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    We receive information on you from your corporate sponsor and collect information from you when you sign onto our site, place an
                    order, modify your information, send us an email, respond to a survey, or fill out a form.
                    When ordering on our site, as appropriate, you may be asked to enter additional banking or credit care information, etc.</span>
            </div>
            <div style={{ marginTop: "10px" }}>
                <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    What do we use your information for?</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    Any of the information we collect from you may be used in one of the following ways: to personalize your experience (your
                    information helps us to better respond to your individual needs); to improve our website (we continually strive to improve our website
                    offerings based on the information and feedback we receive from you); to improve customer service (your information helps us to
                    more effectively respond to your customer service requests and support needs); to process transactions (your information, whether
                    public or private, will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your
                    consent, other than for the express purpose of delivering the purchased advertising requested.); to send periodic emails.</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    <b>Note:</b> Your corporate sponsor has provided us with your contact and other information so that we may assist you by providing low
                    cost promotional benefits available only through CoBrand Media. However, if at any time you would like to be removed from that
                    corporate provided advertiser database, and consequently from receiving future notifications, you may send your request to
                    CustomerService@CoBrandMedia.com.</span>
            </div>
            <div style={{ marginTop: "10px" }}>
                <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    How do we protect your information?</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter,
                    submit, or access your personal information.</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL)
                    technology and then encrypted into our Payment gateway providers database only to be accessible by those authorized with special
                    access rights to such systems, and are required to keep the information confidential.</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    After a transaction, your private information (credit cards, social security numbers, financials, etc.) will not be stored on our servers.</span>
            </div>
            <div style={{ marginTop: "10px" }}>
                <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    Do we use cookies?</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    We use 3 cookies: to store the user login, user name, and last login.</span>
            </div>
            <div style={{ marginTop: "10px" }}>
                <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    Do we disclose any information to outside parties?</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted
                    third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to
                    keep this information confidential. We may also release your information when we believe release is appropriate to comply with the
                    law, enforce our site policies, or protect ours or others’ rights, property, or safety. Your information as approved by you in each
                    order, will be provided to the service providers with whom you chose to advertise. That information will then be in the public domain.</span>
            </div>
            <div style={{ marginTop: "10px" }}>
                <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    Third party links</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    We may offer third party products or services on our website. These third party sites have separate and independent privacy
                    policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to
                    protect the integrity of our site and welcome any feedback about these sites.</span>
            </div>
            <div style={{ marginTop: "10px" }}>
                <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    California Online Privacy Protection Act Compliance</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    Because we value your privacy we have taken the necessary precautions to be in compliance with the California Online Privacy
                    Protection Act. We therefore will not distribute your personal information to outside parties without your consent.</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    As part of the California Online Privacy Protection Act, all users of our site may make any changes to their information at anytime by
                    logging into CoBrandMedia.com, and making those changes their personal profile page.</span>
            </div>
            <div style={{ marginTop: "10px" }}>
                <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    Children’s Online Privacy Protection Act Compliance</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    We are in compliance with the requirements of COPPA (Children’s Online Privacy Protection Act); we do not collect any information
                    from anyone under 13 years of age. Our website, products and services are all directed to people who are at least 13 years old or
                    older.</span>
            </div>
            <div style={{ marginTop: "10px" }}>
                <span id="_11.1" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    Your Consent</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span id="_11.1" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                    By using our site, you consent to our Privacy Policy
                    Changes to our Privacy Policy
                    If we decide to change our privacy policy, we will update the Privacy Policy modification date below.</span>
            </div>
            <div style={{ marginTop: "15px" }}>
                <span id="_11.1" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    This policy was last modified on August 17, 2012</span>
            </div>
            <div style={{ marginTop: "10px" }}>
                <span id="_11.1" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    Contacting Us
                    If there are any questions regarding this privacy policy you may contact us using the information below.</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span id="_11.1" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                    CoBrand Media, P.O. Box 1654, Rancho Santa Fe, California 92067</span>
            </div>
            <div style={{ marginTop: "5px" }}>
                <span id="_11.1" style={{ fontFamily: 'Raleway', fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                </span>
            </div>
        </nowrap>
    }
    : { TermsConditionsText: "Lorem ipsum title Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet." }
