/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'
import './practicesForm.css'
import * as APIController from '../../../../api/APIController.js';
import * as yup from 'yup';
import Notification from '../../../../shared/components/notification/notification';
import CircularProgress from '@material-ui/core/CircularProgress';

const PracticesForm = props => {
    const initialValues = {
        countryId: '', stateId: '', cityId: '',
        countryName: props.history.location.state.countryIdEdit,
        stateName: props.history.location.state.stateIdEdit,
        cityName: props.history.location.state.cityIdEdit,
        practicesname: props.history.location.state.practicesEdit,
        active: props.history.location.state.practicesActiveEdit
    }
    const [isButtonValue, setButtonValue] = React.useState(false);

    const [dataSource, setdataSource] = React.useState([]);
    const [dataSourceState, setdataSourceState] = React.useState([]);
    const [dataSourceCity, setdataSourceCity] = React.useState([]);
    const [Loading, setLoading] = React.useState(true);
    const [LoadingState, setLoadingState] = React.useState(false);
    const [LoadingCity, setLoadingCity] = React.useState(false);
    const [IsLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        props.history.location.state.practicesIdEdit != 0 ? wsGetpracticesByID(props.history.location.state.practicesIdEdit)
            : wsGetCountryForDropdown();
    }, [props])

    const wsGetpracticesByID = (ID) => {
        APIController.GetPostcodeByID(ID)
            .then((response) => {
                setLoading(false)
                setLoadingState(false)
                setLoadingCity(false)
                if (response.error == null) {
                    initialValues.countryId = response.object.data[0].CountryID
                    initialValues.stateId = response.object.data[0].StateID
                    initialValues.cityId = response.object.data[0].CityID

                    setdataSource(JSON.parse(response.object.data[0].Countries));
                    setdataSourceState(JSON.parse(response.object.data[0].States));
                    setdataSourceCity(JSON.parse(response.object.data[0].Cities));
                }
                else if (response.error.message && response.error.message.length > 0) {
                    // ToastsStore.warning(response.error.message)
                }
            })
    }

    const wsGetCountryForDropdown = (ID) => {
        APIController.GetCountryForDropdown(ID)
            .then((response) => {
                setLoading(false)
                if (response.error == null) {
                    setdataSource(response.object.data);
                }
                else if (response.error.message && response.error.message.length > 0) {
                    // ToastsStore.warning(response.error.message)
                }
            })
    }

    const wsGetStateForDropdown = (countryId) => {
        setLoadingState(true)
        APIController.GetStateForDropdown(countryId)
            .then((response) => {
                setLoadingState(false)
                if (response.error == null) {
                    setdataSourceState(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    // ToastsStore.warning(response.error.message)
                }
            })
    }

    const wsGetCityForDropdown = (StateID) => {
        setLoadingCity(true)
        APIController.GetCityForDropdown(StateID)
            .then((response) => {
                setLoadingCity(false)
                if (response.error == null) {
                    setdataSourceCity(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    // ToastsStore.warning(response.error.message)
                }
            })
    }

    const wsAddEditpostcode = (ID, CountryID, StateID, CityID, practices, IsActive) => {
        setIsLoading(true)
        APIController.AddEditPostcode(ID, CountryID, StateID, CityID, practices, IsActive)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    handleNotification(response.object.message);
                    if (isButtonValue === true) {
                        props.history.push({
                            pathname: '/practices',
                            state: { showMessage: response.object.message }
                        })
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message);
                }
            })
    }

    const [message, setMessage] = React.useState("")
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    return (
        <>
            <section className="content-right-main">
                {props.history.location.state.practicesIdEdit == 0 ? <h1>Add practices</h1> : <h1>Edit practices</h1>}
                <Formik
                    // enableReinitialize={false}
                    initialValues={initialValues}
                    validationSchema={validatepracticesForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        wsAddEditpostcode(props.history.location.state.practicesIdEdit, values.countryId, values.stateId, values.cityId, values.practicesname, values.active)
                        if (isButtonValue === false) {
                            values.practicesname = "";
                            values.active = 1;
                        }
                    }} >
                    {({
                        values,
                        handleSubmit,
                        setFieldValue,
                        errors,
                        touched,
                    }) => {
                        return (
                            <div className="form-main">
                                <form onSubmit={handleSubmit}>
                                    <div className={`form_field ${errors && touched.countryId && errors.countryId && errors.countryId !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>Country</label>
                                        </div>
                                        <div className="field-right">
                                            {Loading ? <CircularProgress style={{ alignItems: 'center' }} />
                                                : <select name='countryId' defaultValue={values.countryId} value={values.countryId}
                                                    onChange={(e) => { wsGetStateForDropdown(e.target.value); setFieldValue('countryId', e.target.value); }}>
                                                    <option value={''}>Select Country</option>
                                                    {dataSource.map((obj, index) => {
                                                        return <option selected={obj.ID == values.countryId ? obj.ID : obj.Name} value={obj.ID} >{obj.Name}</option>
                                                    })}
                                                </select>}
                                            {errors && touched.countryId && errors.countryId && errors.countryId !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='countryId' render={(err) => { return err; }} />
                                                </span>)}
                                        </div>
                                    </div>
                                    <div className={`form_field ${errors && touched.stateId && errors.stateId && errors.stateId !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>State</label>
                                        </div>
                                        <div className="field-right">
                                            {LoadingState ? <CircularProgress style={{ alignItems: 'center' }} />
                                                : <select name='stateId' defaultValue={values.stateId} value={values.stateId}
                                                    onChange={(e) => { wsGetCityForDropdown(e.target.value); setFieldValue('stateId', e.target.value); }}>
                                                    <option value={''}>Select State</option>
                                                    {dataSourceState.map((obj, index) => {
                                                        return <option selected={obj.ID == values.stateId ? obj.ID : obj.Name} value={obj.ID}>{obj.Name}</option>
                                                    })}
                                                </select>}
                                            {errors && touched.stateId && errors.stateId && errors.stateId !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='stateId' render={(err) => { return err; }} />
                                                </span>)}
                                        </div>
                                    </div>
                                    <div className={`form_field ${errors && touched.cityId && errors.cityId && errors.cityId !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>City</label>
                                        </div>
                                        <div className="field-right">
                                            {LoadingCity ? <CircularProgress style={{ alignItems: 'center' }} />
                                                : <select name='cityId' defaultValue={values.cityId} value={values.cityId}
                                                    onChange={(e) => { setFieldValue('cityId', e.target.value); }}>
                                                    <option value={''}>Select City</option>
                                                    {dataSourceCity != null && dataSourceCity.map((obj, index) => {
                                                        return <option selected={obj.ID == values.cityId ? obj.ID : obj.Name} value={obj.ID}>{obj.Name}</option>
                                                    })}
                                                </select>}
                                            {errors && touched.cityId && errors.cityId && errors.cityId !== '' && (<span className='error-msg'>
                                                <ErrorMessage name='cityId' render={(err) => { return err; }} />
                                            </span>)}
                                        </div>
                                    </div>
                                    <div className={`form_field ${errors && touched.practicesname && errors.practicesname && errors.practicesname !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>practices</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter practices" name="practicesname" value={values.practicesname} maxLength="50" />
                                            {errors && touched.practicesname && errors.practicesname && errors.practicesname !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='practicesname' render={(err) => { return err; }} />
                                                </span>)}
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Is Active ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="yes-text"
                                                    defaultValue={values.active}
                                                    defaultChecked={values.active != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 1)} />
                                                <label htmlFor="yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="no-text"
                                                    defaultValue={values.active}
                                                    defaultChecked={values.active == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 0)} />
                                                <label htmlFor="no-text">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <Notification
                                        isError={isError}
                                        message={message}
                                        showNotification={showNotification}
                                        clearNotification={handleClearNotification.bind(this)}
                                        closeNotification={handleClearNotification.bind(this)}
                                    />
                                    <div className="form-submit">
                                        <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" onClick={() => setButtonValue(true)} />
                                        </div>
                                        {props.history.location.state.countryIdEdit == 0 ?
                                            <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                                <input type="submit" value={IsLoading ? "Saving..." : "save & add new"} name="save & add new" onClick={() => setButtonValue(false)} />
                                            </div> : null}
                                        <div className="button-field ">
                                            <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )
                    }}
                </Formik>
            </section>
        </>
    )
}
const validatepracticesForm = yup.object().shape({
    countryId: yup.string().required('Country is required'),
    stateId: yup.string().required('State is required'),
    cityId: yup.string().required('City is required'),
    practicesname: yup.string().required('Postalcode is required'),
});

export default PracticesForm;