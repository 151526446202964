import React from 'react';
// import page_img1 from '../../../assets/images/dashboard.png';
import page_img1 from '../../assets/images/dashboard1.png';

class Admin extends React.Component {
    render() {
        return (
            <>
                <section className="content-right-main">
                    <h1>Admin Dashboard</h1>
                    <section className="page-img">
                        <img src={page_img1} alt="" />
                    </section>
                </section>
            </>
        )
    }
}

export default Admin;