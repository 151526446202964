/*eslint-disable eqeqeq*/
import React from 'react';
import CampaignListingComponent from '../components/campaignslist';

import * as APIController from "../../../api/APIController.js";

import Notification from "../../../shared/components/notification/notification";

class Campaigns extends React.Component {

    state = {
        currentPage: 0,
        count: 0,
        rowsPerPage: 25,
        rowsPerPageOptions: [25, 10, 5],
        list: [],
        list1: [],
        from: 0,
        to: 25,
        progress: true,
        message: "",
        showNotification: false,
        isError: false,
        showMessage:
            this.props.history.location.state != null &&
            this.props.history.location.state.showMessage,
    };

    componentDidMount() {
        this.wsGetCampaigns();
        this.state.showMessage != "" &&
            this.handleNotification(this.state.showMessage);
        this.props.history.replace("/campaigns", null);
    }

    handlebind() {
        this.wsGetCampaigns();
    }

    wsGetCampaigns() {
        this.setState({ progress: true, });
        APIController.GetCampaigns().then((response) => {
            if (response.error == null) {
                this.setState({
                    list: response.object.data,
                    list1: response.object.data,
                    count: response.object.data.length,
                    progress: false,
                });
            } else if (response.error.message && response.error.message.length > 0) {
                this.handleNotification(response.error.message, true);
            }
        });
    }

    render() {
        return (
            <>
                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    clearNotification={this.handleClearNotification}
                    closeNotification={this.handleClearNotification}
                />
                <section className="content-right-main">
                    <div className="button-field button-field-country">
                        <button onClick={() => {
                            this.props.history.push({
                                pathname: 'campaigns/add',
                                state: { campaignIdEdit: 0 }
                            })
                        }
                        }><span>+</span>Add Campaign</button>
                    </div>
                    <CampaignListingComponent
                        {...this.state}
                        handleChangeRowsPerPage={this.handleRowsPerPageChange}
                        handleChangePage={this.handlePageChange}
                        applyFilter={this.handleApplyFilter}
                        history={this.props.history}
                        handlebind={this.handlebind.bind(this)}
                        handleNotification={this.handleNotification}
                    />
                </section>
            </>
        );
    }

    /**
     * handleRowsPerPageChange method is used to update rows per page
     * it will reset page to 1
     */
    handleRowsPerPageChange = (value) => {
        let from = 0;
        let to = value;
        this.setState({ rowsPerPage: value, currentPage: 1, to: to, from: from });
    };

    /**
     * handlePageChange method is used to change page
     */
    handlePageChange = values => {
        let from =
            values === 1
                ? 0 * this.state.rowsPerPage
                : (values - 1) * this.state.rowsPerPage;
        let to =
            values == 1
                ? this.state.rowsPerPage
                : parseInt(from) + parseInt(this.state.rowsPerPage);
        this.setState({ currentPage: Number(values), to: to, from: from });
    };

    /**
     * handleApplyFilter method is used to apply filter on every columns
     */
    handleApplyFilter = (tag, values, type) => {
        if (values !== "") {
            let searchUsers = [];

            if (type === "select") {
                searchUsers = this.state.list1.filter(
                    (us) => us[tag].toLowerCase() === values.toLowerCase()
                );
            } else {
                searchUsers = this.state.list.filter(
                    (us) => us[tag].toString().toLowerCase().indexOf(values.toLowerCase()) > -1
                );
            }
            this.setState({
                list: searchUsers,
                currentPage: 0,
                count: searchUsers.length,
                rowsPerPage: 25,
                rowsPerPageOptions: [25, 10, 5],
                from: 0,
                to: 25,
                progress: false,
            });
        } else {
            this.wsGetCampaigns();
            this.setState({
                currentPage: 0,
                rowsPerPage: 25,
                rowsPerPageOptions: [25, 10, 5],
                from: 0,
                to: 25,
            });
        }
    };

    handleNotification = (message, isError) => {
        this.setState({
            message: message,
            showNotification: true,
            isError: isError,
        });

        setTimeout(() => {
            this.handleClearNotification();
        }, 4000);
    };

    handleClearNotification = () => {
        this.setState({
            message: "",
            showNotification: false,
            isError: false,
        });
    };
}

export default Campaigns;