import React from "react";
import Table from "../../../../shared/components/table/table";
import TableHead from "../../../../shared/components/table/tableHead";
import TableRow from "../../../../shared/components/table/tableRow";
import TableBody from "../../../../shared/components/table/tableBody";
import TableCell from "../../../../shared/components/table/tableCell";
import double_left_arrow from '../../../../assets/images/left-doble-arow.svg';
import left_arrow from '../../../../assets/images/left-arrow.svg';
import double_right_arrow from '../../../../assets/images/right-doble-arow.svg';
import right_arrow from '../../../../assets/images/right-arrow.svg';
import "react-perfect-scrollbar/dist/css/styles.css";

import PerfectScrollbar from "react-perfect-scrollbar";
import CircularProgress from '@material-ui/core/CircularProgress';

const UploadExcelSheetList = (props) => {

	const headerList = [
		"Practice Name",
		"Address1",
		"Address2",
		"City",
		"ST",
		"Bill To",
		"VTM",
		"Territory",
		"Ship To",
		"ZIP Code",
		"Customer",
		"VSP",
	]
	const { currentPage,
		count,
		rowsPerPage,
		rowsPerPageOptions,
		to, from, myArray } = props;
	let rowData = myArray;
	// let uploadExcelsheetList = list;
	// uploadExcelsheetList = list.slice(from, to)

	const page = Math.floor(count / rowsPerPage) + 1;

	/**
	 * handleChangeInCurrentPage method is used to change page number
	 * it will check that page number does not exist last page
	 * @param {*} value 
	 */
	const handleChangeInCurrentPage = (value) => {
		if (value <= page && Number(parseInt(value))) {
			props.handleChangePage(Number(value));
		}
	}
	return (
		<>
			<div className="table-main-wrap upload-class">
				<PerfectScrollbar component='div'>
					<Table className="">
						<TableHead>
							<TableRow>
								{/* <TableCell>Action</TableCell> */}
								{headerList.map((obj, index) => {
									return <TableCell key={index}>{obj}</TableCell>;
								})}
							</TableRow>

						</TableHead>
						<TableBody>
							{!!rowData.length ? rowData.map((obj, index) => {
								return (
									<TableRow key={index}>
										{/* <TableCell>
											<span>
											</span>
										</TableCell> */}
										<TableCell>{obj.PracticeName}</TableCell>
										<TableCell>{obj.Address1}</TableCell>
										<TableCell>{obj.Address2}</TableCell>
										<TableCell>{obj.BillTo}</TableCell>
										<TableCell>{obj.City}</TableCell>
										<TableCell>{obj.Customer}</TableCell>
										<TableCell>{obj.ST}</TableCell>
										<TableCell>{obj.ShipTo}</TableCell>
										<TableCell>{obj.Territory}</TableCell>
										<TableCell>{obj.VSP}</TableCell>
										<TableCell>{obj.VTM}</TableCell>
										<TableCell>{obj.ZIPCode}</TableCell>
										{/* <TableCell className={obj.IsActive === true ? 'green' : 'red'}>
											<span>{obj.IsActive === true ? 'Yes' : 'No'}</span>
										</TableCell> */}
									</TableRow>
								);
							}) : <TableRow >
									<TableCell colSpan={headerList.length} className="not_found">
										<CircularProgress style={{ alignItems: 'center', color: '#0a5c7f' }} />
									</TableCell>
								</TableRow>}
						</TableBody>

					</Table>
				</PerfectScrollbar>
			</div>
			<div className="table-pagination">
				<div className="pagination-info">
					<p>Showing {from + 1} - {to > count ? count : to} of {count}</p>
				</div>
				<div className="pagination-control">
					<a href="#!" title="" className={`double-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(1)
						}}
					><img src={double_left_arrow} alt="left" /></a>
					<a href="#!" title="" className={`simple-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage - 1)
						}}
					><img src={left_arrow} alt="single_left" /></a>
					<span>Page</span>
					<input type="text" name="textbox" value={currentPage === 0 ? currentPage + 1 : currentPage} onChange={(e) => handleChangeInCurrentPage(e.target.value)} />
					<span>of {page}</span>
					<a href="#!" title="" className={`simple-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage + 1)
						}}
					><img src={right_arrow} alt="right" /></a>
					<a href="#!" title="" className={`double-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(page)
						}}><img src={double_right_arrow} alt="single_right" /></a>
					<select onChange={(e) => props.handleChangeRowsPerPage(parseInt(e.target.value))} defaultValue={rowsPerPage}>
						{
							rowsPerPageOptions.map((obj, index) => {
								return <option value={obj} key={index}>{obj}</option>
							})
						}
					</select>
				</div>
			</div>
		</>
	);
};

export default UploadExcelSheetList;
