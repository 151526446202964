import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'
// import './importedpracticesForm.css'
import * as APIController from '../../../../api/APIController';
import * as yup from 'yup';

const ImportedPracticesForm = props => {
    const initialValues =
    {
        usersPracticename: props.history.location.state.practiceNameEdit,
        Address1: props.history.location.state.Address1Edit,
        Address2: props.history.location.state.Address2Edit,
        City: props.history.location.state.CityEdit,
        State: props.history.location.state.StateEdit,
        BillTo: props.history.location.state.BillToEdit,
        VTM: props.history.location.state.VTMEdit,
        Territory: props.history.location.state.TerritoryEdit,
        ShipTo: props.history.location.state.ShipToEdit,
        ZIPCode: props.history.location.state.ZIPCodeEdit,
        Customer: props.history.location.state.CustomerEdit,
        VSP: props.history.location.state.VSPEdit,
        active: props.history.location.state.ISAccountCreatedLabelEdit,
        ImportedBy: props.history.location.state.ImportedByEdit,
        ImportedAt: props.history.location.state.ImportedAtEdit,
        MaxRows: props.history.location.state.MaxRowsEdit,														
															
    }


    const wsGetImportedPracticeByID = (ID) => {
        APIController.GetImportedPracticeByID(ID)
            .then((response) => {
                if (response.error == null) {
                    alert(response.object.message)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    // ToastsStore.warning(response.error.message)
                }
            })
    }
  

    return (
        <>
            <section className="content-right-main">
                {/* <h1>{pathName} Users</h1> */}
                <h1>Edit Imported Practices</h1>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validateUsersForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        wsGetImportedPracticeByID(props.history.location.state.ImportedPracticeIdEdit,values.usersPracticename,
                        values.Address1,values.Address2,values.City,values.State,values.BillTo,values.VTM,values.Territory
                        ,values.ShipTo,values.ZIPCode,values.Customer,values.VSP,values.active,values.ImportedBy,
                        values.ImportedAt,values.MaxRows  )
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        setFieldValue,
                        errors,
                        touched,
                    }) => {

                        return (
                            <div className="form-main">
                                <form onSubmit={handleSubmit}>
                                    {/* <div className="form_field"> */}
                                    <div
                                        className={`form_field ${
                                            errors &&
                                                touched.usersPracticename &&
                                                errors.usersPracticename &&
                                                errors.usersPracticename !== ''
                                                ? 'error'
                                                : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Practice Name</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Practice Name" name="usersPracticename" value={values.usersPracticename} />
                                            {errors &&
                                                touched.usersPracticename &&
                                                errors.usersPracticename &&
                                                errors.usersPracticename !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='usersPracticename'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={`form_field ${
                                            errors &&
                                                touched.Address1 &&
                                                errors.Address1 &&
                                                errors.Address1 !== ''
                                                ? 'error'
                                                : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Address1</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Address1" name="Address1" value={values.Address1} />
                                            {errors &&
                                                touched.Address1 &&
                                                errors.Address1 &&
                                                errors.Address1 !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='Address1'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={`form_field ${
                                            errors &&
                                                touched.Address2 &&
                                                errors.Address2 &&
                                                errors.Address2 !== ''
                                                ? 'error'
                                                : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Address2</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Address2" name="Address2" value={values.Address2} />
                                            {errors &&
                                                touched.Address2 &&
                                                errors.Address2 &&
                                                errors.Address2 !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='Address2'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>


                                    <div
                                        className={`form_field ${
                                            errors &&
                                                touched.City &&
                                                errors.City &&
                                                errors.City !== ''
                                                ? 'error'
                                                : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>City</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter City" name="City" value={values.City} />
                                            {errors &&
                                                touched.City &&
                                                errors.City &&
                                                errors.City !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='City'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>


                                    <div
                                        className={`form_field ${
                                            errors &&
                                                touched.State &&
                                                errors.State &&
                                                errors.State !== ''
                                                ? 'error'
                                                : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>State</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter State" name="State" value={values.State} />
                                            {errors &&
                                                touched.State &&
                                                errors.State &&
                                                errors.State !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='State'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>



                                    <div
                                        className={`form_field ${
                                            errors &&
                                                touched.BillTo &&
                                                errors.BillTo &&
                                                errors.BillTo !== ''
                                                ? 'error'
                                                : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>BillTo</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter BillTo" name="BillTo" value={values.BillTo} />
                                            {errors &&
                                                touched.BillTo &&
                                                errors.BillTo &&
                                                errors.BillTo !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='BillTo'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>


                                    <div
                                        className={`form_field ${
                                            errors &&
                                                touched.VTM &&
                                                errors.VTM &&
                                                errors.VTM !== ''
                                                ? 'error'
                                                : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>VTM</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter VTM" name="VTM" value={values.VTM} />
                                            {errors &&
                                                touched.VTM &&
                                                errors.VTM &&
                                                errors.VTM !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='VTM'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>


                                    <div
                                        className={`form_field ${
                                            errors &&
                                                touched.Territory &&
                                                errors.Territory &&
                                                errors.Territory !== ''
                                                ? 'error'
                                                : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Territory</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Territory" name="Territory" value={values.Territory} />
                                            {errors &&
                                                touched.Territory &&
                                                errors.Territory &&
                                                errors.Territory !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='Territory'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>

                                    <div
                                        className={`form_field ${
                                            errors &&
                                                touched.ShipTo &&
                                                errors.ShipTo &&
                                                errors.ShipTo !== ''
                                                ? 'error'
                                                : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Ship To</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter ShipTo" name="ShipTo" value={values.ShipTo} />
                                            {errors &&
                                                touched.ShipTo &&
                                                errors.ShipTo &&
                                                errors.ShipTo !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='ShipTo'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={`form_field ${
                                            errors &&
                                                touched.ZIPCode &&
                                                errors.ZIPCode &&
                                                errors.ZIPCode !== ''
                                                ? 'error'
                                                : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>ZIPCode</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter ZIPCode" name="ZIPCode" value={values.ZIPCode} />
                                            {errors &&
                                                touched.ZIPCode &&
                                                errors.ZIPCode &&
                                                errors.ZIPCode !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='ZIPCode'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={`form_field ${
                                            errors &&
                                                touched.Customer &&
                                                errors.Customer &&
                                                errors.Customer !== ''
                                                ? 'error'
                                                : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Customer</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="email" placeholder="Enter Customer" name="Customer" value={values.Customer} />
                                            {errors &&
                                                touched.Customer &&
                                                errors.Customer &&
                                                errors.Customer !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='Customer'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={`form_field ${
                                            errors &&
                                                touched.VSP &&
                                                errors.VSP &&
                                                errors.VSP !== ''
                                                ? 'error'
                                                : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>VSP</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter VSP" name="VSP" value={values.VSP} />
                                            {errors &&
                                                touched.VSP &&
                                                errors.VSP &&
                                                errors.VSP !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='VSP'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>

                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>IsAccount CreatedLABEL</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="yes-text" defaultValue={values.active} onClick={(e) => setFieldValue('active', true)} />
                                                <label htmlFor="yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="no-text" defaultValue={values.active} onClick={(e) => setFieldValue('active', false)} />
                                                <label htmlFor="no-text">No</label>
                                            </div>

                                        </div>
                                    </div>


                                    <div
                                        className={`form_field ${
                                            errors &&
                                                touched.ImportedBy &&
                                                errors.ImportedBy &&
                                                errors.ImportedBy !== ''
                                                ? 'error'
                                                : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>ImportedBy</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter ImportedBy" name="ImportedBy" value={values.ImportedBy} />
                                            {errors &&
                                                touched.ImportedBy &&
                                                errors.ImportedBy &&
                                                errors.ImportedBy !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='ImportedBy'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={`form_field ${
                                            errors &&
                                                touched.ImportedAt &&
                                                errors.ImportedAt &&
                                                errors.ImportedAt !== ''
                                                ? 'error'
                                                : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>ImportedAt</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter ImportedAt" name="ImportedAt" value={values.ImportedBy} />
                                            {errors &&
                                                touched.ImportedAt &&
                                                errors.ImportedAt &&
                                                errors.ImportedAt !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='ImportedAt'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={`form_field ${
                                            errors &&
                                                touched.MaxRows &&
                                                errors.MaxRows &&
                                                errors.MaxRows !== ''
                                                ? 'error'
                                                : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>MaxRows</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter MaxRows" name="MaxRows" value={values.ImportedBy} />
                                            {errors &&
                                                touched.MaxRows &&
                                                errors.MaxRows &&
                                                errors.MaxRows !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='MaxRows'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>

                                    <div className="form-submit">
                                        {/* <div className="button-field">
                                            <input type="submit" value="save" name="save" />
                                        </div>
                                        <div className="button-field disable-btn">
                                            <input type="submit" value="save & add new" name="save & add new" />
                                        </div> */}
                                        <div className="button-field ">
                                            <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                        </div>
                                    </div>

                                </form>
                            </div>
                        )
                    }}

                </Formik>
            </section>
        </>
    )
}
const validateUsersForm = yup.object().shape({
    usersPracticename: yup.string().required('Practice Name is required'),
    Address1: yup.string().required('Address1 is required'),
    Address2: yup.string().required('Address2 is required'),
    City: yup.string().required('City is required'),
    State: yup.string().required('State is required'),
    BillTo: yup.string().required('BillTo is required'),
    VTM: yup.string().required('VTM is required'),
    Territory: yup.string().required('Territory is required'),
    ShipTo: yup.string().required('ShipTo is required'),
    ZIPCode: yup.string().required('ZIPCode is required'),
    Customer: yup.string().required('Customer is required'),
    VSP: yup.string().required('VSP is required'),
    ImportedBy: yup.string().required('ImportedBy is required'),
    ImportedAt: yup.string().required('ImportedAt is required'),
    MaxRows: yup.string().required('MaxRows is required'),
});
export default ImportedPracticesForm;