/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'
// import './TerritoriesForm.css'
import * as APIController from '../../../../api/APIController';
import * as yup from 'yup';

import Notification from '../../../../shared/components/notification/notification';
import CircularProgress from '@material-ui/core/CircularProgress';

const AdminTerritoriesForm = props => {
    const initialValues =
    {
        AreaId: props.history.location.state.AreaIdEdit,
        RegionId: props.history.location.state.RegionIdEdit,
        territoriesname: props.history.location.state.TerritoriesNameEdit,
        Shortcode: props.history.location.state.ShortcodeEdit,
        active: props.history.location.state.TerritoriesActiveEdit
    }
    const [isButtonValue, setButtonValue] = React.useState(false);
    const [LoadingArea, setLoadingArea] = React.useState(true)
    const [LoadingRegion, setLoadingRegion] = React.useState(false)
    const [IsLoading, setIsLoading] = React.useState(false);

    const wsAddEditTerritory = (ID, AreaID, RegionID, Name, ShortCode, IsActive) => {
        setIsLoading(true)
        APIController.AddEditTerritory(ID, AreaID, RegionID, Name, ShortCode, IsActive)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    if (isButtonValue === true && response.object.status == 1) {
                        props.history.replace({ pathname: '/resource/territories', state: { showMessage: response.object.message } })
                    } else if (response.object.status == 1) {
                        handleNotification(response.object.message);
                    } else {
                        handleNotification(response.object.message, true);
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const [dataSource, setdataSource] = React.useState([]);
    const [dataSourceRegion, setdataSourceRegion] = React.useState([]);
    
    React.useEffect(() => {
        props.history.location.state.TerritoriesIdEdit != 0 ? wsGetTerritoryByID(props.history.location.state.TerritoriesIdEdit)
            : wsGetAreaForDropdown()
    }, [props])

    const wsGetTerritoryByID = (ID) => {
        APIController.GetTerritoryByID(ID)
            .then((response) => {
                setLoadingArea(false)
                setLoadingRegion(false)
                if (response.error == null) {
                    initialValues.AreaId = response.object.data[0].AreaID
                    initialValues.RegionId = response.object.data[0].RegionID

                    setdataSource(JSON.parse(response.object.data[0].Areas));
                    setdataSourceRegion(JSON.parse(response.object.data[0].Regions));
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const wsGetAreaForDropdown = (ID) => {
        APIController.GetAreaForDropdown(ID)
            .then((response) => {
                setLoadingArea(false)
                if (response.error == null) {
                    setdataSource(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const wsGetRegionForDropdown = (AreaID) => {
        setLoadingRegion(true)
        APIController.GetRegionForDropdown(AreaID)
            .then((response) => {
                setLoadingRegion(false)
                if (response.error == null) {
                    setdataSourceRegion(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }


    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }
    return (
        <>
            <section className="content-right-main">
                {props.history.location.state.TerritoriesIdEdit == 0 ? <h1>Add Territory</h1> : <h1>Edit Territory</h1>}
                <Formik
                    // enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validateTerritoriesForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        wsAddEditTerritory(props.history.location.state.TerritoriesIdEdit, values.AreaId, values.RegionId, values.territoriesname,
                            values.Shortcode, values.active)
                        if (isButtonValue === false) {
                            values.territoriesname = "";
                            values.Shortcode = "";
                            values.active = 1;
                        }
                    }} >
                    {({
                        values,
                        handleSubmit,
                        setFieldValue,
                        errors,
                        touched,
                    }) => {

                        return (
                            <div className="form-main">
                                <form onSubmit={handleSubmit}>

                                    <div
                                        className={`form_field ${
                                            errors &&
                                                touched.AreaId &&
                                                errors.AreaId &&
                                                errors.AreaId !== ''
                                                ? 'error'
                                                : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Area</label>
                                        </div>
                                        <div className="field-right">
                                            {LoadingArea ? <CircularProgress style={{ alignItems: 'center' }} />
                                                :
                                                <select name='AreaId'
                                                    defaultValue={values.AreaId}
                                                    value={values.AreaId}
                                                    onChange={(e) => {
                                                        setFieldValue('AreaId', e.target.value);
                                                        wsGetRegionForDropdown(e.target.value)
                                                    }}>
                                                    <option value={''}>Select Area</option>
                                                    {
                                                        dataSource.map((obj, index) => {
                                                            return <option value={obj.ID} key={index}>{obj.Name}</option>
                                                        })
                                                    }
                                                </select>
                                            }
                                            {errors &&
                                                touched.AreaId &&
                                                errors.AreaId &&
                                                errors.AreaId !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='AreaId'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>

                                    <div
                                        className={`form_field ${
                                            errors &&
                                                touched.RegionId &&
                                                errors.RegionId &&
                                                errors.RegionId !== ''
                                                ? 'error'
                                                : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Region</label>
                                        </div>
                                        <div className="field-right">
                                            {LoadingRegion ? <CircularProgress style={{ alignItems: 'center' }} />
                                                :
                                                <select name='RegionId'
                                                    defaultValue={values.RegionId}
                                                    value={values.RegionId}
                                                    onChange={(e) => {
                                                        setFieldValue('RegionId', e.target.value);
                                                    }}>
                                                    <option value={''}>Select Region</option>
                                                    {
                                                        dataSourceRegion.map((obj, index) => {
                                                            return <option value={obj.ID} key={index}>{obj.Name}</option>
                                                        })
                                                    }
                                                </select>
                                            }
                                            {errors &&
                                                touched.RegionId &&
                                                errors.RegionId &&
                                                errors.RegionId !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='RegionId'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={`form_field ${
                                            errors &&
                                                touched.territoriesname &&
                                                errors.territoriesname &&
                                                errors.territoriesname !== ''
                                                ? 'error'
                                                : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Territory</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Territory" name="territoriesname" value={values.territoriesname} maxLength="50"/>
                                            {errors &&
                                                touched.territoriesname &&
                                                errors.territoriesname &&
                                                errors.territoriesname !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='territoriesname'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={`form_field ${
                                            errors &&
                                                touched.Shortcode &&
                                                errors.Shortcode &&
                                                errors.Shortcode !== ''
                                                ? 'error'
                                                : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Short Code</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Short Code" name="Shortcode" value={values.Shortcode} maxLength="50"/>
                                            {errors &&
                                                touched.Shortcode &&
                                                errors.Shortcode &&
                                                errors.Shortcode !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='Shortcode'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Is Active ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="yes-text"
                                                    defaultChecked={values.active != 0 ? true : false}
                                                    // defaultChecked={values.active != false ? true : false}
                                                    defaultValue={values.active}
                                                    // onClick={(e) => setFieldValue('active', true)} />
                                                    onClick={(e) => setFieldValue('active', 1)} />
                                                <label htmlFor="yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="no-text"
                                                    defaultValue={values.active}
                                                    // defaultChecked={values.active == false ? true : false}
                                                    // onClick={(e) => setFieldValue('active', false)} />
                                                    defaultChecked={values.active == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 0)} />
                                                <label htmlFor="no-text">No</label>
                                            </div>

                                        </div>
                                    </div>
                                    <Notification
                                        isError={isError}
                                        message={message}
                                        showNotification={showNotification}
                                        clearNotification={handleClearNotification.bind(this)}
                                        closeNotification={handleClearNotification.bind(this)}
                                    />
                                    <div className="form-submit">
                                        <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" onClick={() => setButtonValue(true)} />
                                        </div>
                                        {props.history.location.state.TerritoriesIdEdit == 0 ?
                                            <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                                <input type="submit" value={IsLoading ? "Saving..." : "save & add new"} name="save & add new" onClick={() => setButtonValue(false)} />
                                            </div> : null}
                                        <div className="button-field ">
                                            <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                        </div>
                                    </div>

                                </form>
                            </div>
                        )
                    }}

                </Formik>
            </section>
        </>
    )
}
const validateTerritoriesForm = yup.object().shape({
    AreaId: yup.string().required('Area is required'),
    RegionId: yup.string().required('Region is required'),
    territoriesname: yup.string().required('Territory is required'),
    Shortcode: yup.string().required('Short Code is required'),
});
export default AdminTerritoriesForm;