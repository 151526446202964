/*eslint-disable eqeqeq*/
import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import CircularProgress from '@material-ui/core/CircularProgress';
import * as AppConstants from "../../../config/AppConstant";
// import "./sidebar.css";
import ExpansionPanelComponent from "../../../shared/components/expansionPanel/expansionPanel";

const Sidebar = (props) => {
	const [expanded, setExpanded] = React.useState("");
	const [selectedPath, setSelectedPath] = React.useState("");
	const [isClick, setClick] = React.useState(false);
	const [subMenuExpanded, setSubMenuExpanded] = React.useState("");
	const [sidebarMenu, setSidebarMenu] = React.useState(1);
	const [dataSource, setdataSource] = React.useState([]);
	const [dataSourceAdmin, setdataSourceAdmin] = React.useState([]);
	const [dataSourceAdmin1, setdataSourceAdmin1] = React.useState([]);
	const [isDevNav, setisDevNav] = React.useState([]);
	const [isLoading, setLoading] = React.useState(true);

	React.useEffect(() => {
		GetNavigationDataFromLocal();
		if (props.location.pathname == "/digital-product/selection/adbreak"
			|| props.location.pathname == "/dashboard"
			|| props.location.pathname == "/analyticsDashboard"
			|| props.location.pathname == "/admin"
			|| props.location.pathname == "/calendar"
			|| props.location.pathname == "/calendarForAdmin"
			|| props.location.pathname == "/product/add"
			|| props.location.pathname == "/assetdownloadreport"
			|| props.location.pathname == "/contestSummaryReport"
			|| props.location.pathname == "/registrationsReport"
			|| props.location.pathname == "/socialAutomationReport"
			|| props.location.pathname == "/campaignOrdersReport"
			|| props.location.pathname == "/subscriptions"
			|| props.location.pathname == "/subscription/subscription"
			|| props.location.pathname == "/ReportData"
			|| props.location.pathname == "/subscription/postschedule"
			|| props.location.pathname == "/emailMarketingReport"
			|| props.location.pathname == "/RegistrationsReportDownload"
			|| props.location.pathname == "/customPostAdminFeed"
			|| props.location.pathname == "/supply-calculator-leads"
			|| props.location.pathname == "/freeassets"
			|| props.location.pathname == "/supplytool/leads/list"
		) {
			props.closeSidebar()
		} else if (window.innerWidth >= 991) {
			props.handleMenu1()
		}
	}, [props.location.pathname]);


	const GetNavigationDataFromLocal = () => {
		let response = JSON.parse(localStorage.getItem('GetNav'))
		if (response != null && response.error == null) {
			setdataSource(response.object.data.BrandsNav)
			setLoading(false)
			if (response.object.data.AdminNav.AdminNav.length) {
				setdataSourceAdmin(response.object.data.AdminNav.AdminNav)
				setdataSourceAdmin1(response.object.data.AdminNav.SuperAdminNav)
				setisDevNav(response.object.data.AdminNav.DevNav)
				sessionStorage.setItem("IsDeveloper", response.object.data.AdminNav.DevNav.length ? true : false);
				AppConstants.SetLocalStorage("headerMenuAdmin", 1);
				setSidebarMenu(0)
			} else {
				AppConstants.SetLocalStorage("headerMenuAdmin", 0);
				setSidebarMenu(1)
			}

			response.object.data.BrandsNav.forEach((obj, index) => {
				if (props.location.pathname.includes(obj.BrandName) && !isClick) {
					setExpanded(`panel${index + 1}`);
					setClick(true);
				}

				JSON.parse(obj.Campaigns) && JSON.parse(obj.Campaigns).forEach((subObj, subIndex) => {
					if (props.location.pathname.includes(subObj.CampaignName) && !isClick) {
						setSubMenuExpanded(`subpanel-${index + 1}-${subIndex + 1}`);
						setClick(true);
					}

					obj.Products && obj.Products.forEach((childObj, childIndex) => {
						if (props.location.pathname.includes(childObj.ProductName) && !isClick) {
							setSubMenuExpanded(`subpanel-${index + 1}-${childIndex + 1}`);
							setClick(true);
						}
					});
				});
			});
		}
	}

	return (
		<section
			className={`left-sidebar ${props.isSidebarOpen ? "active" : ""}`}
			onClick={() => {
				const body = document.getElementsByTagName("body")[0];
				body.classList.add("active");
			}}>
			<PerfectScrollbar
				component='div'
				options={{
					wheelSpeed: 0.5,
					wheelPropagation: true,
					minScrollbarLength: 20,
					swipeEasing: true,
					handlers: ["click-rail", "drag-thumb", "keyboard", "wheel", "touch"],
				}}>
				{isLoading ? <div style={{ display: 'grid', justifyContent: 'center' }}> <CircularProgress style={{ color: '#fff' }} /> </div> :
					<div>
						<div className='humburger-menu' onClick={props.handleMenu}>
							<div className="menu-text">MENU</div>
							<span></span>
							<span></span>
							<span></span>
						</div>
						<nav>
							<ul className="first-step">
								{sidebarMenu == 1 ? dataSource.map((obj, index) => {
									//Code by Nilesh Patel code for Sidebar menu redirect PageURL
									let data = JSON.parse(obj.Campaigns)
									let Product = []
									let Campaign = ''
									if (data != null || data != undefined) {
										Product = data[0].Products[0]
										Campaign = data[0]
									}
									//----------------------------
									return (
										<li key={index}>
											<ExpansionPanelComponent
												// title={<span dangerouslySetInnerHTML={{ __html: obj.BrandName }} />}
												title={obj.OrderNo == -1 ? <span>{Product.ProductName.split('®')[0]}{Product.ProductName.match(/®/g) ? <sup>&reg;</sup> : ''}{Product.ProductName.split('®')[1]}</span> : <span>{obj.BrandName.split('®')[0]}{obj.BrandName.match(/®/g) ? <sup>&reg;</sup> : ''}{obj.BrandName.split('®')[1]}</span>}
												className={`parent-nav`}
												summaryClassName={`${selectedPath === obj.OrderNo ? 'active' : obj.OrderNo == -1 ? '' : ''}`}
												icon={""}
												id={`panel${index + 1}`}
												expanded={expanded}
												controls={`panel${index + 1}`}
												focused={true}
												setExpanded={(value) => {
													setExpanded("");
													setSelectedPath(obj.OrderNo)//Code by Nilesh patel
													//Code by Nilesh Patel code for Sidebar menu redirect PageURL
													if (obj.OrderNo == -1) {
														AppConstants.SetLocalStorage('localStorageChildren', []);
														AppConstants.SetLocalStorage('localStorageBrandID', obj.BrandID);
														AppConstants.SetLocalStorage('localStorageBrandName', obj.BrandName);
														AppConstants.SetLocalStorage('localStorageCampaignID', Campaign.CampaignID);
														AppConstants.SetLocalStorage('localStorageCampaignName', Campaign.CampaignName);
														AppConstants.SetLocalStorage('localStorageProductID', Product.ProductID);
														AppConstants.SetLocalStorage('localStorageProductName', Product.ProductName);
														AppConstants.SetLocalStorage('IsDirectPageURL', true);
														AppConstants.SetLocalStorage("localStorageAutomationSubscriptionID", 0)
														AppConstants.SetLocalStorage("localStorageFBPageData", '')
														AppConstants.SetLocalStorage("SocialAutomationAllLocations", '')
														AppConstants.SetLocalStorage("localStorageInstaUserData", '')
														AppConstants.SetLocalStorage("localStorageBrandsData", [])
														AppConstants.SetLocalStorage("localStoragepracticeId", 0)
														AppConstants.SetLocalStorage('localStorageOrderDetailsID', 0)
														AppConstants.SetLocalStorage("localStorageMultiSelectPractice", "0")
														AppConstants.SetLocalStorage("localStorageIsFromHeader", 3)
														props.history.push({
															pathname: Product.PageURL,
														});
													} else {
														setTimeout(() => {
															setExpanded(value);
														}, 500);
														AppConstants.SetLocalStorage('IsDirectPageURL', false);
														AppConstants.SetLocalStorage('localStorageBrandID', obj.BrandID);
														AppConstants.SetLocalStorage('localStorageBrandName', obj.BrandName);
														AppConstants.SetLocalStorage("localStorageIsFromHeader", 3)
														props.history.push({
															pathname: "/digital-product",
														});
													}
													//-----------------------
												}}>
												{JSON.parse(obj.Campaigns) &&
													!!JSON.parse(obj.Campaigns).length &&
													JSON.parse(obj.Campaigns).map((subObj, subIndex) => {
														return (
															<ExpansionPanelComponent
																title={<span>•&nbsp;&nbsp;{subObj.CampaignName}</span>}
																className={`subparent-nav`}
																summaryClassName={`${selectedPath === subObj.CampaignID ? 'active' : ''}`}
																icon={""}
																id={`subpanel-${index + 1}-${subIndex + 1}`}
																expanded={subMenuExpanded}
																controls={`subpanel-${index + 1}-${subIndex + 1}`}

																setExpanded={(value) => {
																	setSubMenuExpanded("");
																	setTimeout(() => {
																		setSubMenuExpanded(value);
																	}, 500);
																	setSelectedPath(subObj.CampaignID)
																	AppConstants.SetLocalStorage('localStorageCampaignID', subObj.CampaignID);
																	AppConstants.SetLocalStorage('localStorageCampaignName', subObj.CampaignName);
																	AppConstants.SetLocalStorage('IsDirectPageURL', false);
																	AppConstants.SetLocalStorage("localStorageIsFromHeader", 3);
																	props.history.push({
																		pathname: "/digital-product/selection",
																		state: {
																			// campaignIdEdit: subObj.CampaignID,
																			// campaignNameEdit: subObj.CampaignName,
																			// BrandNameHeader: obj.BrandName,
																			// brandIdEdit: obj.BrandID,
																		},
																	});
																}}>
															</ExpansionPanelComponent>
														);
													})}
											</ExpansionPanelComponent>
										</li>
									);

								}) :
									<div>
										<>{
											<li key={0}>
												<ExpansionPanelComponent
													title={<span>Bausch + Lomb</span>}
													className={"parent-nav"}
													summaryClassName={`${selectedPath === "BauschLom" ? 'active' : ''}`}
													icon={""}
													id={`panel${0 + 1}`}
													expanded={expanded}
													controls={`panel${0 + 1}`}
													setExpanded={(value) => {
														setExpanded("");
														setSelectedPath("BauschLom")
														setTimeout(() => {
															setExpanded(value);
														}, 500);
													}}>
													{dataSourceAdmin.map((subObj, subIndex) => {
														return (
															<li key={subIndex}>
																<ExpansionPanelComponent
																	title={<span>{subObj.Name}</span>}
																	className={"subparent-nav"}
																	summaryClassName={`${selectedPath === subObj.Name ? 'active' : ''}`}
																	icon={""}
																	id={`subpanel-${0 + 1}-${subIndex + 1}`}
																	expanded={subMenuExpanded}
																	controls={`subpanel-${0 + 1}-${subIndex + 1}`}

																	setExpanded={(value) => {
																		setSubMenuExpanded("");
																		setSelectedPath(subObj.Name)
																		setTimeout(() => {
																			setSubMenuExpanded(value);
																		}, 500);
																		props.history.push(`${subObj.path}`);
																	}}>
																	{JSON.parse(subObj.Menu) &&
																		!!JSON.parse(subObj.Menu).length &&
																		JSON.parse(subObj.Menu).map((menuobj, menuIndex) => {
																			return (
																				<NavLink
																					className={`child-nav  ${selectedPath === menuobj.Name ? 'active' : ''}`}
																					title={menuobj.ProductName}
																					to={{ pathname: menuobj.URL }}
																					onMouseEnter={() => {
																						const card = document.getElementById(menuobj.Name);
																						if (card) {
																							card.classList.add("active");
																						}
																					}}
																					onMouseLeave={() => {
																						const card = document.getElementById(menuobj.Name);
																						if (card) {
																							card.classList.remove("active");
																						}
																					}}
																					activeClassName='active'
																					onClick={() => {
																						setSelectedPath(`${menuobj.Name}`)
																					}}
																					key={menuIndex}>
																					<span >{menuobj.Name} </span>
																				</NavLink>
																			);
																		})}
																</ExpansionPanelComponent>
															</li>
														);

													})}
												</ExpansionPanelComponent>
											</li>
										}</>
										<>{
											<ExpansionPanelComponent
												title={<span>CoBrand</span>}
												className={"parent-nav"}
												summaryClassName={`${selectedPath === "CoBrand" ? 'active' : ''}`}
												icon={""}
												id={`panel${1 + 1}`}
												expanded={expanded}
												controls={`panel${1 + 1}`}
												setExpanded={(value) => {
													setExpanded("");
													setSelectedPath("CoBrand")
													setTimeout(() => {
														setExpanded(value);
													}, 500);
												}}>
												{dataSourceAdmin1.map((obj, subIndex) => {
													return (
														<li key={subIndex}>
															<ExpansionPanelComponent
																title={
																	<span dangerouslySetInnerHTML={{ __html: obj.Name }} />
																}
																className={"subparent-nav"}
																summaryClassName={`${selectedPath === obj.Name ? 'active' : ''}`}
																icon={""}
																id={`subpanel-${1 + 1}-${subIndex + 1}`}
																expanded={subMenuExpanded}
																controls={`subpanel-${1 + 1}-${subIndex + 1}`}

																setExpanded={(value) => {
																	setSelectedPath(obj.Name)
																	setSubMenuExpanded("");
																	setTimeout(() => {
																		setSubMenuExpanded(value);
																	}, 500);
																	props.history.push(`${obj.path}`);
																}}>

																{JSON.parse(obj.Menu) &&
																	!!JSON.parse(obj.Menu).length &&
																	JSON.parse(obj.Menu).map((subObj, menuIndex) => {
																		return (
																			<NavLink
																				className={`child-nav  ${selectedPath === subObj.Name ? 'active' : ''}`}
																				title={obj.Name}
																				to={{ pathname: subObj.URL }}
																				onMouseEnter={() => {
																					const card = document.getElementById(
																						obj.Name
																							.toLowerCase()
																							.replace(" ", "-")
																					);

																					if (card) {
																						card.classList.add("active");
																					}
																				}}
																				onMouseLeave={() => {
																					const card = document.getElementById(
																						obj.Name
																							.toLowerCase()
																							.replace(" ", "-")
																					);
																					if (card) {
																						card.classList.remove("active");
																					}
																				}}
																				activeClassName='active'
																				onClick={() => {
																					setSelectedPath(`${subObj.Name}`)
																				}}
																				key={menuIndex}>
																				<span>{subObj.Name}</span>
																			</NavLink>
																		);
																	})}
															</ExpansionPanelComponent>
														</li>
													);

												})}
											</ExpansionPanelComponent>
										}
										</>
										<>{isDevNav.length &&
											<ExpansionPanelComponent
												title={<span>DevNav</span>}
												className={"parent-nav"}
												summaryClassName={`${selectedPath === "DevNav" ? 'active' : ''}`}
												icon={""}
												id={`panel${2 + 1}`}
												expanded={expanded}
												controls={`panel${2 + 1}`}
												setExpanded={(value) => {
													setExpanded("");
													setSelectedPath("DevNav")
													setTimeout(() => {
														setExpanded(value);
													}, 500);
												}}>
												{isDevNav.map((obj, subIndex) => {
													return (
														<li key={subIndex}>
															<ExpansionPanelComponent
																title={
																	<span dangerouslySetInnerHTML={{ __html: obj.Name }} />
																}
																className={"subparent-nav"}
																summaryClassName={`${selectedPath === obj.Name ? 'active' : ''}`}
																icon={""}
																id={`subpanel-${2 + 1}-${subIndex + 1}`}
																expanded={subMenuExpanded}
																controls={`subpanel-${2 + 1}-${subIndex + 1}`}

																setExpanded={(value) => {
																	setSelectedPath(obj.Name)
																	setSubMenuExpanded("");
																	setTimeout(() => {
																		setSubMenuExpanded(value);
																	}, 500);
																	props.history.push(`${obj.path}`);
																}}>

																{JSON.parse(obj.Menu) &&
																	!!JSON.parse(obj.Menu).length &&
																	JSON.parse(obj.Menu).map((subObj, menuIndex) => {
																		return (
																			<NavLink
																				className={`child-nav  ${selectedPath === subObj.Name ? 'active' : ''}`}
																				title={obj.Name}
																				to={{ pathname: subObj.URL }}
																				onMouseEnter={() => {
																					const card = document.getElementById(
																						obj.Name
																							.toLowerCase()
																							.replace(" ", "-")
																					);

																					if (card) {
																						card.classList.add("active");
																					}
																				}}
																				onMouseLeave={() => {
																					const card = document.getElementById(
																						obj.Name
																							.toLowerCase()
																							.replace(" ", "-")
																					);
																					if (card) {
																						card.classList.remove("active");
																					}
																				}}
																				activeClassName='active'
																				onClick={() => {
																					setSelectedPath(`${subObj.Name}`)
																				}}
																				key={menuIndex}>
																				<span>{subObj.Name}</span>
																			</NavLink>
																		);
																	})}
															</ExpansionPanelComponent>
														</li>
													);

												})}
											</ExpansionPanelComponent>
										}</>
									</div>
								}
							</ul>
						</nav>
					</div>
				}
			</PerfectScrollbar>
		</section>

	);
};

export default withRouter(Sidebar);
