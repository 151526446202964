/*eslint-disable eqeqeq*/
import React from "react";
import ClinicFormComponent from "../component/clinicForm";
import UserListComponent from "../component/userList";
import CoOpListComponent from "../component/cooplist";
import AddressListComponent from "../component/addressList";
import ModalComponent from "../../../shared/components/modal/modal";
import UserForm from "../component/userForm";
import CoOPForm from "../component/coopForm";

import * as APIController from '../../../api/APIController.js';
class Clinic extends React.Component {
	state = {
		activeTab: 0,
		tabs: ["Information", "Addresses", "Users"],
		usersList: [],
		practiceCoOpList: [],
		isModalOpen: false,
		tag: "",
		currentPage: 0,
		count: 0,
		EdiUserList: [],
		rowsPerPage: 25,
		rowsPerPageOptions: [25, 10, 5],
		from: 0,
		to: 25,
		progress: true,
		list: [],
		initialValues: [{
			ID: "0",
			FirstName: "",
			Lastname: "",
			EmailAddress: "",
			PhoneNo: "",
			IsActive: 1
		}]

	};

	componentDidMount() {
		this.state.showMessage != '' && this.handleNotification(this.state.showMessage)
		this.wsGetUsers()
		this.wsGetPracticeCoOp()
		if (this.props.history.location.state.IsCoOpEligibleId == 1) { this.state.tabs.push('Co-Op') }
	}

	handlebind() {
		this.wsGetUsers()
	}
	handleCoOPbind() {
		this.wsGetPracticeCoOp()
		this.setState({ isModalOpen: false })
	}

	wsGetUsers = () => {
		this.setState({ progress: true, });
		APIController.GetUsersByPracticeID(this.props.history.location.state.PracticeEditID)
			.then((response) => {
				if (response.error == null) {
					this.setState({ usersList: response.object.data, progress: false, })
				}
				else if (response.error.message && response.error.message.length > 0) {
					// handleNotification(response.error.message);
				}
			})
	}

	wsGetPracticeCoOp = () => {
		this.setState({ progress: true, });
		APIController.GetPracticeCoOp(this.props.history.location.state.PracticeEditID)
			.then((response) => {
				if (response.error == null) {
					this.setState({ practiceCoOpList: response.object.data, progress: false, })
				}
				else if (response.error.message && response.error.message.length > 0) {
					// handleNotification(response.error.message);
				}
			})
	}

	render() {
		return (
			<section className='content-right-main'>
				<h1 className="title-wrap1">Practice Details</h1>
				<div className='tab-listing'>
					<ul>
						{this.state.tabs.map((obj, index) => {
							return (
								<li key={index}>
									<a
										href='#!'
										title={obj}
										className={`${this.state.activeTab === index ? "active" : ""}`}
										onClick={(e) => {
											e.preventDefault();
											this.handleTabChange(index);
										}}>
										{obj}
									</a>
								</li>
							);
						})}

					</ul>
					<span className='tab-arrow'></span>
				</div>
				{this.state.activeTab === 0 ? (
					<ClinicFormComponent
						{...this.props}
						PracticeID={this.props.history.location.state.PracticeEditID}
						StatusID={this.props.history.location.state.StatusID}
						IsCoOpEligibleId={this.props.history.location.state.IsCoOpEligibleId}
						Representative={this.props.history.location.state.Representative}
						WebSite={this.props.history.location.state.WebsiteEdit}
						RepresentativeID={this.props.history.location.state.RepresentativeID}
						Territory={this.props.history.location.state.Territory}
					/>
				) : this.state.activeTab === 1 ? (
					<AddressListComponent
						PracticeID={this.props.history.location.state.PracticeEditID}
						applyFilter={this.handleAddressFilter}
						openAddressForm={this.handleModalOpen}
					/>
				) : this.state.activeTab === 2 ? (
					<UserListComponent
						{...this.state}
						PracticeID={this.props.history.location.state.PracticeEditID}
						list={this.state.usersList}
						progress={this.state.progress}
						applyFilter={this.handleApplyFilter}
						openAddressForm={this.handleModalOpen}
						handlebind={this.handlebind.bind(this)}
					/>
				) : (<CoOpListComponent
					{...this.state}
					PracticeID={this.props.history.location.state.PracticeEditID}
					list={this.state.practiceCoOpList}
					progress={this.state.progress}
					applyFilter={this.handleApplyFilter1}
					openAddressForm={this.handleModalOpen}
				/>)
				}
				{
					this.state.isModalOpen && this.state.tag === "user" ? (
						<ModalComponent
							show={this.state.isModalOpen}
							handleClose={() => this.setState({ tag: "", isModalOpen: false })}>
							<UserForm
								PracticeID={this.props.history.location.state.PracticeEditID}
								Title={'Add User'}
								initialValues={this.state.initialValues[0]}
								closeModal={() => this.setState({ tag: "", isModalOpen: false })}
								handlebind={this.handlebind.bind(this)}
							/>
						</ModalComponent>
					) :
						(
							<ModalComponent
								show={this.state.isModalOpen}
								handleClose={() => this.setState({ tag: "", isModalOpen: false })}>
								<CoOPForm
									PracticeID={this.props.history.location.state.PracticeEditID}
									Title={this.title}
									CoOpType={this.state.tag === "coop1" ? '1' : '2'}
									EditList={this.state.EdiUserList}
									closeModal={() => this.setState({ tag: "", isModalOpen: false })}
									handlebind={this.handleCoOPbind.bind(this)}
								/>
							</ModalComponent>
						)
				}
			</section>
		);
	}

	/**
	 * handleTabChange function is used to change tab
	 */
	handleTabChange = (tab) => {
		this.setState({ activeTab: tab, });
	};

	/**
	 * handleApplyFilter method is used to apply filter on every columns
	 */
	handleApplyFilter = (tag, values, type) => {
		if (values !== "") {
			let searchUsers = [];
			/**
			 * it will check if filter is apply from select than it will match exact keyword
			 * else it will find nearest word
			 */
			if (type === "select") {
				searchUsers = this.state.usersList.filter(
					(us) => us[tag].toLowerCase() === values.toLowerCase()
				);
			} else {
				searchUsers = this.state.usersList.filter(
					(us) => us[tag].toLowerCase().indexOf(values.toLowerCase()) > -1
				);
			}
			this.setState({ usersList: searchUsers, progress: false, });
		} else {
			this.wsGetUsers()
		}
	};

	handleApplyFilter1 = (tag, values, type) => {
		if (values !== "") {
			let searchCoOp = [];
			/**
			 * it will check if filter is apply from select than it will match exact keyword
			 * else it will find nearest word
			 */
			if (type === "select") {
				searchCoOp = this.state.practiceCoOpList.filter(
					(us) => us[tag].toLowerCase() === values.toLowerCase()
				);
			} else {
				searchCoOp = this.state.practiceCoOpList.filter(
					(us) => us[tag].toLowerCase().indexOf(values.toLowerCase()) > -1
				);
			}
			this.setState({ practiceCoOpList: searchCoOp, progress: false, });
		} else {
			this.wsGetPracticeCoOp()
		}
	};

	/**
 * handleModalOpen is used to open modal or close
 */
	handleModalOpen = (tag) => {
		this.setState({
			tag: tag,
			isModalOpen: !this.state.isModalOpen,
		});
	};

	handleNotification = (message, isError) => {

		this.setState({
			message: message,
			showNotification: true,
			isError: isError
		})

		setTimeout(() => {
			this.handleClearNotification();
		}, 4000)
	}

	handleClearNotification = () => {
		this.setState({
			message: '',
			showNotification: false,
			isError: false
		})
	}
}

export default Clinic;