/*eslint-disable eqeqeq*/
import React from 'react';
import * as APIController from "../../../../api/APIController.js";
import { Divider } from '@material-ui/core';
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PageTitle from "../../../../config/pageTitle";
import * as AppConstants from "../../../../config/AppConstant";

class OrderDetail extends React.Component {
    state = {
        list: [],
        order: [],
        progress: false,
        isLocationExpanded: false
    };

    componentDidMount() {
        this.wsGetOrderDetails(this.props.history.location.state.OrderIDDetails);
    }

    wsGetOrderDetails(ID) {
        this.setState({ progress: true, });
        APIController.GetOrderDetails(ID).then((response) => {
            if (response.error == null) {
                this.setState({
                    list: response.object.data.OrderDetails,
                    order: response.object.data.Order,
                    progress: false,
                });
            }
        });
    }

    onLocationExpand(bool, index) {
        this.setState({
            isLocationExpanded: bool, isLocationExpandedIndex: index
        })
    }

    wsEditCart(Locations, PracticeID, CampaignID, ProductID, CampaignThemeID) {
        let PracticeIDs = [];
        let data = JSON.parse(PracticeID)
        for (var i = 0; i < data.length; i++) {
            PracticeIDs.push(data[i].PracticeID)
        }
        let budgetDetail = [];
        budgetDetail.push({ budget: "", duration: "" })

        // AppConstants.SetLocalStorage('localStorageFromCart', '0')
        // AppConstants.SetLocalStorage('localStorageOrderDetailsID', response.object.data[0].OrderDetailsID)
        AppConstants.SetLocalStorage("localStorageShowAddToCart", 2)
        // AppConstants.SetLocalStorage('localStoragepracticeId', 0)
        AppConstants.SetLocalStorage('localStoragePracticeIDs', JSON.stringify(PracticeIDs))
        AppConstants.SetLocalStorage('localStorageCampaignID', CampaignID)
        AppConstants.SetLocalStorage('localStorageProductID', ProductID)
        AppConstants.SetLocalStorage('localStorageThemesID', CampaignThemeID)
        // AppConstants.SetLocalStorage("localStorageFreeAssetID", "")
        AppConstants.SetLocalStorage('localStorageCampaignReach', Locations)
        AppConstants.SetLocalStorage("localStorageBudgetDetail", JSON.stringify(budgetDetail))
        this.props.history.push('/digital-product/selection/adbreak')
    }


    render() {
        return (
            <>
                <section className='content-right-main'>
                    <PageTitle Title={AppConstants.ContactDetais.OrderDetails + this.props.history.location.state.OrderNo} />
                    {this.state.progress ? <CircularProgress /> :
                        <div className='two-col-sec'>
                            {this.state.list.map((obj, index) => {
                                return (
                                    <div className='cart-list-content'>
                                        <div style={{ width: '5%', margin: '0px 3% 0px 1%' }}>
                                            <img src={obj.ProductImageURL} alt='icon' />
                                        </div>
                                        <div style={{ display: 'grid', width: '100%', marginBottom: '1%' }}>
                                            <label className='cart-label-heading'>{obj.Product}</label>

                                            <label className='cart-label-subdata'>Campaign : {obj.CampaignName}</label>
                                            <label className='cart-label-subdata'>Campaign Budget : {obj.Budget.replace("/month", "")}</label>
                                            <label className='cart-label-subdata'>Duration : {obj.CampaignDurationInMonths}</label>

                                            <label className='cart-label-subdata-link' onClick={() => this.wsEditCart(obj.Locations, obj.PracticeID, obj.CampaignID, obj.ProductID, obj.CampaignThemeID)}>View Art Proof(s)</label>
                                            {this.state.isLocationExpanded == false ?
                                                <label className='cart-label-subdata-link' onClick={() => this.onLocationExpand(true, index)}>Show Location Details </label>
                                                : <label className='cart-label-subdata-link' onClick={() => this.onLocationExpand(false, index)}>Hide Location Details </label>}
                                            {this.state.isLocationExpanded == true && this.state.isLocationExpandedIndex == index &&
                                                JSON.parse(obj.Locations) != null ? JSON.parse(obj.Locations).map((obj, index) => {
                                                    return (
                                                        <div style={{ marginTop: '5px', display: 'flex' }}>
                                                            <LocationOnIcon style={{ fontSize: '18px' }} />&nbsp;
                                                            <label className='cart-label-location'>{obj.Location}</label>
                                                        </div>
                                                    );
                                                })
                                                : <></>}
                                        </div>
                                        <div style={{ display: 'grid', justifyItems: 'end' }}>
                                            <label className='cart-label-heading'>{obj.TotalAmount}</label>
                                        </div>
                                    </div>
                                )
                            })}

                            {this.state.order != undefined && this.state.order.map((obj, index) => {
                                return (
                                    <div className='cart-price-main'>
                                        <div style={{ marginLeft: '45%' }}>

                                            <div className='cart-total-div'>
                                                <label className='cart-subtotal-text'>Subtotal :</label>
                                                <label className='cart-subtotal-text'>{obj.TotalAmount}</label>
                                            </div>

                                            {obj.CoOpAmount != "$0.00" ?
                                                <div className='cart-total-div' >
                                                    <label className='cart-subtotal-text'>Less Co-Op :</label>
                                                    <label className='cart-subtotal-text'>- {obj.CoOpAmount}</label>
                                                </div> : <></>}

                                            <Divider style={{ width: '100%', height: '1px', borderRadius: '100px', marginTop: '1%' }} orientation="vertical" />

                                            <div className='cart-total-div'>
                                                <label className='cart-total-text'>Gross Total :</label>
                                                <label className='cart-total-text'>{obj.NetAmount}</label>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>}
                </section>
            </>
        )
    }
}

export default OrderDetail;