/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import close_ic from '../../../assets/images/close-ic.svg'
import * as yup from 'yup';

import Notification from '../../../shared/components/notification/notification';
import * as APIController from '../../../api/APIController';

const CoOPForm = props => {
    const initialValues = {
        coopAmount: '',
        coopRemarks: '',
    }

    // React.useEffect(() => {
    //     // if (props.UserID != '0') {
    //     //     wsGetUserByID(props.UserID)
    //     // }
    // }, [props])

    const [IsLoading, setIsLoading] = React.useState(false);

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);


    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)
        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    const wsAddRevokeCoOp = (PracticeID, Amount, Remarks, Type) => {
        setIsLoading(true)
        APIController.AddRevokeCoOp(PracticeID, Amount, Remarks, Type)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    if (response.object.status == 1) {
                        handleNotification(response.object.message)
                    } else {
                        handleNotification(response.object.message, true);
                    }
                    setTimeout(() => {
                        props.handlebind()
                    }, 4000)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    return (
        <div className="popup-box forgot-popup-box">
            <div className="box-heading">
                <h2 className="h1">{props.CoOpType == 1 ? "Add Co-OP" : "Revoke Co-Op"}</h2>
                <button className="close-btn"><img src={close_ic} alt="icon" onClick={props.closeModal} /></button>
            </div>
            <div className="box-content">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validateUsersForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        wsAddRevokeCoOp(props.PracticeID, values.coopAmount, values.coopRemarks, props.CoOpType)
                    }}
                >
                    {({ handleSubmit, values, setFieldValue, errors, touched, }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <div
                                    className={`form_field ${errors &&
                                        touched.coopAmount &&
                                        errors.coopAmount &&
                                        errors.coopAmount !== ''
                                        ? 'error'
                                        : ''
                                        }`}>
                                    <div style={{ textAlign: 'left' }} className="field-left">
                                        <label>Amount</label>
                                    </div>
                                    <div className="field-right">
                                        <Field type="text" placeholder="Enter Amount" id={'coopAmount'} name="coopAmount" value={values.coopAmount} maxLength="50" />
                                        {errors &&
                                            touched.coopAmount &&
                                            errors.coopAmount &&
                                            errors.coopAmount !==
                                            '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage
                                                        name='coopAmount'
                                                        render={(err) => {
                                                            return err;
                                                        }}
                                                    />
                                                </span>
                                            )}
                                    </div>
                                </div>
                                {/* <div className="form_field"> */}
                                <div
                                    className={`form_field ${errors &&
                                        touched.coopRemarks &&
                                        errors.coopRemarks &&
                                        errors.coopRemarks !== ''
                                        ? 'error'
                                        : ''
                                        }`}>
                                    <div style={{ textAlign: 'left' }} className="field-left">
                                        <label>Remarks</label>
                                    </div>
                                    <div className="field-right">
                                        <Field type="text" as="textarea" placeholder="Enter Remarks" id={'coopRemarks'} name="coopRemarks" value={values.coopRemarks} />
                                        {errors &&
                                            touched.coopRemarks &&
                                            errors.coopRemarks &&
                                            errors.coopRemarks !==
                                            '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage
                                                        name='coopRemarks'
                                                        render={(err) => {
                                                            return err;
                                                        }}
                                                    />
                                                </span>
                                            )}
                                    </div>
                                </div>
                                <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                    <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" />
                                </div>
                            </form>
                        )
                    }}
                </Formik>
            </div>
            <Notification
                isError={isError}
                message={message}
                showNotification={showNotification}
                clearNotification={handleClearNotification.bind(this)}
                closeNotification={handleClearNotification.bind(this)}
            />
        </div>

    )
};

const validateUsersForm = yup.object().shape({
    coopAmount: yup.string().required('Amount is required'),
    coopRemarks: yup.string().required('Remarks is required'),
});

export default CoOPForm;