/*eslint-disable eqeqeq*/
import React from "react";
import * as APIController from '../../../../../api/APIController.js';
import Table from "../../../../../shared/components/table/table";
import TableHead from "../../../../../shared/components/table/tableHead";
import TableRow from "../../../../../shared/components/table/tableRow";
import TableBody from "../../../../../shared/components/table/tableBody";
import TableCell from "../../../../../shared/components/table/tableCell";
import double_left_arrow from '../../../../../assets/images/left-doble-arow.svg';
import left_arrow from '../../../../../assets/images/left-arrow.svg';
import double_right_arrow from '../../../../../assets/images/right-doble-arow.svg';
import right_arrow from '../../../../../assets/images/right-arrow.svg';
import "react-perfect-scrollbar/dist/css/styles.css";

import PerfectScrollbar from "react-perfect-scrollbar";

import viewIcon from '../../../../../assets/images/view_icon.png'
import deleteIcon from '../../../../../assets/images/delete.svg'
import editIcon from '../../../../../assets/images/edit.svg'
import filtericon from '../../../../../assets/images/ic_feather_filter.svg'
import ConfirmtionDialog from '../../../../ConfirmDialog/ConfirmationDialog';
import Notification from '../../../../../shared/components/notification/notification';
import Modal from '../../../../../shared/components/modal/modal';
import CircularProgress from "../../../../../shared/components/CircularProgress/CircularProgress";
import PageTitle from "../../../../../config/pageTitle";

const BannersList = (props) => {

	const headerList = ['action', "Segment", "Is footer ?", "Is active ?"]
	const { currentPage,
		count,
		rowsPerPage,
		rowsPerPageOptions,
		list, to, from } = props;
	let bannerList = list;

	const [LoginDialog, setLoginDialog] = React.useState(false);
	const [DeleteId, setDeleteId] = React.useState('');

	// Segment, IsFooter, IsActive,

	const [Segment, setSegment] = React.useState('');
	const [IsFooter, setIsFooter] = React.useState('');
	const [IsActive, setIsActive] = React.useState('');

	const page = rowsPerPage == 1000000 ? 1 : Math.floor(count / rowsPerPage) + 1;
	// const page = Math.floor(count / rowsPerPage) + 1;

	const handleChangeInCurrentPage = (value) => {
		if (value <= page && Number(parseInt(value))) {
			props.handleChangePage(Number(value), Segment, IsFooter, IsActive);
		}
	}

	const wsDeleteBanner = (ID) => {
		APIController.DeleteBanner(ID)
			.then((response) => {
				if (response.error == null) {
					if (response.object.status == 1) {
						handleNotification(response.object.message);
					} else {
						handleNotification(response.object.message, true);
					}
					props.handlebind()
					setLoginDialog(false)
				}
				else if (response.error.message && response.error.message.length > 0) {
					handleNotification(response.error.message, true);
				}
			})
	}

	const [message, setMessage] = React.useState("");
	const [showNotification, setShowNotification] = React.useState(false);
	const [isError, setIsError] = React.useState(false);

	const handleNotification = (message, isError) => {
		setMessage(message)
		setShowNotification(true)
		setIsError(isError)

		setTimeout(() => {
			handleClearNotification();
		}, 4000)
	}

	const handleClearNotification = () => {
		setMessage('')
		setShowNotification(false)
		setIsError(false)
	}

	const _handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			props.applyFilter(Segment, IsFooter, IsActive)
		}
	}

	return (
		<>
			<h1><PageTitle Title="View Banners" /></h1>
			<div className="table-main-wrap">
				<PerfectScrollbar component='div'>
					<Table className="">
						<TableHead>
							<TableRow>
								{headerList.map((obj, index) => { return <TableCell style={index == 0 ? { width: '5%' } : headerList.length - 1 == index || headerList.length - 2 == index ? { width: '10%' } : { width: '25%' }} className={obj === "Is active ?" || "Is footer ?" ? 'center' : ''} key={index}>{obj}</TableCell>; })}
							</TableRow>
							<TableRow>
								<TableCell style={{ width: '5%' }}> <span><img src={filtericon} alt="delete" /></span> </TableCell>
								<TableCell style={{ width: '25%' }}> <input type="text" name="" placeholder="Name" onKeyDown={_handleKeyDown} onChange={(e) => setSegment(e.target.value)} /> </TableCell>
								<TableCell style={{ width: '10%' }}>
									<select onChange={(e) => { setIsFooter(e.target.value); props.applyFilter(Segment, e.target.value, IsActive) }}>
										<option value={""}>All</option>
										<option value={"1"}>Yes</option>
										<option value={"0"}>No</option>
									</select>
								</TableCell>
								<TableCell style={{ width: '10%' }}>
									<select onChange={(e) => { setIsActive(e.target.value); props.applyFilter(Segment, IsFooter, e.target.value) }}>
										<option value={""}>All</option>
										<option value={"1"}>Yes</option>
										<option value={"0"}>No</option>
									</select>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!!bannerList.length ? bannerList.map((obj, idnex) => {
								return (
									<TableRow key={idnex}>
										<TableCell style={{ width: '5%' }}>
											<span>
												<img src={editIcon} alt="edit" style={{ cursor: 'pointer', marginRight: '3px' }}
													onClick={() => {
														props.history.push({
															pathname: '/supplytool/banners/add',
															state: { bannerIdEdit: obj.ID, segmentEdit: obj.Segment, isFooterEdit: obj.IsFooterLABEL === "YES" ? 1 : 0, isActiveEdit: obj.IsActiveLABEL === "YES" ? 1 : 0, imageURLEdit: obj.ImageURL }
														})
													}} />
												<img src={deleteIcon} alt="delete" style={{ cursor: 'pointer', marginRight: '3px' }}
													onClick={() => { setDeleteId(obj.ID); setLoginDialog(true) }} />

												<img src={viewIcon} alt="view" style={{ cursor: 'pointer', marginRight: '3px' }}
													onClick={() => { window.open(obj.ImageURL) }} />
											</span>
										</TableCell>
										<TableCell style={{ width: '25%' }}> {obj.Segment} </TableCell>
										<TableCell style={{ width: '10%' }} className={obj.IsFooterLABEL === "YES" ? 'green' : 'red'}>
											<span>{obj.IsFooterLABEL === "YES" ? 'Yes' : 'No'}</span>
										</TableCell>
										<TableCell style={{ width: '10%' }} className={obj.IsActiveLABEL === "YES" ? 'green' : 'red'}>
											<span>{obj.IsActiveLABEL === "YES" ? 'Yes' : 'No'}</span>
										</TableCell>
									</TableRow>
								);
							}) : <TableRow>
								<TableCell colSpan={headerList.length} className="not_found">
									{props.progress ? <CircularProgress /> : <label>Not Currently Available</label>}
								</TableCell>
							</TableRow>}
						</TableBody>
					</Table>
				</PerfectScrollbar>
			</div>
			<Notification
				isError={isError}
				message={message}
				showNotification={showNotification}
				clearNotification={handleClearNotification.bind(this)}
				closeNotification={handleClearNotification.bind(this)}
			/>
			{LoginDialog && (
				<Modal
					show={LoginDialog}
					handleClose={() => setLoginDialog(false)}>
					<ConfirmtionDialog
						title={"Delete Banner"}
						description={"Are you sure you want to delete this Banner?"}
						deletePress={() => wsDeleteBanner(DeleteId)}
						closeModal={() => setLoginDialog(false)}
					/>
				</Modal>
			)}
			<div className="table-pagination">
				<div className="pagination-info">
					<p>Showing {from + 1} - {to > count ? count : to} of {count}</p>
				</div>
				<div className="pagination-control">
					<a href="#!" title="" className={`double-left-arrow ${props.currentPage == 1 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(1)
						}}
					><img src={double_left_arrow} alt="left" /></a>
					<a href="#!" title="" className={`simple-left-arrow ${props.currentPage == 1 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage - 1)
						}}
					><img src={left_arrow} alt="single_left" /></a>
					<span>Page</span>
					<input disabled type="text" name="textbox" value={currentPage === 0 ? currentPage + 1 : currentPage} onChange={(e) => handleChangeInCurrentPage(e.target.value)} />
					<span>of {page}</span>
					<a href="#!" title="" className={`simple-right-arrow ${currentPage == page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage + 1)
						}}
					><img src={right_arrow} alt="right" /></a>
					<a href="#!" title="" className={`double-right-arrow ${currentPage == page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(page)
						}}><img src={double_right_arrow} alt="single_right" /></a>
					<select onChange={(e) => props.handleChangeRowsPerPage(parseInt(e.target.value), Segment, IsFooter, IsActive)} defaultValue={rowsPerPage}>
						{rowsPerPageOptions.map((obj, index) => {
							return <option value={obj} key={index}>{obj}</option>
						})}
					</select>
				</div>
			</div>
		</>
	);
};

export default BannersList;
