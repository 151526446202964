import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'
// import './UploadExcelSheetForm.css'
import * as yup from 'yup';

const UploadForm = props => {
    const initialValues = {
        practicename: '',
        address1: '',
        address2: '',
        city: '',
        st: '',
        billto: '',
        vtm: '',
        territory: '',
        shipto: '',
        zipcode: '',
        customer: '',
        active: ''
    }

    return (
        <>
            <section className="content-right-main">
                <h1>Edit UploadExcelSheet</h1>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validateBranchForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        // wsAddEditCompanyBranch(props.history.location.state.branchesdEdit, "1", values.branchesname, values.active)
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        setFieldValue,
                        errors,
                        touched,
                    }) => {
                        return (
                            <div className="form-main">
                                <form onSubmit={handleSubmit}>
                                    <div
                                        className={`form_field ${errors &&
                                            touched.practicename &&
                                            errors.practicename &&
                                            errors.practicename !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Practice Name</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Practice Name" name="practicename" value={values.practicename} maxLength="50" />
                                            {errors &&
                                                touched.practicename &&
                                                errors.practicename &&
                                                errors.practicename !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='practicename'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>

                                    <div
                                        className={`form_field ${errors &&
                                            touched.address1 &&
                                            errors.address1 &&
                                            errors.address1 !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Address1</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Address1" name="address1" value={values.address1} maxLength="50" />
                                            {errors &&
                                                touched.address1 &&
                                                errors.address1 &&
                                                errors.address1 !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='address1'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={`form_field ${errors &&
                                            touched.address2 &&
                                            errors.address2 &&
                                            errors.address2 !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Address2</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Address2" name="address2" value={values.address2} maxLength="50" />
                                            {errors &&
                                                touched.address2 &&
                                                errors.address2 &&
                                                errors.address2 !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='address2'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={`form_field ${errors &&
                                            touched.city &&
                                            errors.city &&
                                            errors.city !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>City</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter City" name="city" value={values.city} maxLength="50" />
                                            {errors &&
                                                touched.city &&
                                                errors.city &&
                                                errors.city !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='city'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={`form_field ${errors &&
                                            touched.st &&
                                            errors.st &&
                                            errors.st !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>ST</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter ST" name="st" value={values.st} maxLength="50" />
                                            {errors &&
                                                touched.st &&
                                                errors.st &&
                                                errors.st !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='st'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={`form_field ${errors &&
                                            touched.billto &&
                                            errors.billto &&
                                            errors.billto !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Bill To</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Bill To" name="billto" value={values.billto} maxLength="50" />
                                            {errors &&
                                                touched.billto &&
                                                errors.billto &&
                                                errors.billto !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='billto'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={`form_field ${errors &&
                                            touched.vtm &&
                                            errors.vtm &&
                                            errors.vtm !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>VTM</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter VTM" name="vtm" value={values.vtm} maxLength="50" />
                                            {errors &&
                                                touched.vtm &&
                                                errors.vtm &&
                                                errors.vtm !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='vtm'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={`form_field ${errors &&
                                            touched.territory &&
                                            errors.territory &&
                                            errors.territory !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Territory</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Territory" name="territory" value={values.territory} maxLength="50" />
                                            {errors &&
                                                touched.territory &&
                                                errors.territory &&
                                                errors.territory !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='territory'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={`form_field ${errors &&
                                            touched.shipto &&
                                            errors.shipto &&
                                            errors.shipto !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Ship To</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Ship TO" name="shipto" value={values.shipto} maxLength="50" />
                                            {errors &&
                                                touched.shipto &&
                                                errors.shipto &&
                                                errors.shipto !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='shipto'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={`form_field ${errors &&
                                            touched.zipcode &&
                                            errors.zipcode &&
                                            errors.zipcode !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>ZipCode</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter ZipCode" name="zipcode" value={values.shipto} maxLength="50" />
                                            {errors &&
                                                touched.zipcode &&
                                                errors.zipcode &&
                                                errors.zipcode !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='zipcode'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={`form_field ${errors &&
                                            touched.customer &&
                                            errors.customer &&
                                            errors.customer !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Customer</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Customer" name="shipto" value={values.customer} maxLength="50" />
                                            {errors &&
                                                touched.customer &&
                                                errors.customer &&
                                                errors.customer !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='customer'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>

                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>VSP</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="yes-text" defaultValue={values.active} onClick={(e) => setFieldValue('active', true)} />
                                                <label htmlFor="yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="no-text" defaultValue={values.active} onClick={(e) => setFieldValue('active', false)} />
                                                <label htmlFor="no-text">No</label>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="form-submit">
                                        <div className="button-field">
                                            <input type="submit" value="save" name="save" />
                                        </div>
                                        <div className="button-field disable-btn">
                                            <input type="submit" value="save & add new" name="save & add new" />
                                        </div>
                                        <div className="button-field ">
                                            <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )
                    }}
                </Formik>
            </section>
        </>
    )
}
const validateBranchForm = yup.object().shape({
    practicename: yup.string().required('Practice Name is required'),
    address1: yup.string().required('Address1  is required'),
    address2: yup.string().required('Address2  is required'),
    city: yup.string().required('City Name  is required'),
    st: yup.string().required('ST Name  is required'),
    billto: yup.string().required('Bill To  is required'),
    vtm: yup.string().required('VTM  is required'),
    territory: yup.string().required('Territory  is required'),
    shipto: yup.string().required('Ship To is required'),
    zipcode: yup.string().required('Zip Code is required'),
    customer: yup.string().required('Customer is required'),
});

export default UploadForm;