import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import * as htmlToImage from 'html-to-image';
import uuid from 'react-uuid';

import Dropdown from '../../../shared/components/Dropdown/Dropdown';
import TextField from "../../../shared/components/TextField/TextField";
import Notification from '../../../shared/components/notification/notification';

import ModalComponent from "../../../shared/components/modal/modal";
import ConfirmationDialog from '../../ConfirmDialog/ConfirmationDialog';

import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";
import * as APIController from '../../../api/APIController.js';
import * as AppConstants from "../../../config/AppConstant";
import moment from 'moment';

class AnnualSupplyCalculatorContainer extends React.Component {

    constructor() {
        super();

        this.state = {
            headerImageURL: '',
            footerImageURL: '',

            LensesTypeData: [],
            LensesTypeAll: [],
            purchaseType: "1",
            lensType: true,
            patientName: '',
            patientEmailAddress: '',

            leftContactLensType: '0',
            leftNumberOfBoxes: 8,
            leftRetailPrice: 0,
            leftRebateValue: 0,
            leftAdditionalDiscount: "$0.00",
            leftInsuranceCoverage: "$0.00",
            leftPricePerBoxLeftEye: 0,
            leftPricePerBoxRightEye: 0,
            leftFinalValue: 0,

            rightContactLensType: '0',
            rightNumberOfBoxes: 0,
            rightRetailPrice: 0,
            rightRebateValue: 0,
            rightAdditionalDiscount: "$0.00",
            rightInsuranceCoverage: "$0.00",
            rightPricePerBoxLeftEye: 0,
            rightPricePerBoxRightEye: 0,
            rightFinalValue: 0,

            brandIDL: '',
            brandIDR: '',

            mainLoading: true,

            configurationsData: [],
            practiceDetails: [],

            message: '',
            showNotification: false,
            isError: false,
            emailLoading: false,
            printLoading: false,
            hideButtons: false,
            lensesTypeLoading: false,
            captchaImageData: '',

            confirmDialog: false,
            lenseTypesName: ''

        };
        this.selectorRef = React.createRef(null);
    }

    componentDidMount() {
        this.wsGetScreenConfigurations()
    }

    wsGetScreenConfigurations() {
        APIController.GetScreenConfigurations().then((response) => {
            if (response.error == null) {
                let headerTemp = [], footerTemp = [], lenseTypeTemp = [];

                headerTemp = response.object.data.Header.length && response.object.data.Header.filter(e => !e.IsFooter && response.object.data.Header)
                footerTemp = response.object.data.Header.length && response.object.data.Header.filter(e => e.IsFooter && response.object.data.Header)

                lenseTypeTemp = response.object.data.LensesType.length && response.object.data.LensesType.filter(e => e.IsDailyLense == this.state.lensType)

                this.setState({
                    headerImageURL: headerTemp[0].ImageURL,
                    footerImageURL: footerTemp[0].ImageURL,
                    LensesTypeData: lenseTypeTemp,
                    LensesTypeAll: response.object.data.LensesType,
                    configurationsData: response.object.data.Configurations,
                    practiceDetails: response.object.data.PracticeDetails,
                })
            }
            this.setState({ mainLoading: false })
        }).catch((err) => {
        })
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    leftSideValueCalculation(contactLensTypeL, brandIDL, contactLensTypeR, purchaseType, brandIDR) {
        if (this.state.configurationsData.length) {

            let AdditionalDiscountValueLeft = this.isNumberCheck(this.state.leftAdditionalDiscount.replace("$", ''));
            let InsuranceCoverageValueLeft = this.isNumberCheck(this.state.leftInsuranceCoverage.replace("$", ''));
            let AdditionalDiscountValueRight = this.isNumberCheck(this.state.rightAdditionalDiscount.replace("$", ''));
            let InsuranceCoverageValueRight = this.isNumberCheck(this.state.rightInsuranceCoverage.replace("$", ''));

            let AnnualSupplyLeftTemp = this.state.configurationsData.find((e) => e.LenseTypeID == contactLensTypeL && e.PurchaseType == purchaseType && e.MinimumQuantityForAnnualSupply == (this.state.lensType ? 8 : 4))
            let AnnualSupplyRightTemp = this.state.configurationsData.find((e) => e.LenseTypeID == contactLensTypeR && e.PurchaseType == purchaseType && e.MinimumQuantityForAnnualSupply == (this.state.lensType ? 4 : 2))

            let AnnualSupplyLeft = AnnualSupplyLeftTemp != undefined ? AnnualSupplyLeftTemp : {}
            let AnnualSupplyRight = AnnualSupplyRightTemp != undefined ? AnnualSupplyRightTemp : {}

            let RetailPriceLeft = 0, RetailPriceRight = 0, PriceParBoxLeftEye = 0, PriceParBoxRightEye = 0, FinalPriceLeft = 0;
            let PriceParBoxRightEyeL = 0, PriceParBoxRightEyeR = 0, FinalPriceRight = 0;

            RetailPriceLeft = (AnnualSupplyLeft.PricePerBox * (AnnualSupplyLeft.MinimumQuantityForAnnualSupply / 2) + AnnualSupplyRight.PricePerBox * (AnnualSupplyLeft.MinimumQuantityForAnnualSupply / 2))
            RetailPriceRight = (AnnualSupplyLeft.PricePerBox * (AnnualSupplyRight.MinimumQuantityForAnnualSupply / 2) + AnnualSupplyRight.PricePerBox * (AnnualSupplyRight.MinimumQuantityForAnnualSupply / 2))

            const tempRebateValueLeft = this.state.configurationsData.find((e) => e.LenseTypeID == contactLensTypeL && e.PurchaseType == purchaseType && e.MinimumQuantityForAnnualSupply == AnnualSupplyLeft.MinimumQuantityForAnnualSupply)
            const tempRebateValueRight = this.state.configurationsData.find((e) => e.LenseTypeID == contactLensTypeR && e.PurchaseType == purchaseType && e.MinimumQuantityForAnnualSupply == AnnualSupplyRight.MinimumQuantityForAnnualSupply)
            let rebateValueLeft = tempRebateValueLeft != undefined ? tempRebateValueLeft.Price : 0;
            let rebateValueLeftR = tempRebateValueRight != undefined ? tempRebateValueRight.Price : 0;

            let rebateValueLeftFinal = rebateValueLeft > rebateValueLeftR ? rebateValueLeft : rebateValueLeftR;
            let TotalValue = rebateValueLeftFinal + AdditionalDiscountValueLeft + InsuranceCoverageValueLeft

            if (RetailPriceLeft > TotalValue) {
                // =(((A4*A11)-((B23+B24+B25)/2))/A11)
                PriceParBoxLeftEye = (((AnnualSupplyLeft.PricePerBox * (AnnualSupplyLeft.MinimumQuantityForAnnualSupply / 2)) - (TotalValue / 2)) / (AnnualSupplyLeft.MinimumQuantityForAnnualSupply / 2))
                PriceParBoxLeftEye = PriceParBoxLeftEye > 0 ? PriceParBoxLeftEye : 0

                // =(((B4*A11)-((B23+B24+B25)/2))/A11)
                PriceParBoxRightEye = (((AnnualSupplyRight.PricePerBox * (AnnualSupplyLeft.MinimumQuantityForAnnualSupply / 2)) - (TotalValue / 2)) / (AnnualSupplyLeft.MinimumQuantityForAnnualSupply / 2))
                PriceParBoxRightEye = PriceParBoxRightEye > 0 ? PriceParBoxRightEye : 0

                FinalPriceLeft = (RetailPriceLeft - TotalValue)
            }
            if (RetailPriceRight > (AdditionalDiscountValueRight + InsuranceCoverageValueRight)) {
                PriceParBoxRightEyeL = (((AnnualSupplyLeft.PricePerBox * (AnnualSupplyLeft.MinimumQuantityForAnnualSupply / 4)) - (rebateValueLeftR + AdditionalDiscountValueRight + InsuranceCoverageValueRight) / 2)) / (AnnualSupplyLeft.MinimumQuantityForAnnualSupply / 4)
                PriceParBoxRightEyeL = PriceParBoxRightEyeL > 0 ? PriceParBoxRightEyeL : 0

                PriceParBoxRightEyeR = (((AnnualSupplyRight.PricePerBox * (AnnualSupplyRight.MinimumQuantityForAnnualSupply / 2)) - (rebateValueLeftR + AdditionalDiscountValueRight + InsuranceCoverageValueRight) / 2)) / (AnnualSupplyRight.MinimumQuantityForAnnualSupply / 2)
                PriceParBoxRightEyeR = PriceParBoxRightEyeR > 0 ? PriceParBoxRightEyeR : 0

                FinalPriceRight = (RetailPriceRight - (rebateValueLeftR + AdditionalDiscountValueRight + InsuranceCoverageValueRight))
            }

            this.setState({ leftNumberOfBoxes: AnnualSupplyLeft.MinimumQuantityForAnnualSupply, leftRetailPrice: RetailPriceLeft, leftPricePerBoxLeftEye: Math.ceil(PriceParBoxLeftEye), leftPricePerBoxRightEye: Math.ceil(PriceParBoxRightEye), leftFinalValue: FinalPriceLeft, leftRebateValue: rebateValueLeftFinal })
            this.setState({ rightNumberOfBoxes: 0, rightRetailPrice: RetailPriceRight, rightPricePerBoxLeftEye: Math.ceil(PriceParBoxRightEyeL), rightPricePerBoxRightEye: Math.ceil(PriceParBoxRightEyeR), rightFinalValue: FinalPriceRight, rightRebateValue: rebateValueLeftR })
        }
    }

    isNumberCheck(value) {
        return value == '' ? 0 : parseFloat(isFinite(value) ? value : 0)
    }

    wsAddEditLead = (ID, LeadType, PatientName, PatientEmailAddress, PurchaseType, IsDailyLense, ImageName, ImageData,
        L_LenseTypeID, L_TotalBoxes, L_RetailPrice, L_RebatePrice, L_AdditionalDiscount, L_InsuranceCoverage, L_PricePerBox, L_R_PricePerBox, L_YouPay,
        R_LenseTypeID, R_TotalBoxes, R_RetailPrice, R_RebatePrice, R_AdditionalDiscount, R_InsuranceCoverage, R_L_PricePerBox, R_PricePerBox, R_YouPay, LeadAt) => {

        APIController.AddEditSupplyCostCalculatorLead(ID, LeadType, PatientName, PatientEmailAddress, PurchaseType, IsDailyLense, ImageName, ImageData,
            L_LenseTypeID, L_TotalBoxes, L_RetailPrice, L_RebatePrice, L_AdditionalDiscount, L_InsuranceCoverage, L_PricePerBox, L_R_PricePerBox, L_YouPay,
            R_LenseTypeID, R_TotalBoxes, R_RetailPrice, R_RebatePrice, R_AdditionalDiscount, R_InsuranceCoverage, R_L_PricePerBox, R_PricePerBox, R_YouPay, LeadAt)
            .then((response) => {
                if (response.object.status == 1) {
                    if (LeadType == 2) {
                        this.wsDownloadAsset(response.object.data)
                    } else {
                        this.handleNotification(response.object.message);
                        this.setState({ emailLoading: false });
                    }
                }
                this.setState({ hideButtons: false })
            }).catch((err) => {
                this.setState({ hideButtons: false, emailLoading: false, printLoading: false });
            })
    }

    wsDownloadAsset = async (FileURL) => {
        const image = await fetch(FileURL);

        const imageBlog = await image.blob()
        const href = window.URL.createObjectURL(imageBlog);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', "Your B+L Annual Supply Savings.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.setState({ printLoading: false });
    };

    handleNotification = (message, isError) => {
        this.setState({ message: message, showNotification: true, isError: isError, });
        setTimeout(() => { this.handleClearNotification(); }, 4000);
    };
    handleClearNotification = () => {
        this.setState({ message: "", showNotification: false, isError: false, });
    };

    createDownloadImage = async (ID, LeadType, PatientName, PatientEmailAddress, PurchaseType, IsDailyLense,
        L_LenseTypeID, L_TotalBoxes, L_RetailPrice, L_RebatePrice, L_AdditionalDiscount, L_InsuranceCoverage, L_PricePerBox, L_R_PricePerBox, L_YouPay,
        R_LenseTypeID, R_TotalBoxes, R_RetailPrice, R_RebatePrice, R_AdditionalDiscount, R_InsuranceCoverage, R_L_PricePerBox, R_PricePerBox, R_YouPay) => {
        // if (PatientName == "") {
        //     this.handleNotification("Patient Name is required", true);
        // } else if (PatientEmailAddress.trim() == "") {
        //     this.handleNotification("Patient Email Address is required", true);
        // } else if (!PatientEmailAddress.trim().match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        //     this.handleNotification("Please Enter Valid Patient Email Address", true);
        // } else
        if (L_LenseTypeID == "" || L_LenseTypeID == '0') {
            this.handleNotification("Lense Type Left Eye is required", true);
        } else if (R_LenseTypeID == "" || R_LenseTypeID == '0') {
            this.handleNotification("Lense Type Right Eye is required", true);
        } else {
            this.setState({ hideButtons: true })
            const dataUrl = await htmlToImage.toJpeg(this.selectorRef.current, { quality: 1 });
            const link = document.createElement('a');
            link.href = dataUrl;
            if (LeadType == 2)
                this.setState({ captchaImageData: dataUrl, printLoading: true })
            // , () => { this.printDiv(); }
            else
                this.setState({ emailLoading: true });

            await this.wsAddEditLead(ID, LeadType, PatientName, PatientEmailAddress, PurchaseType, IsDailyLense, uuid() + '.png', link.href.split(',')[1],
                L_LenseTypeID, L_TotalBoxes, L_RetailPrice, L_RebatePrice, this.isNumberCheck(L_AdditionalDiscount.replace("$", '')), this.isNumberCheck(L_InsuranceCoverage.replace("$", '')), L_PricePerBox, L_R_PricePerBox, L_YouPay,
                R_LenseTypeID, R_TotalBoxes, R_RetailPrice, R_RebatePrice, this.isNumberCheck(R_AdditionalDiscount.replace("$", '')), this.isNumberCheck(R_InsuranceCoverage.replace("$", '')), R_L_PricePerBox, R_PricePerBox, R_YouPay, moment().format('MM-DD-YYYY hh:mm:ss A'))
        }
    }

    printDiv = () => {
        var mywindow = window.open("", "_blank", "height=400");
        mywindow.document.write(document.getElementById('printThisDiv').innerHTML);
        setTimeout(() => {
            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/
            mywindow.print();
            mywindow.close();
        }, 1000);

        return true;
    }

    resetAllValue(resetAll, lensesType) {
        if (resetAll == undefined) {
            this.setState({ lensesTypeLoading: false })
        } else if (resetAll) {
            this.setState({ purchaseType: '1', lensType: true, patientName: '', patientEmailAddress: '' })
        } else if (!resetAll) {
            this.setState({ LensesTypeData: this.state.LensesTypeAll.length && this.state.LensesTypeAll.filter(e => e.IsDailyLense == lensesType) })
            this.setState({ lensesTypeLoading: false })
        }
        this.setState({
            leftContactLensType: '0', leftRetailPrice: 0, leftRebateValue: 0, leftAdditionalDiscount: "$0.00", leftInsuranceCoverage: "$0.00", leftPricePerBoxLeftEye: 0, leftPricePerBoxRightEye: 0, leftFinalValue: 0,
            rightContactLensType: '0', rightNumberOfBoxes: 0, rightRetailPrice: 0, rightRebateValue: 0, rightAdditionalDiscount: "$0.00", rightInsuranceCoverage: "$0.00", rightPricePerBoxLeftEye: 0, rightPricePerBoxRightEye: 0, rightFinalValue: 0,
            brandIDL: ''
        })
        this.setState({ confirmDialog: false })
    }

    setValueInD(value) {
        return value.length <= 1 ? value.length == 1 && value == "$" ? value : "$" + value : value;
    }

    blankValue(value) {
        return this.isNumberCheck(value.replace("$", '')) == 0 || isNaN(this.isNumberCheck(value.replace("$", ''))) ? "$0.00" : value
    }

    render() {
        const { headerImageURL, footerImageURL, LensesTypeData, purchaseType, lensType, lensesTypeLoading } = this.state;

        const { leftContactLensType, leftNumberOfBoxes, leftRetailPrice, leftRebateValue, leftAdditionalDiscount, leftInsuranceCoverage, leftPricePerBoxLeftEye, leftPricePerBoxRightEye, leftFinalValue } = this.state;
        const { rightContactLensType, rightRetailPrice, rightRebateValue, rightAdditionalDiscount, rightInsuranceCoverage, rightPricePerBoxLeftEye, rightPricePerBoxRightEye, rightFinalValue } = this.state;

        const { patientName, patientEmailAddress, mainLoading, brandIDL, brandIDR, confirmDialog, practiceDetails } = this.state;
        return (
            <section className='content-right-main'>
                <div id="printThisDiv" style={{ display: 'none' }}>
                    <img src={this.state.captchaImageData} height="100%" width='100%' />
                </div>

                <div className="content-right-heading" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <>{"Annual Supply Calculator"}</>
                    {/* <div className="theme-option" onClick={(e) => { this.props.history.push('/supply-calculator-leads') }}> {"View Existing Leads"} </div> */}
                </div>
                <div className='two-col-sec digital-welcome' id='printablediv' ref={this.selectorRef}>
                    {mainLoading ? <CircularProgress />
                        : <>
                            <img style={{ resize: 'vertical', width: '100%' }} src={headerImageURL} alt='slider-2' />
                            {practiceDetails.length &&
                                <div className='dotted-border-main'>
                                    <label>{practiceDetails[0].CC_PracticeName}</label>
                                    <label style={{ color: '#646464', fontSize: '20px' }}>{practiceDetails[0].CC_Address1}</label>
                                    <label style={{ color: '#646464', fontSize: '20px' }}>{practiceDetails[0].CC_Address2}</label>
                                    <label style={{ color: '#646464', fontSize: '20px' }}>{practiceDetails[0].CC_City}, {practiceDetails[0].CC_State} {practiceDetails[0].CC_Postcode}</label>
                                    <label>{practiceDetails[0].CC_PhoneNo}</label>
                                </div>}

                            <label style={{ width: '100%', textAlign: 'center', fontSize: '23px', color: '#0f568c', marginBottom: '2%' }}>Here`s What Your Bausch + Lomb Contact Lenses Will Cost</label>
                            <div style={{ display: 'grid', width: "100%", gridTemplateColumns: '19% 39% 39%', gridGap: '0px 15px' }}>

                                {/* <div className={`field-full`} style={{ display: 'grid', justifyItems: 'end', alignContent: 'center' }}>
                                    <div className='calculator-main-tabView'>
                                        <div className={purchaseType == '1' ? 'calculator-tabView-active' : 'calculator-tabView-deActive'}
                                            onClick={() => { this.setState({ purchaseType: "1" }); this.resetAllValue() }}>New Wearer</div>
                                        <div className={purchaseType != '1' ? 'calculator-tabView-active' : 'calculator-tabView-deActive'}
                                            onClick={() => { this.setState({ purchaseType: "2" }); this.resetAllValue() }}>Existing Wearer</div>
                                    </div>
                                </div>
                                <div id={'patientName1'} className={`field-full`}>
                                    <TextField error={false} label="Patient's Name*" type='text' placeholder="Patient Name" name='patientName' value={patientName} onChange={this.handleChange} />
                                </div>
                                <div id={'patientEmailAddress1'} className={`field-full`}>
                                    <TextField error={false} label="Patient's Email Address*" type='text' placeholder="Patient Email Address" name='patientEmailAddress' value={patientEmailAddress} onChange={this.handleChange} />
                                </div> */}

                                <div className={`field-full`} style={{ display: 'grid', justifyItems: 'end', alignContent: 'center' }}>
                                    <div className='calculator-main-tabView'>
                                        <div className={lensType ? 'calculator-tabView-active' : 'calculator-tabView-deActive'}
                                            onClick={() => { this.setState({ lensesTypeLoading: true, lensType: true, leftNumberOfBoxes: 8 }); this.resetAllValue(false, true) }}>Daily Lens</div>
                                        <div className={!lensType ? 'calculator-tabView-active' : 'calculator-tabView-deActive'}
                                            onClick={() => { this.setState({ lensesTypeLoading: true, lensType: false, leftNumberOfBoxes: 4 }); this.resetAllValue(false, false) }}>Monthly Lens</div>
                                    </div>
                                </div>
                                {lensesTypeLoading ? <CircularProgress /> :
                                    <div id={'leftContactLensType1'} className={`field-full`} >
                                        {/* <div className='field-right-img'></div> */}
                                        <Dropdown id={'leftContactLensType'} name='leftContactLensType'
                                            value={leftContactLensType} select >
                                            <MenuItem value="0"> Contact Lens Type Left Eye </MenuItem>
                                            {LensesTypeData.map((item, index) =>
                                                <MenuItem key={index}
                                                    onClick={() => {
                                                        if (item.PricePerBox == 0) {
                                                            this.setState({ lenseTypesName: item.Name, confirmDialog: true, lensesTypeLoading: true, lensType: true })
                                                        } else {
                                                            this.setState({ rightContactLensType: item.ID, })
                                                            this.setState({ leftContactLensType: item.ID, brandIDL: item.BrandID, brandIDR: item.BrandID })
                                                            this.leftSideValueCalculation(item.ID, item.BrandID, item.ID, purchaseType, brandIDR == "" ? item.BrandID : brandIDR)
                                                        }
                                                    }}
                                                    selected={item.ID == leftContactLensType ? item.ID : item.Name} value={item.ID} >
                                                    {item.Name.split('®')[0]}{item.Name.match(/®/g) ? <sup>&reg;&nbsp;</sup> : ''}{item.Name.split('®')[1]}
                                                </MenuItem>
                                            )}
                                        </Dropdown>
                                    </div>}
                                {lensesTypeLoading ? <CircularProgress /> :
                                    <div id={'rightContactLensType1'} className={`field-full`} >
                                        {/* <div className='field-right-img'></div> */}
                                        <Dropdown id={'rightContactLensType'} name='rightContactLensType'
                                            value={rightContactLensType} select >
                                            <MenuItem value="0"> Contact Lens Type Right Eye </MenuItem>
                                            {LensesTypeData.map((item, index) =>
                                                <MenuItem key={index}
                                                    onClick={() => {
                                                        if (item.PricePerBox == 0) {
                                                            this.setState({ lenseTypesName: item.Name, confirmDialog: true, lensesTypeLoading: true, lensType: true })
                                                        } else {
                                                            leftContactLensType == "0" && this.setState({ leftContactLensType: item.ID })
                                                            this.setState({ rightContactLensType: item.ID, brandIDR: item.BrandID })
                                                            this.leftSideValueCalculation(leftContactLensType == '0' ? item.ID : leftContactLensType, brandIDL == '' ? item.BrandID : brandIDL, item.ID, purchaseType, item.BrandID)
                                                        }
                                                    }}
                                                    selected={item.ID == rightContactLensType ? item.ID : item.Name} value={item.ID} >
                                                    {item.Name.split('®')[0]}{item.Name.match(/®/g) ? <sup>&reg;&nbsp;</sup> : ''}{item.Name.split('®')[1]}
                                                </MenuItem>
                                            )}
                                        </Dropdown>
                                    </div>}

                                {/* <div className={`field-full`} /> */}
                                <div className={`field-full`} style={{ display: 'grid', justifyItems: 'end', alignContent: 'center' }}>
                                    <div className='calculator-main-tabView'>
                                        <div className={purchaseType == '1' ? 'calculator-tabView-active' : 'calculator-tabView-deActive'}
                                            onClick={() => { this.setState({ purchaseType: "1" }); this.resetAllValue() }}>New Wearer</div>
                                        <div className={purchaseType != '1' ? 'calculator-tabView-active' : 'calculator-tabView-deActive'}
                                            onClick={() => { this.setState({ purchaseType: "2" }); this.resetAllValue() }}>Existing Wearer</div>
                                    </div>
                                </div>
                                <div className={`field-full`} >
                                    <div style={{ padding: '5px 0px', borderRadius: '8px', border: '1px solid #d8dbe0', width: '100%', background: '#0f568c', color: '#FFF', textAlign: 'center', }} >
                                        <label style={{ fontSize: 21, fontWeight: 700, }}>ANNUAL SUPPLY</label>
                                        <br></br>
                                        {leftNumberOfBoxes != 0 && <div style={{ fontSize: 14, fontWeight: 600 }}>{leftNumberOfBoxes} Boxes</div>}
                                    </div>
                                </div>
                                <div className={`field-full`} >
                                    <div style={{ padding: '5px 0px', borderRadius: '8px', border: '1px solid #d8dbe0', width: '100%', background: '#0f568c', color: '#FFF', textAlign: 'center', }} >
                                        <label style={{ fontSize: 21, fontWeight: 700, }}>6 MONTH SUPPLY</label>
                                        <br></br>
                                        {leftNumberOfBoxes != 0 && <div style={{ fontSize: 14, fontWeight: 600 }}>{leftNumberOfBoxes / 2} Boxes</div>}
                                    </div>
                                </div>

                                <div className={`field-full`} style={{ display: 'grid', textAlign: 'end', alignContent: 'center' }} >
                                    <label> Retail Price: </label>
                                </div>
                                <div id={'leftRetailPrice'} className={`field-full`}>
                                    <TextField disabled error={false} type='text' name='leftRetailPrice' value={"$" + leftRetailPrice.toFixed(2)} />
                                </div>
                                <div id={'rightRetailPrice'} className={`field-full`}>
                                    <TextField disabled error={false} type='text' name='rightRetailPrice' value={"$" + rightRetailPrice.toFixed(2)} />
                                </div>

                                <div className={`field-full`} style={{ display: 'grid', textAlign: 'end', alignContent: 'center' }} >
                                    <label> Rebate Value<sup>†</sup>: </label>
                                </div>
                                <div id={'leftRebateValue'} className={`field-full`}>
                                    <TextField disabled error={false} type='text' name='leftRebateValue' value={"$" + leftRebateValue.toFixed(2)} />
                                </div>
                                <div id={'rightRebateValue'} className={`field-full`}>
                                    <TextField disabled error={false} type='text' name='rightRebateValue' value={"$" + rightRebateValue.toFixed(2)} />
                                </div>

                                <div className={`field-full`} style={{ display: 'grid', textAlign: 'end', alignContent: 'center' }} >
                                    <label> Additional Discount: </label>
                                </div>
                                <div id={'leftAdditionalDiscount'} className={`field-full`}>
                                    <TextField error={false} type='text' maxLength="7" name='leftAdditionalDiscount' value={leftAdditionalDiscount}
                                        onChange={(e) => this.setState({ leftAdditionalDiscount: this.setValueInD(e.target.value) })}
                                        onBlur={() => {
                                            this.setState({ leftAdditionalDiscount: this.blankValue(leftAdditionalDiscount) })
                                            leftContactLensType != '' && rightContactLensType != '0' && this.leftSideValueCalculation(leftContactLensType, brandIDL, rightContactLensType, purchaseType, brandIDR)
                                        }} />
                                </div>
                                <div id={'rightAdditionalDiscount'} className={`field-full`}>
                                    <TextField error={false} type='text' maxLength="7" name='rightAdditionalDiscount' value={rightAdditionalDiscount}
                                        onChange={(e) => this.setState({ rightAdditionalDiscount: this.setValueInD(e.target.value) })}
                                        onBlur={() => {
                                            this.setState({ rightAdditionalDiscount: this.blankValue(rightAdditionalDiscount) })
                                            leftContactLensType != '' && rightContactLensType != '0' && this.leftSideValueCalculation(leftContactLensType, brandIDL, rightContactLensType, purchaseType, brandIDR)
                                        }} />
                                </div>

                                <div className={`field-full`} style={{ display: 'grid', textAlign: 'end', alignContent: 'center' }} >
                                    <label> Insurance Coverage: </label>
                                </div>
                                <div id={'leftInsuranceCoverage'} className={`field-full`}>
                                    <TextField error={false} type='text' maxLength="7" name='leftInsuranceCoverage' value={leftInsuranceCoverage}
                                        onChange={(e) => this.setState({ leftInsuranceCoverage: this.setValueInD(e.target.value) })}
                                        onBlur={() => {
                                            this.setState({ leftInsuranceCoverage: this.blankValue(leftInsuranceCoverage) })
                                            leftContactLensType != '' && rightContactLensType != '0' && this.leftSideValueCalculation(leftContactLensType, brandIDL, rightContactLensType, purchaseType, brandIDR)
                                        }} />
                                </div>
                                <div id={'rightInsuranceCoverage'} className={`field-full`}>
                                    <TextField error={false} type='text' maxLength="7" name='rightInsuranceCoverage' value={rightInsuranceCoverage}
                                        onChange={(e) => this.setState({ rightInsuranceCoverage: this.setValueInD(e.target.value) })}
                                        onBlur={() => {
                                            this.setState({ rightInsuranceCoverage: this.blankValue(rightInsuranceCoverage) })
                                            leftContactLensType != '' && rightContactLensType != '0' && this.leftSideValueCalculation(leftContactLensType, brandIDL, rightContactLensType, purchaseType, brandIDR)
                                        }} />
                                </div>

                                <div className={`field-full`} style={{ display: 'grid', textAlign: 'end', alignContent: 'center' }} >
                                    <label> Price per Box Left Eye: </label>
                                    <div style={{ fontSize: 12, fontStyle: 'italic' }}>{"(after deductions)"}</div>
                                </div>
                                <div id={'leftPricePerBoxLeftEye'} className={`field-full`}>
                                    <TextField disabled error={false} type='text' name='leftPricePerBoxLeftEye' value={"$" + leftPricePerBoxLeftEye.toFixed(2)} />
                                </div>
                                <div id={'rightPricePerBoxLeftEye'} className={`field-full`}>
                                    <TextField disabled error={false} type='text' name='rightPricePerBoxLeftEye' value={"$" + rightPricePerBoxLeftEye.toFixed(2)} />
                                </div>

                                <div className={`field-full`} style={{ display: 'grid', textAlign: 'end', alignContent: 'center' }} >
                                    <label> Price per Box Right Eye: </label>
                                    <div style={{ fontSize: 12, fontStyle: 'italic' }}>{"(after deductions)"}</div>
                                </div>
                                <div id={'leftPricePerBoxRightEye'} className={`field-full`}>
                                    <TextField disabled error={false} type='text' name='leftPricePerBoxRightEye' value={"$" + leftPricePerBoxRightEye.toFixed(2)} />
                                </div>
                                <div id={'rightPricePerBoxRightEye'} className={`field-full`}>
                                    <TextField disabled error={false} type='text' name='rightPricePerBoxRightEye' value={"$" + rightPricePerBoxRightEye.toFixed(2)} />
                                </div>

                                <div className={`field-full`} style={{ display: 'grid', textAlign: 'end', alignContent: 'center' }} >
                                    <label> Here's What You'll Pay: </label>
                                    <div style={{ fontSize: 12, fontStyle: 'italic' }}>{"(FSA/HSA eligible)"}</div>
                                </div>
                                <div id={'leftFinalValue1'} className={`field-full`} style={{ borderColor: '#0f568c', }}>
                                    <TextField disabled error={false} type='text' name='leftFinalValue' value={"$" + leftFinalValue.toFixed(2)}
                                        inputProps={{ style: { color: '#0f568c', fontSize: 25, fontWeight: 700, padding: '15px 20px' } }} />
                                </div>
                                <div id={'rightFinalValue1'} className={`field-full`} style={{ borderColor: '#0f568c', }}>
                                    <TextField disabled error={false} type='text' name='rightFinalValue' value={"$" + rightFinalValue.toFixed(2)}
                                        inputProps={{ style: { color: '#0f568c', fontSize: 25, fontWeight: 700, padding: '15px 20px' } }} />
                                </div>

                            </div>
                            {!this.state.hideButtons &&
                                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', gridColumnEnd: 'span 2' }}>
                                    {/* <div className={this.state.emailLoading ? `button-field back-btn disable-btn` : `button-field back-btn`}>
                                            <input type='button' name='button' value={AppConstants.ButtonText.EmailResults}
                                                onClick={() => this.createDownloadImage(0, 1, patientName, patientEmailAddress, purchaseType, lensType,
                                                    leftContactLensType, leftNumberOfBoxes, leftRetailPrice, leftRebateValue, leftAdditionalDiscount, leftInsuranceCoverage, leftPricePerBoxLeftEye, leftPricePerBoxRightEye, leftFinalValue,
                                                    rightContactLensType, leftNumberOfBoxes / 2, rightRetailPrice, rightRebateValue, rightAdditionalDiscount, rightInsuranceCoverage, rightPricePerBoxLeftEye, rightPricePerBoxRightEye, rightFinalValue)} />
                                        </div> */}
                                    <div className={this.state.printLoading ? `button-field back-btn disable-btn` : `button-field back-btn`}>
                                        <input type='button' name='button' value={this.state.printLoading ? AppConstants.ButtonText.Downloading : AppConstants.ButtonText.Download}
                                            onClick={() => this.createDownloadImage(0, 2, patientName, patientEmailAddress, purchaseType, lensType,
                                                leftContactLensType, leftNumberOfBoxes, leftRetailPrice, leftRebateValue, leftAdditionalDiscount, leftInsuranceCoverage, leftPricePerBoxLeftEye, leftPricePerBoxRightEye, leftFinalValue,
                                                rightContactLensType, leftNumberOfBoxes / 2, rightRetailPrice, rightRebateValue, rightAdditionalDiscount, rightInsuranceCoverage, rightPricePerBoxLeftEye, rightPricePerBoxRightEye, rightFinalValue)} />
                                    </div>
                                    <div className='button-field back-btn'>
                                        <input type='button' name='button' value={AppConstants.ButtonText.Reset} onClick={() => { this.resetAllValue(true); this.setState({ leftNumberOfBoxes: 8 }) }} />
                                    </div>
                                </div>}


                            <img style={{ resize: 'vertical', width: '100%' }} src={footerImageURL} alt='slider-2' />
                        </>}
                </div>

                <ModalComponent show={confirmDialog}
                    handleClose={() => this.setState({ confirmDialog: false })}>
                    <ConfirmationDialog
                        title={"Invalid Retail Price"}
                        description={"Retail Price for the " + this.state.lenseTypesName + " is not Set in the Profile."}
                        btnText={'Set Price'}
                        deletePress={() => this.props.history.push('/calendar-account')}
                        closeModal={() => this.resetAllValue()} />
                </ModalComponent>

                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    closeNotification={this.handleClearNotification}
                />
            </section>
        )
    }
}

export default AnnualSupplyCalculatorContainer;