import React, { Fragment } from 'react';
function ListTitle(props) {
    const { Title, AdBilder } = props;
    return (
        <Fragment>
            <div className={AdBilder ? "content-right-heading-adbilder" : "content-right-heading"} >
                {Title}
                {/* {GetPageTitle()} */}
                {/* {/* <p>RapidTables &trade;</p> */}
                {/* {BrandName.replace('®', "<sup>&reg;</sup>")} */}
                {/* {BrandName.split('®')[0]}
					{BrandName.match(/®/g) ? <sup>&reg;</sup> : ''}
					{BrandName.split('®')[1]}{' '}
					{localStorage.getItem('localStorageCampaignName')} */}
            </div>
        </Fragment>
    );
}
export default (ListTitle);