import React from 'react';
import PageTitle from "../../../config/pageTitle";
import * as AppConstants from "../../../config/AppConstant";

const TermsofUse = () => {
    return (
        <>
            <section className='content-right-main'>
                <PageTitle Title={AppConstants.ContactDetais.PrivacyPolicyTitle} />
                <div className='two-col-sec' style={{ fontSize: '16px' }}>
                    <nowrap>
                        <div >
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#0000ff' }}>
                                A. COBRAND MEDIA TERMS OF SALE</span>
                        </div>
                        <div >
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#0000ff' }}>
                                B. COBRAND MEDIA CONDITIONS OF USE</span>
                        </div>
                        <div style={{ marginTop: "15px" }}>
                            <span style={{ fontWeight: 'bold', fontSize: '13px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                                THE LEGAL AGREEMENTS SET OUT BELOW GOVERNS YOUR USE OF COBRAND MEDIA SERVICES. IF YOU DO NOT
                                AGREE TO THESE TERMS, DO NOT USE THE SERVICES. REFERENCE TO COBRANDMEDIA.COM HEREIN, IS USED AND
                                REFERS TO ALL DOMAINS, PUBLIC AND PRIVATE, CUSTOM AND GENERIC, OPERATED BY COBRAND MEDIA, THAT
                                USE THESE TERMS AND CONDITIONS AS SHOWN ON THEIR RESPECTIVE WEBSITES.</span>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#0000ff' }}>
                                A. COBRAND MEDIA TERMS OF SALE</span>
                        </div>
                        <div>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                                PAYMENTS, TAXES, AND REFUND POLICY</span>
                        </div>
                        <div style={{ marginTop: "15px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6 }}>
                                CoBrand Media accepts these forms of payment: certain credit cards issued by U.S. banks, corporate Co-op credits, certain bank
                                account transfer payments, and Allowance Account balances as approved by, and paid for by the corporate sponsor. If a credit card
                                is being used for a transaction, CoBrand Media may obtain pre-approval for an amount up to the amount of the order. Billing occurs
                                at the time of or shortly after your transaction. If an Allowance Account is used for a transaction, the amount is deducted at the time
                                of your transaction. When making purchases, Allowance Account credits are used first. Your credit card is then charged for any remaining balance.</span>
                        </div>
                        <div style={{ marginTop: "15px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                                You agree that you will pay for all products you purchase through CoBrand Media, and that CoBrand Media may charge your credit
                                card for any products purchased and for any additional amounts (including any taxes and late fees, as applicable) that may be
                                accrued by or in connection with your Account. YOU ARE RESPONSIBLE FOR THE TIMELY PAYMENT OF ALL FEES AND FOR
                                PROVIDING COBRAND MEDIA WITH VALID CREDIT CARD DETAILS FOR PAYMENT OF ALL FEES. All fees will be billed to the
                                credit card you designate during the registration process. If you want to designate a different credit card or if there is a change in
                                your credit card status, you must change your information online; this may temporarily disrupt your access to our services while
                                CoBrand Media verifies your new payment information.</span>
                        </div>
                        <div style={{ marginTop: "15px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                                Your total price will include the price of the advertising plus any applicable local or state taxes in effect where your advertising is
                                published. We will charge tax only in states or localities where advertising is taxable.</span>
                        </div>
                        <div style={{ marginTop: "15px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                                All advertising sales are final. With the exception of errors or omissions by CoBrand Media or their service providers, any refund,
                                credit, or adjustment will be made at the sole discretion of CoBrand Media. CoBrand Media seeks to be fair and honest in all
                                business endeavors. If a dispute arises between an advertiser and CoBrand Media, please contact us at
                                CustomerService@CoBrandMedia.com immediately.</span>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                                COBRAND MEDIA LIABILITY</span>
                        </div>
                        <div style={{ marginTop: "15px" }}>
                            <span style={{ fontWeight: 'bold', fontSize: '13px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                                Liability for any error or omission shall be limited to the actual advertising charges for the item of advertising ordered.
                                COBRAND MEDIA WILL NOT BE LIABLE FOR ANY LOST PROFITS, COSTS OF PROCURING SUBSTITUTE GOODS OR
                                SERVICES, OR BUSINESS INTERRUPTION, OR FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR
                                SPECIAL DAMAGES ARISING OUT OF OR RELATED TO THIS AGREEMENT OR COBRAND MEDIA SERVICES,
                                REGARDLESS OF THE CAUSE OF ACTION (INCLUDING TORT, CONTRACT, NEGLIGENCE, STRICT LIABILITY AND
                                BREACH OF WARRANTY) EVEN IF COBRAND MEDIA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN
                                NO EVENT WILL COBRAND MEDIA’S TOTAL CUMULATIVE LIABILITY IN CONNECTION WITH THIS AGREEMENT OR
                                COBRAND MEDIA SERVICES, FROM ALL CAUSES OF ACTION OF ANY KIND, INCLUDING BUT NOT LIMITED TO TORT,
                                CONTRACT, NEGLIGENCE, STRICT LIABILITY AND BREACH OF WARRANTY, EXCEED THE TOTAL COST PAID FOR THE
                                SERVICES OR PRODUCTS.</span>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#0000ff' }}>
                                B. COBRAND MEDIA CONDITIONS OF USE</span>
                        </div>
                        <div style={{ marginTop: "15px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                                CoBrand Media provides website features to you subject to the following conditions. If you visit or place advertising orders at
                                CoBrandMedia.com, you accept these conditions. Please read them carefully. In addition, when you use any current or future
                                CoBrand Media service you also will be subject to the guidelines, terms and agreements ("Terms") applicable to such service or
                                business. If these conditions are inconsistent with such Terms, the Terms will control.</span>
                        </div>
                        <div style={{ marginTop: "15px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                                PRIVACY</span>
                        </div>
                        <div style={{ marginTop: "5px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                                Please review our Privacy Policy, which governs your visit to CoBrandMedia.com, to understand our practices.</span>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                                ELECTRONIC COMMUNICATIONS</span>
                        </div>
                        <div style={{ marginTop: "5px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                                When you visit CoBrandMedia.com or send e-mails to us, you are communicating with us electronically. You consent to receive
                                communications from us electronically. We will communicate with you by e-mail or by posting notices on this site. You agree that all
                                agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that
                                such communications be in writing.</span>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                                COPYRIGHT</span>
                        </div>
                        <div style={{ marginTop: "5px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                                All content included on this site, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data
                                compilations, and software, is the property of CoBrand Media or its content suppliers and protected by United States and
                                international copyright laws. The compilation of all content on this site is the exclusive property of CoBrand Media and protected by
                                U.S. and international copyright laws. All software used on this site is the property of CoBrand Media or its software suppliers and
                                protected by United States and international copyright laws.</span>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                                TRADEMARKS</span>
                        </div>
                        <div style={{ marginTop: "5px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                                CoBrandMedia.com graphics, logos, page headers, button icons, scripts, and service names are trademarks, or trade dress of
                                CoBrand Media in the U.S. and/or other countries. CoBrand Media's trademarks and trade dress may not be used in connection
                                with any product or service that is not CoBrand Media's, in any manner that is likely to cause confusion among customers, or in any
                                manner that disparages or discredits CoBrand Media. All other trademarks not owned by CoBrand Media that appear on this site are
                                the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by CoBrand Media.</span>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                                PATENTS</span>
                        </div>
                        <div style={{ marginTop: "5px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                                One or more patents owned by CoBrand Media may apply to this site and to the features and services accessible via the site. </span>
                        </div>
                        <div>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                                Portions of this site may operate under license of one or more patents.</span>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                                LICENSE AND SITE ACCESS</span>
                        </div>
                        <div style={{ marginTop: "5px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                                CoBrand Media grants you a limited license to access and make personal use of this site and not to download (other than page
                                caching) or modify it, or any portion of it, except with express written consent of CoBrand Media. This license does not include any
                                resale or commercial use of this site or its contents; any collection and use of any product listings, descriptions, or prices; any
                                derivative use of this site or its contents; any downloading or copying of account information for the benefit of another agency,
                                merchant, or service provider; or any use of data mining, robots, or similar data gathering and extraction tools. This site or any
                                portion of this site may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial
                                purpose without express written consent of CoBrand Media. You may not frame or utilize framing techniques to enclose any
                                trademark, logo, or other proprietary information (including images, text, page layout, or form) of CoBrand Media without express
                                written consent. You may not use any Meta tags or any other "hidden text" utilizing CoBrand Media's name or trademarks without
                                the express written consent of CoBrand Media. Any unauthorized use terminates the permission or license granted by CoBrand
                                Media. You are granted a limited, revocable, and nonexclusive right to create a hyperlink to the home page of CoBrandMedia.com
                                so long as the link does not portray CoBrand Media, or its products or services in a false, misleading, derogatory, or otherwise
                                offensive matter. You may not use any CoBrand Media logo or other proprietary graphic or trademark as part of the link without
                                express written permission.</span>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                                YOUR ACCOUNT</span>
                        </div>
                        <div style={{ marginTop: "5px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                                If you use this site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to
                                your computer, and you agree to accept responsibility for all activities that occur under your account or password. CoBrand Media
                                reserves the right to refuse service, terminate accounts, remove or edit content, or cancel orders in their sole discretion.</span>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <span style={{ fontSize: '13px', letterSpacing: '0.3px', lineHeight: 1.6, fontWeight: 'bold', color: '#000000' }}>
                                DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY
                                THIS SITE AND ALL INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) AND SERVICES
                                INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THIS SITE ARE PROVIDED BY COBRAND MEDIA ON
                                AN "AS IS" AND "AS AVAILABLE" BASIS, UNLESS OTHERWISE SPECIFIED IN WRITING. COBRAND MEDIA MAKES NO
                                REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THIS SITE OR
                                THE INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR SERVICES INCLUDED ON OR
                                OTHERWISE MADE AVAILABLE TO YOU THROUGH THIS SITE, UNLESS OTHERWISE SPECIFIED IN WRITING. YOU
                                EXPRESSLY AGREE THAT YOUR USE OF THIS SITE IS AT YOUR SOLE RISK.</span>
                        </div>
                        <div style={{ marginTop: "5px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, fontWeight: 'bold', color: '#000000' }}>
                                TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, COBRAND MEDIA DISCLAIMS ALL WARRANTIES, EXPRESS
                                OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF ADVERTISING EFFECTIVENESS. COBRAND
                                MEDIA DOES NOT WARRANT THAT THIS SITE; INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING
                                SOFTWARE) OR SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THIS SITE; THEIR
                                SERVERS; OR ELECTRONIC COMMUNICATIONS SENT FROM COBRAND MEDIA ARE FREE OF VIRUSES OR OTHER
                                HARMFUL COMPONENTS. COBRAND MEDIA WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE
                                USE OF THIS SITE OR FROM ANY INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR
                                SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THIS SITE, INCLUDING, BUT NOT
                                LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE
                                SPECIFIED IN WRITING.</span>
                        </div>
                        <div style={{ marginTop: "5px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, fontWeight: 'bold', color: '#000000' }}>
                                CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF
                                CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR
                                LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.</span>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                                DISPUTES</span>
                        </div>
                        <div style={{ marginTop: "5px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                                In the event of a dispute or non-payment arising out of the performance or interpretation of this Agreement, the parties agree that
                                any action to enforce this Agreement shall be by binding arbitration in the County of San Diego, California, unless mutually agreed
                                otherwise. The initiating party shall choose the arbitration firm to be used, and that firm must be accredited to conduct binding
                                arbitration in the State of California. The decision of this arbitrator shall be binding as a decision rendered by the Superior Court. </span>
                        </div>
                        <div style={{ marginTop: "5px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                                Each party shall be responsible for its share of the arbitration fees. In the event a party fails to proceed with arbitration,
                                unsuccessfully challenges the arbitrator's award, or fails to comply with the arbitrator's award, the other party is entitled to costs of
                                suit, including a reasonable attorney's fee for having to compel arbitration or defend or enforce the award. The parties further agree
                                that the arbitrator and/or any subsequent court of competent jurisdiction shall award to the prevailing party and against the losing
                                party, reasonable attorney's fees and costs, including, but not limited to, collection fees and charges, investigation fees, collection
                                management fees, court expenses, and arbitration costs.</span>
                        </div>
                        <div style={{ marginTop: "5px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                                Any dispute or claim relating in any way to your visit to CoBrandMedia.com or to products or advertising services sold by CoBrand
                                Media or through CoBrandMedia.com will be resolved by binding arbitration, rather than in court, in the County of San Diego,
                                California, except that either party may assert claims in Small Claims Court in San Diego County if those claims qualify. The
                                decision of an arbitrator shall be binding as a decision rendered by the Superior Court. The parties further agree that the arbitrator
                                and/or any subsequent court of competent jurisdiction shall award to the prevailing party and against the losing party, reasonable
                                attorney's fees and costs, including, but not limited to, collection fees and charges, investigation fees, collection management fees,
                                court expenses, and arbitration costs.</span>
                        </div>
                        <div style={{ marginTop: "5px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                                We also mutually agree that you or we may bring suit in court to enjoin infringement or other misuse of intellectual property rights.</span>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                                APPLICABLE LAW</span>
                        </div>
                        <div style={{ marginTop: "5px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                                By visiting CoBrandMedia.com or one of its custom corporate branded websites, you agree that applicable federal law and the laws
                                of the State of California, without regard to principles of conflict of laws, will govern these Conditions of Use and any dispute of any
                                sort that might arise between you and CoBrand Media.</span>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                                SITE POLICIES, MODIFICATION, AND SEVERABILITY</span>
                        </div>
                        <div style={{ marginTop: "5px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                                Please review our other policies posted on this site. These policies also govern your visit to CoBrandMedia.com. We reserve the
                                right to make changes to our site, policies, and these Conditions of Use at any time. If any of these conditions shall be deemed
                                invalid, void, or for any reason unenforceable, that condition shall be deemed severable and shall not affect the validity and
                                enforceability of any remaining condition.</span>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                                CHANGES TO OUR CONDITIONS OF USE POLICY</span>
                        </div>
                        <div style={{ marginTop: "5px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                                If we decide to change our Conditions of Use policy, we will update the modification date below.</span>
                        </div>
                        <div >
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                                This policy was last modified on December 3, 2018</span>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                                YOUR CONSENT</span>
                        </div>
                        <div style={{ marginTop: "5px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                                By using our site, you consent to our Conditions of Use policy.</span>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                                CONTACTING US</span>
                        </div>
                        <div style={{ marginTop: "5px" }}>
                            <span style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                                If there are any questions regarding this Conditions of Use policy you may contact us using the information below.</span>
                        </div>
                        <div style={{ marginTop: "5px" }}>
                            <span id="_9.8" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                                CoBrand Media <span id="_11.8" style={{ fontFamily: 'Raleway', fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}></span> AB Advertising <span id="_11.8" style={{ fontFamily: 'Raleway', fontSize: '11.8px' }}></span> Avenida Encinas, Suite 100 <span id="_11.8" style={{ fontFamily: 'Raleway', fontSize: '11.8px' }}>
                                </span> California 92008 </span>

                        </div>
                    </nowrap>
                </div>
            </section>
        </>
    );
}


export default TermsofUse