import React from "react";
import { useState } from "react";
import * as APIController from "../../../api/APIController.js";
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";
import * as AppConstants from '../../../config/AppConstant';
const ProductSelection = (props) => {

	const [BrandName, setBrandName] = React.useState(AppConstants.GetLocalStorage('localStorageBrandName'));
	const [dataSource, setdataSource] = React.useState([]);
	const [Loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		wsGetCampaignProducts(AppConstants.GetLocalStorage("localStorageCampaignID"))
		AppConstants.SetLocalStorage("localStorageBrandsData", [])
	}, [props])

	const wsGetCampaignProducts = (ID) => {
		setLoading(true);
		APIController.GetCampaignProducts(ID)
			.then((response) => {
				if (response.object.status == '1') {
					setdataSource(response.object.data)
				}
				else if (response.object.message && response.object.message.length > 0) {
					// handleNotification(response.error.message);
				}
				setLoading(false);
			})
	}

	const [hoverDiv, setHoverDiv] = useState(null);

	const CardComponent = () => {
		return (
			<>{Loading ? (
				<CircularProgress />
			) : dataSource.length == 0 ? (
				<label>Not Currently Available</label>
			) : (
				dataSource.map((obj, index) => {
					return (
						<div className='block-col' key={index} id={obj.ID}
							onMouseEnter={() => { setHoverDiv(index); }}
							onMouseLeave={() => { setHoverDiv(null); }}
							onClick={() => {
								if (obj.PageURL != null && obj.PageURL != '' && obj.PageURL != undefined) {

									props.history.push(obj.PageURL)
									AppConstants.SetLocalStorage("localStorageProductID", obj.Children != null && obj.Children.length ? JSON.parse(obj.Children)[0].ID : obj.ID)
									AppConstants.SetLocalStorage("localStorageMultiSelectPractice", obj.Children != null && obj.Children.length ? JSON.parse(obj.Children)[0].AllowMultiSelectPractice : true)
									AppConstants.SetLocalStorage("localStorageAssetTabName", obj.Children != null && obj.Children.length ? JSON.parse(obj.Children)[0].TabName : "")
									AppConstants.SetLocalStorage("localStorageProductName", obj.Name)
									AppConstants.SetLocalStorage("localStorageISDirectMail", obj.Name == "Direct Mail" ? 1 : 0)
									AppConstants.SetLocalStorage("localStorageMenuPath", obj.MenuPath)
									AppConstants.SetLocalStorage("localStorageShowAddToCart", obj.ShowAddToCart)
									AppConstants.SetLocalStorage("localStorageShowCopyText", obj.ShowCopyText)
									AppConstants.SetLocalStorage("localStorageThemeButtons", obj.ThemeButtons)
									AppConstants.SetLocalStorage("localStorageFromCart", 1)
									AppConstants.SetLocalStorage('localStorageOrderDetailsID', 0)
									AppConstants.SetLocalStorage("localStorageBudgetDetail", obj.PageURL == "/digital-product/selection/location-selection" ? JSON.stringify([{ "budget": 1, "duration": 1 }]) : JSON.stringify([{ "budget": 1, "duration": "" }]))
									AppConstants.SetLocalStorage("localStorageAutomation", "NO")
									AppConstants.SetLocalStorage("localStorageChildren", obj.Children != null && obj.Children)
									AppConstants.SetLocalStorage("localStoragepracticeId", 0)
									AppConstants.SetLocalStorage("localStorageAutomationSubscriptionID", 0)
									AppConstants.SetLocalStorage("localStorageFBPageData", '')
									AppConstants.SetLocalStorage("SocialAutomationAllLocations", '')
									AppConstants.SetLocalStorage("localStorageInstaUserData", '')
									AppConstants.SetLocalStorage("localStorageCampaignProducts", [])
									AppConstants.SetLocalStorage("localStorageCampaignStartDate", AppConstants.GetCurrentDate)
									AppConstants.SetLocalStorage("localStorageCampaignEndDate", '')
									AppConstants.SetLocalStorage("localStorageBudgetProducts", "")

								}
							}} >
							<a style={{ cursor: 'pointer' }} className='hover_link'>
								<img src={hoverDiv === index ? obj.HoverImageURL : obj.ImageURL} alt='icon' />
								<div style={{ height: "40px" }}>
									<p> {obj.TagLine.replace("<br />", '\n')} </p>
								</div>
								<h2>{obj.Name}</h2>
							</a>
						</div>
					);
				})
			)}</>
		);
	};

	return (
		<section className='content-right-main'>
			{/* {AppConstants.ShowPageTitle()} */}
			{BrandName != undefined &&
				<div className="content-right-heading">
					{BrandName.split('®')[0]}
					{BrandName.match(/®/g) ? <sup>&reg;</sup> : ''}
					{BrandName.split('®')[1]}{' '}
					{AppConstants.GetLocalStorage('localStorageCampaignName')}
				</div>
			}
			<section className='three-block-sec'>
				<CardComponent {...props} />
			</section>
		</section>
	);
};

export default ProductSelection;
