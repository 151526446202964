/*eslint-disable eqeqeq*/
import { Component } from 'react';

import authService from "../shared/services/auth-service";
/**
 * HTTP method types
 */
export const HTTPMethod = {
    get: "GET",
    post: "POST",
    put: "PUT",
    delete: "DELETE",
}

/**
 * Default HTTP header content types
 */
export const HTTPHeaderContentType = {
    json: "application/json",
    formURLEncoded: "application/x-www-form-urlencoded",
}

/**
 * A network utility class that performs all the necessary underlying HTTP piping and server authentication work for all HTTP request
 */
export class API extends Component {

    /**
     * Creates a promise that wraps an HTTP request configured with given parameters.
     * 
     * @param {String} method HTTP method
     * @param {String} url The target HTTP request URL
     * @param {props} body The body of the HTTP request
     */
    request(method, url, body) {
        return new Promise((resolve, reject) => {

            let headers = method.indexOf("token") == -1 ? this.requestHeaders() : this.requestTokenHeaders()
            fetch(url, { method: method, headers: headers, body: body })
                .then((response) => {
                    if (response.headers.get('content-type').indexOf('image') != -1 || response.headers.get('content-type').indexOf('html') != -1
                        || response.headers.get('content-type').indexOf('x-zip-compressed') != -1 || response.headers.get('content-type').indexOf('application/pdf') != -1 || response.headers.get('content-type').indexOf('video/mp4') != -1) {
                        return response
                    } else {
                        return response.json()
                    }
                })
                .then((responseJSON) => {
                    if (responseJSON.Message == "Authorization has been denied for this request.") {
                        authService.removeAuthData();
                        localStorage.clear()
                        alert("Authorization has been denied for this request.")
                        window.location.reload();
                    } else {
                        resolve(responseJSON)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
    request1(method, url) {
        return new Promise((resolve, reject) => {
            let headers = this.requestHeaders()
            fetch(url, { method: method, headers: headers })
                .then((response) => { return response.json() })
                .then((responseJSON) => { resolve(responseJSON) })
                .catch((error) => {
                    reject(error)
                })
        })
    }
    request2(method, url, body) {
        return new Promise((resolve, reject) => {
            let headers = this.requestHeaders()
            fetch(url, { method: method, headers: {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
            }, body: body })
                .then((response) => { return response.json() })
                .then((responseJSON) => { resolve(responseJSON) })
                .catch((error) => {
                    reject(error)
                })
        })
    }
    requestTokenHeaders() {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }

    /**
     * Builds and returns a prop containing HTTP Header key-value pairs with the appropriate values populated.
     */
    requestHeaders() {
        return {
            "Accept": HTTPHeaderContentType.json,
            "Content-Type": HTTPHeaderContentType.json,
            "Authorization": "Bearer " + sessionStorage.getItem('Access_Token')
        }
    }
}

API.shared = new API()