/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'
// import './positionForm.css'
import * as APIController from '../../../../api/APIController.js';
import * as yup from 'yup';

import Notification from '../../../../shared/components/notification/notification';

const PositionForm = props => {
    const initialValues = {
        positionsName: props.history.location.state.positionsNameEdit,
        active: props.history.location.state.positionsActiveEdit,
    }
    const [isButtonValue, setButtonValue] = React.useState(false);
    const [IsLoading, setIsLoading] = React.useState(false);

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    const wsAddEditPosition = (ID, Name, CompanyID, IsActive) => {
        setIsLoading(true)
        APIController.AddEditPosition(ID, Name, CompanyID, IsActive)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    handleNotification(response.object.message);
                    if (isButtonValue === true && response.object.status == 1) {
                        props.history.push({ pathname: '/positions', state: { showMessage: response.object.message } })
                    } else if (response.object.status == 1) {
                        handleNotification(response.object.message);
                    } else {
                        handleNotification(response.object.message, true);
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    return (
        <>
            <section className="content-right-main">
                {props.history.location.state.positionsIdEdit != 0 ? <h1>Edit Position</h1> : <h1>Add Position</h1>}
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validatePositionsForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        wsAddEditPosition(props.history.location.state.positionsIdEdit, values.positionsName, "2", values.active)
                        if (isButtonValue === false) {
                            values.positionsName = "";
                            values.active = true;
                        }
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        setFieldValue,
                        errors,
                        touched,
                    }) => {
                        return (
                            <div className="form-main">
                                <form onSubmit={handleSubmit}>
                                    <div
                                        className={`form_field ${
                                            errors &&
                                                touched.positionsName &&
                                                errors.positionsName &&
                                                errors.positionsName !== ''
                                                ? 'error'
                                                : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Position</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Position" name="positionsName" value={values.positionsName} maxLength="50" />
                                            {errors &&
                                                touched.positionsName &&
                                                errors.positionsName &&
                                                errors.positionsName !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='positionsName'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Is Active ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="yes-text"
                                                    defaultValue={values.active}
                                                    defaultChecked={values.active != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 1)} />
                                                <label htmlFor="yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="no-text"
                                                    defaultValue={values.active}
                                                    defaultChecked={values.active == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 0)} />
                                                <label htmlFor="no-text">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <Notification
                                        isError={isError}
                                        message={message}
                                        showNotification={showNotification}
                                        clearNotification={handleClearNotification.bind(this)}
                                        closeNotification={handleClearNotification.bind(this)}
                                    />
                                    <div className="form-submit">
                                        <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" onClick={() => setButtonValue(true)} />
                                        </div>
                                        {props.history.location.state.positionsIdEdit == 0 ?
                                            <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                                <input type="submit" value={IsLoading ? "Saving..." : "save & add new"} name="save & add new" onClick={() => setButtonValue(false)} />
                                            </div> : null}
                                        <div className="button-field ">
                                            <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )
                    }}
                </Formik>
            </section>
        </>
    )
}
const validatePositionsForm = yup.object().shape({
    positionsName: yup.string().required('Position is required'),
});
export default PositionForm;