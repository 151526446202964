/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'
// import './productForm.css'
import * as APIController from '../../../../api/APIController.js';
import * as yup from 'yup';
import uuid from 'react-uuid';
import file_upload_ic from "../../../../assets/images/file-upload-ic.svg";
import close_icon from "../../../../assets/images/close_icon.svg";

import Editor from '../../../campaign/components/editor';

import Notification from '../../../../shared/components/notification/notification';
import CircularProgress from '@material-ui/core/CircularProgress';

import PerfectScrollbar from "react-perfect-scrollbar";
import deleteIcon from "../../../../assets/images/delete.svg";
import editIcon from "../../../../assets/images/edit.svg";
import previewIcon from "../../../../assets/images/blue-preview.svg";
import { TrendingUpRounded } from '@material-ui/icons';

import ModalComponent from '../../../../shared/components/modal/modal';


const ProductChildrenForm = props => {
    const initialValues = { tabName: "", childsequenceno: "", productid: "" }
    const [IsLoading, setIsLoading] = React.useState(false);
    const [IsLoadingData, setIsLoadingData] = React.useState(true);

    const [sourceSequence, setsourceSequence] = React.useState([]);
    const [sourceProducts, setsourceProducts] = React.useState([]);
    const [childrensData, setchildrensData] = React.useState([]);

    const [ChildrenID, setChildrenID] = React.useState(0);
    const [ParentProductID, setParentProductID] = React.useState(props.history.location.state.productIdEdit);

    React.useEffect(() => {
        wsGetProductChildren(ParentProductID)

        var data = [];
        for (let i = 0; i < 10; i++) {
            data.push({ ID: i + 1 });
        }
        setsourceSequence(data)

    }, [props])

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    const wsGetProductChildren = (ProductID) => {
        APIController.GetProductChildren(ProductID)
            .then((response) => {
                if (response.error == null) {
                    setchildrensData(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
                setIsLoadingData(false)
                wsGetProductForDropdown();
            })
    }
    const wsGetProductForDropdown = () => {
        APIController.GetProductForDropdown()
            .then((response) => {
                if (response.error == null) {
                    setsourceProducts(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const wsAddEditProductChildren = (ID, ParentProductID, TabName, TabSequence, ProductID) => {
        setIsLoading(true)
        APIController.AddEditProductChildren(ID, ParentProductID, TabName, TabSequence, ProductID)
            .then((response) => {
                if (response.error == null) {
                    if (response.object.status == 1) {
                        handleNotification(response.object.message);
                        wsGetProductChildren(ParentProductID)

                        initialValues.tabName = ""
                        initialValues.childsequenceno = ""
                        initialValues.productid = ""
                    } else {
                        handleNotification(response.object.message, true);
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
                setIsLoading(false)
            })
    }

    const wsDeleteProductChildren = (ID) => {
        APIController.DeleteProductChildren(ID)
            .then((response) => {
                if (response.object.status == "1") {
                    handleNotification(response.object.message);
                    wsGetProductChildren(ParentProductID)
                } else {
                    handleNotification(response.object.message, true);
                }
            })
    }

    return (
        <>
            <section className="tab-content asset-tab-wrapper">
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validateProductForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        wsAddEditProductChildren(ChildrenID, ParentProductID, values.tabName, values.childsequenceno, values.productid)
                    }}
                >
                    {({ values, handleSubmit, setFieldValue, errors, touched }) => {
                        return (
                            <div className="form-main" style={{ padding: '0px', boxShadow: '0px 0px 0px' }}>
                                <form onSubmit={handleSubmit} style={{ maxWidth: '100%' }}>
                                    <div className="asset-tab-content">
                                        <div className="asset-tab-left">

                                            <div className={`tab_field ${errors && touched.tabName && errors.tabName && errors.tabName !== '' ? 'error' : ''}`}>
                                                <div className="field-right" style={{ width: '100%' }}>
                                                    <Field type="text" placeholder="Enter Tab Name" name="tabName" value={values.tabName} maxLength="50" />
                                                    {errors && touched.tabName && errors.tabName && errors.tabName !== '' && (
                                                        <span className='error-msg'>
                                                            <ErrorMessage name='tabName' render={(err) => { return err; }} />
                                                        </span>
                                                    )}
                                                </div>
                                            </div>

                                            <div className={`tab_field ${errors && touched.childsequenceno && errors.childsequenceno && errors.childsequenceno !== '' ? 'error' : ''}`}>
                                                <div className="field-right" style={{ width: '100%' }}>
                                                    <select
                                                        id={'childsequenceno'}
                                                        name="reportType"
                                                        defaultValue={values.childsequenceno}
                                                        value={values.childsequenceno}
                                                        onChange={(e) => setFieldValue('childsequenceno', e.target.value)}>
                                                        <option value={''}>Tab Sequence #</option>
                                                        {sourceSequence.map((obj, index) => {
                                                            return <option selected={obj.ID == values.childsequenceno ? obj.ID : obj.ID} value={obj.ID} key={index}>{obj.ID}</option>
                                                        })}
                                                    </select>
                                                    {errors && touched.childsequenceno && errors.childsequenceno && errors.childsequenceno !== '' && (
                                                        <span className='error-msg'>
                                                            <ErrorMessage name='childsequenceno' render={(err) => { return err; }} />
                                                        </span>
                                                    )}
                                                </div>
                                            </div>

                                            <div className={`tab_field ${errors && touched.productid && errors.productid && errors.productid !== '' ? 'error' : ''}`}>
                                                <div className="field-right" style={{ width: '100%' }}>
                                                    <select
                                                        id={'productid'}
                                                        name="reportType"
                                                        defaultValue={values.productid}
                                                        value={values.productid}
                                                        onChange={(e) => setFieldValue('productid', e.target.value)}>
                                                        <option value={''}>Select Parent Product</option>
                                                        {sourceProducts.map((obj, index) => {
                                                            return <option selected={obj.ID == values.productid ? obj.ID : obj.Name} value={obj.ID} key={index}>{obj.Name}</option>
                                                        })}
                                                    </select>
                                                    {errors && touched.productid && errors.productid && errors.productid !== '' && (
                                                        <span className='error-msg'>
                                                            <ErrorMessage name='productid' render={(err) => { return err; }} />
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="asset-tab-right">
                                            <div className="asset-table-head">
                                                <div style={{ width: '16%' }} className="asset-table-width-17"><h5>Action</h5></div>
                                                <div style={{ width: '21.33%' }} className="asset-table-width-1"><h5>Tab Name</h5></div>
                                                <div style={{ width: '23.33%', textAlign: 'left' }} className="asset-table-width-22"><h5>Tab Sequence #</h5></div>
                                                <div style={{ width: '21.33%' }} className="asset-table-width-17"><h5>Parent Product</h5></div>
                                            </div>
                                            {<PerfectScrollbar style={{ maxHeight: '500px' }}>
                                                {IsLoadingData ? <CircularProgress style={{ color: '#0a5c7f', alignSelf: 'center' }} />
                                                    : !!childrensData.length ? childrensData.map((obj, index) => {
                                                        return (
                                                            <div className="asset-table-body" key={index}>
                                                                <div style={{ width: '13%' }} className="asset-table-width-17">
                                                                    <a style={{ cursor: 'pointer' }} title="" className="small-ic" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setFieldValue('tabName', obj.TabName);
                                                                        setFieldValue('childsequenceno', obj.TabSequence);
                                                                        setFieldValue('productid', obj.ProductID);

                                                                        setChildrenID(obj.ID)
                                                                        setParentProductID(obj.ParentProductID)
                                                                    }}>
                                                                        <img src={editIcon} alt="icon" />
                                                                    </a>
                                                                    <a style={{ cursor: 'pointer' }} title="" className="small-ic" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        wsDeleteProductChildren(obj.ID)
                                                                        setFieldValue('tabName', "");
                                                                        setFieldValue('childsequenceno', "");
                                                                        setFieldValue('productid', "");

                                                                        setChildrenID(0)
                                                                    }}><img src={deleteIcon} alt="icon" /></a>
                                                                </div>
                                                                <div style={{ width: '24.33%', textAlign: 'left' }} className="asset-table-width-1"><p>{obj.TabName}</p></div>
                                                                <div style={{ width: '23.33%' }} className="asset-table-width-22"><p>{obj.TabSequence}</p></div>
                                                                <div style={{ width: '21.33%', textAlign: 'center' }} className="asset-table-width-17"><p>{obj.ProductName}</p></div>
                                                            </div>);
                                                    })
                                                        : <div style={{ textAlign: "center", marginTop: '2%' }}>
                                                            <label>Not Currently Available</label>
                                                        </div>}
                                            </PerfectScrollbar>}
                                        </div>

                                    </div>

                                    <Notification
                                        isError={isError}
                                        message={message}
                                        showNotification={showNotification}
                                        clearNotification={handleClearNotification.bind(this)}
                                        closeNotification={handleClearNotification.bind(this)}
                                    />


                                    <div className="form-submit">
                                        <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" />
                                        </div>
                                        <div className="button-field ">
                                            <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )
                    }}
                </Formik>
            </section>
        </>
    )
}
const validateProductForm = yup.object().shape({
    tabName: yup.string().required('Tab Name is required'),
    childsequenceno: yup.string().required('Tab Sequence is required'),
    productid: yup.string().required('Product is required'),
});
export default ProductChildrenForm;