import React from 'react';
import Editor from '../../../../src/features/campaign/components/editor';
import TextField from "../../../shared/components/TextField/TextField";
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";
import * as APIController from '../../../api/APIController.js';
import * as AppConstants from "../../../config/AppConstant";
import Notification from '../../../shared/components/notification/notification';

import close_icon from "../../../assets/images/gray-close-ic.svg";
import search_ic from '../../../assets/images/icon/search.png';

import facebook_color_icon from "../../../assets/images/icon/facebookcoloricon.png";
import instagram_color_icon from "../../../assets/images/icon/instagramcoloricon.png";

import ModalComponent from "../../../shared/components/modal/modal";
import ThankyouDialog from '../../../features/ConfirmDialog/ThankyouDialog';

import MiniCalendar from "./MiniCalendar";

import uuid from 'react-uuid';
import moment from 'moment';

const image2base64 = require('image-to-base64');

export default class AddEditSocialPost extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            message: "",
            showNotification: false,
            isError: false,
            AssetID: 0,
            AutomationSubscriptionIDLocal: AppConstants.GetLocalStorage("localStorageAutomationSubscriptionID"),
            AutomationSubscriptionID: AppConstants.GetLocalStorage("localStorageAutomationSubscriptionID"),
            PreviewModalData: this.props.wholeState.PreviewModalData,
            FBCHAKE: false,
            IGCHAKE: false,
            FinalArray: this.props.wholeState.FinalArray,
            timeRangesData: [],
            ScheduledAtDate: this.props.wholeState.ScheduledAtDate,
            // isReschedule: this.props.wholeState.isReschedule,
            isReschedule: false,
            isEditable: false,
            isFromAsset: this.props.wholeState.isFromAsset,
            CurrentYear: this.props.wholeState.CurrentYear,
            CurrentMonth: this.props.wholeState.CurrentMonth,
            postImageName: this.props.wholeState.postImageName,
            postImageData: this.props.wholeState.postImageData,
            baseImageData: this.props.wholeState.baseImageData,
            Description: this.props.wholeState.description,
            postTimeRanges: "11:00 AM",
            FilterBy: 'DESC',
            FilterByData: [{ ID: 'ASC', Name: 'Oldest' }, { ID: 'DESC', Name: 'Newest' }],
            SegmentName: "Standard",
            SegmentData: [],
            FreeFormText: '',
            CampaignFreeAssets: [],
            CurrentPage: 0, RecordPerPage: 25,
            isConfirmLoading: false,
            isLoadingAssets: true,
            subscriptionsData: [],
            videoFormat: '',
            openDialog: false,
            TotalRecord: 0,
        };
        this.myRef = React.createRef()
    }

    componentDidMount() {
        this.RescheduleSetData()
        this.wsGetSegmentForDropdown()

        if (AppConstants.GetLocalStorage("localStorageSubscriptions") != undefined
            && AppConstants.GetLocalStorage("localStorageSubscriptions") != null
            && AppConstants.GetLocalStorage("localStorageSubscriptions") != "") {
            let SubscriptionOpctions = JSON.parse(AppConstants.GetLocalStorage("localStorageSubscriptions"))
            if (SubscriptionOpctions.length == 1) {
                this.setState({ AutomationSubscriptionID: SubscriptionOpctions[0].ID })
            }
            this.setState({ subscriptionsData: SubscriptionOpctions })
        }

        if (this.props.wholeState.baseImageData !== "") {
            image2base64(this.props.wholeState.baseImageData)
                .then((response) => {
                    this.setState({ baseImageData: response })
                }).catch((error) => { })
        }
    }

    SetSelectedDate(selectedDate) {
        this.setState({ ScheduledAtDate: this.formatDate(selectedDate) })
    }

    formatDate(date) {
        return moment(date).format('YYYY-MM-DD')
    }

    formatTime(timeString) {
        const [hourString, minute] = timeString.split(":");
        const hour = +hourString % 24;
        return ((hour % 12)) + ":" + minute + (hour < 12 ? " AM" : " PM");
    }

    to24HrTime(time) {
        let [hr, min, ap] = time.toLowerCase().match(/\d+|[a-z]+/g) || [];
        return `${(hr % 12) + (ap == 'am' ? 0 : 12)}:${min}:00`;
    }

    RescheduleSetData() {
        const locale = 'en'; // or whatever you want...
        const hours = [];

        moment.locale(locale);  // optional - can remove if you are only dealing with one locale

        for (let hour = 0; hour < 24; hour++) {
            hours.push({ "PostTime": moment({ hour }).format('h:mm A') });
            hours.push({ "PostTime": moment({ hour, minute: 30 }).format('h:mm A') });
        }

        this.setState({ timeRangesData: hours })
    }

    handleAddEditMyCustomPost(IsReschedule, ID, AutomationSubscriptionID, ScheduledAtDate, postTimeRanges, Description, ImageName, ImageData, PostToFacebook, PostToInstagram, IsFromLibrary) {
        if (AutomationSubscriptionID == '') {
            this.handleNotification("Please select Subscription", true);
        } else if (postTimeRanges == "") {
            this.handleNotification("Please select Post Time", true);
        } else if (Description == "") {
            this.handleNotification("Please Add Description", true);
        } else if (ImageData == "") {
            this.handleNotification("Please select Post Image", true);
        } else if (!PostToFacebook && !PostToInstagram) {
            this.handleNotification("Please check Facebook / Instagram", true);
        } else {
            const fromLibrary = this.state.isFromAsset ? true : IsFromLibrary
            if (this.formatDate(ScheduledAtDate) == this.formatDate(new Date()) && fromLibrary) {
                this.wsAddEditMyCustomPost(IsReschedule, ID, AutomationSubscriptionID, ScheduledAtDate + " " + postTimeRanges, Description, ImageName, ImageData, PostToFacebook, PostToInstagram, fromLibrary)
            } else if (this.formatDate(ScheduledAtDate) != this.formatDate(new Date())) {
                this.wsAddEditMyCustomPost(IsReschedule, ID, AutomationSubscriptionID, ScheduledAtDate + " " + postTimeRanges, Description, ImageName, ImageData, PostToFacebook, PostToInstagram, fromLibrary)
            } else {
                this.handleNotification("You cannot upload Custom Post for next 10 Days.", true);
            }
        }
    }

    wsAddEditMyCustomPost(IsReschedule, ID, AutomationSubscriptionID, ScheduledAt, Description, ImageName, ImageData, PostToFacebook, PostToInstagram, IsFromLibrary) {
        this.setState({ isConfirmLoading: true })
        APIController.AddEditMyCustomPost(IsReschedule, ID, AutomationSubscriptionID, ScheduledAt, Description, ImageName, ImageData, PostToFacebook, PostToInstagram, IsFromLibrary)
            .then((response) => {
                if (response.object.status == "1") {
                    this.handleNotification(response.object.message);
                    !this.state.isFromAsset && this.props.handleReload(AppConstants.GetLocalStorage('localStorageBrandsData').length && JSON.parse(AppConstants.GetLocalStorage('localStorageBrandsData')), AppConstants.GetLocalStorage("localStoragepracticeId"), this.state.AutomationSubscriptionID)
                    setTimeout(() => {
                        this.props.handleClose()
                    }, 2000)
                } else if (response.object.status == "2") {
                    this.setState({ message: response.object.message, openDialog: true })
                } else {
                    this.handleNotification(response.object.message, true);
                }
                this.setState({ isConfirmLoading: false })
            })
    }

    wsGetSegmentForDropdown = () => {
        APIController.GetSegmentForDropdown()
            .then((response) => {
                if (response.error == null) {
                    this.setState({ SegmentData: response.object.data })
                }
                else if (response.error.message && response.error.message.length > 0) {
                    // this.handleNotification(response.error.message, true);
                }
                this.wsGetCampaignFreeAssetsForSocialAutomation(this.state.SegmentName, this.state.FreeFormText, this.state.CurrentPage, this.state.RecordPerPage, this.state.FilterBy, true)
            })
    }

    wsGetCampaignFreeAssetsForSocialAutomation(SegmentName, FreeFormText, CurrentPage, RecordPerPage, SortDirection, isLoading) {
        this.setState({ isLoadingAssets: isLoading })
        APIController.GetCampaignFreeAssetsForSocialAutomation(SegmentName, FreeFormText, CurrentPage, RecordPerPage, SortDirection)
            .then((response) => {
                if (response.object.data.length) {
                    this.setState({ TotalRecord: response.object.data[0].MaxRows })
                } else {
                    // this.handleNotification(response.object.message, true);
                }
                this.setState({ CampaignFreeAssets: response.object.data, TotalRecord: 0, isLoadingAssets: false })

            })
    }

    handleNotification = (message, isError) => {
        this.setState({ message: message, showNotification: true, isError: isError });
        setTimeout(() => { this.handleClearNotification(); }, 4000);
    };
    handleClearNotification = () => { this.setState({ message: "", showNotification: false, isError: false, }); };

    videoPlayer(VideoURL, width) {
        return (<video controls width={width} maxHeight="360px">
            <source src={VideoURL} type="video/mp4" />
        </video>)
    }

    render() {
        const { PreviewModalData, FinalArray, CurrentYear, CurrentMonth, isReschedule, isEditable, isFromAsset, isConfirmLoading, subscriptionsData } = this.state;
        const { AssetID, AutomationSubscriptionID, ScheduledAtDate, postTimeRanges, Description, timeRangesData, postImageData, postImageName, baseImageData, FBCHAKE, IGCHAKE } = this.state;
        const { FilterBy, FilterByData, SegmentName, SegmentData, FreeFormText, CurrentPage, RecordPerPage, CampaignFreeAssets, isLoadingAssets, videoFormat, openDialog } = this.state;

        return (
            <div className={`popup-wrap ${PreviewModalData.showLibrary && !isReschedule && 'popup-wrap-library'}`}
                style={{ margin: '45px 0px', padding: '0px', borderRadius: '5px', justifyContent: 'space-between' }}>
                {/* minWidth: PreviewModalData.showLibrary && !isReschedule ? '1250px' : '800px', maxWidth: '800px' */}
                <div className='box-body box-body-library-left'>
                    <div className='box-head' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px' }}>
                        <h4>{PreviewModalData.headingText}</h4>
                        {PreviewModalData.isEdit == 123 && this.state.AutomationSubscriptionIDLocal == 0 && subscriptionsData.length ?
                            <div>
                                <label>Subscription: </label>
                                <select className={'title-content'} style={{ borderRadius: '3px', border: '1px solid #CCCCCC', padding: '6px', fontSize: '13px', width: '300px', marginRight: '10px' }}
                                    name='AutomationSubscriptionID'
                                    defaultValue={AutomationSubscriptionID}
                                    value={AutomationSubscriptionID}
                                    onChange={(e) => this.setState({ AutomationSubscriptionID: e.target.value })}>
                                    <option value={0}>Select Subscription</option>
                                    {subscriptionsData.map((item, index) => {
                                        return <option key={index} value={item.ID}>{item.Address}</option>
                                    })}
                                </select>
                            </div> : <></>}
                    </div>

                    {PreviewModalData.isEdit == 123 ?
                        <div className="digital-welcome" style={{ padding: '10px 0px', marginTop: '0px', boxShadow: 'none' }}>
                            {/* Add Post */}
                            <div style={{ borderBottom: '3px solid #f5f7fa', display: 'flex', marginBottom: '10px' }}>
                                <div className="sec-left">
                                    <div style={{ letterSpacing: '0px' }}>
                                        {/* <div dangerouslySetInnerHTML={{ __html: item.Description }} /> */}
                                        {/* <Editor content={Description} setContent={(e) => this.setState({ Description: e })} /> */}
                                        <TextField disabled={isReschedule || isEditable || isFromAsset} label='Post Copy' type="text" id="description" name="description" value={Description.replaceAll(/<[^>]+>/g, '')}
                                            onChange={(e) => { this.setState({ Description: e.target.value }) }}
                                            multiline rows={12} style={{ fontSize: 10, height: '280px' }}
                                        />
                                    </div>
                                </div>
                                <div className="sec-right" style={{ padding: '0px', marginBottom: '10px' }}>
                                    {isReschedule || isEditable || isFromAsset
                                        ? postImageData.split('.').pop().split('?')[0] == "mp4" ?
                                            this.videoPlayer(postImageData, "290px")
                                            : <img src={postImageData} style={{ width: '260px' }} alt='preview-imag' />

                                        : postImageData == '' ?
                                            <div style={{ height: '170px', display: 'grid', alignContent: 'space-around', alignItems: 'center' }}>
                                                <p style={{ textAlign: 'center', margin: '0 10px', fontSize: '14px' }}>
                                                    - Accepted files: PNG & JPG<br />
                                                    - PNG with transparent background recommended<br />
                                                    - Recommended image size:<strong> 1080 pixels X 1080 pixels</strong>
                                                </p>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <div className='button-field file-upload-btn'>
                                                        <input type='file' id='upload-btn' name='files' accept=".svg, .png, .jpeg, .jpg, .mp4"
                                                            onChange={(e) => {
                                                                const file = e.target.files[0];
                                                                if (file && (file.type.includes("svg") || file.type.includes("png") || file.type.includes("jpeg") || file.type.includes("jpg") || file.type.includes("mp4"))) {
                                                                    let file1 = e.target.files[0]
                                                                    file1['preview'] = URL.createObjectURL(e.target.files[0]);

                                                                    image2base64(file1.preview)
                                                                        .then((response) => { this.setState({ baseImageData: response }) })
                                                                        .catch((error) => { })
                                                                    this.setState({
                                                                        postImageName: uuid() + "." + file.type.split('/')[1],
                                                                        postImageData: file1.preview,
                                                                        videoFormat: file.type.split('/')[1] == "mp4" ? "mp4" : ''
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                        <label htmlFor='upload-btn'>{AppConstants.ButtonText.Upload}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            : <>
                                                {videoFormat == "mp4" || postImageData.split('.').pop().split('?')[0] == "mp4" ?
                                                    <>
                                                        {this.videoPlayer(postImageData, "380px")}
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <div className='button-field file-upload-btn'>
                                                                <input type="button" value={AppConstants.ButtonText.Delete} name="delete" onClick={() => this.setState({ postImageData: '', baseImageData: '' })} />
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <div className="content-box" style={{ width: '260px', border: 'none' }} >
                                                        <div className="box-image free-asset-image-box"
                                                            style={{ backgroundImage: `url(${postImageData} )`, backgroundSize: 'contain', width: '260px' }}>
                                                            <div className="button-hover">
                                                                <div className="button-field">
                                                                    <button onClick={() => this.setState({ postImageData: '', baseImageData: '' })}>{"delete"}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>}
                                                {/* <img src={postImageData} style={{ width: '260px' }} alt='preview-imag' /> */}

                                                {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <div className='button-field file-upload-btn'>
                                                        <input type="button" value={AppConstants.ButtonText.Delete} name="delete" onClick={() => this.setState({ postImageData: '', baseImageData: '' })} />
                                                    </div>
                                                </div> */}
                                            </>}
                                </div>
                            </div>
                            <label style={{ fontSize: '14px', fontWeight: 400, fontStyle: 'italic' }}>Note: Use only provided posts for Bausch + Lomb; competitive product posts may not be added.</label>
                            <form style={{ display: 'flex', marginTop: '15px', width: '100%' }}>
                                <div className="sec-left">
                                    <label>Select your social platform(s), post date and time, then click CONFIRM to add your post to the calendar.</label>
                                    <div className="table-body" style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '10px' }}>
                                        <div className='custom-checkbox'>
                                            <input type='checkbox'
                                                name={"FBCHAKE"}
                                                id={"FBCHAKE"}
                                                defaultChecked={FBCHAKE}
                                                onChange={(e) => this.setState({ FBCHAKE: e.target.checked })}
                                            />
                                            <label htmlFor={"FBCHAKE"} style={{ paddingLeft: '40px', display: 'flex', alignContent: 'center' }}>
                                                <img src={facebook_color_icon} alt="image" style={{ borderRadius: '5px' }} />
                                                &nbsp;Facebook</label>
                                        </div>

                                        <div className='custom-checkbox' >
                                            <input type='checkbox'
                                                name={"IGCHAKE"}
                                                id={"IGCHAKE"}
                                                defaultChecked={IGCHAKE}
                                                onChange={(e) => this.setState({ IGCHAKE: e.target.checked })}
                                            />
                                            <label htmlFor={"IGCHAKE"} style={{ paddingLeft: '40px', display: 'flex', alignContent: 'center' }}>
                                                <img src={instagram_color_icon} alt="image" />
                                                &nbsp;Instagram</label>
                                        </div>

                                    </div>
                                    <div className='field-field-row' style={{ marginTop: '25px' }}>
                                        <TextField label='New Post Date' type="text" name="ScheduledAtDate" value={moment(ScheduledAtDate).format('MM/DD/YYYY')} />
                                    </div>
                                    <div className='field-field-row'>
                                        <select
                                            name="PostTime"
                                            defaultValue={postTimeRanges}
                                            value={postTimeRanges}
                                            onChange={(e) => this.setState({ postTimeRanges: e.target.value })}>
                                            <option value="">Post Time</option>
                                            {timeRangesData.map((obj, indx) => {
                                                return <option value={obj.PostTime} key={indx}>{obj.PostTime}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div className={isConfirmLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type="button" value="Confirm" name="confirm" onClick={() => {
                                                this.handleAddEditMyCustomPost(isReschedule, AssetID, AutomationSubscriptionID, this.formatDate(ScheduledAtDate), this.to24HrTime(postTimeRanges), Description, postImageName, baseImageData, FBCHAKE, IGCHAKE, isEditable)
                                            }} />
                                        </div>
                                        <div className="button-field">
                                            {isReschedule || PreviewModalData.headingText == "Add Post" ? <input type="button" value="Cancel" name="cancel" onClick={this.props.handleClose.bind(this)} />
                                                : <input type="button" value="Cancel" name="cancel" onClick={() => {
                                                    this.setState({
                                                        isReschedule: false,
                                                        PreviewModalData: {
                                                            AssetDate: this.formatDate(ScheduledAtDate),
                                                            isModalOpen: true,
                                                            isEdit: true,
                                                            headingText: AppConstants.ButtonText.EditPost,
                                                            Date: ScheduledAtDate.split("-")[1],
                                                        },
                                                    })
                                                }} />}
                                        </div>
                                    </div>
                                </div>
                                <div className="sec-right" style={{ padding: '0px', marginBottom: '10px' }}>
                                    <MiniCalendar MyState={this.props.wholeState} ScheduledAtDate={ScheduledAtDate} SetSelectedDate={this.SetSelectedDate.bind(this)} />
                                </div>
                            </form>
                        </div>
                        :
                        FinalArray.map((item, index) =>

                            item.DayNo != undefined && item.DayNo != '0' && item.Status != 3 &&
                            item.AssetDate.split("-")[2] == CurrentYear &&
                            item.AssetDate.split("-")[0] == CurrentMonth &&
                            item.AssetDate.split("-")[1] == PreviewModalData.Date &&
                            <div style={{ padding: '10px 0px', marginTop: '0px', boxShadow: 'none' }}>
                                {/* Preview Post */}
                                <div style={{ borderBottom: '3px solid #f5f7fa', display: 'flex', paddingBottom: '10px' }}>
                                    <div className="sec-left" style={{ marginTop: '0px', letterSpacing: '0px', width: '50%', fontSize: '18px' }} >
                                        <div dangerouslySetInnerHTML={{ __html: item.Description }} />
                                        {PreviewModalData.isEdit &&
                                            <div style={{ display: 'grid', alignSelf: 'flex-end', paddingTop: '10px' }}>
                                                <div style={{ display: 'flex' }}>
                                                    <div className={isReschedule ? "button-field disable-btn" : "button-field"}>
                                                        <input type="button" value={item.IsPersonalAsset ? 'Edit' : 'Reschedule'} name="reschedule" onClick={() => {
                                                            image2base64(item.ImageURL)
                                                                .then((response) => {
                                                                    this.setState({ baseImageData: response })
                                                                }).catch((error) => { })

                                                            this.setState({
                                                                AssetID: item.ID,
                                                                Description: item.Description, ScheduledAtDate: item.AssetDate,
                                                                postImageName: item.ImageURL.split('?')[0].split('/').pop(), postImageData: item.ImageURL,
                                                                postTimeRanges: this.formatTime(item.SchedulerDate.split("T")[1]),
                                                                FBCHAKE: item.IsPersonalAsset && item.Status == 0 ? false : true, IGCHAKE: item.IsPersonalAsset && item.InstagramStatus == 0 ? false : true,
                                                                isReschedule: !item.IsPersonalAsset && true,
                                                                isEditable: item.IsFromLibrary,
                                                                PreviewModalData: {
                                                                    AssetDate: item.AssetDate,
                                                                    isModalOpen: true,
                                                                    isEdit: 123,
                                                                    headingText: item.IsPersonalAsset ? AppConstants.ButtonText.EditPost : AppConstants.ButtonText.ReschedulePost,
                                                                    Date: item.AssetDate.split("-")[1],
                                                                },
                                                            })
                                                        }} />
                                                    </div>
                                                    <div className={isReschedule ? "button-field disable-btn" : "button-field"}>
                                                        <input type="button" value="Delete" name="delete" onClick={() => { this.props.handleDelete(item.ID, item.IsPersonalAsset ? 0 : item.BrandID, true) }} />
                                                        {/* <input type="button" value="Delete" name="delete" onClick={() => { this.setState({ EditID: 0, DeleteId: item.ID, DeleteStatusID: item.BrandID, ConfirmDialog: true }) }} /> */}
                                                    </div>
                                                </div>
                                            </div>}
                                    </div>
                                    <div className="sec-right" style={{ padding: '0px', marginBottom: '10px', width: '50%' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0 0 10px 0' }}>
                                            <div style={{ display: 'flex' }}>Posting At : <label>&nbsp;{this.formatTime(item.SchedulerDate.split("T")[1])}</label></div>
                                            <div style={{ display: 'flex' }}>
                                                {item.Status == 1 && <img src={facebook_color_icon} alt="image" style={{ borderRadius: '5px', marginRight: '5px' }} />}
                                                {item.InstagramStatus == 1 && <img src={instagram_color_icon} alt="image" />}
                                            </div>
                                        </div>
                                        {item.ImageURL != undefined && item.ImageURL.split('.').pop().split('?')[0] == "mp4" ?
                                            this.videoPlayer(item.ImageURL, "380px")
                                            : <img src={item.ImageURL} alt="image" />}
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>

                {PreviewModalData.showLibrary && !isReschedule
                    ? <div className='box-body box-body-library-right'>
                        {isLoadingAssets ? <CircularProgress /> :
                            <>
                                <label>Filter by: </label>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', margin: '10px 0' }}>
                                    <select className={'title-content'} style={{ borderRadius: '3px', border: '1px solid #CCCCCC', padding: '6px', fontSize: '13px', width: '200px', marginRight: '10px' }}
                                        name='FilterBy'
                                        defaultValue={FilterBy}
                                        value={FilterBy}
                                        onChange={(e) => { this.wsGetCampaignFreeAssetsForSocialAutomation(SegmentName, FreeFormText, CurrentPage, RecordPerPage, e.target.value, true); this.setState({ FilterBy: e.target.value }) }}>
                                        {FilterByData.map((item, index) => {
                                            return <option key={index} value={item.ID}>{item.Name}</option>
                                        })}
                                    </select>
                                    {SegmentData.length > 1 && <select className={'title-content'} style={{ borderRadius: '3px', border: '1px solid #CCCCCC', padding: '6px', fontSize: '13px', width: '200px', marginLeft: '10px' }}
                                        name='SegmentName'
                                        defaultValue={SegmentName}
                                        value={SegmentName}
                                        onChange={(e) => { this.wsGetCampaignFreeAssetsForSocialAutomation(e.target.value, FreeFormText, CurrentPage, RecordPerPage, FilterBy, true); this.setState({ SegmentName: e.target.value }) }}>
                                        {SegmentData.map((item, index) => {
                                            return <option key={index} value={item.Name}>{item.Name}</option>
                                        })}
                                    </select>}
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '15px' }}>
                                    <input className={'title-content'} style={{ borderRadius: '3px', border: '1px solid #CCCCCC', padding: '6px', fontSize: '13px', width: '380px', marginRight: '10px' }}
                                        placeholder='Search by Brand | Asset Name | Description' type="search" name="FreeFormText" value={FreeFormText}
                                        onChange={(e) => this.setState({ FreeFormText: e.target.value })} />
                                    <a title="" style={{ cursor: 'pointer' }}>
                                        <img src={search_ic} alt="icon" onClick={() => this.wsGetCampaignFreeAssetsForSocialAutomation(SegmentName, FreeFormText, CurrentPage, RecordPerPage, FilterBy, true)} />
                                    </a>
                                </div>

                                <div className='library-list-main two-col-sec'
                                    id="myDiv"
                                    ref={this.myRef}
                                    onScroll={this.onScroll}>
                                    {CampaignFreeAssets.length ? CampaignFreeAssets.map((obj, index) => {
                                        return (
                                            <div className="content-box-feed content-box" key={index} style={{ width: '210px', marginBottom: '10px' }}>
                                                <div className="box-image free-asset-image-box"
                                                    style={{ backgroundImage: `url(${obj.ImageURL} )`, backgroundSize: 'contain', height: '200px' }}>
                                                    {!isReschedule && <div className="button-hover">
                                                        <div className="button-field">
                                                            <button onClick={(e) => {
                                                                image2base64(obj.ImageURL)
                                                                    .then((response) => {
                                                                        this.setState({ baseImageData: response })
                                                                    }).catch((error) => { })

                                                                this.setState({ Description: obj.Description, postImageName: obj.ImageURL.split('?')[0].split('/').pop(), postImageData: obj.ImageURL, isEditable: true })
                                                            }}>{"Add"}</button>
                                                        </div>
                                                    </div>}
                                                </div>
                                                <div className="box-content-info" style={{ overflowY: "hidden", overflowX: "hidden", maxHeight: '175px', padding: '5px' }}>
                                                    <p style={{ fontSize: '12px', }}>{obj.Description.replaceAll(/<[^>]+>/g, '')}</p>
                                                </div>
                                            </div>
                                        )
                                    }) : <label>No Assets found</label>}
                                </div>
                            </>}
                    </div> : <></>}

                <a title="" className="gray-close-ic">
                    <img src={close_icon} alt="icon" onClick={this.props.handleClose.bind(this)} />
                </a>

                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    clearNotification={this.handleClearNotification}
                    closeNotification={this.handleClearNotification}
                />

                <ModalComponent show={openDialog}
                    handleClose={() => this.setState({ openDialog: false })}>

                    <ThankyouDialog
                        title={"Alert"}
                        description={this.state.message}
                        deletePress={() => { }}
                        closeModal={() => this.setState({ openDialog: false })} />

                </ModalComponent>
            </div>
        )
    }

    onScroll = () => {
        const element = document.getElementById("myDiv");
        const scrollTop = this.myRef.current.scrollTop
        let y = element.scrollHeight;

        let ScollEnd = (y - 675) == scrollTop ? true : false
        if (ScollEnd && (this.state.TotalRecord < this.state.CampaignFreeAssets.length))
            this.wsGetCampaignFreeAssetsForSocialAutomation(this.state.SegmentName, this.state.FreeFormText, this.state.CurrentPage, this.state.RecordPerPage + 25, this.state.FilterBy, false)
    }

}