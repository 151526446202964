/*eslint-disable eqeqeq*/
import React from "react";
import RegistrationsReportList from "./registrationsReportList";
import * as APIController from "../../../../../api/APIController.js";
import Notification from "../../../../../shared/components/notification/notification";
import CsvDownload from 'react-json-to-csv'
import moment from 'moment';

import PageTitle from "../../../../../config/pageTitle";
import * as AppConstants from "../../../../../config/AppConstant";
import TextField from "../../../../../shared/components/TextField/TextField";

class RegistrationsReport extends React.Component {
    state = {
        currentPage: 1,
        count: 0,
        rowsPerPage: 1000000,
        rowsPerPageOptions: ["All", 50, 100, 250, 500, 1000],
        list: [],
        from: 0,
        to: 1000000,
        downloadlist: [],
        progress: true,
        message: "",
        showNotification: false,
        isError: false,
        downloadDate: moment(new Date().toUTCString()).utcOffset(0, false).format('MMDDYY'),
        showMessage:
            this.props.history.location.state != null &&
            this.props.history.location.state.showMessage,
        FromDate: "2022-05-16",
        ToDate: moment(new Date().toUTCString()).utcOffset(0, false).format('YYYY-MM-DD'),
    };

    componentDidMount() {
        this.wsAssetDownloadReport(this.state.from, this.state.to, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", this.state.FromDate, this.state.ToDate, this.state.currentPage, this.state.rowsPerPage);
    }

    wsAssetDownloadReport(from, to, DownloadedAt, ShipToNo, CampaignName, BrandName, PracticeName, Address1, Address2, City, State, Postcode, PhoneNo, VS, VSP, Website, AppointmentURL, ContactName, EmailAddress, VTM_Territory, VTM,
        StartDate, EndDate, CurrentPage, RecordPerPage) {
        this.setState({ progress: true, });
        APIController.AssetDownloadReport("3", DownloadedAt, "", ShipToNo, CampaignName, BrandName, "", PracticeName,
            Address1, Address2, City, State, Postcode, PhoneNo, VS, VSP, Website, AppointmentURL, ContactName, EmailAddress, VTM_Territory, VTM,
            "", "", "", "", StartDate, EndDate, "", "", "", "", "", CurrentPage, RecordPerPage).then((response) => {
                if (response.error == null && response.object.data.length != 0) {
                    let downloaddata = []
                    response.object.data.map((item) =>
                        downloaddata.push({
                            "Submission Date": item.DownloadedAt, "Ship To #": item.ShipToNo, "Campaign": item.CampaignName, "Brand": item.BrandName, "Practice Name": item.PracticeName,
                            "Street Address": item.Address1, "Street Address Line 2": item.Address2,
                            "City": item.City, "State": item.State, "Zip Code": item.Postcode, "Practice Phone #": item.PhoneNo, "VS": item.VS,
                            "VSP": item.VSP, "Website URL": item.Website, "Appointment URL": item.AppointmentURL, "Contact Name": item.ContactName, "Email Address": item.EmailAddress,
                            "VTM #": item.VTM_Territory, "Territory Manager": item.VTM
                        })
                    )
                    this.setState({
                        downloadlist: downloaddata,
                        to: isNaN(to) ? 1000000 : to,
                        from: from,
                        count: response.object.data[0].MaxRows,
                        list: response.object.data,
                        progress: false,
                    });
                } else { this.setState({ list: [], progress: false }) }
            });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        return (
            <>
                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    clearNotification={this.handleClearNotification}
                    closeNotification={this.handleClearNotification}
                />
                <section className='content-right-main'>
                    <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                        <h1><PageTitle Title={AppConstants.ContactDetais.RegistrationsReport} /></h1>
                        <div style={{ display: 'flex', alignItems: 'baseline' }}>
                            <TextField style={{ margin: '10px' }} label='From Date' type="date" name="FromDate" value={this.state.FromDate} onChange={this.handleChange} />

                            <TextField label='To Date' type="date" name="ToDate" value={this.state.ToDate} onChange={this.handleChange} />

                            <div className='button-field button-field-country' style={{ height: '40px', minWidth: '100px', textAlign: 'center' }}>
                                <button onClick={() => { this.wsAssetDownloadReport(this.state.from, this.state.to, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", this.state.FromDate, this.state.ToDate, this.state.currentPage, this.state.rowsPerPage); }}> Search </button>
                            </div>

                            <div className='button-field' style={{ height: '40px', width: '240px', textAlign: 'center' }}>
                                <CsvDownload data={this.state.downloadlist} filename={"REACH Registration Download Report " + this.state.downloadDate + ".csv"}>Download to CSV</CsvDownload>
                            </div>
                        </div>
                    </div>

                    <RegistrationsReportList
                        {...this.state}
                        history={this.props.history}
                        handleChangeRowsPerPage={this.handleRowsPerPageChange}
                        handleChangePage={this.handlePageChange}
                        handleApplyFilter={this.handleApplyFilter}
                        handleNotification={this.handleNotification}
                    />
                    {/*  */}
                </section>
            </>
        );
    }
    handleRowsPerPageChange = (value, DownloadedAt, ShipToNo, CampaignName, BrandName, PracticeName, Address1, Address2, City, State, Postcode, PhoneNo, VS, VSP, Website, AppointmentURL, ContactName, EmailAddress, VTM_Territory, VTM) => {
        let from = 0
        let to = value;
        this.setState({ rowsPerPage: value, currentPage: 1, to: to, from: from })
        this.wsAssetDownloadReport(from, to, DownloadedAt, ShipToNo, CampaignName, BrandName, PracticeName, Address1, Address2, City, State, Postcode, PhoneNo, VS, VSP, Website, AppointmentURL, ContactName, EmailAddress, VTM_Territory, VTM, this.state.FromDate, this.state.ToDate, isNaN(value) ? '' : this.state.currentPage, isNaN(value) ? 1000000 : value);
    }

    handlePageChange = (values, DownloadedAt, ShipToNo, CampaignName, BrandName, PracticeName, Address1, Address2, City, State, Postcode, PhoneNo, VS, VSP, Website, AppointmentURL, ContactName, EmailAddress, VTM_Territory, VTM) => {
        let from = values === 1 ? 0 * this.state.rowsPerPage : (values - 1) * this.state.rowsPerPage
        let to = values === 1 ? this.state.rowsPerPage : (from + this.state.rowsPerPage);
        this.setState({ currentPage: Number(values) })
        this.wsAssetDownloadReport(from, to, DownloadedAt, ShipToNo, CampaignName, BrandName, PracticeName, Address1, Address2, City, State, Postcode, PhoneNo, VS, VSP, Website, AppointmentURL, ContactName, EmailAddress, VTM_Territory, VTM, this.state.FromDate, this.state.ToDate, Number(values), this.state.rowsPerPage);
    }
    /**
     * handleApplyFilter method is used to apply filter on every columns
     */
    handleApplyFilter = (DownloadedAt, ShipToNo, CampaignName, BrandName, PracticeName, Address1, Address2, City, State, Postcode, PhoneNo, VS, VSP, Website, AppointmentURL, ContactName, EmailAddress, VTM_Territory, VTM) => {
        this.wsAssetDownloadReport(this.state.from, this.state.to, DownloadedAt, ShipToNo, CampaignName, BrandName, PracticeName, Address1, Address2, City, State, Postcode, PhoneNo, VS, VSP, Website, AppointmentURL, ContactName, EmailAddress, VTM_Territory, VTM, this.state.FromDate, this.state.ToDate, this.state.currentPage, this.state.rowsPerPage);
    };

    handleNotification = (message, isError) => {
        this.setState({
            message: message,
            showNotification: true,
            isError: isError,
        });

        setTimeout(() => {
            this.handleClearNotification();
        }, 4000);
    };

    handleClearNotification = () => {
        this.setState({
            message: "",
            showNotification: false,
            isError: false,
        });
    };
}

export default RegistrationsReport;
