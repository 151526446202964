/*eslint-disable eqeqeq*/
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Field } from 'formik';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import deleteIcon from "../../../assets/images/delete.svg";
import editIcon from "../../../assets/images/edit.svg";

import ModalComponent from '../../../shared/components/modal/modal';
import ConfirmtionDialog from '../../ConfirmDialog/ConfirmationDialog';
import UploadAsset from '../../../features/campaign/components/upload';

import blueeditIcon from "../../../assets/images/blue-edit.svg";
import bluedeleteIcon from "../../../assets/images/blue-delete.svg";
import blueplusIcon from "../../../assets/images/blue-plus-ic.svg";
import previewIcon from "../../../assets/images/blue-preview.svg";

import file_upload_ic from "../../../assets/images/file-upload-ic.svg";
import close_icon from "../../../assets/images/close_icon.svg";

import './campaign.css';
import { withRouter } from 'react-router-dom';

import * as APIController from '../../../api/APIController.js';
import Notification from '../../../shared/components/notification/notification';
import uuid from 'react-uuid';
import CircularProgress from '@material-ui/core/CircularProgress';

import PerfectScrollbar from "react-perfect-scrollbar";

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
}));
const AssetForm = props => {
	const [CampaignId, setCampaignId] = React.useState('');
	const [directMailHTML, setDirectMailHTML] = React.useState('');
	const [ImageData, setImageData] = React.useState('');
	const [PreviewImageData, setPreviewImageData] = React.useState('');
	// const [MessageImage, setMessageImage] = React.useState('');
	const [campaignThemesId, setCampaignThemesId] = React.useState('');
	// const [HTMLPreviewContent, setHTMLPreviewContent] = React.useState('');
	const classes = useStyles();
	const [modalPreview, setModalPreview] = React.useState(null);
	const [modalAddEdit, setModalAddEdit] = React.useState(null);
	const [ModalImage, setModalImage] = React.useState(null);

	const [progress, setprogress] = React.useState(true);
	const [Loading, setLoading] = React.useState(true)
	const [IsLoading, setIsLoading] = React.useState(false);
	const [isThemeLoading, setThemeLoading] = React.useState(false);

	const [DeleteModel, setDeleteModel] = React.useState(false);
	const [DeleteAssetId, setDeleteAssetId] = React.useState('');

	const [orderData, setOrderData] = React.useState([]);
	const [CampaignThemes, setCampaignThemes] = React.useState([]);
	const [sourceProducts, setsourceProducts] = React.useState([]);
	const [CampaignAssets, setCampaignAssets] = React.useState([]);

	const campaignThemes = CampaignThemes;
	const campaignAssets = CampaignAssets;

	const initialValues = props.initialValues || {
		campaignTheme: {
			id: '0',
			productID: '',
			name: '',
			imagename: '',
			imageUrl: '',
			previewimagename: '',
			previewimageUrl: '',
			orderno: '',
			description: '',
			active: 1,
			action: {
				type: 'add',
				id: null
			}
		}
	};

	React.useEffect(() => {

		setCampaignId(props.history.location.state.campaignIdEdit)
		wsGetCampaignThemes();

		var pairs = [];
		for (let i = 0; i < 100; i++) {
			pairs.push({ ID: i + 1 });
		}
		setOrderData(pairs)

	}, [props])

	const DirectMailData = (data) => {
		setDirectMailHTML(data)
		setModalAddEdit(false)
	}

	const wsGetCampaignThemes = () => {
		APIController.GetCampaignThemes(props.history.location.state.campaignIdEdit)
			.then((response) => {
				setprogress(false)
				setLoading(false)
				if (response.error == null) {
					setCampaignThemes(response.object.data)
				}
				else if (response.error.message && response.error.message.length > 0) {
					handleNotification(response.error.message, true);
				}
				wsGetProductForDropdown()
			})
	}
	const wsGetProductForDropdown = () => {
		APIController.GetProductForDropdown()
			.then((response) => {
				// setLoadingProducts(false)
				if (response.error == null) {
					setsourceProducts(response.object.data)
				}
				else if (response.error.message && response.error.message.length > 0) {
					handleNotification(response.error.message, true);
				}
			})
	}
	const wsAddEditCampaignTheme = (ID, CampaignId, ProductID, Name, Description, OrderNo, ImageName, ImageURL, ImageData, PreviewImageName, PreviewImageURL, PreviewImageData, IsActive) => {
		setIsLoading(true)
		APIController.AddEditCampaignTheme(ID, CampaignId, ProductID, Name, Description, OrderNo, ImageName, ImageURL, ImageData, PreviewImageName, PreviewImageURL, PreviewImageData, IsActive)
			.then((response) => {
				setIsLoading(false)
				if (response.error == null) {
					if (response.object.status == 1) {
						handleNotification(response.object.message);
						wsGetCampaignThemes();
					} else {
						handleNotification(response.object.message, true);
					}
				}
				else if (response.error.message && response.error.message.length > 0) {
					handleNotification(response.error.message, true);
				}
			})
	}
	const wsDeleteCampaignTheme = (ID) => {
		APIController.DeleteCampaignTheme(ID)
			.then((response) => {
				if (response.error == null) {
					if (response.object.status == 1) {
						handleNotification(response.object.message);
						wsGetCampaignThemes();
					} else {
						handleNotification(response.object.message, true);
					}
				}
				else if (response.error.message && response.error.message.length > 0) {
					handleNotification(response.error.message)
				}
				setDeleteModel(false)
			})
	}
	const wsGetCampaignAssets = (CampaignID, CampaignThemeID) => {
		setThemeLoading(true)
		APIController.GetCampaignAssets(CampaignID, CampaignThemeID)
			.then((response) => {
				setprogress(false)
				if (response.error == null) {
					setCampaignAssets(response.object.data)
					setThemeLoading(false)
				}
				else if (response.error.message && response.error.message.length > 0) {
					handleNotification(response.error.message, true);
				}
			})
	}
	const wsDeleteCampaignAsset = (ID) => {
		APIController.DeleteCampaignAsset(ID)
			.then((response) => {
				if (response.error == null) {
					if (response.object.status == 1) {
						handleNotification(response.object.message);
						wsGetCampaignAssets(props.history.location.state.campaignIdEdit, campaignThemesId);
					} else {
						handleNotification(response.object.message, true);
					}
				}
				else if (response.error.message && response.error.message.length > 0) {
					handleNotification(response.error.message)
				}
			})
	}

	const wsGetCampaignAssetByID = (ID) => {
		APIController.GetCampaignAssetByID(ID)
			.then((response) => {
				if (response.error == null) {
					setModalPreview({
						isModalOpen: true,
						content: { content: response.object.data[0].AdContent },
					})
				}
				else if (response.error.message && response.error.message.length > 0) {
					handleNotification(response.error.message, true);
				}
			})
	}

	const wsDownloadCampaignAssetFile = (ID, TypeID, IsFreeAsset) => {
		APIController.DownloadCampaignAssetFile(ID, TypeID, IsFreeAsset)
			.then((response) => {
				if (response.error.message && response.error.message.length > 0) {
					handleNotification(response.error.message, true);
				}
			})
	}

	const [message, setMessage] = React.useState("");
	const [showNotification, setShowNotification] = React.useState(false);
	const [isError, setIsError] = React.useState(false);

	const handleNotification = (message, isError) => {
		setMessage(message)
		setShowNotification(true)
		setIsError(isError)

		setTimeout(() => {
			handleClearNotification();
		}, 4000)
	}
	const handleClearNotification = () => {
		setMessage('')
		setShowNotification(false)
		setIsError(false)
	}
	const AssetTabUploadContent = ({ values, fieldName, assetTypeId, isPrintProduct }) => {
		return (
			<div className="asset-tab-content">
				<div className="asset-tab-right">
					<div className="asset-table-head">
						<div className="asset-table-width-4"><h5>Size</h5></div>
						<div className="asset-table-width-5"><h5>Asset</h5></div>
					</div>
					{!!values.length && values.map((obj, index) => {
						return (
							<div className="asset-table-body">
								<div className="asset-table-width-4"><p><strong>{obj.Height + " X " + obj.Width}</strong></p></div>
								<div className="asset-table-width-5">
									{obj.IsUploaded == 0 ?
										<div className="asset-link"> {
											<a title="Add" style={{ cursor: 'pointer' }} onClick={e => {

												setModalAddEdit({
													isModalOpen: true,
													content: {
														titlefieldName: `${fieldName.trim()} ` + obj.Height + "x" + obj.Width,
														uploadAssetIdEdit: obj.IsUploaded,
														campaignIdEdit: CampaignId,
														assetTypeIdEdit: `${assetTypeId}`,
														assetSizeIdEdit: obj.ID,
														campaignThemesId: campaignThemesId,
													},
												})
											}}><img src={blueplusIcon} alt="icon" />Add</a>}
										</div>
										:
										<div className="asset-link"> {
											<>
												<div className="asset-link">
													<a title="Edit" style={{ cursor: 'pointer' }}
														onClick={e => {
															setModalAddEdit({
																isModalOpen: true,
																content: {
																	titlefieldName: `${fieldName.trim()} ` + obj.Height + "x" + obj.Width,
																	uploadAssetIdEdit: obj.AssetID,
																	campaignIdEdit: CampaignId,
																	assetTypeIdEdit: `${assetTypeId}`,
																	assetSizeIdEdit: obj.ID,
																	campaignThemesId: campaignThemesId,
																	isPrintProductEdit: false
																},
															})
														}}><img src={blueeditIcon} alt="icon" />Edit</a>
												</div>
												{isPrintProduct && <div className="asset-link">
													<a title="Edit" style={{ cursor: 'pointer' }}
														onClick={e => {
															setModalAddEdit({
																isModalOpen: true,
																content: {
																	titlefieldName: `${fieldName.trim()} ` + obj.Height + "x" + obj.Width,
																	uploadAssetIdEdit: obj.AssetID,
																	campaignIdEdit: CampaignId,
																	assetTypeIdEdit: `${assetTypeId}`,
																	assetSizeIdEdit: obj.ID,
																	campaignThemesId: campaignThemesId,
																	isPrintProductEdit: true,
																},
															})
														}}><img src={blueeditIcon} alt="icon" />Edit Print Ad Content</a>
												</div>}
												<div className="asset-link">
													<a title="Delete" style={{ cursor: 'pointer' }} onClick={e => {
														wsDeleteCampaignAsset(obj.AssetID)
													}}><img src={bluedeleteIcon} alt="icon" />Delete</a>
												</div>
												<div className="asset-link">
													<a title="" onClick={() =>
														wsGetCampaignAssetByID(obj.AssetID)} style={{ cursor: 'pointer' }}>
														<img src={previewIcon} alt="icon" />Preview</a>
												</div>
												<div className="asset-link">
													<a title="" onClick={() =>
														wsDownloadCampaignAssetFile(obj.AssetID, "1", "0")} style={{ cursor: 'pointer' }}>
														<img src={previewIcon} alt="icon" />Download</a>
												</div>
												{isPrintProduct && <div className="asset-link">
													<a title="" onClick={() =>
														wsDownloadCampaignAssetFile(obj.AssetID, "2", "0")} style={{ cursor: 'pointer' }}>
														<img src={previewIcon} alt="icon" />Download Print Ad Content</a>
												</div>}
											</>
										}
										</div>
									}
								</div>
							</div>
						)
					})}
				</div>
			</div>
		);
	}

	return (
		<div className="tab-content asset-tab-wrapper">
			<Formik initialValues={initialValues} onSubmit={(values) => { }}>{({ values, setFieldValue, handleSubmit }) => {

				return <form onSubmit={handleSubmit}>
					<ExpansionPanel>
						<ExpansionPanelSummary
							expandIcon={''}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<div className={classes.heading}>Campaign theme</div>
						</ExpansionPanelSummary>
						<ExpansionPanelDetails>
							<div className="asset-tab-content">
								<div className="asset-tab-left">

									<div className="tab_field">
										<select
											id={'productID'}
											name="reportType"
											defaultValue={values.campaignTheme.productID}
											value={values.campaignTheme.productID}
											onChange={(e) => setFieldValue('campaignTheme.productID', e.target.value)}>
											<option value={""}>Select Product</option>
											{sourceProducts.map((obj, index) => {
												return <option selected={obj.ID == values.campaignTheme.productID ? obj.ID : obj.ID} value={obj.ID} key={index}>{obj.Name}</option>
											})}
										</select>
									</div>


									<div className="tab_field">
										<Field name="campaignTheme.name" type="text" placeholder="Name" value={values.campaignTheme.name} />
									</div>
									<div className="tab_field">
										<Field style={{ height: '100px' }} as="textarea" name="campaignTheme.description" type="text" placeholder="Description" value={values.campaignTheme.description} />
									</div>
									<div className="tab_field">
										<select
											id={'orderno'}
											name="reportType"
											defaultValue={values.campaignTheme.orderno}
											value={values.campaignTheme.orderno}
											onChange={(e) => setFieldValue('campaignTheme.orderno', e.target.value)}>
											<option>Sequence #</option>
											{orderData.map((obj, index) => {
												return <option selected={obj.ID == values.campaignTheme.orderno ? obj.ID : obj.ID} value={obj.ID} key={index}>{obj.ID}</option>
											})}
										</select>
									</div>
									<div className="form_field" style={{ justifyContent: 'space-between' }}>
										<label>Is Active ?</label>
										<div style={{ display: 'flex' }}>
											<div className="custom-radio">
												<input type="radio" name="active" id="yes-text"
													value={values.campaignTheme.active}
													checked={values.campaignTheme.active != 0 ? true : false}
													onClick={(e) => setFieldValue('campaignTheme.active', 1)} />
												<label htmlFor="yes-text">Yes</label>
											</div>
											<div className="custom-radio" style={{ marginLeft: '20px' }}>
												<input type="radio" name="active" id="no-text"
													value={values.campaignTheme.active}
													checked={values.campaignTheme.active == 0 ? true : false}
													onClick={(e) => setFieldValue('campaignTheme.active', 0)} />
												<label htmlFor="no-text">No</label>
											</div>
										</div>
									</div>
									<div className="form_field">
										<label>Theme Image</label>
										<div className='form-file-input' style={{ width: '100%' }}>
											<input className='input-file' id='fileupload' name='files' type='file' onChange={(e) => {
												const file = e.target.files[0];
												if (file && (file.type.includes("svg") || file.type.includes("png") || file.type.includes("jpeg") || file.type.includes("jpg"))) {
													let file1 = e.target.files[0]
													file1['preview'] = URL.createObjectURL(e.target.files[0]);
													setFieldValue("campaignTheme.imagename", uuid() + '.' + file1.name.split('.')[1]);

													const image2base64 = require('image-to-base64');
													image2base64(file1.preview)
														.then((response) => { setImageData(response) }).catch((error) => { })
													setFieldValue('campaignTheme.imageUrl', file1.preview);
												}
											}} />
											{values.campaignTheme.imageUrl == '' ?
												<label htmlFor='fileupload' className='input-file-trigger'>
													<img src={file_upload_ic} alt='file-uplaod' /> Drag and drop your file here </label> :
												<div className='logo-preview'>
													<a title="" className="close-ic"><img src={close_icon} alt="close-ic" onClick={() => setFieldValue('campaignTheme.imageUrl', '')} /></a>
													<img src={values.campaignTheme.imageUrl} style={{ height: '170px', width: '230px' }} alt='' />
												</div>}
										</div>
										<p style={{ fontSize: '11px' }}> Image size: 250X250 px. Accepted files : .jpg, .jpeg, .png, .svg </p>
									</div>
									<div className="form_field">
										<label>Preview Image</label>
										<div className='form-file-input' style={{ width: '100%' }}>
											<input className='input-file' id='fileupload' name='files' type='file' onChange={(e) => {
												const file = e.target.files[0];
												if (file && (file.type.includes("svg") || file.type.includes("png") || file.type.includes("jpeg") || file.type.includes("jpg"))) {
													let file1 = e.target.files[0]
													file1['preview'] = URL.createObjectURL(e.target.files[0]);
													setFieldValue("campaignTheme.previewimagename", uuid() + '.' + file1.name.split('.')[1]);

													const image2base64 = require('image-to-base64');
													image2base64(file1.preview)
														.then((response) => { setPreviewImageData(response) }).catch((error) => { })
													setFieldValue('campaignTheme.previewimageUrl', file1.preview);
												}
											}} />
											{values.campaignTheme.previewimageUrl == '' || values.campaignTheme.previewimageUrl == undefined ?
												<label htmlFor='fileupload' className='input-file-trigger'>
													<img src={file_upload_ic} alt='file-uplaod' /> Drag and drop your file here </label> :
												<div className='logo-preview'>
													<a title="" className="close-ic"><img src={close_icon} alt="close-ic" onClick={() => setFieldValue('campaignTheme.previewimageUrl', '')} /></a>
													<img src={values.campaignTheme.previewimageUrl} style={{ height: '170px', width: '230px' }} alt='' />
												</div>}
										</div>
										<p style={{ fontSize: '11px' }}> Image size: 250X250 px. Accepted files : .jpg, .jpeg, .png, .svg </p>
									</div>
								</div>
								<div className="asset-tab-right">
									<div className="asset-table-head">
										<div style={{ width: '13%' }} className="asset-table-width-1"><h5>Action</h5></div>
										<div style={{ width: '37%' }} className="asset-table-width-2"><h5>Name</h5></div>
										<div style={{ width: '26%' }} className="asset-table-width-2"><h5>Product</h5></div>
										<div style={{ width: '9%', textAlign: 'center' }} className="asset-table-width-1"><h5>Seq.</h5></div>
										<div style={{ width: '15%' }} className="asset-table-width-2"><h5>Is Active</h5></div>
									</div>
									{<PerfectScrollbar style={{ maxHeight: '500px' }}>
										{!!campaignThemes.length ? campaignThemes.map((obj, index) => {
											return (
												<div className="asset-table-body" key={index}>
													<div style={{ width: '13%' }} className="asset-table-width-1">
														<a style={{ cursor: 'pointer', marginRight: '8px' }} title="" className="small-ic" onClick={(e) => {
															e.preventDefault();
															setFieldValue('campaignTheme.id', obj.ID);
															setFieldValue('campaignTheme.productID', obj.ProductID != null ? obj.ProductID : "");
															setFieldValue('campaignTheme.name', obj.Name);
															setFieldValue('campaignTheme.description', obj.Description != null ? obj.Description : "");
															setFieldValue('campaignTheme.orderno', obj.OrderNo);
															setFieldValue('campaignTheme.active', obj.IsActive ? 1 : 0);
															setFieldValue('campaignTheme.imagename', obj.ImageName);
															setFieldValue('campaignTheme.imageUrl', obj.ImageURL);
															setImageData(obj.ImageURL)
															setFieldValue('campaignTheme.previewimagename', obj.PreviewName);
															setFieldValue('campaignTheme.previewimageUrl', obj.PreviewURL);
															setPreviewImageData(obj.PreviewURL)
															setFieldValue('campaignTheme.action.type', 'edit');
															setFieldValue('campaignTheme.action.id', index);
														}}>
															<img src={editIcon} alt="icon" />
														</a>
														{/* <a style={{ cursor: 'pointer' }} title="" className="small-ic" onClick={(e) => {
															e.preventDefault();
															wsDeleteCampaignTheme(obj.ID)
														}}>
															<img src={deleteIcon} alt="icon" />
														</a> */}
														<a style={{ cursor: 'pointer', marginRight: '8px' }} title="" className="small-ic" onClick={(e) => {
															e.preventDefault();
															setDeleteAssetId(obj.ID)
															setDeleteModel(true)
														}}><img src={deleteIcon} alt="icon" />
														</a>
														<a style={{ cursor: 'pointer', marginRight: '0px' }} title="" onClick={() =>
															setModalImage({
																isModalOpen: true,
																content: { image: obj.ImageURL },
															})}>
															<img src={previewIcon} alt="icon" />
														</a>
													</div>
													<div style={{ width: '38%' }} className="asset-table-width-2"><p>{obj.Name}</p></div>
													<div style={{ width: '28%' }} className="asset-table-width-2"><p>{obj.ProductName}</p></div>
													<div style={{ width: '7%' }} className="asset-table-width-1">{obj.OrderNo}</div>
													<div className="asset-table-width-1">{obj.IsActiveLABEL}</div>
												</div>
											);
										}) :
											<div style={{ alignSelf: 'center', alignContent: 'center' }}>
												{progress ?
													<CircularProgress style={{ color: '#0a5c7f', alignSelf: 'center' }} /> :
													<label>Not Currently Available</label>}
											</div>}
									</PerfectScrollbar>}

								</div>
								<div className={IsLoading ? "tab-submit-btn button-field disable-btn" : "tab-submit-btn button-field"}>
									<input type="button" value="Save" name="save" onClick={() => {

										wsAddEditCampaignTheme(values.campaignTheme.id, CampaignId, values.campaignTheme.productID, values.campaignTheme.name,
											values.campaignTheme.description, values.campaignTheme.orderno, values.campaignTheme.imagename, '', ImageData,
											values.campaignTheme.previewimagename, '', PreviewImageData,
											values.campaignTheme.active)

										setFieldValue('campaignTheme.id', '0');
										setFieldValue('campaignTheme.productID', '');
										setFieldValue('campaignTheme.name', '');
										setFieldValue('campaignTheme.description', '');
										setFieldValue('campaignTheme.orderno', '');
										setFieldValue('campaignTheme.active', 1);
										setFieldValue('campaignTheme.imagename', '');
										setFieldValue('campaignTheme.imageUrl', '');
										setImageData('')
										setFieldValue('campaignTheme.previewimagename', '');
										setFieldValue('campaignTheme.previewimageUrl', '');
										setPreviewImageData('')
									}} />
								</div>

							</div>
						</ExpansionPanelDetails>
					</ExpansionPanel>

					<ExpansionPanel>
						<ExpansionPanelDetails>
							{Loading ? <CircularProgress style={{ alignItems: 'center' }} />
								:
								<select name='selectcampaigntheme'
									id={'selectcampaigntheme'}
									value={values.selectcampaigntheme}
									onChange={(e) => {
										setCampaignThemesId(e.target.value)
										wsGetCampaignAssets(props.history.location.state.campaignIdEdit, e.target.value)
									}}								>
									<option value={''}>Select Campaign theme</option>
									{campaignThemes.map((obj, index) => {
										return <option value={obj.ID} key={index}>{obj.Name}</option>
									})}
								</select>
							}
						</ExpansionPanelDetails>
					</ExpansionPanel>

					{isThemeLoading ? <CircularProgress style={{ alignItems: 'center' }} /> :
						!!campaignAssets.length && campaignAssets.map((obj, index) => {
							return (
								<ExpansionPanel>
									<ExpansionPanelSummary
										expandIcon={''}
										aria-controls="panel2a-content"
										id="panel2a-header"
									>
										<div className={classes.heading}>{obj.Name}</div>
									</ExpansionPanelSummary>
									<ExpansionPanelDetails>
										<AssetTabUploadContent values={obj.AssetSizes != null ? JSON.parse(obj.AssetSizes) : []} fieldName={obj.Name} assetTypeId={obj.ID} isPrintProduct={obj.IsPrintProduct} />
									</ExpansionPanelDetails>
								</ExpansionPanel>)
						})}
					<Notification
						isError={isError}
						message={message}
						showNotification={showNotification}
						clearNotification={handleClearNotification.bind(this)}
						closeNotification={handleClearNotification.bind(this)}
					/>
				</form>;
			}}
			</Formik>
			{ModalImage && ModalImage.isModalOpen && (
				<ModalComponent
					show={ModalImage.isModalOpen}
					handleClose={() => setModalImage(null)}>
					<div className='popup-wrap'>
						<img src={ModalImage.content.image} alt='place' />
						<img src={close_icon} alt='icon' className='gray-close-ic' onClick={() => setModalImage(null)} style={{ cursor: 'pointer' }} />
					</div>
				</ModalComponent>
			)}

			{modalPreview && modalPreview.isModalOpen && (
				<ModalComponent
					show={modalPreview.isModalOpen}
					handleClose={() => setModalPreview(null)}>
					<div className='popup-wrap'>
						<div className="asset-img" dangerouslySetInnerHTML={{ __html: modalPreview.content.content }}></div>
						<img src={close_icon} alt='icon' className='gray-close-ic' onClick={() => setModalPreview(null)} style={{ cursor: 'pointer' }} />
					</div>
				</ModalComponent>
			)}

			{modalAddEdit && modalAddEdit.isModalOpen && (
				<ModalComponent
					show={modalAddEdit.isModalOpen}
					handleClose={() => setModalAddEdit(null)}>
					<div className='popup-wrap'>
						<UploadAsset
							closeModal={() => setModalAddEdit(false)}
							titlefieldName={modalAddEdit.content.titlefieldName}
							uploadAssetIdEdit={modalAddEdit.content.uploadAssetIdEdit}
							campaignIdEdit={modalAddEdit.content.campaignIdEdit}
							assetTypeIdEdit={modalAddEdit.content.assetTypeIdEdit}
							assetSizeIdEdit={modalAddEdit.content.assetSizeIdEdit}
							isPrintProductEdit={modalAddEdit.content.isPrintProductEdit}
							campaignThemesId={modalAddEdit.content.campaignThemesId}
							DirectMailData={DirectMailData.bind(this)}
							DirectMailHTMLData={directMailHTML}
						/>
						<img src={close_icon} alt='icon' className='gray-close-ic' onClick={() => setModalAddEdit(null)} style={{ cursor: 'pointer' }} />
					</div>
				</ModalComponent>
			)}

			{DeleteModel &&
				<ModalComponent
					show={DeleteModel}
					handleClose={() => setDeleteModel(false)}>
					<ConfirmtionDialog
						title={"Delete Asset"}
						description={"Are you sure you want to delete this Theme?"}
						deletePress={() => wsDeleteCampaignTheme(DeleteAssetId)}
						closeModal={() => setDeleteModel(false)}
					/>
				</ModalComponent>}
		</div>
	);
};

export default withRouter(AssetForm);