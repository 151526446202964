/*eslint-disable eqeqeq*/
import React from "react";
import * as APIController from "../../../../api/APIController";
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";
import ModalComponent from "../../../../shared/components/modal/modal";
import close_icon from "../../../../assets/images/gray-close-ic.svg";
import Notification from '../../../../shared/components/notification/notification';
import TabComponent from "../../../../shared/components/tab/tab";
import PerfectScrollbar from "react-perfect-scrollbar";
import MyiFrame from "../../../campaign/components/myiFrame";
import * as AppConstants from "../../../../config/AppConstant";

import ProductTabSelection from '../../../digitalProduct/component/product-tab-selection';

import AddEditSocialPost from '../../../../shared/components/Calendar/AddEditSocialPost';
import moment from 'moment';

import TilesForAdBuilder from './tilesForAdBuilder';
import TilesForFreeAssets from './tilesForFreeAssets';

const FreeAssetsTiles = (props) => {
    const [CampaignName, setCampaignName] = React.useState(AppConstants.GetLocalStorage('localStorageCampaignName'));
    const [ProductName, setProductName] = React.useState(AppConstants.GetLocalStorage('localStorageProductName'));
    const [BrandName, setBrandName] = React.useState(AppConstants.GetLocalStorage('localStorageBrandName'));
    const [CampaignID, setCampaignID] = React.useState(localStorage.getItem("localStorageCampaignID"));

    const [defaultActiveTab, setDefaultActiveTab] = React.useState(0);
    const [selectValue, setSelectValue] = React.useState('Standard');

    const [Loading, setLoading] = React.useState(false);
    const [downloadloading, setdownloadloading] = React.useState(false);
    const [modal, setmodal] = React.useState(null);
    const [modalDec, setmodalDec] = React.useState(null);
    const [PreviewModalData, setPreviewModalData] = React.useState(null);
    const [AllData, setAllData] = React.useState([]);
    const [PreviewButtonName, setPreviewButtonName] = React.useState("");

    const [AssetsTypeData, setAssetsTypeData] = React.useState('');

    const [SegmentShow, setSegmentShow] = React.useState(false);
    const [SegmentStandard, setSegmentStandard] = React.useState(false);
    const [SegmentVS, setSegmentVS] = React.useState(false);
    const [SegmentVSP, setSegmentVSP] = React.useState(false);

    const handleSelectChange = (value) => {
        setSelectValue(value)
    };

    React.useEffect(() => {
        wsGetBrandsForHome(AppConstants.GetLocalStorage('localStorageProductID'))
    }, [props]);

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => { handleClearNotification(); }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    const wsGetBrandsForHome = (ProductID) => {
        setLoading(true);
        APIController.GetBrandsForHome(ProductID).then((response) => {
            if (response.object.data.Data.length) {
                setPreviewButtonName(response.object.data.Data[0].PreviewButtonName)
                setAllData(response.object.data.Data)

                AppConstants.SetLocalStorage("localStorageChildren", response.object.data.Data.Children != null && response.object.data.Data.Children)

                // setAssetsTypeData(response.object.data.Segment)
                response.object.data.Data.map((item) => {
                    SegmentFiltter(item.Children)
                })
            } else {
                setLoading(false);
            }
        })
    }

    let assetsType = [];
    const SegmentFiltter = (childrens) => {
        childrens != null && JSON.parse(childrens).map((obj, index) => {
            if (!obj.ShowAdBuilder) {
                if (obj.Segment == "Standard") {
                    setSegmentStandard(true); assetsType.push("Standard");
                    // setSelectValue("Standard")
                } else if (obj.Segment == "Vision Source") {
                    setSegmentVS(true); assetsType.push("Vision Source");
                    //  setSelectValue("Vision Source")
                } else if (obj.Segment == "VSP Premier") {
                    setSegmentVSP(true); assetsType.push("VSP Premier");
                    // setSelectValue("VSP Premier")
                }
                setSegmentShow(true)
            }
        })

        const uniqueSagment = assetsType.filter((val, id, array) => {
            return array.indexOf(val) == id;
        })

        setAssetsTypeData(uniqueSagment)
        setLoading(false);
    }


    const handleTabChange = (index, BrandID) => {
        setDefaultActiveTab(index);
        if (index == 0) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
            const container = document.getElementById(BrandID).offsetTop - 150;
            // container.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
            window.scrollTo({ top: container, behavior: 'smooth' });
        }
    };

    const [CurrentYear, setCurrentYear] = React.useState(new Date().getFullYear())
    const [CurrentMonth, setCurrentMonth] = React.useState(new Date().getMonth() + 1)
    const [ImageName, setImageName] = React.useState('')
    const [ImageData, setImageData] = React.useState('')
    const [Description, setDescription] = React.useState('')
    const [FinalArray, setFinalArray] = React.useState([])
    const [CalendarAssetsAllData, setCalendarAssetsAllData] = React.useState([])
    const [isLoading, setisLoading] = React.useState(false)

    const wsGetAutomationCalendarForPractice = (PracticeID, ID, postImageName, postImageData, description) => {
        setDescription(description)
        setImageName(postImageName)
        setImageData(postImageData)
        setisLoading(true)
        let monthData = [];

        APIController.GetAutomationCalendarForPractice(PracticeID, ID)
            .then((response) => {
                if (response.object.data.length) {

                    const CurrentMonthData = response.object.data.filter((e) => e.CalendarMonth == CurrentMonth)

                    setCurrentYear(CurrentMonthData[0].CalendarYear)
                    setCurrentMonth(CurrentMonthData[0].CalendarMonth)

                    let FinalArray = [], Counter = 0;

                    CurrentMonthData.map((item) => {
                        JSON.parse(item.CalendarAssets).map((itm) => {
                            if (Counter == 0) {
                                for (let t = 1; t < itm.DayNo; t++) {
                                    FinalArray.push({ DayNo: '0' })
                                }
                                Counter = itm.DayNo;
                            }
                            FinalArray.push({ ...itm, totalAssets: JSON.parse(item.CalendarAssets).filter((e) => e.AssetDate == itm.AssetDate).length });
                        })
                        setFinalArray(FinalArray)
                    })

                    if (response.object.data.length > 1) {
                        response.object.data.map((item, index) => {
                            monthData.push({ YearNo: item.CalendarYear, MonthNo: item.CalendarMonth, MonthName: AppConstants.monthsName[item.CalendarMonth - 1] })
                        })
                    }

                    setwholeState({
                        PreviewModalData: {
                            isModalOpen: true,
                            isEdit: 123,
                            headingText: AppConstants.ButtonText.AddPost,
                            Date: new Date().getDate(),
                        },
                        FinalArray: FinalArray,
                        ScheduledAtDate: moment(new Date()).format('YYYY-MM-DD'),
                        isReschedule: false,
                        isFromAsset: true,
                        CurrentMonth: CurrentMonth,
                        CurrentYear: CurrentYear,
                        description: description,
                        postImageName: postImageName,
                        postImageData: postImageData,
                        baseImageData: postImageData,
                        postTimeRanges: moment(new Date(), 'ddd DD-MMM-YYYY, hh:mm A').format('hh:mm A'),
                        CalendarAssetsAllData: response.object.data,
                        CurrentMonthName: AppConstants.monthsName[new Date().getMonth()],
                        PracticeMonthData: monthData,
                        FirstDateWeek: new Date(new Date().getFullYear(), new Date().getMonth(), 1).getDay() + 1,
                    })
                }
                setCalendarAssetsAllData(response.object.data)
                setPreviewModalData({
                    isModalOpen: true,
                    isEdit: 123,
                    headingText: AppConstants.ButtonText.AddPost,
                    Date: new Date().getDate(),
                })



                setisLoading(false)
            })
    }
    const CopyClipboard = (Description, Subcategory) => {
        setmodalDec({
            isModalOpen: true,
            content: { Description: Description, Subcategory: Subcategory },
        })
    }

    const CopyToClipboard = (Description, Subcategory) => {
        setmodalDec(null)
        navigator.clipboard.writeText(Description)
        handleNotification("Content Copied to Clipboard");
        AppConstants.LogEvent('Copy Text', AppConstants.CreateLogEventObject("", BrandName, CampaignName, ProductName, "", "", AppConstants.GetLocalStorage('localStorageAssetTabName'), Subcategory, "FreeAsset", "", "", ""))
    }

    const wsDownloadAsset = async (FileURL, Name, CampaignID) => {
        setdownloadloading(true)
        const image = await fetch(FileURL);

        const imageBlog = await image.blob()
        const href = window.URL.createObjectURL(imageBlog);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', Name); //or any other extension
        document.body.appendChild(link);
        link.click();

        wsDownloadFile(FileURL, Name, CampaignID)
    };

    const wsDownloadFile = (FileURL, Name, CampaignID) => {
        APIController.DownloadFile(FileURL, Name, CampaignID)
            .then((response) => {
                AppConstants.LogEvent('Download_Asset', AppConstants.CreateLogEventObject("", BrandName, CampaignName, ProductName, "", "", AppConstants.GetLocalStorage('localStorageAssetTabName'), Name, "", "", "", ""))
                setdownloadloading(false)
            }).catch((err) => { })
    }

    const [wholeState, setwholeState] = React.useState(
        {
            PreviewModalData: {
                isModalOpen: true,
                isEdit: 123,
                headingText: AppConstants.ButtonText.AddPost,
                Date: new Date().getDate(),
            },
            FinalArray: FinalArray,
            ScheduledAtDate: moment(new Date()).format('YYYY-MM-DD'),
            isReschedule: false,
            isFromAsset: true,
            CurrentMonth: CurrentMonth,
            CurrentYear: CurrentYear,
            description: Description,
            postImageName: ImageName,
            postImageData: ImageData,
            baseImageData: ImageData,
            postTimeRanges: moment(new Date(), 'ddd DD-MMM-YYYY, hh:mm A').format('hh:mm A'),
            CalendarAssetsAllData: CalendarAssetsAllData,
            CurrentMonthName: AppConstants.monthsName[new Date().getMonth()],
            FirstDateWeek: new Date(new Date().getFullYear(), new Date().getMonth(), 1).getDay() + 1,
        }
    );

    const [colorChange, setColorchange] = React.useState(false);
    const changeNavbarColor = () => {
        if (window.scrollY >= 1) {
            setColorchange(true);
        }
        else {
            setColorchange(false);
        }
    };
    window.addEventListener('scroll', changeNavbarColor);

    return (
        <section className="content-right-main ecp_resource" style={{ backgroundColor: colorChange ? '#f5f7fa' : '', paddingTop: '20px' }}>
            <div style={{ position: 'relative' }}>
                <div style={{ position: 'fixed', zIndex: 2, width: '93%', backgroundColor: colorChange ? '#f5f7fa' : '' }}>
                    <div className="content-right-heading tile-main-heading">{ProductName}</div>
                    <div className="content-heading-view">
                        {AllData.length > 1 &&
                            <div className="free-asset-tab" >
                                <TabComponent
                                    tabs={AllData}
                                    setTab={handleTabChange}
                                    classes={{ root: "tab-listing" }}
                                    activeTab={defaultActiveTab} />
                            </div>}

                        {/* {AssetsTypeData.length > 1 &&
                            <div className="select-style">
                                <select id={'segment'}
                                    name="reportType"
                                    defaultValue={selectValue}
                                    value={selectValue}
                                    onChange={(e) => handleSelectChange(e.target.value)}>
                                    {AssetsTypeData.map((obj, index) => {
                                        return <option selected={obj.Name} value={obj.Name} key={index}>{obj.Name}</option>
                                    })}
                                </select>
                            </div>} */}
                        {SegmentShow && AssetsTypeData.length > 1 &&
                            <div className="select-style">
                                <select
                                    className="select1"
                                    id={'segment'}
                                    name="reportType"
                                    defaultValue={selectValue}
                                    value={selectValue}
                                    onChange={(e) => handleSelectChange(e.target.value)}>

                                    {SegmentStandard && <option value='Standard'>Standard</option>}
                                    {SegmentVS && <option value='Vision Source'>Vision Source</option>}
                                    {SegmentVSP && <option value='VSP Premier'>VSP Premier</option>}
                                </select>
                            </div>}
                    </div>
                </div>
            </div>

            <div className="free-asset-main-content-box">
                {Loading ? (
                    <CircularProgress />
                ) : AllData.length == 0 ? (
                    <div className="center-div">
                        <label>Not currently available for this category</label>
                    </div>
                ) : (
                    <>
                        {ProductName != undefined &&
                            <p className="free-asset-tile-heading" style={{ borderBottom: "1.5px solid #dfdbdb", padding: "10px 0" }}>
                                All {ProductName.split('®')[0]}
                                {ProductName.match(/®/g) ? <sup>&reg;</sup> : ''}
                                {ProductName.split('®')[1]}
                            </p>}
                        <div className="main_tab_content">
                            {AllData.map((item, idx) =>
                                item.Children != null && JSON.parse(item.Children).map((obj, index) =>
                                    item.ShowAdBuilder
                                        ? <TilesForAdBuilder {...props} index={index} obj={obj} setmodal={setmodal} />
                                        : selectValue == obj.Segment && <TilesForFreeAssets {...props} index={index} obj={obj} PreviewButtonName={PreviewButtonName} setmodal={setmodal} downloadloading={downloadloading} wsDownloadAsset={wsDownloadAsset} CopyClipboard={CopyClipboard} wsGetAutomationCalendarForPractice={wsGetAutomationCalendarForPractice} isLoading={isLoading} selectValue={selectValue} />
                                )
                            )}
                        </div>

                        {AllData.length > 1
                            ? AllData.map((item, idx) =>
                                <div key={idx} id={item.ID}>
                                    {idx != 0 && item.Children != null && item.Name != undefined &&
                                        <p className="free-asset-tile-heading" style={{ borderBottom: item.ID == 0 ? "none" : "1.5px solid #dfdbdb", paddingBottom: "10px" }}>
                                            {item.Name.split('®')[0]}
                                            {item.Name.match(/®/g) ? <sup>&reg;</sup> : ''}
                                            {item.Name.split('®')[1]}
                                        </p>}
                                    <div className={idx != 0 ? "main_tab_content" : ""}>
                                        {item.Children != null && JSON.parse(item.Children).map((obj, index) =>
                                            item.ShowAdBuilder
                                                ? <TilesForAdBuilder {...props} index={index} obj={obj} setmodal={setmodal} />
                                                : selectValue == obj.Segment && <TilesForFreeAssets {...props} index={index} obj={obj} PreviewButtonName={PreviewButtonName} setmodal={setmodal} downloadloading={downloadloading} wsDownloadAsset={wsDownloadAsset} CopyClipboard={CopyClipboard} wsGetAutomationCalendarForPractice={wsGetAutomationCalendarForPractice} isLoading={isLoading} />
                                        )}
                                    </div>
                                </div>
                            ) : <></>}
                    </>
                )}
            </div>

            <Notification
                isError={isError}
                message={message}
                showNotification={showNotification}
                clearNotification={handleClearNotification.bind(this)}
                closeNotification={handleClearNotification.bind(this)}
            />

            {modal && modal.isModalOpen && (
                <ModalComponent show={modal.isModalOpen} handleClose={() => setmodal(null)}>
                    <div className="popup-wrap">
                        <div style={{ display: 'grid', width: '100%' }}>
                            <div className='box-head' style={{ marginBottom: '5px', padding: '8px' }}>
                                <h4>{PreviewButtonName}</h4>
                            </div>

                            {/* && modal.content.FullObject.Description != "" && modal.content.FullObject.Description != null */}

                            {modal.content.image == null || modal.content.image == undefined || modal.content.image == "" ?
                                <img src={modal.content.image} alt="place" />
                                : AppConstants.GetLocalStorage('localStorageShowCopyText') == "1"
                                    ? <div className="two-col-sec digital-welcome" style={{ padding: '0 0 0 10px', marginTop: '0px', boxShadow: 'none' }}>
                                        <div className="sec-left" style={{ marginTop: '0px', letterSpacing: '0px', width: "50%", fontSize: '18px' }} >
                                            <div dangerouslySetInnerHTML={{ __html: modal.content.FullObject.Description }} />
                                        </div>
                                        <div className="sec-right" style={{ padding: '0px', marginBottom: '10px', width: "50%" }}>
                                            {modal.content.image.indexOf('.zip') != -1 ?
                                                <div style={{ textAlign: 'center' }}><h5>This is .zip File, Please download and open</h5></div>
                                                : modal.content.image.indexOf('.pdf') != -1
                                                    ? <MyiFrame isFacbook={true} Height={'400px'} Width={'370px'} src={modal.content.image + "?" + Math.floor(Math.random() * (999999 - 100000 + 1) + 100000)} />
                                                    : modal.content.image.indexOf('.mp4') != -1
                                                        ? <video controls width="370px" maxHeight="400px" autoPlay >
                                                            <source src={modal.content.image} type="video/mp4" />
                                                        </video>
                                                        : modal.content.image.indexOf('.gif') != -1
                                                            ? <img src={modal.content.image} alt="place" style={{ height: '550px' }} />
                                                            : <img src={modal.content.image} alt="img" />}
                                        </div>
                                    </div>
                                    : modal.content.image.indexOf('.zip') != -1 ?
                                        <div style={{ textAlign: 'center' }}><h5>This is .zip File, Please download and open</h5></div>
                                        : modal.content.image.indexOf('.pdf') != -1
                                            ? <MyiFrame isFacbook={true} Height={'500px'} Width={'750px'} src={modal.content.image + "?" + Math.floor(Math.random() * (999999 - 100000 + 1) + 100000)} />
                                            : modal.content.image.indexOf('.mp4') != -1
                                                ? <video controls width="760px" maxHeight="360px" autoPlay >
                                                    <source src={modal.content.image} type="video/mp4" />
                                                </video>
                                                : modal.content.image.indexOf('.gif') != -1
                                                    ? <img src={modal.content.image} alt="place" style={{ height: '550px' }} />
                                                    : <img src={modal.content.image} alt="img" />
                            }
                            <div style={{ display: 'flex' }}>
                                {modal.content.FullObject.ShowDownload == 1 &&
                                    <div className={downloadloading ? "button-field disable-btn" : "button-field"}>
                                        <button onClick={(e) => wsDownloadAsset(modal.content.FullObject.ImageURL, modal.content.FullObject.Name + '.' + modal.content.FullObject.ImageURL.split('.').pop().split('?')[0], modal.content.FullObject.ID)}>
                                            {downloadloading ? AppConstants.ButtonText.Downloading : AppConstants.ButtonText.Download}
                                        </button>
                                    </div>}
                                {AppConstants.GetLocalStorage('localStorageShowCopyText') == "1" &&
                                    <div className="button-field">
                                        <button onClick={(e) => CopyClipboard(modal.content.FullObject.Description, modal.content.FullObject.Name)}>{AppConstants.ButtonText.CopyText}</button>
                                    </div>}
                            </div>
                        </div>
                        <a title="" className="gray-close-ic">
                            <img src={close_icon} alt="icon" onClick={() => setmodal(null)} />
                        </a>
                    </div>
                </ModalComponent>)}

            {modalDec && modalDec.isModalOpen && (
                <ModalComponent show={modalDec.isModalOpen} handleClose={() => setmodalDec(null)}>
                    <div className="popup-wrap" style={{ paddingBottom: '10px', display: 'grid' }}>

                        <div className='box-head' style={{ marginBottom: '5px', padding: '8px' }}>
                            <h4>{AppConstants.ButtonText.CopyText}</h4>
                        </div>
                        <div style={{ marginBottom: '10px', padding: '10px', alignSelf: 'center', height: '450px', width: '750px', border: '1px solid #cccccc', justifyContent: 'center', alignItems: 'center', }}>
                            <PerfectScrollbar> <div dangerouslySetInnerHTML={{ __html: modalDec.content.Description.replaceAll("\n", '<br />') }} /> </PerfectScrollbar>
                        </div>

                        <a title="" className="gray-close-ic">
                            <img src={close_icon} alt="icon" onClick={() => setmodalDec(null)} />
                        </a>
                        <div style={{ textAlign: 'center' }}>
                            <button className="button-field" onClick={(e) => CopyToClipboard(modalDec.content.Description.replaceAll(/<[^>]+>/g, ''), modalDec.content.Subcategory)}>{AppConstants.ButtonText.CopytoClipboard}</button>
                        </div>
                    </div>
                </ModalComponent>)}

            {!isLoading && PreviewModalData && PreviewModalData.isModalOpen && (
                <ModalComponent show={PreviewModalData.isModalOpen} >
                    <AddEditSocialPost
                        wholeState={wholeState}
                        handleClose={() => setPreviewModalData(null)}
                    // handleDelete={this.handleDelete.bind(this)}
                    // handleReload={this.wsGetAutomationCalendarForPractice.bind(this)}
                    />
                </ModalComponent>
            )}

        </section>
    );
};

export default FreeAssetsTiles;