/*eslint-disable eqeqeq*/
import React from 'react';
import * as APIController from '../../../../api/APIController.js';

import Notification from '../../../../shared/components/notification/notification';
import CircularProgress from '@material-ui/core/CircularProgress';

import PerfectScrollbar from "react-perfect-scrollbar";

import viewIcon from '../../../../assets/images/view_icon.png'
import settingIcon from "../../../../assets/images/settings.svg";

import Modal from '../../../../shared/components/modal/modal';
import ConfirmtionDialog from '../../../ConfirmDialog/ConfirmationDialog';
import close_icon from "../../../../assets/images/close_icon.svg";
import AssetViewer from './assetViewer';

const LogsPopupList = props => {
    const [openDialog, setOpenDialog] = React.useState(false);
    const [HTMLContent, setHTMLContent] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const [childrensData, setchildrensData] = React.useState([]);

    React.useEffect(() => {
        wsGetLandingPageTemplateLogs(props.templateID, props.practiceID)
    }, [props])

    const wsGetLandingPageTemplateLogs = (TemplateID, PracticeID) => {
        setIsLoading(true)
        APIController.GetLandingPageTemplateLogs(TemplateID, PracticeID)
            .then((response) => {
                if (response.error == null) {
                    setchildrensData(response.object.data)
                    setOpenDialog(false)
                    setIsLoading(false)
                }
            })
    }

    return (
        <section className="content-right-main campaign-page" style={{ width: '100%', minWidth: '1000px', padding: '0px' }}>
            <h2>{props.title}</h2>
            <div className="upload-asset-main">
                <div className="asset-tab-right">
                    <div className="asset-table-head">
                        <div style={{ width: '15%' }} className="asset-table-width-1"><h5>Practice Name</h5></div>
                        <div style={{ width: '15%' }} className="asset-table-width-1"><h5>Template Name</h5></div>
                        <div style={{ width: '15%' }} className="asset-table-width-22"><h5>Brand Name</h5></div>
                        <div style={{ width: '15%' }} className="asset-table-width-17"><h5>Log At</h5></div>
                        <div style={{ width: '40%' }} className="asset-table-width-17"><h5>Remarks</h5></div>
                    </div>
                    {<PerfectScrollbar style={{ maxHeight: '500px' }}>
                        {!!childrensData.length ? childrensData.map((obj, index) => {
                            return (
                                <div className="asset-table-body" key={index}>
                                    <div style={{ width: '15%' }} className="asset-table-width-22"><p>{obj.PracticeName}</p></div>
                                    <div style={{ width: '15%' }} className="asset-table-width-1"><p>{obj.TemplateName}</p></div>
                                    <div style={{ width: '13%' }} className="asset-table-width-22"><p>{obj.BrandName}</p></div>
                                    <div style={{ width: '17%' }} className="asset-table-width-22"><p>{obj.LogAt}</p></div>
                                    <div style={{ width: '40%' }} className="asset-table-width-22"><p>{obj.Remarks}</p></div>
                                </div>);
                        })
                            : <div style={{ textAlign: "center", marginTop: '2%' }}>
                                <label>Not Currently Available</label>
                            </div>}
                    </PerfectScrollbar>}
                </div>
            </div>
        </section>

    )
}
export default LogsPopupList;