/*eslint-disable eqeqeq*/
import React from "react";
import * as APIController from '../../../../api/APIController.js';
import Table from "../../../../shared/components/table/table";
import TableHead from "../../../../shared/components/table/tableHead";
import TableRow from "../../../../shared/components/table/tableRow";
import TableBody from "../../../../shared/components/table/tableBody";
import TableCell from "../../../../shared/components/table/tableCell";
import double_left_arrow from '../../../../assets/images/left-doble-arow.svg';
import left_arrow from '../../../../assets/images/left-arrow.svg';
import double_right_arrow from '../../../../assets/images/right-doble-arow.svg';
import right_arrow from '../../../../assets/images/right-arrow.svg';
import "react-perfect-scrollbar/dist/css/styles.css";

import PerfectScrollbar from "react-perfect-scrollbar";

import deleteIcon from '../../../../assets/images/delete.svg'
import editIcon from '../../../../assets/images/edit.svg'
import filtericon from '../../../../assets/images/ic_feather_filter.svg'

import Modal from '../../../../shared/components/modal/modal';
import ConfirmtionDialog from '../../../ConfirmDialog/ConfirmationDialog';
import Notification from '../../../../shared/components/notification/notification';
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";

const ProductList = (props) => {
	const headerList = ['action', "product", "sequence #", "Is Parent ?", "Is active ?"]
	const { currentPage, count, rowsPerPage, rowsPerPageOptions, list, to, from } = props;
	let productList = list;
	productList = list.slice(from, to)
	const [LoginDialog, setLoginDialog] = React.useState(false);
	const [DeleteId, setDeleteId] = React.useState('');

	const page = Math.floor(count / rowsPerPage) + 1;

	const handleChangeInCurrentPage = (value) => {
		if (value <= page && Number(parseInt(value))) {
			props.handleChangePage(Number(value));
		}
	}

	const wsDeleteProduct = (ID) => {
		APIController.DeleteProduct(ID)
			.then((response) => {
				if (response.error == null) {
					if (response.object.status == 1) {
						handleNotification(response.object.message);
					} else {
						handleNotification(response.object.message, true);
					}
					props.handlebind()
					setLoginDialog(false)
				}
				else if (response.error.message && response.error.message.length > 0) {
					handleNotification(response.error.message, true);
				}
			})
	}

	const [message, setMessage] = React.useState("");
	const [showNotification, setShowNotification] = React.useState(false);
	const [isError, setIsError] = React.useState(false);

	const handleNotification = (message, isError) => {
		setMessage(message)
		setShowNotification(true)
		setIsError(isError)

		setTimeout(() => {
			handleClearNotification();
		}, 4000)
	}

	const handleClearNotification = () => {
		setMessage('')
		setShowNotification(false)
		setIsError(false)
	}

	return (
		<>
			<h1>View Products</h1>
			<div className="table-main-wrap products-class">
				<PerfectScrollbar component='div'>
					<Table className="">
						<TableHead>
							<TableRow>
								{/* <TableCell>Action</TableCell> */}
								{headerList.map((obj, index) => {
									return <TableCell className={obj === "Is active ?" || obj === "Is Parent ?" ? 'center' : ''} key={index}>{obj}</TableCell>;
								})}
							</TableRow>
							<TableRow>
								<TableCell>
									<span><img src={filtericon} alt="delete" /></span>
								</TableCell>
								<TableCell>
									<input type="text" name="" placeholder="Search Product" onChange={(e) => props.applyFilter('Name', e.target.value, 'input')} />
								</TableCell>

								<TableCell>
									<input type="text" name="" placeholder="Search Sequence #" onChange={(e) => props.applyFilter('OrderNo', e.target.value, 'input')} />
								</TableCell>
								{/* <TableCell>
									<input type="text" name="" placeholder="Search Co-Op" onChange={(e) => props.applyFilter('CoOpPercentage', e.target.value, 'input')} />
								</TableCell> */}
								<TableCell>
									<select onChange={(e) => props.applyFilter('IsParent', e.target.value, 'select')}>
										<option value={""}>All</option>
										<option value={"YES"}>Yes</option>
										<option value={"NO"}>No</option>
									</select>
								</TableCell>
								<TableCell>
									<select onChange={(e) => props.applyFilter('IsActiveLABEL', e.target.value, 'select')}>
										<option value={""}>All</option>
										<option value={"YES"}>Yes</option>
										<option value={"NO"}>No</option>
									</select>
								</TableCell>

							</TableRow>
						</TableHead>
						<TableBody>
							{!!productList.length ? productList.map((obj, idnex) => {
								return (
									<TableRow key={idnex}>
										<TableCell>
											<span>
												<img src={editIcon} alt="edit" style={{ cursor: 'pointer', marginRight: '3px' }}
													onClick={() => {
														props.history.push({
															pathname: '/product/add',
															state: {
																productIdEdit: obj.ID,
																productActiveEdit: obj.IsActiveLABEL === "YES" ? 1 : 0,
																productIsParentEdit: obj.IsParent === "YES" ? 1 : 0,
															}
														})
													}} />
												<img src={deleteIcon} alt="delete" style={{ cursor: 'pointer', marginRight: '3px' }}
													onClick={() => {
														setDeleteId(obj.ID)
														setLoginDialog(true)
													}} />
											</span>
										</TableCell>
										<TableCell>
											{obj.Name}
										</TableCell>
										<TableCell>
											{obj.OrderNo}
										</TableCell>
										{/* <TableCell>
											{obj.CoOpPercentage}
										</TableCell> */}
										<TableCell className={obj.IsParent === "YES" ? 'green' : 'red'}>
											<span>{obj.IsParent === "YES" ? 'Yes' : 'No'}</span>
										</TableCell>
										<TableCell className={obj.IsActiveLABEL === "YES" ? 'green' : 'red'}>
											<span>{obj.IsActiveLABEL === "YES" ? 'Yes' : 'No'}</span>
										</TableCell>
									</TableRow>
								);
							}) : <TableRow >
								<TableCell colSpan={headerList.length} className="not_found">
									{props.progress ?
										<CircularProgress /> :
										<label>Not Currently Available</label>}
								</TableCell>
							</TableRow>}
						</TableBody>
					</Table>
				</PerfectScrollbar>
			</div>
			{LoginDialog && (
				<Modal
					show={LoginDialog}
					handleClose={() => setLoginDialog(false)}>
					<ConfirmtionDialog
						title={"Delete Product"}
						description={"Are you sure you want to delete this Product?"}
						deletePress={() => wsDeleteProduct(DeleteId)}
						closeModal={() => setLoginDialog(false)}
					/>
				</Modal>
			)}
			<Notification
				isError={isError}
				message={message}
				showNotification={showNotification}
				clearNotification={handleClearNotification.bind(this)}
				closeNotification={handleClearNotification.bind(this)}
			/>
			<div className="table-pagination">
				<div className="pagination-info">
					<p>Showing {from + 1} - {to > count ? count : to} of {count}</p>
				</div>
				<div className="pagination-control">
					<a href="#!" title="" className={`double-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(1)
						}}
					><img src={double_left_arrow} alt="left" /></a>
					<a href="#!" title="" className={`simple-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage - 1)
						}}
					><img src={left_arrow} alt="single_left" /></a>
					<span>Page</span>
					<input type="text" name="textbox" value={currentPage === 0 ? currentPage + 1 : currentPage} onChange={(e) => handleChangeInCurrentPage(e.target.value)} />
					<span>of {page}</span>
					<a href="#!" title="" className={`simple-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage + 1)
						}}
					><img src={right_arrow} alt="right" /></a>
					<a href="#!" title="" className={`double-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(page)
						}}><img src={double_right_arrow} alt="single_right" /></a>
					<select onChange={(e) => props.handleChangeRowsPerPage(parseInt(e.target.value))} defaultValue={rowsPerPage}>
						{
							rowsPerPageOptions.map((obj, index) => {
								return <option value={obj} key={index}>{obj}</option>
							})
						}
					</select>
				</div>
			</div>
		</>
	);
};

export default ProductList;
