import React from 'react'
import '../../component/bioTrue.css'

import * as APIController from "../../../../api/APIController.js";
import CircularProgress from '@material-ui/core/CircularProgress';
import * as AppConsatnt from "../../../../config/AppConstant";


const SocialMedia = (props) => {
	const [Description, setDescription] = React.useState(' ');
	const [Name, setName] = React.useState('');
	const [ImageURL, setImageURL] = React.useState('');
	const [Loading, setLoading] = React.useState(true)

	React.useEffect(() => {

		wsGetProductByID(props.history.location.state.productIdEdit)
	}, [props]);

	const wsGetProductByID = (ID) => {
		APIController.GetProductByID(ID)
			.then((response) => {
				setLoading(false)
				if (response.error == null) {
					setDescription(response.object.data[0].Description)
					setName(response.object.data[0].Name)
					setImageURL(response.object.data[0].DisplayImageURL)
				}
				else if (response.error.message && response.error.message.length > 0) {
					// handleNotification(response.error.message);
				}
			})
	}

	return (
		<>
			<section className='content-right-main'>
				<h1>
					{props.history.location.state.CampaignHeader.split('®')[0]}
					{props.history.location.state.CampaignHeader.match(/®/g) ? <sup>&reg;</sup> : ''}
					{props.history.location.state.CampaignHeader.split('®')[1]}{' '}
					{Name}
				</h1>
				{Loading ? <CircularProgress style={{ alignItems: 'center' }} />
					:
					<div className='two-col-sec digital-welcome'>
						<div className='sec-left'>
							<div dangerouslySetInnerHTML={{ __html: Description }} />
							<div className='button-field'>
								<button onClick={() => props.history.push({
									pathname: "/digital-product/selection/location-selection",
									state: {
										brandIdEdit: props.history.location.state.brandIdEdit,
										productIdEdit: props.history.location.state.productIdEdit,
										campaignIdEdit: props.history.location.state.campaignIdEdit,
										brandName: props.history.location.state.CampaignHeader
									}
								})}
								>{AppConsatnt.ButtonText.GetStarted}</button>
							</div>
						</div>
						<div className='sec-right'>
							<img src={ImageURL} alt='' />
						</div>
					</div>
				}
			</section>
		</>
	);
};

export default SocialMedia;
