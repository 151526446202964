/*eslint-disable eqeqeq*/
import React from "react";
import Table from "../../../../../shared/components/table/table";
import TableHead from "../../../../../shared/components/table/tableHead";
import TableRow from "../../../../../shared/components/table/tableRow";
import TableBody from "../../../../../shared/components/table/tableBody";
import TableCell from "../../../../../shared/components/table/tableCell";
import double_left_arrow from '../../../../../assets/images/left-doble-arow.svg';
import left_arrow from '../../../../../assets/images/left-arrow.svg';
import double_right_arrow from '../../../../../assets/images/right-doble-arow.svg';
import right_arrow from '../../../../../assets/images/right-arrow.svg';
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

import Notification from '../../../../../shared/components/notification/notification';
import CircularProgress from "../../../../../shared/components/CircularProgress/CircularProgress";
import * as AppConstants from "../../../../../config/AppConstant";

const LoginReportList = (props) => {
	const headerList = ["Practice Name", "Full Name", "Email Address", "Address1", "Address2", "City", "State", "Postcode", "Ship To No", "Login At", "VS", "VSP"]

	const { currentPage, count, rowsPerPage, rowsPerPageOptions, list, to, from } = props;
	let countryList = list;
	// countryList = list.slice(from, to)

	const page = Math.floor(count / rowsPerPage) + 1;

	const handleChangeInCurrentPage = (value) => {
		if (value <= page && Number(parseInt(value))) {
			props.handleChangePage(Number(value), PracticeName, FullName, EmailAddress, Address1, Address2, City, State, Postcode, ShipToNo, VS, VSP);
		}
	}

	const [message, setMessage] = React.useState("");
	const [showNotification, setShowNotification] = React.useState(false);
	const [isError, setIsError] = React.useState(false);

	const [PracticeName, setPracticeName] = React.useState("");
	const [FullName, setFullName] = React.useState("");
	const [EmailAddress, setEmailAddress] = React.useState("");
	const [Address1, setAddress1] = React.useState("");
	const [Address2, setAddress2] = React.useState("");
	const [City, setCity] = React.useState("");
	const [State, setState] = React.useState("");
	const [Postcode, setPostcode] = React.useState("");
	const [ShipToNo, setShipToNo] = React.useState("");
	const [VS, setVS] = React.useState("");
	const [VSP, setVSP] = React.useState("");

	const handleClearNotification = () => {
		setMessage('')
		setShowNotification(false)
		setIsError(false)
	}

	const _handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			props.handleApplyFilter(PracticeName, FullName, EmailAddress, Address1, Address2, City, State, Postcode, ShipToNo, VS, VSP)
		}
	}

	return (
		<>
			{/* <h1><PageTitle Title={AppConstants.ContactDetais.ContestSummaryReport} /></h1> */}
			<div className="table-main-wrap">
				<PerfectScrollbar component='div'>
					<Table>
						<TableHead>
							<TableRow> {headerList.map((obj, index) => { return <TableCell style={{ minWidth: '125px', textAlign: 'center', padding: '5px' }} key={index}>{obj}</TableCell>; })} </TableRow> <TableRow>
								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder="Practice Name" onKeyDown={_handleKeyDown} onChange={(e) => setPracticeName(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder="User Name" onKeyDown={_handleKeyDown} onChange={(e) => setFullName(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder="Email Address" onKeyDown={_handleKeyDown} onChange={(e) => setEmailAddress(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder="Address1" onKeyDown={_handleKeyDown} onChange={(e) => setAddress1(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder="Address2" onKeyDown={_handleKeyDown} onChange={(e) => setAddress2(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder="City" onKeyDown={_handleKeyDown} onChange={(e) => setCity(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: '150px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder="State" onKeyDown={_handleKeyDown} onChange={(e) => setState(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: '150px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder="Postcode" onKeyDown={_handleKeyDown} onChange={(e) => setPostcode(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder="ShipToNo" onKeyDown={_handleKeyDown} onChange={(e) => setShipToNo(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>  </TableCell>
								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>
									<select onChange={(e) => {
										setVS(e.target.value)
										props.handleApplyFilter(PracticeName, FullName, EmailAddress, Address1, Address2, City, State, Postcode, ShipToNo, e.target.value, VSP)
									}}>
										<option value={""}>All</option>
										<option value={"1"}>Yes</option>
										<option value={"0"}>No</option>
									</select>
								</TableCell>
								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>
									<select onChange={(e) => {
										setVS(e.target.value)
										props.handleApplyFilter(PracticeName, FullName, EmailAddress, Address1, Address2, City, State, Postcode, ShipToNo, VS, e.target.value)
									}}>
										<option value={""}>All</option>
										<option value={"1"}>Yes</option>
										<option value={"0"}>No</option>
									</select>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!!countryList.length ? countryList.map((obj, idnex) => {
								return (
									<TableRow key={idnex}>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.PracticeName}</TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.FullName}</TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.EmailAddress}</TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.Address1}</TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.Address2}</TableCell>

										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.City}</TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.State}</TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.Postcode}</TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.ShipToNo}</TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.LoginAt}</TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }} className={obj.VS === "Yes" ? 'green' : 'red'}> <span>{obj.VS == "Yes" ? 'Yes' : 'No'}</span> </TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }} className={obj.VSP === "Yes" ? 'green' : 'red'}> <span>{obj.VSP == "Yes" ? 'Yes' : 'No'}</span> </TableCell>
									</TableRow>
								);
							}) : <TableRow>
								<TableCell colSpan={headerList.length} className="not_found">
									{props.progress ? <CircularProgress /> : <label>Not Currently Available</label>}
								</TableCell>
							</TableRow>}
						</TableBody>
					</Table>
				</PerfectScrollbar>
			</div>
			<Notification
				isError={isError}
				message={message}
				showNotification={showNotification}
				closeNotification={handleClearNotification.bind(this)}
			/>

			<div className="table-pagination">
				<div className="pagination-info">
					<p>Showing {from + 1} - {to > count ? count : to} of {count}</p>
				</div>
				<div className="pagination-control">
					<a href="#!" title="" className={`double-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(1)
						}}
					><img src={double_left_arrow} alt="left" /></a>
					<a href="#!" title="" className={`simple-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage - 1)
						}}
					><img src={left_arrow} alt="single_left" /></a>
					<span>Page</span>
					<input type="text" name="textbox" value={currentPage === 0 ? currentPage + 1 : currentPage} onChange={(e) => handleChangeInCurrentPage(e.target.value)} />
					<span>of {page}</span>
					<a href="#!" title="" className={`simple-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage + 1)
						}}
					><img src={right_arrow} alt="right" /></a>
					<a href="#!" title="" className={`double-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(page)
						}}><img src={double_right_arrow} alt="single_right" /></a>
					<select onChange={(e) => props.handleChangeRowsPerPage(e.target.value, PracticeName, FullName, EmailAddress, Address1, Address2, City, State, Postcode, ShipToNo, VS, VSP)} defaultValue={rowsPerPage}>
						{
							rowsPerPageOptions.map((obj, index) => {
								return <option value={obj} key={index}>{obj}</option>
							})
						}
					</select>
				</div>
			</div>
		</>
	);
};

export default LoginReportList;
