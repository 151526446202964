import React, { Component } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import * as AppConsatnt from "../../../config/AppConstant";

class CircularProgres extends Component {
    render() {
        return (
            <>{AppConsatnt.GetMainClassName() == "app-main" ?
                <div style={{ width: '100%', textAlign: "center" }}>
                    <CircularProgress style={{ color: "#0a5c7f", alignSelf: "center" }} />
                </div> :
                <div style={{ width: '100%', textAlign: "center" }} >
                    <CircularProgress style={{ color: "#fa8930", alignSelf: "center" }} />
                </div>
            }</>)
    }
}


export default CircularProgres;