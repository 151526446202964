/*eslint-disable eqeqeq*/
import React from 'react';
import SupplyToolReportList from '../../../component/componentSupplytool/SupplyToolReport/supplyToolReportList';
import * as APIController from '../../../../../api/APIController.js';
import Notification from '../../../../../shared/components/notification/notification';
import PageTitle from "../../../../../config/pageTitle";

class SupplyToolReportContainer extends React.Component {

    state = {
        currentPage: 1,
        count: 0,
        list: [],
        rowsPerPage: 25,
        rowsPerPageOptions: [25, 50, 100, 'All'],
        from: 0,
        to: 25,
        progress: true,
        message: '',
        showNotification: false,
        isError: false,
        showMessage: this.props.history.location.state != null && this.props.history.location.state.showMessage,
        CurrentYear: new Date().getFullYear(),
        Years: []
    }

    componentDidMount() {
        this.wsGetSupplyToolReportByPricePerBrand(null, null, this.state.CurrentYear);
        this.props.history.replace('/supplyToolReport', null);

        let years = [];
        for (let year = 2023; year <= this.state.CurrentYear; year++) {
            years.push(year);
        }

        this.setState({ Years: years })
    }

    handleYearChange = (event) => {
        this.setState({ CurrentYear: parseInt(event.target.value) });
    }

    handlebind() {
        this.wsGetSupplyToolReportByPricePerBrand(null, null, this.state.CurrentYear)
    }

    wsGetSupplyToolReportByPricePerBrand(PracticeName, LenseType, ReportYear) {
        this.setState({ progress: true, });
        APIController.GetSupplyToolReportByPricePerBrand(PracticeName, LenseType, ReportYear)
            .then((response) => {
                if (response.object.status == 0 && response.object.data.length != 0) {
                    this.setState({
                        count: response.object.data.length,
                        list: response.object.data,
                        progress: false,
                    })
                } else { this.setState({ list: [], progress: false }) }
            })
    }


    render() {
        return (
            <>
                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    clearNotification={this.handleClearNotification}
                    closeNotification={this.handleClearNotification}
                />
                <section className="content-right-main">

                    <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                        <h1><PageTitle Title="Price per brand/box by practice" /></h1>
                        <div style={{ display: 'flex', alignItems: 'baseline' }}>
                            <select value={this.state.CurrentYear} onChange={this.handleYearChange} style={{ borderRadius: '5px', border: '1px solid #CCCCCC', padding: '6px', width: '100px' }}>
                                {this.state.Years.map(year => (
                                    <option key={year} value={year}>{year}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <SupplyToolReportList {...this.state}
                        history={this.props.history}
                        handlebind={this.handlebind.bind(this)}
                        handleChangeRowsPerPage={this.handleRowsPerPageChange}
                        handleChangePage={this.handlePageChange}
                        applyFilter={this.handleApplyFilter}
                    />
                </section>
            </>
        )
    }

    handleRowsPerPageChange = (value, PracticeName, LenseType, ReportYear) => {
        let from = 0
        let to = value;
        this.setState({ progress: true, rowsPerPage: value, currentPage: 1, to: to, from: from })
        this.wsGetSupplyToolReportByPricePerBrand(PracticeName, LenseType, ReportYear);
    }

    handlePageChange = (values, PracticeName, LenseType, ReportYear) => {
        let from = values === 1 ? 0 * this.state.rowsPerPage : (values - 1) * this.state.rowsPerPage
        let to = values === 1 ? this.state.rowsPerPage : (from + this.state.rowsPerPage);
        this.setState({ currentPage: Number(values), to: to, from: from })
        this.wsGetSupplyToolReportByPricePerBrand(PracticeName, LenseType, ReportYear);
    }

    handleApplyFilter = (PracticeName, LenseType, ReportYear) => {
        this.wsGetSupplyToolReportByPricePerBrand(PracticeName, LenseType, ReportYear)
    }
    handleNotification = (message, isError) => {
        this.setState({
            message: message,
            showNotification: true,
            isError: isError
        })

        setTimeout(() => {
            this.handleClearNotification();
        }, 4000)
    }

    handleClearNotification = () => {
        this.setState({
            message: '',
            showNotification: false,
            isError: false
        })
    }
}

export default SupplyToolReportContainer;