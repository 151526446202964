/*eslint-disable eqeqeq*/
import React, { useState } from "react";
import close_ic from "../../../assets/images/close-ic.svg";
import otp_ic from "../../../assets/images/verify-otp-ic.svg";
import * as APIController from "../../../../src/api/APIController";
import { Formik, ErrorMessage, Field } from "formik";
import * as yup from "yup";
import Notification from "../../../shared/components/notification/notification";
const Verify = (props) => {
	const [otp, setOtp] = useState("");
	const [IsLoading, setIsLoading] = React.useState(false);
	const initialValues = { otpfield: "" }

	const wsVerifyOTP = (otp) => {
		if (otp.length > 5) {
			setIsLoading(true)
			APIController.VerifyOTP(otp).then((response) => {
				if (response.error == null) {
					setIsLoading(false)
					if (response.object.status == 1) {
						props.history.push("/home");
					}
					else {
						handleNotification(response.object.message, true);
					}
				} else if (response.error.message && response.error.message.length > 0) {
					handleNotification(response.error.message, true);
				}
			});
		} else if (otp != "") {
			handleNotification("OTP is required", true);
			initialValues.otpfield = "";
		}
	};

	const wsResendOTP = () => {
		APIController.ResendOTP().then((response) => {
			if (response.error == null) {
				if (response.object.status == 1) {
					handleNotification(response.object.message);
				}
				else {
					handleNotification(response.object.message, true);
				}
			} else if (response.error.message && response.error.message.length > 0) {
				handleNotification(response.error.message, true);
			}
		});
	};
	const [message, setMessage] = React.useState("");
	const [showNotification, setShowNotification] = React.useState(false);
	const [isError, setIsError] = React.useState(false);

	const handleNotification = (message, isError) => {
		setMessage(message);
		setShowNotification(true);
		setIsError(isError);

		setTimeout(() => {
			handleClearNotification();
		}, 4000);
	};

	const handleClearNotification = () => {
		setMessage("");
		setShowNotification(false);
		setIsError(false);
	};
	return (
		<div className='popup-box verify-otp-popup'  >
			<div className='box-heading'>
				<img src={otp_ic} alt='icon' />
				<h2 className='h1'>Verify OTP</h2>
				<button className='close-btn' onClick={props.closeModal}>
					<img src={close_ic} alt='icon' />
				</button>
			</div>
			<div className='box-content' >
				<p> We've sent a one time password to your registered email address. Please enter the 6-digit code below. </p>
				<Formik
					initialValues={initialValues}
					validationSchema={validateVerifyOTPForm}
					validateOnMount={false}
					onSubmit={(values) => {
						// wsVerifyOTP(values.otpfield);
					}}>
					{({ values, handleSubmit, errors, touched }) => {
						return (
							<form onSubmit={handleSubmit} >
								<div
									className={`form-field ${errors &&
											touched.otpfield &&
											errors.otpfield &&
											otp.length == 0
											? "error"
											: ""
										}`}
									style={{ textAlign: "center" }}>

									{/* <label>Please Enter OTP</label> */}
									<div className='multiple-field' >
										<Field
											type='text'
											placeholder=''
											id='1'
											name='otpfield'
											value={values.otp1}
											maxLength={1}
											onChange={(e) => {
												if (e.target.value.length >= 0) {
													setOtp(replaceAt(0, e.target.value, otp));
													document.getElementById('2').focus();
												}
											}}
										/>

										<Field
											type='text'
											placeholder=''
											id='2'
											name='otpfield'
											value={values.otp1}
											maxLength={1}
											onChange={(e) => {
												if (e.target.value.length >= 0) {
													setOtp(replaceAt(1, e.target.value, otp));
													document.getElementById('3').focus();
												}
											}}
										/>

										<Field
											type='text'
											placeholder=''
											id='3'
											name='otpfield'
											value={values.otp1}
											maxLength={1}
											onChange={(e) => {
												if (e.target.value.length >= 0) {
													setOtp(replaceAt(2, e.target.value, otp));
													document.getElementById('4').focus();
												}
											}}
										/>

										<Field
											type='text'
											placeholder=''
											id='4'
											name='otpfield'
											value={values.otp1}
											maxLength={1}
											onChange={(e) => {
												if (e.target.value.length >= 0) {
													setOtp(replaceAt(3, e.target.value, otp));
													document.getElementById('5').focus();
												}
											}}
										/>

										<Field
											type='text'
											placeholder=''
											id='5'
											name='otpfield'
											value={values.otp1}
											maxLength={1}
											onChange={(e) => {
												if (e.target.value.length >= 0) {
													setOtp(replaceAt(4, e.target.value, otp));
													document.getElementById('6').focus();
												}
											}}
										/>

										<Field
											type='text'
											placeholder=''
											id='6'
											name='otpfield'
											value={values.otp1}
											maxLength={1}
											onChange={(e) => {
												if (e.target.value.length >= 0) {
													setOtp(replaceAt(5, e.target.value, otp));
													document.getElementById('btn').focus();
												}
											}}
										/>

									</div>
									<div >
										{errors &&
											touched.otpfield &&
											errors.otpfield &&
											otp.length == 0 && (
												<span className='error-msg' style={{ marginTop: '12.5%' }}>
													<ErrorMessage
														name='otpfield'
														render={(err) => {
															return err;
														}}
													/>
												</span>
											)}
									</div>
								</div>

								<div className={IsLoading ? "form-field button-field disable-btn" : "form-field button-field"}>
									<input
										type='submit'
										value={IsLoading ? "verifying..." : "verify OTP"}
										id={'btn'}
										name='verify-OTP'
										onClick={() => {
											wsVerifyOTP(otp)
											// props.verifyOtp(otp)
										}}
									/>

								</div>

								<div> <a style={{ cursor: "pointer" }} onClick={() => { wsResendOTP(); }} title=''> Resend OTP </a></div>
							</form>
						)
					}}
				</Formik>
			</div>
			<Notification
				isError={isError}
				message={message}
				showNotification={showNotification}
				closeNotification={handleClearNotification.bind(this)}
			/>
		</div>
	);
};

/**
 *  replaceAt function is used to replace the otp string at specified number
 * @param {*} index
 * @param {*} chr
 * @param {*} str
 */
const replaceAt = (index, chr, str) => {
	return str.substr(0, index) + chr + str.substr(index + 1);
};
const validateVerifyOTPForm = yup.object().shape({
	otpfield: yup.string().required("OTP is required"),
});

export default Verify;