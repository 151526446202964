import React, { Component } from 'react'
import Orderlist from "./Orderlist";
import * as APIController from "../../../api/APIController.js";
import Modal from '../../../shared/components/modal/modal';
import { Typography, Grid } from '@material-ui/core';
import Notification from '../../../shared/components/notification/notification';
import ConfigureReportForm from "./ConfigureReportForm";
export default class ConfirmReport extends Component {
	state = {
		currentPage: 0,
		count: 0,
		rowsPerPage: 25,
		rowsPerPageOptions: [25, 10, 5],
		list: [],
		list1: [],
		from: 0,
		to: 25,
		progress: true,
		message: "",
		showNotification: false,
		isError: false,
		AddEditPopup: false,
		showMessage:
			this.props.history.location.state != null &&
			this.props.history.location.state.showMessage,
		Message: '',
		EditData: []
	};

	componentDidMount() {
		this.wsGetOrderReportIDs();
	}
	handleNotification = (message, isError) => {
		this.setState({
			message: message,
			showNotification: true,
			isError: isError,
		});

		setTimeout(() => {
			this.handleClearNotification();
		}, 4000);
	};

	handleClearNotification = () => {
		this.setState({
			message: "",
			showNotification: false,
			isError: false,
		});
	};

	handlebind() {
		this.wsGetOrderReportIDs();
	}

	handleAddEditPopup(data) {
		this.setState({ AddEditPopup: true, EditData: data })
	}

	wsGetOrderReportIDs() {
		this.setState({ progress: true, });
		APIController.GetOrderReportIDs(this.props.location.state.OrderIDDetails).then((response) => {
			if (response.error == null) {
				this.setState({
					list: response.object.data,
					list1: response.object.data,
					count: response.object.data.length,
					progress: false,
				});
			} else if (response.error.message && response.error.message.length > 0) {
				this.handleNotification(response.error.message, true);
			}
		});
	}

	/**
	 * handleRowsPerPageChange method is used to update rows per page
	 * it will reset page to 1
	 */

	handleRowsPerPageChange = (value) => {
		let from = 0;
		let to = value;
		this.setState({ rowsPerPage: value, currentPage: 1, to: to, from: from });
	};

	/**
	 * handlePageChange method is used to change page
	 */

	handlePageChange = (values) => {
		let from =
			values === 1
				? 0 * this.state.rowsPerPage
				: (values - 1) * this.state.rowsPerPage;
		let to =
			values == 1
				? this.state.rowsPerPage
				: parseInt(from) + parseInt(this.state.rowsPerPage);
		this.setState({ currentPage: Number(values), to: to, from: from });
	};

	/**
	 * handleApplyFilter method is used to apply filter on every columns
	 */
	handleApplyFilter = (tag, values, type) => {
		if (values !== "") {

			let searchCountrys = [];

			if (type === "select") {
				searchCountrys = this.state.list1.filter(
					(us) => us[tag].toLowerCase() === values.toLowerCase()
				);
			} else {
				searchCountrys = this.state.list.filter(
					(us) => us[tag].toLowerCase().indexOf(values.toLowerCase()) > -1
				);
			}
			this.setState({
				list: searchCountrys,
				currentPage: 0,
				count: searchCountrys.length,
				rowsPerPage: 25,
				rowsPerPageOptions: [25, 10, 5],
				from: 0,
				to: 25,
				progress: false,
			});
		} else {
			this.wsGetOrderReportIDs();
			this.setState({
				currentPage: 0,
				rowsPerPage: 25,
				rowsPerPageOptions: [25, 10, 5],
				from: 0,
				to: 25,
			});
		}
	};

	wsAddEditOrderReportIDs(values) {
		let OrderID = this.props.history.location.state.OrderIDDetails
		let ID = this.state.EditData == '' ? 0 : this.state.EditData.ID
		let OrderDetailsID = values.OrderDetailID
		let ReportTypeID = values.ReportTypeID
		let dsUser = values.dsUser
		let dsAccounts = values.dsAccount
		let CampaignID = values.CampaignID
		let CampaignID1 = values.CampaignID1
		let CampaignID2 = values.CampaignID2
		APIController.AddEditOrderReportIDs(OrderID, ID, OrderDetailsID, ReportTypeID, dsUser, dsAccounts, CampaignID, CampaignID1, CampaignID2)
			.then((response) => {
				if (response.error == null) {
					if (response.object.status == 1) {
						this.handleNotification(response.object.message);
						this.setState({ AddEditPopup: false })
						this.handlebind()
					} else {
						this.handleNotification(response.object.message, true);
						this.setState({ AddEditPopup: false })
					}
				}
				else if (response.error.message && response.error.message.length > 0) {
					this.handleNotification(response.error.message, true);
				}
			})
	}
	render() {
		return (
			<>
				<Notification
					isError={this.state.isError}
					message={this.state.message}
					showNotification={this.state.showNotification}
					clearNotification={this.handleClearNotification}
					closeNotification={this.handleClearNotification}
				/>
				<section className='content-right-main'>

					<div className='button-field button-field-country'>
						<button
							onClick={() => this.setState({ EditData: [], AddEditPopup: true })}>
							+ Add Report
                		</button>
					</div>

					<Orderlist
						{...this.state}
						OrderNo={this.props.history.location.state.OrderNo}
						history={this.props.history}
						handlebind={this.handlebind.bind(this)}
						handleAddEditPopup={this.handleAddEditPopup.bind(this)}
						handleChangeRowsPerPage={this.handleRowsPerPageChange}
						handleChangePage={this.handlePageChange}
						applyFilter={this.handleApplyFilter}
						handleNotification={this.handleNotification}
					/>
					{this.state.AddEditPopup && (
						<Modal
							show={this.state.AddEditPopup}
							handleClose={() => this.setState({ AddEditPopup: false })}>
							<ConfigureReportForm
								Message={this.state.Message}
								OrderId={this.props.history.location.state.OrderIDDetails}
								EditData={this.state.EditData}
								title={this.state.EditData == '' ? "Add Configure Reports":"Edit Configure Reports"}
								SavePress={this.wsAddEditOrderReportIDs.bind(this)}
								closeModal={() => this.setState({ AddEditPopup: false })}
							/>
						</Modal>
					)}
				</section>
			</>

		)
	}
}
