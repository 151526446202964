/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import close_ic from '../../../assets/images/close-ic.svg'
import * as yup from 'yup';
import Notification from '../../../shared/components/notification/notification';
import * as APIController from '../../../api/APIController';
import Input from 'react-phone-number-input/input';
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";
import * as AppConstants from "../../../config/AppConstant";

const validateUsersForm = yup.object().shape({
    ShipToNo: yup.string().required('Ship To No is required'),
    PracticeName: yup.string().required('Practice Name is required'),
    PhoneNo: yup.string().required('Phone No is required'),
    Address1: yup.string().required('Address 1 is required'),
    Postcode: yup.string().required('Postcode is required'),
    City: yup.string().required('City is required'),
    State: yup.string().required('State is required'),
});

const PracticeForm = props => {
    let initialValues = {
        ShipToNo: props.EditData.ShipToNo,
        PracticeName: props.EditData.PracticeName,
        PhoneNo: props.EditData.PhoneNo,
        Address1: props.EditData.Address1,
        Address2: props.EditData.Address2,
        Postcode: props.EditData.Postcode,
        City: props.EditData.City,
        State: props.EditData.State,
        VisionSource: props.EditData.VisionSourceLABEL === "YES" ? 1 : 0,
        VSP: props.EditData.VSPLABEL === "YES" ? 1 : 0
    }

    React.useEffect(() => {
        initialValues = initialValues.PhoneNo
        props.EditData != "" && setIsShowField(true)
        wsGetBrand()
    }, [props])

    const [IsLoading, setIsLoading] = React.useState(false);
    const [mainLoading, setMainLoading] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [isShowField, setIsShowField] = React.useState(false);
    const [isShipToNoEdit, setIsShipToNoEdit] = React.useState(false);
    const [counter, setCounter] = React.useState(0);

    const [BrandLoading, setBrandLoading] = React.useState(true);
    const [BrandsList, setBrandsList] = React.useState([]);


    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)
        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    const wsVerifyClinic = (ShipToNo, Postcode, values) => {
        setIsLoading(true)
        setMainLoading(true)
        APIController.VerifyClinic(ShipToNo, Postcode)
            .then((response) => {
                if (response.error == null) {
                    if (response.object.status == 0) {
                        handleNotification(response.object.message, true)
                        setCounter(counter + 1)
                        if (counter == 3) {
                            setFieldValues(values, response.object.data[0], Postcode)
                        }
                        setMainLoading(false)
                    } else if (response.object.status == 1) {
                        setFieldValues(values, response.object.data[0])
                        setMainLoading(false)
                    } else if (response.object.status == 2) {
                        handleNotification(response.object.message, true)
                        setMainLoading(false)
                    } else {
                        handleNotification(response.object.message, true);
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
                setIsLoading(false)

            })
    }

    const setFieldValues = (values, data, Postcode) => {
        values.PracticeName = data.PracticeName
        values.Postcode = data.ZIPCode || Postcode
        values.PhoneNo = data.PhoneNo
        values.Address1 = data.Address1
        values.Address2 = data.Address2
        values.City = data.City
        values.State = data.State
        values.VisionSource = data.Customer == 'Vision Source' ? 1 : 0
        values.VSP = data.VSP == "No" ? 0 : 1

        setIsShowField(true)
        setCounter(0)
        setIsShipToNoEdit(true)
        setMainLoading(false)
    }

    const wsAddEditPracticeAddress = (PracticeID, ParentCompanyID, ShipToNo, PracticeName, PhoneNo, Address1, Address2, Postcode, VisionSource, VSP, Brands) => {
        setIsLoading(true)
        APIController.AddEditPracticeAddress(PracticeID, ParentCompanyID, ShipToNo, PracticeName, PhoneNo, Address1, Address2, Postcode, VisionSource, VSP, Brands)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    if (response.object.status == 1) {
                        handleNotification(response.object.message)
                        props.handlebind()
                        setTimeout(() => {
                            props.closeModal()
                        }, 4000)
                    } else {
                        handleNotification(response.object.message, true);
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const wsGetBrand = () => {
        APIController.GetBrand("1", "AutomationOrderNo", "ASC", AppConstants.GetLocalStorage("localStorageAutomationSubscriptionID"))
            .then((response) => {
                let Temp = [];
                if (response.error == null) {
                    response.object.data.map((item, index) => {
                        return (Temp.push({ Checked: item.IsSelected == 1 ? true : false, ID: item.ID, Name: item.Name }))
                    })
                    setBrandsList(Temp)
                }
                setBrandLoading(false)
            })
    }
    const handleCheckList = (ID) => {
        setBrandsList(BrandsList.map((item, index) => item.ID !== ID ? item : { ...item, Checked: !item.Checked }))
    };

    const formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            var intlCode = (match[1] ? '' : '');
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return null;
    }

    return (
        <div className="popup-box forgot-popup-box" style={{ minWidth: '700px' }}>
            <div className="box-heading">
                <h2 className="h1">{props.Title}</h2>
                <button className="close-btn"><img src={close_ic} alt="icon" onClick={props.closeModal} /></button>
            </div>
            <div className="box-content">
                <Formik
                    // enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validateUsersForm}
                    validateOnMount={true}
                    onSubmit={(values) => {
                        if (values.PhoneNo.length < 10 || formatPhoneNumber(values.PhoneNo) == null || formatPhoneNumber(values.PhoneNo).length < 14) {
                            handleNotification('Please Enter Valid Phone Number', true)
                            document.getElementById('PhoneNo').focus();
                        } else {
                            let tempIds = []
                            BrandsList.filter((item) => { if (item.Checked == true) { tempIds.push(item.ID) } })
                            wsAddEditPracticeAddress(props.EditData == "" ? "0" : props.EditData.ID, props.ParentCompanyID, values.ShipToNo, values.PracticeName, values.PhoneNo, values.Address1, values.Address2, values.Postcode, values.VisionSource, values.VSP, tempIds.toString());
                        }
                    }}
                >
                    {({ handleSubmit, values, setFieldValue, errors, touched, }) => {
                        return (
                            mainLoading ? <CircularProgress />
                                : <form onSubmit={handleSubmit}>
                                    <div>
                                        <div className={`form_field ${errors && touched.ShipToNo && errors.ShipToNo && errors.ShipToNo !== '' ? 'error' : ''}`}>
                                            <div style={{ textAlign: 'left' }} className="field-left">
                                                <label>Practice Ship To # <span>*</span></label>
                                            </div>
                                            <div className="field-right">
                                                <Field disabled={isShipToNoEdit ? true : false} type="text" placeholder="Enter Ship To No" id={'ShipToNo'} name="ShipToNo" value={values.ShipToNo} maxLength="8" />
                                                {errors && touched.ShipToNo && errors.ShipToNo && errors.ShipToNo !== '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage name='ShipToNo' render={(err) => { return err; }} />
                                                    </span>)}
                                            </div>
                                        </div>

                                        <div className={`form_field ${errors && touched.Postcode && errors.Postcode && errors.Postcode !== '' ? 'error' : ''}`}>
                                            <div style={{ textAlign: 'left' }} className="field-left">
                                                <label>Postcode</label>
                                            </div>
                                            <div className='field-right'>
                                                <Field type="text" placeholder="Enter Postcode" id={'Postcode'} name="Postcode" value={values.Postcode} maxLength="6" />
                                                {/* <input type='text' placeholder='Enter Postcode' name='Postcode'
                                                    onChange={(e) => {
                                                        wsSearchPostcodes(e.target.value);
                                                        setFieldValue('Postcode', e.target.value);
                                                        e.target.value == '' && setFieldValue('City', '');
                                                        e.target.value == '' && setFieldValue('State', '');
                                                    }}
                                                    value={Postcodes}
                                                />
                                                {postcodeSuggestion ? (
                                                    <div className='Suggetion-wrap'>
                                                        <PerfectScrollbar style={{ maxHeight: '300px' }}>
                                                            {SearchPostcodes.length == 0 ?
                                                                <div>{'No Suggetion Found'}</div> :
                                                                SearchPostcodes.map((item, index) => {
                                                                    return (<div onClick={() => { SelectRepresentName(item); setFieldValue('City', item.City); setFieldValue('State', item.State) }} style={{ cursor: 'pointer', padding: '5px' }}>{item.Postcode}</div>
                                                                    )
                                                                })
                                                            }
                                                        </PerfectScrollbar>
                                                    </div>
                                                ) : null} */}
                                                {errors && touched.Postcode && errors.Postcode && errors.Postcode !== '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage name='Postcode' render={(err) => { return err; }} />
                                                    </span>)}
                                            </div>
                                        </div>
                                        {!isShowField &&
                                            <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                                <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" onClick={() => wsVerifyClinic(values.ShipToNo, values.Postcode, values)} />
                                            </div>}
                                    </div>

                                    {isShowField &&
                                        <div style={{ marginTop: '8%' }}>
                                            <div className={`form_field ${errors && touched.PracticeName && errors.PracticeName && errors.PracticeName !== '' ? 'error' : ''}`}>
                                                <div style={{ textAlign: 'left' }} className="field-left">
                                                    <label>Practice Name</label>
                                                </div>
                                                <div className="field-right">
                                                    <Field type="text" placeholder="Enter Practice Name" id={'PracticeName'} name="PracticeName" value={values.PracticeName} maxLength="50" />
                                                    {errors && touched.PracticeName && errors.PracticeName && errors.PracticeName !== '' && (
                                                        <span className='error-msg'>
                                                            <ErrorMessage name='PracticeName' render={(err) => { return err; }} />
                                                        </span>)}
                                                </div>
                                            </div>
                                            <div className={`form_field ${errors && touched.PhoneNo && errors.PhoneNo && errors.PhoneNo !== '' ? 'error' : ''}`}>
                                                <div style={{ textAlign: 'left' }} className="field-left">
                                                    <label>Phone No</label>
                                                </div>
                                                <div className="field-right">
                                                    <Field type="text" placeholder="Enter Phone No" id={'PhoneNo'} name="PhoneNo" value={formatPhoneNumber(values.PhoneNo)} maxLength="14" />
                                                    {/* <Input country="US" type="text" placeholder="Enter Phone No" id={'PhoneNo'} name="PhoneNo" default={formatPhoneNumber(values.PhoneNo)} value={formatPhoneNumber(values.PhoneNo)} maxLength="14" onChange={(e) => { values.PhoneNo = e }} /> */}
                                                    {errors && touched.PhoneNo && errors.PhoneNo && errors.PhoneNo !== '' && (
                                                        <span className='error-msg'>
                                                            <ErrorMessage name='PhoneNo' render={(err) => { return err; }} />
                                                        </span>)}
                                                </div>
                                            </div>

                                            <div className={`form_field ${errors && touched.Address1 && errors.Address1 && errors.Address1 !== '' ? 'error' : ''}`}>
                                                <div style={{ textAlign: 'left' }} className="field-left">
                                                    <label>Address 1</label>
                                                </div>
                                                <div className="field-right">
                                                    <Field type="text" placeholder="Enter Address 1" id={'Address1'} name="Address1" value={values.Address1} maxLength="50" />
                                                    {errors && touched.Address1 && errors.Address1 && errors.Address1 !== '' && (
                                                        <span className='error-msg'>
                                                            <ErrorMessage name='Address1' render={(err) => { return err; }} />
                                                        </span>)}
                                                </div>
                                            </div>

                                            <div className={`form_field`}>
                                                <div style={{ textAlign: 'left' }} className="field-left">
                                                    <label>Address 2</label>
                                                </div>
                                                <div className="field-right">
                                                    <Field type="text" placeholder="Enter Address 2" id={'Address2'} name="Address2" value={values.Address2} maxLength="50" />
                                                </div>
                                            </div>

                                            <div className={`form_field ${errors && touched.City && errors.City && errors.City !== '' ? 'error' : ''}`}>
                                                <div style={{ textAlign: 'left' }} className="field-left">
                                                    <label>City</label>
                                                </div>
                                                <div className="field-right">
                                                    <Field type="text" placeholder="Enter City" id={'City'} name="City" value={values.City} maxLength="50" />
                                                    {errors && touched.City && errors.City && errors.City !== '' && (
                                                        <span className='error-msg'>
                                                            <ErrorMessage name='City' render={(err) => { return err; }} />
                                                        </span>)}
                                                </div>
                                            </div>

                                            <div className={`form_field ${errors && touched.State && errors.State && errors.State !== '' ? 'error' : ''}`}>
                                                <div style={{ textAlign: 'left' }} className="field-left">
                                                    <label>State</label>
                                                </div>
                                                <div className="field-right">
                                                    <Field type="text" placeholder="Enter State" id={'State'} name="State" value={values.State} maxLength="50" />
                                                    {errors && touched.State && errors.State && errors.State !== '' && (
                                                        <span className='error-msg'>
                                                            <ErrorMessage name='State' render={(err) => { return err; }} />
                                                        </span>)}
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '60%' }}>
                                                    <div className="form_field">
                                                        <div style={{ textAlign: 'left', width: '40%' }} className="field-left">
                                                            <label>Vision Source Member Practice</label>
                                                        </div>
                                                        <div style={{ textAlign: 'left', width: '60%' }} className="field-right">
                                                            <div className="custom-radio">
                                                                <input type="radio" name="VisionSource" id="yes-textvisionsource" value={values.VisionSource}

                                                                    defaultChecked={values.VisionSource != 0 ? true : false}
                                                                    onClick={(e) => setFieldValue('VisionSource', 1)} />
                                                                <label htmlFor="yes-textvisionsource">Yes</label>
                                                            </div>
                                                            <div className="custom-radio">
                                                                <input type="radio" name="VisionSource" id="no-textvisionsource" value={values.VisionSource}

                                                                    defaultChecked={values.VisionSource == 0 ? true : false}
                                                                    onClick={(e) => setFieldValue('VisionSource', 0)} />
                                                                <label htmlFor="no-textvisionsource">No</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form_field">
                                                        <div style={{ textAlign: 'left', width: '40%' }} className="field-left">
                                                            <label>VSP Premier Member Practice</label>
                                                        </div>
                                                        <div style={{ textAlign: 'left', width: '60%' }} className="field-right">
                                                            <div className="custom-radio">
                                                                <input type="radio" name="VSP" id="yes-textVSP" value={values.VSP}

                                                                    defaultChecked={values.VSP != 0 ? true : false}
                                                                    onClick={(e) => setFieldValue('VSP', 1)} />
                                                                <label htmlFor="yes-textVSP">Yes</label>
                                                            </div>
                                                            <div className="custom-radio">
                                                                <input type="radio" name="VSP" id="no-textVSP" value={values.VSP}

                                                                    defaultChecked={values.VSP == 0 ? true : false}
                                                                    onClick={(e) => setFieldValue('VSP', 0)} />
                                                                <label htmlFor="no-textVSP">No</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ width: '40%' }}>
                                                    <label>Brands To Included In The Bausch + Lomb Doc Finder</label>
                                                    {BrandLoading ? <></>
                                                        : <div className="table-body" style={{ marginBottom: '20px' }}>
                                                            {BrandsList.length && BrandsList.map((item, index) => (
                                                                <div className='custom-checkbox' style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }} >
                                                                    <input type='checkbox'
                                                                        name={`${item.ID}`}
                                                                        id={`${item.ID}`}
                                                                        defaultChecked={item.Checked}
                                                                        onChange={(e) => { handleCheckList(item.ID); }}
                                                                    />
                                                                    <label htmlFor={`${item.ID}`} style={{ paddingLeft: '40px' }}>{item.Name.split('®')[0]}{item.Name.match(/®/g) ? <sup>&reg;&nbsp;</sup> : ''}{item.Name.split('®')[1]}</label>
                                                                </div>
                                                            ))}
                                                        </div>}
                                                </div>
                                            </div>

                                            <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                                <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" />
                                            </div>
                                        </div>
                                    }
                                </form>
                        )
                    }}

                </Formik>

            </div>
            <Notification
                isError={isError}
                message={message}
                showNotification={showNotification}
                clearNotification={handleClearNotification.bind(this)}
                closeNotification={handleClearNotification.bind(this)}
            />
        </div>

    )
};

export default PracticeForm;