/*eslint-disable eqeqeq*/
import React from "react";
import Table from "../../../../shared/components/table/table";
import TableHead from "../../../../shared/components/table/tableHead";
import TableRow from "../../../../shared/components/table/tableRow";
import TableBody from "../../../../shared/components/table/tableBody";
import TableCell from "../../../../shared/components/table/tableCell";
import double_left_arrow from '../../../../assets/images/left-doble-arow.svg';
import left_arrow from '../../../../assets/images/left-arrow.svg';
import double_right_arrow from '../../../../assets/images/right-doble-arow.svg';
import right_arrow from '../../../../assets/images/right-arrow.svg';
import "react-perfect-scrollbar/dist/css/styles.css";

import PerfectScrollbar from "react-perfect-scrollbar";
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";

const ImportedpracticesList = (props) => {

	const headerList = ["Ship To", "Practice Name", "Address 1", "Address 2", "City", "State", "Postcode", "Bill To", "VTM", "Territory", "Vision Source", "VSP"]

	const { currentPage, count, rowsPerPage, rowsPerPageOptions, list, to, from } = props;
	let ImportedPracticeList = list;
	// let ImportedPracticeList = list.slice(from, to)

	const page = rowsPerPage == 1000000 ? 1 : Math.floor(count / rowsPerPage) + 1;

	const handleChangeInCurrentPage = (value) => {
		if (value <= page && Number(parseInt(value))) {
			props.handleChangePage(Number(value), PracticeNameText, Address1Text, Address2Text, CityText, StateText, ZIPCodeText, BillToText, VTMText, TerritoryText, ShipToText, VisionSourceText, VSPText);
		}
	}
	const [PracticeNameText, setPracticeNameText] = React.useState('');
	const [Address1Text, setAddress1Text] = React.useState('');
	const [Address2Text, setAddress2Text] = React.useState('');
	const [CityText, setCityText] = React.useState('');
	const [StateText, setStateText] = React.useState('');
	const [ZIPCodeText, setZIPCodeText] = React.useState('');
	const [BillToText, setBillToText] = React.useState('');
	const [VTMText, setVTMText] = React.useState('');
	const [TerritoryText, setTerritoryText] = React.useState('');
	const [ShipToText, setShipToText] = React.useState('');
	const [VisionSourceText, setVisionSourceText] = React.useState('');
	const [VSPText, setVSPText] = React.useState('');
	const [IsAccountCreated, setIsAccountCreated] = React.useState('');

	const _handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			props.applyFilter(PracticeNameText, Address1Text, Address2Text, CityText, StateText, ZIPCodeText, BillToText, VTMText, TerritoryText, ShipToText, VisionSourceText, VSPText, IsAccountCreated)
		}
	}

	return (
		<>
			<h1>View Imported Practices</h1>
			<div className="table-main-wrap users-class">
				<PerfectScrollbar component='div' style={{ width: '2000px' }}>
					<Table className="">
						<TableHead>
							<TableRow>
								{headerList.map((obj, index) => {
									return <TableCell key={index}>{obj}</TableCell>;
								})}
							</TableRow>
							<TableRow>
								<TableCell style={{ width: '4%' }}>
									<input type="text" name="" onKeyDown={_handleKeyDown} onChange={(e) => setShipToText(e.target.value)} />
								</TableCell>
								<TableCell style={{ width: '12%' }}>
									<input type="text" name="" onKeyDown={_handleKeyDown} onChange={(e) => setPracticeNameText(e.target.value)} />
								</TableCell>
								<TableCell style={{ width: '10%' }}>
									<input type="text" name="" onKeyDown={_handleKeyDown} onChange={(e) => setAddress1Text(e.target.value)} />
								</TableCell>
								<TableCell style={{ width: '10%' }}>
									<input type="text" name="" onKeyDown={_handleKeyDown} onChange={(e) => setAddress2Text(e.target.value)} />
								</TableCell>
								<TableCell style={{ width: '8%' }}>
									<input type="text" name="" onKeyDown={_handleKeyDown} onChange={(e) => setCityText(e.target.value)} />
								</TableCell>
								<TableCell style={{ width: '4%' }}>
									<input type="text" name="" onKeyDown={_handleKeyDown} onChange={(e) => setStateText(e.target.value)} />
								</TableCell>
								<TableCell style={{ width: '2%' }}>
									<input type="text" name="" onKeyDown={_handleKeyDown} onChange={(e) => setZIPCodeText(e.target.value)} />
								</TableCell>
								<TableCell style={{ width: '3%' }}>
									<input type="text" name="" onKeyDown={_handleKeyDown} onChange={(e) => setBillToText(e.target.value)} />
								</TableCell>
								<TableCell style={{ width: '8%' }}>
									<input type="text" name="" onKeyDown={_handleKeyDown} onChange={(e) => setVTMText(e.target.value)} />
								</TableCell>
								<TableCell style={{ width: '8%' }}>
									<input type="text" name="" onKeyDown={_handleKeyDown} onChange={(e) => setTerritoryText(e.target.value)} />
								</TableCell>

								<TableCell style={{ width: '8%' }}>
									<select onChange={(e) => { setVisionSourceText(e.target.value); props.applyFilter(PracticeNameText, Address1Text, Address2Text, CityText, StateText, ZIPCodeText, BillToText, VTMText, TerritoryText, ShipToText, e.target.value, VSPText, IsAccountCreated) }}>
										<option value={""}>All</option>
										<option value={"YES"}>Yes</option>
										<option value={"NO"}>No</option>
									</select>
								</TableCell>
								<TableCell style={{ width: '8%' }}>
									<select onChange={(e) => { setVSPText(e.target.value); props.applyFilter(PracticeNameText, Address1Text, Address2Text, CityText, StateText, ZIPCodeText, BillToText, VTMText, TerritoryText, ShipToText, VisionSourceText, e.target.value, IsAccountCreated) }}>
										<option value={""}>All</option>
										<option value={"YES"}>Yes</option>
										<option value={"NO"}>No</option>
									</select>
								</TableCell>
								{/* <TableCell style={{ width: '12%' }}>
									<select onChange={(e) => { setIsAccountCreated(e.target.value); props.applyFilter(PracticeNameText, Address1Text, Address2Text, CityText, StateText, ZIPCodeText, BillToText, VTMText, TerritoryText, ShipToText, VisionSourceText, VSPText, e.target.value) }}>
										<option value={""}>All</option>
										<option value={"YES"}>Yes</option>
										<option value={"NO"}>No</option>
									</select>
								</TableCell> */}

							</TableRow>
						</TableHead>

						<TableBody>
							{!!ImportedPracticeList.length ? ImportedPracticeList.map((obj, idnex) => {
								return (
									<TableRow key={idnex}>
										<TableCell>{obj.ShipTo}</TableCell>
										<TableCell>{obj.PracticeName}</TableCell>
										<TableCell>{obj.Address1}</TableCell>
										<TableCell>{obj.Address2}</TableCell>
										<TableCell>{obj.City}</TableCell>
										<TableCell>{obj.State}</TableCell>
										<TableCell>{obj.ZIPCode}</TableCell>
										<TableCell>{obj.BillTo}</TableCell>
										<TableCell>{obj.VTM}</TableCell>
										<TableCell>{obj.Territory}</TableCell>
										<TableCell className={obj.VisionSource === 'YES' ? 'green' : 'red'}>
											<span>{obj.VisionSource}</span>
										</TableCell>
										<TableCell className={obj.VSP === 'YES' ? 'green' : 'red'}>
											<span>{obj.VSP}</span>
										</TableCell>
										{/* <TableCell className={obj.IsAccountCreatedLABEL === 'YES' ? 'green' : 'red'}>
											<span>{obj.IsAccountCreatedLABEL}</span>
										</TableCell> */}
									</TableRow>
								);
							}) : <TableRow >
								<TableCell colSpan={headerList.length} className="not_found">
									{props.progress ?
										<CircularProgress /> :
										<label>Not Currently Available</label>}
								</TableCell>
							</TableRow>}
						</TableBody>

					</Table>
				</PerfectScrollbar>
			</div>

			<div className="table-pagination">
				<div className="pagination-info">
					<p>Showing {from + 1} - {to > count ? count : to} of {count}</p>
				</div>
				<div className="pagination-control">
					<a href="#!" title="" className={`double-left-arrow ${props.currentPage == 1 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(1)
						}}
					><img src={double_left_arrow} alt="left" /></a>
					<a href="#!" title="" className={`simple-left-arrow ${props.currentPage == 1 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage - 1)
						}}
					><img src={left_arrow} alt="single_left" /></a>
					<span>Page</span>
					<input disabled type="text" name="textbox" value={currentPage === 0 ? currentPage + 1 : currentPage} onChange={(e) => handleChangeInCurrentPage(e.target.value)} />
					<span>of {page}</span>
					<a href="#!" title="" className={`simple-right-arrow ${currentPage == page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage + 1)
						}}
					><img src={right_arrow} alt="right" /></a>
					<a href="#!" title="" className={`double-right-arrow ${currentPage == page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(page)
						}}><img src={double_right_arrow} alt="single_right" /></a>
					<select onChange={(e) => props.handleChangeRowsPerPage(parseInt(e.target.value), PracticeNameText, Address1Text, Address2Text, CityText, StateText, ZIPCodeText, BillToText, VTMText, TerritoryText, ShipToText, VisionSourceText, VSPText)} defaultValue={rowsPerPage}>
						{
							rowsPerPageOptions.map((obj, index) => {
								return <option value={obj} key={index}>{obj}</option>
							})
						}
					</select>
				</div>
			</div>
		</>
	);
};

export default ImportedpracticesList;
