/*eslint-disable eqeqeq*/
import React from 'react';
import WelcomeInfoListList from '../../component/componentWelcomeInfo/welcomeInfolist';
import * as APIController from '../../../../api/APIController.js';

import Notification from '../../../../shared/components/notification/notification';

class WelcomeInfo extends React.Component {

    state = {
        dataSource: [],
        currentPage: 0,
        count: 0,
        list: [],
        list1: [],
        rowsPerPage: 25,
        rowsPerPageOptions: [25, 10, 5],
        from: 0,
        to: 25,
        progress: true,
        message: '',
        showNotification: false,
        isError: false,
        showMessage: this.props.history.location.state != null && this.props.history.location.state.showMessage
    }

    componentDidMount() {
        this.wsGetWelcomePopup();
        this.state.showMessage != '' && this.handleNotification(this.state.showMessage)
        this.props.history.replace('/welcomeInfo', null);
    }

    handlebind() { this.wsGetWelcomePopup() }

    wsGetWelcomePopup() {
        this.setState({ progress: true, });
        APIController.GetWelcomePopup()
            .then((response) => {
                if (response.error == null) {
                    this.setState({
                        list: response.object.data,
                        list1: response.object.data,
                        count: response.object.data.length,
                        progress: false,
                    })
                } else if (response.error.message && response.error.message.length > 0) {
                    // ToastsStore.warning(response.error.message)
                }
            })
    }

    render() {
        return (
            <>
                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    clearNotification={this.handleClearNotification}
                    closeNotification={this.handleClearNotification}
                />
                <section className="content-right-main">
                    <div className="button-field button-field-country">
                        <button onClick={() => {
                            this.props.history.push({
                                pathname: '/welcomeInfo/add',
                                state: {
                                    idEdit: '0',
                                    titleEdit: '',
                                    segmentEdit: '',
                                    descriptionEdit: '',
                                    imageDataEdit: ''
                                }
                            })
                        }}>+ Add Welcome info</button>
                    </div>

                    <WelcomeInfoListList {...this.state}
                        history={this.props.history}
                        handlebind={this.handlebind.bind(this)}
                        handleChangeRowsPerPage={this.handleRowsPerPageChange}
                        handleChangePage={this.handlePageChange}
                        applyFilter={this.handleApplyFilter}
                    />
                </section>
            </>
        )
    }

    handleRowsPerPageChange = (value) => {
        let from = 0, to = value;
        this.setState({ rowsPerPage: value, currentPage: 1, to: to, from: from })
    }

    handlePageChange = values => {
        let from = values === 1 ? 0 * this.state.rowsPerPage : (values - 1) * this.state.rowsPerPage
        let to = values === 1 ? this.state.rowsPerPage : parseInt(from) + parseInt(this.state.rowsPerPage);
        this.setState({ currentPage: Number(values), to: to, from: from })
    }

    handleApplyFilter = (tag, values, type) => {
        if (values !== "") {
            let searchBrands = []

            if (type === 'select') {
                searchBrands = this.state.list1.filter((us) => us[tag].toLowerCase() === values.toLowerCase());
            } else {
                searchBrands = this.state.list.filter((us) => us[tag].toString().toLowerCase().indexOf(values.toLowerCase()) > -1);
            }
            this.setState({
                list: searchBrands,
                currentPage: 0,
                count: searchBrands.length,
                rowsPerPage: 25,
                rowsPerPageOptions: [25, 10, 5],
                from: 0,
                to: 25,
                progress: false,
            });
        } else {
            this.wsGetWelcomePopup();
            this.setState({
                currentPage: 0,
                rowsPerPage: 25,
                rowsPerPageOptions: [25, 10, 5],
                from: 0,
                to: 25,
            });
        }
    }

    handleNotification = (message, isError) => {
        this.setState({
            message: message,
            showNotification: true,
            isError: isError
        })
        setTimeout(() => { this.handleClearNotification(); }, 4000)
    }

    handleClearNotification = () => { this.setState({ message: '', showNotification: false, isError: false }) }
}

export default WelcomeInfo;