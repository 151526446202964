import React from 'react';
import forgot_user_ic from '../../assets/images/forgot-username-ic.svg';
import close_ic from '../../assets/images/close-ic.svg';
import { Grid } from '@material-ui/core';

const ConfirmationDialog = props => {

    const { deletePress, title, description, btnText, isLoading } = props;

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="popup-box forgot-popup-box" style={{ width: '10%' }}>
                <div className="box-heading">
                    {/* <img src={delete_ic} alt="icon" /> */}
                    {/* <img src={forgot_user_ic} alt="icon" /> */}
                    <h2 className="h1">{title}</h2>
                    <button className="close-btn"><img src={close_ic} alt="icon" onClick={props.closeModal} /></button>
                </div>
                <Grid container justify='center' alignItems='center' style={{ padding: '3% 3% 0% 3%' }}>
                    {/* <h2>Are you sure you want to delete?</h2> */}
                    <h2 className='confirmation-message'>{description}</h2>
                </Grid>
                <Grid container justify='space-around' alignItems='center' style={{ padding: '1%', }}>
                    <div className={isLoading ? "button-field disable-btn" : "button-field"}>
                        <input type="button" value={btnText != undefined ? btnText : "Delete"} name="delete" onClick={deletePress} />
                    </div>
                    <div className="button-field">
                        <input type="button" value="Close" name="close" onClick={props.closeModal} />
                    </div>
                </Grid>
            </div>
        </div>
    )
}

export default ConfirmationDialog;