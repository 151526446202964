import React from 'react';
import { Formik } from 'formik'
// import './component/componentCountry/countryForm.css'
import './campaignsStyle.css'

const campaigns = props => {
    // const pathName = props.history.location.pathname.includes('add') ? 'Add' : 'Edit'
    // const initialValues = pathName === 'Add' ? {
    //     countryname: '',
    // } : {
    //         country: 'India',
    //     }

    const initialValues = { countryname: '' }
    return (
        <>
            <section className="content-right-main">
                {/* <h1>{pathName} countrys</h1> */}
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    onSubmit={(values) => {  }}
                >
                    {({

                        handleSubmit,
                    }) => {
                        return (
                            <div className="form-main">
                                {/* <form onSubmit={handleSubmit}>
                                    <div className="button-field">
                                        <button onClick={() => { props.history.push('/clinic-registrationdesktop') }}>clinic registration</button>
                                    </div>
                                </form>
                                <form onSubmit={handleSubmit}>
                                    <div className="button-field">
                                        <button onClick={() => { props.history.push('/country') }}>Add Country</button>
                                    </div>
                                    <div className="button-field">
                                        <button onClick={() => { props.history.push('/state') }}>Add States</button>
                                    </div>
                                    <div className="button-field">
                                        <button onClick={() => { props.history.push('/city') }}>Add City</button>
                                    </div>
                                    <div className="button-field">
                                        <button onClick={() => { props.history.push('/postcode') }}>Add Postcodes</button>
                                    </div>
                                </form>
                                <form onSubmit={handleSubmit}>
                                    <div className="button-field">
                                        <button onClick={() => { props.history.push('/right') }}>Add Right</button>
                                    </div>
                                    <div className="button-field">
                                        <button onClick={() => { props.history.push('/module') }}>Add Module</button>
                                    </div>
                                    <div className="button-field">
                                        <button onClick={() => { props.history.push('/screen') }}>Add Screen</button>
                                    </div>
                                    <div className="button-field">
                                        <button onClick={() => { props.history.push('/role') }}>Add Role</button>
                                    </div>
                                    <div className="button-field">
                                        <button onClick={() => { props.history.push('/UploadExcelSheet') }}>Upload ExcelSheet</button>
                                    </div>
                                </form>


                                <form onSubmit={handleSubmit}>
                                    <div className="button-field">
                                        <button onClick={() => { props.history.push('/branches') }}>Add Branches</button>
                                    </div>
                                    <div className="button-field">
                                        <button onClick={() => { props.history.push('/department') }}>Add Departments</button>
                                    </div>
                                    <div className="button-field">
                                        <button onClick={() => { props.history.push('/positions') }}>Add Positions</button>
                                    </div>
                                </form> */}
                                <form onSubmit={handleSubmit}>
                                    <div className="button-field">
                                        <button onClick={() => { props.history.push('/areas') }}>Add Right</button>
                                    </div>
                                    <div className="button-field">
                                        <button onClick={() => { props.history.push('/regions') }}>Add Module</button>
                                    </div>

                                </form>
                            </div>
                        )
                    }}

                </Formik>
            </section>
        </>
    )
}

export default campaigns;