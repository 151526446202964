/*eslint-disable eqeqeq*/
import React from "react";
import SubscriptionList from "./subscriptionList";
import SubscriptionOrderList from "./subscriptionOrderList";
// import { data } from './countrydata'
import * as APIController from "../../../../api/APIController.js";
import Notification from "../../../../shared/components/notification/notification";
import Modal from '../../../../shared/components/modal/modal';
import SubscriptionOrderCalendar from './SubscriptionOrderCalendar';

class Subscription extends React.Component {
    state = {
        currentPage: 0,
        count: 0,
        rowsPerPage: 50,
        rowsPerPageOptions: [50, 100, 500],
        list: [],
        list1: [],
        from: 0,
        to: 50,
        progress: true,
        message: "",
        showNotification: false,
        isError: false,
        openCalendarDialog: false,
        showMessage:
            this.props.history.location.state != null &&
            this.props.history.location.state.showMessage,
        showOrderList: false,
        isSetYear: true,
        CalendarAssetsData: [],
        SubscriptionID: '',
        CalendarYear: '',
        CalendarMonth: ''
    };

    componentDidMount() {
        this.wsGetAutomationSubscriptions();
        this.state.showMessage != "" &&
            this.handleNotification(this.state.showMessage);
        this.props.history.replace("/subscription/subscription", null);
    }

    wsGetAutomationSubscriptions() {
        this.setState({ progress: true, });
        APIController.GetAutomationSubscriptions().then((response) => {
            if (response.error == null) {
                this.setState({
                    list: response.object.data,
                    list1: response.object.data,
                    count: response.object.data.length ? response.object.data[0].MaxRows : 0,
                    progress: false,
                });
            } else if (response.error.message && response.error.message.length > 0) {
                this.handleNotification(response.error.message, true);
            } else { this.handleNotification(response.object.message, true); }
        });
    }

    handleShowList = (value, SubscriptionID, Month, Year) => {
        this.setState({ SubscriptionID: SubscriptionID })
        if (value) {
            this.wsGetAutomationSubscriptionOrders(value, SubscriptionID)
        } else {
            this.wsGetAutomationSubscriptionOrderCalendar(SubscriptionID, new Date().getMonth() + 1, new Date().getFullYear())
        }
    }
    wsGetAutomationSubscriptionOrders(value, SubscriptionID) {
        this.setState({ progress: true, });
        APIController.GetAutomationSubscriptionOrders(SubscriptionID).then((response) => {
            if (response.error == null) {
                this.setState({
                    list: response.object.data,
                    list1: response.object.data,
                    count: response.object.data.length,
                    progress: false,
                });
            } else if (response.error.message && response.error.message.length > 0) {
                this.handleNotification(response.error.message, true);
            } else { this.handleNotification(response.object.message, true); }
            this.setState({ showOrderList: value })
        });
    }

    wsGetAutomationSubscriptionOrderCalendar(SubscriptionID, Month, Year) {
        this.setState({ progress: true, });
        APIController.GetAutomationSubscriptionOrderCalendar(SubscriptionID, Month, Year).then((response) => {
            if (response.error == null) {
                this.setState({
                    CalendarAssetsData: response.object.data,
                    CalendarYear: Year,
                    CalendarMonth: Month,
                    progress: false,
                });
                this.setState({ openCalendarDialog: true })
            } else if (response.error.message && response.error.message.length > 0) {
                this.handleNotification(response.error.message, true);
            } else { this.handleNotification(response.object.message, true); }
        });
    }

    handlebindAssetsData(Month, Year) {
        this.setState({ isSetYear: false })
        this.wsGetAutomationSubscriptionOrderCalendar(this.state.SubscriptionID, Month, Year)
    }

    render() {
        return (
            <>
                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    clearNotification={this.handleClearNotification}
                    closeNotification={this.handleClearNotification}
                />
                <section className='content-right-main'>

                    {!this.state.showOrderList ?
                        <>
                            <div div className='button-field button-field-country'>
                                <button onClick={() => {
                                    this.props.history.push('/subscription/postschedule')
                                }}> Post Schedule </button>
                            </div>
                            <SubscriptionList
                                {...this.state}
                                history={this.props.history}
                                handleChangeRowsPerPage={this.handleRowsPerPageChange}
                                handleChangePage={this.handlePageChange}
                                applyFilter={this.handleApplyFilter}
                                handleNotification={this.handleNotification}
                                handleShowList={this.handleShowList}
                            />
                        </>
                        : <>
                            <div div className='button-field button-field-country'>
                                <button onClick={() => { this.wsGetAutomationSubscriptions(); this.setState({ showOrderList: false }) }}>
                                    + List Subscription
                                </button>
                            </div>

                            <SubscriptionOrderList
                                {...this.state}
                                history={this.props.history}
                                handleChangeRowsPerPage={this.handleRowsPerPageChange}
                                handleChangePage={this.handlePageChange}
                                applyFilter={this.handleApplyFilter}
                                handleNotification={this.handleNotification}
                            />
                        </>
                    }
                </section>

                {this.state.openCalendarDialog && (
                    <Modal
                        show={this.state.openCalendarDialog}
                        handleClose={() => this.setState({ openCalendarDialog: false })}>
                        <SubscriptionOrderCalendar
                            CalendarAssetsData={this.state.CalendarAssetsData}
                            isSetYear={this.state.isSetYear}
                            CalendarYear={this.state.CalendarYear}
                            CalendarMonth={this.state.CalendarMonth}
                            // PMonthData={PMonthData}
                            // AutomationCalendarID={AutomationCalendarID}
                            handlebindAssetsData={this.handlebindAssetsData.bind(this)}
                            // handleAssetsParMonthData={handleAssetsParMonthData.bind(this)}
                            // history={props.history}
                            closeModal={() => this.setState({ openCalendarDialog: false })}
                        />
                    </Modal>
                )}
            </>
        );
    }

    /**
     * handleRowsPerPageChange method is used to update rows per page
     * it will reset page to 1
     */

    handleRowsPerPageChange = (value) => {
        let from = 0;
        let to = value;
        this.setState({ rowsPerPage: value, currentPage: 1, to: to, from: from });
    };

    /**
     * handlePageChange method is used to change page
     */

    handlePageChange = (values) => {
        let from =
            values === 1
                ? 0 * this.state.rowsPerPage
                : (values - 1) * this.state.rowsPerPage;
        let to =
            values == 1
                ? this.state.rowsPerPage
                : parseInt(from) + parseInt(this.state.rowsPerPage);
        this.setState({ currentPage: Number(values), to: to, from: from });
    };

    /**
     * handleApplyFilter method is used to apply filter on every columns
     */
    handleApplyFilter = (tag, values, type) => {
        if (values !== "") {
            let searchCountrys = [];
            if (type === "select") {
                searchCountrys = this.state.list1.filter(
                    (us) => us[tag].toString().toLowerCase() === values.toString().toLowerCase()
                );
            } else {
                searchCountrys = this.state.list.filter(
                    (us) => us[tag] != null && us[tag].toString().toLowerCase().indexOf(values.toLowerCase()) > -1
                );
            }
            this.setState({
                list: searchCountrys,
                currentPage: 0,
                count: searchCountrys.length,
                rowsPerPage: 50,
                rowsPerPageOptions: [50, 100, 500],
                from: 0,
                to: 50,
                progress: false,
            });
        } else {
            this.wsGetAutomationSubscriptions();
            this.setState({
                currentPage: 0,
                rowsPerPage: 50,
                rowsPerPageOptions: [50, 100, 500],
                from: 0,
                to: 50,
            });
        }
    };

    handleNotification = (message, isError) => {
        this.setState({
            message: message,
            showNotification: true,
            isError: isError,
        });

        setTimeout(() => {
            this.handleClearNotification();
        }, 4000);
    };

    handleClearNotification = () => {
        this.setState({
            message: "",
            showNotification: false,
            isError: false,
        });
    };
}

export default Subscription;
