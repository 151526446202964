/*eslint-disable eqeqeq*/
import React from "react";
import Table from "../../../../../shared/components/table/table";
import TableHead from "../../../../../shared/components/table/tableHead";
import TableRow from "../../../../../shared/components/table/tableRow";
import TableBody from "../../../../../shared/components/table/tableBody";
import TableCell from "../../../../../shared/components/table/tableCell";
import double_left_arrow from '../../../../../assets/images/left-doble-arow.svg';
import left_arrow from '../../../../../assets/images/left-arrow.svg';
import double_right_arrow from '../../../../../assets/images/right-doble-arow.svg';
import right_arrow from '../../../../../assets/images/right-arrow.svg';
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

import Notification from '../../../../../shared/components/notification/notification';
import CircularProgress from "../../../../../shared/components/CircularProgress/CircularProgress";
import * as AppConstants from "../../../../../config/AppConstant";

const ContestSummaryReportList = (props) => {
	const headerList = ["Area", "Region #", "VTM #", "VTM Name", "New Registrations plus 1+ download (10 Points)",
		"Prior Registrations plus 1+ download (10 Points)", "Additional Asset Download (1 Points)",
		"Social Automation (25 Points)", "Campaign Order (25 Points)",
		"Email Marketing (10 Points)", "Total Points"]

	const { currentPage, count, rowsPerPage, rowsPerPageOptions, list, to, from } = props;
	let countryList = list;
	countryList = list.slice(from, isNaN(to) ? 1000000 : to)

	const page = Math.floor(count / rowsPerPage) + 1;

	/**
	 * handleChangeInCurrentPage method is used to change page number
	 * it will check that page number does not exist last page
	 * @param {*} value 
	 */
	const handleChangeInCurrentPage = (value) => {
		if (value <= page && Number(parseInt(value))) {
			props.handleChangePage(Number(value));
		}
	}

	const [message, setMessage] = React.useState("");
	const [showNotification, setShowNotification] = React.useState(false);
	const [isError, setIsError] = React.useState(false);

	const handleClearNotification = () => {
		setMessage('')
		setShowNotification(false)
		setIsError(false)
	}
	return (
		<>
			{/* <h1><PageTitle Title={AppConstants.ContactDetais.ContestSummaryReport} /></h1> */}
			<div className="table-main-wrap">
				<PerfectScrollbar component='div'>
					<Table>
						<TableHead>
							<TableRow> {headerList.map((obj, index) => { return <TableCell style={{ minWidth: '125px', textAlign: 'center', padding: '5px' }} key={index}>{obj}</TableCell>; })} </TableRow> <TableRow>
								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder="Area" onChange={(e) => props.applyFilter('Area', e.target.value, 'input')} /> </TableCell>
								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder="Region #" onChange={(e) => props.applyFilter('Region', e.target.value, 'input')} /> </TableCell>
								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder="VTM #" onChange={(e) => props.applyFilter('Territory', e.target.value, 'input')} /> </TableCell>
								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder="VTM Name" onChange={(e) => props.applyFilter('VTM', e.target.value, 'input')} /> </TableCell>
								<TableCell style={{ minWidth: '150px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder="New Registrations plus 1+ download (10 Points)" onChange={(e) => props.applyFilter('NewRegistrations', e.target.value, 'input')} /> </TableCell>

								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder="Prior Registrations plus 1+ download (10 Points)" onChange={(e) => props.applyFilter('PriorRegistrations', e.target.value, 'input')} /> </TableCell>
								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder="Additional Asset Download (1 Points)" onChange={(e) => props.applyFilter('AssetDownload', e.target.value, 'input')} /> </TableCell>

								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder="Social Automation (25 Points)" onChange={(e) => props.applyFilter('SocialAutomation', e.target.value, 'input')} /> </TableCell>
								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder="Campaign Order (25 Points)" onChange={(e) => props.applyFilter('CampaignOrder', e.target.value, 'input')} /> </TableCell>

								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder="Email Marketing (10 Points)" onChange={(e) => props.applyFilter('EmailMarketing', e.target.value, 'input')} /> </TableCell>
								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder="Total Points" onChange={(e) => props.applyFilter('Total', e.target.value, 'input')} /> </TableCell>

							</TableRow>
						</TableHead>
						<TableBody>
							{!!countryList.length ? countryList.map((obj, idnex) => {
								return (
									<TableRow key={idnex}>

										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.Area}</TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.Region}</TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.Territory}</TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.VTM}</TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.NewRegistrations}</TableCell>

										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.PriorRegistrations}</TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.AssetDownload}</TableCell>

										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.SocialAutomation}</TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.CampaignOrder}</TableCell>

										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.EmailMarketing}</TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.Total}</TableCell>

									</TableRow>
								);
							}) : <TableRow>
								<TableCell colSpan={headerList.length} className="not_found">
									{props.progress ? <CircularProgress /> : <label>Not Currently Available</label>}
								</TableCell>
							</TableRow>}
						</TableBody>
					</Table>
				</PerfectScrollbar>
			</div>
			<Notification
				isError={isError}
				message={message}
				showNotification={showNotification}
				closeNotification={handleClearNotification.bind(this)}
			/>

			<div className="table-pagination">
				<div className="pagination-info">
					<p>Showing {from + 1} - {to > count ? count : to} of {count}</p>
				</div>
				<div className="pagination-control">
					<a href="#!" title="" className={`double-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(1)
						}}
					><img src={double_left_arrow} alt="left" /></a>
					<a href="#!" title="" className={`simple-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage - 1)
						}}
					><img src={left_arrow} alt="single_left" /></a>
					<span>Page</span>
					<input type="text" name="textbox" value={currentPage === 0 ? currentPage + 1 : currentPage} onChange={(e) => handleChangeInCurrentPage(e.target.value)} />
					<span>of {page}</span>
					<a href="#!" title="" className={`simple-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage + 1)
						}}
					><img src={right_arrow} alt="right" /></a>
					<a href="#!" title="" className={`double-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(page)
						}}><img src={double_right_arrow} alt="single_right" /></a>
					<select onChange={(e) => props.handleChangeRowsPerPage(e.target.value)} defaultValue={rowsPerPage}>
						{
							rowsPerPageOptions.map((obj, index) => {
								return <option value={obj} key={index}>{obj}</option>
							})
						}
					</select>
				</div>
			</div>
		</>
	);
};

export default ContestSummaryReportList;
