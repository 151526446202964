import React from 'react';

import TableRow from '@material-ui/core/TableRow';


const TableRowComponent = (props) => {
    return (
        <TableRow classes={props.classes} 
        
        className={props.className} component={'tr'} style={props.style || null} hover={props.hover || true} selected={props.selected || false}>
            {props.children}
        </TableRow>
    )
}

export default TableRowComponent;