import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import * as AppConstant from "./config/AppConstant";
import './index.css'
import './indexyomi.css'

import ErrorBoundary from './shared/components/errorBoundary/errorBoundary';

import App from './App';

const Root = props => {
	React.useEffect(() => {
		// This will run when the page first loads and whenever the title changes
		document.title = AppConstant.GetMainClassName() == "app-main" ? "Bausch + Lomb" : "Yomi";
		AppConstant.initializeAnalytics();
	}, ["title"]);

	return (
		<ErrorBoundary>
			<div className={AppConstant.GetMainClassName()}>
				<BrowserRouter>
					<App {...props} />
				</BrowserRouter>
			</div>
		</ErrorBoundary>
	);
};

export default Root;