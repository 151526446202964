/*eslint-disable eqeqeq*/
import React from "react";
import CountryList from "../../component/componentCountry/countrylist";
// import { data } from './countrydata'
import * as APIController from "../../../../api/APIController1.js";
import { clsGetCountries } from "../../../../api/ApiParameters.js";
import Notification from "../../../../shared/components/notification/notification";

const apiParameters = new clsGetCountries();
class CountryContainer extends React.Component {

	state = {
		ApiParameters: new clsGetCountries(),

		currentPage: 0,
		rowsPerPage: 25,
		count: 0,
		rowsPerPageOptions: [25, 10, 5],
		list: [],
		list1: [],
		from: 0,
		to: 25,
		progress: true,
		message: "",
		showNotification: false,
		isError: false,
		showMessage:
			this.props.history.location.state != null &&
			this.props.history.location.state.showMessage,
	};

	componentDidMount() {
		this.wsGetCountries();
		this.state.showMessage != "" &&
			this.handleNotification(this.state.showMessage);
		this.props.history.replace("/country", null);
	}

	handlebind() {
		this.wsGetCountries();
	}
	wsGetCountries() {
		this.setState({ progress: true, });

		apiParameters.Name = this.state.ApiParameters.Name;

		APIController.GetCountries(apiParameters).then((response) => {
			if (response.error == null) {
				this.setState({
					list: response.object.data,
					list1: response.object.data,
					count: response.object.data.length,
					progress: false,
				});
			} else if (response.error.message && response.error.message.length > 0) {
				this.handleNotification(response.error.message, true);
			}
		});
	}

	render() {
		return (
			<>
				<Notification
					isError={this.state.isError}
					message={this.state.message}
					showNotification={this.state.showNotification}
					clearNotification={this.handleClearNotification}
					closeNotification={this.handleClearNotification}
				/>
				<section className='content-right-main'>

					<div className='button-field button-field-country'>
						<button
							onClick={() => {
								this.props.history.push({
									pathname: "/country/add",
									state: {
										countryIdEdit: "0",
										countryNameEdit: "",
										countryActiveEdit: 1,
									},
								});
							}}>
							+ Add Country
						</button>
					</div>

					<CountryList
						{...this.state}
						history={this.props.history}
						handlebind={this.handlebind.bind(this)}
						handleChangeRowsPerPage={this.handleRowsPerPageChange}
						handleChangePage={this.handlePageChange}
						applyFilter={this.handleApplyFilter}
						handleNotification={this.handleNotification}
					/>
				</section>
			</>
		);
	}

	/**
	 * handleRowsPerPageChange method is used to update rows per page
	 * it will reset page to 1
	 */

	handleRowsPerPageChange = (value) => {
		let from = 0;
		let to = value;
		this.setState({ RecordPerPage: value, CurrentPage: 1, to: to, from: from });
	};

	/**
	 * handlePageChange method is used to change page
	 */

	handlePageChange = (values) => {
		let from =
			values === 1
				? 0 * this.state.RecordPerPage
				: (values - 1) * this.state.RecordPerPage;
		let to =
			values == 1
				? this.state.RecordPerPage
				: parseInt(from) + parseInt(this.state.RecordPerPage);
		this.setState({ CurrentPage: Number(values), to: to, from: from });
	};

	/**
	 * handleApplyFilter method is used to apply filter on every columns
	 */
	handleApplyFilter = (tag, values, type) => {
		if (values !== "") {
			let searchCountrys = [];
			if (type === "select") {
				searchCountrys = this.state.list1.filter(
					(us) => us[tag].toLowerCase() === values.toLowerCase()
				);
			} else {
				searchCountrys = this.state.list.filter(
					(us) => us[tag].toLowerCase().indexOf(values.toLowerCase()) > -1
				);
			}
			this.setState({
				list: searchCountrys,
				CurrentPage: 0,
				count: searchCountrys.length,
				RecordPerPage: 25,
				rowsPerPageOptions: [25, 10, 5],
				from: 0,
				to: 25,
				progress: false,
			});
		} else {
			this.wsGetCountries();
			this.setState({
				CurrentPage: 0,
				RecordPerPage: 25,
				rowsPerPageOptions: [25, 10, 5],
				from: 0,
				to: 25,
			});
		}
	};

	handleNotification = (message, isError) => {
		this.setState({
			message: message,
			showNotification: true,
			isError: isError,
		});

		setTimeout(() => {
			this.handleClearNotification();
		}, 4000);
	};

	handleClearNotification = () => {
		this.setState({
			message: "",
			showNotification: false,
			isError: false,
		});
	};
}

export default CountryContainer;
