import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
	dots: false,
	infinite: true,
	speed: 1000,
	slidesToShow: 1,
	slidesToScroll: 1,
	nav: false,
	arrows: false,
	autoplay: true,
	autoplaySpeed: 7000
};
class SimpleSliderHome extends React.Component {
	render() {
		return (
			<Slider {...settings}>
				{this.props.children.map((obj, index) => {
					return <React.Fragment key={index}>{obj}</React.Fragment>;
				})}
			</Slider>
		);
	}
}

export default SimpleSliderHome;
