import React, { Component } from 'react';
import moment from 'moment';
import ic_left_arrow from "../../../assets/images/left-arrow-grey.svg";
import ic_right_arrow from "../../../assets/images/right-arrow-grey.svg";
import * as AppConstants from "../../../config/AppConstant";

class MiniCalendar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            scheduledAtDate: this.props.ScheduledAtDate,
            FinalArray: [],
            CalendarAssetsAllData: this.props.MyState.CalendarAssetsAllData,
            currentMonth: this.props.MyState.CurrentMonth,
            currentYear: this.props.MyState.CurrentYear,
            currentMonthName: this.props.MyState.CurrentMonthName,
            firstDateWeek: this.props.MyState.FirstDateWeek,
            PracticeMonthData: this.props.MyState.PracticeMonthData,
            monthNames: AppConstants.monthsName,
            totalDayOfMonth: [],
            totalCards: [],
            currentMonthNames: AppConstants.monthsName[new Date().getMonth()],
            DayNames: [{ "ID": 1, "Name": "Sun" }, { "ID": 2, "Name": "Mon" }, { "ID": 3, "Name": "Tue" }, { "ID": 4, "Name": "Wed" }, { "ID": 5, "Name": "Thu" }, { "ID": 6, "Name": "Fri" }, { "ID": 7, "Name": "Sat" }],
        };
    }


    componentDidMount = () => {
        const CurrentMonthData = this.props.MyState.CalendarAssetsAllData.filter((e) => e.CalendarMonth == this.props.MyState.CurrentMonth && e.CalendarYear == this.props.MyState.CurrentYear)

        let FinalArray = [], Counter = 0, FirstDay = 0;

        CurrentMonthData.map((item) => {
            JSON.parse(item.CalendarAssets).map((itm) => {
                if (Counter == 0) {
                    FirstDay = itm.DayNo == undefined ? this.state.firstDateWeek : itm.DayNo;
                    for (let t = 1; t < FirstDay; t++) {
                        FinalArray.push({ DayNo: '0' })
                    }
                    Counter = FirstDay;
                }
                FinalArray.push({ ...itm, totalAssets: JSON.parse(item.CalendarAssets).filter((e) => e.AssetDate == itm.AssetDate).length });
            })
            this.setState({ FinalArray })
        })

    }

    nextMonth(currentMonth) {
        var current = ''
        this.setState({ currentMonth: currentMonth == 11 ? 12 : currentMonth == 12 ? 1 : currentMonth + 1 })
        if (currentMonth == 12) {
            current = new Date(this.state.currentYear + 1, 0, 1);
            this.setState({ currentYear: current.getFullYear(), firstDateWeek: current.getDay() + 1, currentMonthName: this.state.monthNames[current.getMonth()] })
        } else {
            current = new Date(this.state.currentYear, currentMonth, 1);
            this.setState({ currentYear: current.getFullYear(), firstDateWeek: current.getDay() + 1, currentMonthName: this.state.monthNames[current.getMonth()] })
        }

        let FinalArray = [], Counter = 0, FirstDay = 0, CMonth = currentMonth == 12 ? 1 : currentMonth + 1, CYear = currentMonth == 12 ? this.state.currentYear + 1 : this.state.currentYear;
        const CurrentMonthData = this.state.CalendarAssetsAllData.filter((e) => e.CalendarMonth == CMonth && e.CalendarYear == CYear)
        if (CurrentMonthData.length) {
            CurrentMonthData.map((item) => {
                JSON.parse(item.CalendarAssets).map((itm) => {
                    if (Counter == 0) {
                        FirstDay = itm.DayNo == undefined ? current.getDay() + 1 : itm.DayNo;
                        for (let t = 1; t < FirstDay; t++) {
                            FinalArray.push({ DayNo: '0' })
                        }
                        Counter = FirstDay;
                    }
                    FinalArray.push({ ...itm, totalAssets: JSON.parse(item.CalendarAssets).filter((e) => e.AssetDate == itm.AssetDate).length });
                })
                this.setState({ FinalArray })
            })
        } else {
            this.setState({ FinalArray: [] })
            var pairs = [], cards = [], daysInMonth = new Date(current.getFullYear(), current.getMonth() + 1, 0).getDate();
            for (let i = 0; i < daysInMonth; i++) { pairs.push({ Date: current.getFullYear() + "-" + (current.getMonth() + 1) + "-" + parseInt(i + 1) }); }
            for (let i = 0; i < 42; i++) {
                if (i < pairs.length + new Date(this.state.currentYear, currentMonth, 1).getDay()) { cards.push({ ID: i + 1 }); }
            }
            this.setState({ totalDayOfMonth: pairs, totalCards: cards })
        }
    }

    previousMonth(currentMonth) {
        this.setState({ currentMonth: currentMonth == 1 ? 12 : currentMonth - 1 })
        var current = ''
        if (currentMonth == 1) {
            current = new Date(this.state.currentYear, -1, 1);
            this.setState({ currentYear: current.getFullYear(), firstDateWeek: current.getDay() + 1, currentMonthName: this.state.monthNames[current.getMonth()] })
        } else {
            current = new Date(this.state.currentYear, currentMonth - 2, 1);
            this.setState({ currentYear: current.getFullYear(), firstDateWeek: current.getDay() + 1, currentMonthName: this.state.monthNames[current.getMonth()] })
        }

        let FinalArray = [], Counter = 0, FirstDay = 0, CMonth = currentMonth == 1 ? 12 : currentMonth - 1, CYear = currentMonth == 1 ? this.state.currentYear - 1 : this.state.currentYear;
        const CurrentMonthData = this.state.CalendarAssetsAllData.filter((e) => e.CalendarMonth == CMonth && e.CalendarYear == CYear)

        CurrentMonthData.map((item) => {
            JSON.parse(item.CalendarAssets).map((itm) => {
                if (Counter == 0) {
                    FirstDay = itm.DayNo == undefined ? current.getDay() + 1 : itm.DayNo;
                    for (let t = 1; t < FirstDay; t++) {
                        FinalArray.push({ DayNo: '0' })
                    }
                    Counter = FirstDay;
                }
                FinalArray.push({ ...itm, totalAssets: JSON.parse(item.CalendarAssets).filter((e) => e.AssetDate == itm.AssetDate).length });
            })
            this.setState({ FinalArray })
        })
    }

    extractDatePart = (AssetDate, Index) => {
        if (Index == -1) {
            return AssetDate
        } else {
            return AssetDate.split("-")[Index]
        }
    }
    pad(num, size) {
        var s = "00" + num;
        return s.substr(s.length - size);
    }

    getDay(dt) {
        const d = new Date(dt.replace('-', '/').replace('-', '/').replace('-', '/'));
        return d.getDate();
    }

    setSelectedDate(AssetDate) {
        this.props.SetSelectedDate(AssetDate)
        this.setState({ scheduledAtDate: moment(AssetDate).format('YYYY-MM-DD') })
    }

    formatDate(date) {
        return moment(date).format('YYYY-MM-DD')
    }

    isFutureDate(DateValue, AddDays) {
        if (AddDays == "" || AddDays == undefined || AddDays == null) {
            {/* Add 10 Days To Add Post  */ }
            return new Date().getTime() > new Date(DateValue).getTime()
        } else {
            return new Date(new Date().getTime() + (AddDays * 24 * 60 * 60 * 1000)) < new Date(DateValue).getTime()
        }
    }

    render() {
        const { scheduledAtDate, currentYear, currentMonth, FinalArray, currentMonthName, totalCards, totalDayOfMonth, firstDateWeek, DayNames, PracticeMonthData } = this.state;
        return (
            <div style={{ minWidth: "90px", maxHeight: '300px', border: '1px solid #d3d3d3', borderRadius: '5px' }}>
                <div style={{ background: '#d3d3d3' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 15px' }}>
                        <label>{currentMonthName + " " + currentYear}</label>
                        {/* CurrentMonth == 1 && item.DayNames == 1 ? true : CurrentMonth - 1 == item.DayNames ? true : false }) == -1 */}
                        <div style={{ display: 'flex' }}>
                            {PracticeMonthData.findIndex((item) => { return currentMonth == 1 && item.MonthNo == 12 && currentYear - 1 == item.YearNo ? true : currentMonth - 1 == item.MonthNo && currentYear == item.YearNo ? true : false }) != -1
                                ? <a title="" style={{ marginRight: '5px', padding: '3px', cursor: 'pointer' }}
                                    onClick={() => { this.previousMonth(currentMonth) }}>
                                    <img src={ic_left_arrow} alt='icon' />
                                </a>
                                : <></>}
                            {PracticeMonthData.findIndex((item) => { return currentMonth == 12 && item.MonthNo == 1 && currentYear + 1 == item.YearNo ? true : currentMonth + 1 == item.MonthNo && currentYear == item.YearNo ? true : false }) != -1
                                ? <a title="" style={{ marginLeft: '5px', padding: '3px', cursor: 'pointer' }}
                                    onClick={() => { this.nextMonth(currentMonth) }}>
                                    <img src={ic_right_arrow} alt='icon' />
                                </a>
                                : <></>}
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: '100%', gridGap: '5px', padding: '10px 0px' }}>{DayNames.map((Item, Index) => {
                        return (<div style={{ width: '100%', textAlign: 'center', padding: '2px 4px' }} key={Index}>{Item.Name[0]}</div>)
                    })}
                    </div>
                </div>
                <div style={{ display: 'grid', height: "200px", gridTemplateColumns: '12.25% 12.25% 12.50% 12.50% 12.25% 12.50% 13%', gridTemplateRows: '30px 30px 30px 30px 30px 30px', gridGap: '4px 5px' }}>

                    {FinalArray.length
                        ? FinalArray.map((Item, Index) =>
                            Item.DayNo == '0'
                                ? <div style={{ textAlign: 'center', padding: '2px 4px' }} key={Index} />
                                : Item.AssetNo > 1
                                    ? <></>
                                    : <div style={{ textAlign: 'center', padding: '2px 4px' }} key={Index}>
                                        {Item.PreviewURL == undefined || Item.Status == 3
                                            ? <div className={`daygrid-day-top ${this.formatDate(Item.AssetDate) == this.formatDate(scheduledAtDate) && 'daygrid-day-top-active'}`}
                                                onClick={() => { this.setSelectedDate(Item.AssetDate); }} >
                                                {this.getDay(Item.AssetDate)}
                                            </div>
                                            : <div className={`dayDate-dote ${this.formatDate(Item.AssetDate) == this.formatDate(scheduledAtDate) && 'dayDate-dote-active'}`}
                                                onClick={() => { this.setSelectedDate(Item.AssetDate); }} />}
                                    </div>)
                        : totalCards.map((Item, Index) => {
                            return (
                                <div style={{ textAlign: 'center', padding: '2px 4px' }}>
                                    {totalDayOfMonth.map((item, index) => index == Index - firstDateWeek + 1 &&
                                        <>{this.extractDatePart(item.Date, 1) != new Date().getMonth() + 1 || Item.ID > new Date().getDate() + 1
                                            ? <div className={`daygrid-day-top ${this.formatDate(item.Date) == this.formatDate(scheduledAtDate) && 'daygrid-day-top-active'}`}
                                                onClick={() => { this.setSelectedDate(item.Date) }} >
                                                {this.extractDatePart(item.Date, 2)}
                                            </div>
                                            : <div className={`daygrid-day-top ${this.formatDate(item.Date) == this.formatDate(scheduledAtDate) && 'daygrid-day-top-active'}`}>
                                                {this.extractDatePart(item.Date, 2)}
                                            </div>}</>
                                    )}
                                </div>)
                        })}
                </div>
            </div>
        )
    }
}

export default MiniCalendar;