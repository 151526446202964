/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'
import './AssetTypesForm.css'
import * as APIController from '../../../../api/APIController';
import * as yup from 'yup';
import Editor from '../../../campaign/components/editor';

import Notification from '../../../../shared/components/notification/notification';
const AdminAssetTypesForm = props => {
    const initialValues = {
        AssetTypeId: props.history.location.state.AssetTypeId,
        Name: props.history.location.state.AssetTypeNameEdit,
        freeasset: props.history.location.state.FreeAssetTypeEdit,
        directmailasset: props.history.location.state.DirectMailAssetTypeEdit,
        active: props.history.location.state.AssetTypeActiveEdit
    }
    const [Description, setDescription] = React.useState(props.history.location.state.DescriptionEdit);
    const [isButtonValue, setButtonValue] = React.useState(false);

    const [IsLoading, setIsLoading] = React.useState(false);

    const wsAddEditAssetType = (ID, Name, Description, IsFreeAssetType, IsDirectMailAssetType, IsActive) => {
        setIsLoading(true)
        APIController.AddEditAssetType(ID, Name, Description, IsFreeAssetType, IsDirectMailAssetType, IsActive)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    handleNotification(response.object.message);
                    if (isButtonValue === true) {
                        props.history.replace({
                            pathname: '/campaigns/AssetTypes',
                            state: { showMessage: response.object.message }
                        })
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }
    return (
        <>
            <section className="content-right-main">
                {props.history.location.state.AssetTypeIdEdit == 0 ? <h1>Add Asset Type</h1> : <h1>Edit Asset Type</h1>}
                <Formik
                    initialValues={initialValues}
                    validationSchema={validateAssetTypesForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        if (Description == '') {
                            handleNotification("Description is required", true);
                        } else {
                            wsAddEditAssetType(props.history.location.state.AssetTypeIdEdit,
                                values.Name, Description, values.freeasset, values.directmailasset, values.active)
                            if (isButtonValue === false) {
                                values.Name = "";
                                values.freeasset = 0;
                                values.directmailasset = 0;
                                values.active = 1;
                                setDescription('');
                            }
                        }
                    }} >
                    {({
                        values,
                        handleSubmit,
                        setFieldValue,
                        errors,
                        touched,
                    }) => {
                        return (
                            <div className="form-main">
                                <form onSubmit={handleSubmit}>
                                    <div className={`form_field ${errors && touched.Name && errors.Name && errors.Name !== '' ? 'error' : ''}`}>
                                        <div className="field-left"> <label>Name</label> </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Name" name="Name" value={values.Name} maxLength="50" />
                                            {errors && touched.Name && errors.Name && errors.Name !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='Name' render={(err) => { return err; }} />
                                                </span>)}
                                        </div>
                                    </div>
                                    <div className={`form_field`}>
                                        <div className="field-left"> <label>Description</label> </div>
                                        <div className="field-right asset-full" style={{ padding: '0px', marginBottom: '-0px' }}>
                                            {/* <Field type="text" as="textarea" placeholder="Enter Description" name="Description" value={values.Description} /> */}
                                            <Editor content={Description} setContent={setDescription} />
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Is Free Asset ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="freeasset" id="freeasset-yes-text"
                                                    defaultValue={values.freeasset}
                                                    defaultChecked={values.freeasset != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('freeasset', 1)} />
                                                <label htmlFor="freeasset-yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="freeasset" id="freeasset-no-text"
                                                    defaultValue={values.freeasset}
                                                    defaultChecked={values.freeasset == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('freeasset', 0)} />
                                                <label htmlFor="freeasset-no-text">No</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Is Direct Mail Asset ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="directmailasset" id="directmail-yes-text"
                                                    defaultValue={values.directmailasset}
                                                    defaultChecked={values.directmailasset != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('directmailasset', 1)} />
                                                <label htmlFor="directmail-yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="directmailasset" id="directmail-no-text"
                                                    defaultValue={values.directmailasset}
                                                    defaultChecked={values.directmailasset == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('directmailasset', 0)} />
                                                <label htmlFor="directmail-no-text">No</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Is Active ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="active-yes-text"
                                                    defaultValue={values.active}
                                                    defaultChecked={values.active != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 1)} />
                                                <label htmlFor="active-yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="active-no-text"
                                                    defaultValue={values.active}
                                                    defaultChecked={values.active == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 0)} />
                                                <label htmlFor="active-no-text">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <Notification
                                        isError={isError}
                                        message={message}
                                        showNotification={showNotification}
                                        clearNotification={handleClearNotification.bind(this)}
                                        closeNotification={handleClearNotification.bind(this)}
                                    />
                                    <div className="form-submit">
                                        <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" onClick={() => setButtonValue(true)} />
                                        </div>
                                        {props.history.location.state.AssetTypeIdEdit == 0 ?
                                            <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                                <input type="submit" value={IsLoading ? "Saving..." : "save & add new"} name="save & add new" onClick={() => setButtonValue(false)} />
                                            </div> : null}
                                        <div className="button-field">
                                            <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )
                    }}
                </Formik>
            </section>
        </>
    )
}
const validateAssetTypesForm = yup.object().shape({
    Name: yup.string().required('Name is required')
});


export default AdminAssetTypesForm;