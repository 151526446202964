/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'
import * as APIController from '../../../../api/APIController.js';
import * as yup from 'yup';

import Notification from '../../../../shared/components/notification/notification';
const NegativeKeywordForm = props => {
    const initialValues = { countryname: props.history.location.state.countryNameEdit }
    const [isButtonValue, setButtonValue] = React.useState(false);
    const [IsLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        document.getElementById('countryname').focus();
    }, [props])


    const wsAddEditNegativeKeyword = (ID, Name) => {
        setIsLoading(true)
        APIController.AddEditNegativeKeyword(ID, Name)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    if (isButtonValue === true && response.object.status == 1) {
                        props.history.replace({
                            pathname: '/negativeKeyword',
                            state: { showMessage: response.object.message }
                        })
                    }
                    else if (response.object.status == 1) {
                        handleNotification(response.object.message);
                    } else {
                        handleNotification(response.object.message, true);
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }
    return (
        <>
            <section className="content-right-main">
                {props.history.location.state.countryIdEdit == 0 ? <h1>Add Keyword</h1> : <h1>Edit Keyword</h1>}
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validateCountryForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        wsAddEditNegativeKeyword(props.history.location.state.countryIdEdit, values.countryname)
                        if (isButtonValue === false) { values.countryname = ""; }
                    }} >
                    {({
                        values,
                        handleSubmit,
                        setFieldValue,
                        errors,
                        touched,
                    }) => {
                        return (
                            <div className="form-main">
                                <form onSubmit={handleSubmit}>
                                    {/* <div className="form_field"> */}
                                    <div className={`form_field ${errors && touched.countryname && errors.countryname && errors.countryname !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>Negative Keyword</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Keyword" name="countryname" value={values.countryname} maxLength="500" id={'countryname'} />
                                            {errors && touched.countryname && errors.countryname && errors.countryname !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='countryname' render={(err) => { return err; }} />
                                                </span>)}
                                        </div>
                                    </div>

                                    <Notification
                                        isError={isError}
                                        message={message}
                                        showNotification={showNotification}
                                        clearNotification={handleClearNotification.bind(this)}
                                        closeNotification={handleClearNotification.bind(this)}
                                    />

                                    <div className="form-submit">
                                        <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" onClick={() => { setButtonValue(true) }} />
                                        </div>
                                        {props.history.location.state.countryIdEdit == 0 ?
                                            <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                                <input type="submit" value={IsLoading ? "Saving..." : "save & add new"} name="save & add new" onClick={() => setButtonValue(false)} />
                                            </div> : null}
                                        <div className="button-field">
                                            <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )
                    }}
                </Formik>
            </section>
        </>
    )
}
const validateCountryForm = yup.object().shape({
    countryname: yup.string().required('Keyword is required'),
});


export default NegativeKeywordForm;