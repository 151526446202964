/*eslint-disable eqeqeq*/
import React from 'react';
import Table from "../../../../shared/components/table/table";
import TableHead from "../../../../shared/components/table/tableHead";
import TableRow from "../../../../shared/components/table/tableRow";
import TableBody from "../../../../shared/components/table/tableBody";
import TableCell from "../../../../shared/components/table/tableCell";
import * as APIController from "../../../../api/APIController.js";
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";
import { Typography, } from '@material-ui/core';
import PerfectScrollbar from "react-perfect-scrollbar";
import PageTitle from "../../../../config/pageTitle";
import * as AppConsatnt from "../../../../config/AppConstant";
import double_left_arrow from '../../../../assets/images/left-doble-arow.svg';
import left_arrow from '../../../../assets/images/left-arrow.svg';
import double_right_arrow from '../../../../assets/images/right-doble-arow.svg';
import right_arrow from '../../../../assets/images/right-arrow.svg';
import Modal from '../../../../shared/components/modal/modal';
import ReportDataForm from "./ReportDataForm";
import deleteIcon from '../../../../assets/images/delete.svg'
import ConfirmtionDialog from '../../../ConfirmDialog/ConfirmationDialog';

class ReportData extends React.Component {
    state = {
        list: [],
        headerList: ["action", "Report Date", "Report Type", "Clicks", "Impression", "CTR", "CPC", "Cost", "Certificate_Email",
            "Certificate_Download", "Appointment_Clicks", "Email Address", "Location"],
        progress: false,
        currentPage: 1,
        count: 0,
        rowsPerPage: 1000000,
        rowsPerPageOptions: [25, 50, 100, 'All'],
        from: 0,
        to: 25,
        page: Math.floor(1 / 25) + 1,
        ImportPopup: false,
        DeleteDialog: false,
        DeleteID: '',
    };
    componentDidMount() {
        this.wsGetReportData(this.state.from, this.state.to);
    }

    wsGetReportData(from, to) {
        this.setState({ progress: true, });
        APIController.GetReportData(this.props.location.state.OrderID).then((response) => {
            if (response.object.data.length) {
                this.setState({
                    to: isNaN(to) ? response.object.data.length : to,
                    from: from,
                    count: response.object.data.length,
                    list: response.object.data,
                    page: Math.floor(response.object.data.length / this.state.rowsPerPage) + 1,
                    progress: false,
                });
            } else {
                this.setState({
                    list: [],
                    count: 0,
                    progress: false,
                });
            }
        });
    }

    handleChangeInCurrentPage = (value) => {
        if (value <= this.state.page && Number(parseInt(value))) {
            this.handlePageChange(Number(value));
        }
    }

    handleRowsPerPageChange = (value) => {
        let from = 0
        let to = value;
        this.setState({ rowsPerPage: value, currentPage: 1, to: to, from: from })
        this.wsGetReportData(from, to);
    }

    handlePageChange = (values) => {
        let from = values === 1 ? 0 * this.state.rowsPerPage : (values - 1) * this.state.rowsPerPage
        let to = values === 1 ? this.state.rowsPerPage : (from + this.state.rowsPerPage);
        this.setState({ currentPage: Number(values), to: to, from: from })
        this.wsGetReportData(from, to);
    }

    RenderData = () => {
        this.wsGetReportData(this.state.from, this.state.to);
    }

    wsDeleteReportData = (ID) => {
        APIController.DeleteReportData(ID)
            .then((response) => {
                if (response.error == null) {
                    if (response.object.status == 1) {
                        // handleNotification(response.object.message);
                    } else {
                        // handleNotification(response.object.message, true);
                    }
                    this.setState({ DeleteDialog: false })
                    this.wsGetReportData(this.state.from, this.state.to);
                }
                else if (response.error.message && response.error.message.length > 0) {
                    // handleNotification(response.error.message)
                }
            })
    }

    render() {
        const { currentPage, count, rowsPerPage, rowsPerPageOptions, from, to, page } = this.state;
        return (
            <>
                <section className='content-right-main'>
                    <div style={{ display: 'flex', justifyContent: "space-between" }}>
                        <PageTitle Title={AppConsatnt.ContactDetais.ReportData} />
                        <div className='button-field button-field-country'>
                            <button onClick={() => this.setState({ ImportPopup: true })}>Import Data</button>
                        </div>
                    </div>
                    <div className="table-main-wrap countrys-class">
                        <PerfectScrollbar component='div' style={{ width: '100%' }}>
                            <Table>
                                <TableHead> <TableRow> {this.state.headerList.map((obj, index) => { return <TableCell key={index}>{obj}</TableCell>; })} </TableRow> </TableHead>
                                <TableBody>
                                    {this.state.list.length ? this.state.list.map((obj, index) => {
                                        return (
                                            <TableRow>
                                                <TableCell>
                                                    <span>
                                                        <img src={deleteIcon} alt="delete" style={{ cursor: 'pointer', marginRight: '3px' }}
                                                            onClick={() => { this.setState({ DeleteID: obj.ID, DeleteDialog: true }) }} />
                                                    </span>
                                                </TableCell>
                                                <TableCell> <Typography style={{ color: '#0A0A0A', fontSize: '13px', textAlign: 'start' }}>{obj.ReportDate}</Typography> </TableCell>
                                                <TableCell> <Typography style={{ color: '#0A0A0A', fontSize: '13px', textAlign: 'center' }}>{obj.ReportType}</Typography> </TableCell>
                                                <TableCell> <Typography style={{ color: '#0A0A0A', fontSize: '13px', textAlign: 'center' }}>{obj.Clicks}</Typography> </TableCell>
                                                <TableCell> <Typography style={{ color: '#0A0A0A', fontSize: '13px', textAlign: 'start' }}>{obj.Impression}</Typography> </TableCell>
                                                <TableCell> <Typography style={{ color: '#0A0A0A', fontSize: '13px', textAlign: 'center' }}>{obj.CTR}</Typography> </TableCell>
                                                <TableCell> <Typography style={{ color: '#0A0A0A', fontSize: '13px', textAlign: 'center' }}>{obj.CPC}</Typography> </TableCell>
                                                <TableCell> <Typography style={{ color: '#0A0A0A', fontSize: '13px', textAlign: 'start' }}>{obj.Cost}</Typography> </TableCell>
                                                <TableCell> <Typography style={{ color: '#0A0A0A', fontSize: '13px', textAlign: 'center' }}>{obj.Certificate_Email}</Typography> </TableCell>
                                                <TableCell> <Typography style={{ color: '#0A0A0A', fontSize: '13px', textAlign: 'center' }}>{obj.Certificate_Download}</Typography> </TableCell>
                                                <TableCell> <Typography style={{ color: '#0A0A0A', fontSize: '13px', textAlign: 'center' }}>{obj.Appointment_Clicks}</Typography> </TableCell>
                                                <TableCell> <Typography style={{ color: '#0A0A0A', fontSize: '13px', textAlign: 'center' }}>{obj.MailchimpDetails}</Typography> </TableCell>
                                                <TableCell> <Typography style={{ color: '#0A0A0A', fontSize: '13px', textAlign: 'center' }}>{obj.Location}</Typography> </TableCell>
                                            </TableRow>
                                        );
                                    }) : <TableRow>
                                        <TableCell colSpan={this.state.headerList.length} className="not_found">
                                            {this.state.progress ? <CircularProgress /> : <label style={{ display: 'flex', justifyContent: 'center' }}>No Order Found</label>}
                                        </TableCell>
                                    </TableRow>}
                                </TableBody>
                            </Table>
                            <div className="table-pagination">
                                <div className="pagination-info">
                                    <p>Showing {from + 1} - {to > count ? count : to} of {count}</p>
                                </div>
                                <div className="pagination-control">
                                    <a href="#!" title="" className={`double-left-arrow ${currentPage === 1 ? 'disabled' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.handleChangeInCurrentPage(1)
                                        }}
                                    ><img src={double_left_arrow} alt="left" /></a>
                                    <a href="#!" title="" className={`simple-left-arrow ${currentPage === 1 ? 'disabled' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.handleChangeInCurrentPage(currentPage - 1)
                                        }}
                                    ><img src={left_arrow} alt="single_left" /></a>
                                    <span>Page</span>
                                    <input type="text" name="textbox" value={currentPage === 0 ? currentPage + 1 : currentPage} onChange={(e) => this.handleChangeInCurrentPage(e.target.value)} />
                                    <span>of {page}</span>
                                    <a href="#!" title="" className={`simple-right-arrow ${currentPage === page ? 'disabled' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.handleChangeInCurrentPage(currentPage + 1)
                                        }}
                                    ><img src={right_arrow} alt="right" /></a>
                                    <a href="#!" title="" className={`double-right-arrow ${currentPage === page ? 'disabled' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.handleChangeInCurrentPage(page)
                                        }}><img src={double_right_arrow} alt="single_right" /></a>
                                    <select onChange={(e) => this.handleRowsPerPageChange(e.target.value)} defaultValue={rowsPerPage}>
                                        {
                                            rowsPerPageOptions.map((obj, index) => {
                                                return <option value={obj} key={index}>{obj}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </PerfectScrollbar>
                    </div>
                </section>
                {this.state.ImportPopup && (
                    <Modal
                        show={this.state.ImportPopup}
                        handleClose={() => this.setState({ ImportPopup: false })}>
                        <ReportDataForm
                            Message={this.state.Message}
                            OrderID={this.props.history.location.state.OrderID}
                            title={"Import Reports Data"}
                            RenderData={this.RenderData.bind(this)}
                            closeModal={() => this.setState({ ImportPopup: false })}
                        />
                    </Modal>
                )}

                {this.state.DeleteDialog && (
                    <Modal
                        show={this.state.DeleteDialog}
                        handleClose={() => this.setState({ DeleteDialog: false })}>
                        <ConfirmtionDialog
                            title={"Delete Report Data"}
                            description={"Are you sure you want to delete this Report Data?"}
                            deletePress={() => this.wsDeleteReportData(this.state.DeleteID)}
                            closeModal={() => this.setState({ DeleteDialog: false })}
                        />
                    </Modal>
                )}

            </>
        )
    }
}

export default ReportData;