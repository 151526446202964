import React from 'react';
import * as APIController from '../../api/APIController';
import { Grid } from '@material-ui/core';
import { ExcelRenderer } from 'react-excel-renderer';
import UploadExcelSheetList from '../../features/admin/component/componentUploadExcelSheet/UploadExcelSheetlist';

class UploadExcelSheet extends React.Component {

    state = {
        rows: [],
        cols: [],
        myArray: [],
        showData: [],
        checkStatus: '',
        currentPage: 0,
        count: 0,
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 25],
        list: [],
        from: 0,
        to: 5
    }

    wsGetImportPractices(ExcelData) {
        APIController.ImportPractices(this.state.myArray)
            .then((response) => {
                if (response.error == null) {
                    this.setState({
                        showData: response.object.data,
                        count: response.object.data.length,
                        checkStatus: response.object.status,
                    })
                    // ToastsStore.warning(response.object.message);
                }
                else if (response.error.message && response.error.message.length > 0) {
                    // ToastsStore.warning(response.error.message)
                }
            })
    }

    fileHandler = (event) => {
        let fileObj = event.target.files[0];
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
            var obj = {};
            var mainobj = []
            for (var i = 1; i < resp.rows.length; i++) {
                for (var j = 0; j < resp.cols.length; j++) {
                    if (resp.rows[0][j] != undefined)
                        obj[resp.rows[0][j].replace(' ', '')] = resp.rows[i][j];
                }
                mainobj.push(obj);
                obj = {};
            }
            this.setState({ myArray: mainobj });
            // this.wsGetImportPractices(mainobj);
            this.setState({
                list: resp.rows,
                cols: resp.cols,
                rows: resp.rows
            });
        });
    }


    render() {
        return (
            <>
                {this.state.checkStatus === "1" ? <UploadExcelSheet></UploadExcelSheet> :
                    <section className="content-right-main">
                        <h1>Import Practicess</h1>
                        {this.state.myArray.length === 0 ? null :
                            <div className="button-field button-field-upload">
                                <button type='submit' onClick={() => { this.wsGetImportPractices(this.state.myArray) }}>
                                    + Upload File
                                </button>
                            </div>}
                        <input hidden type="file" accept=".xlsx,.xls" onChange={this.fileHandler.bind(this)} style={{ "padding": "15px", }} className="button-field button-field-upload"></input>

                        {this.state.myArray.length <= 0 ? null : <UploadExcelSheetList {...this.state}
                            {...this.state.myArray}
                            history={this.props.history}
                            handleChangeRowsPerPage={this.handleRowsPerPageChange}
                            handleChangePage={this.handlePageChange}
                            applyFilter={this.handleApplyFilter}
                        />}
                    </section>
                }
            </>
        )
    }

    /**
        * handleRowsPerPageChange method is used to update rows per page
        * it will reset page to 1
        */
    handleRowsPerPageChange = (value) => {
        let from = 0
        let to = value;
        this.setState({ rowsPerPage: value, currentPage: 1, to: to, from: from })
    }

    /**
     * handlePageChange method is used to change page
     */
    handlePageChange = values => {
        let from = values === 1 ? 0 * this.state.rowsPerPage : (values - 1) * this.state.rowsPerPage
        let to = values === 1 ? this.state.rowsPerPage : (from + this.state.rowsPerPage);
        this.setState({ currentPage: Number(values), to: to, from: from })
    }

    /**
     * handleApplyFilter method is used to apply filter on every columns
     */
    handleApplyFilter = (tag, values, type) => {
        if (values !== "") {
            let searchUsers = []
            /**
             * it will check if filter is apply from select than it will match exact keyword
             * else it will find nearest word
             */
            if (type === 'select') {
                searchUsers = this.state.list1.filter(
                    (us) => us[tag].toLowerCase() === values.toLowerCase()
                );
            } else {
                searchUsers = this.state.list.filter(
                    //  searchUsers = data.filter(
                    (us) => us[tag].toLowerCase().indexOf(values.toLowerCase()) > -1
                );
            }
            this.setState({
                list: searchUsers,
                currentPage: 0,
                count: searchUsers.length,
                rowsPerPage: 5,
                rowsPerPageOptions: [5, 10, 25],
                from: 0,
                to: 5
            });
        } else {
            this.wsGetImportPractices();
            this.setState({
                currentPage: 0,
                rowsPerPage: 5,
                rowsPerPageOptions: [5, 10, 25],
                from: 0,
                to: 5
            });
        }
    }
}


export default UploadExcelSheet;