import React from 'react';
import { Typography, Grid, Divider, CircularProgress } from '@material-ui/core';
import PerfectScrollbar from "react-perfect-scrollbar";
import Paper from "@material-ui/core/Paper";
import CountUp from "react-countup";
import * as APIController from '../../../api/APIController.js';
import clicks from '../../../assets/images/clicks.svg';
import impressions from '../../../assets/images/impressions.svg';
import engagements from '../../../assets/images/engagements.svg';
import './DashboardStyle.css';
import DateRangePicker from 'react-daterange-picker'
import 'react-daterange-picker/dist/css/react-calendar.css' // For some basic styling. (OPTIONAL)
import DateRangeIcon from '@material-ui/icons/DateRange';
import moment from 'moment';
import { RangeDatePicker } from 'react-google-flight-datepicker';
import * as AppConstants from "../../../config/AppConstant.js";

import { Line, Doughnut } from 'react-chartjs-2';

const Style = {
    txttitleStyle: {
        fontSize: '16px',
        letterSpacing: '0.3px',
        lineHeight: 1.6,
        textAlign: 'center',
        color: '#9E9E9E'
    },
    txtdesStyle: {
        fontSize: '22px',
        letterSpacing: '0.3px',
        lineHeight: 1.6,
        textAlign: 'center',
    },
    AdsNameBox: {
        padding: '10px',
        margin: '0px',
        width: '200px',
    },
    BoxStyle: {
        borderRadius: 5,
        border: '0.5px solid #1588ba',
        backgroundColor: '#fff',
        padding: '15px',
        margin: '0px',
        elevation: 4,
        boxShadow: '1px 4px 2px #9E9E9E'
    },
    BoxStyle1: {
        borderRadius: 5,
        border: '0.5px solid #0f0f',
        backgroundColor: '#fff',
        padding: '10px',
        margin: '7.5px',
        elevation: 4,
        boxShadow: '1px 4px 2px #9E9E9E'
    },
    listStyle: {
        fontSize: '15px',
        letterSpacing: '0.3px',
        lineHeight: 1.6,
        textAlign: 'center',
        color: '#0A0A0A',
    },
    contentTitle: {
        fontSize: '25px',
        fontWeight: 'bold',
        color: '#0A5C7F',
        marginBottom: '5px'

    },
    Content: {
        fontSize: '12px',
        color: '#646464',
        // lineHeight:1.5,
    },
    portalWidgetHeading: {
        fontSize: '18px',
        fontWeight: 'bold',
        fontFamily: 'Raleway',
        color: '#4B4C4C',
        borderLeftStyle: 'solid',
        '&:after': {
            content: '""',
            width: 2,
            height: '0%',
            position: 'absolute',
            bottom: 0,
            left: -2,
            transition: 'height .5s'
        }
    }
}

class DashboardLandingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            BrandID: '', BrandsData: [],
            PracticeData: [], PracticeID: '',
            StartDate: moment().startOf('month').format('MM/DD/YYYY'),
            EndDate: moment(new Date()).format('MM/DD/YYYY'),

            IsMainLoading: false,
            IsCard: 'impressions',
            IsLoadingDropdown: true,
            IsLoadingMailChip: true,
            IsShowTopChart: false,
            IsShowMailChip: false,
            ChartLoading: true,


            hoverView: '',
            TotalPageView: 0,
            TotalEngagement: 0,
            LeadsMain: [],

            AllWeeks: ['29', '30', '32', '33', '34', '35', '36', '37', '38', '39', '40'],
            SearchData: ['9542', '5516', '11118', '1059', '4661', '9623', '21030', '12045', '5124', '20170', '12283'],
            GoogleData: ['1118', '11211', '15353', '18762', '9623', '5428', '6889', '7124', '10170', '16283', '4661'],

            MasterImpressions: [],
            MasterImpressions1: [],

            AllReportDate: [],
            AllPageViewsData: [],
            AllAPIDATA: [],

            BrandNames: [], TotalBrandPageView: [],
            UserTotal: 0, NewUserTotal: 0, SessionsPerUser: 0, ScreenPageViews: 0, ActiveUsers: 0, AverageUserEngagementDuration: 0, EngagementRate: 0, BounceRate: 0,
            Engagements: [],
            EngagementsNames: [], TotalEngagements: []

        };
    }

    componentDidMount() {
        this.wsGetLandingPageDashboard(this.state.PracticeID, this.state.BrandID, this.state.StartDate, this.state.EndDate)
        // this.wsGetLandingPageDashboard(this.state.PracticeID, this.state.BrandID, "10-01-2023", this.state.EndDate)
    }

    PressCards(value) {
        this.setState({ IsCard: value })
        this.SplitMainData(this.state.AllAPIDATA, value)
    }

    wsGetLandingPageDashboard(PracticeID, BrandID, StartDate, EndDate) {
        this.setState({ IsMainLoading: true, AllReportDate: [], AllPageViewsData: [] })
        APIController.GetLandingPageDashboard(PracticeID, BrandID, moment(StartDate).format('MM/DD/YYYY'), moment(EndDate).format('MM/DD/YYYY')).then((response) => {
            if (response.object.data != "") {
                this.setState({
                    AllAPIDATA: response.object.data,
                    BrandsData: response.object.data.Brands,

                    LeadsMain: response.object.data.LeadsMain,
                    Engagements: response.object.data.Engagements,
                    IsLoadingMailChip: false,
                    IsShowMailChip: true,

                    IsLoadingDropdown: false,
                })
                if (PracticeID != '') {
                    this.setState({
                        PracticeID: PracticeID,
                        PracticeData: !this.state.PracticeData.length ? response.object.data.Practices : this.state.PracticeData,
                    })
                } else {
                    this.setState({
                        PracticeID: '0',
                        PracticeData: !this.state.PracticeData.length ? response.object.data.Practices : this.state.PracticeData,
                    })
                }

                this.SplitMainData(response.object.data, 'impressions')
                this.addEngagement(response.object.data.LeadsMain.length)

            } else {
                this.setState({ IsLoadingDropdown: false, IsMainLoading: false })
            }
        }).catch((err) => {
            this.setState({ IsLoadingDropdown: false, IsMainLoading: false })
        })
    }

    SplitMainData(MainData, typeID) {
        this.setState({ ChartLoading: true })
        let DateData = [], PageViewsData = [], AllPageViews = [], TotalBrandPageView = [];
        let TotalPageViews = 0, TotalEngagement = 0

        MainData.Main.map((i) => {
            DateData.push(i.ReportDate)
            if (i.TypeID == 1) {
                TotalPageViews = TotalPageViews + i.PageViews
            } else if (i.TypeID == 2) {
                TotalEngagement = TotalEngagement + i.PageViews
            }
        })

        MainData.Brands.map((item, index) => {
            if (typeID == 'impressions') {
                AllPageViews = this.getFilterDataForBrands(MainData, item.ID)
                PageViewsData.push({
                    label: "",
                    data: AllPageViews,
                    lineTension: 0,
                    fill: false,
                    borderColor: AppConstants.graphColors[index],
                    backgroundColor: AppConstants.graphColorsRGB[index],
                })
                TotalBrandPageView.push(AllPageViews.reduce((acc, curr) => acc + curr, 0))
            } else {
                AllPageViews = this.getFilterDataForBrands2(MainData, item.ID)
                PageViewsData.push({
                    label: "",
                    data: AllPageViews,
                    lineTension: 0,
                    fill: false,
                    borderColor: AppConstants.graphColors[index],
                    backgroundColor: AppConstants.graphColorsRGB[index],
                })
                TotalBrandPageView.push(AllPageViews.reduce((acc, curr) => acc + curr, 0))
            }
        })

        const uniqueBrandNames = Array.from(new Set(MainData.Brands.map(item => item.Name)));
        // this.calculateSums(MainData.Leads)

        // console.log('PageViewsData', Array.from(new Set(PageViewsData)));

        this.setState({
            AllReportDate: Array.from(new Set(DateData)), AllPageViewsData: PageViewsData, BrandNames: uniqueBrandNames, TotalBrandPageView,
            TotalPageView: TotalPageViews, TotalEngagement, IsMainLoading: false,
        })

        setTimeout(() => {
            this.setState({ ChartLoading: false })
        }, 1000);
    }

    getFilterDataForBrands(MainData, BrandID) {
        return MainData.Main.filter((e) => { return e.BrandID == BrandID && e.TypeID == 1 }).map((item) => {
            return item.PageViews
        })
    }

    getFilterDataForBrands2(MainData, BrandID) {
        return MainData.Main.filter((e) => { return e.BrandID == BrandID && e.TypeID == 2 }).map((item) => {
            return item.PageViews
        })
    }

    addEngagement = (LeadsLength) => {
        const { Engagements } = this.state;
        const names = [];
        const counts = [];

        Engagements.forEach(engagement => {
            names.push(engagement.Name);
            counts.push(engagement.Count);
        });

        // Adding one more data point to both names and counts arrays
        names.push("Free Trial");
        counts.push(LeadsLength);

        this.setState({ EngagementsNames: names, TotalEngagements: counts });
    };

    calculateSums = (leads) => {
        let totalTotalUsers = 0;
        let totalNewUsers = 0;
        let totalSessionsPerUser = 0;
        let totalScreenPageViews = 0;
        let totalActiveUsers = 0;
        let totalAverageUserEngagementDuration = 0;
        let totalEngagementRate = 0;
        let totalBounceRate = 0;

        for (const lead of leads) {
            totalTotalUsers += lead.TotalUsers;
            totalNewUsers += lead.NewUsers;
            totalSessionsPerUser += lead.SessionsPerUser;
            totalScreenPageViews += lead.ScreenPageViews;
            totalActiveUsers += lead.ActiveUsers;
            totalAverageUserEngagementDuration += lead.AverageUserEngagementDuration;
            totalEngagementRate += lead.EngagementRate;
            totalBounceRate += lead.BounceRate;
        }

        let engagementRateTotal = (totalEngagementRate / leads.length) * 100, bounceRateTotal = (totalBounceRate / leads.length) * 100

        this.setState({
            UserTotal: totalTotalUsers, NewUserTotal: totalNewUsers, SessionsPerUser: totalSessionsPerUser,
            ScreenPageViews: totalScreenPageViews, ActiveUsers: totalActiveUsers, AverageUserEngagementDuration: totalAverageUserEngagementDuration,
            EngagementRate: engagementRateTotal, BounceRate: bounceRateTotal
        })
    };

    onMouseEnterHandler(value) {
        this.setState({ hoverView: value })
    }
    onMouseLeaveHandler() {
        this.setState({ hoverView: '-1' })
    }

    ReloadChart(data) {
        if (data == 'impressions') {
            this.setState({ GoogleData: this.state.MasterImpressions.length && this.state.MasterImpressions[0].split(','), SearchData: this.state.MasterImpressions1.length && this.state.MasterImpressions1[0].split(',') })
        }
        else if (data == 'clicks') {
            this.setState({ GoogleData: [], SearchData: [] })
        }
        else if (data == 'engagement') {
            this.setState({ GoogleData: [], SearchData: [] })
        }
    }

    render() {
        const { IsLoadingDropdown, PracticeID, StartDate, EndDate, IsLoadingMailChip, IsShowTopChart, IsShowMailChip, IsCard } = this.state
        return (
            this.state.IsMainLoading ?
                <Grid container flexDirection='row' justify='center' alignItems='center' style={{ width: '100%', height: window.innerHeight - 200 }}>
                    <CircularProgress style={{ color: '#0a5c7f' }} />
                </Grid> :
                <section className="content-right-main">
                    {/* For Title  */}
                    <div className='content-right-header content-right-heading' >
                        <div style={{ width: "20%" }}>Dashboard</div>

                        <div style={{ width: '79%', marginTop: '4%' }}>
                            <div className='content-right-data'>
                                {this.state.BrandsData.length > 0 &&
                                    <div className='report-select-box'>
                                        {IsLoadingDropdown ? <CircularProgress style={{ color: '#0a5c7f' }} /> :
                                            <select className={'title-content'} style={{ borderRadius: '3px', border: '1px solid #CCCCCC', padding: '8px', fontSize: '13px', textOverflow: 'ellipsis' }}
                                                name='BrandID'
                                                onChange={(e, index) => {
                                                    this.setState({ BrandID: JSON.parse(e.target.value).ID, IsCard: 'impressions' });
                                                    this.wsGetLandingPageDashboard(this.state.PracticeID, JSON.parse(e.target.value).ID, this.state.StartDate, this.state.EndDate)
                                                }}>
                                                <option value={'0'}>{'Select Brand'}</option>
                                                {this.state.BrandsData.map((item, index) => { return <option key={index} value={JSON.stringify(item)}>{item.Name}</option> })}
                                            </select>}
                                    </div>}

                                {this.state.PracticeData.length > 0 &&
                                    <div className='report-select-box' style={{ marginRight: '20px' }}>
                                        {IsLoadingDropdown ? <CircularProgress style={{ color: '#0a5c7f' }} /> :
                                            <select className={'title-content'} style={{ marginLeft: '10px', borderRadius: '3px', border: '1px solid #CCCCCC', padding: '8px', fontSize: '13px', textOverflow: 'ellipsis' }}
                                                name='PracticeID'
                                                defaultValue={PracticeID}
                                                value={PracticeID}
                                                onChange={(e, index) => {
                                                    this.setState({ PracticeID: e.target.value, IsCard: 'impressions' });
                                                    this.wsGetLandingPageDashboard(e.target.value, this.state.BrandID, this.state.StartDate, this.state.EndDate)
                                                }}>
                                                <option value={'0'}>{'All Locations'}</option>
                                                {this.state.PracticeData.map((item, index) => {
                                                    return <option key={index} selected={item.ID == PracticeID ? item.ID : item.PracticeName} value={item.ID}>{item.PracticeName}</option>
                                                })}
                                            </select>}
                                    </div>}

                                <div className='date-piker-box'>
                                    <RangeDatePicker
                                        className="date-piker"
                                        startDate={StartDate}
                                        endDate={EndDate}
                                        onChange={(startDate, endDate) => {
                                            this.setState({ StartDate: startDate, EndDate: endDate, IsCard: 'impressions' })
                                            this.wsGetLandingPageDashboard(this.state.PracticeID, this.state.BrandID, startDate, endDate)
                                        }}
                                        dateFormat="MM/DD/YYYY"
                                        startDatePlaceholder="Start Date"
                                        endDatePlaceholder="End Date"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* For Top Cards */}
                    <div className='card-main container'>
                        <div className='paid-card' onClick={() => this.PressCards('impressions')} >
                            <div className='card-item' style={{ border: IsCard == 'impressions' ? '1px solid #0a5c7f' : 0 }}>
                                <div>
                                    <CountUp separator={','} duration={3} className={'title'} end={this.state.TotalPageView} />
                                    <Typography className={'title-content'}>Total Page Views</Typography>
                                </div>
                                <div style={{ width: '50px', borderRadius: 100, height: '50px' }}>
                                    <img src={impressions} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className='paid-card' onClick={() => this.PressCards('engagement')}  >
                            <div className='card-item' style={{ border: IsCard == 'engagement' ? '1px solid #0a5c7f' : 0 }}>
                                <div>
                                    <CountUp separator={','} duration={3} className={'title'} end={this.state.TotalEngagement} />
                                    <Typography className={'title-content'}>Total Engagements</Typography>
                                </div>
                                <div style={{ width: '50px', borderRadius: 100, height: '50px' }}>
                                    <img src={engagements} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* For Top Charts Report */}
                    <Paper style={{ padding: "15px", margin: '5px', display: 'flex' }}>
                        <div style={{ width: '70%' }}>
                            <Typography className={'title-main'} variant="subheading">{IsCard == 'impressions' ? 'Page Views' : 'Engagements'}</Typography>
                            <div style={{ marginBottom: '10px', marginTop: '10px', borderBottom: '2.5px solid #F5F7FA', width: '100%' }} />
                            <div className='paid-chart'>
                                <div className='paid-chart-inner' style={{ width: '100%' }}>
                                    {this.state.ChartLoading
                                        ? <CircularProgress style={{ color: '#0a5c7f' }} />
                                        : <Line
                                            data={{
                                                labels: this.state.AllReportDate,
                                                datasets: this.state.AllPageViewsData
                                            }}
                                            height={90}
                                            legend={{ responsive: true, display: false, }}
                                        />}
                                </div>
                            </div>
                        </div>
                        <div style={{ width: '30%' }}>
                            <Typography className={'title-main'} variant="subheading">{IsCard == 'impressions' ? 'Page Views' : 'Engagements'}</Typography>
                            <div style={{ marginBottom: '10px', marginTop: '10px', borderBottom: '2.5px solid #F5F7FA', width: '100%' }} />
                            <Doughnut
                                data={{
                                    labels: this.state.BrandNames,
                                    datasets: [{
                                        data: this.state.TotalBrandPageView,
                                        borderWidth: 0,
                                        backgroundColor: AppConstants.graphColors,
                                        hoverBackgroundColor: '#CCCCCC'
                                    }],
                                    doughnutChartOptions: {
                                        responsive: true,
                                        maintainAspectRatio: true,
                                        tooltips: {
                                            enabled: true,
                                            backgroundColor: '#1588ba',
                                            titleFontColor: '#fff',
                                            bodyFontColor: '#fff',
                                            xPadding: 20,
                                            yPadding: 20,
                                            displayColors: false,
                                        }
                                    }
                                }}
                                options={{
                                    animateRotate: true,
                                    animateScale: true,
                                    animation: { duration: 2000, },
                                    legend: { position: "bottom", align: "center", labels: { boxWidth: 30, fontSize: 12, fontColor: '#646464' } },
                                    datalabels: { display: true, color: "white" },
                                    tooltips: { backgroundColor: "#5a6e7f", },
                                    plugins: {
                                        legend: {
                                            position: 'top',
                                            labels: { usePointStyle: true, pointStyle: 'rect' }
                                        },
                                    },
                                }}
                            />
                        </div>
                    </Paper>

                    {/* For MailChimp Data */}
                    {IsShowMailChip ?
                        <Paper style={{ padding: "15px", margin: '5px', display: 'flex' }}>
                            <div style={{ width: '70%' }}>
                                <Typography style={Style.portalWidgetHeading} variant="subheading">{'Free Trial Certificate Emails'}</Typography>
                                <div style={{ marginBottom: '10px', marginTop: '10px', borderBottom: '2.5px solid #6464641A', width: '100%' }} />

                                {IsLoadingMailChip ? <CircularProgress style={{ color: '#0a5c7f' }} />
                                    : <PerfectScrollbar component='div' style={{ height: '350px', overflow: 'scroll' }}>
                                        <table style={{ width: '98%', }} id="table-to-xls">
                                            <tr style={{ width: '100%', padding: '15px' }} className={'table-hed'}>
                                                <th className={'table-column'} style={{ width: '6%' }}>No</th>
                                                <th className={'table-column'} style={{ width: '20%' }}>Date</th>
                                                <th className={'table-column'} style={{ width: '30%' }}>Landing Page</th>
                                                <th className={'table-column'} style={{ width: '50%' }}>Email Address</th>
                                            </tr>
                                            {this.state.LeadsMain.length ? this.state.LeadsMain.map((item, index) => {
                                                return (
                                                    <tr className={(index % 2) ? 'table-row-even' : 'table-row-odd'}>
                                                        <td className={'table-column1'} style={{ width: '6%' }} >{index + 1}</td>
                                                        <td className={'table-column1'} style={{ width: '20%' }} >{item.LeadGeneratedAt}</td>
                                                        <td className={'table-column1'} style={{ width: '30%' }} >{item.LandingPage}</td>
                                                        <td className={'table-column1'} style={{ width: '50%' }} >{item.EmailAddress}</td>
                                                    </tr>
                                                )
                                            }) :
                                                <td colspan="4">
                                                    <Typography className={'title-content'}>No Data Found</Typography>
                                                </td>
                                            }
                                        </table>
                                    </PerfectScrollbar>}
                            </div>

                            <div style={{ width: '30%' }}>
                                <Typography style={Style.portalWidgetHeading} variant="subheading">{'Engagement'}</Typography>
                                <div style={{ marginBottom: '10px', marginTop: '10px', borderBottom: '2.5px solid #6464641A', width: '100%' }} />
                                <Doughnut
                                    data={{
                                        labels: this.state.EngagementsNames,
                                        datasets: [{
                                            data: this.state.TotalEngagements,
                                            borderWidth: 0,
                                            backgroundColor: AppConstants.graphColors,
                                            hoverBackgroundColor: '#CCCCCC'
                                        }],
                                        doughnutChartOptions: {
                                            responsive: true,
                                            maintainAspectRatio: true,
                                            tooltips: {
                                                enabled: true,
                                                backgroundColor: '#1588ba',
                                                titleFontColor: '#fff',
                                                bodyFontColor: '#fff',
                                                xPadding: 20,
                                                yPadding: 20,
                                                displayColors: false,
                                            }
                                        }
                                    }}
                                    options={{
                                        animateRotate: true,
                                        animateScale: true,
                                        animation: { duration: 2000, },
                                        legend: { position: "bottom", align: "center", labels: { boxWidth: 30, fontSize: 12, fontColor: '#646464' } },
                                        datalabels: { display: true, color: "white" },
                                        tooltips: { backgroundColor: "#5a6e7f", },
                                        plugins: {
                                            legend: {
                                                position: 'top',
                                                labels: { usePointStyle: true, pointStyle: 'rect' }
                                            },
                                        },
                                    }}
                                />
                            </div>
                        </Paper>
                        : <></>}

                    {/* style={{ borderRadius: '3px', border: '1px solid #CCCCCC', padding: '8px', fontSize: '13px', textOverflow: 'ellipsis' }} */}

                    {/* <div style={{ padding: "5px", display: 'flex' }}>
                        <div className='card-item' style={{ width: '20%', margin: '5px' }}>
                            <Typography className={'title-content'}>Total Users</Typography>
                            <CountUp separator={','} duration={3} className={'title'} end={this.state.UserTotal} />
                        </div>
                        <div className='card-item' style={{ width: '20%', margin: '5px' }}>
                            <Typography className={'title-content'}>Total New Users</Typography>
                            <CountUp separator={','} duration={3} className={'title'} end={this.state.NewUserTotal} />
                        </div>
                        <div className='card-item' style={{ width: '20%', margin: '5px' }}>
                            <Typography className={'title-content'}>Sessions Per User</Typography>
                            <CountUp separator={','} duration={3} className={'title'} end={this.state.SessionsPerUser} />
                        </div>
                        <div className='card-item' style={{ width: '20%', margin: '5px' }}>
                            <Typography className={'title-content'}>Screen Page Views</Typography>
                            <CountUp separator={','} duration={3} className={'title'} end={this.state.ScreenPageViews} />
                        </div>
                        <div className='card-item' style={{ width: '20%', margin: '5px' }}>
                            <Typography className={'title-content'}>Total Active Users</Typography>
                            <CountUp separator={','} duration={3} className={'title'} end={this.state.ActiveUsers} />
                        </div>
                    </div>
                    <div style={{ padding: "5px", display: 'flex' }}>
                        <div className='card-item' style={{ width: '35%', margin: '5px' }}>
                            <Typography className={'title-content'}>Average User Engagement Duration<span style={{ fontSize: '11px' }}>(sec)</span></Typography>
                            <CountUp separator={','} duration={3} className={'title'} end={this.state.AverageUserEngagementDuration} />
                        </div>
                        <div className='card-item' style={{ width: '25%', margin: '5px' }}>
                            <Typography className={'title-content'}>Total Engagement Rate<span style={{ fontSize: '11px' }}> (%)</span></Typography>
                            <CountUp duration={3} className={'title'} end={this.state.EngagementRate} />
                        </div>
                        <div className='card-item' style={{ width: '25%', margin: '5px' }}>
                            <Typography className={'title-content'}>Total Bounce Rate<span style={{ fontSize: '11px' }}> (%)</span></Typography>
                            <CountUp separator={','} duration={3} className={'title'} end={this.state.BounceRate} />
                        </div>
                    </div> */}
                </section>
        )
    }
}

export default DashboardLandingPage;
