/*eslint-disable eqeqeq*/
import React from 'react';
import forgot_user_ic from '../../../assets/images/forgot-username-ic.svg';
import { Formik, ErrorMessage, Field } from 'formik';
import * as yup from 'yup';
import close_ic from '../../../assets/images/close-ic.svg';
import * as APIController from '../../../../src/api/APIController';

import Notification from '../../../shared/components/notification/notification';
import TextField from "../../../shared/components/TextField/TextField";

const ChangePassword = props => {
    const initialValues = { oldpassword: '', newpassword: '', confirmpassword: '' }
    const [IsLoading, setIsLoading] = React.useState(false);

    const wsChangePassword = (Password, NewPassword, ConfirmPassword) => {
        setIsLoading(true)
        APIController.ChangePassword(Password, NewPassword)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    initialValues.oldpassword = ''
                    initialValues.newpassword = ''
                    initialValues.confirmpassword = ''
                    if (response.object.status == 1) {
                        handleNotification(response.object.message);
                        setTimeout(() => {
                            props.closeModal()
                        }, 4000)
                    }
                    else {
                        handleNotification(response.object.message, true);
                        setTimeout(() => {
                            props.closeModal()
                        }, 4000)
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true)
                }
            })
    }

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    return (
        <div className='popup-box forgot-popup-box'>
            <div className="box-heading">
                {/* <img src={forgot_user_ic} alt="icon" /> */}
                <h2 className="h1">Change Password</h2>
                <button className="close-btn"><img src={close_ic} alt="icon" onClick={props.closeModal} /></button>
            </div>
            <div className="box-content">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validateChangepwdForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        setIsLoading(false)
                        if (values.oldpassword.length <= 5) {
                            handleNotification('Old Password should be minimum 6 characters long', true);
                        } else if (values.newpassword.length <= 5) {
                            handleNotification('New Password should be minimum 6 characters long', true);
                        } else if (values.confirmpassword.length <= 5) {
                            handleNotification('Confirm Password should be minimum 6 characters long', true);
                        } else if (values.confirmpassword !== values.newpassword) {
                            handleNotification('Password and Confirm Password does not Match', true);
                        } else {
                            wsChangePassword(values.oldpassword, values.newpassword, values.confirmpassword)
                        }
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        errors,
                        touched,
                    }) => (
                        <form onSubmit={handleSubmit} style={{ maxWidth: '500px', width: '90%' }}>
                            <div
                                className={`form_field ${errors &&
                                    touched.oldpassword &&
                                    errors.oldpassword &&
                                    errors.oldpassword !== ''
                                    ? 'error'
                                    : ''
                                    }`}>

                                {/* <div style={{ textAlign: 'left' }} className="field-left">
                                    <label>Old Password</label>
                                </div> */}
                                <div className="field-right" style={{ width: '100%' }}>
                                    <Field as={TextField}
                                        error={touched.oldpassword &&
                                            errors.oldpassword &&
                                            errors.oldpassword !== "" ? true : false}
                                        label='Enter Old Password' type="password" placeholder='******' name="oldpassword" className='pwd-field' value={values.oldpassword} maxLength="50" />
                                    {errors &&
                                        touched.oldpassword &&
                                        errors.oldpassword &&
                                        errors.oldpassword !==
                                        '' && (
                                            <span className='error-msg'>
                                                <ErrorMessage
                                                    name='oldpassword'
                                                    render={(err) => { return err; }}
                                                />
                                            </span>
                                        )}
                                </div>
                            </div>
                            <div
                                className={`form_field ${errors &&
                                    touched.newpassword &&
                                    errors.newpassword &&
                                    errors.newpassword !== ''
                                    ? 'error'
                                    : ''
                                    }`}>
                                {/* <div style={{ textAlign: 'left' }} className="field-left">
                                    <label>New Password</label>
                                </div> */}
                                <div className="field-right" style={{ width: '100%' }}>
                                    <Field as={TextField}
                                        error={touched.newpassword &&
                                            errors.newpassword &&
                                            errors.newpassword !== "" ? true : false}
                                        label='Enter New Password' type='password' placeholder='******' name='newpassword' className='pwd-field' value={values.newpassword} maxLength="50" />
                                    {errors &&
                                        touched.newpassword &&
                                        errors.newpassword &&
                                        errors.newpassword !==
                                        '' && (
                                            <span className='error-msg'>
                                                <ErrorMessage
                                                    name='newpassword'
                                                    render={(
                                                        err
                                                    ) => {
                                                        return err;
                                                    }}
                                                />
                                            </span>
                                        )}
                                </div>
                            </div>

                            <div
                                className={`form_field ${errors &&
                                    touched.confirmpassword &&
                                    errors.confirmpassword &&
                                    errors.confirmpassword !== ''
                                    ? 'error'
                                    : ''
                                    }`}>
                                {/* <div style={{ textAlign: 'left' }} className="field-left">
                                    <label>Confirm Password</label>
                                </div> */}
                                <div className="field-right" style={{ width: '100%' }}>
                                    <Field as={TextField}
                                        error={touched.confirmpassword &&
                                            errors.confirmpassword &&
                                            errors.confirmpassword !== "" ? true : false}
                                        label='Enter Confirm Password' type='password' placeholder='******' name='confirmpassword' className='pwd-field' value={values.confirmpassword} maxLength="50" />
                                    {errors &&
                                        touched.confirmpassword &&
                                        errors.confirmpassword &&
                                        errors.confirmpassword !==
                                        '' && (
                                            <span className='error-msg'>
                                                <ErrorMessage
                                                    name='confirmpassword'
                                                    render={(
                                                        err
                                                    ) => {
                                                        return err;
                                                    }}
                                                />
                                            </span>
                                        )}
                                </div>
                            </div>

                            <div className={IsLoading ? "form-field button-field disable-btn" : "form-field button-field"}>
                                <input type="submit" value={IsLoading ? "Changing..." : "Change Password"} name="change-password" />
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
            <Notification
                isError={isError}
                message={message}
                showNotification={showNotification}
                closeNotification={handleClearNotification.bind(this)}
            />
        </div>
    )
}
const validateChangepwdForm = yup.object().shape({
    oldpassword: yup.string().required('Password is required'),
    newpassword: yup.string().required('New Password is required'),
    confirmpassword: yup.string().required('Confirm Password is required'),
});

export default ChangePassword;