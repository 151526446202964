import React from "react";
import Table from "../../../shared/components/table/table";
import TableHead from "../../../shared/components/table/tableHead";
import TableRow from "../../../shared/components/table/tableRow";
import TableBody from "../../../shared/components/table/tableBody";
import TableCell from "../../../shared/components/table/tableCell";
import PerfectScrollbar from "react-perfect-scrollbar";
import deleteIcon from "../../../assets/images/delete.svg";
import editIcon from "../../../assets/images/edit.svg";
import ic_setting from "../../../assets/images/settings.svg";
import ic_password from "../../../assets/images/ic_password.png";
import filtericon from "../../../assets/images/ic_feather_filter.svg";
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";
import { withRouter } from 'react-router-dom';
import ModalComponent from "../../../shared/components/modal/modal";
import ConfirmtionDialog from '../../ConfirmDialog/ConfirmationDialog';
import UserForm from "../component/userForm";
import "react-perfect-scrollbar/dist/css/styles.css";
import ForgotPassword from "../../../features/login/component/forgot-password";
import Notification from '../../../shared/components/notification/notification';
import * as APIController from '../../../api/APIController';

const UserListComponent = (props) => {
	const list = props.list;

	const [Id, setId] = React.useState('');
	const [isModalOpen, setisModalOpen] = React.useState(false);

	const [LoginDialog, setLoginDialog] = React.useState(false);
	const [Loading, setLoading] = React.useState(false);
	const [DeleteId, setDeleteId] = React.useState("");
	const [forgotDialog, setforgotDialog] = React.useState(false);
	const [forgotID, setForgotID] = React.useState("");
	const [UserID, setUserID] = React.useState("");
	const [initialValues, setInitialValues] = React.useState([]);

	const headerList = ['action', "Full Name", "Email Address", "Phone No", "Is Primary User", "Is Active ?"]

	const DeleteUser = () => {
		props.handledelete(DeleteId)
	}

	const OpenPopup = (ID) => {
		wsGetUserByID(ID)
	}
	const handlebind = () => {
		props.handlebind()
	}

	const wsDeleteUser = (Id) => {
		APIController.DeleteUser(Id)
			.then((response) => {
				if (response.error == null) {
					if (response.object.status == 1) {
						handleNotification(response.object.message);
					} else {
						handleNotification(response.object.message, true);
					}
					props.handlebind()
					setLoginDialog(false)
				}
				else if (response.error.message && response.error.message.length > 0) {
					handleNotification(response.error.message, true);
				}
			});
	};

	const wsGetUserByID = (ID) => {
		setLoading(true)
		APIController.GetUserByID(ID)
			.then((response) => {

				if (response.error == null) {
					setInitialValues(response.object.data[0])
					setisModalOpen(true)
				}
				else if (response.error.message && response.error.message.length > 0) {
					handleNotification(response.error.message, true)
				}
				setLoading(false)
			})
	}

	const [message, setMessage] = React.useState("");
	const [showNotification, setShowNotification] = React.useState(false);
	const [isError, setIsError] = React.useState(false);

	const handleNotification = (message, isError) => {
		setMessage(message)
		setShowNotification(true)
		setIsError(isError)

		setTimeout(() => {
			handleClearNotification();
		}, 4000)
	}

	const handleClearNotification = () => {
		setMessage('')
		setShowNotification(false)
		setIsError(false)
	}

	return (
		<>
			<div className='table-main-wrap users-class'>
				<div className="table-link">
					<a href="#!" title="Add User" onClick={(e) => {
						e.preventDefault();
						props.openAddressForm('user')
					}}>+ Add User</a>
				</div>
				<PerfectScrollbar style={{ maxHeight: 600, overflow: 'auto' }} component='div'>

					<Table className='' stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								{headerList.map((obj, index) => {
									return <TableCell className={obj === "Is active ?" ? 'center' : ''} key={index}>{obj}</TableCell>;
								})}
							</TableRow>
							<TableRow style={{ top: 57 }}>
								<TableCell>
									<span>
										<img src={filtericon} alt='delete' />
									</span>
								</TableCell>
								<TableCell>
									<input type='text' name='' placeholder="Full Name" onChange={(e) => props.applyFilter("FullName", e.target.value, "input")} />
								</TableCell>
								<TableCell >
									<input type='text' name='' placeholder="Email Address" onChange={(e) => props.applyFilter("EmailAddress", e.target.value, "input")} />
								</TableCell>
								<TableCell>
									<input type='text' name='' placeholder="Phone No" onChange={(e) => props.applyFilter("PhoneNo", e.target.value, "input")} />
								</TableCell>
								<TableCell>
									<input type='text' name='' placeholder="Is Primary User" onChange={(e) => props.applyFilter("IsPrimaryUser", e.target.value, "input")} />
								</TableCell>
								<TableCell>
									<select
										onChange={(e) =>
											props.applyFilter("IsActiveLABEL", e.target.value, "select")
										}>
										<option value={""}>All</option>
										<option value={"true"}>Yes</option>
										<option value={"false"}>No</option>
									</select>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!!list.length ? list.map((obj, idnex) => {
								return (
									<TableRow key={idnex}>
										<TableCell style={{ minWidth: "100px" }}>
											<span>
												<a style={{ cursor: 'pointer' }} title="">
													<img src={editIcon} onClick={() => OpenPopup(obj.ID)} alt='edit' />{" "}
												</a>
												<a style={{ cursor: 'pointer' }} title="" onClick={() => setDeleteId(obj.ID)} >
													<img src={deleteIcon} alt='delete' onClick={() => setLoginDialog(true)} />{" "}
												</a>
												<a style={{ cursor: 'pointer' }} title="" onClick={() => { setForgotID(obj.EmailAddress); setUserID(0) }}>
													<img src={ic_setting} onClick={() => setforgotDialog(true)} />
												</a>
												<a style={{ cursor: 'pointer' }} title="" onClick={() => { setForgotID(""); setUserID(obj.ID) }}>
													<img src={ic_password} onClick={() => setforgotDialog(true)} />
												</a>
											</span>
										</TableCell>

										<TableCell>{obj.FullName}</TableCell>
										<TableCell>{obj.EmailAddress}</TableCell>
										<TableCell>{obj.PhoneNo}</TableCell>
										<TableCell>{obj.IsPrimaryUser}</TableCell>
										{/* <TableCell>{obj.Role}</TableCell> */}
										<TableCell
											className={`${obj.IsActiveLABEL === "YES" ? "green" : "red"}`}>
											<span>{obj.IsActiveLABEL === "YES" ? "Yes" : "No"}</span>
										</TableCell>
									</TableRow>
								);
							}) : (
								<TableRow>
									<TableCell colSpan={headerList.length}>
										<div className="not_found">
											{props.progress ?
												<CircularProgress /> :
												<label>Not Currently Available</label>}
										</div>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>

				</PerfectScrollbar>

				{isModalOpen &&
					<ModalComponent
						show={isModalOpen}
						handleClose={() => setisModalOpen(false)}>
						<UserForm
							// UserID={Id}
							PracticeID={props.PracticeID}
							Title={'Edit User'}
							initialValues={initialValues}
							closeModal={() => setisModalOpen(false)}
							handlebind={handlebind.bind(this)}
						/>
					</ModalComponent>
				}
				{LoginDialog && (
					<ModalComponent
						show={LoginDialog}
						handleClose={() => setLoginDialog(false)}>
						<ConfirmtionDialog
							title={"Delete User"}
							description={"Are you sure you want to delete this User?"}
							deletePress={() => wsDeleteUser(DeleteId)}
							closeModal={() => setLoginDialog(false)}
						/>
					</ModalComponent>
				)}
				{forgotDialog && (
					<ModalComponent show={forgotDialog}>
						<ForgotPassword {...props} closeModal={() => setforgotDialog(false)} forgotEmailID={forgotID} UserID={UserID} />
					</ModalComponent>
				)}
			</div>
			<Notification
				isError={isError}
				message={message}
				showNotification={showNotification}
				closeNotification={handleClearNotification.bind(this)}
			/>
		</>
	);
};

export default withRouter(UserListComponent);
