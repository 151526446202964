import React from 'react';
import { ExcelRenderer } from 'react-excel-renderer';

import * as APIController from '../../../../src/api/APIController';

import PageTitle from "../../../config/pageTitle";
import TextField from "../../../shared/components/TextField/TextField";
import authService from "../../../shared/services/auth-service";
import Notification from '../../../shared/components/notification/notification';
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";

class CalendarAccount extends React.Component {
    constructor() {
        super();
        this.state = {
            practiceEmailAddressFileName: '',
            singleEmailAddress: '',
            practiceEmailAddress: 0,
            profilePhoto: '',
            photowidth: '',
            photoheight: '',

            message: '',
            showNotification: false,
            isError: false,

            isImgLoading: false,
            singleEmailLoading: false,
            isPricingListLoading: false,
            isPricingLoading: false,
            touched: false,
            touchedPricing: false,
            practiceID: '',

            practicePricingData: [],
            practiceName: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            postcode: '',
            phoneNo: ''
        }
    }

    componentDidMount() {
        this.wsGetMyProfile()
    }

    wsGetMyProfile = () => {
        APIController.GetMyProfile()
            .then((response) => {
                if (response.error == null) {
                    if (response.object.data != '') {
                        this.setState({
                            profilePhoto: response.object.data[0].DoctorPhotoURL,
                            practiceID: response.object.data[0].PracticeID,
                        })
                        this.handleUploadLogoSize(response.object.data[0].DoctorPhotoURL)
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.handleNotification(response.error.message, true)
                }
                this.wsGetSupplyToolPracticePricingForDropdown()
            })
    }

    wsGetSupplyToolPracticePricingForDropdown = () => {
        this.setState({ isPricingListLoading: true })
        APIController.GetSupplyToolPracticePricingForDropdown()
            .then((response) => {
                if (response.error == null && response.object.data != '') {
                    this.setState({
                        practicePricingData: response.object.data.PricePerBox,
                        practiceName: response.object.data.PracticeDetails[0].CC_PracticeName,
                        address1: response.object.data.PracticeDetails[0].CC_Address1,
                        address2: response.object.data.PracticeDetails[0].CC_Address2,
                        city: response.object.data.PracticeDetails[0].CC_City,
                        state: response.object.data.PracticeDetails[0].CC_State,
                        postcode: response.object.data.PracticeDetails[0].CC_Postcode,
                        phoneNo: this.normalizeInput(response.object.data.PracticeDetails[0].CC_PhoneNo) == undefined ? '' : this.normalizeInput(response.object.data.PracticeDetails[0].CC_PhoneNo)
                    })
                } else if (response.error.message && response.error.message.length > 0) {
                    this.handleNotification(response.error.message, true)
                }
                this.setState({ isPricingListLoading: false })
            })
    }

    formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            var intlCode = (match[1] ? '' : '');
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return null;
    }

    normalizeInput = (value, previousValue) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;
        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 4) return currentValue;
            if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
    };

    handleUploadLogoSize = (image) => {
        let img = new Image()
        img.src = image
        img.onload = () => { this.setState({ photoheight: img.height, photowidth: img.width }) }
    }

    fileHandler = (event) => {
        let fileObj = event.target.files[0];
        // setFileName(fileObj.name)
        this.setState({ practiceEmailAddressFileName: fileObj.name })
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
            var obj = {};
            var mainobj = []

            for (var i = 1; i < resp.rows.length; i++) {
                for (var j = 0; j < resp.cols.length; j++) {
                    obj[resp.rows[0][j]] = resp.rows[i][j];
                }
                mainobj.push({ EmailAddress: obj.email });
                obj = {};
            }
            this.setState({ practiceEmailAddress: resp.rows.length })
            this.wsImportVTMContactList(mainobj)
        });
    }

    emailHandler(email) {
        this.setState({ touched: true })
        let id = undefined;
        if (email.trim() == '') {
            id = 'singleEmail'
        } else if (!email.trim().match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            id = 'singleEmail'
            this.handleNotification('Please Enter Valid Email Address', true)
        } else {
            this.setState({ singleEmailLoading: true })
            let mainobj = [];
            mainobj.push({ EmailAddress: email });
            this.wsImportVTMContactList(mainobj)
        }
        var element = document.getElementById(id);
        id != undefined && id != null && element.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });
    }

    wsImportVTMContactList = (EmailAddresses) => {
        APIController.ImportVTMContactList(EmailAddresses)
            .then((response) => {
                if (response.status != "0") {
                    this.setState({ touched: false, singleEmailAddress: '', singleEmailLoading: false })
                } else if (response.object.message && response.object.message.length > 0) {
                    this.handleNotification(response.object.message, true);
                }
            })
    }

    handleNotification = (message, isError) => {
        this.setState({ message: message, showNotification: true, isError: isError })
        setTimeout(() => { this.handleClearNotification(); }, 4000)
    }

    handleClearNotification = () => {
        this.setState({ message: '', showNotification: false, isError: false })
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }




    wsUploadPracticeLogo = (practiceId, name, value, isPracticeLogo) => {
        this.setState({ isImgLoading: true })
        APIController.UploadPracticeLogo(practiceId, name, value, isPracticeLogo)
            .then((response) => {
                if (response.object.status == 1) {
                    this.handleNotification(response.object.message);
                } else {
                    this.handleNotification(response.object.message, true);
                }
                this.setState({ isImgLoading: false })
            });
    }
    wsDeleteDoctorLogo = (practiceId) => {
        this.setState({ isImgLoading: true })
        APIController.DeleteDoctorLogo(practiceId)
            .then((response) => {
                if (response.object.status == 1) {
                    this.handleNotification(response.object.message);
                    this.setState({ profilePhoto: '', photowidth: '', photoheight: '' })
                } else {
                    this.handleNotification(response.object.message, true);
                }
                this.setState({ isImgLoading: false })
            });
    }



    handleValidatePricing(practicePricingData, practiceName, address1, address2, city, state, postcode, phoneNo) {
        this.setState({ touchedPricing: true })
        let id = undefined;
        if (this.state.practiceName == '') {
            id = 'practiceName'
        } else if (this.state.address1 == '') {
            id = 'address1'
        } else if (this.state.phoneNo == '' || this.state.phoneNo == undefined) {
            id = 'phoneNo'
        } else if (this.state.phoneNo.length < 14) {
            this.handleNotification("Please enter a valid 10- digit phone number.", true);
            id = 'phoneNo'
        } else {
            this.wsAddEditSupplyToolPracticePricing(practicePricingData, practiceName, address1, address2, city, state, postcode, phoneNo)
        }
        var element = document.getElementById(id);
        id != undefined && id != null && element.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });
    }

    wsAddEditSupplyToolPracticePricing = (practicePricingData, practiceName, address1, address2, city, state, postcode, phoneNo) => {
        this.setState({ isPricingLoading: true })
        let priceValue = []
        practicePricingData.map((item) =>
            priceValue.push({ "LenseTypeID": item.ID, "PricePerBox": item.PricePerBox == "" && item.PricePerBox == undefined && item.PricePerBox == null ? 0 : item.PricePerBox }))

        APIController.AddEditSupplyToolPracticePricing("0", priceValue, practiceName, address1, address2, city, state, postcode, phoneNo)
            .then((response) => {
                if (response.object.status == 1) {
                    this.handleNotification(response.object.message);
                    this.wsGetSupplyToolPracticePricingForDropdown()
                } else {
                    this.handleNotification(response.object.message, true);
                }
                this.setState({ isPricingLoading: false })
            });
    }

    setValueInD(value) {
        return value.length <= 1 ? value.length == 1 && value == "$" ? value : "$" + value : value;
    }

    handlePracticePricingList = (ID, Value) => {
        this.setState({ practicePricingData: this.state.practicePricingData.map((item, index) => item.ID !== ID ? item : { ...item, PricePerBox: Value.replace(/[^0-9.-]+/g, "") }) })
    };

    render() {
        return (
            <section className='content-right-main'>
                <PageTitle Title={"Annual Supply Calculator Profile"} />
                <div className='business-info-form' style={{ marginTop: '20px' }}>
                    <div className='business-info-wrap'>
                        <div className='info-col-left'>
                            <div className='white-box'>
                                <div className='box-head'>
                                    <h4>Annual Supply Calculator Profile</h4>
                                </div>
                                {this.state.isPricingListLoading ? <CircularProgress /> :
                                    <form>
                                        <div className='box-body'>
                                            <p>Please enter your practice's standard pricing for each Bausch + Lomb lens brand and indication below, and click the CONFIRM button.</p>
                                            <p style={{ marginBottom: '7%' }}>This pricing will be saved and auto-populated in the Annual Supply Cost Calculator located on the top navigation menu.  You may return to this provide to update pricing at any time. </p>
                                            {this.state.practicePricingData.length ? this.state.practicePricingData.map((obj, index) =>
                                                <div id={index} style={{ position: 'relative' }} className={`field-field-row`}>
                                                    <TextField label={obj.Name} type='text' placeholder={obj.Name} name='infuseCost' error={false} maxLength="5"
                                                        value={`${"$"}${obj.PricePerBox}`}
                                                        onBlur={() => { this.handlePracticePricingList(obj.ID, obj.PricePerBox == "" ? "$0" : obj.PricePerBox) }}
                                                        onClick={() => obj.PricePerBox == 0 ? this.handlePracticePricingList(obj.ID, "") : this.handlePracticePricingList(obj.ID, "$" + obj.PricePerBox)}
                                                        onChange={(e) => { this.handlePracticePricingList(obj.ID, e.target.value) }} />
                                                </div>
                                            ) : <></>}
                                        </div>
                                    </form>}
                            </div>
                        </div>
                        <div className='info-col-right'>
                            {/* {AppConstants.GetLocalStorage("localStorageRoleID") == AppConstants.VTMRoleID ?
                                <div className='white-box'>
                                    <div className='box-head'>
                                        <h4>Profile Photo</h4>
                                    </div>
                                    <div className='box-body'>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <AspectRatio ratio="3/4" style={{ maxWidth: '400px' }}>
                                                {this.state.isImgLoading ? <div style={{ display: 'flex', alignItems: 'center', height: '200px', borderRadius: '50%' }}><CircularProgress /> </div> :
                                                    this.state.profilePhoto != '' && this.state.profilePhoto != undefined && this.state.profilePhoto != null
                                                        ? <img style={{ maxHeight: '200px', borderRadius: '50%' }} src={this.state.profilePhoto} alt='profile' />
                                                        : <img style={{ height: '150px', borderRadius: '50%' }} src={profile_generic} alt='profile' />}
                                            </AspectRatio>
                                        </div>
                                        <div>
                                            <p style={{ textAlign: 'center', margin: '0 10px', fontSize: '14px', color: '#d8dbe0' }}>Accepted files: PNG & JPG<br />
                                                Recommended photo size: 500 pixels X 500 pixels</p>
                                            {this.state.profilePhoto != '' && this.state.profilePhoto != null &&
                                                <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                                                    <img className="logo-icon-location" src={this.state.photowidth > 500 && this.state.photoheight > 500 ? checkmark : fail} alt="icon" style={{ marginBottom: '0' }} />
                                                    <p>Your uploaded profile photo is {this.state.photowidth > 500 && this.state.photoheight > 500 ? "" : "NOT"} optimized for web</p>
                                                </div>}
                                        </div>

                                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
                                            <div className={this.state.isImgLoading ? 'button-field file-upload-btn disable-btn' : 'button-field file-upload-btn'}>
                                                <input type='file' id='upload-btn' name='files'
                                                    onChange={(e) => {
                                                        const file = e.target.files[0];
                                                        if (file && (file.type.includes("png") || file.type.includes("jpeg") || file.type.includes("jpg"))) {
                                                            let file1 = e.target.files[0]
                                                            file1['preview'] = URL.createObjectURL(e.target.files[0]);

                                                            let img = new Image()
                                                            img.src = window.URL.createObjectURL(file)
                                                            img.onload = () => {
                                                                this.setState({
                                                                    profilePhoto: window.URL.createObjectURL(new Blob([file], { type: file.type })),
                                                                    photoheight: img.height,
                                                                    photowidth: img.width
                                                                })

                                                                const image2base64 = require('image-to-base64');
                                                                image2base64(file1.preview).then((response) => {
                                                                    this.wsUploadPracticeLogo(this.state.practiceID, uuid() + "." + file.type.split('/')[1], response, false)
                                                                }).catch((error) => { })
                                                                return true;
                                                            }
                                                        }
                                                    }}
                                                />
                                                <label htmlFor='upload-btn'>{AppConstants.ButtonText.Upload}</label>
                                            </div>
                                            <div className={this.state.isImgLoading ? 'button-field back-btn disable-btn' : 'button-field back-btn'}>
                                                <input type='button' name='button' value="Delete" onClick={() => {
                                                    this.wsDeleteDoctorLogo(this.state.practiceID)
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : */}

                            {this.state.isPricingListLoading ? <CircularProgress /> :
                                <form>
                                    <div className='white-box'>
                                        <div className='box-head'>
                                            <h4>Location Details</h4>
                                        </div>
                                        <div className='box-body'>
                                            <div id={"practiceName"} className={`field-field-row ${this.state.practiceName == '' && this.state.touchedPricing ? 'error' : ''}`}>
                                                <TextField label='Practice Name' type='text' placeholder='Practice Name' name='practiceName' value={this.state.practiceName} maxLength="100"
                                                    onChange={(e) => { this.setState({ practiceName: e.target.value }) }}
                                                    error={this.state.practiceName == '' && this.state.touchedPricing ? true : false} />
                                            </div>
                                            <div id={"address1"} className={`field-field-row ${this.state.address1 == '' && this.state.touchedPricing ? 'error' : ''}`}>
                                                <TextField label='Address 1' type='text' placeholder='Address 1' name='address1' value={this.state.address1} maxLength="100"
                                                    onChange={(e) => { this.setState({ address1: e.target.value }) }}
                                                    error={this.state.address1 == '' && this.state.touchedPricing ? true : false} />
                                            </div>
                                            <div id={"address2"} className={`field-field-row`}>
                                                <TextField label='Address 2' type='text' placeholder='Address 2' name='address2' value={this.state.address2} maxLength="100" onChange={(e) => { this.setState({ address2: e.target.value }) }} />
                                            </div>
                                            <div id={"city"} className={`field-field-row`}>
                                                <TextField label='city' type='text' placeholder='city' name='city' value={this.state.city} maxLength="50" onChange={(e) => { this.setState({ city: e.target.value }) }} />
                                            </div>
                                            <div id={"state"} className={`field-field-row`}>
                                                <TextField label='state' type='text' placeholder='state' name='state' value={this.state.state} maxLength="50" onChange={(e) => { this.setState({ state: e.target.value }) }} />
                                            </div>
                                            <div id={"postcode"} className={`field-field-row`}>
                                                <TextField label='postcode' type='text' placeholder='postcode' name='postcode' value={this.state.postcode} maxLength="5" onChange={(e) => { this.setState({ postcode: e.target.value }) }} />
                                            </div>
                                            <div id={"phoneNo"} className={`field-field-row ${(this.state.phoneNo == '' || this.state.phoneNo.length < 14) && this.state.touchedPricing ? 'error' : ''}`}>
                                                <TextField label='Phone number' type='text' placeholder='Phone No' name='phoneNo' value={this.state.phoneNo} maxLength="14"
                                                    onChange={(e) => { this.setState({ phoneNo: this.normalizeInput(e.target.value) }) }}
                                                    error={this.state.phoneNo == '' && this.state.touchedPricing ? true : false} />
                                            </div>


                                        </div>
                                    </div>
                                </form>}
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className={this.state.isPricingLoading ? `button-field disable-btn` : `button-field`}>
                            <input type='button' name='submit' value="Confirm" onClick={() => { this.handleValidatePricing(this.state.practicePricingData, this.state.practiceName, this.state.address1, this.state.address2, this.state.city, this.state.state, this.state.postcode, this.state.phoneNo); }} />
                        </div>
                    </div>

                    {/* {AppConstants.GetLocalStorage("localStorageRoleID") == AppConstants.VTMRoleID &&
                        <div className='white-box' style={{ marginTop: '20px' }}>
                            <div className='box-head'>
                                <h4>Practice Contact List</h4>
                            </div>
                            <div className='box-body' style={{ display: 'flex' }}>
                                <div className='info-col-left'>
                                    <p style={{ fontWeight: 600 }}>Please upload your practice email address list below. This list will be used to streamline the process of populating the Doctor Finder on the new Bausch + Lomb Multi Brand Website. </p>
                                    <a style={{ fontWeight: 600, color: '#2d89d8', marginTop: '5px' }} href='/VTM Contact List.csv' download>Download a sample CSV file HERE.</a>

                                    <div id={'emailList'} className={`field-full`} style={{ margin: '15px 0' }}>
                                        <TextField disabled error={false} label='Practice Email Address List' type='text' placeholder='Practice Email Address List' name='emailList' value={this.state.practiceEmailAddressFileName} />
                                    </div>

                                    <div style={{ display: 'flex', width: '100%' }}>
                                        <div className={this.state.practiceEmailAddress != 0 ? "button-field color-disable" : "button-field file-upload-btn"} style={{ textAlign: 'center', minWidth: '155px' }}>
                                            <input type="file" id='fileupload' name='files' accept=".csv" onChange={this.fileHandler.bind(this)} />
                                            <label>Upload</label>
                                        </div>
                                        <div className={this.state.practiceEmailAddress == 0 ? "button-field back-btn disable-btn" : "button-field back-btn"}>
                                            <input type='button' name='button' value="Delete" onClick={() => { this.setState({ practiceEmailAddressFileName: "", practiceEmailAddress: 0 }) }} />
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #d8dbe0', borderBottom: '1px solid #d8dbe0', padding: '15px 0', marginTop: '15px' }}>
                                        <label># of Practice Email Addresses</label>
                                        <label>{this.state.practiceEmailAddress}</label>
                                    </div>
                                </div>

                                <div className='info-col-right'>
                                    <p style={{ fontWeight: 600 }}>If you have already uploaded your practice email address list and want to add a single location, please load it below.</p>

                                    <div id={'singleEmail'} className={`field-full`} style={{ margin: '10.75% 0 0' }}>
                                        <TextField label='Single Practice Email Address' type='text' placeholder='Single Practice Email Address' name='singleEmailAddress' error={this.state.singleEmailAddress == '' && this.state.touched ? true : false}
                                            value={this.state.singleEmailAddress} maxLength="100" onChange={this.handleChange} />
                                    </div>

                                    <div className={this.state.singleEmailLoading ? "disable-btn button-field" : "button-field"} style={{ textAlign: 'center', minWidth: '155px', marginTop: '15px' }}>
                                        <input type='button' name='upload' value="Upload" onClick={() => { this.emailHandler(this.state.singleEmailAddress) }} />
                                    </div>
                                </div>
                            </div>
                        </div>} */}
                </div>

                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    closeNotification={this.handleClearNotification.bind(this)}
                />
            </section>
        )
    }
}
export default CalendarAccount;