import React from "react";
import * as AppConstants from "../../../../config/AppConstant";
import * as APIController from "../../../../api/APIController.js";
import ModalComponent from "../../../../shared/components/modal/modal";
import Notification from '../../../../shared/components/notification/notification';
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";
import EMail from '../../../campaign/components/eMail';

import save_instagram from "../../../../assets/images/icon/saveinstagram.png";
import save_instagram_color from "../../../../assets/images/icon/saveinstagramcolor.png";
import facebook_color_icon from "../../../../assets/images/icon/facebookcoloricon.png";
import instagram_color_icon from "../../../../assets/images/icon/instagramcoloricon.png";
import email_icon from "../../../../assets/images/icon/email.png";
import close_icon from "../../../../assets/images/close_icon.svg";

import moment from 'moment';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DateRangePicker from 'react-daterange-picker'

class CustomPostAdminFeed extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            showNotification: false,
            isError: false,
            progress: false,
            listData: [],
            openDialog: false,
            previewModalData: null,
            FilterByScheduledAt: [{ ID: '', Name: 'All' }, { ID: '1', Name: 'Is Flagged' }, { ID: '0', Name: 'Is Not Flagged' }],
            FilterStartDate: moment(new Date().setMonth(new Date().getMonth() - 6)).format("MM/DD/YYYY"),
            FilterEndDate: moment(new Date()).add(1, 'M').format("MM/DD/YYYY"),
            FilterIsFlagged: "",
            FilterSortDirection: "DESC",
            FilterPracticeName: "",
            openDateRangePicker: false,
            openEmailModal: false,
            AutomationSubscriptionID: '',
            AutomationSubscriptionDetails: ''
        }
    }

    componentDidMount = () => {
        this.wsGetCustomPostAdminFeed(this.state.FilterStartDate, this.state.FilterEndDate, this.state.FilterIsFlagged, this.state.FilterSortDirection, this.state.FilterPracticeName);
    }

    wsGetCustomPostAdminFeed(StartDate, EndDate, IsFlagged, SortDirection, PracticeName) {
        this.setState({ progress: true, });
        APIController.GetCustomPostAdminFeed(StartDate, EndDate, IsFlagged, SortDirection, PracticeName).then((response) => {
            if (response.error == null) {
                this.setState({ listData: response.object.data });
            } else if (response.error.message && response.error.message.length > 0) {
                this.handleNotification(response.error.message, true);
            } else { this.handleNotification(response.object.message, true); }
            this.setState({ progress: false });
        });
    }

    wsFlagCustomPost(ID, IsFlagged) {
        this.setState({ progress: true });
        APIController.FlagCustomPost(ID, IsFlagged).then((response) => {
            if (response.error == null) {
                this.handleNotification(response.object.message);
                this.wsGetCustomPostAdminFeed(this.state.FilterStartDate, this.state.FilterEndDate, this.state.FilterIsFlagged, this.state.FilterSortDirection, this.state.FilterPracticeName);
            } else if (response.error.message && response.error.message.length > 0) {
                this.handleNotification(response.error.message, true);
            } else {
                this.handleNotification(response.object.message, true);
            }
            this.setState({ progress: false });
        });
    }

    onSelectDateRange = dates => {
        this.setState({ FilterStartDate: moment(dates.start._d).format("MM/DD/YYYY"), FilterEndDate: moment(dates.end._d).format("MM/DD/YYYY"), openDateRangePicker: false })
    }

    handleNotification = (message, isError) => {
        this.setState({ message: message, showNotification: true, isError: isError });
        setTimeout(() => { this.handleClearNotification(); }, 4000);
    };
    handleClearNotification = () => { this.setState({ message: "", showNotification: false, isError: false, }); };


    render() {
        const { listData, progress, previewModalData, FilterStartDate, FilterEndDate, openDateRangePicker, FilterByScheduledAt, openEmailModal } = this.state;
        return (
            <section className='content-right-main'>
                <div className="content-right-heading">
                    {AppConstants.ContactDetais.CustomPostPageTitle}
                </div>
                <div className="white-box">
                    <div style={{ display: 'flex', justifySelf: 'flex-start', alignItems: "center", paddingTop: '20px' }}>
                        <div style={{ display: "grid", marginLeft: '10px' }}>
                            <label>Date: </label>
                            <div style={{ borderRadius: '3px', border: '1px solid #CCCCCC', padding: '6px', fontSize: '15px', display: 'flex', alignItems: 'center', position: 'relative' }} >
                                <label>{FilterStartDate + " to " + FilterEndDate}</label>  <DateRangeIcon style={{ color: '#CCCCCC', cursor: 'pointer' }} onClick={() => this.setState({ openDateRangePicker: !this.state.openDateRangePicker })} />
                                {openDateRangePicker &&
                                    <div style={{ border: '1px solid #cccc', zIndex: 1000, position: 'absolute', top: 50, backgroundColor: '#fff', right: 0 }}>
                                        <DateRangePicker selectionType='range' onSelect={this.onSelectDateRange} />
                                    </div>}
                            </div>
                        </div>

                        <div style={{ display: "grid", marginLeft: '10px' }}>
                            <label>Flagged: </label>
                            <select className={'title-content'} style={{ borderRadius: '3px', border: '1px solid #CCCCCC', padding: '8px', fontSize: '13px', width: '200px' }}
                                name='ScheduleAtID'
                                onChange={(e) => {
                                    this.setState({ FilterIsFlagged: e.target.value });
                                    this.wsGetCustomPostAdminFeed(this.state.FilterStartDate, this.state.FilterEndDate, e.target.value, this.state.FilterSortDirection, this.state.FilterPracticeName);
                                }}>
                                {FilterByScheduledAt != null && FilterByScheduledAt.map((item, index) => {
                                    return <option key={index} value={item.ID}>{item.Name}</option>
                                })}
                            </select>
                        </div>

                        <div style={{ display: "grid", marginLeft: '10px' }}>
                            <label>Practice: </label>
                            <input type="text" name="PracticeName" placeholder="Enter Practice Name" value={this.state.FilterPracticeName} onChange={(e) => this.setState({ FilterPracticeName: e.target.value })}
                                style={{ borderRadius: '3px', border: '1px solid #CCCCCC', padding: '8px', fontSize: '13px', width: '250px' }} />
                        </div>
                        <div style={{ alignSelf: 'end', marginLeft: '10px' }}>
                            <div className="button-field">
                                <input type="button" value="Search" name="search" onClick={() => this.wsGetCustomPostAdminFeed(this.state.FilterStartDate, this.state.FilterEndDate, this.state.FilterIsFlagged, this.state.FilterSortDirection, this.state.FilterPracticeName)} />
                            </div>
                        </div>
                    </div>

                    <div className='two-col-sec' style={{ boxShadow: 'none', marginTop: '5px' }}>
                        {progress ? <CircularProgress />
                            : listData.length ? listData.map((obj, index) =>
                                <div className="content-box-feed" key={index} >
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ width: '70%' }}>
                                            <label className="box-label-feed">{obj.PracticeName}</label>
                                            <p style={{ fontSize: "10px" }}>Post Date: {obj.ScheduledAt.split(" ")[0]}</p>
                                        </div>
                                        <div style={{ width: '30%', textAlign: 'end' }}>
                                            {obj.PostToFacebook == 1 && <img src={facebook_color_icon} alt="fb-image" style={{ marginRight: "2px" }} />}
                                            {obj.PostToInstagram == 1 && <img src={instagram_color_icon} alt="in-image" style={{ marginLeft: "2px" }} />}
                                        </div>
                                    </div>
                                    <div style={{ cursor: 'pointer' }} onClick={() => this.setState({ previewModalData: { openDialog: true, imageURL: obj.ImageURL, description: obj.Description, negativeKeywords: obj.NegativeKeywords } })}>
                                        <div className="box-feed-image"
                                            style={{ backgroundImage: `url(${obj.ImageURL + "?" + Math.floor(Math.random() * (999999 - 100000 + 1) + 100000)} )`, margin: '5px' }}>
                                        </div>
                                        <div className="box-feed-desc" dangerouslySetInnerHTML={{ __html: obj.Description }} />
                                    </div>
                                    <div className="flagged-icons-main" >
                                        <img src={email_icon} alt="email-image" style={{ marginRight: '5px', cursor: 'pointer' }}
                                            onClick={() => this.setState({ AutomationSubscriptionID: obj.AutomationSubscriptionID, AutomationSubscriptionDetails: obj, openEmailModal: true })} />
                                        {obj.IsFlagged
                                            ? <img src={save_instagram_color} alt="inst-color-image" style={{ cursor: 'pointer' }} onClick={() => this.wsFlagCustomPost(obj.ID, 0)} />
                                            : <img src={save_instagram} alt="inst-image" style={{ cursor: 'pointer' }} onClick={() => this.wsFlagCustomPost(obj.ID, 1)} />}
                                    </div>
                                </div>)
                                : <label>{AppConstants.ContactDetais.NotCurrentlyAvailable}</label>}
                    </div>
                </div>
                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    clearNotification={this.handleClearNotification}
                    closeNotification={this.handleClearNotification}
                />

                {previewModalData
                    && <ModalComponent
                        show={previewModalData.openDialog}
                        handleClose={() => this.setState({ previewModalData: null })}>
                        <div className='popup-wrap'>
                            <div style={{ display: 'contents', padding: '10px 0px', marginTop: '0px', boxShadow: 'none' }}>
                                <div className="sec-left" style={{ marginTop: '0px', letterSpacing: '0px', display: 'grid' }} >
                                    <div style={{ display: 'grid', alignContent: 'space-between' }}>
                                        <div dangerouslySetInnerHTML={{ __html: previewModalData.description }} />
                                        {previewModalData.negativeKeywords != null && previewModalData.negativeKeywords != "" && previewModalData.negativeKeywords != undefined &&
                                            <div><strong>Negative Keywords : </strong> {previewModalData.negativeKeywords}</div>}
                                    </div>
                                </div>
                                <div className="sec-right" style={{ padding: '0px', marginBottom: '10px' }}>
                                    <img src={previewModalData.imageURL} alt="preview-image" />
                                </div>
                            </div>
                            <img src={close_icon} alt='icon' className='gray-close-ic' onClick={() => this.setState({ previewModalData: null })} style={{ cursor: 'pointer' }} />
                        </div>
                    </ModalComponent>}

                {openEmailModal
                    && <ModalComponent
                        show={openEmailModal}
                        handleClose={() => this.setState({ openEmailModal: false })}>
                        <div className='popup-wrap'>
                            <EMail AutomationSubscriptionID={this.state.AutomationSubscriptionID} AutomationSubscriptionDetails={this.state.AutomationSubscriptionDetails} handleCloseModel={() => this.setState({ openEmailModal: false })} />
                            <img src={close_icon} alt='icon' className='gray-close-ic' onClick={() => this.setState({ openEmailModal: false })} style={{ cursor: 'pointer' }} />
                        </div>
                    </ModalComponent>}
            </section>
        )
    }
}

export default CustomPostAdminFeed;