import React from 'react'
import * as APIController from "../../../api/APIController.js";
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";

class BiotrueController extends React.Component {

    state = {
        Description: '',
        Name: '',
        ImageURL: '',
        IsLoading: true,
        BrandId: '',
        musicList: []
    };
    componentWillMount() {
        this.wsGetBrandByID(localStorage.getItem('localStorageBrandID'));
    }

    async componentWillReceiveProps() {
        await this.setState({ BrandId: localStorage.getItem('localStorageBrandID') })
        this.wsGetBrandByID(this.state.BrandId);
    }

    wsGetBrandByID(ID) {
        APIController.GetBrandByID(ID).then((response) => {
            if (response.error == null) {
                this.setState({
                    IsLoading: false,
                    Description: response.object.data[0].Description,
                    Name: response.object.data[0].Name,
                    ImageURL: response.object.data[0].ImageURL,
                });

            } else if (response.error.message && response.error.message.length > 0) {
                // this.handleNotification(response.error.message, true);
            }
        });
    }


    render() {
        let title1 = this.state.Name.split('®')[0]
        let title2 = this.state.Name.match(/®/g) ? <sup>&reg;</sup> : ''
        let title3 = this.state.Name.split('®')[1]
        return (
            <>
                <section className="content-right-main">
                    <div className="content-right-heading">{this.state.Name.split('®')[0]}{this.state.Name.match(/®/g) ? <sup>&reg;</sup> : ''}{this.state.Name.split('®')[1]}</div>
                    {/* <PageTitle Title={"title1" + this.state.Name.match(/®/g) ? <sup>&reg;</sup> : '' + "title3"} /> */}
                    {this.state.IsLoading ?
                        <CircularProgress /> :
                        <div className="two-col-sec digital-welcome" style={{ paddingBottom: '0px' }}>
                            <div className="sec-left" style={{ marginTop: '0px' }} >
                                <div style={{ letterSpacing: '0px' }}>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.Description }} />
                                </div>
                            </div>
                            <div className="sec-right" style={{ padding: '0px', marginBottom: '10px' }}>
                                <img src={this.state.ImageURL + "?" + Math.floor(Math.random() * (999999 - 100000 + 1) + 100000)} alt="" />
                            </div>
                        </div>
                    }
                </section>
            </>
        )
    }
}


export default BiotrueController