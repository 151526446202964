
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'
import * as APIController from '../../../api/APIController.js';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import close_ic from '../../../assets/images/close-ic.svg';
import Notification from '../../../shared/components/notification/notification';
import forgot_user_ic from '../../../assets/images/forgot-username-ic.svg';

const ConfigureReportForm = props => {
    let initialValues = { OrderDetailID: '', ReportTypeID: '', dsUser: '', dsAccount: '', CampaignID: '', CampaignID1: '', CampaignID2: '' }
    const [IsLoading, setIsLoading] = React.useState(true);
    const [DataForDropDown, setDataForDropDown] = React.useState([]);

    React.useEffect(() => {
        // document.getElementById('Name').focus();
        if (props.EditData != '') {
            initialValues.OrderDetailID = props.EditData.OrderDetailsID
            initialValues.ReportTypeID = props.EditData.ReportTypeID
            initialValues.dsUser = props.EditData.dsUser
            initialValues.dsAccount = props.EditData.dsAccounts
            initialValues.CampaignID = props.EditData.CampaignID
            initialValues.CampaignID1 = props.EditData.CampaignID_1
            initialValues.CampaignID2 = props.EditData.CampaignID_2
        }
        wsGetOrderListForDashboard()
    }, [])



    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    const wsGetOrderListForDashboard = () => {
        APIController.GetOrderListForDashboard(props.OrderId, '1').then((response) => {
            setIsLoading(false)
            setDataForDropDown(response.object.data)
        });
    }
    return (
        <>
            <div className="popup-box forgot-popup-box" style={{ width: '10%' }}>
                <div className="box-heading">
                    {/* <img src={forgot_user_ic} alt="icon" /> */}
                    <h2 className="h1">{props.title}</h2>
                    <button className="close-btn"><img src={close_ic} alt="icon" onClick={props.closeModal} /></button>
                </div>


                <Formik
                    enableReinitialize={false}
                    initialValues={initialValues}
                    validationSchema={validateCountryForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        props.SavePress(values)
                    }} >
                    {({
                        values,
                        handleSubmit,
                        setFieldValue,
                        errors,
                        touched,
                    }) => {
                        return (
                            <div className="form-main">
                                <form onSubmit={handleSubmit}>
                                    <div className={`form_field ${errors &&
                                        touched.OrderDetailID &&
                                        errors.OrderDetailID &&
                                        errors.OrderDetailID !== '' ? 'error' : ''
                                        }`}>
                                        <div className="field-left">
                                            <label>Campaign </label>
                                        </div>
                                        <div className="field-right">
                                            {IsLoading ? <h2>loading....</h2> :
                                                <select
                                                    name='OrderDetailID'
                                                    value={values.OrderDetailID}
                                                    onChange={(e, index) => {
                                                        setFieldValue('OrderDetailID', e.target.value);
                                                    }}>
                                                    <option value={''}>{'Select Campaign'}</option>
                                                    {
                                                        DataForDropDown != null && DataForDropDown.map((obj, index) => {
                                                            return <option key={index} selected={obj.OrderDetailsID == values.OrderDetailsID ? obj.OrderDetailsID : obj.Name} value={obj.OrderDetailsID}>{obj.Name}</option>
                                                        })
                                                    }
                                                </select>}

                                            {errors &&
                                                touched.OrderDetailID &&
                                                errors.OrderDetailID &&
                                                errors.OrderDetailID !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='OrderDetailID'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div className={`form_field ${errors &&
                                        touched.ReportTypeID &&
                                        errors.ReportTypeID &&
                                        errors.ReportTypeID !== '' ? 'error' : ''
                                        }`}>
                                        <div className="field-left">
                                            <label>ReportType</label>
                                        </div>
                                        <div className="field-right">
                                            <select

                                                disabled={props.EditData != '' ? true : false}
                                                name='ReportTypeID'
                                                value={values.ReportTypeID}
                                                onChange={(e, index) => {
                                                    // wsGetStateForDropdown(e.target.value)
                                                    setFieldValue('ReportTypeID', e.target.value);
                                                }}>
                                                <option value=''>Select Report Type</option>
                                                <option value='Facebook'>Facebook</option>
                                                <option value='MailChimp'>MailChimp</option>
                                                <option value='Display'>Display</option>
                                                <option value='Search'>Search</option>
                                            </select>

                                            {errors &&
                                                touched.ReportTypeID &&
                                                errors.ReportTypeID &&
                                                errors.ReportTypeID !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='ReportTypeID'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>

                                    <div
                                        className={`form_field ${errors &&
                                            touched.dsUser &&
                                            errors.dsUser &&
                                            errors.dsUser !== '' ? 'error' : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>dsUser</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter dsUser" name="dsUser" value={values.dsUser} maxLength="50" id={'dsUser'} />
                                            {errors &&
                                                touched.dsUser &&
                                                errors.dsUser &&
                                                errors.dsUser !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='dsUser'
                                                            render={(err) => { return err; }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>



                                    <div
                                        className={`form_field ${errors &&
                                            touched.dsAccount &&
                                            errors.dsAccount &&
                                            errors.dsAccount !== '' ? 'error' : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>dsAccount</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter dsAccount" name="dsAccount" value={values.dsAccount} maxLength="50" id={'dsAccount'} />
                                            {errors &&
                                                touched.dsAccount &&
                                                errors.dsAccount &&
                                                errors.dsAccount !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='dsAccount'
                                                            render={(err) => { return err; }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>


                                    <div
                                        className={`form_field ${errors &&
                                            touched.CampaignID &&
                                            errors.CampaignID &&
                                            errors.CampaignID !== '' ? 'error' : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Campaign ID</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter CampaignID" name="CampaignID" value={values.CampaignID} maxLength="50" id={'CampaignID'} />
                                            {errors &&
                                                touched.CampaignID &&
                                                errors.CampaignID &&
                                                errors.CampaignID !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='CampaignID'
                                                            render={(err) => { return err; }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={`form_field ${errors &&
                                            touched.CampaignID1 &&
                                            errors.CampaignID1 &&
                                            errors.CampaignID1 !== '' ? 'error' : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Campaign ID 1</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter CampaignID1" name="CampaignID1" value={values.CampaignID1} maxLength="50" id={'CampaignID1'} />
                                            {errors &&
                                                touched.CampaignID1 &&
                                                errors.CampaignID1 &&
                                                errors.CampaignID1 !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='CampaignID1'
                                                            render={(err) => { return err; }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={`form_field`}>
                                        <div className="field-left">
                                            <label>Campaign ID 2</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter CampaignID2" name="CampaignID2" value={values.CampaignID2} maxLength="50" id={'CampaignID2'} />

                                        </div>
                                    </div>
                                    <Grid container direction={'row'} style={{ textAlign: 'center', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                        <div className={IsLoading ? 'form-field button-field disable-btn' : 'form-field button-field'}>
                                            <input type="submit" name="save"
                                                value={IsLoading ? "Saving..." : "Save"} />
                                        </div>
                                    </Grid>

                                    {/* <div className="form-submit">
                                        <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" />
                                        </div>
                                        <div className="button-field">
                                            <input type="button" value="close" name="close" onClick={() => props.closeModal()} />
                                        </div>
                                    </div> */}
                                    <Notification
                                        isError={isError}
                                        message={message}
                                        showNotification={showNotification}
                                        clearNotification={handleClearNotification.bind(this)}
                                        closeNotification={handleClearNotification.bind(this)}
                                    />
                                </form>
                            </div>
                        )
                    }}
                </Formik>
            </div>
        </>
    )
}
const validateCountryForm = yup.object().shape({
    OrderDetailID: yup.string().required('Select Campaign'),
    ReportTypeID: yup.string().required('Select ReportType'),
    dsUser: yup.string().required('dsUser is required'),
    dsAccount: yup.string().required('dsAccount is required'),
    // CampaignID: yup.string().required('CampaignID is required'),
});


export default ConfigureReportForm;