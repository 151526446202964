/*eslint-disable eqeqeq*/
import React from "react";
import file_upload_ic from "../../../assets/images/file-upload-ic.svg";
import * as APIController from "../../../api/APIController.js";
import Notification from "../../../shared/components/notification/notification";
import MyiFrame from "./myiFrame.js";

const UploadAsset = (props) => {
    const [UploadAssetId, setuploadAssetId] = React.useState("");
    const [CampaignId, setCampaignId] = React.useState("");
    const [AssetTypeId, setAssetTypeId] = React.useState("");
    const [AssetSizeId, setAssetSizeId] = React.useState("");
    const [campaignThemesId, setCampaignThemesId] = React.useState("");
    const [content, setcontent] = React.useState("");
    const [previewContent, setPreviewContent] = React.useState("");
    const [DirectMail, setDirectMail] = React.useState(false);
    const [IsLoading, setIsLoading] = React.useState(false);
    const [AssetOrientation, setAssetOrientation] = React.useState(1);
    const [AssetOrientationData, setAssetOrientationData] = React.useState([{ ID: 1, Name: "Portrait" }, { ID: 2, Name: "Landscape" }]);

    React.useEffect(() => {
        setuploadAssetId(props.uploadAssetIdEdit);
        setCampaignId(props.campaignIdEdit);
        setAssetTypeId(props.assetTypeIdEdit);
        setAssetSizeId(props.assetSizeIdEdit);
        setCampaignThemesId(props.campaignThemesId);
        if (props.uploadAssetIdEdit == 1) {
            setcontent(props.DirectMailHTMLData)
            setDirectMail(true)
        } else if (props.uploadAssetIdEdit != 0) {
            wsGetCampaignAssetByID(props.uploadAssetIdEdit);
        }
    }, [props]);

    const wsGetCampaignAssetByID = (ID) => {
        APIController.GetCampaignAssetByID(ID).then((response) => {
            if (response.error == null) {
                setcontent(response.object.data[0].AdContent);
                setPreviewContent(response.object.data[0].AdContent);
                setAssetOrientation(response.object.data[0].AssetOrientation);
            } else if (response.error.message && response.error.message.length > 0) {
                handleNotification(response.error.message, true);
            }
        });
    };

    const wsAddEditCampaignAsset = (ID, CampaignID, AssetTypeID, AdContent, AssetSizeID, ImageName, ImageURL, ImageData, CampaignThemeID, AssetOrientation) => {
        setIsLoading(true);
        APIController.AddEditCampaignAsset(ID, CampaignID, AssetTypeID, AdContent, AssetSizeID, ImageName, ImageURL, ImageData, CampaignThemeID, AssetOrientation, props.isPrintProductEdit)
            .then((response) => {
                setIsLoading(false);
                if (response.error == null) {
                    if (response.object.status == 1) {
                        handleNotification(response.object.message);
                        setTimeout(() => {
                            props.closeModal();
                        }, 4000);
                    } else {
                        handleNotification(response.object.message, true);
                    }
                } else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            });
    };

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message);
        setShowNotification(true);
        setIsError(isError);

        setTimeout(() => {
            handleClearNotification();
        }, 4000);
    };

    const handleClearNotification = () => {
        setMessage("");
        setShowNotification(false);
        setIsError(false);
    };

    const getBase64 = file => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    return (
        <section className="campaign-page">
            {DirectMail ? <h2>Upload DirectMail Asset : {props.titlefieldName}</h2> : <h2>Upload Asset : {props.titlefieldName}</h2>}
            <div className="upload-asset-main">
                {DirectMail ?
                    <div className="form_field" style={{ width: '100%', justifyContent: 'center' }}>
                        <div className="form-file-input asset-button">
                            <input className="input-file" id="fileupload" name="files" type="file" accept=".html" onChange={(e) => {
                                const file = e.target.files[0];
                                if (file && file.type === "text/html") {

                                    getBase64(file)
                                        .then(result => {
                                            file["base64"] = result;
                                            setcontent(result.split(',').pop())
                                        }).catch(err => { });

                                    const reader = new window.FileReader();
                                    reader.onload = (data) => { setPreviewContent(data.target.result); };
                                    reader.readAsText(file);
                                }
                            }} />
                            <label htmlFor="fileupload" className="input-file-trigger">
                                <img src={file_upload_ic} alt="file-uplaod" /> Drag and drop your file here </label>
                        </div>

                        <div className="asset-full">
                            <MyiFrame HTMLContent={previewContent} />
                        </div>

                        <div className="asset-button">
                            <div className={IsLoading ? "button-field disable-btn" : "button-field"} >
                                <input type="submit" value="upload" name="upload" onClick={() => props.DirectMailData(content)} />
                            </div>
                        </div>
                    </div>
                    : <div className="form_field" style={{ width: '100%', justifyContent: 'center' }}>
                        <div style={{ display: 'flex', width: '100%' }}>
                            <div className="form-file-input asset-button" style={{ width: '49%', margin: '0 1%' }}>
                                <input className="input-file" id="fileupload" name="files" type="file" accept=".html" onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (file && file.type === "text/html") {

                                        getBase64(file)
                                            .then(result => {
                                                file["base64"] = result;
                                                setcontent(result.split(',').pop())
                                            }).catch(err => { });

                                        const reader = new window.FileReader();
                                        reader.onload = (data) => { setPreviewContent(data.target.result); };
                                        reader.readAsText(file);
                                    }
                                }} />
                                <label htmlFor="fileupload" className="input-file-trigger">
                                    <img src={file_upload_ic} alt="file-uplaod" /> Drag and drop your file here </label>
                            </div>
                            <div style={{ width: '49%', margin: '0 1%' }}>
                                <label>Asset Orientation</label>
                                <select id={'AssetOrientation'}
                                    name="reportType"
                                    defaultValue={AssetOrientation}
                                    value={AssetOrientation}
                                    onChange={(e) => setAssetOrientation(e.target.value)}>
                                    {AssetOrientationData.map((obj, index) => {
                                        return <option selected={obj.ID == AssetOrientation ? obj.ID : obj.ID} value={obj.ID} key={index}>{obj.Name}</option>
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="asset-full">
                            <MyiFrame HTMLContent={previewContent} />
                        </div>
                        <div className="asset-button">
                            <div className={IsLoading ? "button-field disable-btn" : "button-field"} >
                                <input type="submit" value="upload" name="upload" onClick={() => {
                                    wsAddEditCampaignAsset(UploadAssetId, CampaignId, AssetTypeId, content, AssetSizeId, "ImageName", "", "ImageData", campaignThemesId, AssetOrientation);
                                }} />
                            </div>
                        </div>
                    </div>
                }
            </div>
            <Notification
                isError={isError}
                message={message}
                showNotification={showNotification}
                clearNotification={handleClearNotification.bind(this)}
                closeNotification={handleClearNotification.bind(this)}
            />
        </section>
    );
};

export default UploadAsset;
