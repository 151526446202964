/*eslint-disable eqeqeq*/
import React from "react";
import LoginReportList from "./loginReportList";
import * as APIController from "../../../../../api/APIController.js";
import Notification from "../../../../../shared/components/notification/notification";
import CsvDownload from 'react-json-to-csv'
import * as AppConstants from "../../../../../config/AppConstant";
import TextField from "../../../../../shared/components/TextField/TextField";
import Dropdown from "../../../../../shared/components/Dropdown/Dropdown";
import MenuItem from '@material-ui/core/MenuItem';
import PageTitle from "../../../../../config/pageTitle";
import moment from 'moment';

class LoginReport extends React.Component {
    state = {
        currentPage: 1,
        count: 0,
        rowsPerPage: 1000000,
        rowsPerPageOptions: ["All", 50, 100, 250, 500, 1000],
        list: [],
        from: 0,
        to: 1000000,
        downloadlist: [],
        progress: true,
        message: "",
        showNotification: false,
        isError: false,
        downloadDate: moment(new Date().toUTCString()).utcOffset(0, false).format('MMDDYY'),
        showMessage:
            this.props.history.location.state != null &&
            this.props.history.location.state.showMessage,
        FromDate: moment(new Date().toUTCString()).utcOffset(0, false).format('YYYY-MM-DD'),
        ToDate: moment(new Date().toUTCString()).utcOffset(0, false).format('YYYY-MM-DD'),
        ReportTypeID: 1
    };

    componentDidMount() {
        this.wsGetLoginLogs(this.state.from, this.state.to, this.state.ReportTypeID);
    }

    wsGetLoginLogs(from, to, ReportTypeID, PracticeName, FullName, EmailAddress, Address1, Address2, City, State, Postcode, ShipToNo, VS, VSP, RecordPerPage, CurrentPage) {
        this.setState({ progress: true, });
        APIController.GetLoginLogs(this.state.FromDate, this.state.ToDate, PracticeName, FullName, EmailAddress, Address1, Address2, City, State, Postcode, ShipToNo, VS, VSP, RecordPerPage, CurrentPage, ReportTypeID).then((response) => {
            if (response.error == null && response.object.data.length != 0) {
                let downloaddata = []
                response.object.data.map((item) =>
                    downloaddata.push({
                        "Practice Name": item.PracticeName, "Full Name": item.FullName, "Email Address": item.EmailAddress, "Address1": item.Address1, "Address2": item.Address2,
                        "City": item.City, "State": item.State, "Postcode": item.Postcode.toString(), "Ship To No": item.ShipToNo.toString(), "Login At": item.LoginAt.toString()
                    })
                )
                this.setState({
                    downloadlist: downloaddata,
                    to: isNaN(to) ? 1000000 : to,
                    from: from,
                    count: response.object.data[0].MaxRows,
                    list: response.object.data,
                    progress: false,
                });
            } else { this.setState({ list: [], progress: false }) }
        });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        return (
            <>
                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    clearNotification={this.handleClearNotification}
                    closeNotification={this.handleClearNotification}
                />
                <section className='content-right-main'>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h1><PageTitle Title={AppConstants.ContactDetais.LoginReport} /></h1>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="field-right">
                                <Dropdown
                                    label='Type'
                                    id={'reportTypeID'}
                                    name='reportTypeId'
                                    value={this.state.ReportTypeID}
                                    onChange={(e) => { this.setState({ ReportTypeID: e.target.value }); this.wsGetLoginLogs(this.state.from, this.state.to, e.target.value); }}
                                    select >
                                    <MenuItem classes='' value={1}> {'Practice'} </MenuItem>
                                    <MenuItem classes='' value={2}> {'Admin'} </MenuItem>
                                    <MenuItem classes='' value={3}> {'VTM'} </MenuItem>
                                </Dropdown>
                            </div>
                            <TextField style={{ margin: '10px' }} label='From Date' type="date" name="FromDate" value={this.state.FromDate} onChange={this.handleChange} />

                            <TextField label='To Date' type="date" name="ToDate" value={this.state.ToDate} onChange={this.handleChange} />

                            <button className='button-field' style={{ minWidth: '100px' }} onClick={() => { this.wsGetLoginLogs(this.state.from, this.state.to, this.state.ReportTypeID); }}>Search</button>

                            <div className='button-field' style={{ height: '40px', width: '200px', minWidth: '180px' }}>
                                <CsvDownload data={this.state.downloadlist} filename={"REACH Portal Login Report " + this.state.downloadDate + ".csv"}>Download to CSV</CsvDownload>
                            </div>
                        </div>
                    </div>

                    <LoginReportList
                        {...this.state}
                        history={this.props.history}
                        handleChangeRowsPerPage={this.handleRowsPerPageChange}
                        handleChangePage={this.handlePageChange}
                        handleApplyFilter={this.handleApplyFilter}
                        handleNotification={this.handleNotification}
                    />
                    {/*  */}
                </section>
            </>
        );
    }

    handleRowsPerPageChange = (value, PracticeName, FullName, EmailAddress, Address1, Address2, City, State, Postcode, ShipToNo, VS, VSP) => {
        let from = 0
        let to = value;
        this.setState({ rowsPerPage: value, currentPage: 1, to: to, from: from })
        this.wsGetLoginLogs(from, to, this.state.ReportTypeID, PracticeName, FullName, EmailAddress, Address1, Address2, City, State, Postcode, ShipToNo, VS, VSP, isNaN(value) ? 1000000 : value, isNaN(value) ? '' : this.state.currentPage);
    }

    handlePageChange = (values, PracticeName, FullName, EmailAddress, Address1, Address2, City, State, Postcode, ShipToNo, VS, VSP) => {
        let from = values === 1 ? 0 * this.state.rowsPerPage : (values - 1) * this.state.rowsPerPage
        let to = values === 1 ? this.state.rowsPerPage : (from + this.state.rowsPerPage);
        this.setState({ currentPage: Number(values) })
        this.wsGetLoginLogs(from, to, this.state.ReportTypeID, PracticeName, FullName, EmailAddress, Address1, Address2, City, State, Postcode, ShipToNo, VS, VSP, this.state.rowsPerPage, Number(values),);
    }

    handleApplyFilter = (PracticeName, FullName, EmailAddress, Address1, Address2, City, State, Postcode, ShipToNo, VS, VSP) => {
        this.wsGetLoginLogs(this.state.from, this.state.to, this.state.ReportTypeID, PracticeName, FullName, EmailAddress, Address1, Address2, City, State, Postcode, ShipToNo, VS, VSP, this.state.rowsPerPage, this.state.currentPage);
    };

    handleNotification = (message, isError) => {
        this.setState({
            message: message,
            showNotification: true,
            isError: isError,
        });

        setTimeout(() => {
            this.handleClearNotification();
        }, 4000);
    };

    handleClearNotification = () => {
        this.setState({
            message: "",
            showNotification: false,
            isError: false,
        });
    };
}

export default LoginReport;
