import React from 'react';
import page_img1 from '../../../assets/images/dashboard1.png';


class DashboardImg extends React.Component {
    render() {
        return (
            <>
                <section className="content-right-main">
                    <div style={{ fontWeight: 550, color: "#334455", lineHeight: 1.2, fontSize: "22pt", marginTop: '15px' }}>Dashboard</div>
                    <section className="page-img">
                        <img src={page_img1} alt="" />
                    </section>
                </section>
            </>
        )
    }
}

export default DashboardImg;