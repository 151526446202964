import React from 'react';
import forgot_user_ic from '../../assets/images/forgot-username-ic.svg';
import close_ic from '../../assets/images/close-ic.svg';
import { Grid } from '@material-ui/core';

import * as AppConstants from "../../config/AppConstant";
import { PayPalButton } from "react-paypal-button-v2";

const PaypalPopup = props => {

    const { title, description, PracticeDetails, PlanID, OrderByName } = props;

    const paypalSubscribe = (data, actions) => {
        setTimeout(() => {
            return actions.subscription.create({
                "plan_id": PlanID,
                "custom_id": AppConstants.GetLocalStorage("localStorageAutomationSubscriptionID"),
                "subscriber": PracticeDetails
            });
        }, 4000);
    };

    const paypalOnError = (err) => {
        console.log("paypalOnError", err)
    }
    const paypalOnApprove = (data, detail) => {
        // call the backend api to store transaction details
        this.props.history.push({
            pathname: '/paymentSuccess', state: { AutomationSubscriptionID: AppConstants.GetLocalStorage("localStorageAutomationSubscriptionID"), OrderByName: OrderByName }
        })
    };

    return (
        <div className="popup-box forgot-popup-box" style={{ width: '10%' }}>
            <div className="box-heading">
                {/* <img src={delete_ic} alt="icon" /> */}
                {/* <img src={forgot_user_ic} alt="icon" /> */}
                <h2 className="h1">{title}</h2>
                <button className="close-btn"><img src={close_ic} alt="icon" onClick={props.closeModal} /></button>
            </div>
            <Grid container justify='center' alignItems='center' style={{ padding: '5% 3%' }}>
                {/* <h2>Are you sure you want to delete?</h2> */}
                <h2 className='confirmation-message'>{description}</h2>
            </Grid>
            <Grid container justify='space-around' alignItems='center' style={{ padding: '1%', }}>
                {/* <div className="button-field">
                    <input type="button" value={btnText != undefined ? btnText : "Delete"} name="delete" onClick={deletePress} />
                </div> */}
                <PayPalButton
                    amount="0.01"
                    options={{ vault: true }}
                    currency="USD"
                    createSubscription={paypalSubscribe}
                    onApprove={paypalOnApprove}
                    catchError={paypalOnError}
                    onError={paypalOnError}
                    onCancel={paypalOnError}
                    style={{ shape: 'pill', color: 'blue', layout: 'horizontal', label: 'subscribe', Size: 'medium', height: 43, tagline: false }}
                />
                <div className="button-field">
                    <input type="button" value="Close" name="close" onClick={props.closeModal} />
                </div>
            </Grid>
        </div>
    )
}

export default PaypalPopup;