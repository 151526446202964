/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'
import * as APIController from '../../../../api/APIController.js';
import * as yup from 'yup';

import Notification from '../../../../shared/components/notification/notification';
const MonthlyBudgetForm = props => {
    const initialValues = { productid: props.history.location.state.productNameEdit, budget: props.history.location.state.budgetEdit }
    const [isButtonValue, setButtonValue] = React.useState(false);
    const [IsLoading, setIsLoading] = React.useState(false);
    const [sourceProducts, setsourceProducts] = React.useState([]);

    React.useEffect(() => {
        document.getElementById('productid').focus();

        wsGetProductForDropdown();
    }, [props])

    const wsGetProductForDropdown = () => {
        APIController.GetProductForDropdown()
            .then((response) => {
                if (response.error == null) {
                    setsourceProducts(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const wsAddEditMonthlyBudget = (ID, ProductID, Budget) => {
        setIsLoading(true)
        APIController.AddEditMonthlyBudget(ID, ProductID, Budget)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    if (isButtonValue === true && response.object.status == 1) {
                        props.history.replace({
                            pathname: '/monthlyBudget',
                            state: { showMessage: response.object.message }
                        })
                    }
                    else if (response.object.status == 1) {
                        handleNotification(response.object.message);
                    } else {
                        handleNotification(response.object.message, true);
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    return (
        <>
            <section className="content-right-main">
                {props.history.location.state.monthlyBudgetIdEdit == 0 ? <h1>Add Monthly Budget</h1> : <h1>Edit Monthly Budget</h1>}
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validateMonthlyBudgetForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        wsAddEditMonthlyBudget(props.history.location.state.monthlyBudgetIdEdit, values.productid, values.budget)
                        if (isButtonValue === false) {
                            values.productid = "";
                            values.budget = "";
                        }
                    }} >
                    {({ values, handleSubmit, setFieldValue, errors, touched }) => {
                        return (
                            <div className="form-main">
                                <form onSubmit={handleSubmit}>

                                    <div className={`form_field`}>
                                        <div className="field-left">
                                            <label>Product Name</label>
                                        </div>
                                        <div className="field-right">
                                            <select
                                                id={'productid'}
                                                name="reportType"
                                                defaultValue={values.productid}
                                                value={values.productid}
                                                onChange={(e) => setFieldValue('productid', e.target.value)}>
                                                <option value={''}>Select Parent Product</option>
                                                {sourceProducts.map((obj, index) => {
                                                    return <option selected={obj.ID == values.productid ? obj.ID : obj.Name} value={obj.ID} key={index}>{obj.Name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className={`form_field ${errors && touched.budget && errors.budget && errors.budget !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>Budget</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Budget" name="budget" value={values.budget} maxLength="50" id={'budget'} />
                                            {errors && touched.budget && errors.budget && errors.budget !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='budget' render={(err) => { return err; }} />
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <Notification
                                        isError={isError}
                                        message={message}
                                        showNotification={showNotification}
                                        clearNotification={handleClearNotification.bind(this)}
                                        closeNotification={handleClearNotification.bind(this)}
                                    />
                                    <div className="form-submit">
                                        <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" onClick={() => { setButtonValue(true) }} />
                                        </div>
                                        {props.history.location.state.monthlyBudgetIdEdit == 0 ?
                                            <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                                <input type="submit" value={IsLoading ? "Saving..." : "save & add new"} name="save & add new" onClick={() => setButtonValue(false)} />
                                            </div> : null}
                                        <div className="button-field">
                                            <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )
                    }}
                </Formik>
            </section>
        </>
    )
}
const validateMonthlyBudgetForm = yup.object().shape({
    budget: yup.string().required('Budget is required'),
});


export default MonthlyBudgetForm;