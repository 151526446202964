/*eslint-disable eqeqeq*/
import React from 'react';
import TerritoriesList from '../../component/componentTerritories/Territorieslist';
// import {data} from './data'
import * as APIController from '../../../../api/APIController';
// import * as APIController from '../../../../src/api/APIController';
import Notification from '../../../../shared/components/notification/notification';
class AdminTerritoriesContainer extends React.Component {

    state = {
        currentPage: 0,
        count: 0,
        rowsPerPage: 25,
        rowsPerPageOptions: [25, 10, 5],
        list: [],
        list1: [],
        from: 0,
        to: 25,
        progress: true,
        message: '',
        showNotification: false,
        isError: false,
        showMessage: this.props.history.location.state != null && this.props.history.location.state.showMessage
    }
    componentDidMount() {
        this.wsGetTerritories();
        this.state.showMessage != '' && this.handleNotification(this.state.showMessage)
        this.props.history.replace('/resource/territories', null);
    }
    handlebind() {
        this.wsGetTerritories()
    }
    wsGetTerritories() {
        this.setState({ progress: true, });
        APIController.GetTerritories()
            .then((response) => {
                if (response.error == null) {
                    this.setState({
                        list: response.object.data,
                        list1: response.object.data,
                        count: response.object.data.length,
                        progress: false,
                    })
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.handleNotification(response.error.message, true);
                }
            })
    }
    render() {
        return (
            <>
                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    clearNotification={this.handleClearNotification}
                    closeNotification={this.handleClearNotification}
                />
                <section className="content-right-main">
                    <div className="button-field button-field-country">
                        <button onClick={() => {
                            this.props.history.push({
                                pathname: '/admin/territories/add',
                                state: { TerritoriesIdEdit: '0',areaIdEdit: '',RegionID:'',
                                TerritoriesNameEdit: '',ShortcodeEdit:'', TerritoriesActiveEdit: 1, }
                            })
                        }}>+ Add Territory</button>
                    </div>
                    <TerritoriesList {...this.state}
                        history={this.props.history}
                        handlebind={this.handlebind.bind(this)}
                        handleChangeRowsPerPage={this.handleRowsPerPageChange}
                        handleChangePage={this.handlePageChange}
                        applyFilter={this.handleApplyFilter}
                        handleNotification={this.handleNotification}
                    />
                </section>
            </>
        )
    }
      /**
     * handleRowsPerPageChange method is used to update rows per page
     * it will reset page to 1
     */

    handleRowsPerPageChange = (value) => {
        let from = 0
        let to = value;
        this.setState({ rowsPerPage: value, currentPage: 1, to: to, from: from })
    }

    /**
     * handlePageChange method is used to change page
     */

    handlePageChange = values => {
        let from = values === 1 ? 0 * this.state.rowsPerPage : (values - 1) * this.state.rowsPerPage
        let to = values == 1 ? this.state.rowsPerPage : parseInt(from) + parseInt(this.state.rowsPerPage);
        this.setState({ currentPage: Number(values), to: to, from: from })
    }

      /**
     * handleApplyFilter method is used to apply filter on every columns
     */
    handleApplyFilter = (tag, values, type) => {
        if (values !== "") {

            let searchCountrys = []
            /**
             * it will check if filter is apply from select than it will match exact keyword
             * else it will find nearest word
             */
            if (type === 'select') {
                searchCountrys = this.state.list1.filter(
                    (us) => us[tag].toLowerCase() === values.toLowerCase()
                );
            } else {
                searchCountrys = this.state.list.filter(
                    (us) => us[tag].toLowerCase().indexOf(values.toLowerCase()) > -1
                );
            }
            this.setState({
                list: searchCountrys,
                currentPage: 0,
                count: searchCountrys.length,
                rowsPerPage: 25,
                rowsPerPageOptions: [25, 10, 5],
                from: 0,
                to: 25,
                progress: false,
            });
        } else {
            this.wsGetTerritories();
            this.setState({
                currentPage: 0,
                rowsPerPage: 25,
                rowsPerPageOptions: [25, 10, 5],
                from: 0,
                to: 25
            });
        }
    }

    handleNotification = (message, isError) => {

        this.setState({
            message: message,
            showNotification: true,
            isError: isError
        })

        setTimeout(() => {
            this.handleClearNotification();
        }, 4000)
    }
    
    handleClearNotification = () => {
        this.setState({
            message: '',
            showNotification: false,
            isError: false
        })
    }

}

export default AdminTerritoriesContainer;