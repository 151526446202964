import React, { Component, PropTypes } from 'react';
import RichTextEditor from 'react-rte';

class MyStatefulEditor extends Component {

    state = {
        value: RichTextEditor.createEmptyValue()
    }

    onChange = (value) => {
        this.setState({ value });
        if (this.props.onChange) {
            // Send the changes up to the parent component as an HTML string.
            // This is here to demonstrate using `.toString()` but in a real app it
            // would be better to avoid generating a string on each change.
            this.props.onChange(
                value.toString('html')
            );
        }
    };

    swapSoftNewLineBehavior(event) {
        let isSoftKeyPressed = (e) => {

            return e.which === 13 && (e.getModifierState("Shift") || e.getModifierState("Alt") || e.getModifierState("Control"));
        };

        if (!isSoftKeyPressed(event)) {

            event.getModifierState = (_) => { return true; };

        } else {

            event.getModifierState = (_) => { return false; }
        }
    }

    render() {
        // The toolbarConfig object allows you to specify custom buttons, reorder buttons and to add custom css classes.
        // Supported inline styles: https://github.com/facebook/draft-js/blob/master/docs/Advanced-Topics-Inline-Styles.md
        // Supported block types: https://github.com/facebook/draft-js/blob/master/docs/Advanced-Topics-Custom-Block-Render.md#draft-default-block-render-map
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_ALIGNMENT_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Strikethrough', style: 'STRIKETHROUGH' },
                { label: 'Monospace', style: 'CODE' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
            BLOCK_TYPE_DROPDOWN: [
                { label: 'Normal', style: 'unstyled' },
                { label: 'Heading Large', style: 'header-one' },
                { label: 'Heading Medium', style: 'header-two' },
                { label: 'Heading Small', style: 'header-three' }
            ],
            BLOCK_TYPE_BUTTONS: [
                { label: 'UL', style: 'unordered-list-item' },
                { label: 'OL', style: 'ordered-list-item' }
            ],
            BLOCK_ALIGNMENT_BUTTONS: [
                { label: "Align Left", style: "ALIGN_LEFT" },
                { label: "Align Center", style: "ALIGN_CENTER" },
                { label: "Align Right", style: "ALIGN_RIGHT" },
                { label: "Align Justify", style: "ALIGN_JUSTIFY" },
            ],
        };

        return (
            <RichTextEditor
                // handleReturn={this.swapSoftNewLineBehavior}
                toolbarConfig={toolbarConfig}
                value={this.state.value}
                onChange={this.onChange}
            />
        );
    }
}

export default MyStatefulEditor;