/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import close_ic from '../../../assets/images/close-ic.svg'
import * as yup from 'yup';

import Notification from '../../../shared/components/notification/notification';
import * as APIController from '../../../api/APIController';

const RejectForm = (props) => {
	const initialValues = { rejectionReason: '' }
	const [IsLoading, setIsLoading] = React.useState(false);

	const [message, setMessage] = React.useState("");
	const [showNotification, setShowNotification] = React.useState(false);
	const [isError, setIsError] = React.useState(false);

	const handleNotification = (message, isError) => {
		setMessage(message)
		setShowNotification(true)
		setIsError(isError)
		setTimeout(() => {
			handleClearNotification();
		}, 4000)
	}

	const handleClearNotification = () => {
		setMessage('')
		setShowNotification(false)
		setIsError(false)
	}

	const RejectPractice = (PracticeID, RejectionReason) => {
		setIsLoading(true)
		APIController.RejectPractice(PracticeID, RejectionReason)
			.then((response) => {
				setIsLoading(false)
				if (response.error == null) {
					if (response.object.status == 1) {
						handleNotification(response.object.message, false);
					}
					else {
						handleNotification(response.object.message, true);
					}
				}
				else if (response.error.message && response.error.message.length > 0) {
					handleNotification(response.error.message, true);
				}
			})
	}

	return (
		<div className="popup-box forgot-popup-box">
			<div className="box-heading">
				<h2 className="h1">Reject Practice</h2>
				<button className="close-btn"><img src={close_ic} alt="icon" onClick={props.closeModal} /></button>
			</div>
			<div className="box-content">
				<Formik
					enableReinitialize={true}
					initialValues={initialValues}
					validationSchema={validateUsersForm}
					validateOnMount={false}
					onSubmit={(values) => {
						RejectPractice(props.PracticeID, values.rejectionReason)
					}}
				>
					{({ handleSubmit, values,  errors, touched, }) => {
						return (
							<form onSubmit={handleSubmit}>
								<div
									className={`form_field ${errors &&
										touched.rejectionReason &&
										errors.rejectionReason &&
										errors.rejectionReason !== ''
										? 'error'
										: ''
										}`}>
									<div style={{ textAlign: 'left' }} className="field-left">
										<label>Rejection Reason</label>
									</div>
									<div className="field-right">
										<Field type="text" placeholder="Enter Reason" name="rejectionReason" value={values.rejectionReason} />
										{errors &&
											touched.rejectionReason &&
											errors.rejectionReason &&
											errors.rejectionReason !==
											'' && (
												<span className='error-msg'>
													<ErrorMessage
														name='rejectionReason'
														render={(err) => { return err; }}
													/>
												</span>
											)}
									</div>
								</div>

								<Notification
									isError={isError}
									message={message}
									showNotification={showNotification}
									clearNotification={handleClearNotification.bind(this)}
									closeNotification={handleClearNotification.bind(this)}
								/>

								<div className={IsLoading ? "button-field disable-btn" : "button-field"}>
									<input type="submit" value={IsLoading ? "rejecting..." : "reject"} name="reject" />
								</div>
								<div className={IsLoading ? "button-field disable-btn" : "button-field"}>
									<input type="button" value='cancel' name="cancel" onClick={props.closeModal} />
								</div>

							</form>
						)
					}}
				</Formik>
			</div>
		</div>

	)
};

const validateUsersForm = yup.object().shape({
	rejectionReason: yup.string().required('Reason is required'),
});

export default RejectForm;