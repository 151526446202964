/*eslint-disable eqeqeq*/
import React from "react";
import * as APIController from '../../../api/APIController.js';
import Table from "../../../shared/components/table/table";
import TableHead from "../../../shared/components/table/tableHead";
import TableRow from "../../../shared/components/table/tableRow";
import TableBody from "../../../shared/components/table/tableBody";
import TableCell from "../../../shared/components/table/tableCell";
import "react-perfect-scrollbar/dist/css/styles.css";

import PerfectScrollbar from "react-perfect-scrollbar";

import deleteIcon from '../../../assets/images/delete.svg'
import editIcon from '../../../assets/images/edit.svg'
import filtericon from '../../../assets/images/ic_feather_filter.svg'

import Modal from '../../../shared/components/modal/modal';
import ConfirmtionDialog from '../../../features/ConfirmDialog/ConfirmationDialog';
import Notification from '../../../shared/components/notification/notification';
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";

const OrderList = (props) => {
	const headerList = ['action', "Name", "ReportType ID"]
	const { currentPage, count, rowsPerPage, rowsPerPageOptions, list, to, from } = props;
	let countryList = list;
	countryList = list.slice(from, to)
	const [LoginDialog, setLoginDialog] = React.useState(false);
	const [DeleteId, setDeleteId] = React.useState('');

	const page = Math.floor(count / rowsPerPage) + 1;

	/**
	 * handleChangeInCurrentPage method is used to change page number
	 * it will check that page number does not exist last page
	 * @param {*} value 
	 */
	const handleChangeInCurrentPage = (value) => {
		if (value <= page && Number(parseInt(value))) {
			props.handleChangePage(Number(value));
		}
	}

	const WsDeleteOrderReportIDs = (ID) => {
		APIController.DeleteOrderReportIDs(ID)
			.then((response) => {
				if (response.error == null) {
					if (response.object.status == 1) {
						props.handleNotification(response.object.message);
					} else {
						props.handleNotification(response.object.message, true);
					}
					props.handlebind()
					setLoginDialog(false)
				}
				else if (response.error.message && response.error.message.length > 0) {
					props.handleNotification(response.error.message)
				}
			})
	}

	const [message, setMessage] = React.useState("");
	const [showNotification, setShowNotification] = React.useState(false);
	const [isError, setIsError] = React.useState(false);

	const handleClearNotification = () => {
		setMessage('')
		setShowNotification(false)
		setIsError(false)
	}
	return (
		<>

			<h1>Order Details - #{props.OrderNo}</h1>
			<div className="table-main-wrap countrys-class">
				<PerfectScrollbar component='div'>
					<Table>
						<TableHead>
							<TableRow>
								{headerList.map((obj, index) => {
									return <TableCell style={index == 0 ? { width: '10%' } : index == index.length - 1 ? { width: '10%' } : { width: '80%' }} className={obj === "Is active ?" ? 'center' : ''} key={index}>{obj}</TableCell>;
								})}
							</TableRow>
							<TableRow>
								<TableCell>
									<span><img src={filtericon} alt="delete" /></span>
								</TableCell>
								<TableCell>
									<input type="text" name="" placeholder="Search Name" onChange={(e) => props.applyFilter('Name', e.target.value, 'input')} />
								</TableCell>
								<TableCell>
									<input type="text" name="" placeholder="Search Report type Id" onChange={(e) => props.applyFilter('ReportTypeID', e.target.value, 'input')} />
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!!countryList.length ? countryList.map((obj, idnex) => {
								return (
									<TableRow key={idnex}>
										<TableCell>
											<span>
												<img src={editIcon} alt="edit" style={{ cursor: 'pointer', marginRight: '3px' }}
													onClick={() => {
														props.handleAddEditPopup(obj)
													}} />
												<img src={deleteIcon} alt="delete" style={{ cursor: 'pointer', marginRight: '3px' }}
													onClick={() => {
														setDeleteId(obj.ID)
														setLoginDialog(true)
													}} />
											</span>
										</TableCell>
										<TableCell>
											{obj.Name}
										</TableCell>
										<TableCell >
											{obj.ReportTypeID}
										</TableCell>
									</TableRow>
								);
							}) : <TableRow >
								<TableCell colSpan={headerList.length} className="not_found">
									{props.progress ?
										<CircularProgress /> :
										<label>Not Currently Available</label>}
								</TableCell>
							</TableRow>}
						</TableBody>
					</Table>
				</PerfectScrollbar>
			</div>
			<Notification
				isError={isError}
				message={message}
				showNotification={showNotification}
				closeNotification={handleClearNotification.bind(this)}
			/>
			{LoginDialog && (
				<Modal
					show={LoginDialog}
					handleClose={() => setLoginDialog(false)}>
					<ConfirmtionDialog
						title={"Delete Campaign"}
						description={"Are you sure you want to delete this Campaign?"}
						deletePress={() => WsDeleteOrderReportIDs(DeleteId)}
						closeModal={() => setLoginDialog(false)}
					/>
				</Modal>
			)}
		</>
	);
};

export default OrderList;
