import React, { Component } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import * as AppConsatnt from "../../../config/AppConstant";

class ModalComponent extends Component {
	render() {
		return (
			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				className={`modal-main ${AppConsatnt.GetMainClassName()}`}
				open={this.props.show}
				onClose={this.props.handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}>
				<Fade in={this.props.show}>
					<div>{this.props.children}</div>
				</Fade>
			</Modal>
		);
	}
}

export default ModalComponent;
