
let refs;

/**
 * getRef - get html element reference
 */
const getRef = () => {
	return refs.current;
};

/**
 * setRef - set html element reference
 */
const setRef = (ref) => {
	refs = ref;
};

export {
	getRef,
	setRef
};
