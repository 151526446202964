import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import "font-awesome/css/font-awesome.css";
import { Doughnut } from "react-chartjs-2";
import CountUp from "react-countup";
import styles from "./SocialDonutChartStyle";
import { CircularProgress } from "@material-ui/core";
import './DashboardStyle.css';

class SocialDonutChart extends React.Component {
    render() {
        return (
            <div className="social-chart-main" style={{ width: '32%', margin: '5px' }} >
                {this.props.IsLoadingFbInst ?
                    this.props.data.map((obj, index) => {
                        return (
                            <div>
                                {index == 0 ?
                                    <Paper style={{ padding: "20px", margin: '5px' }}>
                                        <Typography className={'title-main'} variant="subheading">{'Campaign Budget Spent'}</Typography>
                                        <div style={{ width: '100%', height: '165px', justifyContent: "center", alignItems: "center" }}  >
                                            <CircularProgress style={{ color: '#0a5c7f' }} />
                                        </div>
                                    </Paper> :
                                    <Paper style={{ padding: "20px", margin: '5px' }}>
                                        <Typography className={'title-main'} variant="subheading">{obj.Title}</Typography>
                                        <div style={{ marginBottom: '10px', marginTop: '10px', borderBottom: '2.5px solid #F5F7FA', width: '100%' }} />
                                        <div style={{ width: '100%', height: '165px', justifyContent: "center", alignItems: "center" }}  >
                                            <CircularProgress style={{ color: '#0a5c7f' }} />
                                        </div>
                                    </Paper>
                                }
                            </div>
                        )
                    }) :
                    this.props.FinalSocialReports.map((obj, index) => {
                        return (
                            index != 0 &&
                            <div key={index} style={{ width: '100%' }}  >
                                <Paper style={{ padding: "20px" }}>
                                    <Typography className={'title-main'} variant="subheading">{obj.ReportType}</Typography>
                                    <div className="facebook-chart" style={{ width: '100%', height: '165px', justifyContent: "space-between", alignItems: "center", display: "flex" }}>

                                        <div style={{ width: '39%', justifyContent: "flex-start", alignItems: "flex-start" }}  >
                                            <Typography style={{ fontSize: '15px' }} className={'title-content'}>Impressions</Typography>
                                            <CountUp separator={','} style={{ marginBottom: '10px' }} duration={3} className={'title'} end={obj.Impressions} />
                                            <Typography style={{ fontSize: '15px' }} className={'title-content'} >Clicks</Typography>
                                            <CountUp separator={','} style={{ marginBottom: '10px' }} duration={3} className={'title'} end={obj.Clicks} />
                                            <Typography style={{ fontSize: '15px' }} className={'title-content'} >Total Conversions</Typography>
                                            <CountUp separator={','} className={'title'} end={parseInt(obj.Certificate_Downloads) + parseInt(obj.Certificate_Emails) + parseInt(obj.Appointment_Clicks)} />
                                        </div>

                                        <div style={{ width: '60%', borderLeft: '3px solid #F5F7FA', justifyContent: "center", alignItems: "center", }}  >
                                            <Doughnut
                                                height={200}
                                                data={{
                                                    labels: ["Certificate Download", "Appointment Clicks  ", "Certificate Email        ",],
                                                    datasets: [{
                                                        weight: 0.5,
                                                        data: [obj.Certificate_Downloads, obj.Appointment_Clicks, obj.Certificate_Emails],
                                                        borderWidth: 0,
                                                        backgroundColor: ['#689AB0', '#0A5C7F', '#C6D8E1'],
                                                        hoverBackgroundColor: "#3588ba"
                                                    }]
                                                }}
                                                options={{
                                                    animateRotate: true,
                                                    animateScale: false,
                                                    animation: { duration: 2000 },
                                                    legend: {
                                                        position: "bottom", align: "center",
                                                        labels: { boxWidth: 30, fontSize: 12, fontColor: '#646464' }
                                                    },
                                                    datalabels: { display: true, color: "white" },
                                                    tooltips: { backgroundColor: "#5a6e7f" }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Paper>
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}

SocialDonutChart.propTypes = {
    theme: PropTypes.shape({
        palette: PropTypes.shape({
            primary: PropTypes.shape({
                dark: PropTypes.string,
                main: PropTypes.string,
                contrastText: PropTypes.string,
            }),
            secondary: PropTypes.shape({
                main: PropTypes.string,
            }),
            common: PropTypes.shape({
                white: PropTypes.string,
            }),
        }),
    }).isRequired,
};

export default withStyles(styles, { withTheme: true })(SocialDonutChart);
