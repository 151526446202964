import React from 'react';

import welcome_img from "../../../assets/images/Error500.png";
import { Typography, Grid, Divider, } from '@material-ui/core';

class ErrorBoundary extends React.Component {
	state = {
		error: null
	};
	static getDerivedStateFromError(error) {
		return { error };
	}
	
	componentDidCatch(error) {
		this.logError(error);
	}

	logError(args) {
	}

	render() {
		if (this.state.error) {
			return <section className='content-right-main'>
				<Grid container direction="column" justify="space-between" alignItems="center" className='main_tab_content' >
					<img src={welcome_img} alt='' style={{ marginTop: '0px', paddingTop: '0px' }} />
					<h1>Oops!! Something went wrong.</h1>
					<Typography style={{ color: '#0A0A0A', fontSize: '18px' }}>Please try again later after sometime.</Typography>
					<div className="button-field">
						<input type="button" value="    Back to home    " name="close" onClick={() => this.props.history.push("/")} />
					</div>
				</Grid>
			</section>
		}
		return this.props.children;
	}
}

export default ErrorBoundary;
