/*eslint-disable eqeqeq*/
import React from "react";
import EmailMarketingReportList from "./emailMarketingReportList";
import * as APIController from "../../../../../api/APIController.js";
import Notification from "../../../../../shared/components/notification/notification";
import CsvDownload from 'react-json-to-csv'
import moment from 'moment';

import PageTitle from "../../../../../config/pageTitle";
import * as AppConstants from "../../../../../config/AppConstant";
import TextField from "../../../../../shared/components/TextField/TextField";

class EmailMarketingReport extends React.Component {
    state = {
        currentPage: 1,
        count: 0,
        rowsPerPage: 1000000,
        rowsPerPageOptions: ["All", 50, 100, 250, 500, 1000],
        list: [],
        from: 0,
        to: 1000000,
        downloadlist: [],
        progress: true,
        message: "",
        showNotification: false,
        isError: false,
        downloadDate: moment(new Date().toUTCString()).utcOffset(0, false).format('MMDDYY'),
        showMessage:
            this.props.history.location.state != null &&
            this.props.history.location.state.showMessage,
        FromDate: "2022-05-16",
        ToDate: moment(new Date().toUTCString()).utcOffset(0, false).format('YYYY-MM-DD'),
    };

    componentDidMount() {
        this.wsAssetDownloadReport(this.state.from, this.state.to, "", "", "", "", "", "", "", "", "", "", "", "", "", this.state.FromDate, this.state.ToDate, this.state.currentPage, this.state.rowsPerPage);
    }

    wsAssetDownloadReport(from, to, DownloadedAt, ShipToNo, CampaignName, BrandName, PracticeName, CampaignTheme, EmailAddress, VeevaCode, Version, VTM_Territory, VTM, VS, VSP, StartDate, EndDate, CurrentPage, RecordPerPage) {
        this.setState({ progress: true, });

        // ReportTypeID, DownloadedAt, OrderNo, ShipToNo, CampaignName, BrandName, CampaignTheme, PracticeName,
        // Address1, Address2, City, State, Postcode, PhoneNo, VS, VSP, Website, AppointmentURL, ContactName, EmailAddress, VTM_Territory, VTM,
        // VeevaCode, Version, SubscriptionNo, Subscription, StartDate, EndDate,
        // FullName, AssetCategory, AssetName, CSRFirstName, CSRLastName, CurrentPage, RecordPerPage

        APIController.AssetDownloadReport("6", DownloadedAt, "", ShipToNo, CampaignName, BrandName, CampaignTheme, PracticeName,
            "", "", "", "", "", "", VS, VSP, "", "", "", EmailAddress, VTM_Territory, VTM,
            VeevaCode, Version, "", "", StartDate, EndDate,
            "", "", "", "", "", CurrentPage, RecordPerPage).then((response) => {
                if (response.error == null && response.object.data.length != 0) {
                    let downloaddata = []
                    response.object.data.map((item) =>
                        downloaddata.push({
                            "Accessed At": item.DownloadedAt, "Ship To #": item.ShipToNo, "Practice Name": item.PracticeName,
                            "Campaign": item.CampaignName, "Brand": item.BrandName, "Email Name": item.EmailAddress, "Veeva Code": item.VeevaCode,
                            "Version": item.Version, "VTM #": item.VTM_Territory, "Territory Manager": item.VTM, "VS": item.VS, "VSP": item.VSP,
                        })
                    )
                    this.setState({
                        downloadlist: downloaddata,
                        to: isNaN(to) ? 1000000 : to,
                        from: from,
                        count: response.object.data[0].MaxRows,
                        list: response.object.data,
                        progress: false,
                    });
                } else { this.setState({ list: [], progress: false }) }
            });
    }

    render() {
        return (
            <>
                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    clearNotification={this.handleClearNotification}
                    closeNotification={this.handleClearNotification}
                />
                <section className='content-right-main'>

                    <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                        <h1><PageTitle Title={AppConstants.ContactDetais.EmailMarketingReport} /></h1>
                        <div style={{ display: 'flex', alignItems: 'baseline' }}>
                            <TextField style={{ margin: '10px' }} label='From Date' type="date" name="FromDate" value={this.state.FromDate} onChange={this.handleChange} />

                            <TextField label='To Date' type="date" name="ToDate" value={this.state.ToDate} onChange={this.handleChange} />

                            <div className='button-field button-field-country' style={{ height: '40px', minWidth: '100px', textAlign: 'center' }}>
                                <button onClick={() => { this.wsAssetDownloadReport(this.state.from, this.state.to, "", "", "", "", "", "", "", "", "", "", "", "", "", this.state.FromDate, this.state.ToDate, this.state.currentPage, this.state.rowsPerPage); }}> Search </button>
                            </div>

                            <div className='button-field' style={{ height: '40px', width: '240px', textAlign: 'center' }}>
                                <CsvDownload data={this.state.downloadlist} filename={"REACH Email Marketing Download Report " + this.state.downloadDate + ".csv"}>Download to CSV</CsvDownload>
                            </div>
                        </div>
                    </div>

                    <EmailMarketingReportList
                        {...this.state}
                        history={this.props.history}
                        handleChangeRowsPerPage={this.handleRowsPerPageChange}
                        handleChangePage={this.handlePageChange}
                        handleApplyFilter={this.handleApplyFilter}
                        handleNotification={this.handleNotification}
                    />
                </section>
            </>
        );
    }
    handleRowsPerPageChange = (value, DownloadedAt, ShipToNo, CampaignName, BrandName, PracticeName, CampaignTheme, EmailAddress, VeevaCode, Version, VTM_Territory, VTM, VS, VSP) => {
        let from = 0
        let to = value;
        this.setState({ rowsPerPage: value, currentPage: 1, to: to, from: from })
        this.wsAssetDownloadReport(from, to, DownloadedAt, ShipToNo, CampaignName, BrandName, PracticeName, CampaignTheme, EmailAddress, VeevaCode, Version, VTM_Territory, VTM, VS, VSP, this.state.StartDate, this.state.EndDate, isNaN(value) ? '' : this.state.currentPage, isNaN(value) ? 1000000 : value);
    }

    handlePageChange = (values, DownloadedAt, ShipToNo, CampaignName, BrandName, PracticeName, CampaignTheme, EmailAddress, VeevaCode, Version, VTM_Territory, VTM, VS, VSP) => {
        let from = values === 1 ? 0 * this.state.rowsPerPage : (values - 1) * this.state.rowsPerPage
        let to = values === 1 ? this.state.rowsPerPage : (from + this.state.rowsPerPage);
        this.setState({ currentPage: Number(values) })
        this.wsAssetDownloadReport(from, to, DownloadedAt, ShipToNo, CampaignName, BrandName, PracticeName, CampaignTheme, EmailAddress, VeevaCode, Version, VTM_Territory, VTM, VS, VSP, this.state.StartDate, this.state.EndDate, Number(values), this.state.rowsPerPage);
    }

    /**
     * handleApplyFilter method is used to apply filter on every columns
     */
    handleApplyFilter = (DownloadedAt, ShipToNo, CampaignName, BrandName, PracticeName, CampaignTheme, EmailAddress, VeevaCode, Version, VTM_Territory, VTM, VS, VSP) => {
        this.wsAssetDownloadReport(this.state.from, this.state.to, DownloadedAt, ShipToNo, CampaignName, BrandName, PracticeName, CampaignTheme, EmailAddress, VeevaCode, Version, VTM_Territory, VTM, VS, VSP, this.state.StartDate, this.state.EndDate, this.state.currentPage, this.state.rowsPerPage);
    };

    handleNotification = (message, isError) => {
        this.setState({
            message: message,
            showNotification: true,
            isError: isError,
        });

        setTimeout(() => {
            this.handleClearNotification();
        }, 4000);
    };

    handleClearNotification = () => {
        this.setState({
            message: "",
            showNotification: false,
            isError: false,
        });
    };
}

export default EmailMarketingReport;
