import React from "react";
import * as APIController from "../../../api/APIController.js";
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";
import "./welcome-screen.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import * as AppConstants from "../../../config/AppConstant";

import ProductTabSelection from './product-tab-selection';

const WelcomeScreen = (props) => {
	const [Description, setDescription] = React.useState('');
	const [Loading, setLoading] = React.useState(true)
	const [ProductImages, setProductImages] = React.useState([]);

	// const [ChildrenTabs, setChildrenTabs] = React.useState([]);

	React.useEffect(() => {
		wsGetProductByID(AppConstants.GetLocalStorage('localStorageProductID'))
		// setChildrenTabs(JSON.parse(AppConstants.GetLocalStorage('localStorageChildren')))

	}, [props]);

	const wsGetProductByID = (ID) => {
		APIController.GetProductByID(ID)
			.then((response) => {
				if (response.error == null) {
					setDescription(response.object.data[0].Description)
					wsGetProductImages(response.object.data[0].ID, AppConstants.GetLocalStorage('localStorageBrandID'))
				}
				else if (response.error.message && response.error.message.length > 0) {
					// handleNotification(response.error.message);
				}
			})
	}

	const wsGetProductImages = (ID, BrandID) => {
		APIController.GetProductImages(ID, BrandID)
			.then((response) => {
				setLoading(false)
				if (response.error == null) {
					setProductImages(response.object.data)
				}
				else if (response.error.message && response.error.message.length > 0) {
					// handleNotification(response.error.message, true);
				}
			})
	}

	const settings = {
		dots: false,
		infinite: true,
		speed: 3000,
		slidesToShow: 1,
		slidesToScroll: 1,
		nav: false,
		arrows: false,
		autoplay: true,
		customPaging: i => (<div style={{ height: 10, width: 10, borderRadius: 50, backgroundColor: '#3232' }}></div>)
	};
	return (
		<section className='content-right-main'>
			{/* {AppConstants.ShowPageTitle()} */}
			<div className="content-right-heading">
				{/* {AppConstants.GetLocalStorage('localStorageCampaignName').split('®')[0]}
				{AppConstants.GetLocalStorage('localStorageCampaignName').match(/®/g) ? <sup>&reg;</sup> : ''}
				{AppConstants.GetLocalStorage('localStorageCampaignName').split('®')[1]}{': '} */}
				{AppConstants.GetLocalStorage('localStorageProductName')}
			</div>
			{/* {ChildrenTabs.length > 1 &&
				<ProductTabSelection history={props.history} ChildrenTabs={ChildrenTabs} INDEX={props.history.location.state != undefined ? props.history.location.state.Index : 0} />} */}

			<div className='two-col-sec digital-welcome'>
				{Loading ? <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}><CircularProgress /></div>
					: <>
						<div className='sec-left'>
							<div style={{ letterSpacing: '0px' }}>
								<div dangerouslySetInnerHTML={{ __html: Description }} />
							</div>
							<div className='button-field' style={{ marginTop: "40px", marginLeft: "-1%" }}>
								<button onClick={() => {
									props.history.push(AppConstants.GetLocalStorage('localStorageCustomizePath'))
									AppConstants.SetLocalStorage("localStorageAutomation", "NO")
									AppConstants.SetLocalStorage("localStorageBudgetDetail", AppConstants.GetLocalStorage('localStorageMenuPath')
										== "/digital-product/selection/location-selection" ? JSON.stringify([{ "budget": 1, "duration": 1 }]) : JSON.stringify([{ "budget": "", "duration": "" }]))
								}}
								>{AppConstants.ButtonText.GetStarted}</button>
							</div>
						</div>
						<div className="sec-right" style={{ padding: '0px', margin: '0px', zIndex: '0' }} >
							<div className='image-slider' >
								<Slider  {...settings}>
									{!!ProductImages.length ? ProductImages.map((obj, index) => {
										return (
											<div className='image-slider'>
												<img style={{ maxHeight: '700px', width: '100%', resize: 'vertical' }} src={obj.ImageURL} alt='slider-2' />
											</div>
										)
									}) : ''}
								</Slider>
							</div>
						</div>
					</>}
			</div>

		</section>
	);
};

export default WelcomeScreen;
