/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'
import './userForm.css'
import * as APIController from '../../../api/APIController';
import * as yup from 'yup';

import Notification from '../../../shared/components/notification/notification';
import CircularProgress from '@material-ui/core/CircularProgress';

const UserForm = props => {
    const initialValues = {
        usersFirstname: '', usersLastname: '',
        usersEmailAddress: props.history.location.state.usersEmailEdit,
        usersPhoneNo: '',
        roleId: '',
        companyBranchId: '',
        positionsId: '',
        departmentId: '',
        areaId: '',
        regionId: '',
        territoryId: '',
        active: props.history.location.state.usersActiveEdit
    }
    const [isButtonValue, setButtonValue] = React.useState(false);
    const [LoadingRole, setLoadingRole] = React.useState(true)
    const [LoadingArea, setLoadingArea] = React.useState(true)
    const [LoadingRegion, setLoadingRegion] = React.useState(true)
    const [LoadingTerritory, setLoadingTerritory] = React.useState(true)
    const [LoadingPosition, setLoadingPosition] = React.useState(true)
    const [LoadingDepartment, setLoadingDepartment] = React.useState(true)
    const [LoadingCompanyBranch, setLoadingCompanyBranch] = React.useState(true)
    const [IsLoading, setIsLoading] = React.useState(false);

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)
        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }
    const [dataRoleDropdown, setdataRoleDropdown] = React.useState([]);
    const [dataAreaDropdown, setdataAreaDropdown] = React.useState([]);
    const [dataRegionDropdown, setdataRegionDropdown] = React.useState([]);
    const [dataTerritoryDropdown, setdataTerritoryDropdown] = React.useState([]);
    const [dataPositionsDropdown, setdataPositionsDropdown] = React.useState([]);
    const [dataDepartmentDropdown, setdataDepartmentDropdown] = React.useState([]);
    const [dataCompanyBranchDropdown, setdataCompanyBranchDropdown] = React.useState([]);
    const [userLevelID, setUserLevelID] = React.useState(1);

    React.useEffect(() => {
        if (props.history.location.state.usersIdEdit != 0) {
            wsGetUserByID(props.history.location.state.usersIdEdit)
            initialValues.usersPhoneNo = normalizeInput(props.history.location.state.usersPhoneNoEdit)
        } else {
            wsGetAreaForDropdown()
            wsGetRoleForDropdown(2)
            wsGetPositionForDropdown(2)
            wsGetDepartmentForDropdown(2)
            wsGetCompanyBranchForDropdown(2)
        }
    }, props)

    const wsAddEditUser = (ID, CompanyID, FirstName, LastName, EmailAddress, PhoneNo, Territory, RoleID, Branch, PositionID,
        DepartmentID, AreaID, RegionID, TerritoryID, IsActive, UserType, UserLevelID) => {
        setIsLoading(true)

        APIController.BLAddEditUser(ID, CompanyID, FirstName, LastName, EmailAddress, PhoneNo, Territory, RoleID, Branch, PositionID,
            DepartmentID, AreaID, RegionID, TerritoryID, IsActive, UserType, UserLevelID)
            .then((response) => {
                setIsLoading(false)
                // handleNotification(response.object.message)
                if (response.error == null) {
                    if (isButtonValue === true) {
                        props.history.push({ pathname: '/users', state: { showMessage: response.object.message } })
                    }

                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const wsGetUserByID = (ID) => {
        APIController.GetUserByID(ID)
            .then((response) => {
                setLoadingRole(false)
                setLoadingArea(false)
                setLoadingRegion(false)
                setLoadingTerritory(false)
                setLoadingCompanyBranch(false)
                setLoadingDepartment(false)
                setLoadingPosition(false)
                if (response.error == null) {
                    initialValues.usersFirstname = response.object.data[0].FirstName
                    initialValues.usersLastname = response.object.data[0].LastName
                    initialValues.roleId = response.object.data[0].RoleID
                    initialValues.companyBranchId = response.object.data[0].CompanyBranchID
                    initialValues.positionsId = response.object.data[0].PositionID
                    initialValues.departmentId = response.object.data[0].DepartmentID
                    initialValues.areaId = response.object.data[0].AreaID
                    initialValues.regionId = response.object.data[0].RegionID
                    initialValues.territoryId = response.object.data[0].TerritoryID

                    setdataRoleDropdown(response.object.data[0].Roles != null ? JSON.parse(response.object.data[0].Roles) : []);

                    setdataPositionsDropdown(JSON.parse(response.object.data[0].Positions));
                    setdataDepartmentDropdown(JSON.parse(response.object.data[0].Departments));
                    setdataAreaDropdown(JSON.parse(response.object.data[0].Areas));
                    setdataCompanyBranchDropdown(JSON.parse(response.object.data[0].CompanyBranches));
                    if (response.object.data[0].Regions !== null) {
                        setdataRegionDropdown(JSON.parse(response.object.data[0].Regions));
                    }
                    if (response.object.data[0].Territories !== null) {
                        setdataTerritoryDropdown(JSON.parse(response.object.data[0].Territories));
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    // ToastsStore.warning(response.error.message)
                }
            })
    }

    const wsGetRoleForDropdown = (CompanyID) => {
        APIController.GetRoleForDropdown(CompanyID)
            .then((response) => {
                setLoadingRole(false)
                if (response.error == null) {
                    setdataRoleDropdown(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    // ToastsStore.warning(response.error.message)
                }
            })
    }

    const wsGetAreaForDropdown = (ID) => {
        APIController.GetAreaForDropdown(ID)
            .then((response) => {
                setLoadingArea(false)
                setLoadingRegion(false)
                setLoadingTerritory(false)
                if (response.error == null) {
                    setdataAreaDropdown(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    // ToastsStore.warning(response.error.message)
                }
            })
    }

    const wsGetRegionForDropdown = (AreaID) => {
        setLoadingRegion(true)
        APIController.GetRegionForDropdown(AreaID)
            .then((response) => {
                setLoadingRegion(false)
                if (response.error == null) {
                    setdataRegionDropdown(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    // ToastsStore.warning(response.error.message)
                }
            })
    }

    const wsGetTerritoryForDropdown = (RegionID) => {
        setLoadingTerritory(true)
        APIController.GetTerritoryForDropdown(RegionID)
            .then((response) => {
                setLoadingTerritory(false)
                if (response.error == null) {
                    setdataTerritoryDropdown(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    // ToastsStore.warning(response.error.message)
                }
            })
    }

    const wsGetPositionForDropdown = (CompanyID) => {
        APIController.GetPositionForDropdown(CompanyID)
            .then((response) => {
                setLoadingPosition(false)
                if (response.error == null) {
                    setdataPositionsDropdown(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    // ToastsStore.warning(response.error.message)
                }
            })
    }

    const wsGetDepartmentForDropdown = (CompanyID) => {
        APIController.GetDepartmentForDropdown(CompanyID)
            .then((response) => {
                setLoadingDepartment(false)
                if (response.error == null) {
                    setdataDepartmentDropdown(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    // ToastsStore.warning(response.error.message)
                }
            })
    }

    const wsGetCompanyBranchForDropdown = (CompanyID) => {
        APIController.GetCompanyBranchForDropdown(CompanyID)
            .then((response) => {
                setLoadingCompanyBranch(false)
                if (response.error == null) {
                    setdataCompanyBranchDropdown(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    // ToastsStore.warning(response.error.message)
                }
            })
    }

    const normalizeInput = (value, previousValue) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;
        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 4) return currentValue;
            if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
    };

    return (
        <>
            <section className="content-right-main">
                {props.history.location.state.usersIdEdit == '0' ? <h1> Add Users </h1> : <h1>Edit Users</h1>}
                <Formik
                    initialValues={initialValues}
                    validationSchema={validateUsersForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        if (!values.usersEmailAddress.trim().match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                            handleNotification('Please Enter Valid Email Address', true)
                            document.getElementById('usersEmailAddress').focus();
                        } else if (values.usersPhoneNo.length < 10) {
                            handleNotification('Please Enter Valid Phone number', true)
                            document.getElementById('usersPhoneNo').focus();
                        } else if (!values.usersPhoneNo.match(/^[0-9]+$/)) {
                            handleNotification("Please enter Phone No only number.", true);
                            document.getElementById('usersPhoneNo').focus();
                        } else {
                            wsAddEditUser(props.history.location.state.usersIdEdit, "2", values.usersFirstname, values.usersLastname, values.usersEmailAddress,
                                values.usersPhoneNo, "", values.roleId, values.companyBranchId, values.positionsId,
                                values.departmentId, values.areaId, values.regionId, values.territoryId, values.active, "2", userLevelID)
                            if (isButtonValue === false) {
                                values.usersFirstname = "";
                                values.usersLastname = "";
                                values.usersEmailAddress = "";
                                values.usersPhoneNo = "";
                                values.active = 1;
                            }
                        }
                    }} >
                    {({
                        values,
                        handleSubmit,
                        setFieldValue,
                        errors,
                        touched,
                    }) => {
                        return (
                            <div className="form-main">
                                <form onSubmit={handleSubmit}>
                                    <div className={`form_field ${errors && touched.usersFirstname && errors.usersFirstname && errors.usersFirstname !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>First Name</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter First Name" id={'usersFirstname'} name="usersFirstname" value={values.usersFirstname} maxLength="50" />
                                            {errors && touched.usersFirstname && errors.usersFirstname && errors.usersFirstname !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='usersFirstname' render={(err) => { return err; }} />
                                                </span>)}
                                        </div>
                                    </div>
                                    <div className={`form_field ${errors && touched.usersLastname && errors.usersLastname && errors.usersLastname !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>Last Name</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Last Name" id={'usersLastname'} name="usersLastname" value={values.usersLastname} maxLength="50" />
                                            {errors && touched.usersLastname && errors.usersLastname && errors.usersLastname !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='usersLastname' render={(err) => { return err; }} />
                                                </span>)}
                                        </div>
                                    </div>
                                    <div className={`form_field ${errors && touched.usersEmailAddress && errors.usersEmailAddress && errors.usersEmailAddress !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>Email Address</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Email Address" id={'usersEmailAddress'} name="usersEmailAddress" value={values.usersEmailAddress} maxLength="50" />
                                            {errors && touched.usersEmailAddress && errors.usersEmailAddress && errors.usersEmailAddress !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='usersEmailAddress' render={(err) => { return err; }} />
                                                </span>)}
                                        </div>
                                    </div>
                                    <div className={`form_field ${errors && touched.usersPhoneNo && errors.usersPhoneNo && errors.usersPhoneNo !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>PhoneNo</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Phone No" id={'usersPhoneNo'} name="usersPhoneNo" value={values.usersPhoneNo} maxLength="14"
                                                onChange={(e) => setFieldValue('usersPhoneNo', normalizeInput(e.target.value))} />
                                            {errors && touched.usersPhoneNo && errors.usersPhoneNo && errors.usersPhoneNo !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='usersPhoneNo' render={(err) => { return err; }} />
                                                </span>)}
                                        </div>
                                    </div>

                                    <div className={`form_field ${errors && touched.roleId && errors.roleId && errors.roleId !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>Role</label>
                                        </div>
                                        <div className="field-right">
                                            {LoadingRole ? <CircularProgress style={{ alignItems: 'center' }} />
                                                : <select name='roleId'
                                                    defaultValue={values.roleId} value={values.roleId} onChange={(e) => { setFieldValue('roleId', e.target.value); }}>
                                                    <option value={''}>Select Role</option>
                                                    {dataRoleDropdown.map((obj, index) => {
                                                        return <option value={obj.ID} key={index}>{obj.Name}</option>
                                                    })}
                                                </select>}
                                            {errors && touched.roleId && errors.roleId && errors.roleId !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='roleId' render={(err) => { return err; }} />
                                                </span>)}
                                        </div>
                                    </div>
                                    <div className={`form_field ${errors && touched.companyBranchId && errors.companyBranchId && errors.companyBranchId !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>Company Branch</label>
                                        </div>
                                        <div className="field-right">
                                            {LoadingCompanyBranch ? <CircularProgress style={{ alignItems: 'center' }} />
                                                : <select name='companyBranchId' defaultValue={values.companyBranchId} value={values.companyBranchId} onChange={(e) => { setFieldValue('companyBranchId', e.target.value); }}>
                                                    <option value={''}>Select Company Branch</option>
                                                    {dataCompanyBranchDropdown.map((obj, index) => {
                                                        return <option value={obj.ID} key={index}>{obj.Name}</option>
                                                    })}
                                                </select>}
                                            {errors && touched.companyBranchId && errors.companyBranchId && errors.companyBranchId !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='companyBranchId' render={(err) => { return err; }} />
                                                </span>)}
                                        </div>
                                    </div>
                                    <div className={`form_field ${errors && touched.positionsId && errors.positionsId && errors.positionsId !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>Position</label>
                                        </div>
                                        <div className="field-right">
                                            {LoadingPosition ? <CircularProgress style={{ alignItems: 'center' }} />
                                                : <select name='positionsId' defaultValue={values.positionsId} value={values.positionsId} onChange={(e) => { setFieldValue('positionsId', e.target.value); }}>
                                                    <option value={''}>Select Position</option>
                                                    {dataPositionsDropdown.map((obj, index) => {
                                                        return <option value={obj.ID} key={index}>{obj.Name}</option>
                                                    })}
                                                </select>}
                                            {errors && touched.positionsId && errors.positionsId && errors.positionsId !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='positionsId' render={(err) => { return err; }} />
                                                </span>)}
                                        </div>
                                    </div>
                                    <div className={`form_field ${errors && touched.departmentId && errors.departmentId && errors.departmentId !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>Department</label>
                                        </div>
                                        <div className="field-right">
                                            {LoadingDepartment ? <CircularProgress style={{ alignItems: 'center' }} />
                                                : <select name='departmentId' defaultValue={values.departmentId} value={values.departmentId} onChange={(e) => { setFieldValue('departmentId', e.target.value); }}>
                                                    <option value={''}>Select Department</option>
                                                    {dataDepartmentDropdown.map((obj, index) => {
                                                        return <option value={obj.ID} key={index}>{obj.Name}</option>
                                                    })}
                                                </select>}
                                            {errors && touched.departmentId && errors.departmentId && errors.departmentId !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='departmentId' render={(err) => { return err; }} />
                                                </span>)}
                                        </div>
                                    </div>
                                    <div className={`form_field ${errors && touched.areaId && errors.areaId && errors.areaId !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>Area</label>
                                        </div>
                                        <div className="field-right">
                                            {LoadingArea ? <CircularProgress style={{ alignItems: 'center' }} />
                                                : <select name='areaId' defaultValue={values.areaId} value={values.areaId} onChange={(e) => { wsGetRegionForDropdown(e.target.value); setFieldValue('areaId', e.target.value); }}>
                                                    <option value={''}>Select Area</option>
                                                    {dataAreaDropdown.map((obj, index) => {
                                                        return <option value={obj.ID} key={index}>{obj.Name}</option>
                                                    })}
                                                </select>}
                                            {errors && touched.areaId && errors.areaId && errors.areaId !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='areaId' render={(err) => { return err; }} />
                                                </span>)}
                                        </div>
                                    </div>
                                    <div className='form_field'>
                                        <div className="field-left">
                                            <label>Region</label>
                                        </div>
                                        <div className="field-right">
                                            {LoadingRegion ? <CircularProgress style={{ alignItems: 'center' }} />
                                                : <select name='regionId' defaultValue={values.regionId} value={values.regionId}
                                                    onChange={(e) => {
                                                        wsGetTerritoryForDropdown(e.target.value)
                                                        setUserLevelID(2)
                                                        setFieldValue('regionId', e.target.value);
                                                    }}>
                                                    <option value={''}>Select Region</option>
                                                    {dataRegionDropdown.map((obj, index) => {
                                                        return <option value={obj.ID} key={index}>{obj.Name}</option>
                                                    })}
                                                </select>}
                                        </div>
                                    </div>

                                    <div className='form_field'>
                                        <div className="field-left">
                                            <label>Territory</label>
                                        </div>
                                        <div className="field-right">
                                            {LoadingTerritory ? <CircularProgress style={{ alignItems: 'center' }} />
                                                : <select name='territoryId' defaultValue={values.territoryId} value={values.territoryId}
                                                    onChange={(e) => {
                                                        setUserLevelID(3)
                                                        setFieldValue('territoryId', e.target.value);
                                                    }}>
                                                    <option value={''}>Select Territory</option>
                                                    {dataTerritoryDropdown.map((obj, index) => {
                                                        return <option value={obj.ID} key={index}>{obj.Name}</option>
                                                    })}
                                                </select>}
                                        </div>
                                    </div>

                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Is Active ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="yes-text"
                                                    defaultValue={values.active}
                                                    defaultChecked={values.active != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 1)} />
                                                <label htmlFor="yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="no-text"
                                                    defaultValue={values.active}
                                                    defaultChecked={values.active == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 0)} />
                                                <label htmlFor="no-text">No</label>
                                            </div>
                                        </div>
                                    </div>

                                    <Notification
                                        isError={isError}
                                        message={message}
                                        showNotification={showNotification}
                                        clearNotification={handleClearNotification.bind(this)}
                                        closeNotification={handleClearNotification.bind(this)}
                                    />
                                    <div className="form-submit">
                                        <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" onClick={() => setButtonValue(true)} />
                                        </div>
                                        {props.history.location.state.usersIdEdit == 0 ?
                                            <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                                <input type="submit" value={IsLoading ? "Saving..." : "save & add new"} name="save & add new" onClick={() => setButtonValue(false)} />
                                            </div> : null}
                                        <div className="button-field ">
                                            <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                        </div>
                                    </div>

                                </form>
                            </div>)
                    }}
                </Formik>
            </section>
        </>
    )
}
const validateUsersForm = yup.object().shape({
    usersFirstname: yup.string().required('First Name is required'),
    usersLastname: yup.string().required('Last Name is required'),
    usersEmailAddress: yup.string().required('EmailAddress is required'),
    usersPhoneNo: yup.string().required('PhoneNo is required'),
    roleId: yup.string().required('Role is required'),
    companyBranchId: yup.string().required('Company Branch is required'),
    positionsId: yup.string().required('Positions is required'),
    departmentId: yup.string().required('Department is required'),
    areaId: yup.string().required('Area is required'),
});
export default UserForm;

