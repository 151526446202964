/*eslint-disable eqeqeq*/
import React, { Component } from "react";
import * as AppConstants from '../../../config/AppConstant';

class ProductTabSelection extends Component {
	constructor() {
		super();
		this.state = {
			ActiveTab: 0,
		};
	}

	render() {
		return (
			<div>
				<section style={{ marginTop: '20px' }}>
					<div className="tab-listing">
						<ul>
							{this.props.ChildrenTabs.map((item, index) => (
								<li>
									<a style={{ cursor: 'pointer' }} title="product details"
										className={`${this.props.INDEX == index && window.location.href.indexOf(item.PageURL) > 0 ? 'active' : ' '}`}
										onClick={() => {
											this.props.history.push({
												pathname: item.PageURL,
												state: { Index: index }
											})
											AppConstants.SetLocalStorage("localStorageProductID", item.ID)
											AppConstants.SetLocalStorage("localStorageMenuPath", item.MenuPath)
											AppConstants.SetLocalStorage("localStorageMultiSelectPractice", item.AllowMultiSelectPractice)
											AppConstants.SetLocalStorage("localStorageAssetTabName", item.TabName)
										}}
									>{item.TabName}</a>
								</li>
							))}
						</ul>
					</div>
				</section>
			</div>
		);
	}
}

export default ProductTabSelection;