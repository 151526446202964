import React from 'react';
import TableCell from '@material-ui/core/TableCell';


const TableCellComponent = (props) => {
    return (
        <TableCell align={props.align || 'inherit'}
        style={props.style || null} 
        colSpan={props.colSpan}
    
        classes={props.classes} className={props.className} padding={props.padding || 'default'}
        size={props.size || 'medium'} sortDirection={props.sortDirection || 'asc'} variant={props.variant} >
            {props.children}
        </TableCell>
    )
}

export default TableCellComponent