/*eslint-disable eqeqeq*/
import React from "react"
import * as APIController from "../../../api/APIController.js";
import CircularProgress from '@material-ui/core/CircularProgress';
import authService from "../../../shared/services/auth-service";
import * as AppConstants from "../../../config/AppConstant";

const getQueryParams = (query = null) => (query || window.location.search.replace('?', '')).split('&').map(e => e.split('=').map(decodeURIComponent)).reduce((r, [k, v]) => (r[k] = v, r), {});

export default class VerifySSO extends React.Component {
    componentDidMount() {
        if (this.props.location.search != "") {
            const Params = getQueryParams();
            if (Params.access_token == undefined || Params.jwt == undefined) {
                this.props.history.goBack()
            } else {
                sessionStorage.setItem('Access_Token', Params.access_token)
                this.wsVerifySSO(Params.jwt)
            }
        } else {
            this.props.history.goBack()
        }
    }

    wsVerifySSO(JWT) {
        this.setState({ progress: true, });
        APIController.VerifySSO(JWT).then((response) => {
            this.setState({ IsLoading: false });
            if (response.error == null) {
                if (response.object.status == 1) {
                    sessionStorage.setItem("Token", "");

                    authService.setAuthData('response');

                    AppConstants.SetLocalStorage("sideBarMenuAdmin", 1);

                    const jwt_decode = require("jwt-decode");
                    var decoded = jwt_decode(JWT);

                    AppConstants.SetLocalStorage("profilelogo", decoded.ImageURL);
                    if (decoded.IsCoOpEligible != 0) {
                        AppConstants.SetLocalStorage("headerMenuCoOp", 1);
                        AppConstants.SetLocalStorage("headerMenuCoOpAmount", decoded.CoOpAmount);
                    } else {
                        AppConstants.SetLocalStorage("headerMenuCoOp", 0);
                    }
                    // if (decoded.LeftNav != []) {
                    AppConstants.SetLocalStorage("headerMenuAdmin", 0);

                    // Navigation And Get SideBar Data
                    this.wsGetNav(decoded)

                } else {
                    this.props.history.goBack()
                }
            }
            else if (response.error.message) {
            }
        });
    }

    wsGetNav(decoded) {
        APIController.GetNav()
            .then((response) => {
                if (response.error == null) {
                    AppConstants.SetLocalStorage('GetNav', JSON.stringify(response));
                    this.setState({ IsLoading: false });

                    //Navigate home Screen When Api Call is Done
                    AppConstants.SetLocalStorage('localStorageBrandID', response.object.data.BrandsNav[0].BrandID);
                    AppConstants.SetLocalStorage('localStorageBrandName', response.object.data.BrandsNav[0].BrandName);
                    AppConstants.SetLocalStorage('localStorageCampaignID', JSON.parse(response.object.data.BrandsNav[0].Campaigns)[0].CampaignID);
                    AppConstants.SetLocalStorage('localStorageCampaignName', JSON.parse(response.object.data.BrandsNav[0].Campaigns)[0].CampaignName);

                    if (AppConstants.GetMainClassName() == "app-main") {
                        this.props.history.push("/home");
                    } else {
                        this.props.history.push("/digital-product/selection");
                    }

                }
            }).catch((err) => { })
    }

    render() {
        return (
            <div style={{ maxHeight: '1000px', height: '900px', width: '100%', background: '#FFF' }}>
                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <img src={AppConstants.HeaderLogo} alt={AppConstants.HeaderLogo} style={{ height: '250px', width: '300px' }} />
                    <CircularProgress style={{ color: '#f57e20', justifySelf: 'center', height: '65px', width: '65px' }} />
                </div>
            </div>
        )
    }
}