import React from "react";
import * as APIController from "../../../api/APIController.js";
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";
import "./welcome-screen.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import * as AppConstants from "../../../config/AppConstant";
import ProductTabSelection from './product-tab-selection';

import ModalComponent from "../../../shared/components/modal/modal";
import close_icon from "../../../assets/images/gray-close-ic.svg";
import MyiFrame from "../../../features/campaign/components/myiFrame";

const SocialAutomation = (props) => {
	const [Description, setDescription] = React.useState('');
	const [EditDescription, setEditDescription] = React.useState('');
	const [PreviewImageURL, setPreviewImageURL] = React.useState('');
	const [Loading, setLoading] = React.useState(true)
	const [ProductImages, setProductImages] = React.useState([]);
	const [ProductImagesForUpdate, setProductImagesForUpdate] = React.useState([]);
	const [ChildrenTabs, setChildrenTabs] = React.useState([]);
	const [PageTitle, setPageTitle] = React.useState(false);
	const [duration, setDuration] = React.useState('');
	const [PreviewModalOpen, setPreviewModalOpen] = React.useState(false);

	React.useEffect(() => {
		let data = AppConstants.GetLocalStorage("localStorageDuration") != '' && JSON.parse(AppConstants.GetLocalStorage("localStorageDuration"));
		if (AppConstants.GetLocalStorage('IsDirectPageURL') != ""
			&& AppConstants.GetLocalStorage('IsDirectPageURL') != undefined
			&& AppConstants.GetLocalStorage('IsDirectPageURL') != null) {
			setPageTitle(JSON.parse(AppConstants.GetLocalStorage('IsDirectPageURL')))
		}

		wsGetProductByID(AppConstants.GetLocalStorage('localStorageProductID'))

		if (AppConstants.GetLocalStorage('localStorageChildren') != ""
			&& AppConstants.GetLocalStorage('localStorageChildren') != undefined
			&& AppConstants.GetLocalStorage('localStorageChildren') != null) {
			setChildrenTabs(JSON.parse(AppConstants.GetLocalStorage('localStorageChildren')))
		} else {
			setChildrenTabs([])
		}

		setDuration(data != '' ? data[0].Duration : '')

	}, [props]);

	const wsGetProductByID = (ID) => {
		APIController.GetProductByID(ID)
			.then((response) => {
				if (response.error == null) {
					setDescription(response.object.data[0].Description)
					setEditDescription(response.object.data[0].EditDescription)
					setPreviewImageURL(response.object.data[0].PreviewURL)
					wsGetProductImages(response.object.data[0].ID, AppConstants.GetLocalStorage('localStorageBrandID'))
				}
			})
	}

	const wsGetProductImages = (ID, BrandID) => {
		APIController.GetProductImages(ID, BrandID)
			.then((response) => {
				if (response.error == null) {
					setProductImages(response.object.data.filter((obj) => { return obj.IsForUpdate == false && obj }))
					setProductImagesForUpdate(response.object.data.filter((obj) => { return obj.IsForUpdate == true && obj }))
				}
				setLoading(false)
			})
	}

	const settings = {
		dots: false,
		infinite: true,
		speed: 3000,
		slidesToShow: 1,
		slidesToScroll: 1,
		nav: false,
		arrows: false,
		autoplay: true,
		customPaging: i => (<div style={{ height: 10, width: 10, borderRadius: 50, backgroundColor: '#3232' }}></div>)
	};

	return (
		<section className='content-right-main'>
			{PageTitle == "3"
				? <div className="content-right-heading">
					{AppConstants.ContactDetais.UpdateSubscription}
				</div>
				: PageTitle
					? <div className="content-right-heading">
						{AppConstants.GetLocalStorage('localStorageProductName')}
					</div>
					: <div className="content-right-heading">
						{/* {AppConstants.GetLocalStorage('localStorageCampaignName').split('®')[0]}
						{AppConstants.GetLocalStorage('localStorageCampaignName').match(/®/g) ? <sup>&reg;</sup> : ''}
						{AppConstants.GetLocalStorage('localStorageCampaignName').split('®')[1]}{': '} */}
						{AppConstants.GetLocalStorage('localStorageProductName')}
					</div>}
			{ChildrenTabs.length > 1 &&
				<ProductTabSelection history={props.history} ChildrenTabs={ChildrenTabs} INDEX={props.history.location.state != undefined ? props.history.location.state.Index : 0} />}

			<div className='two-col-sec digital-welcome'>
				{Loading ? <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}><CircularProgress /></div>
					: <>
						<div className='sec-left'>
							<div style={{ letterSpacing: '0px' }}>
								<div dangerouslySetInnerHTML={{ __html: PageTitle == "3" ? EditDescription : Description }} />
							</div>
							<div className='button-field' style={{ marginTop: "40px", marginLeft: "-1%" }}>
								<button onClick={() => {
									props.history.push("/digital-product/selection/location-selection")
									AppConstants.SetLocalStorage("localStorageBudgetDetail", JSON.stringify([{ "budget": 1, "duration": duration }]))
									AppConstants.SetLocalStorage("localStorageAutomation", "YES")
								}}
								>{AppConstants.ButtonText.GetStarted}</button>
							</div>
						</div>
						<div className="sec-right" style={{ padding: '0px', margin: '0px', zIndex: '0' }} >
							<div className='image-slider' >
								<Slider  {...settings}>
									{PageTitle == "3" ?
										!!ProductImagesForUpdate.length ? ProductImagesForUpdate.map((obj, index) => {
											return (<>
												<img style={{ maxHeight: '700px' }} src={obj.ImageURL} alt='slider-2' />
												{PreviewImageURL != null && PreviewImageURL != undefined && PreviewImageURL != "" && <div style={{ display: 'flex', justifyContent: 'center' }}>
													<label style={{ cursor: 'pointer', color: '#1073c6', textDecoration: 'underline' }} onClick={() => setPreviewModalOpen(true)} >PREVIEW SOCIAL CALENDAR</label>
												</div>}
											</>)
										}) : ''
										: !!ProductImages.length ? ProductImages.map((obj, index) => {
											return (<>
												<img style={{ maxHeight: '700px' }} src={obj.ImageURL} alt='slider-2' />
												{PreviewImageURL != null && PreviewImageURL != undefined && PreviewImageURL != "" && <div style={{ display: 'flex', justifyContent: 'center' }}>
													<label style={{ cursor: 'pointer', color: '#1073c6', textDecoration: 'underline' }} onClick={() => setPreviewModalOpen(true)} >PREVIEW SOCIAL CALENDAR</label>
												</div>}
											</>)
										}) : ''}
								</Slider>
							</div>
						</div>
					</>}
			</div>

			{PreviewModalOpen && (
				<ModalComponent show={PreviewModalOpen} handleClose={() => setPreviewModalOpen(false)}>
					<div className="popup-wrap">
						<div style={{ display: 'grid' }}>
							<div className='box-head' style={{ marginBottom: '5px', padding: '8px' }}>
								<h4>{AppConstants.ButtonText.Preview}</h4>
							</div>
							{PreviewImageURL != null || PreviewImageURL != undefined || PreviewImageURL != "" ?
								PreviewImageURL.indexOf('.pdf') != -1
									? <MyiFrame isFacbook={true} Height={'500px'} Width={'745px'} src={PreviewImageURL + "?" + Math.floor(Math.random() * (999999 - 100000 + 1) + 100000)} />
									: PreviewImageURL.indexOf('.mp4') != -1
										? !navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')
											? <video controls width="250" autoPlay >
												<source src={PreviewImageURL} type="video/mp4" />
											</video>
											: <MyiFrame Height={'450px'} Width={'500px'} src={PreviewImageURL} />
										: PreviewImageURL.indexOf('.gif') != -1 ?
											<img src={PreviewImageURL} alt="place" style={{ height: '550px' }} />
											: <img src={PreviewImageURL} alt="img" />
								: <></>
							}
						</div>
						<a title="" className="gray-close-ic">
							<img src={close_icon} alt="icon" onClick={() => setPreviewModalOpen(false)} />
						</a>
					</div>
				</ModalComponent>)}
		</section>
	);
};

export default SocialAutomation;
