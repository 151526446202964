/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'
import * as APIController from '../../../../api/APIController.js';
import * as yup from 'yup';

import uuid from 'react-uuid';
import file_upload_ic from "../../../../assets/images/file-upload-ic.svg";
import close_icon from "../../../../assets/images/close_icon.svg";
import CircularProgress from '@material-ui/core/CircularProgress';

import Editor from '../../../campaign/components/editor.jsx';

import Notification from '../../../../shared/components/notification/notification.jsx';

const WelcomeInfoForm = props => {
    const initialValues = {
        title: props.history.location.state.titleEdit,
        segmentID: props.history.location.state.segmentEdit,
        imageName: props.history.location.state.imageDataEdit.split('?')[0].split('/').pop(),
        imageData: props.history.location.state.imageDataEdit,
        imagePreview: props.history.location.state.imageDataEdit
    }
    const [isButtonValue, setButtonValue] = React.useState(false);
    const [IsLoading, setIsLoading] = React.useState(false);
    const [IsLoadingData, setIsLoadingData] = React.useState(true);
    const [description, setDescription] = React.useState('');
    const [segmentData, setSegmentData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        wsGetWelcomePopupSegmentForDropdown()
        setDescription(props.history.location.state.descriptionEdit)
        setIsLoadingData(false)
    }, [props])

    const wsGetWelcomePopupSegmentForDropdown = () => {
        APIController.GetWelcomePopupSegmentForDropdown()
            .then((response) => {
                if (response.error == null) {
                    setSegmentData(response.object.data)
                } else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
                setLoading(false)
            })
    }
    const wsGetWelcomePopupByID = (ID) => {
        APIController.GetWelcomePopupByID(ID)
            .then((response) => {
                if (response.error == null) {
                    initialValues.title = response.object.data[0].Title
                    initialValues.segmentID = response.object.data[0].SegmentID
                    initialValues.imageData = response.object.data[0].ImageURL.split('?')[0].split('/').pop()
                    setDescription(response.object.data[0].Description)
                } else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
                setLoading(false)
            })
    }

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => { handleClearNotification(); }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    const wsAddEditWelcomePopup = (ID, Name, SegmentID, Description, ImageName, ImageData) => {
        setIsLoading(true)
        APIController.AddEditWelcomePopup(ID, Name, SegmentID, Description, ImageName, ImageData)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    if (isButtonValue === true && response.object.status == 1) {
                        props.history.push({ pathname: '/welcomeInfo', state: { showMessage: response.object.message } })
                    } else if (response.object.status == 1) {
                        handleNotification(response.object.message);
                    } else {
                        handleNotification(response.object.message, true);
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    return (
        <>
            <section className="content-right-main">
                <div className="content-right-heading">{props.history.location.state.idEdit != '0' ? "Edit Welcome Info" : "Add Welcome Info"}</div>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validateProductForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        if (description == '') {
                            handleNotification("Description is required", true);
                        } else {
                            wsAddEditWelcomePopup(props.history.location.state.idEdit, values.title, values.segmentID, description, values.imageName, values.imageData)
                            if (isButtonValue === false) {
                                values.title = "";
                                values.imagePreview = "";
                                values.imageName = "";
                                values.imageData = "";
                                values.segmentID = "";
                                setDescription('')
                            }
                        }
                    }} >
                    {({ values, handleSubmit, setFieldValue, errors, touched, }) => {
                        return (
                            <div className="form-main">
                                <form onSubmit={handleSubmit}>
                                    <div className={`form_field ${errors && touched.title && errors.title && errors.title !== '' ? 'error' : ''}`}>
                                        <div className="field-left"> <label>Title</label> </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Title" name="title" value={values.title} />
                                            {errors && touched.title && errors.title && errors.title !== ''
                                                && (<span className='error-msg'>
                                                    <ErrorMessage name='title' render={(err) => { return err; }} />
                                                </span>)}
                                        </div>
                                    </div>

                                    {loading
                                        ? <CircularProgress style={{ alignItems: 'center' }} />
                                        : <div className={`form_field ${errors && touched.segmentID && errors.segmentID && errors.segmentID !== '' ? 'error' : ''}`}>
                                            <div className="field-left"> <label>Segment </label> </div>
                                            <div className="field-right">
                                                <select id={'segmentID'}
                                                    name="reportType"
                                                    defaultValue={values.segmentID}
                                                    value={values.segmentID}
                                                    onChange={(e) => setFieldValue('segmentID', e.target.value)}>
                                                    <option value={''}>Segment </option>
                                                    {segmentData.map((obj, index) => {
                                                        return <option selected={obj.ID == values.segmentID ? obj.ID : obj.ID} value={obj.ID} key={index}>{obj.Name}</option>
                                                    })}
                                                </select>
                                                {errors && touched.segmentID && errors.segmentID && errors.segmentID !== ''
                                                    && (<span className='error-msg'>
                                                        <ErrorMessage name='segmentID' render={(err) => { return err; }} />
                                                    </span>)}
                                            </div>
                                        </div>}

                                    {IsLoadingData ? <CircularProgress style={{ alignItems: 'center' }} />
                                        : <div className='form_field'>
                                            <label style={{ marginBottom: '-40px' }}>Description</label>
                                            <div className="asset-full" style={{ padding: '0px', marginBottom: '-0px' }}>
                                                <Editor content={description} setContent={setDescription} />
                                            </div>
                                        </div>
                                    }
                                    <div className={`form_field ${errors && touched.imagePreview && errors.imagePreview && errors.imagePreview !== '' ? 'error' : ''}`}>
                                        <div className='field-left'> <label>Image</label> </div>
                                        <div className='field-right'>
                                            <div className='form-file-input'>
                                                <input className='input-file' id='fileupload' name='files' type='file' accept=".svg,.png,.jpeg,.jpg,.mp4"
                                                    onChange={(e) => {
                                                        const file = e.target.files[0];
                                                        if (file && (file.type.includes("svg") || file.type.includes("png") || file.type.includes("jpeg") || file.type.includes("jpg") || file.type.includes("mp4"))) {
                                                            let file1 = e.target.files[0]
                                                            file1['preview'] = URL.createObjectURL(e.target.files[0]);

                                                            setFieldValue("imageName", values.imageName == ""
                                                                ? uuid() + "." + file.type.split('/')[1]
                                                                : values.imageName.split('.')[0] + "." + file.type.split('/')[1]);

                                                            const image2base64 = require('image-to-base64');
                                                            image2base64(file1.preview)
                                                                .then((response) => { setFieldValue("imageData", response); })
                                                                .catch((error) => { })

                                                            setFieldValue("imagePreview", file1.preview);
                                                        }
                                                    }}
                                                />
                                                {errors && touched.imagePreview && errors.imagePreview && errors.imagePreview !== ''
                                                    && (<span className='error-msg'>
                                                        <ErrorMessage name='imagePreview' render={(err) => { return err; }} />
                                                    </span>)}
                                                {values.imagePreview == ''
                                                    ? <label htmlFor='fileupload' className='input-file-trigger' style={{ display: 'flex' }}>
                                                        <img src={file_upload_ic} alt='file-uplaod' />
                                                        {'Drag and drop or Click here to upload file'}
                                                    </label>
                                                    : <div className='logo-preview'>
                                                        <img src={close_icon} alt="close-ic" className="close-ic" onClick={() => {
                                                            setFieldValue("imageName", '');
                                                            setFieldValue("imageData", '');
                                                            setFieldValue("imagePreview", '');
                                                        }} style={{ cursor: 'pointer', marginRight: '3px' }} />
                                                        <img src={values.imagePreview} alt='file-uplaod' />
                                                    </div>}
                                            </div>
                                            <p> Min image size: 650X800 px. Accepted files : .jpg, .jpeg, .png, .svg .mp4</p>
                                        </div>
                                    </div>

                                    <Notification
                                        isError={isError}
                                        message={message}
                                        showNotification={showNotification}
                                        clearNotification={handleClearNotification.bind(this)}
                                        closeNotification={handleClearNotification.bind(this)} />

                                    <div className="form-submit">
                                        <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" onClick={() => setButtonValue(true)} />
                                        </div>
                                        {props.history.location.state.idEdit == '0' ?
                                            <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                                <input type="submit" value={IsLoading ? "Saving..." : "save & add new"} name="save & add new" onClick={() => setButtonValue(false)} />
                                            </div> : null}
                                        <div className="button-field ">
                                            <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                        </div>
                                    </div>
                                </form>
                            </div>)
                    }}
                </Formik>
            </section>
        </>
    )
}
const validateProductForm = yup.object().shape({
    title: yup.string().required('Title is required'),
    segmentID: yup.string().required('Segment # is required'),
    imagePreview: yup.string().required('Image is required'),
});

export default WelcomeInfoForm;
