import React from 'react'
import welcome_img from '../../../../assets/images/welcome-right-img.svg';
import '../../component/bioTrue.css'
import * as AppConsatnt from "../../../../config/AppConstant";

class CustomPrinting extends React.Component {
    componentDidMount() {
        this.props.history.push('/digital-product/selection/free-assets', {
            campaignIdEdit: this.props.history.location.state.campaignIdEdit,
            productIdEdit: this.props.history.location.state.productIdEdit,
            CampaignID: this.props.history.location.state.campaignIdEdit,
            brandIdEdit: this.props.history.location.state.brandIdEdit,
            campaignNameEdit: this.props.history.location.state.campaignNameEdit,
            CampaignHeader: this.props.history.location.state.CampaignHeader,
            FreeAssetHeader: this.props.history.location.state.FreeAssetHeader,
            ISDirectMail: 1
        })
    }
    render() {

        return (
            <>
                {/* <FreeAssets /> */}
                <section className="content-right-main">
                    <h1>Biotrue ONEday Custom Printing</h1>
                    <div className="two-col-sec digital-welcome">
                        <div className="sec-left">
                            <p>Now that you have selected a product, let's walk through a few steps to complete your order. </p>
                            <p>This includes selecting the locations you would like to include in your campaign, confirming your location information, selecting the details of your campaign, and approving your campaign artwork.</p>
                            <p>Let's get started.</p>
                            <div className="button-field">
                                <button>{AppConsatnt.ButtonText.GetStarted}</button>
                            </div>
                        </div>
                        <div className="sec-right">
                            <img src={welcome_img} alt="" />
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default CustomPrinting