/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'
import './SegmentsForm.css'
import * as APIController from '../../../../api/APIController';
import * as yup from 'yup';
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";
import Notification from '../../../../shared/components/notification/notification';

const SegmentsForm = props => {
    const initialValues = {
        Name: "", SequenceNo: "", IsDefault: props.history.location.state.SegmentIsDefaultEdit, active: props.history.location.state.SegmentActiveEdit
    }
    const [isButtonValue, setButtonValue] = React.useState(false);
    const [IsLoading, setIsLoading] = React.useState(false);
    const [IsLoadingData, setIsLoadingData] = React.useState(false);
    const [orderData, setOrderData] = React.useState([]);

    React.useEffect(() => {
        if (props.history.location.state.SegmentIdEdit != 0) {
            wsGetSegmentByID(props.history.location.state.SegmentIdEdit)
        }
        var orderSequenceNo = [];
        for (let i = 0; i < 100; i++) {
            orderSequenceNo.push({ ID: i + 1 });
        }
        setOrderData(orderSequenceNo)
    }, [props])

    const wsAddEditSegment = (ID, Name, SequenceNo, IsDirectMailAssetType, IsActive) => {
        setIsLoading(true)
        APIController.AddEditSegment(ID, Name, SequenceNo, IsDirectMailAssetType, IsActive)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    handleNotification(response.object.message);
                    if (isButtonValue === true) {
                        props.history.replace({
                            pathname: '/admin/segments',
                            state: { showMessage: response.object.message }
                        })
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const wsGetSegmentByID = (ID) => {
        setIsLoadingData(true)
        setIsLoading(true)
        APIController.GetSegmentByID(ID)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    initialValues.Name = response.object.data[0].Name
                    initialValues.SequenceNo = response.object.data[0].SequenceNo
                    setIsLoadingData(false)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }
    return (
        <>
            <section className="content-right-main">
                {props.history.location.state.SegmentIdEdit == 0 ? <h1>Add Segment</h1> : <h1>Edit Segment</h1>}
                <Formik
                    initialValues={initialValues}
                    validationSchema={validateSegmentIForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        wsAddEditSegment(props.history.location.state.SegmentIdEdit,
                            values.Name, values.SequenceNo, values.IsDefault, values.active)
                        if (isButtonValue === false) {
                            values.Name = "";
                            values.SequenceNo = "";
                            values.IsDefault = 0;
                            values.active = 1;
                        }
                    }} >
                    {({
                        values,
                        handleSubmit,
                        setFieldValue,
                        errors,
                        touched,
                    }) => {
                        return (
                            <div className="form-main">
                                <form onSubmit={handleSubmit}>
                                    {IsLoadingData ?
                                        <CircularProgress /> :
                                        <div>
                                            <div
                                                className={`form_field ${errors &&
                                                    touched.Name &&
                                                    errors.Name &&
                                                    errors.Name !== ''
                                                    ? 'error'
                                                    : ''
                                                    }`}>
                                                <div className="field-left">
                                                    <label>Name</label>
                                                </div>
                                                <div className="field-right">
                                                    <Field type="text" placeholder="Enter Name" name="Name" value={values.Name} maxLength="50" />
                                                    {errors &&
                                                        touched.Name &&
                                                        errors.Name &&
                                                        errors.Name !==
                                                        '' && (
                                                            <span className='error-msg'>
                                                                <ErrorMessage
                                                                    name='Name'
                                                                    render={(
                                                                        err
                                                                    ) => {
                                                                        return err;
                                                                    }}
                                                                />
                                                            </span>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="form_field">
                                                <div className="field-left">
                                                    <label>Sequence</label>
                                                </div>
                                                <div className="field-right">
                                                    <select
                                                        id={'sequenceno'}
                                                        name="SequenceNo"
                                                        defaultValue={values.SequenceNo}
                                                        value={values.SequenceNo}
                                                        onChange={(e) => setFieldValue('SequenceNo', e.target.value)}>
                                                        <option value={''}>Sequence #</option>
                                                        {orderData.map((obj, index) => {
                                                            return <option selected={obj.ID == values.SequenceNo ? obj.ID : obj.ID} value={obj.ID} key={index}>{obj.ID} </option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form_field">
                                                <div className="field-left">
                                                    <label>Is Default ?</label>
                                                </div>
                                                <div className="field-right">
                                                    <div className="custom-radio">
                                                        <input type="radio" name="IsDefault" id="IsDefault-yes-text"
                                                            defaultValue={values.IsDefault}
                                                            defaultChecked={values.IsDefault != 0 ? true : false}
                                                            onClick={(e) => setFieldValue('IsDefault', 1)} />
                                                        <label htmlFor="IsDefault-yes-text">Yes</label>
                                                    </div>
                                                    <div className="custom-radio">
                                                        <input type="radio" name="IsDefault" id="IsDefault-no-text"
                                                            defaultValue={values.IsDefault}
                                                            defaultChecked={values.IsDefault == 0 ? true : false}
                                                            onClick={(e) => setFieldValue('IsDefault', 0)} />
                                                        <label htmlFor="IsDefault-no-text">No</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form_field">
                                                <div className="field-left">
                                                    <label>Is Active ?</label>
                                                </div>
                                                <div className="field-right">
                                                    <div className="custom-radio">
                                                        <input type="radio" name="active" id="yes-text"
                                                            defaultValue={values.active}
                                                            defaultChecked={values.active != 0 ? true : false}
                                                            onClick={(e) => setFieldValue('active', 1)} />
                                                        <label htmlFor="yes-text">Yes</label>
                                                    </div>
                                                    <div className="custom-radio">
                                                        <input type="radio" name="active" id="no-text"
                                                            defaultValue={values.active}
                                                            defaultChecked={values.active == 0 ? true : false}
                                                            onClick={(e) => setFieldValue('active', 0)} />
                                                        <label htmlFor="no-text">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                    <Notification
                                        isError={isError}
                                        message={message}
                                        showNotification={showNotification}
                                        clearNotification={handleClearNotification.bind(this)}
                                        closeNotification={handleClearNotification.bind(this)}
                                    />
                                    <div className="form-submit">
                                        <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" onClick={() => setButtonValue(true)} />
                                        </div>
                                        {props.history.location.state.SegmentIdEdit == 0 ?
                                            <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                                <input type="submit" value={IsLoading ? "Saving..." : "save & add new"} name="save & add new" onClick={() => setButtonValue(false)} />
                                            </div> : null}
                                        <div className="button-field">
                                            <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )
                    }}
                </Formik>
            </section>
        </>
    )
}
const validateSegmentIForm = yup.object().shape({
    Name: yup.string().required('Name is required'),
    SequenceNo: yup.string().required('SequenceNo is required'),
});


export default SegmentsForm;