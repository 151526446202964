/*eslint-disable eqeqeq*/
import React from 'react';

import uuid from 'react-uuid';

import Editor from '../components/editor';
import * as APIController from "../../../api/APIController.js";
import Notification from '../../../shared/components/notification/notification';

import attachment_icon from "../../../assets/images/icon/attachment.png";
import close_icon from "../../../assets/images/close_icon.svg";

class EMainComponents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            showNotification: false,
            isError: false,
            emailTo: this.props.AutomationSubscriptionDetails.EmailAddress,
            emailCC: '',
            emailBCC: '',
            emailSubject: 'Bausch + Lomb Social Media Post',
            emailBody: `<br>--------------------------------------------------<br>Post copy:<br> ${this.props.AutomationSubscriptionDetails.Description}`,
            attachment1_Name: this.props.AutomationSubscriptionDetails.ImageURL.split('/')[5].split("?")[0],
            attachment1_Data: '',
            attachment1_Preview: this.props.AutomationSubscriptionDetails.ImageURL,
            attachment2_Name: '',
            attachment2_Data: '',
            attachment2_Preview: '',
            attachment3_Name: '',
            attachment3_Data: '',
            attachment3_Preview: '',
            AutomationSubscriptionDetailsID: this.props.AutomationSubscriptionDetails.ID
        }
    }

    componentDidMount = () => {
        const image2base64 = require('image-to-base64');
        image2base64(this.props.AutomationSubscriptionDetails.ImageURL)
            .then((response) => this.setState({ attachment1_Data: response }))
            .catch((error) => { })

    }

    wsEmailFlaggedSocialPost(AutomationSubscriptionID, AutomationSubscriptionDetailsID, EmailTo, EmailCC, EmailBCC, EmailSubject, EmailBody, Attachment1_ImageName, Attachment1_ImageData, Attachment2_ImageName, Attachment2_ImageData, Attachment3_ImageName, Attachment3_ImageData) {
        this.setState({ progress: true });
        APIController.EmailFlaggedSocialPost(AutomationSubscriptionID, AutomationSubscriptionDetailsID, EmailTo, EmailCC, EmailBCC, EmailSubject, EmailBody, Attachment1_ImageName, Attachment1_ImageData, Attachment2_ImageName, Attachment2_ImageData, Attachment3_ImageName, Attachment3_ImageData)
            .then((response) => {
                if (response.object.status == "1") {
                    this.handleNotification(response.object.message);
                    setTimeout(() => { this.props.handleCloseModel() }, 2000)
                } else {
                    this.handleNotification(response.object.message, true);
                }
                this.setState({ progress: false });
            });
    }

    handleValidate(AutomationSubscriptionID, AutomationSubscriptionDetailsID, EmailTo, EmailCC, EmailBCC, EmailSubject, EmailBody, Attachment1_ImageName, Attachment1_ImageData, Attachment2_ImageName, Attachment2_ImageData, Attachment3_ImageName, Attachment3_ImageData) {
        if (EmailTo.trim() == "") {
            this.handleNotification('Please Enter E-Mail', true)
        } else if (EmailSubject.trim() == "") {
            this.handleNotification('Please Enter E-Mail Subject', true)
        } else if (EmailBody.trim() == "") {
            this.handleNotification('Please Enter E-Mail Body', true)
        }
        else {
            this.wsEmailFlaggedSocialPost(AutomationSubscriptionID, AutomationSubscriptionDetailsID, EmailTo.trim(), EmailCC, EmailBCC, EmailSubject, EmailBody, Attachment1_ImageName, Attachment1_ImageData, Attachment2_ImageName, Attachment2_ImageData, Attachment3_ImageName, Attachment3_ImageData)
        }
    }

    handleNotification = (message, isError) => {
        this.setState({ message: message, showNotification: true, isError: isError });
        setTimeout(() => { this.handleClearNotification(); }, 4000);
    };
    handleClearNotification = () => { this.setState({ message: "", showNotification: false, isError: false, }); };


    render() {
        const { emailTo, emailCC, emailBCC, emailSubject, emailBody } = this.state;
        const { attachment1_Name, attachment1_Data, attachment1_Preview, attachment2_Name, attachment2_Data, attachment2_Preview, attachment3_Name, attachment3_Data, attachment3_Preview } = this.state;

        return (
            <div style={{ width: '100%' }}>
                <label>New E-Mail </label>
                <div style={{ border: '1px solid #CCCCCC', }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <label style={{ position: 'absolute', left: '30px', zIndex: 2 }}>To:</label>
                        <input style={{ position: 'relative', borderRadius: '0px', borderBottom: '1px solid #CCCCCC', padding: '8px 8px 8px 40px', fontSize: '13px' }}
                            type="text" name="email-To" value={this.state.emailTo} onChange={(e) => this.setState({ emailTo: e.target.value })} autoFocus />
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <label style={{ position: 'absolute', left: '30px', zIndex: 2 }}>CC:</label>
                        <input style={{ position: 'relative', borderRadius: '0px', borderBottom: '1px solid #CCCCCC', padding: '8px 8px 8px 40px', fontSize: '13px' }}
                            type="text" name="email-CC" value={this.state.emailCC} onChange={(e) => this.setState({ emailCC: e.target.value })} />
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <label style={{ position: 'absolute', left: '30px', zIndex: 2 }}>BCC:</label>
                        <input style={{ position: 'relative', borderRadius: '0px', borderBottom: '1px solid #CCCCCC', padding: '8px 8px 8px 50px', fontSize: '13px' }}
                            type="text" name="email-BCC" value={this.state.emailBCC} onChange={(e) => this.setState({ emailBCC: e.target.value })} />
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <label style={{ position: 'absolute', left: '30px', zIndex: 2 }}>Subject:</label>
                        <input style={{ position: 'relative', borderRadius: '0px', borderBottom: '1px solid #CCCCCC', padding: '8px 8px 8px 77px', fontSize: '13px' }}
                            type="text" name="email-Subject" value={this.state.emailSubject} onChange={(e) => this.setState({ emailSubject: e.target.value })} />
                    </div>

                    <div className="asset-full" style={{ padding: '0px', margin: '0 0 10px', height: '175px' }}>
                        <Editor content={emailBody} setContent={(e) => this.setState({ emailBody: e })} />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '40%' }}>
                            <div className='form-file-input'>
                                <input className='input-file' id='fileupload' name='files' type='file'
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        if (file && (file.type.includes("svg") || file.type.includes("png") || file.type.includes("jpeg") || file.type.includes("jpg") || file.type.includes("pdf") || file.type.includes("gif") || file.type.includes("doc") || file.type.includes("docx"))) {
                                            let file1 = e.target.files[0]
                                            file1['preview'] = URL.createObjectURL(e.target.files[0]);

                                            const image2base64 = require('image-to-base64');
                                            image2base64(file1.preview).then((response) => this.setState({ attachment1_Data: response })).catch((error) => { })
                                            this.setState({ attachment1_Name: uuid() + "." + file.type.split('/')[1], attachment1_Preview: file1.preview })
                                        }
                                    }} />
                                {attachment1_Preview == ''
                                    ? <img src={attachment_icon} alt='file-uplaod' />
                                    : <div className='logo-preview' style={{ marginTop: '0', padding: '5px' }}>
                                        <a title="" className="close-ic" style={{ width: '24px' }}><img src={close_icon} alt="close-ic" onClick={() => this.setState({ attachment1_Name: '', attachment1_Data: '', attachment1_Preview: '' })} /></a>
                                        <img src={attachment1_Preview} style={{ width: '100px', height: "100px" }} />
                                    </div>}
                            </div>
                            {/* <div className='form-file-input'>
                                <input disabled={attachment1_Preview == "" ? true : false} className='input-file' id='fileupload' name='files' type='file'
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        if (file && (file.type.includes("svg") || file.type.includes("png") || file.type.includes("jpeg") || file.type.includes("jpg") || file.type.includes("pdf") || file.type.includes("gif") || file.type.includes("doc") || file.type.includes("docx"))) {
                                            let file1 = e.target.files[0]
                                            file1['preview'] = URL.createObjectURL(e.target.files[0]);

                                            const image2base64 = require('image-to-base64');
                                            image2base64(file1.preview).then((response) => this.setState({ attachment2_Data: response })).catch((error) => { })
                                            this.setState({ attachment2_Name: uuid() + "." + file.type.split('/')[1], attachment2_Preview: file1.preview })
                                        }
                                    }} />
                                {attachment2_Preview == ''
                                    ? <img src={attachment_icon} alt='file-uplaod' />
                                    : <div className='logo-preview' style={{ marginTop: '0', padding: '5px' }}>
                                        <a title="" className="close-ic" style={{ width: '24px' }}><img src={close_icon} alt="close-ic" onClick={() => this.setState({ attachment2_Name: '', attachment2_Data: '', attachment2_Preview: '' })} /></a>
                                        <img src={attachment2_Preview} style={{ width: '100px', height: "100px" }} />
                                    </div>}
                            </div>
                            <div className='form-file-input'>
                                <input disabled={attachment2_Preview == "" || attachment1_Preview == "" ? true : false} className='input-file' id='fileupload' name='files' type='file'
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        if (file && (file.type.includes("svg") || file.type.includes("png") || file.type.includes("jpeg") || file.type.includes("jpg") || file.type.includes("pdf") || file.type.includes("gif") || file.type.includes("doc") || file.type.includes("docx"))) {
                                            let file1 = e.target.files[0]
                                            file1['preview'] = URL.createObjectURL(e.target.files[0]);

                                            const image2base64 = require('image-to-base64');
                                            image2base64(file1.preview).then((response) => this.setState({ attachment3_Data: response })).catch((error) => { })
                                            this.setState({ attachment3_Name: uuid() + "." + file.type.split('/')[1], attachment3_Preview: file1.preview })
                                        }
                                    }} />
                                {attachment3_Preview == ''
                                    ? <img src={attachment_icon} alt='file-uplaod' />
                                    : <div className='logo-preview' style={{ marginTop: '0', padding: '5px' }}>
                                        <a title="" className="close-ic" style={{ width: '24px' }}><img src={close_icon} alt="close-ic" onClick={() => this.setState({ attachment3_Name: '', attachment3_Data: '', attachment3_Preview: '' })} /></a>
                                        <img src={attachment3_Preview} style={{ width: '100px', height: "100px" }} />
                                    </div>}
                            </div> */}
                        </div>

                        <div className="button-field email-send-button">
                            <input type="button" value="Send" name="send" onClick={() => { this.handleValidate(this.props.AutomationSubscriptionID, this.state.AutomationSubscriptionDetailsID, emailTo, emailCC, emailBCC, emailSubject, emailBody, attachment1_Name, attachment1_Data, attachment2_Name, attachment2_Data, attachment3_Name, attachment3_Data) }} />
                        </div>
                    </div>
                </div>

                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    clearNotification={this.handleClearNotification}
                    closeNotification={this.handleClearNotification}
                />
            </div>)
    }
}
export default EMainComponents;