import CryptoJS from 'crypto-js';
const KEY = 'adsfghjkla2312safaaszAS';

/**
 * function to check if user is logged in or not
 */
const checkLogin = () => {
	if (sessionStorage.authData) {
		return true;
	} else {
		return false;
	}
};

/**
 * function to get user access token
 */
const getAccessToken = () => {
	try {
		const data = sessionStorage.authData;
		if (data) {
			const bytes = CryptoJS.AES.decrypt(data.toString(), KEY);
			const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
			return decryptedData && decryptedData.token
				? decryptedData.token
				: false;
		} else {
			return false;
		}
	} catch (e) {
		return false;
	}
};

/**
 * function to get user role
 */
const getUserRole = () => {
	const data = sessionStorage.authData;
	if (data) {
		const bytes = CryptoJS.AES.decrypt(data.toString(), KEY);
		const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
		return decryptedData && decryptedData.userType
			? decryptedData.userType
			: false;
	} else {
		return false;
	}
};

/**
 * function to get user id
 */
const getUserId = () => {
	const data = sessionStorage.authData;
	if (data) {
		const bytes = CryptoJS.AES.decrypt(data.toString(), KEY);
		const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
		return decryptedData && decryptedData.userId;
	} else {
		return null;
	}
};

/**
 * function to set user authentication data
 */
const setAuthData = (data) => {
	const cipherText = CryptoJS.AES.encrypt(JSON.stringify(data), KEY);
	sessionStorage.setItem('authData', cipherText.toString());
};

/**
 * function to get user authentication data
 */
const getAuthData = () => {
	const data = sessionStorage.authData;
	if (data) {
		const bytes = CryptoJS.AES.decrypt(data.toString(), KEY);
		const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
		return decryptedData;
	} else {
		return {};
	}
};

/**
 * function to remove user authentication data
 */
const removeAuthData = () => {
	sessionStorage.removeItem('authData');
};

const authService = {
	checkLogin: checkLogin,
	// getAccessToken: getAccessToken,
	// getUserRole: getUserRole,
	// getUserId: getUserId,
	setAuthData: setAuthData,
	getAuthData: getAuthData,
	removeAuthData: removeAuthData,
};

export default authService;
