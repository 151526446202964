/*eslint-disable eqeqeq*/
import React from "react";
import PostScheduleList from "./postscheduleList";
import * as APIController from "../../../../api/APIController.js";
import Notification from "../../../../shared/components/notification/notification";
import Modal from '../../../../shared/components/modal/modal';
import close_icon from "../../../../assets/images/gray-close-ic.svg";
import PageTitle from "../../../../config/pageTitle";
import TextField from "../../../../shared/components/TextField/TextField";
import moment from 'moment';
import MyiFrame from "../../../../features/campaign/components/myiFrame";

import refreshIcon from '../../../../assets/images/icon/refresh.svg'
import ConfirmtionDialog from '../../../ConfirmDialog/ConfirmationDialog';

class PostSchedule extends React.Component {
    state = {
        currentPage: 0,
        count: 0,
        rowsPerPage: 100,
        rowsPerPageOptions: [500, 100, 50, 25],
        list: [],
        list1: [],
        from: 0,
        ID: 0,
        to: 100,
        progress: true,
        message: "",
        showNotification: false,
        isError: false,
        showMessage:
            this.props.history.location.state != null &&
            this.props.history.location.state.showMessage,
        ScheduledDate: moment(new Date().toUTCString()).utcOffset(0, false).format('YYYY-MM-DD'),
        openRemarksDialog: false,
        RemarksText: '',
        RemarksImage: '',
        openPostDialog: false,
        SchedulePostID: '',
        PostTypeID: '',
        isLoading: false
    };

    componentDidMount() {
        this.wsGetScheduledPosts(this.state.ScheduledDate);
        this.state.showMessage != "" &&
            this.handleNotification(this.state.showMessage);
        this.props.history.replace("/subscription/postschedule", null);
    }

    wsGetScheduledPosts(ScheduledDate) {
        this.setState({ progress: true });
        APIController.GetScheduledPosts(ScheduledDate).then((response) => {
            if (response.error == null) {
                this.setState({
                    list: response.object.data,
                    list1: response.object.data,
                    count: response.object.data.length,
                    progress: false,
                    isLoading: false
                });
            } else if (response.error.message && response.error.message.length > 0) {
                this.handleNotification(response.error.message, true);
            } else { this.handleNotification(response.object.message, true); this.setState({ progress: false, isLoading: false }); }
        });
    }

    handleScheduledPost(ID, PostTypeID) {
        this.setState({ openPostDialog: true, SchedulePostID: ID, PostTypeID: PostTypeID })
    }

    wsPostScheduledPost(ID, PostTypeID, ScheduledDate) {
        this.setState({ progress: true, isLoading: true });
        APIController.PostScheduledPost(ID, PostTypeID, ScheduledDate).then((response) => {
            if (response.error == null) {
                this.wsGetScheduledPosts(this.state.ScheduledDate);
                this.setState({ openPostDialog: false })
            } else if (response.error.message && response.error.message.length > 0) {
                this.handleNotification(response.error.message, true);
            } else { this.handleNotification(response.object.message, true); this.setState({ progress: false, isLoading: false }); }
        });
    }

    handleRemarksDialog(Remarks, RemarksImag) {
        this.setState({ openRemarksDialog: true, RemarksText: Remarks, RemarksImag: RemarksImag })
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        this.wsGetScheduledPosts(e.target.value);
    }


    render() {
        return (
            <>
                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    clearNotification={this.handleClearNotification}
                    closeNotification={this.handleClearNotification}
                />
                <section className='content-right-main'>
                    <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                        <h1><PageTitle Title={"Post Schedule"} /></h1>
                        <div style={{ display: 'flex', alignItems: 'baseline' }}>
                            <TextField label='Scheduled Date' type="date" name="ScheduledDate" value={this.state.ScheduledDate} onChange={this.handleChange} />
                            <img src={refreshIcon} alt="delete" style={{ marginLeft: '20px', width: "25px", cursor: 'pointer' }} onClick={() => { this.wsGetScheduledPosts(this.state.ScheduledDate); }} />
                            {/* <div className='button-field'>
                                <button style={{ minWidth: '100px', padding: '6px', fontSize: '13px' }} onClick={() => { }}> refresh </button>
                            </div> */}
                        </div>
                    </div>

                    <PostScheduleList
                        {...this.state}
                        history={this.props.history}
                        handleChangeRowsPerPage={this.handleRowsPerPageChange}
                        handleChangePage={this.handlePageChange}
                        applyFilter={this.handleApplyFilter}
                        handleNotification={this.handleNotification}
                        handleScheduledPost={this.handleScheduledPost.bind(this)}
                        handleRemarksDialog={this.handleRemarksDialog.bind(this)}
                    />

                </section>

                {this.state.openRemarksDialog && (
                    <Modal
                        show={this.state.openRemarksDialog}
                        handleClose={() => this.setState({ openRemarksDialog: false })}>
                        <div className="popup-wrap">
                            <div className="two-col-sec digital-welcome" style={{ padding: '0px', marginTop: '0px', boxShadow: 'none' }}>
                                {this.state.RemarksImag != '' ? <>
                                    <div className="sec-left" style={{ marginTop: '0px' }} >
                                        <div style={{ letterSpacing: '0px' }}>
                                            <div dangerouslySetInnerHTML={{ __html: this.state.RemarksText }} />
                                        </div>
                                    </div>
                                    <div className="sec-right" style={{ padding: '0px', marginBottom: '10px' }}>
                                        {this.state.RemarksImag.indexOf('.mp4') != -1
                                            ? !navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')
                                                ? <video controls width="250" autoPlay >
                                                    <source src={this.state.RemarksImag} type="video/mp4" />
                                                </video>
                                                : <MyiFrame Height={'450px'} Width={'500px'} src={this.state.RemarksImag} />
                                            : <img src={this.state.RemarksImag} alt="image" />}
                                    </div>
                                </>
                                    : <div className="sec-left" style={{ marginTop: '0px' }} >
                                        <div style={{ letterSpacing: '0px' }}>
                                            <div dangerouslySetInnerHTML={{ __html: this.state.RemarksText }} />
                                        </div>
                                    </div>}
                            </div>
                            <a title="" className="gray-close-ic">
                                <img src={close_icon} alt="icon" onClick={() => this.setState({ openRemarksDialog: false })} />
                            </a>
                        </div>
                    </Modal>
                )}

                {this.state.openPostDialog && (
                    <Modal
                        show={this.state.openPostDialog}
                        handleClose={() => this.setState({ openPostDialog: false })}>
                        <ConfirmtionDialog
                            title={this.state.PostTypeID == "1" ? "Facebook Post" : "Instagram Post"}
                            btnText={"Post"}
                            description={"Are you sure you want to Post?"}
                            deletePress={() => this.wsPostScheduledPost(this.state.SchedulePostID, this.state.PostTypeID, this.state.ScheduledDate)}
                            closeModal={() => this.setState({ openPostDialog: false })}
                            isLoading={this.state.isLoading}
                        />
                    </Modal>
                )}
            </>
        );
    }

    /**
     * handleRowsPerPageChange method is used to update rows per page
     * it will reset page to 1
     */

    handleRowsPerPageChange = (value) => {
        let from = 0;
        let to = value;
        this.setState({ rowsPerPage: value, currentPage: 1, to: to, from: from });
    };

    /**
     * handlePageChange method is used to change page
     */

    handlePageChange = (values) => {
        let from =
            values === 1
                ? 0 * this.state.rowsPerPage
                : (values - 1) * this.state.rowsPerPage;
        let to =
            values == 1
                ? this.state.rowsPerPage
                : parseInt(from) + parseInt(this.state.rowsPerPage);
        this.setState({ currentPage: Number(values), to: to, from: from });
    };

    /**
     * handleApplyFilter method is used to apply filter on every columns
     */
    handleApplyFilter = (tag, values, type) => {
        if (values !== "") {
            let searchCountrys = [];
            if (type === "select") {
                searchCountrys = this.state.list1.filter(
                    (us) => us[tag].toString().toLowerCase() === values.toString().toLowerCase()
                );
            } else {
                searchCountrys = this.state.list.filter(
                    (us) => us[tag] != null && us[tag].toString().toLowerCase().indexOf(values.toLowerCase()) > -1
                );
            }
            this.setState({
                list: searchCountrys,
                currentPage: 0,
                count: searchCountrys.length,
                rowsPerPage: 100,
                rowsPerPageOptions: [500, 100, 50, 25],
                from: 0,
                to: 100,
                progress: false,
            });
        } else {
            this.wsGetScheduledPosts(this.state.ScheduledDate);
            this.setState({
                currentPage: 0,
                rowsPerPage: 100,
                rowsPerPageOptions: [500, 100, 50, 25],
                from: 0,
                to: 100,
            });
        }
    };

    handleNotification = (message, isError) => {
        this.setState({
            message: message,
            showNotification: true,
            isError: isError,
        });

        setTimeout(() => {
            this.handleClearNotification();
        }, 4000);
    };

    handleClearNotification = () => {
        this.setState({
            message: "",
            showNotification: false,
            isError: false,
        });
    };
}

export default PostSchedule;
