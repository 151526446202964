/*eslint-disable eqeqeq*/
import React from "react";
import Table from "../../../../shared/components/table/table";
import TableHead from "../../../../shared/components/table/tableHead";
import TableRow from "../../../../shared/components/table/tableRow";
import TableBody from "../../../../shared/components/table/tableBody";
import TableCell from "../../../../shared/components/table/tableCell";
import double_left_arrow from '../../../../assets/images/left-doble-arow.svg';
import left_arrow from '../../../../assets/images/left-arrow.svg';
import double_right_arrow from '../../../../assets/images/right-doble-arow.svg';
import right_arrow from '../../../../assets/images/right-arrow.svg';
import "react-perfect-scrollbar/dist/css/styles.css";
import PageTitle from "../../../../config/pageTitle";
import PerfectScrollbar from "react-perfect-scrollbar";
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";

const GetLeadsList = (props) => {

	const headerList = ["Practice Name", "Location", "Brand", "Lead Generated At", "Type", "Email Address"]

	const { currentPage, count, rowsPerPage, rowsPerPageOptions, list, to, from } = props;
	let ImportedPracticeList = list;
	// let ImportedPracticeList = list.slice(from, to)

	const page = rowsPerPage == 1000000 ? 1 : Math.floor(count / rowsPerPage) + 1;

	const handleChangeInCurrentPage = (value) => {
		if (value <= page && Number(parseInt(value))) {
			props.handleChangePage(Number(value), PracticeNameText, LocationText, BrandText, LeadGeneratedAtText, TypeText, EmailAddressText);
		}
	}

	const [PracticeNameText, setPracticeNameText] = React.useState('');
	const [LocationText, setLocationText] = React.useState('');
	const [BrandText, setBrandText] = React.useState('');
	const [LeadGeneratedAtText, setLeadGeneratedAtText] = React.useState('');
	const [TypeText, setTypeText] = React.useState('');
	const [EmailAddressText, setEmailAddressText] = React.useState('');

	const _handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			props.applyFilter(PracticeNameText, LocationText, BrandText, LeadGeneratedAtText, TypeText, EmailAddressText)
		}
	}

	return (
		<>
			<h1><PageTitle Title="View Leads" /></h1>
			<div className="table-main-wrap users-class">
				<PerfectScrollbar component='div'>
					<Table className="">
						<TableHead>
							<TableRow> {headerList.map((obj, index) => { return <TableCell style={index == 0 ? { minWidth: "90px" } : { width: '10%', minWidth: '50px' }} key={index}>{obj}</TableCell>; })} </TableRow>
							<TableRow>
								<TableCell style={{ minWidth: '110px' }}> <input type="text" name="" onKeyDown={_handleKeyDown} onChange={(e) => setPracticeNameText(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: '200px' }}> <input type="text" name="" onKeyDown={_handleKeyDown} onChange={(e) => setLocationText(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: '110px' }}> <input type="text" name="" onKeyDown={_handleKeyDown} onChange={(e) => setBrandText(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: '165px' }}> <input type="text" name="" onKeyDown={_handleKeyDown} onChange={(e) => setLeadGeneratedAtText(e.target.value)} /> </TableCell>
								<TableCell style={{ width: '165px' }}>
									<select onChange={(e) => { setTypeText(e.target.value); props.applyFilter(PracticeNameText, LocationText, BrandText, LeadGeneratedAtText, e.target.value, EmailAddressText) }}>
										<option value={""}>All</option>
										<option value={"1"}>Email</option>
										<option value={"2"}>Download</option>
									</select>
								</TableCell>
								{/* <TableCell style={{ minWidth: '50px' }}> <input type="text" name="" onKeyDown={_handleKeyDown} onChange={(e) => setTypeText(e.target.value)} /> </TableCell> */}
								<TableCell style={{ minWidth: '100px' }}> <input type="text" name="" onKeyDown={_handleKeyDown} onChange={(e) => setEmailAddressText(e.target.value)} /> </TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{!!ImportedPracticeList.length ? ImportedPracticeList.map((obj, idnex) => {
								return (
									<TableRow key={idnex}>
										<TableCell>{obj.PracticeName}</TableCell>
										<TableCell>{obj.Location}</TableCell>
										<TableCell>{obj.Brand}</TableCell>
										<TableCell>{obj.LeadGeneratedAt}</TableCell>
										<TableCell>{obj.Type}</TableCell>
										<TableCell>{obj.EmailAddress}</TableCell>
									</TableRow>
								);
							}) : <TableRow >
								<TableCell colSpan={headerList.length} className="not_found">
									{props.progress ?
										<CircularProgress /> :
										<label>Not Currently Available</label>}
								</TableCell>
							</TableRow>}
						</TableBody>

					</Table>
				</PerfectScrollbar>
			</div>

			<div className="table-pagination">
				<div className="pagination-info">
					<p>Showing {from + 1} - {to > count ? count : to} of {count}</p>
				</div>
				<div className="pagination-control">
					<a href="#!" title="" className={`double-left-arrow ${props.currentPage == 1 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(1)
						}}
					><img src={double_left_arrow} alt="left" /></a>
					<a href="#!" title="" className={`simple-left-arrow ${props.currentPage == 1 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage - 1)
						}}
					><img src={left_arrow} alt="single_left" /></a>
					<span>Page</span>
					<input disabled type="text" name="textbox" value={currentPage === 0 ? currentPage + 1 : currentPage} onChange={(e) => handleChangeInCurrentPage(e.target.value)} />
					<span>of {page}</span>
					<a href="#!" title="" className={`simple-right-arrow ${currentPage == page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage + 1)
						}}
					><img src={right_arrow} alt="right" /></a>
					<a href="#!" title="" className={`double-right-arrow ${currentPage == page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(page)
						}}><img src={double_right_arrow} alt="single_right" /></a>
					<select onChange={(e) => props.handleChangeRowsPerPage(parseInt(e.target.value), PracticeNameText, LocationText, BrandText, LeadGeneratedAtText, TypeText, EmailAddressText)} defaultValue={rowsPerPage}>
						{
							rowsPerPageOptions.map((obj, index) => {
								return <option value={obj} key={index}>{obj}</option>
							})
						}
					</select>
				</div>
			</div>
		</>
	);
};

export default GetLeadsList;
