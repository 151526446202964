import React from 'react'
import '../../component/bioTrue.css'
import { ExcelRenderer } from 'react-excel-renderer';
import * as APIController from '../../../../api/APIController';
import MyiFrame from "../../../../features/campaign/components/myiFrame.js";
import { Grid, Divider } from '@material-ui/core';
import Notification from '../../../../shared/components/notification/notification';
import Slider from "react-slick";
import PageTitle from "../../../../config/pageTitle";
import * as AppConstants from "../../../../config/AppConstant";

const EmailMarketing = (props) => {
    const [fileName, setFileName] = React.useState("");
    const [list, setList] = React.useState('');
    const [listCost, setListCost] = React.useState([]);
    const [subTotal, setSubTotal] = React.useState(0);
    const [tax, setTax] = React.useState(0);
    const [totalCost, setTotalCost] = React.useState('');
    const [CampaignDirectMailAssets, setCampaignDirectMailAssets] = React.useState([]);

    React.useEffect(() => {

        wsGetCampaignDirectMailAssets();
        wsGetPostageCharges()
    }, [])

    const wsGetPostageCharges = () => {
        APIController.GetPostageCharges()
            .then((response) => {
                if (response.error == null) {
                    setListCost(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    // ToastsStore.warning(response.error.message)
                }
            })
    }

    const wsCalculatePostageCharges = (TotalAddresses) => {

        APIController.CalculatePostageCharges(TotalAddresses > 0 ? TotalAddresses - 1 : TotalAddresses)
            .then((response) => {
                if (response.object.data[0].status == 1) {
                    setSubTotal(response.object.data[0].SubTotal)
                    setTax(response.object.data[0].Tax)
                    setTotalCost(response.object.data[0].TotalCost)
                }
                else if (response.object.message && response.object.message.length > 0) {
                    handleNotification(response.object.message, true);
                    setTotalCost(0)
                    // setListCost([])
                }
            })
    }

    const wsGetCampaignDirectMailAssets = () => {
        APIController.GetCampaignDirectMailAssets()
            .then((response) => {
                if (response.error == null) {
                    setCampaignDirectMailAssets(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const fileHandler = (event) => {
        let fileObj = event.target.files[0];
        setFileName(fileObj.name)
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
            var obj = {};
            var mainobj = []

            for (var i = 1; i < resp.rows.length; i++) {
                for (var j = 0; j < resp.cols.length; j++) {
                    obj[resp.rows[0][j]] = resp.rows[i][j];
                }
                mainobj.push(obj);
                obj = {};
            }
            setList(resp.rows.length)
            // wsGetPostageCharges()
            wsCalculatePostageCharges(resp.rows.length)
            // deletefileHandler()
        });
    }
    const deletefileHandler = (event) => {
        setFileName("")
        setList(0)
        // setListCost([])
        setSubTotal(0)
        setTax(0)
        setTotalCost(0)
    }
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nav: false,
        arrows: false,
        autoplay: true,
        customPaging: i => (
            <div style={{ height: 10, width: 10, borderRadius: 50, backgroundColor: '#3232' }} />
        )
    };

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }
    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    return (
        <>
            <section className="content-right-main">
                <PageTitle Title={AppConstants.ContactDetais.Step2Upload} />
                <div className="two-col-sec" style={{ paddingLeft: '30px' }}>
                    <Grid container direction='row' justify="space-between" >
                        <div className="sec-left" style={{ width: "36.5%", paddingRight: '20px' }}>
                            <Grid container direction='row' justify="space-between" >
                                <label>Upload Mailing List</label>
                                <label style={{ color: '#0a5c7f' }}>{fileName}</label>
                            </Grid>
                            {totalCost == "" ?
                                <div className='button-field file-upload-btn' style={{ textAlign: 'center', marginLeft: "-1%", minWidth: '155px' }}>
                                    <input type="file" id='fileupload' name='files' accept=".csv" onChange={fileHandler.bind(this)} />
                                    <label>Upload</label>
                                </div> :
                                <div className="button-field" style={{ marginLeft: "-1%", minWidth: '155px' }}>
                                    {/* <button onClick={() => { deletefileHandler() }} type='button'> delete </button> */}
                                    <input type="button" value="delete" name="delete" onClick={() => deletefileHandler()} />
                                </div>
                            }
                            {fileName != "" && <>
                                <Divider style={{ marginTop: '10px', marginBottom: '15px' }} />
                                <Grid container direction='row' justify="space-between" >
                                    <label># of Addresses</label>
                                    <label>{list == 0 ? '' : list - 1}</label>
                                </Grid>
                            </>}

                            {!!listCost.length && <>
                                <Divider style={{ marginTop: '15px', marginBottom: '20px' }} />
                                <Grid container direction='row' justify="space-between">
                                    <Grid container style={{ width: '35%' }} direction='row'>
                                        <label>Postage Cost</label>
                                    </Grid>
                                    <Grid container style={{ width: '60%' }} justify='flex-end' direction='row'>
                                        {!!listCost.length ? listCost.map((obj, index) => {
                                            return (
                                                <label >{obj.FromCount}-{obj.ToCount} {obj.Cost}/Postcard</label>
                                            )
                                        }) : <label></label>}
                                    </Grid>
                                </Grid>
                            </>}

                            {fileName != "" && <>
                                <Divider style={{ marginTop: '15px', marginBottom: '10px' }} />
                                <Grid container direction='row' justify="space-between" >
                                    <label style={{ fontSize: '25px', color: '#000', marginLeft: '100px' }}>Total Cost</label>
                                    <label style={{ fontSize: '25px', color: '#000' }}>{totalCost}</label>
                                </Grid>
                            </>}
                        </div>
                        <div style={{ width: "63.5%", textAlign: 'center' }}>
                            <div style={{ height: "100%", width: "654px" }}>
                                <Slider {...settings}>
                                    {/* <img src={obj.ImageURL} alt='slider-2' /> */}
                                    {CampaignDirectMailAssets.map((obj, index) => {
                                        return (
                                            <MyiFrame HTMLContent={obj.HTMLContent} />
                                        )
                                    })}
                                </Slider>
                            </div>
                        </div>

                    </Grid>
                </div>

                <Grid container flexDirection='row' justify='space-between' style={{ marginTop: '10px' }}>
                    <div className="button-field">
                        <input type="button" value={AppConstants.ButtonText.Back} name="Back" onClick={() => props.history.goBack()} />
                    </div>
                    <div className={totalCost == 0 ? "disable-btn button-field" : "button-field"}>
                        <input type="button" value={AppConstants.ButtonText.Continue} name="Next"
                            onClick={() => {
                                props.history.push({
                                    pathname: '/digital-product/selection/location-selection',
                                    state: {
                                        // budgetDetail: [{ budget: totalCost.substring(1), duration: '1' }],
                                        // productIdEdit: props.history.location.state.productIdEdit,
                                        // campaignIdEdit: props.history.location.state.campaignIdEdit,
                                        // brandIdEdit: props.history.location.state.brandIdEdit,
                                        // brandName: props.history.location.state.brandName,
                                        // practiceIdEdit: props.history.location.state.practiceIdEdit,
                                        // OrderDetailsID: props.history.location.state.OrderDetailsID,
                                        // PracticeIDs: props.history.location.state.PracticeIDs,
                                        // CampaignReach: props.history.location.state.CampaignReach,
                                        // campaignThemesIdEdit: props.history.location.state.campaignThemesIdEdit,
                                    }
                                })
                                AppConstants.SetLocalStorage("localStorageBudgetDetail", JSON.stringify([{ budget: totalCost.substring(1), duration: '1' }]))
                            }}
                        />
                    </div>
                </Grid>
                <Notification
                    isError={isError}
                    message={message}
                    showNotification={showNotification}
                    clearNotification={handleClearNotification.bind(this)}
                    closeNotification={handleClearNotification.bind(this)}
                />
            </section>
        </>
    )
}

export default EmailMarketing