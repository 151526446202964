/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as APIController from '../../../../api/APIController.js';
import * as yup from 'yup';
import Notification from '../../../../shared/components/notification/notification';
import CircularProgress from '@material-ui/core/CircularProgress';

import uuid from 'react-uuid';
import file_upload_ic from "../../../../assets/images/file-upload-ic.svg";
import close_icon from "../../../../assets/images/close_icon.svg";

const ScreenBannersForm = props => {
    const initialValues = {
        screenId: props.history.location.state.screenIdEdit, sequenceNo: props.history.location.state.sequenceNoEdit,
        screenImageName: props.history.location.state.screenImageName, screenImageData: props.history.location.state.screenImageDataEdit,
    }
    const [isButtonValue, setButtonValue] = React.useState(false);
    const [Loading, setLoading] = React.useState(true)
    const [IsLoading, setIsLoading] = React.useState(false);

    const [dataSource, setdataSource] = React.useState([]);

    const [baseImageData, setbaseImageData] = React.useState(props.history.location.state.screenImageDataEdit);

    React.useEffect(() => {
        props.history.location.state.screenbannerIdEdit != 0 && wsGetScreenBannerByID(props.history.location.state.screenbannerIdEdit)
        wsGetScreenForDropdown();
    }, [props])

    const wsGetScreenBannerByID = (ID) => {
        APIController.GetScreenBannerByID(ID)
            .then((response) => {
                setLoading(false)
                if (response.error == null) {
                    initialValues.screenId = response.object.data[0].ScreenID
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const wsGetScreenForDropdown = () => {
        APIController.GetScreenForDropdown()
            .then((response) => {
                setLoading(false)
                if (response.error == null) {
                    setdataSource(response.object.data);
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const wsAddEditScreenBanner = (ID, ScreenID, SequenceNo, ImageName, ImageData) => {
        setIsLoading(true)
        APIController.AddEditScreenBanner(ID, ScreenID, SequenceNo, ImageName, ImageData)
            .then((response) => {
                if (response.error == null) {
                    setIsLoading(false)
                    if (isButtonValue === true && response.object.status == 1) {
                        props.history.push({ pathname: '/screenbanners', state: { showMessage: response.object.message } })
                    } else if (response.object.status == 1) {
                        handleNotification(response.object.message);
                    } else {
                        handleNotification(response.object.message, true);
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const [message, setMessage] = React.useState("")
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    return (
        <>
            <section className="content-right-main">
                {props.history.location.state.screenbannerIdEdit == 0 ? <h1>Add Screen Banners</h1> : <h1>Edit Screen Banners</h1>}
                <Formik
                    initialValues={initialValues}
                    validationSchema={validateCityForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        wsAddEditScreenBanner(props.history.location.state.screenbannerIdEdit, values.screenId, values.sequenceNo, values.screenImageName, baseImageData)
                        if (isButtonValue === false) {
                            values.screenId = "";
                            values.sequenceNo = "";
                            values.screenImageData = "";
                            setbaseImageData('')
                        }
                    }}
                >
                    {({ values,
                        handleSubmit,
                        setFieldValue,
                        errors,
                        touched,
                    }) => {
                        return (
                            <div className="form-main">
                                <form onSubmit={handleSubmit}>
                                    <div
                                        className={`form_field ${errors && touched.screenId && errors.screenId && errors.screenId !== '' ? 'error' : ''}`}>
                                        <div className="field-left"> <label>Screen</label> </div>
                                        <div className="field-right">
                                            {Loading ? <CircularProgress style={{ alignItems: 'center' }} />
                                                : <select name='screenId' defaultValue={values.screenId} value={values.screenId}
                                                    onChange={(e, index) => { setFieldValue('screenId', e.target.value); }}>
                                                    <option value={''}>Select Screen</option>
                                                    {dataSource.map((obj, index) => {
                                                        return <option key={index} selected={obj.ID == values.screenId ? obj.ID : obj.Name} value={obj.ID}>{obj.Name}</option>
                                                    })}
                                                </select>}
                                            {errors && touched.screenId && errors.screenId && errors.screenId !== '' &&
                                                (<span className='error-msg'>
                                                    <ErrorMessage name='screenId' render={(err) => { return err; }} />
                                                </span>)}
                                        </div>
                                    </div>

                                    <div className={`form_field ${errors && touched.sequenceNo && errors.sequenceNo && errors.sequenceNo !== '' ? 'error' : ''}`}>
                                        <div className="field-left"> <label>Sequence No</label> </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Sequence No" name="sequenceNo" value={values.sequenceNo} maxLength="50" />
                                            {errors && touched.sequenceNo && errors.sequenceNo && errors.sequenceNo !== '' &&
                                                (<span className='error-msg'>
                                                    <ErrorMessage name='sequenceNo' render={(err) => { return err; }} />
                                                </span>)}
                                        </div>
                                    </div>

                                    <div className="form_field">
                                        <div className='field-left'>
                                            <label>Screen Image</label>
                                        </div>
                                        <div className='field-right'>
                                            <div className='form-file-input'>
                                                <input className='input-file' id='fileupload' name='files' type='file' onChange={(e) => {
                                                    const file = e.target.files[0];
                                                    if (file && (file.type.includes("svg") || file.type.includes("png") || file.type.includes("jpeg") || file.type.includes("jpg"))) {
                                                        let file1 = e.target.files[0]
                                                        file1['preview'] = URL.createObjectURL(e.target.files[0]);
                                                        setFieldValue("screenImageName", uuid() + "." + file.type.split('/')[1]);

                                                        const image2base64 = require('image-to-base64');
                                                        image2base64(file1.preview).then((response) => { setbaseImageData(response) }).catch((error) => { })
                                                        setFieldValue('screenImageData', file1.preview);
                                                    }
                                                }} />
                                                {values.screenImageData == '' ?
                                                    <label htmlFor='fileupload' className='input-file-trigger' style={{ display: 'flex' }}>
                                                        <img src={file_upload_ic} alt='file-uplaod' />Drag and drop your file here</label> :
                                                    <div className='logo-preview'>
                                                        <a title="" className="close-ic"><img src={close_icon} alt="close-ic" onClick={() => { setFieldValue('screenImageData', ''); setbaseImageData('') }} /></a>
                                                        <img src={values.screenImageData} style={{ height: '170px', width: '230px' }} alt='' />
                                                    </div>}
                                            </div>
                                            <p> Min image size: 650X800 px. Accepted files : .jpg, .jpeg, .png, .svg </p>
                                        </div>
                                    </div>

                                    <Notification
                                        isError={isError}
                                        message={message}
                                        showNotification={showNotification}
                                        clearNotification={handleClearNotification.bind(this)}
                                        closeNotification={handleClearNotification.bind(this)}
                                    />
                                    <div className="form-submit">
                                        <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" onClick={() => setButtonValue(true)} />
                                        </div>
                                        {props.history.location.state.screenbannerIdEdit == 0 ?
                                            <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                                <input type="submit" value={IsLoading ? "Saving..." : "save & add new"} onClick={() => setButtonValue(false)} />
                                            </div> : null}
                                        <div className="button-field ">
                                            <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )
                    }}
                </Formik>
            </section>
        </>
    )
}
const validateCityForm = yup.object().shape({
    screenId: yup.string().required('Screen is required'),
    sequenceNo: yup.string().required('Sequence No is required'),
});


export default ScreenBannersForm;