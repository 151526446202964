/*eslint-disable eqeqeq*/
import React from "react";
import { Grid, SvgIcon, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from "@material-ui/core";
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import PageTitle from "../../../config/pageTitle";
import "./location.css";
import SocialLocationValidationComponent from "./social-location-validation";
import * as AppConstants from "../../../config/AppConstant";

const SocialLocationSelectionComponent = (props) => {
	if (props.scrollTop) {
		window.scrollTo(0, 0);
		props.setScrollTop();
	}
	const [expanded, setExpanded] = React.useState(null);
	const handleChange = (panel) => {
		setExpanded(panel);
	};
	const [ReviewTitle, setReviewTitle] = React.useState('inValid');
	const SocialAutomationAllLocations = 'SocialAutomationAllLocations'
	const [modalNumber, setModalNumber] = React.useState(null);

	React.useEffect(() => {
		//code for Default Select when One(1) Location found
		if (props.DefaultOpenPanel == true) {
			setExpanded('panel1')
			setModalNumber(0)
		}

		if (AppConstants.GetLocalStorage('localStorageFBPageData') != "" && AppConstants.GetLocalStorage('localStorageFBPageData') != undefined && AppConstants.GetLocalStorage('localStorageFBPageData') != null) {
			if (props.practiceId == JSON.parse(AppConstants.GetLocalStorage('localStorageFBPageData')).practiceID) {
				setReviewTitle("Valid")
			}
		}
		if (AppConstants.GetLocalStorage('localStorageInstaUserData') != "" && AppConstants.GetLocalStorage('localStorageInstaUserData') != undefined && AppConstants.GetLocalStorage('localStorageInstaUserData') != null) {
			if (props.practiceId == JSON.parse(AppConstants.GetLocalStorage('localStorageInstaUserData')).practiceID) {
				setReviewTitle("Valid")
			}
		}
		InitializeIsReviewNeeded();
	}, []);

	const InitializeIsReviewNeeded = () => {
		const AllLocations = localStorage.getItem(SocialAutomationAllLocations);
		if (AllLocations == '' || AllLocations == null || AllLocations == undefined) {
			localStorage.setItem("SocialAutomationAllLocations", JSON.stringify([]));
		}
	}

	const CheckIsReviewNeededFromLocalStorage = (PracticeID) => {
		if (localStorage.getItem(SocialAutomationAllLocations) != '' && localStorage.getItem(SocialAutomationAllLocations) != null && localStorage.getItem(SocialAutomationAllLocations) != undefined) {
			return JSON.parse(localStorage.getItem(SocialAutomationAllLocations)).filter((e) => e.PracticeID == PracticeID);
		} else {
			return 0
		}
	}

	const CheckBoxComponent = ({
		locationDetail,
		index,
		onSelectAddress,
		selectedLocations,
		onDeSelectAddress,
	}) => {
		return (
			<div className='location-inner-content'>
				<div className='location-name'>
					<div className={'custom-radio'} >
						{props.budgetDetail[0].budget != "" && AppConstants.GetLocalStorage('localStorageIsFromHeader') != 1 &&
							<input
								disabled={locationDetail.IsSubscriptionCreated == 0 ? false : true}
								type={'radio'}
								name='location'
								id={`${locationDetail.id}`}
								checked={!!selectedLocations.length && selectedLocations.find((ob) => ob.id === locationDetail.id)}
								onChange={(e) => {
									if (e.target.checked) {
										onDeSelectAddress(selectedLocations[0])
										onSelectAddress(locationDetail);
									} else {
										onDeSelectAddress(locationDetail);
									}
								}}
							/>
						}
						<label htmlFor={`${locationDetail.id}`} style={props.budgetDetail[0].budget != "" && selectedLocations.find((ob) => ob.id === locationDetail.id) ? { fontWeight: 700, width: '100%' } : { width: '100%' }}>
							{locationDetail.name}, {locationDetail.location}
						</label>
					</div>
				</div>

				<div className="theme-option theme-option-location"
					onClick={(e) => {
						e.preventDefault();
						props.SetPracticeId(locationDetail.id)
						setModalNumber(index);
						// handleChange(expanded === `panel${index + 1}` ? null : `panel${index + 1}`);
					}} >
					{/* IsDirectPageURL means From Header  */}
					{AppConstants.GetLocalStorage("IsDirectPageURL") == "3" ?
						locationDetail.tokenvalid == 0 ?
							<div className="red-link" style={{ display: 'contents' }}>{"Needs Review! :"}&nbsp;</div> :
							<div style={{ display: 'contents' }}>{"Location Details:"}&nbsp;</div>
						: locationDetail.IsSubscriptionCreated == 1
							? <div style={{ display: 'contents' }}>{"Already Subscribed :"}&nbsp;</div>
							: !CheckIsReviewNeededFromLocalStorage(locationDetail.id).length
								? <div className="red-link" style={{ display: 'contents' }}>{"Needs Review! :"}&nbsp;</div>
								: <div style={{ display: 'contents' }}>{"Location Details:"}&nbsp;</div>
					}
					
					{!CheckIsReviewNeededFromLocalStorage(locationDetail.id).length && locationDetail.tokenvalid == 0
						? <div className='location-red-main-toggle' style={{ minWidth: '150px' }}>
							<div className={expanded != null && index == modalNumber ? 'location-red-toggle-active' : 'location-red-toggle-deActive'}
								onClick={(e) => { handleChange(`panel${index + 1}`); }}>Show</div>
							<div className={expanded == null || index != modalNumber ? 'location-red-toggle-active' : 'location-red-toggle-deActive'}
								onClick={(e) => { handleChange(null); }}>Hide</div>
						</div>
						: <div className='calculator-main-tabView' style={{ minWidth: '150px' }}>
							<div className={expanded != null && index == modalNumber ? 'calculator-tabView-active' : 'calculator-tabView-deActive'}
								onClick={(e) => { handleChange(`panel${index + 1}`); }}>Show</div>
							<div className={expanded == null || index != modalNumber ? 'calculator-tabView-active' : 'calculator-tabView-deActive'}
								onClick={(e) => { handleChange(null); }}>Hide</div>
						</div>}
				</div>
			</div>
		);
	};

	const handleTitle = (data) => {
		props.category.locations.map((obj, index) => {
			if (modalNumber == index) {
				setReviewTitle(data)
			}
		})
	}

	return (
		<section className='content-right-main'>
			{AppConstants.GetLocalStorage("IsDirectPageURL") == "3" ?
				<PageTitle Title={AppConstants.ContactDetais.ConfirmLocationTitle} /> :
				<PageTitle Title={AppConstants.ContactDetais.SocialSelectLocationTitle} />}

			<div className='location-screen-sec'>
				<div className='loc-content-wrap'>
					{props.progress ?
						<div style={{ padding: '3%' }}> <CircularProgress /> </div>
						: <div className='loc-content-main'>
							{props.category.locations != undefined && props.category.locations.map((obj, index) => {
								return (
									<ExpansionPanel
										expanded={expanded === `panel${index + 1}`}
										key={index}>

										<ExpansionPanelSummary>
											<CheckBoxComponent
												locationDetail={obj}
												key={index}
												index={index}
												onSelectAddress={props.onSelectAddress}
												selectedLocations={props.selectAddress}
												onDeSelectAddress={props.onDeSelectAddress}
											/>
										</ExpansionPanelSummary>
										<ExpansionPanelDetails>
											{modalNumber === index && (
												<SocialLocationValidationComponent
													locationDetail={obj.locationDetail}
													tokenvalid={obj.tokenvalid}
													needsToReview={obj.needsToReview}
													practiceId={props.practiceId}
													budgetDetail={props.budgetDetail}
													// isTitle={handleIsTitle.bind(this)}
													handleTitle={handleTitle.bind(this)}
													updateLocationDetails={(values) => {
														props.updateLocationDetails(values, obj.id);
														handleChange(null);
														setModalNumber(null);
													}}
												/>
											)}
										</ExpansionPanelDetails>
									</ExpansionPanel>
								);
							})}
						</div>
					}
				</div>
			</div>

			{props.budgetDetail[0].budget != "" && AppConstants.GetLocalStorage('localStorageIsFromHeader') != 1 &&
				<Grid container flexDirection='row' justify='space-between' style={{ marginTop: '-25px' }}>
					<div className='button-field'>
						<input type='button' name='button' value={AppConstants.ButtonText.Back} onClick={props.back} />
					</div>
					{AppConstants.GetLocalStorage('localStorageLocationByPass') == 1 ?
						<div className={`button-field ${!props.selectAddress.length ? 'disable-btn' : ''}`}>
							<input type='button' name='button' value={AppConstants.ButtonText.Continue} onClick={props.nextForm} />
						</div>
						: CheckIsReviewNeededFromLocalStorage(props.selectAddress.length ? props.selectAddress[0].id : 0) == 0
							? <div className={`button-field disable-btn`}>
								<input type='button' name='button' value={AppConstants.ButtonText.Continue} />
							</div>
							: <div className={`button-field ${props.selectAddress.length === 0 || props.selectAddress.find((ob) => ob.needsToReview === true) ? "disable-btn" : ""}`}>
								<input type='button' name='button' value={AppConstants.ButtonText.Continue} onClick={props.nextForm} />
							</div>}
				</Grid>}
		</section>
	);
};

export default SocialLocationSelectionComponent;