import React from "react";
import styles from "./SocialDonutChartStyle";

import { Doughnut } from "react-chartjs-2";
import CountUp from "react-countup";

import { withStyles, Paper, Typography } from "@material-ui/core";

class CampaignBudgetSpent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            Cost: this.props.TotalAmount - this.props.TotalCost
        };
    }

    render() {
        return (
            <Paper style={{ padding: "20px", margin: '5px', width: '32%', }}>
                <Typography className={'title-main'} variant="subheading">{'Campaign Budget Spent'}</Typography>
                <div className="budget-chart" style={{ width: '100%', height: '155px', justifyContent: "center", alignItems: "center", marginTop: "10px", borderTop: '2.5px solid #F5F7FA', paddingTop: '10px' }} >
                    <Doughnut
                        width={300}
                        height={100}
                        data={{
                            labels: ['Total Cost', 'Total Amount'],
                            datasets: [{
                                weight: 0.5,
                                data: [this.props.TotalCost, this.state.Cost],
                                borderWidth: 0,
                                backgroundColor: ["#02ABAE", '#EAF2FD'],
                                hoverBackgroundColor: "#52ABAE",
                            }]
                        }}
                        options={{
                            weight: 0.5,
                            rotation: 1 * Math.PI,
                            circumference: 1 * Math.PI,
                            legend: { display: false, position: "bottom" },
                            datalabels: { display: false, color: "white" },
                            tooltips: { backgroundColor: "#5a6e7f" }
                        }} />

                    <div className="budget-spant-chart-data" style={{ alignItems: 'center', justifyContent: 'center', display: "flex" }}>
                        <div style={{ width: '25%', alignItems: 'center' }}>
                            <Typography style={{ fontSize: '15px' }} className={'title-content'} >{'$' + this.props.TotalCost}</Typography>
                        </div>
                        <div style={{ width: '20%', justifyContent: 'end', alignItems: 'center', display: "flex" }}>
                            <CountUp separator={','} duration={3} className={'title'} end={(this.props.TotalCost * 100) / (this.props.TotalAmount)} />
                            <Typography className={'title'} >{'%'}</Typography>
                        </div>
                        <div style={{ width: '30%', textAlign: 'end', alignItems: 'center' }}>
                            <Typography style={{ fontSize: '15px' }} className={'title-content'}>{'$' + (this.props.TotalAmount)}</Typography>
                        </div>
                    </div>
                </div>
            </Paper>
        )
    }
}


export default withStyles(styles, { withTheme: true })(CampaignBudgetSpent);