/*eslint-disable eqeqeq*/
import React from "react";
import KeywordList from "../../component/componentNegativeKeyword/negativeKeywordlist";
import * as APIController from "../../../../api/APIController.js";
import Notification from "../../../../shared/components/notification/notification";

class NegativeKeywordContainer extends React.Component {
	state = {
		currentPage: 1,
		count: 0,
		rowsPerPage: 50,
		rowsPerPageOptions: [50, 100, 250, 500, 1000, "All",],
		list: [],
		from: 0,
		to: 50,
		downloadlist: [],
		progress: true,
		message: "",
		showNotification: false,
		isError: false,
		showMessage:
			this.props.history.location.state != null &&
			this.props.history.location.state.showMessage,
	};

	componentDidMount() {
		this.wsGetNegativeKeywords(this.state.from, this.state.to, "", this.state.currentPage, this.state.rowsPerPage);
		this.state.showMessage != "" &&
			this.handleNotification(this.state.showMessage);
		this.props.history.replace("/negativeKeyword", null);
	}

	handlebind() {
		this.wsGetNegativeKeywords(this.state.from, this.state.to, "", this.state.currentPage, this.state.rowsPerPage);
	}

	wsGetNegativeKeywords(from, to, Name, CurrentPage, RecordPerPage) {
		this.setState({ progress: true, });
		APIController.GetNegativeKeywords(Name, CurrentPage, RecordPerPage).then((response) => {
			if (response.error == null) {
				this.setState({
					to: isNaN(to) ? 1000000 : to,
					from: from,
					count: response.object.data[0].MaxRows,
					list: response.object.data,
					progress: false,
				});
			} else if (response.error.message && response.error.message.length > 0) {
				this.handleNotification(response.error.message, true);
			}
		});
	}

	render() {
		return (
			<>
				<Notification
					isError={this.state.isError}
					message={this.state.message}
					showNotification={this.state.showNotification}
					clearNotification={this.handleClearNotification}
					closeNotification={this.handleClearNotification}
				/>
				<section className='content-right-main'>

					<div className='button-field button-field-country'>
						<button
							onClick={() => {
								this.props.history.push({
									pathname: "/negativeKeyword/add",
									state: { countryIdEdit: "0", countryNameEdit: "" }
								});
							}}>
							+ Add Keyword
						</button>
					</div>

					<KeywordList
						{...this.state}
						history={this.props.history}
						handlebind={this.handlebind.bind(this)}
						handleChangeRowsPerPage={this.handleRowsPerPageChange}
						handleChangePage={this.handlePageChange}
						handleApplyFilter={this.handleApplyFilter}
						handleNotification={this.handleNotification}
					/>
				</section>
			</>
		);
	}

	handleRowsPerPageChange = (value, Name) => {
		let from = 0
		let to = value;
		this.setState({ rowsPerPage: value, currentPage: 1, to: to, from: from })
		this.wsGetNegativeKeywords(from, to, Name, isNaN(value) ? '' : this.state.currentPage, isNaN(value) ? 1000000 : value);
	}

	handlePageChange = (values, Name) => {
		let from = values === 1 ? 0 * this.state.rowsPerPage : (values - 1) * this.state.rowsPerPage
		let to = values === 1 ? this.state.rowsPerPage : (from + this.state.rowsPerPage);
		this.setState({ currentPage: Number(values) })
		this.wsGetNegativeKeywords(from, to, Name, Number(values), this.state.rowsPerPage);
	}

	handleApplyFilter = (Name) => {
		this.wsGetNegativeKeywords(this.state.from, this.state.to, Name, this.state.currentPage, this.state.rowsPerPage);
	};

	handleNotification = (message, isError) => {
		this.setState({
			message: message,
			showNotification: true,
			isError: isError,
		});

		setTimeout(() => {
			this.handleClearNotification();
		}, 4000);
	};

	handleClearNotification = () => {
		this.setState({
			message: "",
			showNotification: false,
			isError: false,
		});
	};
}

export default NegativeKeywordContainer;
