import React from 'react';
import { ExcelRenderer } from 'react-excel-renderer';

import * as APIController from '../../../../src/api/APIController';

import PageTitle from "../../../config/pageTitle";
import TextField from "../../../shared/components/TextField/TextField";
import authService from "../../../shared/services/auth-service";
import Notification from '../../../shared/components/notification/notification';
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";

class MyAccount extends React.Component {
    constructor() {
        super();
        this.state = {
            firstName: '',
            lastName: '',
            emailAddress: '',
            checkEmail: '',
            practicePhone: '',


            message: '',
            showNotification: false,
            isError: false,
            isLoading: false,
            touched: false
        }
    }

    componentDidMount() {
        this.wsGetMyProfile()
    }

    wsGetMyProfile = () => {
        APIController.GetMyProfile()
            .then((response) => {
                if (response.error == null) {
                    if (response.object.data != '') {
                        this.setState({
                            firstName: response.object.data[0].FirstName,
                            lastName: response.object.data[0].LastName,
                            emailAddress: response.object.data[0].EmailAddress,
                            checkEmail: response.object.data[0].EmailAddress,

                            practicePhone: this.normalizeInput(response.object.data[0].phoneNo) == undefined ? '' : this.normalizeInput(response.object.data[0].phoneNo)
                        })
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.handleNotification(response.error.message, true)
                }
            })
    }

    normalizeInput = (value, previousValue) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;
        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 4) return currentValue;
            if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
    };

    handleNotification = (message, isError) => {
        this.setState({ message: message, showNotification: true, isError: isError })
        setTimeout(() => { this.handleClearNotification(); }, 4000)
    }

    handleClearNotification = () => {
        this.setState({ message: '', showNotification: false, isError: false })
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleValidateScroll = (data) => {
        this.setState({ touched: true })
        let id = undefined;
        if (this.state.firstName == '') {
            id = 'this.state.firstName'
        } else if (this.state.lastName == '') {
            id = 'this.state.lastName'
        } else if (this.state.emailAddress.trim() == '') {
            id = 'this.state.emailAddress'
        } else if (!this.state.emailAddress.trim().match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            id = 'this.state.emailAddress'
            this.handleNotification('Please Enter Valid Email Id', true)
        } else if (this.state.practicePhone == '' || this.state.practicePhone == undefined) {
            id = 'this.state.practicePhone'
        } else if (this.state.practicePhone.length < 14) {
            this.handleNotification("Please enter a valid 10- digit phone number.", true);
            id = 'this.state.practicePhone'
        } else {
            this.wsEditProfile(this.state.firstName, this.state.lastName, this.state.practicePhone, this.state.emailAddress)
        }
        var element = document.getElementById(id);
        id != undefined && id != null && element.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });
    }

    wsEditProfile = (FirstName, LastName, phoneNo, EmailAddress) => {
        this.setState({ isLoading: true })
        APIController.EditProfile(FirstName, LastName, phoneNo, EmailAddress)
            .then((response) => {
                if (response.error == null) {
                    if (response.object.status == 1) {
                        this.handleNotification(response.object.message);
                        if (this.state.checkEmail != EmailAddress) {
                            this.handleLogout()
                        }
                    } else {
                        this.handleNotification(response.object.message, true);
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.handleNotification(response.error.message, true)
                }
                this.setState({ isLoading: false })
            })
    }

    handleLogout = () => {
        authService.removeAuthData();
        localStorage.clear()
        sessionStorage.clear()
        setTimeout(() => {
            this.history.push("/login")
        }, 4000)
    };

    render() {
        return (
            <section className='content-right-main'>
                <PageTitle Title={"My Profile"} />
                <div className='business-info-form' style={{ marginTop: '20px' }}>
                    <div className='business-info-wrap'>
                        <div className='info-col-left'>
                            <div className='white-box'>
                                <div className='box-head'>
                                    <h4>User Profile</h4>
                                </div>
                                <div className='box-body'>
                                    <form>
                                        <div id={'this.state.firstName'} style={{ position: 'relative' }} className={`field-field-row ${this.state.firstName == '' && this.state.touched ? 'error' : ''}`}>
                                            <TextField label='First Name' type='text' placeholder='First Name' name='firstName' error={this.state.firstName == '' && this.state.touched ? true : false}
                                                value={this.state.firstName} maxLength="100" onChange={this.handleChange} />
                                        </div>
                                        <div id={'this.state.lastName'} style={{ position: 'relative' }} className={`field-field-row ${this.state.lastName == '' && this.state.touched ? 'error' : ''}`}>
                                            <TextField label='Last Name' type='text' placeholder='Last Name' name='lastName' error={this.state.lastName == '' && this.state.touched ? true : false}
                                                value={this.state.lastName} maxLength="100" onChange={this.handleChange} />
                                        </div>
                                        <div id={'this.state.emailAddress'} style={{ position: 'relative' }} className={`field-field-row ${this.state.emailAddress == '' && this.state.touched ? 'error' : ''}`}>
                                            <TextField label='Email Address' type='text' placeholder='Email Address' name='emailAddress' error={this.state.emailAddress == '' && this.state.touched ? true : false}
                                                value={this.state.emailAddress} maxLength="100" onChange={this.handleChange} />
                                        </div>
                                        <div id={'this.state.practicePhone'} style={{ position: 'relative' }} className={`field-field-row ${(this.state.practicePhone == '' || this.state.practicePhone.length < 14) && this.state.touched ? 'error' : ''}`}>
                                            <TextField label='Phone Number' type='text' placeholder='Phone Number' name='practicePhone' error={(this.state.practicePhone == '' || this.state.practicePhone.length < 14) && this.state.touched ? true : false}
                                                value={this.state.practicePhone} maxLength="14" onChange={(e) => this.setState({ practicePhone: this.normalizeInput(e.target.value) })} />
                                        </div>

                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <div className={this.state.isLoading ? `button-field back-btn disable-btn` : `button-field back-btn`}>
                                                <input type='button' name='submit' value="Update Profile" onClick={() => { this.handleValidateScroll(); }} />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className='info-col-right'>
                        </div>
                    </div>
                </div>

                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    closeNotification={this.handleClearNotification.bind(this)}
                />
            </section>
        )
    }
}
export default MyAccount;