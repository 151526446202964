/*eslint-disable eqeqeq*/
import React from "react";
import * as APIController from "../../../../api/APIController";
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";
import * as htmlToImage from 'html-to-image';
import uuid from 'react-uuid';
import { URLs } from "../../../../api/URLs";

const MyLandingPage = (props) => {

    const [DocFinders, setDocFinders] = React.useState([]);
    const [MyLandingPages, setMyLandingPages] = React.useState([]);
    const [Loading, setLoading] = React.useState(false);
    const qrCodeUrl = URLs.base + "/API/QR/GetQR?height=200&width=200&data="
    // const qrCodeUrl = `https://cobrand.api.thestaging.cc/API/QR/GetQR?height=200&width=200&data=`;
    // const qrCodeUrl = `https://chart.googleapis.com/chart?cht=qr&chs=200x200&chl=`;

    React.useEffect(() => {
        wsGetMyLandingPageURLs();
    }, [props]);

    const wsGetMyLandingPageURLs = () => {
        setLoading(true);
        APIController.GetMyLandingPageURLs().then((response) => {
            if (response.object.data != {}) {
                setDocFinders(response.object.data.DocFinders)
                setMyLandingPages(response.object.data.LandingPages)
                setLoading(false)
            } else {
                setLoading(false);
            }
        })
    }

    const domEl = React.useRef(null);
    const downloadImage = async () => {
        const dataUrl = await htmlToImage.toPng(domEl.current);

        // download image
        const link = document.createElement('a');
        link.download = uuid() + ".png";
        link.href = dataUrl;
        link.click();
    }

    return (
        <section className='content-right-main'>
            <div className="content-right-heading">My Landing Pages</div>
            {Loading ? <CircularProgress />
                : DocFinders.length ?
                    <div className="two-col-sec">
                        <div className='box-head' style={{ width: '100%', padding: '0px 0px 10px' }}> <h4>Practice Doc Finders</h4> </div>

                        <div className="digital-welcome" style={{ display: 'grid', width: "100%" }}>
                            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: "15px" }}>
                                {DocFinders.map((item, idx) =>
                                    <div className="content-box landing-page-content-box" key={idx}>
                                        <div className="box-image landing-page-box-image" id="domEl" ref={domEl}
                                            style={{ background: `url("${qrCodeUrl + item.URL}")` }}>
                                            <div className="button-hover">
                                                <div className="button-field">
                                                    <button style={{ padding: '5px 12px', fontSize: '12px', fontWeight: 600 }} onClick={() => window.open(item.URL)} >View Doc Finder</button>
                                                </div>
                                                <div className="button-field">
                                                    <button style={{ padding: '5px 12px', fontSize: '12px', fontWeight: 600 }} onClick={() => { downloadImage() }}>Download QR Code</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-content-info">
                                            <label className="box-lable">{item.Name}</label>
                                        </div>
                                    </div>)}
                            </div>
                        </div>
                    </div> : <></>}


            <div className="two-col-sec">
                <div className='box-head' style={{ width: '100%', padding: '0px 0px 10px' }}> <h4>Location Landing Pages</h4> </div>

                <div className="digital-welcome" style={{ display: 'grid', width: "100%" }}>
                    {Loading ? <CircularProgress />
                        : !MyLandingPages.filter(e => e.URLs != null).length ?
                            <label style={{ textAlign: 'center' }}>Not Currently Available</label>
                            : MyLandingPages.map((obj, index) => {
                                return (obj.URLs != null &&
                                    <div style={index != index - 1 ? { marginBottom: "15px", borderBottom: '3px solid #f5f7fa' } : {}}>
                                        <label className="box-lable" style={{ margin: '10px 0' }}>{obj.PracticeName}, {obj.Location}</label>

                                        <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: "15px" }}>
                                            {obj.URLs != "" && JSON.parse(obj.URLs).map((item, idx) =>
                                                <div className="content-box landing-page-content-box" key={index}>
                                                    <div className="box-image landing-page-box-image" id="domEl" ref={domEl}
                                                        style={{ background: `url(${qrCodeUrl + item.URL})` }}>
                                                        <div className="button-hover">
                                                            <div className="button-field">
                                                                <button style={{ padding: '5px 12px', fontSize: '12px', fontWeight: 600 }} onClick={() => window.open(item.URL)} >View Landing Page</button>
                                                            </div>
                                                            <div className="button-field">
                                                                <button style={{ padding: '5px 12px', fontSize: '12px', fontWeight: 600 }} onClick={() => { downloadImage() }}>Download QR Code</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="box-content-info">
                                                        <p>{item.Name}</p>
                                                        <label className="box-lable">Landing Page</label>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>);
                            })}
                </div>
            </div>
        </section>
    );
};

export default MyLandingPage;