import React from "react";
import forgot_user_ic from "../../../assets/images/forgot-username-ic.svg";
import close_ic from "../../../assets/images/close-ic.svg";
import * as APIController from "../../../../src/api/APIController";
import { Formik, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import Notification from "../../../shared/components/notification/notification";

import TextField from "../../../shared/components/TextField/TextField";

const ForgotPassword = (props) => {
	const [IsLoading, setIsLoading] = React.useState(false);
	const [LoadingButton, setLoadingButton] = React.useState(false);

	React.useEffect(() => {
	})

	const wsSetPracticePassword = (PracticeID, UserID, Password) => {
		setIsLoading(true)
		APIController.SetPracticePassword(PracticeID, UserID, Password).then((response) => {
			if (response.error == null && response.object.data) {
				handleNotification(response.object.data[0].Message);
				setTimeout(() => { props.closeModal(); handleClearNotification(); setIsLoading(false); }, 4000);
			} else if (response.error.message && response.error.message.length > 0) {
				handleNotification(response.error.message, true); setIsLoading(false);
			}
		});
	};

	const wsForgotPassword = (EmailAddress) => {
		setIsLoading(true)
		setLoadingButton(true)
		APIController.ForgotPassword(EmailAddress).then((response) => {
			if (response.error == null) {
				// eslint-disable-next-line
				if (response.object.status == 1) {
					handleNotification(response.object.message);
					setTimeout(() => { props.closeModal(); handleClearNotification(); setLoadingButton(false); setIsLoading(false); }, 8000);
				}
				else { handleNotification(response.object.message, true); setLoadingButton(false); setIsLoading(false); }
			} else if (response.error.message && response.error.message.length > 0) {
				handleNotification(response.error.message, true); setLoadingButton(false); setIsLoading(false);
			}

		});
	};

	const [message, setMessage] = React.useState("");
	const [showNotification, setShowNotification] = React.useState(false);
	const [isError, setIsError] = React.useState(false);

	const handleNotification = (message, isError) => {
		setMessage(message);
		setShowNotification(true);
		setIsError(isError);

		setTimeout(() => {
			handleClearNotification();
		}, 8000);
	};

	const handleClearNotification = () => {
		setMessage("");
		setShowNotification(false);
		setIsError(false);
	};

	return (
		<div className='popup-box forgot-popup-box'>
			<div className='box-heading'>
				{/* <img src={forgot_user_ic} alt='icon' /> */}
				<h2 className='h1'>{props.UserID == 0 ? "Forgot Password" : props.UserID == 1 ? "Reset Password" : "Change Password"}</h2>
				<button className='close-btn'>
					<img src={close_ic} alt='icon' onClick={props.closeModal} />
				</button>
			</div>
			<div className='box-content'>

				{props.UserID == 0 ?
					<Formik
						initialValues={{ username: props.forgotEmailID }}
						validationSchema={validateForgotpwdForm}
						validateOnMount={false}
						onSubmit={(values) => {
							if (!values.username.trim().match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
								handleNotification('Please Enter Valid Email Address', true)
								document.getElementById('username').focus();
							} else {
								wsForgotPassword(values.username);
								values.username = "";
							}
						}}>
						{({ values, handleSubmit, errors, touched }) => (
							<form onSubmit={handleSubmit} style={{ maxWidth: '500px', width: '90%' }}>
								<div
									className={`form-field ${errors && touched.username && errors.username && errors.username !== "" ? "error" : ""}`}>
									{/* <div style={{ textAlign: 'left', marginBottom: '2px' }}>
									<label>Please enter your registered email address:</label>
								</div> */}
									<Field as={TextField} error={touched.username && errors.username && errors.username !== "" ? true : false}
										label='Please enter your registered email address:'
										type="text"
										placeholder='name@domain.com'
										id={'username'}
										name='username'
										value={values.username}
										maxLength='50' />
									{errors && touched.username && errors.username && errors.username !== "" && (<span className='error-msg' style={{ top: "65%" }}>
										<ErrorMessage name='username' render={(err) => { return err; }} />
									</span>
									)}
								</div>

								<div className={IsLoading ? 'form-field button-field disable-btn' : 'form-field button-field'}>
									<input type='submit' value={IsLoading ? "Resetting..." : "Reset Password"} />
								</div>
							</form>
						)}
					</Formik>
					: props.UserID == 1 ?
						<div >
							{/* Change By nilesh */}
							{/* <label style={{ padding: '10px' }}>{props.forgotMessage}</label> */}
							<div style={{ display: 'grid', textAlign: 'left', padding: '0px 20px' }}>

								<label style={{ padding: '5px 10px' }}>{'The Practice entered has been previously registered for the REACH Portal.'}</label>
								<label onClick={() => window.location.href = `mailto:${props.forgotMessage}`} style={{ cursor: 'pointer', padding: '5px 10px' }}>{'Do you want to reset the Password? An email will be sent to the registered email address: '} <a href='${props.forgotMessage}'>{props.forgotMessage} </a></label>
								<label onClick={() => window.location.href = `mailto:support@bauschreach.com`} style={{ cursor: 'pointer', padding: '5px 10px' }}>{'If you wish to change this email address, please contact '}<a href='support@bauschreach.com'> support@bauschreach.com</a></label>
							</div>
							<div style={{ marginTop: '20px' }} >
								<div className={LoadingButton ? 'form-field button-field disable-btn' : 'form-field button-field'}>
									<input type='button' value={"Yes"} onClick={() => wsForgotPassword(props.forgotEmailID)} />
								</div>
								<div className={'form-field button-field'} style={{ margin: '10px 0 20px' }}>
									<input type='button' value={"No"} onClick={props.closeModal} />
								</div>
							</div>
						</div>
						: <Formik
							initialValues={{ password: "" }}
							validationSchema={validateForgotForm}
							validateOnMount={false}
							onSubmit={(values) => {
								if (values.password.length <= 5) {
									handleNotification('Password should be minimum 6 characters long', true)
									document.getElementById('password').focus();
								} else {
									wsSetPracticePassword(props.PracticeID, props.UserID, values.password);
									values.password = "";
								}
							}}>
							{({ values, handleSubmit, errors, touched }) => (
								<form onSubmit={handleSubmit}>
									<div className={`form-field ${errors && touched.password && errors.password && errors.password !== "" ? "error" : ""}`}>
										<Field as={TextField}
											error={touched.password && errors.password && errors.password !== "" ? true : false}
											label='Please enter Password'
											type='password'
											placeholder='******'
											id={'password'}
											name='password'
											value={values.password}
											maxLength='50' />
										{errors && touched.password && errors.password && errors.password !== "" && (<span className='error-msg' style={{ top: "65%" }}>
											<ErrorMessage name='password' render={(err) => { return err; }} />
										</span>
										)}
									</div>

									<div className={IsLoading ? 'form-field button-field disable-btn' : 'form-field button-field'}>
										<input type='submit' value={IsLoading ? "Updating..." : "Update Password"} />
									</div>
								</form>
							)}
						</Formik>
				}
			</div>
			<Notification
				isError={isError}
				message={message}
				showNotification={showNotification}
				closeNotification={handleClearNotification.bind(this)}
			/>
		</div>
	);
};
const validateForgotForm = yup.object().shape({
	password: yup.string().required("Password is required"),
});
const validateForgotpwdForm = yup.object().shape({
	username: yup.string().required("Email Address is required"),
});
export default ForgotPassword;


{/*  */ }