/*eslint-disable eqeqeq*/
import React from 'react';
import MyiFrame from "../../../../features/campaign/components/myiFrame";

const AssetViewer = props => {

    const [previewContent, setPreviewContent] = React.useState("");

    React.useEffect(() => {
        setPreviewContent(props.HTMLContent)
    }, [props])

    return (

        <section className="campaign-page" style={{ width: '100%' }}>
            <h2>{props.title}</h2>
            <div className="upload-asset-main">
                <div className="form_field" style={{ width: '100%', justifyContent: 'center' }}>
                    <div className="asset-full" style={{ marginTop: "10px" }}>
                        <MyiFrame HTMLContent={previewContent} />
                    </div>

                </div>
            </div>


        </section>

    )
}
export default AssetViewer;