/*eslint-disable eqeqeq*/
import React from "react";
import * as AppConstants from "../../../../config/AppConstant";
import * as APIController from "../../../../api/APIController.js";
import moment from 'moment';

import PageTitle from "../../../../config/pageTitle";
import TextField from "../../../../shared/components/TextField/TextField";
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";

class CostCalculatorReport extends React.Component {

    constructor() {
        super();
        this.state = {
            listData: [],
            progress: true,
            // StartDate: moment(new Date().toUTCString()).utcOffset(0, false).format('YYYY-MM-DD'),
            EndDate: moment(new Date().toUTCString()).utcOffset(0, false).format('YYYY-MM-DD'),
            StartDate: moment().startOf('month').format('YYYY-MM-DD')
        };
    }
    componentDidMount() {
        this.wsGetSupplyToolReportSummary(this.state.StartDate, this.state.EndDate)
    }

    wsGetSupplyToolReportSummary(StartDate, EndDate) {
        APIController.GetSupplyToolReportSummary(StartDate, EndDate)
            .then((response) => {
                if (response.error == null && response.object.data.length != 0) {
                    this.setState({
                        listData: response.object.data,
                        progress: false,
                    });
                } else { this.setState({ listData: [], progress: false }) }
            });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {

        return (
            <section className='content-right-main'>

                <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                    <h1><PageTitle Title="Summary / Average" /></h1>

                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                        <TextField style={{ margin: '10px' }} label='Start Date' type="date" name="StartDate" value={this.state.StartDate} onChange={this.handleChange} />

                        <TextField label='End Date' type="date" name="EndDate" value={this.state.EndDate} onChange={this.handleChange} />

                        <div className='button-field button-field-country' style={{ height: '40px', textAlign: 'center' }}>
                            <button onClick={() => { this.wsGetSupplyToolReportSummary(this.state.StartDate, this.state.EndDate); }}> Search </button>
                        </div>
                    </div>
                </div>

                {this.state.progress ?
                    <CircularProgress />
                    : <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className='two-col-sec' style={{ display: 'flex', justifyContent: 'space-between', width: '40%', marginRight: '5px' }}>
                            {this.state.listData.Summary.length &&
                                <>
                                    <div style={{ display: 'grid' }}>
                                        <label>Click on Cost Calculator Tool:</label>
                                        <label>Emailed Reports:</label>
                                        <label>Printed Reports:</label>
                                        <label>How Many Practices configured profile:</label>
                                    </div>
                                    <div style={{ display: 'grid' }}>
                                        <div>{this.state.listData.Summary[0].TotalClicks}</div>
                                        <div>{this.state.listData.Summary[0].EmailedReport}</div>
                                        <dvi>{this.state.listData.Summary[0].PrintedReport}</dvi>
                                        <dvi>{this.state.listData.Summary[0].PriceConfiguration}</dvi>
                                    </div>
                                </>
                            }
                        </div>
                        <div className='two-col-sec' style={{ width: '60%', marginLeft: '5px' }}>
                            <div style={{ display: 'grid', width: '90%', gridTemplateColumns: '60% 40%' }}>
                                <label>Brand</label>
                                <label>Avg Price Per Box</label>
                                {/* <label>Right Avg Price Per Box</label> */}
                                {this.state.listData.AverageCost.length &&
                                    this.state.listData.AverageCost.map((item) =>
                                        <>
                                            <div>{item.L_Brand}</div>
                                            <div>{item.L_AvgPricePerBox}</div>
                                            {/* <div>{item.R_AvgPricePerBox}</div> */}
                                        </>
                                    )}
                            </div>
                        </div>
                    </div>
                }

            </section>
        );
    }
}

export default CostCalculatorReport;