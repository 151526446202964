/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'
import * as APIController from '../../../../../api/APIController.js';
import * as yup from 'yup';
import Notification from '../../../../../shared/components/notification/notification';
import CircularProgress from '@material-ui/core/CircularProgress';

const LensestypeForm = props => {
    const initialValues = {
        lensestypeID: '', brandID: '',
        brandNameEdit: props.history.location.state.brandNameEdit,
        lensestypeName: props.history.location.state.lensestypeNameEdit,
        sequenceNo: props.history.location.state.sequenceNoEdit,
        active: props.history.location.state.lensestypeActiveEdit,
        isDailyLense: props.history.location.state.isDailyLenseEdit
    }
    const [isButtonValue, setButtonValue] = React.useState(false);

    const [dataSource, setdataSource] = React.useState([]);
    const [SequenceData, setSequenceData] = React.useState([]);
    const [Loading, setLoading] = React.useState(true);
    const [LoadingState, setLoadingState] = React.useState(false);
    const [IsLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        props.history.location.state.lensestypeIdEdit != 0 && wsGetLensesTypeByID(props.history.location.state.lensestypeIdEdit)

        wsGetBrandForDropdown();

        var orderSequenceNo = [];
        for (let i = 0; i < 10; i++) {
            orderSequenceNo.push({ ID: i + 1 });
        }
        setSequenceData(orderSequenceNo)

    }, [props])

    const wsGetLensesTypeByID = (ID) => {
        setLoadingState(true)
        APIController.GetLensesTypeByID(ID)
            .then((response) => {
                setLoading(false)
                if (response.error == null) {
                    initialValues.brandID = response.object.data[0].BrandID
                    initialValues.lensestypeName = response.object.data[0].Name
                    initialValues.sequenceNo = response.object.data[0].SequenceNo
                    initialValues.active = response.object.data[0].IsActive
                    initialValues.isDailyLense = response.object.data[0].IsDailyLense ? 1 : 0
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
                setLoadingState(false)
            })
    }


    const wsGetBrandForDropdown = () => {
        APIController.GetBrandForDropdown()
            .then((response) => {
                setLoading(false)
                if (response.error == null) {
                    setdataSource(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const wsAddEditLensesType = (ID, BrandID, Name, SequenceNo, IsActive, IsDailyLense) => {
        setIsLoading(true)
        APIController.AddEditLensesType(ID, BrandID, Name, SequenceNo, IsActive, IsDailyLense)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    handleNotification(response.object.message);
                    if (isButtonValue === true && response.object.status == 1) {
                        props.history.push({ pathname: '/supplytool/lensestype/list', state: { showMessage: response.object.message } })
                    } else if (response.object.status == 1) {
                        handleNotification(response.object.message);
                    } else {
                        handleNotification(response.object.message, true);
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const [message, setMessage] = React.useState("")
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    return (
        <>
            <section className="content-right-main">
                {props.history.location.state.lensestypeIdEdit == 0 ? <h1>Add Lenses Type</h1> : <h1>Edit Lenses Type</h1>}
                <Formik
                    // enableReinitialize={false}
                    initialValues={initialValues}
                    validationSchema={validateLensestypeForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        wsAddEditLensesType(props.history.location.state.lensestypeIdEdit, values.brandID, values.lensestypeName, values.sequenceNo, values.active, values.isDailyLense)
                        if (isButtonValue === false) {
                            values.lensestypeName = "";
                            values.active = 1;
                            values.isDailyLense = 0;
                        }
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        setFieldValue,
                        errors,
                        touched,
                    }) => {
                        return (
                            <div className="form-main">
                                {LoadingState ? <CircularProgress style={{ alignItems: 'center' }} />
                                    : <form onSubmit={handleSubmit}>
                                        <div className={`form_field ${errors && touched.brandID && errors.brandID && errors.brandID !== '' ? 'error' : ''}`}>
                                            <div className="field-left">
                                                <label>Brand</label>
                                            </div>
                                            <div className="field-right">
                                                {Loading ? <CircularProgress style={{ alignItems: 'center' }} />
                                                    : <select name='brandID'
                                                        defaultValue={values.brandID}
                                                        value={values.brandID}
                                                        onChange={(e) => { setFieldValue('brandID', e.target.value); }}>
                                                        <option value={''}>Select Brand</option>
                                                        {dataSource.map((obj, index) => {
                                                            return <option key={index} selected={obj.ID == values.brandID ? obj.ID : obj.Name} value={obj.ID} >{obj.Name}</option>
                                                        })}
                                                    </select>}
                                                {errors && touched.brandID && errors.brandID && errors.brandID !== '' &&
                                                    (<span className='error-msg'>
                                                        <ErrorMessage name='brandID' render={(err) => { return err; }} />
                                                    </span>)}
                                            </div>
                                        </div>

                                        <div className={`form_field ${errors && touched.sequenceNo && errors.sequenceNo && errors.sequenceNo !== '' ? 'error' : ''}`}>
                                            <div className="field-left">
                                                <label>Sequence #</label>
                                            </div>
                                            <div className="field-right">
                                                {LoadingState ? <CircularProgress style={{ alignItems: 'center' }} />
                                                    : <select name='sequenceNo'
                                                        defaultValue={values.sequenceNo}
                                                        value={values.sequenceNo}
                                                        onChange={(e) => {
                                                            setFieldValue('sequenceNo', e.target.value);
                                                        }}>
                                                        <option value={''}>Sequence #</option>
                                                        {SequenceData.map((obj, index) => {
                                                            return <option selected={obj.ID == values.sequenceNo ? obj.ID : obj.ID} value={obj.ID} key={index}>{obj.ID}</option>
                                                        })}
                                                    </select>}
                                                {errors && touched.sequenceNo && errors.sequenceNo && errors.sequenceNo !== '' &&
                                                    (<span className='error-msg'>
                                                        <ErrorMessage name='sequenceNo' render={(err) => { return err; }} />
                                                    </span>)}
                                            </div>
                                        </div>

                                        <div className={`form_field ${errors && touched.lensestypeName && errors.lensestypeName && errors.lensestypeName !== '' ? 'error' : ''}`}>
                                            <div className="field-left">
                                                <label>Lenses Type</label>
                                            </div>
                                            <div className="field-right">
                                                <Field type="text" placeholder="Enter Lenses Type" name="lensestypeName" value={values.lensestypeName} maxLength="100" />
                                                {errors && touched.lensestypeName && errors.lensestypeName && errors.lensestypeName !== '' &&
                                                    (<span className='error-msg'>
                                                        <ErrorMessage name='lensestypeName' render={(err) => { return err; }} />
                                                    </span>)}
                                            </div>
                                        </div>

                                        <div className="form_field">
                                            <div className="field-left">
                                                <label>Is Active ?</label>
                                            </div>
                                            <div className="field-right">
                                                <div className="custom-radio">
                                                    <input type="radio" name="active" id="yes-text"
                                                        defaultValue={values.active}
                                                        defaultChecked={values.active != 0 ? true : false}
                                                        onClick={(e) => setFieldValue('active', 1)} />
                                                    <label htmlFor="yes-text">Yes</label>
                                                </div>
                                                <div className="custom-radio">
                                                    <input type="radio" name="active" id="no-text"
                                                        defaultValue={values.active}
                                                        defaultChecked={values.active == 0 ? true : false}
                                                        onClick={(e) => setFieldValue('active', 0)} />
                                                    <label htmlFor="no-text">No</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form_field">
                                            <div className="field-left">
                                                <label>Is Daily Lense ?</label>
                                            </div>
                                            <div className="field-right">
                                                <div className="custom-radio">
                                                    <input type="radio" name="isDailyLense" id="dyes-text"
                                                        defaultValue={values.isDailyLense}
                                                        defaultChecked={values.isDailyLense != 0 ? true : false}
                                                        onClick={(e) => setFieldValue('isDailyLense', 1)} />
                                                    <label htmlFor="dyes-text">Yes</label>
                                                </div>
                                                <div className="custom-radio">
                                                    <input type="radio" name="isDailyLense" id="dno-text"
                                                        defaultValue={values.isDailyLense}
                                                        defaultChecked={values.isDailyLense == 0 ? true : false}
                                                        onClick={(e) => setFieldValue('isDailyLense', 0)} />
                                                    <label htmlFor="dno-text">No</label>
                                                </div>
                                            </div>
                                        </div>
                                        <Notification
                                            isError={isError}
                                            message={message}
                                            showNotification={showNotification}
                                            clearNotification={handleClearNotification.bind(this)}
                                            closeNotification={handleClearNotification.bind(this)}
                                        />
                                        <div className="form-submit">
                                            <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                                <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" onClick={() => setButtonValue(true)} />
                                            </div>
                                            {props.history.location.state.countryIdEdit == 0 ?
                                                <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                                    <input type="submit" value={IsLoading ? "Saving..." : "save & add new"} name="save & add new" onClick={() => setButtonValue(false)} />
                                                </div> : null}
                                            <div className="button-field ">
                                                <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                            </div>
                                        </div>
                                    </form>}
                            </div>
                        )
                    }}
                </Formik>
            </section>
        </>
    )
}
const validateLensestypeForm = yup.object().shape({
    brandID: yup.string().required('Brand is required'),
    sequenceNo: yup.string().required('Sequence # is required'),
    lensestypeName: yup.string().required('Lenses Type is required'),
});

export default LensestypeForm;