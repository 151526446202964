/*eslint-disable eqeqeq*/
import React from "react";
import Table from "../../../../../shared/components/table/table";
import TableHead from "../../../../../shared/components/table/tableHead";
import TableRow from "../../../../../shared/components/table/tableRow";
import TableBody from "../../../../../shared/components/table/tableBody";
import TableCell from "../../../../../shared/components/table/tableCell";
import double_left_arrow from '../../../../../assets/images/left-doble-arow.svg';
import left_arrow from '../../../../../assets/images/left-arrow.svg';
import double_right_arrow from '../../../../../assets/images/right-doble-arow.svg';
import right_arrow from '../../../../../assets/images/right-arrow.svg';
import "react-perfect-scrollbar/dist/css/styles.css";

import PerfectScrollbar from "react-perfect-scrollbar";

import CircularProgress from "../../../../../shared/components/CircularProgress/CircularProgress";

const SupplyToolReportList = (props) => {
	// "Date",	"Practice Name",	"New Wearer/Existing Wearer",	"Contact Lens Type - Left Eye",	"Contact Lens Type - Right Eye",	"Retail Price Annual Supply",	"Rebate Value Annual Supply",	"Additional Discount Annual Supply",	"Insurance Coverage Annual Supply",	"Price Per Box Left Eye Annual Supply",	"Price Per Box Right Eye Annual Supply",	"Here's What You'll Pay Annual Supply",	"Retail Price 6 Month Supply",	"Rebate Value 6 Month Supply",	"Additional Discount 6 Month Supply",	"Insurance Coverage 6 Month Supply",	"Price Per Box Left Eye 6 Month Supply",	"Price Per Box Right Eye 6 Month Supply",	"Here's What You'll Pay 6 Month Supply"

	const headerList = ["Practice Name", "Contact Lens Type", "Jan 2023", "Feb 2023", "Mar 2023", "April 2023", "May 2023", "June 2023", "July 2023", "Aug 2023", "Sep 2023", "Oct 2023", "Nov 2023", "Dec 2023"]
	const { currentPage, count, rowsPerPage, rowsPerPageOptions, list, to, from } = props;
	let lensestypeList = list;

	const [PracticeName, setPracticeName] = React.useState(null);
	const [LenseType, setLenseType] = React.useState(null);
	const [ReportYear, setReportYear] = React.useState(new Date().getFullYear());

	const page = rowsPerPage == 1000000 ? 1 : Math.floor(count / rowsPerPage) + 1;
	// const page = Math.floor(count / rowsPerPage) + 1;

	const handleChangeInCurrentPage = (value) => {
		if (value <= page && Number(parseInt(value))) {
			props.handleChangePage(Number(value), PracticeName, LenseType, ReportYear);
		}
	}

	const _handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			props.applyFilter(PracticeName, LenseType, ReportYear)
		}
	}

	return (
		<>
			<div className="table-main-wrap">
				<PerfectScrollbar component='div'>
					<Table className="">
						<TableHead>
							<TableRow>
								{headerList.map((obj, index) => { return <TableCell key={index}>{obj}</TableCell>; })}
							</TableRow>
							<TableRow>
								<TableCell style={{ minWidth: '200px', width: '200px' }}> <input type="text" onKeyDown={_handleKeyDown} onChange={(e) => setPracticeName(e.target.value == "" ? null : e.target.value)} /> </TableCell>
								<TableCell> <input type="text" onKeyDown={_handleKeyDown} onChange={(e) => setLenseType(e.target.value == "" ? null : e.target.value)} /> </TableCell>
								{/* <TableCell> <input type="text" onKeyDown={_handleKeyDown} onChange={(e) => setPurchaseType(e.target.value == "" ? null : e.target.value)} /> </TableCell> */}
							</TableRow>
						</TableHead>
						<TableBody>
							{!!lensestypeList.length ? lensestypeList.map((obj, idnex) => {
								return (
									<TableRow key={idnex}>
										<TableCell> {obj.PracticeName} </TableCell>
										<TableCell> {obj.LenseType} </TableCell>

										<TableCell> {obj.Jan} </TableCell>
										<TableCell> {obj.Feb} </TableCell>
										<TableCell> {obj.Mar} </TableCell>
										<TableCell> {obj.Apr} </TableCell>
										<TableCell> {obj.May} </TableCell>
										<TableCell> {obj.Jun} </TableCell>

										<TableCell> {obj.Jul} </TableCell>
										<TableCell> {obj.Aug} </TableCell>
										<TableCell> {obj.Sep} </TableCell>
										<TableCell> {obj.Oct} </TableCell>
										<TableCell> {obj.Nov} </TableCell>
										<TableCell> {obj.Dec} </TableCell>
									</TableRow>
								);
							}) : <TableRow>
								<TableCell colSpan={headerList.length} className="not_found">
									{props.progress ? <CircularProgress /> : <label>Not Currently Available</label>}
								</TableCell>
							</TableRow>}
						</TableBody>
					</Table>
				</PerfectScrollbar>
			</div>

			<div className="table-pagination">
				<div className="pagination-info">
					<p>Showing {from + 1} - {to > count ? count : to} of {count}</p>
				</div>
				<div className="pagination-control">
					<a href="#!" title="" className={`double-left-arrow ${props.currentPage == 1 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(1)
						}}
					><img src={double_left_arrow} alt="left" /></a>
					<a href="#!" title="" className={`simple-left-arrow ${props.currentPage == 1 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage - 1)
						}}
					><img src={left_arrow} alt="single_left" /></a>
					<span>Page</span>
					<input disabled type="text" name="textbox" value={currentPage === 0 ? currentPage + 1 : currentPage} onChange={(e) => handleChangeInCurrentPage(e.target.value)} />
					<span>of {page}</span>
					<a href="#!" title="" className={`simple-right-arrow ${currentPage == page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage + 1)
						}}
					><img src={right_arrow} alt="right" /></a>
					<a href="#!" title="" className={`double-right-arrow ${currentPage == page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(page)
						}}><img src={double_right_arrow} alt="single_right" /></a>
					<select onChange={(e) => props.handleChangeRowsPerPage(parseInt(e.target.value), PracticeName, LenseType, ReportYear)} defaultValue={rowsPerPage}>
						{
							rowsPerPageOptions.map((obj, index) => {
								return <option value={obj} key={index}>{obj}</option>
							})
						}
					</select>
				</div>
			</div>
		</>
	);
};

export default SupplyToolReportList;
