import React from "react";
import * as AppConstants from "../../../../config/AppConstant";
import newicon from '../../../../assets/images/icon/newicon.png'

const TilesForFreeAssets = (props) => {
    const { index, obj, setmodal, PreviewButtonName, wsDownloadAsset, CopyClipboard, wsGetAutomationCalendarForPractice, isLoading } = props;
    return (
        <div className="content-box free-asset-content-box" style={{ width: '245px' }} key={index}>
            <div className="box-image free-asset-image-box" style={{ backgroundImage: `url(${obj.PreviewURL} )` }}>
                {obj.IsNew == 1 &&
                    <div style={{ position: 'absolute', right: '5px', top: '5px', width: '50px', zIndex: -1 }}>
                        <img src={newicon}></img>
                    </div>}
                <div className="button-hover">
                    <div className="button-field">
                        <button onClick={() => setmodal({
                            isModalOpen: true,
                            content: { image: obj.ImageURL, AdContent: obj.AdContent, FullObject: obj },
                        })}>{PreviewButtonName}</button>
                    </div>
                    {/* {obj.ShowDownload == 1 &&
                        <div className={downloadloading ? "button-field disable-btn" : "button-field"}>
                            <button onClick={(e) => wsDownloadAsset(obj.ImageURL, obj.Name + '.' + obj.ImageURL.split('.').pop().split('?')[0], obj.ID)}>
                                {downloadloading ? AppConstants.ButtonText.Downloading : AppConstants.ButtonText.Download}
                            </button>
                        </div>} */}
                    {obj.IsCustomizable && <div className="button-field">
                        <button onClick={(e) => {
                            props.history.push(obj.CustomizePath)
                            AppConstants.SetLocalStorage("localStorageFreeAssetID", obj.ID)
                            AppConstants.SetLocalStorage("localStorageBudgetDetail", obj.CustomizePath == "/digital-product/selection/location-selection" ? JSON.stringify([{ "budget": 1, "duration": 1 }]) : JSON.stringify([{ "budget": 1, "duration": "" }]))
                        }}>{AppConstants.ButtonText.Customize}</button>
                    </div>}
                    {/* {AppConstants.GetLocalStorage('localStorageShowCopyText') == "1" && obj.Description != "" && obj.Description != null &&
                        <div className="button-field">
                            <button onClick={(e) => CopyClipboard(obj.Description, obj.Name)}>{AppConstants.ButtonText.CopyText}</button>
                        </div>} */}
                    {obj.ExternalURL != "" && obj.ExternalURL != null && obj.ExternalURL != undefined &&
                        <div className="button-field">
                            <button onClick={(e) => window.open(obj.ExternalURL, "_blank")}>{"Visit"}</button>
                        </div>}
                    {obj.ShowAssetsInSocialAutomation &&
                        <div className={isLoading ? "button-field disable-btn" : "button-field"}>
                            <button onClick={(e) => wsGetAutomationCalendarForPractice(AppConstants.GetLocalStorage("localStoragepracticeId"), AppConstants.GetLocalStorage("localStorageAutomationSubscriptionID"), obj.ImageURL.split('?')[0].split('/').pop(), obj.ImageURL, obj.Description)}>
                                {AppConstants.ButtonText.AddCalendar}
                            </button>
                        </div>}
                </div>
            </div>

            <div className="box-content-info" style={{ padding: '5px 5px' }}>
                {obj.BrandName != undefined && <p>
                    {obj.BrandName.split('®')[0]}
                    {obj.BrandName.match(/®/g) ? <sup>&reg;</sup> : ''}
                    {obj.BrandName.split('®')[1]}
                </p>}
                <h4>{obj.Name}</h4>
            </div>
        </div>
    )
}

export default TilesForFreeAssets;