/*eslint-disable eqeqeq*/
import React from "react";
import Table from "../../../../../shared/components/table/table";
import TableHead from "../../../../../shared/components/table/tableHead";
import TableRow from "../../../../../shared/components/table/tableRow";
import TableBody from "../../../../../shared/components/table/tableBody";
import TableCell from "../../../../../shared/components/table/tableCell";
import double_left_arrow from '../../../../../assets/images/left-doble-arow.svg';
import left_arrow from '../../../../../assets/images/left-arrow.svg';
import double_right_arrow from '../../../../../assets/images/right-doble-arow.svg';
import right_arrow from '../../../../../assets/images/right-arrow.svg';
import "react-perfect-scrollbar/dist/css/styles.css";

import PerfectScrollbar from "react-perfect-scrollbar";

import CircularProgress from "../../../../../shared/components/CircularProgress/CircularProgress";
import PageTitle from "../../../../../config/pageTitle";

const SupplyLeadsList = (props) => {
	// "Date",	"Practice Name",	"New Wearer/Existing Wearer",	"Contact Lens Type - Left Eye",	"Contact Lens Type - Right Eye",	"Retail Price Annual Supply",	"Rebate Value Annual Supply",	"Additional Discount Annual Supply",	"Insurance Coverage Annual Supply",	"Price Per Box Left Eye Annual Supply",	"Price Per Box Right Eye Annual Supply",	"Here's What You'll Pay Annual Supply",	"Retail Price 6 Month Supply",	"Rebate Value 6 Month Supply",	"Additional Discount 6 Month Supply",	"Insurance Coverage 6 Month Supply",	"Price Per Box Left Eye 6 Month Supply",	"Price Per Box Right Eye 6 Month Supply",	"Here's What You'll Pay 6 Month Supply"

	const headerList = ["Date", "Practice Name", "New Wearer/Existing Wearer", "Contact Lens Type - Left Eye", "Contact Lens Type - Right Eye",
		"Retail Price Annual Supply", "Rebate Value Annual Supply", "Additional Discount Annual Supply", "Insurance Coverage Annual Supply", "Price Per Box Left Eye Annual Supply",
		"Price Per Box Right Eye Annual Supply", "Here's What You'll Pay Annual Supply", "Retail Price 6 Month Supply", "Rebate Value 6 Month Supply", "Additional Discount 6 Month Supply",
		"Insurance Coverage 6 Month Supply", "Price Per Box Left Eye 6 Month Supply", "Price Per Box Right Eye 6 Month Supply", "Here's What You'll Pay 6 Month Supply"]
	const { currentPage, count, rowsPerPage, rowsPerPageOptions, list, to, from } = props;
	let lensestypeList = list;

	// LeadAt, PracticeName, PurchaseType, LeftLenseType, RightLenseType,
	// 	L_RetailPrice, L_RebatePrice, L_AdditionalDiscount, L_InsuranceCoverage, L_PricePerBox, L_R_PricePerBox, L_YouPay,
	// 	R_RetailPrice, R_RebatePrice, R_AdditionalDiscount, R_InsuranceCoverage, R_L_PricePerBox, R_PricePerBox, R_YouPay

	const [LeadAt, setLeadAt] = React.useState(null);
	const [PracticeName, setPracticeName] = React.useState(null);
	const [PurchaseType, setPurchaseType] = React.useState('');
	const [LeftLenseType, setLeftLenseType] = React.useState(null);
	const [RightLenseType, setRightLenseType] = React.useState(null);

	const [L_RetailPrice, setL_RetailPrice] = React.useState(null);
	const [L_RebatePrice, setL_RebatePrice] = React.useState(null);
	const [L_AdditionalDiscount, setL_AdditionalDiscount] = React.useState(null);
	const [L_InsuranceCoverage, setL_InsuranceCoverage] = React.useState(null);
	const [L_PricePerBox, setL_PricePerBox] = React.useState(null);
	const [L_R_PricePerBox, setL_R_PricePerBox] = React.useState(null);
	const [L_YouPay, setL_YouPay] = React.useState(null);

	const [R_RetailPrice, setR_RetailPrice] = React.useState(null);
	const [R_RebatePrice, setR_RebatePrice] = React.useState(null);
	const [R_AdditionalDiscount, setR_AdditionalDiscount] = React.useState(null);
	const [R_InsuranceCoverage, setR_InsuranceCoverage] = React.useState(null);
	const [R_L_PricePerBox, setR_L_PricePerBox] = React.useState(null);
	const [R_PricePerBox, setR_PricePerBox] = React.useState(null);
	const [R_YouPay, setR_YouPay] = React.useState(null);


	const page = rowsPerPage == 1000000 ? 1 : Math.floor(count / rowsPerPage) + 1;
	// const page = Math.floor(count / rowsPerPage) + 1;

	const handleChangeInCurrentPage = (value) => {
		if (value <= page && Number(parseInt(value))) {
			props.handleChangePage(Number(value), LeadAt, PracticeName, PurchaseType, LeftLenseType, RightLenseType,
				L_RetailPrice, L_RebatePrice, L_AdditionalDiscount, L_InsuranceCoverage, L_PricePerBox, L_R_PricePerBox, L_YouPay,
				R_RetailPrice, R_RebatePrice, R_AdditionalDiscount, R_InsuranceCoverage, R_L_PricePerBox, R_PricePerBox, R_YouPay);
		}
	}

	const _handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			props.applyFilter(LeadAt, PracticeName, PurchaseType, LeftLenseType, RightLenseType,
				L_RetailPrice, L_RebatePrice, L_AdditionalDiscount, L_InsuranceCoverage, L_PricePerBox, L_R_PricePerBox, L_YouPay,
				R_RetailPrice, R_RebatePrice, R_AdditionalDiscount, R_InsuranceCoverage, R_L_PricePerBox, R_PricePerBox, R_YouPay)
		}
	}

	return (
		<>
			<h1><PageTitle Title="Activity by Practice" /></h1>
			<div className="table-main-wrap">
				<PerfectScrollbar component='div'>
					<Table className="">
						<TableHead>
							<TableRow>
								{headerList.map((obj, index) => { return <TableCell key={index}>{obj}</TableCell>; })}
							</TableRow>
							<TableRow>
								<TableCell style={{ minWidth: '150px', width: '150px' }}> <input type="text" onKeyDown={_handleKeyDown} onChange={(e) => setLeadAt(e.target.value == "" ? null : e.target.value)} /> </TableCell>
								<TableCell> <input type="text" onKeyDown={_handleKeyDown} onChange={(e) => setPracticeName(e.target.value == "" ? null : e.target.value)} /> </TableCell>
								{/* <TableCell> <input type="text" onKeyDown={_handleKeyDown} onChange={(e) => setPurchaseType(e.target.value == "" ? null : e.target.value)} /> </TableCell> */}
								<TableCell>
									<select onChange={(e) => {
										setPurchaseType(e.target.value)
										props.applyFilter(LeadAt, PracticeName, e.target.value, LeftLenseType, RightLenseType, L_RetailPrice, L_RebatePrice, L_AdditionalDiscount, L_InsuranceCoverage, L_PricePerBox, L_R_PricePerBox, L_YouPay, R_RetailPrice, R_RebatePrice, R_AdditionalDiscount, R_InsuranceCoverage, R_L_PricePerBox, R_PricePerBox, R_YouPay)
									}}>
										<option value={""}>All</option>
										<option value={"1"}>New</option>
										<option value={"2"}>Old</option>
									</select>
								</TableCell>

								<TableCell> <input type="text" onKeyDown={_handleKeyDown} onChange={(e) => setLeftLenseType(e.target.value == "" ? null : e.target.value)} /> </TableCell>
								<TableCell> <input type="text" onKeyDown={_handleKeyDown} onChange={(e) => setRightLenseType(e.target.value == "" ? null : e.target.value)} /> </TableCell>

								<TableCell> <input type="text" onKeyDown={_handleKeyDown} onChange={(e) => setL_RetailPrice(e.target.value == "" ? null : e.target.value)} /> </TableCell>
								<TableCell> <input type="text" onKeyDown={_handleKeyDown} onChange={(e) => setL_RebatePrice(e.target.value == "" ? null : e.target.value)} /> </TableCell>
								<TableCell> <input type="text" onKeyDown={_handleKeyDown} onChange={(e) => setL_AdditionalDiscount(e.target.value == "" ? null : e.target.value)} /> </TableCell>
								<TableCell> <input type="text" onKeyDown={_handleKeyDown} onChange={(e) => setL_InsuranceCoverage(e.target.value == "" ? null : e.target.value)} /> </TableCell>
								<TableCell> <input type="text" onKeyDown={_handleKeyDown} onChange={(e) => setL_PricePerBox(e.target.value == "" ? null : e.target.value)} /> </TableCell>
								<TableCell> <input type="text" onKeyDown={_handleKeyDown} onChange={(e) => setL_R_PricePerBox(e.target.value == "" ? null : e.target.value)} /> </TableCell>
								<TableCell> <input type="text" onKeyDown={_handleKeyDown} onChange={(e) => setL_YouPay(e.target.value == "" ? null : e.target.value)} /> </TableCell>

								<TableCell> <input type="text" onKeyDown={_handleKeyDown} onChange={(e) => setR_RetailPrice(e.target.value == "" ? null : e.target.value)} /> </TableCell>
								<TableCell> <input type="text" onKeyDown={_handleKeyDown} onChange={(e) => setR_RebatePrice(e.target.value == "" ? null : e.target.value)} /> </TableCell>
								<TableCell> <input type="text" onKeyDown={_handleKeyDown} onChange={(e) => setR_AdditionalDiscount(e.target.value == "" ? null : e.target.value)} /> </TableCell>
								<TableCell> <input type="text" onKeyDown={_handleKeyDown} onChange={(e) => setR_InsuranceCoverage(e.target.value == "" ? null : e.target.value)} /> </TableCell>
								<TableCell> <input type="text" onKeyDown={_handleKeyDown} onChange={(e) => setR_L_PricePerBox(e.target.value == "" ? null : e.target.value)} /> </TableCell>
								<TableCell> <input type="text" onKeyDown={_handleKeyDown} onChange={(e) => setR_PricePerBox(e.target.value == "" ? null : e.target.value)} /> </TableCell>
								<TableCell> <input type="text" onKeyDown={_handleKeyDown} onChange={(e) => setR_YouPay(e.target.value == "" ? null : e.target.value)} /> </TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!!lensestypeList.length ? lensestypeList.map((obj, idnex) => {
								return (
									<TableRow key={idnex}>
										<TableCell> {obj.LeadAt} </TableCell>
										<TableCell> {obj.PracticeName} </TableCell>
										<TableCell> {obj.PurchaseType} </TableCell>
										<TableCell> {obj.LeftLenseType} </TableCell>
										<TableCell> {obj.RightLenseType} </TableCell>

										<TableCell> {obj.L_RetailPrice} </TableCell>
										<TableCell> {obj.L_RebatePrice} </TableCell>
										<TableCell> {obj.L_AdditionalDiscount} </TableCell>
										<TableCell> {obj.L_InsuranceCoverage} </TableCell>
										<TableCell> {obj.L_PricePerBox} </TableCell>
										<TableCell> {obj.L_R_PricePerBox} </TableCell>
										<TableCell> {obj.L_YouPay} </TableCell>

										<TableCell> {obj.R_RetailPrice} </TableCell>
										<TableCell> {obj.R_RebatePrice} </TableCell>
										<TableCell> {obj.R_AdditionalDiscount} </TableCell>
										<TableCell> {obj.R_InsuranceCoverage} </TableCell>
										<TableCell> {obj.R_L_PricePerBox} </TableCell>
										<TableCell> {obj.R_PricePerBox} </TableCell>
										<TableCell> {obj.R_YouPay} </TableCell>
									</TableRow>
								);
							}) : <TableRow>
								<TableCell colSpan={headerList.length} className="not_found">
									{props.progress ? <CircularProgress /> : <label>Not Currently Available</label>}
								</TableCell>
							</TableRow>}
						</TableBody>
					</Table>
				</PerfectScrollbar>
			</div>

			<div className="table-pagination">
				<div className="pagination-info">
					<p>Showing {from + 1} - {to > count ? count : to} of {count}</p>
				</div>
				<div className="pagination-control">
					<a href="#!" title="" className={`double-left-arrow ${props.currentPage == 1 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(1)
						}}
					><img src={double_left_arrow} alt="left" /></a>
					<a href="#!" title="" className={`simple-left-arrow ${props.currentPage == 1 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage - 1)
						}}
					><img src={left_arrow} alt="single_left" /></a>
					<span>Page</span>
					<input disabled type="text" name="textbox" value={currentPage === 0 ? currentPage + 1 : currentPage} onChange={(e) => handleChangeInCurrentPage(e.target.value)} />
					<span>of {page}</span>
					<a href="#!" title="" className={`simple-right-arrow ${currentPage == page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage + 1)
						}}
					><img src={right_arrow} alt="right" /></a>
					<a href="#!" title="" className={`double-right-arrow ${currentPage == page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(page)
						}}><img src={double_right_arrow} alt="single_right" /></a>
					<select onChange={(e) => props.handleChangeRowsPerPage(parseInt(e.target.value), LeadAt, PracticeName, PurchaseType, LeftLenseType, RightLenseType,
						L_RetailPrice, L_RebatePrice, L_AdditionalDiscount, L_InsuranceCoverage, L_PricePerBox, L_R_PricePerBox, L_YouPay,
						R_RetailPrice, R_RebatePrice, R_AdditionalDiscount, R_InsuranceCoverage, R_L_PricePerBox, R_PricePerBox, R_YouPay)} defaultValue={rowsPerPage}>
						{
							rowsPerPageOptions.map((obj, index) => {
								return <option value={obj} key={index}>{obj}</option>
							})
						}
					</select>
				</div>
			</div>
		</>
	);
};

export default SupplyLeadsList;
