const styles = theme => ({
  portalDashboardPageWrapper: {
    padding: '0 16 16 16',
    minHeight: '100%',
    boxSizing: 'border-box'
  },
  portalWidget: {
    flex: '1 1 100%',
    display: 'flex',
    padding: 16,
    flexDirection: 'column'
  },
  portalWidgetHeading: {

    fontSize: '18px',
    fontWeight: 'bold',
    fontFamily:'Raleway',
    color: '#4B4C4C',
    borderLeftStyle: 'solid',
    '&:after': {
      content: '""',
      width: 2,
      height: '0%',
      position: 'absolute',
      bottom: 0,
      left: -2,
      transition: 'height .5s'
    }
  },
  portalWidgetContent: {
    flex: '1 1 100%'
  },
  contentTitle: {
    fontSize: '25px',
    fontWeight: 'bold',
    color: '#0A5C7F',
    marginBottom: '5px'

  },
  content: {
    fontSize: '12px',
    color: '#646464',
    // lineHeight:1.5,

  },
  percentContain:{
    fontSize: '25px',
    fontWeight: 'bold',
    color: '#02ABAE',
    fontFamily:'Raleway'
  }
});

export default styles;
