/*eslint-disable eqeqeq*/
import React from 'react';
import GetLeadsList from '../../component/componentGetLeads/getLeadslist';
// import {data} from './data'
import * as APIController from '../../../../api/APIController';
// import * as APIController from '../../../../src/api/APIController';
import CircularProgress from '@material-ui/core/CircularProgress';

import Notification from "../../../../shared/components/notification/notification";
class GetLeadsContainer extends React.Component {

    state = {
        currentPage: 1,
        count: 0,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 'All'],
        list: [],
        from: 0,
        to: 10,
        progress: true,
        message: "",
        showNotification: false,
        isError: false,
        showMessage:
            this.props.history.location.state != null &&
            this.props.history.location.state.showMessage,
    }

    componentDidMount() {
        this.wsGetLeads(this.state.currentPage, this.state.rowsPerPage, this.state.from, this.state.to);
        this.state.showMessage != "" &&
            this.handleNotification(this.state.showMessage);
        // this.props.history.replace("/clinic/importedpractices", null);
    }

    wsGetLeads(CurrentPage, RecordPerPage, from, to, PracticeName, Location, Brand, LeadGeneratedAt, Type, EmailAddress) {
        this.setState({ progress: true, });
        APIController.GetLeads(CurrentPage, RecordPerPage, PracticeName, Location, Brand, LeadGeneratedAt, Type, EmailAddress)
            .then((response) => {
                if (response.error == null && response.object.data.length != 0) {
                    this.setState({
                        to: isNaN(to) ? 1000000 : to,
                        // rowsPerPage: isNaN(to) ? 1000000 : to,
                        from: from,
                        count: response.object.data[0].MaxRows,
                        list: response.object.data,
                        progress: false,
                    })
                } else { this.setState({ list: [], progress: false }) }
            })
    }

    render() {
        return (
            <div>
                <section className="content-right-main">

                    <GetLeadsList
                        {...this.state}
                        history={this.props.history}
                        handleChangeRowsPerPage={this.handleRowsPerPageChange}
                        handleChangePage={this.handlePageChange}
                        applyFilter={this.handleApplyFilter}
                    />
                </section>
                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    clearNotification={this.handleClearNotification}
                    closeNotification={this.handleClearNotification}
                />
            </div>
        )
    }

    handleRowsPerPageChange = (value, PracticeName, Location, Brand, LeadGeneratedAt, Type, EmailAddress) => {
        let from = 0
        let to = value;
        this.setState({ rowsPerPage: value, currentPage: 1, to: to, from: from })
        this.wsGetLeads(isNaN(value) ? '' : this.state.currentPage, isNaN(value) ? 1000000 : value, from, to, PracticeName, Location, Brand, LeadGeneratedAt, Type, EmailAddress);
    }

    handlePageChange = (values, PracticeName, Location, Brand, LeadGeneratedAt, Type, EmailAddress) => {

        let from = values === 1 ? 0 * this.state.rowsPerPage : (values - 1) * this.state.rowsPerPage
        let to = values === 1 ? this.state.rowsPerPage : (from + this.state.rowsPerPage);
        this.setState({ currentPage: Number(values) })
        this.wsGetLeads(Number(values), this.state.rowsPerPage, from, to, PracticeName, Location, Brand, LeadGeneratedAt, Type, EmailAddress);
    }

    handleApplyFilter = (PracticeName, Location, Brand, LeadGeneratedAt, Type, EmailAddress) => {
        this.wsGetLeads(this.state.currentPage, this.state.rowsPerPage, this.state.from, this.state.to, PracticeName, Location, Brand, LeadGeneratedAt, Type, EmailAddress)
    }

    handleNotification = (message, isError) => {
        this.setState({
            message: message,
            showNotification: true,
            isError: isError,
        });

        setTimeout(() => {
            this.handleClearNotification();
        }, 4000);
    };

    handleClearNotification = () => {
        this.setState({
            message: "",
            showNotification: false,
            isError: false,
        });
    };
}

export default GetLeadsContainer;