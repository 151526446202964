/*eslint-disable eqeqeq*/
import React from "react";
import LandingPageUserList from "../../component/componentLandingPageUser/landingPageUserlist";
import * as APIController from "../../../../api/APIController.js";
import pageTitle from "../../../../config/pageTitle";
import Notification from "../../../../shared/components/notification/notification";


class LandingPageUserContainer extends React.Component {
	state = {
		currentPage: 1,
		count: 0,
		list: [],
		list1: [],
		rowsPerPage: 10,
		rowsPerPageOptions: [10, 25, 50, 'All'],
		from: 0,
		to: 10,
		progress: true,
		PostcodeArray: [],
		message: '',
		showNotification: false,
		isError: false,
		showMessage: this.props.history.location.state != null && this.props.history.location.state.showMessage
	};

	componentDidMount() {
		this.wsGetLandingPageUsers(this.state.currentPage, this.state.rowsPerPage, this.state.from, this.state.to);
		this.state.showMessage != "" && this.handleNotification(this.state.showMessage);
		this.props.history.replace("/landingPageUsers", null);
	}

	handlebind() {
		this.wsGetLandingPageUsers(this.state.currentPage, this.state.rowsPerPage);
	}
	wsGetLandingPageUsers(CurrentPage, RecordPerPage, from, to, PracticeName, FullName, EmailAddress, ShipToNo, Postcode, Address, City) {
		this.setState({ progress: true, });
		APIController.GetLandingPageUsers(CurrentPage, RecordPerPage, PracticeName, FullName, EmailAddress, ShipToNo, Postcode, Address, City).then((response) => {
			if (response.error == null && response.object.data.length != 0) {
				this.setState({
					to: isNaN(to) ? 1000000 : to,
					// rowsPerPage: isNaN(to) ? 1000000 : to,
					from: from,
					count: response.object.data[0].MaxRows,
					list: response.object.data,
					progress: false
				});
			} else {
				this.handleNotification("Not Currently Available", true);
			}
		});
	}

	render() {
		return (
			<>
				<Notification
					isError={this.state.isError}
					message={this.state.message}
					showNotification={this.state.showNotification}
					clearNotification={this.handleClearNotification}
					closeNotification={this.handleClearNotification}
				/>
				<section className='content-right-main'>

					<LandingPageUserList
						{...this.state}
						history={this.props.history}
						handlebind={this.handlebind.bind(this)}
						handleChangeRowsPerPage={this.handleRowsPerPageChange}
						handleChangePage={this.handlePageChange}
						applyFilter={this.handleApplyFilter}
						handleNotification={this.handleNotification}
					/>
				</section>
			</>
		);
	}

	handleRowsPerPageChange = (value, PracticeName, FullName, EmailAddress, ShipToNo, Postcode, Address, City) => {
		let from = 0
		let to = value;
		this.setState({ progress: true, rowsPerPage: value, currentPage: 1, to: to, from: from })
		this.wsGetLandingPageUsers(isNaN(value) ? '' : this.state.currentPage, isNaN(value) ? 1000000 : value, from, to, PracticeName, FullName, EmailAddress, ShipToNo, Postcode, Address, City);
	}

	handlePageChange = (values, PracticeName, FullName, EmailAddress, ShipToNo, Postcode, Address, City) => {
		let from = values === 1 ? 0 * this.state.rowsPerPage : (values - 1) * this.state.rowsPerPage
		let to = values === 1 ? this.state.rowsPerPage : (from + this.state.rowsPerPage);
		this.setState({ currentPage: Number(values) })
		this.wsGetLandingPageUsers(Number(values), this.state.rowsPerPage, from, to, PracticeName, FullName, EmailAddress, ShipToNo, Postcode, Address, City);
	}

	handleApplyFilter = (PracticeName, FullName, EmailAddress, ShipToNo, Postcode, Address, City) => {
		this.wsGetLandingPageUsers(this.state.currentPage, this.state.rowsPerPage, this.state.from, this.state.to, PracticeName, FullName, EmailAddress, ShipToNo, Postcode, Address, City)
	}

	handleNotification = (message, isError) => {
		this.setState({
			message: message,
			showNotification: true,
			isError: isError,
		});

		setTimeout(() => {
			this.handleClearNotification();
		}, 4000);
	};

	handleClearNotification = () => {
		this.setState({
			message: "",
			showNotification: false,
			isError: false,
		});
	};
}

export default LandingPageUserContainer;
