/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'
import * as APIController from '../../../../../api/APIController.js';
import * as yup from 'yup';
import Notification from '../../../../../shared/components/notification/notification';
import CircularProgress from '@material-ui/core/CircularProgress';
import Editor from '../../../../campaign/components/editor';

import file_upload_ic from "../../../../../assets/images/file-upload-ic.svg";
import close_icon from "../../../../../assets/images/close_icon.svg";
import uuid from 'react-uuid';
import PageTitle from "../../../../../config/pageTitle";

const BannersForm = props => {
    // bannerIdEdit: '0', segmentEdit: '', isFooterEdit: 1, isActiveEdit: 1, imageURLEdit: "
    const initialValues = {
        segmentID: '',
        footer: props.history.location.state.isFooterEdit,
        active: props.history.location.state.isActiveEdit,
        imageconvert: props.history.location.state.imageURLEdit,
        imagename: '', imageData: '', ImageURL: ''
    }
    const [isButtonValue, setButtonValue] = React.useState(false);

    const [dataSource, setdataSource] = React.useState([]);
    const [description, setDescription] = React.useState("");
    const [Loading, setLoading] = React.useState(true);
    const [IsLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        props.history.location.state.bannerIdEdit != 0 && wsGetBannerByID(props.history.location.state.bannerIdEdit)

        wsGetSegmentForDropdown();

    }, [props])

    const wsGetBannerByID = (ID) => {
        APIController.GetBannerByID(ID)
            .then((response) => {
                if (response.error == null) {
                    initialValues.segmentID = response.object.data[0].SegmentID
                    initialValues.active = response.object.data[0].IsActive ? 1 : 0
                    initialValues.footer = response.object.data[0].IsFooter ? 1 : 0
                    initialValues.imagename = response.object.data[0].ImageName
                    initialValues.ImageURL = response.object.data[0].ImageURL
                    initialValues.imageData = response.object.data[0].ImageURL

                    setDescription(response.object.data[0].FooterText)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
                setLoading(false)
            })
    }


    const wsGetSegmentForDropdown = () => {
        APIController.GetSegmentForDropdown()
            .then((response) => {
                setLoading(false)
                if (response.error == null) {
                    setdataSource(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const wsAddEditBanner = (ID, SegmentID,  ImageName, ImageData, ImageURL, IsFooter, IsActive) => {
        setIsLoading(true)
        APIController.AddEditBanner(ID, SegmentID,  ImageName, ImageData, ImageURL, IsFooter, IsActive)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    handleNotification(response.object.message);
                    if (isButtonValue === true && response.object.status == 1) {
                        setTimeout(() => {
                            props.history.push({ pathname: '/supplytool/banners/list', state: { showMessage: response.object.message } })
                        }, 4000)
                        handleNotification(response.object.message);
                    } else if (response.object.status == 1) {
                        handleNotification(response.object.message);
                    } else {
                        handleNotification(response.object.message, true);
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const [message, setMessage] = React.useState("")
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    return (
        <>
            <section className="content-right-main">
                <h1>{props.history.location.state.bannerIdEdit == 0 ? <PageTitle Title="Add Banner" /> : <PageTitle Title=" Edit Banner" />}</h1>
                <Formik
                    // enableReinitialize={false}
                    initialValues={initialValues}
                    validationSchema={validateBannersForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        wsAddEditBanner(props.history.location.state.bannerIdEdit, values.segmentID, values.imagename, values.imageData, values.ImageURL, values.footer, values.active)
                        if (isButtonValue === false) {
                            values.segmentID = "";
                            values.footer = 1;
                            values.active = 1;
                            setDescription('')
                        }
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        setFieldValue,
                        errors,
                        touched,
                    }) => {
                        return (
                            <div className="form-main">
                                <form onSubmit={handleSubmit}>
                                    <div className={`form_field ${errors && touched.segmentID && errors.segmentID && errors.segmentID !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>Segment</label>
                                        </div>
                                        <div className="field-right">
                                            {Loading ? <CircularProgress style={{ alignItems: 'center' }} />
                                                : <select name='segmentID'
                                                    defaultValue={values.segmentID}
                                                    value={values.segmentID}
                                                    onChange={(e) => { setFieldValue('segmentID', e.target.value); }}>
                                                    <option value={''}>Select Brand</option>
                                                    {dataSource.map((obj, index) => {
                                                        return <option key={index} selected={obj.ID == values.segmentID ? obj.ID : obj.Name} value={obj.ID} >{obj.Name}</option>
                                                    })}
                                                </select>}
                                            {errors && touched.segmentID && errors.segmentID && errors.segmentID !== '' &&
                                                (<span className='error-msg'>
                                                    <ErrorMessage name='segmentID' render={(err) => { return err; }} />
                                                </span>)}
                                        </div>
                                    </div>

                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Is Footer ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="footer" id="yes-footertext"
                                                    defaultValue={values.footer}
                                                    defaultChecked={values.footer != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('footer', 1)} />
                                                <label htmlFor="yes-footertext">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="footer" id="no-footertext"
                                                    defaultValue={values.footer}
                                                    defaultChecked={values.footer == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('footer', 0)} />
                                                <label htmlFor="no-footertext">No</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Is Active ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="yes-text"
                                                    defaultValue={values.active}
                                                    defaultChecked={values.active != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 1)} />
                                                <label htmlFor="yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="no-text"
                                                    defaultValue={values.active}
                                                    defaultChecked={values.active == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 0)} />
                                                <label htmlFor="no-text">No</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='form_field'>
                                        {/* {values.footer == 1 ? <>
                                            <label style={{ marginBottom: '-40px' }}>Description</label>
                                            <div className="asset-full" style={{ padding: '0px', marginBottom: '-0px' }}>
                                                <Editor content={description} setContent={setDescription} />
                                            </div>
                                        </> :
                                            <> */}
                                        <label style={{ marginBottom: '-40px' }}>Image</label>
                                        <div className='field-right'>
                                            <div className='form-file-input'>
                                                <input className='input-file' id='fileupload' name='files' type='file'
                                                    onChange={(e) => {
                                                        const file = e.target.files[0];
                                                        if (file && (file.type.includes("svg") || file.type.includes("png") || file.type.includes("jpeg") || file.type.includes("jpg"))) {
                                                            let file1 = e.target.files[0]
                                                            file1['preview'] = URL.createObjectURL(e.target.files[0]);

                                                            setFieldValue("imagename", values.imagename == "" ?
                                                                uuid() + "." + file.type.split('/')[1] :
                                                                values.imagename.split('.')[0] + "." + file.type.split('/')[1]);

                                                            const image2base64 = require('image-to-base64');
                                                            image2base64(file1.preview)
                                                                .then((response) => {
                                                                    setFieldValue("imageData", response);
                                                                }).catch((error) => { })

                                                            setFieldValue("imageconvert", file1.preview);
                                                        }
                                                    }}
                                                />
                                                {errors && touched.imageconvert && errors.imageconvert && errors.imageconvert !== '' &&
                                                    (<span className='error-msg'>
                                                        <ErrorMessage name='imageconvert' render={(err) => { return err; }} />
                                                    </span>)}
                                                {values.imageconvert == '' ?
                                                    <label htmlFor='fileupload' className='input-file-trigger'>
                                                        <img src={file_upload_ic} alt='file-uplaod' />
                                                        {'Drag and drop or Click here to upload file'}
                                                    </label> : <div className='logo-preview'>
                                                        <img src={close_icon} alt="close-ic" className="close-ic" onClick={() => setFieldValue("imageconvert", '')} style={{ cursor: 'pointer', marginRight: '3px' }} />
                                                        <img src={values.imageconvert} alt='file-uplaod' />
                                                    </div>}
                                            </div>
                                            <p> Min image size: 650X800 px. Accepted files : .jpg, .jpeg, .png, .svg </p>
                                        </div>
                                        {/* </>
                                        } */}
                                    </div>

                                    <Notification
                                        isError={isError}
                                        message={message}
                                        showNotification={showNotification}
                                        clearNotification={handleClearNotification.bind(this)}
                                        closeNotification={handleClearNotification.bind(this)}
                                    />
                                    <div className="form-submit">
                                        <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" onClick={() => setButtonValue(true)} />
                                        </div>
                                        {props.history.location.state.countryIdEdit == 0 ?
                                            <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                                <input type="submit" value={IsLoading ? "Saving..." : "save & add new"} name="save & add new" onClick={() => setButtonValue(false)} />
                                            </div> : null}
                                        <div className="button-field ">
                                            <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )
                    }}
                </Formik>
            </section>
        </>
    )
}
const validateBannersForm = yup.object().shape({
    segmentID: yup.string().required('Brand is required')
});

export default BannersForm;