import React from 'react';
import forgot_user_ic from '../../assets/images/forgot-username-ic.svg';
import close_ic from '../../assets/images/close-ic.svg';
import { Grid, } from '@material-ui/core';

const ThankyouDialog = props => {

    const { deletePress, title, description, btnText, btnClose } = props;

    return (
        <div className="popup-box forgot-popup-box" style={{ width: '90%', minWidth: "650px", maxWidth: '700px' }}>
            <div className="box-heading">
                <h2 className="h1" style={{ fontSize: '26px' }}>{title}</h2>
                <button className="close-btn"><img src={close_ic} alt="icon" onClick={props.closeModal} /></button>
            </div>
            <Grid container justify='center' alignItems='center' style={{ margin: '3%', maxHeight: '500px', overflow: 'auto' }}>
                <h2 style={{ fontSize: '20px', marginRight: '6%' }}><p dangerouslySetInnerHTML={{ __html: description }} /></h2>
            </Grid>
            <Grid container justify='center' alignItems='center' style={{ padding: '1%', }}>
                <div className="button-field">
                    <input type="button" value={btnClose != undefined ? btnClose : "close"} name="Ok" onClick={props.closeModal} />
                </div>
                {btnText != undefined && <div className="button-field">
                    <input type="button" value={btnText != undefined ? btnText : "Delete"} name="delete" onClick={deletePress} />
                </div>}
            </Grid>
        </div>
    )
}

export default ThankyouDialog;