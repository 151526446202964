export class clsGetCountries {
    constructor() {
        this.Name = null;
        this.IsActive = null;
        this.CurrentPage = 0;
        this.RecordPerPage = 25;
        this.SortExpression = "Name";
        this.SortDirection = "DESC";
    }
}

export class clsDeleteCountry {
    constructor(id, name, isActive) {
        this.id = id;
        this.name = name;
        this.isActive = isActive;
    }
}
