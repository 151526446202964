/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'
// import './RegionsForm.css'
import * as APIController from '../../../../api/APIController';
import * as yup from 'yup';

import Notification from '../../../../shared/components/notification/notification';
import CircularProgress from '@material-ui/core/CircularProgress';

const AdminRegionsForm = props => {
    const initialValues = { AreaId: '', regioname: '', Shortcode: '', active: props.history.location.state.regionActiveEdit }
    const [isButtonValue, setButtonValue] = React.useState(false);
    const [IsLoading, setIsLoading] = React.useState(false);
    const [Loading, setLoading] = React.useState(true)

    const wsAddEditRegion = (ID, AreaID, Name, ShortCode, IsActive) => {
        setIsLoading(true)
        APIController.AddEditRegion(ID, AreaID, Name, ShortCode, IsActive)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    handleNotification(response.object.message);
                    if (isButtonValue === true && response.object.status == 1) {
                        props.history.replace({ pathname: '/resource/regions', state: { showMessage: response.object.message } })
                    } else if (response.object.status == 1) {
                        handleNotification(response.object.message);
                    } else {
                        handleNotification(response.object.message, true);
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }
    const [dataSource, setdataSource] = React.useState([]);

    React.useEffect(() => {
        props.history.location.state.regionIdEdit != 0 ? wsGetRegionByID(props.history.location.state.regionIdEdit)
            : wsGetAreaForDropdown()
    }, '')

    const wsGetRegionByID = (ID) => {
        APIController.GetRegionByID(ID)
            .then((response) => {
                setLoading(false)
                if (response.error == null) {
                    initialValues.AreaId = response.object.data[0].AreaID
                    initialValues.regioname = response.object.data[0].Name
                    initialValues.Shortcode = response.object.data[0].Shortcode

                    setdataSource(JSON.parse(response.object.data[0].Areas));
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const wsGetAreaForDropdown = (ID) => {
        APIController.GetAreaForDropdown(ID)
            .then((response) => {
                setLoading(false)
                if (response.error == null) {
                    setdataSource(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    return (
        <>
            <section className="content-right-main">
                {props.history.location.state.regionIdEdit == 0 ? <h1>Add Region</h1> : <h1>Edit Region</h1>}
                <Formik
                    initialValues={initialValues}
                    validationSchema={validateRegionsForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        wsAddEditRegion(props.history.location.state.regionIdEdit, values.AreaId, values.regioname,
                            values.Shortcode, values.active)
                        if (isButtonValue === false) {
                            values.regioname = "";
                            values.Shortcode = "";
                            values.active = 1;
                        }
                    }} >
                    {({
                        values,
                        handleSubmit,
                        setFieldValue,
                        errors,
                        touched,
                    }) => {

                        return (
                            <div className="form-main">
                                <form onSubmit={handleSubmit}>
                                    <div
                                        className={`form_field ${errors &&
                                            touched.AreaId &&
                                            errors.AreaId &&
                                            errors.AreaId !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Area</label>
                                        </div>
                                        <div className="field-right">
                                            {Loading ? <CircularProgress style={{ alignItems: 'center' }} />
                                                :
                                                <select name='AreaId'
                                                    defaultValue={values.AreaId}
                                                    value={values.AreaId}
                                                    onChange={(e) => {
                                                        setFieldValue('AreaId', e.target.value);
                                                    }}>
                                                    <option value={''}>Select Area</option>
                                                    {
                                                        dataSource.map((obj, index) => {
                                                            return <option value={obj.ID} key={index}>{obj.Name}</option>
                                                        })
                                                    }
                                                </select>
                                            }
                                            {errors &&
                                                touched.AreaId &&
                                                errors.AreaId &&
                                                errors.AreaId !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='AreaId'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={`form_field ${errors &&
                                            touched.regioname &&
                                            errors.regioname &&
                                            errors.regioname !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        <div className="field-left">
                                            <label> Region</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Region" name="regioname" value={values.regioname} maxLength="50" />
                                            {errors &&
                                                touched.regioname &&
                                                errors.regioname &&
                                                errors.regioname !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='regioname'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={`form_field ${errors &&
                                            touched.Shortcode &&
                                            errors.Shortcode &&
                                            errors.Shortcode !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Short code</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Short code" name="Shortcode" value={values.Shortcode} maxLength="50" />
                                            {errors &&
                                                touched.Shortcode &&
                                                errors.Shortcode &&
                                                errors.Shortcode !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='Shortcode'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Is Active ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="yes-text"
                                                    defaultChecked={values.active != 0 ? true : false}
                                                    defaultValue={values.active}
                                                    onClick={(e) => setFieldValue('active', 1)} />
                                                <label htmlFor="yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="no-text"
                                                    defaultValue={values.active}
                                                    defaultChecked={values.active == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 0)} />
                                                <label htmlFor="no-text">No</label>
                                            </div>

                                        </div>
                                    </div>
                                    <Notification
                                        isError={isError}
                                        message={message}
                                        showNotification={showNotification}
                                        clearNotification={handleClearNotification.bind(this)}
                                        closeNotification={handleClearNotification.bind(this)}
                                    />
                                    <div className="form-submit">
                                        <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" onClick={() => setButtonValue(true)} />
                                        </div>
                                        {props.history.location.state.regionIdEdit == 0 ?
                                            <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                                <input type="submit" value={IsLoading ? "Saving..." : "save & add new"} name="save & add new" onClick={() => setButtonValue(false)} />
                                            </div> : null}
                                        <div className="button-field ">
                                            <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                        </div>
                                    </div>

                                </form>
                            </div>
                        )
                    }}

                </Formik>
            </section>
        </>
    )
}
const validateRegionsForm = yup.object().shape({
    AreaId: yup.string().required('Area is required'),
    regioname: yup.string().required('Region is required'),
    Shortcode: yup.string().required('Short code is required'),
});
export default AdminRegionsForm;