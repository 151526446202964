/*eslint-disable eqeqeq*/
import React from 'react';
import Table from "../../../shared/components/table/table";
import TableHead from "../../../shared/components/table/tableHead";
import TableRow from "../../../shared/components/table/tableRow";
import TableBody from "../../../shared/components/table/tableBody";
import TableCell from "../../../shared/components/table/tableCell";
import * as APIController from "../../../api/APIController.js";
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";
import viewIcon from '../../../assets/images/view_icon.png'
import double_left_arrow from '../../../assets/images/left-doble-arow.svg';
import left_arrow from '../../../assets/images/left-arrow.svg';
import double_right_arrow from '../../../assets/images/right-doble-arow.svg';
import right_arrow from '../../../assets/images/right-arrow.svg';
import PageTitle from "../../../config/pageTitle";
import * as AppConstants from "../../../config/AppConstant";

class NotificationScreen extends React.Component {
	state = {
		headerList: AppConstants.GetLocalStorage("headerMenuAdmin") == 1 ? ['View', 'Title', "Notification At", "Type Module"] : ['Title', "Notification At"],
		currentPage: 1,
		count: 0,
		rowsPerPage: 25,
		rowsPerPageOptions: [25, 50, 100, 'All'],
		list: [],
		from: 0,
		to: 25,
		progress: true,
		page: Math.floor(0 / 25) + 1,
		Title: '', NotificationAt: '', TypeModule: ''
	};

	componentDidMount() {
		this.wsGetNotifications("", "", "", this.state.rowsPerPage, this.state.currentPage, this.state.from, this.state.to);
	}

	wsGetNotifications(Title, NotificationAt, TypeModule, RecordsPerPage, CurrentPage, from, to) {
		this.setState({ progress: true, });
		APIController.GetNotifications(Title, NotificationAt, TypeModule, RecordsPerPage, CurrentPage).then((response) => {
			if (response.object.status == 1 && response.object.data.length) {
				this.setState({
					to: isNaN(to) ? 1000000 : to,
					from: from,
					count: response.object.data[0].MaxRows,
					list: response.object.data,
					page: 25 == 1000000 ? 1 : Math.floor(response.object.data[0].MaxRows / 25) + 1,
					progress: false,
				});
			} else {
				this.setState({
					list: [],
					count: 0,
					progress: false,
				});
			}
		});
	}

	handleApplyFilter = (Title, NotificationAt, TypeModule) => {
		this.wsGetNotifications(Title, NotificationAt, TypeModule, this.state.rowsPerPage, this.state.currentPage);
	};

	handleChangeInCurrentPage = (value) => {
		if (value <= this.state.page && Number(parseInt(value))) {
			this.handlePageChange(Number(value), this.state.Title, this.state.NotificationAt, this.state.TypeModule);
		}
	}

	/**
 * handleRowsPerPageChange method is used to update rows per page
 * it will reset page to 1
 */
	handleRowsPerPageChange = (value, Title, NotificationAt, TypeModule) => {
		let from = 0
		let to = value;
		this.setState({ rowsPerPage: value, currentPage: 1, to: to, from: from })
		this.wsGetNotifications(Title, NotificationAt, TypeModule, isNaN(value) ? 1000000 : value, isNaN(value) ? '' : this.state.currentPage, from, to);
	}

	/**
	 * handlePageChange method is used to change page
	 */
	handlePageChange = (values, Title, NotificationAt, TypeModule) => {
		let from = values === 1 ? 0 * this.state.rowsPerPage : (values - 1) * this.state.rowsPerPage
		let to = values === 1 ? this.state.rowsPerPage : (from + this.state.rowsPerPage);
		this.setState({ currentPage: Number(values), to: to, from: from })
		this.wsGetNotifications(Title, NotificationAt, TypeModule, this.state.rowsPerPage, Number(values), from, to);
	}

	_handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			this.handleApplyFilter(this.state.Title, this.state.NotificationAt, this.state.TypeModule, this.state.from, this.state.to)
		}
	}

	render() {
		const { currentPage, count, rowsPerPage, rowsPerPageOptions, list, from, to, page, } = this.state;
		let notificationList = list;

		return (
			<section className='content-right-main'>
				<PageTitle Title={AppConstants.ContactDetais.ViewNotifications} />

				<div className="table-main-wrap countrys-class">
					<Table>
						<TableHead>
							<TableRow> {this.state.headerList.map((obj, index) => { return <TableCell key={index}>{obj}</TableCell>; })} </TableRow>
							<TableRow>
								{AppConstants.GetLocalStorage("headerMenuAdmin") == 1 ? <TableCell style={{ width: '5%' }}> </TableCell> : <></>}
								<TableCell style={AppConstants.GetLocalStorage("headerMenuAdmin") == 1 ? { width: '60%' } : { width: '75%' }}>
									<input type="text" name="" placeholder="Search Notification" onKeyDown={this._handleKeyDown} onChange={(e) => this.setState({ Title: e.target.value })} />
								</TableCell>
								<TableCell style={{ width: '22%' }}>
									<input type="text" name="" placeholder="Search Notification Date Time" onKeyDown={this._handleKeyDown} onChange={(e) => this.setState({ NotificationAt: e.target.value })} />
								</TableCell>
								{AppConstants.GetLocalStorage("headerMenuAdmin") == 1 ? <TableCell style={{ width: '12%' }}>
									<input type="text" name="" placeholder="Search Module" onKeyDown={this._handleKeyDown} onChange={(e) => this.setState({ TypeModule: e.target.value })} />
								</TableCell> : <></>}
							</TableRow>
						</TableHead>
						<TableBody>
							{!!notificationList.length ? notificationList.map((obj, index) => {
								return (
									<TableRow key={index}>
										{AppConstants.GetLocalStorage("headerMenuAdmin") == 1 ?
											<TableCell>
												<span> <img src={viewIcon} alt="edit" style={{ cursor: 'pointer', marginRight: '3px' }} onClick={() => { window.open(obj.Description) }} /> </span>
											</TableCell> : <></>}
										<TableCell> {obj.Title} </TableCell>
										<TableCell> {obj.NotificationDateTime} </TableCell>
										{AppConstants.GetLocalStorage("headerMenuAdmin") == 1 ? <TableCell> {obj.TypeModule} </TableCell> : <></>}
									</TableRow>
								);
							}) : <TableRow >
								<TableCell colSpan={this.state.headerList.length} className="not_found">
									{this.state.progress ? <CircularProgress /> : <label>Not Currently Available</label>}
								</TableCell>
							</TableRow>}
						</TableBody>
					</Table>
					<div className="table-pagination">
						<div className="pagination-info">
							<p>Showing {from + 1} - {to > count ? count : to} of {count}</p>
						</div>
						<div className="pagination-control">
							<a href="#!" title="" className={`double-left-arrow ${currentPage === 1 ? 'disabled' : ''}`}
								onClick={(e) => {
									e.preventDefault();
									this.handleChangeInCurrentPage(1)
								}}
							><img src={double_left_arrow} alt="left" /></a>
							<a href="#!" title="" className={`simple-left-arrow ${currentPage === 1 ? 'disabled' : ''}`}
								onClick={(e) => {
									e.preventDefault();
									this.handleChangeInCurrentPage(currentPage - 1)
								}}
							><img src={left_arrow} alt="single_left" /></a>
							<span>Page</span>
							<input type="text" name="textbox" value={currentPage === 0 ? currentPage + 1 : currentPage} onChange={(e) => this.handleChangeInCurrentPage(e.target.value)} />
							<span>of {page}</span>
							<a href="#!" title="" className={`simple-right-arrow ${currentPage === page ? 'disabled' : ''}`}
								onClick={(e) => {
									e.preventDefault();
									this.handleChangeInCurrentPage(currentPage + 1)
								}}
							><img src={right_arrow} alt="right" /></a>
							<a href="#!" title="" className={`double-right-arrow ${currentPage === page ? 'disabled' : ''}`}
								onClick={(e) => {
									e.preventDefault();
									this.handleChangeInCurrentPage(page)
								}}><img src={double_right_arrow} alt="single_right" /></a>
							<select onChange={(e) => this.handleRowsPerPageChange(e.target.value, this.state.Title, this.state.NotificationAt, this.state.TypeModule)} defaultValue={rowsPerPage}>
								{
									rowsPerPageOptions.map((obj, index) => {
										return <option value={obj} key={index}>{obj}</option>
									})
								}
							</select>
						</div>
					</div>
				</div>
			</section>
		)
	}
}


export default NotificationScreen;