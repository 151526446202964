/*eslint-disable eqeqeq*/
import React from "react";
import VTMContactReportList from "./vtmContactReportList";
import * as APIController from "../../../../api/APIController.js";
import Notification from "../../../../shared/components/notification/notification";
import CsvDownload from 'react-json-to-csv'
import * as AppConstants from "../../../../config/AppConstant";
import moment from 'moment';

import PageTitle from "../../../../config/pageTitle";
import TextField from "../../../../shared/components/TextField/TextField";

class VTMContactReport extends React.Component {
    state = {
        currentPage: 1,
        count: 0,
        rowsPerPage: 50,
        rowsPerPageOptions: [50, 100, 250, 500, 1000],
        list: [],
        from: 0,
        to: 50,
        downloadlist: [],
        progress: true,
        message: "",
        showNotification: false,
        isError: false,
        showMessage:
            this.props.history.location.state != null &&
            this.props.history.location.state.showMessage
    };

    componentDidMount() {
        this.wsGetVTMContactList(this.state.from, this.state.to, "", "", "", "", this.state.currentPage, this.state.rowsPerPage);
    }

    wsGetVTMContactList(from, to, FullName, EmailAddress, VTMEmailAddress, ImportedAt, CurrentPage, RecordPerPage) {
        this.setState({ progress: true, });
        APIController.GetVTMContactList(FullName, EmailAddress, VTMEmailAddress, ImportedAt, CurrentPage, RecordPerPage)
            .then((response) => {
                if (response.error == null && response.object.data.length != 0) {
                    this.setState({
                        to: isNaN(to) ? 50 : to,
                        from: from,
                        count: response.object.data[0].MaxRows,
                        list: response.object.data,
                        progress: false,
                    });
                } else { this.setState({ list: [], progress: false }) }
            });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        return (
            <>
                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    clearNotification={this.handleClearNotification}
                    closeNotification={this.handleClearNotification}
                />
                <section className='content-right-main'>

                    <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                        <h1><PageTitle Title={AppConstants.ContactDetais.VTMContactList} /></h1>
                    </div>

                    <VTMContactReportList
                        {...this.state}
                        history={this.props.history}
                        handleChangeRowsPerPage={this.handleRowsPerPageChange}
                        handleChangePage={this.handlePageChange}
                        handleApplyFilter={this.handleApplyFilter}
                        handleNotification={this.handleNotification}
                    />
                    {/*  */}
                </section>
            </>
        );
    }

    handleRowsPerPageChange = (value, FullName, EmailAddress, VTMEmailAddress, ImportedAt) => {
        let from = 0
        let to = value;
        this.setState({ rowsPerPage: value, currentPage: 1, to: to, from: from })
        this.wsGetVTMContactList(from, to, FullName, EmailAddress, VTMEmailAddress, ImportedAt, isNaN(value) ? '' : this.state.currentPage, isNaN(value) ? 50 : value);
    }

    handlePageChange = (values, FullName, EmailAddress, VTMEmailAddress, ImportedAt) => {
        let from = values === 1 ? 0 * this.state.rowsPerPage : (values - 1) * this.state.rowsPerPage
        let to = values === 1 ? this.state.rowsPerPage : (from + this.state.rowsPerPage);
        this.setState({ currentPage: Number(values) })
        this.wsGetVTMContactList(from, to, FullName, EmailAddress, VTMEmailAddress, ImportedAt, Number(values), this.state.rowsPerPage);
    }

    handleApplyFilter = (FullName, EmailAddress, VTMEmailAddress, ImportedAt) => {
        this.wsGetVTMContactList(this.state.from, this.state.to, FullName, EmailAddress, VTMEmailAddress, ImportedAt, this.state.currentPage, this.state.rowsPerPage);
    };

    handleNotification = (message, isError) => {
        this.setState({
            message: message,
            showNotification: true,
            isError: isError,
        });

        setTimeout(() => {
            this.handleClearNotification();
        }, 4000);
    };

    handleClearNotification = () => {
        this.setState({
            message: "",
            showNotification: false,
            isError: false,
        });
    };
}

export default VTMContactReport;
