/*eslint-disable eqeqeq*/
import React from "react";
import Table from "../../../../shared/components/table/table";
import TableHead from "../../../../shared/components/table/tableHead";
import TableRow from "../../../../shared/components/table/tableRow";
import TableBody from "../../../../shared/components/table/tableBody";
import TableCell from "../../../../shared/components/table/tableCell";
import double_left_arrow from '../../../../assets/images/left-doble-arow.svg';
import left_arrow from '../../../../assets/images/left-arrow.svg';
import double_right_arrow from '../../../../assets/images/right-doble-arow.svg';
import right_arrow from '../../../../assets/images/right-arrow.svg';
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

import Notification from '../../../../shared/components/notification/notification';
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";
import * as AppConstants from "../../../../config/AppConstant";

const VTMContactReportList = (props) => {
	const headerList = ["Full Name", "Email Address", "VTM Email Address", "Imported At"]

	const { currentPage, count, rowsPerPage, rowsPerPageOptions, list, to, from } = props;
	let countryList = list;
	// countryList = list.slice(from, to)

	const page = Math.floor(count / rowsPerPage) + 1;

	/**
	 * handleChangeInCurrentPage method is used to change page number
	 * it will check that page number does not exist last page
	 * @param {*} value 
	 */
	const handleChangeInCurrentPage = (value) => {
		if (value <= page && Number(parseInt(value))) {
			props.handleChangePage(Number(value), FullName, EmailAddress, VTMEmailAddress, ImportedAt);
		}
	}

	const [message, setMessage] = React.useState("");
	const [showNotification, setShowNotification] = React.useState(false);
	const [isError, setIsError] = React.useState(false);

	const [FullName, setFullName] = React.useState("");
	const [EmailAddress, setEmailAddress] = React.useState("");
	const [VTMEmailAddress, setVTMEmailAddress] = React.useState("");
	const [ImportedAt, setImportedAt] = React.useState("");

	const handleClearNotification = () => {
		setMessage('')
		setShowNotification(false)
		setIsError(false)
	}

	const _handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			props.handleApplyFilter(FullName, EmailAddress, VTMEmailAddress, ImportedAt)
		}
	}

	return (
		<>
			<div className="table-main-wrap">
				<PerfectScrollbar component='div'>
					<Table>
						<TableHead>
							<TableRow> {headerList.map((obj, index) => { return <TableCell style={{ minWidth: '125px', padding: '5px' }} key={index}>{obj}</TableCell>; })} </TableRow>
							<TableRow>
								<TableCell> <input type="text" name="" placeholder="Full Name" onKeyDown={_handleKeyDown} onChange={(e) => setFullName(e.target.value)} /> </TableCell>
								<TableCell> <input type="text" name="" placeholder="Email Address" onKeyDown={_handleKeyDown} onChange={(e) => setEmailAddress(e.target.value)} /> </TableCell>
								<TableCell> <input type="text" name="" placeholder="VTM Email Address" onKeyDown={_handleKeyDown} onChange={(e) => setVTMEmailAddress(e.target.value)} /> </TableCell>
								<TableCell> <input type="text" name="" placeholder="Imported At" onKeyDown={_handleKeyDown} onChange={(e) => setImportedAt(e.target.value)} /> </TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!!countryList.length ? countryList.map((obj, idnex) => {
								return (
									<TableRow key={idnex}>
										<TableCell>{obj.FullName}</TableCell>
										<TableCell>{obj.EmailAddress} </TableCell>
										<TableCell>{obj.VTMEmailAddress} </TableCell>
										<TableCell>{obj.ImportedAt} </TableCell>
									</TableRow>
								);
							}) : <TableRow>
								<TableCell colSpan={headerList.length} className="not_found">
									{props.progress ? <CircularProgress /> : <label>Not Currently Available</label>}
								</TableCell>
							</TableRow>}
						</TableBody>
					</Table>
				</PerfectScrollbar>
			</div>
			<Notification
				isError={isError}
				message={message}
				showNotification={showNotification}
				closeNotification={handleClearNotification.bind(this)}
			/>

			<div className="table-pagination">
				<div className="pagination-info">
					<p>Showing {from + 1} - {to > count ? count : to} of {count}</p>
				</div>
				<div className="pagination-control">
					<a href="#!" title="" className={`double-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(1)
						}}
					><img src={double_left_arrow} alt="left" /></a>
					<a href="#!" title="" className={`simple-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage - 1)
						}}
					><img src={left_arrow} alt="single_left" /></a>
					<span>Page</span>
					<input type="text" name="textbox" value={currentPage === 0 ? currentPage + 1 : currentPage} onChange={(e) => handleChangeInCurrentPage(e.target.value)} />
					<span>of {page}</span>
					<a href="#!" title="" className={`simple-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage + 1)
						}}
					><img src={right_arrow} alt="right" /></a>
					<a href="#!" title="" className={`double-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(page)
						}}><img src={double_right_arrow} alt="single_right" /></a>
					<select onChange={(e) => props.handleChangeRowsPerPage(e.target.value, FullName, EmailAddress, VTMEmailAddress, ImportedAt)} defaultValue={rowsPerPage}>
						{rowsPerPageOptions.map((obj, index) => {
							return <option value={obj} key={index}>{obj}</option>
						})}
					</select>
				</div>
			</div>
		</>
	);
};

export default VTMContactReportList;
