/*eslint-disable eqeqeq*/
import React from "react";
import biotrue_bundle from "../../../assets/images/biotrue_bundle_calendar.jpg";
import { Grid } from "@material-ui/core";
import Dropdown from '../../../shared/components/Dropdown/Dropdown';
import MenuItem from '@material-ui/core/MenuItem';
import PageTitle from "../../../config/pageTitle";
import * as AppConstants from "../../../config/AppConstant";
import * as APIController from '../../../api/APIController.js';

import TextField from "../../../shared/components/TextField/TextField";
import moment from 'moment';

const BudgetCampaignDuration = (props) => {

	const [StartDate, setStartDate] = React.useState(moment(new Date().setDate(new Date().getDate() + 7)).format('YYYY-MM-DD'));

	const [EndDate, setEndDate] = React.useState('');
	const [duration, setDuration] = React.useState('');

	const [CampaignProducts, setCampaignProducts] = React.useState(props.CampaignProducts);
	React.useEffect(() => {
		setDuration(props.budgetDetail[0].duration)

		if (AppConstants.GetLocalStorage("localStorageCampaignStartDate") != null && AppConstants.GetLocalStorage("localStorageCampaignStartDate") != "" && AppConstants.GetLocalStorage("localStorageCampaignStartDate") != undefined) {
			CalculateEndDate(props.budgetDetail[0].duration, AppConstants.GetLocalStorage("localStorageCampaignStartDate"))
		}
	}, [props])


	const wsGetMonthlyBudgetByProductID = (ID) => {
		APIController.GetMonthlyBudgetByProductID(ID)
			.then((response) => {
				if (response.error == null) {
					setCampaignProducts(CampaignProducts.map((item, index) => item.ID !== ID ? item : { ...item, Checked: !item.Checked, AllBudget: response.object.data, SelectedBudget: !item.Checked && "" }))
				}
				else if (response.error.message && response.error.message.length > 0) {
					// handleNotification(response.error.message);
				}
			})
	}

	const CalculateTotalBudget = () => {
		let temp = 0;
		CampaignProducts.length && CampaignProducts.map((itm) => {
			if (itm.SelectedBudget != "") {
				temp = parseInt(temp) + parseInt(itm.SelectedBudget.replace("$", "").replaceAll(",", ""))
			}
		})
		return temp;
	}

	const CalculateEndDate = (duration, StartDate) => {
		// moment(new Date().setDate(new Date().getDate() + 7)).format('YYYY-MM-DD')
		if (StartDate != '' && duration != '') {
			const d = new Date(StartDate);
			d.setDate(d.getDate() + 30 * duration)
			setEndDate(moment(new Date(d)).format('YYYY-MM-DD'))
		} else {
			setEndDate('');
		}
	}

	return (
		<>
			<PageTitle Title={AppConstants.ContactDetais.Step2Select} />

			<div className=' two-col-sec digital-welcome box-body' style={{ marginTop: '0' }}>
				<div className='box-head' style={{ width: '100%', padding: '15px 10px' }}> <h4>Campaign Budget & Duration</h4> </div>
				<div className='sec-left box-body' style={{ width: '55%' }}>

					<div style={{ letterSpacing: '0px', margin: '15px 0 0 15px' }}>
						<div>Please select the local campaign(s) you would like to run, your desired monthly budget(s), campaign duration, and estimated start date.</div>
					</div>
					<div style={{ display: 'grid', width: '100%', margin: '0px 15px' }}>
						{CampaignProducts.length && CampaignProducts.map((item, index) => (
							<div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
								<div className='custom-checkbox' style={{ width: '51%' }} >
									<input type='checkbox'
										name={`${item.ID}`}
										id={`${item.ID}`}
										defaultChecked={item.Checked}
										checked={item.Checked}
										onChange={(e) => { wsGetMonthlyBudgetByProductID(item.ID); CalculateTotalBudget() }}
									/>
									<label htmlFor={`${item.ID}`} style={{ paddingLeft: '11%', display: 'flex' }}>{item.Name}</label>
								</div>

								<div className='full-field budget_detail_field' style={{ margin: '0px 0', width: '48%' }}>
									<Dropdown
										style={{ color: '#555555' }}
										label='Select Monthly Budget'
										name={`${item.SelectedBudget}`}
										id={`${item.SelectedBudget}`}
										placeholder='Select Monthly Budget'
										defaultValue={item.Checked ? item.SelectedBudget : ''}
										value={item.Checked ? item.SelectedBudget : ''}
										disabled={item.Checked ? false : true}
										onChange={(e) => { setCampaignProducts(CampaignProducts.map((item, idx) => index !== idx ? item : { ...item, SelectedBudget: e.target.value })); CalculateTotalBudget(); }}
										select>
										{item.Checked && CampaignProducts[index].AllBudget.map((obj) =>
											<MenuItem value={obj.MonthlyBudget}>{obj.MonthlyBudget}</MenuItem>
										)}
									</Dropdown>
								</div>
							</div>
						))}

						<div style={{ width: '100%', marginTop: '10px' }}>
							<Dropdown
								style={{ color: '#555555' }}
								label='Select Monthly Duration'
								id={'duration'}
								name='duration'
								placeholder='Select Monthly Duration'
								defaultValue={duration}
								value={duration}
								onChange={(e) => { setDuration(e.target.value); CalculateEndDate(e.target.value, StartDate); }}
								select>
								<MenuItem value='2'>2 Months</MenuItem>
								<MenuItem value='3'>3 Months</MenuItem>
								<MenuItem value='4'>4 Months</MenuItem>
								<MenuItem value='5'>5 Months</MenuItem>
								<MenuItem value='6'>6 Months</MenuItem>
							</Dropdown>
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0', width: '100%' }}>
							<div style={{ width: '100%' }}>
								<TextField label='Select Start Date' type="date" name="StartDate" value={StartDate} onChange={(e) => { setStartDate(moment(new Date(e.target.value)).format('YYYY-MM-DD')); CalculateEndDate(duration, e.target.value) }} />
							</div>
							<div style={{ width: '100%', padding: '0 0 0 10px' }}>
								<TextField label='End Date' disabled type={EndDate == '' ? "text" : "date"} name="EndDate" value={EndDate} />
							</div>
						</div>
						<p>
							<small>* A minimum of 2 months required (3 months recommended) to optimize the performance of the campaign. </small>
						</p>

						<div className='box-head' style={{ width: '100%', padding: '5px' }} />
						<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
							<label style={{ fontSize: '21px', width: '50%', textAlign: 'end', fontWeight: 400 }}>Total Monthly Budget:</label>
							<label style={{ fontSize: '21px', width: '50%', textAlign: 'center', fontWeight: 400 }}>{"$" + CalculateTotalBudget().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ".00"}</label>
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
							<label style={{ fontSize: '21px', width: '50%', textAlign: 'end', color: '#177bc9' }}>Total Campaign Cost:</label>
							<label style={{ fontSize: '21px', width: '50%', textAlign: 'center', color: '#177bc9' }}>{"$" + (CalculateTotalBudget() * duration).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ".00"}</label>
						</div>

					</div>
				</div>

				<div className='sec-right' style={{ width: '45%' }}>
					<img src={biotrue_bundle} alt='sd-1' />
				</div>

			</div>

			<form action='#'>
				<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }} >
					<div className='button-field back-btn'>
						<input type='button' name='button' value={AppConstants.ButtonText.Back} onClick={props.back} />
					</div>
					<div className={`button-field ${duration == '' || EndDate == ''
						|| !CampaignProducts.every(item => item.Checked !== true || (item.Checked === true && item.SelectedBudget.trim() !== ''))
						|| !CampaignProducts.filter(item => item.Checked === true).length > 0
						? "disable-btn"
						: ""}`}>
						<input type='button' name='submit' value={AppConstants.ButtonText.Continue}
							onClick={() => {
								let BudgetProductIDs = []
								CampaignProducts.map((item) => { item.Checked && BudgetProductIDs.push(item.ID) })
								AppConstants.SetLocalStorage("localStorageBudgetProducts", BudgetProductIDs)
								props.submitBudget(duration, CalculateTotalBudget(), CampaignProducts, StartDate, EndDate)
							}}
						/>
					</div>
				</div>
			</form>
		</>
	);
};

export default BudgetCampaignDuration;
