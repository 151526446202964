import React from 'react';
import Dashboard from '../component/home';
import * as APIController from "../../../api/APIController.js";
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";
import * as AppConstants from '../../../config/AppConstant';

class HomeContainer extends React.Component {

    state = {
        ProductData: [],
        hoverDiv: null,
        ChildrenTab: ''
    }

    componentDidMount = () => {
        this.wsGetProductsForHome();
    }

    wsGetProductsForHome() {
        APIController.GetProductsForHome()
            .then((response) => {
                if (response.object.data != []) {
                    this.setState({ ProductData: response.object.data })
                }
                else {
                    // handleNotification(response.error.message);
                }
            })
    }


    render() {
        return (
            <section className='content-right-main '>
                {/* <Dashboard {...this.props} /> */}

                <div style={{ width: '100%', textAlign: "center", marginBottom: '20px' }}>
                    <h2 className="login-text-h2">WELCOME TO REACH</h2>
                    <h5 className="login-text-h5" style={{ color: '#5d6e71' }}>Click on the Tiles Below to Explore the Latest Bausch + Lomb Assets</h5>
                </div>
                <div className='three-block-sec' >
                    {this.state.ProductData.map((obj, index) => {
                        return (
                            <div className='block-col' key={index} id={obj.ID}
                                onMouseEnter={() => { this.setState({ hoverDiv: index }) }}
                                onMouseLeave={() => { this.setState({ hoverDiv: null }) }}
                                onClick={() => {
                                    if (obj.PageURL != null && obj.PageURL != '' && obj.PageURL != undefined) {

                                        this.props.history.push(obj.PageURL)
                                        AppConstants.SetLocalStorage("localStoragePageURL", obj.PageURL)
                                        AppConstants.SetLocalStorage("localStorageMenuPath", obj.MenuPath)
                                        AppConstants.SetLocalStorage("localStorageCustomizePath", obj.CustomizePath)

                                        AppConstants.SetLocalStorage("localStorageProductID", obj.ID)
                                        AppConstants.SetLocalStorage("localStorageProductName", obj.TileName)
                                        AppConstants.SetLocalStorage("localStorageShowAddToCart", obj.ShowAddToCart)
                                        AppConstants.SetLocalStorage("localStorageShowCopyText", obj.ShowCopyText)
                                        AppConstants.SetLocalStorage("localStorageThemeButtons", obj.ThemeButtons)

                                        AppConstants.SetLocalStorage("localStorageMultiSelectPractice", obj.AllowMultiSelectPractice)
                                        AppConstants.SetLocalStorage('IsDirectPageURL', obj.AllowMultiSelectPractice == 0 ? true : false);
                                        AppConstants.SetLocalStorage('localStoragePracticeIDs', JSON.stringify([]));
                                        AppConstants.SetLocalStorage("localStorageISDirectMail", obj.TileName == "Direct Mail & Print" ? 1 : 0)
                                        AppConstants.SetLocalStorage("localStorageFromCart", 1)
                                        AppConstants.SetLocalStorage('localStorageOrderDetailsID', 0)
                                        AppConstants.SetLocalStorage("localStorageBudgetDetail", obj.PageURL == "/digital-product/selection/location-selection" ? JSON.stringify([{ "budget": 1, "duration": 1 }]) : JSON.stringify([{ "budget": 1, "duration": "" }]))

                                        // AppConstants.SetLocalStorage("localStorageProductID", obj.Children != null && obj.Children.length ? JSON.parse(obj.Children)[0].ID : obj.ID)
                                        // AppConstants.SetLocalStorage("localStorageMultiSelectPractice", obj.Children != null && obj.Children.length ? JSON.parse(obj.Children)[0].AllowMultiSelectPractice : true)
                                        // AppConstants.SetLocalStorage("localStorageAssetTabName", obj.Children != null && obj.Children.length ? JSON.parse(obj.Children)[0].TabName : "")
                                        AppConstants.SetLocalStorage("localStorageAutomation", "NO")

                                        AppConstants.SetLocalStorage("localStoragepracticeId", 0)
                                        AppConstants.SetLocalStorage("localStorageAutomationSubscriptionID", 0)
                                        AppConstants.SetLocalStorage("localStorageFBPageData", '')
                                        AppConstants.SetLocalStorage("SocialAutomationAllLocations", '')
                                        AppConstants.SetLocalStorage("localStorageInstaUserData", '')
                                        AppConstants.SetLocalStorage("localStorageCampaignProducts", [])
                                        AppConstants.SetLocalStorage("localStorageCampaignStartDate", AppConstants.GetCurrentDate)
                                        AppConstants.SetLocalStorage("localStorageCampaignEndDate", '')
                                        AppConstants.SetLocalStorage("localStorageBudgetProducts", "")
                                        AppConstants.SetLocalStorage("localStorageIsFromHeader", 3)

                                    }
                                }} >
                                <a style={{ cursor: 'pointer' }} className='hover_link'>
                                    <div style={{ display: 'grid', alignContent: 'space-between' }}>
                                        <img src={this.state.hoverDiv === index ? obj.HoverImageURL : obj.ImageURL} alt='icon' />
                                        <div>
                                            <div style={{ height: "40px" }}>
                                                <p> {obj.TagLine.replace("<br />", '\n')} </p>
                                            </div>
                                            <h2>{obj.TileName}</h2>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        );
                    })}
                </div>
            </section>
        )
    }
}

export default HomeContainer;