/*eslint-disable eqeqeq*/import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'
import './AssetSizesForm.css'
import * as APIController from '../../../../api/APIController';
import * as yup from 'yup';

import Notification from '../../../../shared/components/notification/notification';
import CircularProgress from '@material-ui/core/CircularProgress';

const AdminAssetSizesForm = props => {
    const initialValues =
    {
        AssetTypeId: '',
        Height: '',
        Width: '',
        active: props.history.location.state.AssetSizeActiveEdit
    }
    const [isButtonValue, setButtonValue] = React.useState(false);
    const [LoadingAsset, setLoadingAsset] = React.useState(true)
    const [IsLoading, setIsLoading] = React.useState(false);


    const [dataSource, setdataSource] = React.useState([]);
   
    React.useEffect(() => {
        props.history.location.state.AssetSizeIdEdit != 0 ? wsGetAssetSizeByID(props.history.location.state.AssetSizeIdEdit)
            : wsGetAssetTypeForDropdown()
    }, [props])


    const wsGetAssetSizeByID = (ID) => {
        APIController.GetAssetSizeByID(ID)
            .then((response) => {
                setLoadingAsset(false)
                if (response.error == null) {
                    initialValues.AssetTypeId = response.object.data[0].AssetTypeID
                    initialValues.Height = response.object.data[0].Height
                    initialValues.Width = response.object.data[0].Width
                    setdataSource(JSON.parse(response.object.data[0].AssetTypes));
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message);
                }
            })
    }

    const wsAddEditAssetSize = (ID, AssetTypeID, Height, Width, IsActive) => {
        setIsLoading(true)
        APIController.AddEditAssetSize(ID, AssetTypeID, Height, Width, IsActive)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    handleNotification(response.object.message);
                    if (isButtonValue === true) {
                        props.history.replace({
                            pathname: '/campaigns/AssetSizes',
                            state: { showMessage: response.object.message }
                        })
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }



    const wsGetAssetTypeForDropdown = () => {
        APIController.GetAssetTypeForDropdown()
            .then((response) => {
                setLoadingAsset(false)
                if (response.error == null) {
                    setdataSource(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    // ToastsStore.warning(response.error.message)
                }
            })
    }

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }
    return (
        <>
            <section className="content-right-main">
                {props.history.location.state.AssetSizeIdEdit == 0 ? <h1>Add Asset Size</h1> : <h1>Edit Asset Size</h1>}
                <Formik
                    initialValues={initialValues}
                    validationSchema={validateAssetsizesForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        wsAddEditAssetSize(
                            props.history.location.state.AssetSizeIdEdit,
                            values.AssetTypeId,
                            values.Height,
                            values.Width,
                            values.active
                        )
                        if (isButtonValue === false) {
                            values.Height = "";
                            values.Width = "";
                            values.active = 1;
                        }
                    }} >
                    {({
                        values,
                        handleSubmit,
                        setFieldValue,
                        errors,
                        touched,
                    }) => {
                        return (
                            <div className="form-main">
                                <form onSubmit={handleSubmit}>
                                    <div
                                        className={`form_field ${errors &&
                                            touched.AssetTypeId &&
                                            errors.AssetTypeId &&
                                            errors.AssetTypeId !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Asset Type</label>
                                        </div>
                                        <div className="field-right">
                                            {LoadingAsset ? <CircularProgress style={{ alignItems: 'center' }} />
                                                :
                                                <select name='AssetTypeId'
                                                    defaultValue={values.AssetTypeId}
                                                    value={values.AssetTypeId}
                                                    onChange={(e) => {
                                                        setFieldValue('AssetTypeId', e.target.value);
                                                    }}>
                                                    <option value={''}>Select AssetType</option>
                                                    {
                                                        dataSource.map((obj, index) => {
                                                            return <option value={obj.ID} key={index}>{obj.Name}</option>
                                                        })
                                                    }
                                                </select>
                                            }
                                            {errors &&
                                                touched.AssetTypeId &&
                                                errors.AssetTypeId &&
                                                errors.AssetTypeId !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='AssetTypeId'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={`form_field ${errors &&
                                            touched.Height &&
                                            errors.Height &&
                                            errors.Height !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Height</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" pattern="[0-9]*" placeholder="Enter Height" name="Height" value={values.Height} maxLength="5" />
                                            {errors &&
                                                touched.Height &&
                                                errors.Height &&
                                                errors.Height !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='Height'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={`form_field ${errors &&
                                            touched.Width &&
                                            errors.Width &&
                                            errors.Width !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Width</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" pattern="[0-9]*" placeholder="Enter Width" name="Width" value={values.Width} maxLength="5" />
                                            {errors &&
                                                touched.Width &&
                                                errors.Width &&
                                                errors.Width !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='Width'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Is Active ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">

                                                <input type="radio" name="active" id="yes-text"
                                                    defaultValue={values.active}
                                                    // defaultChecked={values.active != false ? true : false}
                                                    // onClick={(e) => setFieldValue('active', true)} />
                                                    defaultChecked={values.active != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 1)} />
                                                <label htmlFor="yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="no-text"
                                                    defaultValue={values.active}
                                                    // defaultChecked={values.active == false ? true : false}
                                                    // onClick={(e) => setFieldValue('active', false)} />
                                                    defaultChecked={values.active == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 0)} />
                                                <label htmlFor="no-text">No</label>

                                            </div>
                                        </div>
                                    </div>
                                    <Notification
                                        isError={isError}
                                        message={message}
                                        showNotification={showNotification}
                                        clearNotification={handleClearNotification.bind(this)}
                                        closeNotification={handleClearNotification.bind(this)}
                                    />
                                    <div className="form-submit">
                                        <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" onClick={() => setButtonValue(true)} />
                                        </div>
                                        {props.history.location.state.AssetSizeIdEdit == 0 ?
                                            <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                                <input type="submit" value={IsLoading ? "Saving..." : "save & add new"} name="save & add new" onClick={() => setButtonValue(false)} />
                                            </div> : null}
                                        <div className="button-field">
                                            <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )
                    }}
                </Formik>
            </section>
        </>
    )
}
const validateAssetsizesForm = yup.object().shape({
    AssetTypeId: yup.string().required('AssetTypeId is required'),
    Height: yup.string().required('Height is required'),
    Width: yup.string().required('Width is required'),
});

export default AdminAssetSizesForm;