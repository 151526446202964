/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import close_ic from '../../../assets/images/close-ic.svg'
import * as yup from 'yup';
import CircularProgress from '@material-ui/core/CircularProgress';

import Notification from '../../../shared/components/notification/notification';
import * as APIController from '../../../api/APIController';

const UserForm = props => {
    const initialValues = {
        usersFirstname: props.initialValues.FirstName,
        usersLastname: props.initialValues.LastName,
        usersEmailAddress: props.initialValues.EmailAddress,
        usersPhoneNo: "",
        active: props.initialValues.IsActive ? 1 : 0
    }

    React.useEffect(() => {
        if (props.initialValues.PhoneNo != '') {
            initialValues.usersPhoneNo = normalizeInput(props.initialValues.PhoneNo)
        }
    }, [])

    const [IsLoading, setIsLoading] = React.useState(false);

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)
        setTimeout(() => {
            handleClearNotification();
            props.closeModal()
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    const wsAddEditUser = (ID, CompanyID, PracticeID, FirstName, LastName, EmailAddress, PhoneNo, IsActive, UserType) => {
        setIsLoading(true)
        APIController.AddEditUser(ID, CompanyID, PracticeID, FirstName, LastName, EmailAddress, PhoneNo, IsActive, UserType)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    if (response.object.status == 1) {
                        handleNotification(response.object.message)
                    } else {
                        handleNotification(response.object.message, true);
                    }
                    props.handlebind()
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const normalizeInput = (value, previousValue) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;
        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 4) return currentValue;
            if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
    };

    return (
        <div className="popup-box forgot-popup-box">
            <div className="box-heading">
                <h2 className="h1">{props.Title}</h2>
                <button className="close-btn"><img src={close_ic} alt="icon" onClick={() => props.closeModal()} /></button>
            </div>
            <div className="box-content">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validateUsersForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        if (!values.usersEmailAddress.trim().match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                            handleNotification('Please Enter Valid Email Address', true)
                            document.getElementById('usersEmailAddress').focus();
                        } else if (values.usersPhoneNo.length < 12) {
                            handleNotification('Please Enter Valid Phone Number', true)
                            document.getElementById('usersPhoneNo').focus();
                        } else {
                            wsAddEditUser(props.initialValues.ID, '1', props.PracticeID, values.usersFirstname, values.usersLastname, values.usersEmailAddress, values.usersPhoneNo, values.active, '3')
                        }
                    }} >
                    {({ handleSubmit, values, setFieldValue, errors, touched, }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <div className={`form_field ${errors && touched.usersFirstname && errors.usersFirstname && errors.usersFirstname !== '' ? 'error' : ''}`}>
                                    <div style={{ textAlign: 'left' }} className="field-left">
                                        <label>First Name</label>
                                    </div>
                                    <div className="field-right">
                                        <Field type="text" placeholder="Enter First Name" id={'usersFirstname'} name="usersFirstname" value={values.usersFirstname} maxLength="50" />
                                        {errors && touched.usersFirstname && errors.usersFirstname && errors.usersFirstname !== '' && (
                                            <span className='error-msg'>
                                                <ErrorMessage name='usersFirstname' render={(err) => { return err; }} />
                                            </span>)}
                                    </div>
                                </div>

                                <div className={`form_field ${errors && touched.usersLastname && errors.usersLastname && errors.usersLastname !== '' ? 'error' : ''}`}>
                                    <div style={{ textAlign: 'left' }} className="field-left">
                                        <label>Last Name</label>
                                    </div>
                                    <div className="field-right">
                                        <Field type="text" placeholder="Enter Last Name" id={'usersLastname'} name="usersLastname" value={values.usersLastname} maxLength="50" />
                                        {errors && touched.usersLastname && errors.usersLastname && errors.usersLastname !== '' && (
                                            <span className='error-msg'>
                                                <ErrorMessage name='usersLastname' render={(err) => { return err; }} />
                                            </span>)}
                                    </div>
                                </div>

                                <div className={`form_field ${errors && touched.usersEmailAddress && errors.usersEmailAddress && errors.usersEmailAddress !== '' ? 'error' : ''}`}>
                                    <div style={{ textAlign: 'left' }} className="field-left">
                                        <label>Email Address</label>
                                    </div>
                                    <div className="field-right">
                                        <Field type="text" placeholder="Enter Email Address" id={'usersEmailAddress'} name="usersEmailAddress" value={values.usersEmailAddress} maxLength="50" />
                                        {errors && touched.usersEmailAddress && errors.usersEmailAddress && errors.usersEmailAddress !== '' && (
                                            <span className='error-msg'>
                                                <ErrorMessage name='usersEmailAddress' render={(err) => { return err; }} />
                                            </span>)}
                                    </div>
                                </div>
                                <div className={`form_field ${errors && touched.usersPhoneNo && errors.usersPhoneNo && errors.usersPhoneNo !== '' ? 'error' : ''}`}>
                                    <div style={{ textAlign: 'left' }} className="field-left">
                                        <label>Phone No</label>
                                    </div>
                                    <div className="field-right">
                                        <Field type="text" placeholder="Enter Phone No" id={'usersPhoneNo'} name="usersPhoneNo" value={values.usersPhoneNo} maxLength="14"
                                            onChange={(e) => setFieldValue('usersPhoneNo', normalizeInput(e.target.value))} />

                                        {errors && touched.usersPhoneNo && errors.usersPhoneNo && errors.usersPhoneNo !== '' && (
                                            <span className='error-msg'>
                                                <ErrorMessage name='usersPhoneNo' render={(err) => { return err; }} />
                                            </span>)}
                                    </div>
                                </div>

                                <div className="form_field">
                                    <div style={{ textAlign: 'left' }} className="field-left">
                                        <label>Is Active ?</label>
                                    </div>
                                    <div style={{ textAlign: 'left' }} className="field-right">
                                        <div className="custom-radio">
                                            <input type="radio" name="active" id="yes-text"
                                                defaultValue={values.active}
                                                defaultChecked={values.active != 0 ? true : false}
                                                onClick={(e) => setFieldValue('active', 1)} />
                                            <label htmlFor="yes-text">Yes</label>
                                        </div>
                                        <div className="custom-radio">
                                            <input type="radio" name="active" id="no-text"
                                                defaultValue={values.active}
                                                defaultChecked={values.active == 0 ? true : false}
                                                onClick={(e) => setFieldValue('active', 0)} />
                                            <label htmlFor="no-text">No</label>
                                        </div>
                                    </div>
                                </div>

                                <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                    <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" />
                                </div>
                            </form>)
                    }}
                </Formik>

            </div>
            <Notification
                isError={isError}
                message={message}
                showNotification={showNotification}
                clearNotification={handleClearNotification.bind(this)}
                closeNotification={handleClearNotification.bind(this)}
            />
        </div>

    )
};

const validateUsersForm = yup.object().shape({
    usersFirstname: yup.string().required('First Name is required'),
    usersLastname: yup.string().required('Last Name is required'),
    usersEmailAddress: yup.string().required('Email Address is required'),
    usersPhoneNo: yup.string().required('Phone No is required'),
    // roleid: yup.string().required('Role is required'),
});

export default UserForm;