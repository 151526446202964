/*eslint-disable eqeqeq*/
import React from 'react';
import BrandList from '../../component/componentBrand/brandlist';
// import { data } from './branddata'
import * as APIController from '../../../../api/APIController.js';

import Notification from '../../../../shared/components/notification/notification';

class BrandContainer extends React.Component {

    state = {
        dataSource: [],
        currentPage: 0,
        count: 0,
        list: [],
        list1: [],
        rowsPerPage: 25,
        rowsPerPageOptions: [25, 10, 5],
        from: 0,
        to: 25,
        progress: true,
        message: '',
        showNotification: false,
        isError: false,
        showMessage: this.props.history.location.state != null && this.props.history.location.state.showMessage
    }

    // const[dataSource, setdataSource] = React.useState([]);
    // const[myState, setMyState] = React.useState(0)

    componentDidMount() {
        this.wsGetBrand();
        this.state.showMessage != '' && this.handleNotification(this.state.showMessage)
        this.props.history.replace('/brand', null);
    }
    handlebind() {
        this.wsGetBrand()
    }

    wsGetBrand() {
        this.setState({ progress: true, });
        APIController.GetBrand()
            .then((response) => {
                if (response.error == null) {
                    // setdataSource(response.object.data)
                    this.setState({
                        list: response.object.data,
                        list1: response.object.data,
                        count: response.object.data.length,
                        progress: false,
                    })
                }
                else if (response.error.message && response.error.message.length > 0) {
                    // ToastsStore.warning(response.error.message)
                }
            })
    }


    render() {
        return (
            <>
                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    clearNotification={this.handleClearNotification}
                    closeNotification={this.handleClearNotification}
                />
                <section className="content-right-main">
                    <div className="button-field button-field-country">
                        <button onClick={() => {
                            this.props.history.push({
                                pathname: '/brand/add',
                                state: {
                                    brandIdEdit: '0',
                                    brandNameEdit: '',
                                    brandDescriptionEdit: '',
                                    brandImageNameEdit: '',
                                    brandLogoURLEdit: '',
                                    brandOrderNoEdit: '',
                                    brandActiveEdit: 1,
                                    showInTile: 0,
                                    showInAutomation: 0,
                                    automationOrderNo: 0,
                                    showInLeftNav: 0,
                                    landingPageOrderNo: 0,
                                    myLandingPagesName: ''
                                }
                            })
                        }}>+ Add Brand</button>
                    </div>

                    <BrandList {...this.state}
                        history={this.props.history}
                        handlebind={this.handlebind.bind(this)}
                        handleChangeRowsPerPage={this.handleRowsPerPageChange}
                        handleChangePage={this.handlePageChange}
                        applyFilter={this.handleApplyFilter}
                    />
                </section>
            </>
        )
    }

    /**
     * handleRowsPerPageChange method is used to update rows per page
     * it will reset page to 1
     */
    handleRowsPerPageChange = (value) => {
        let from = 0
        let to = value;
        this.setState({ rowsPerPage: value, currentPage: 1, to: to, from: from })
    }

    /**
     * handlePageChange method is used to change page
     */
    handlePageChange = values => {
        let from = values === 1 ? 0 * this.state.rowsPerPage : (values - 1) * this.state.rowsPerPage
        let to = values === 1 ? this.state.rowsPerPage : parseInt(from) + parseInt(this.state.rowsPerPage);
        this.setState({ currentPage: Number(values), to: to, from: from })
    }

    /**
     * handleApplyFilter method is used to apply filter on every columns
     */
    handleApplyFilter = (tag, values, type) => {
        if (values !== "") {
            let searchBrands = []
            /**
             * it will check if filter is apply from select than it will match exact keyword
             * else it will find nearest word
             */
            if (type === 'select') {
                searchBrands = this.state.list1.filter(
                    (us) => us[tag].toLowerCase() === values.toLowerCase()
                );
            } else {
                searchBrands = this.state.list.filter(
                    (us) => us[tag].toString().toLowerCase().indexOf(values.toLowerCase()) > -1
                );
            }
            this.setState({
                list: searchBrands,
                currentPage: 0,
                count: searchBrands.length,
                rowsPerPage: 25,
                rowsPerPageOptions: [25, 10, 5],
                from: 0,
                to: 25,
                progress: false,
            });
        } else {
            this.wsGetBrand();
            this.setState({
                currentPage: 0,
                rowsPerPage: 25,
                rowsPerPageOptions: [25, 10, 5],
                from: 0,
                to: 25,
            });
        }
    }

    handleNotification = (message, isError) => {

        this.setState({
            message: message,
            showNotification: true,
            isError: isError
        })

        setTimeout(() => {
            this.handleClearNotification();
        }, 4000)
    }

    handleClearNotification = () => {
        this.setState({
            message: '',
            showNotification: false,
            isError: false
        })
    }

}

export default BrandContainer;