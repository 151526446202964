/*eslint-disable eqeqeq*/
import React from "react";
import SupplyCalculatorList from "./supplyCalculatorList";
import CsvDownload from 'react-json-to-csv'

import * as APIController from "../../../../api/APIController.js";
import PageTitle from "../../../../config/pageTitle";
import TabComponent from "../dashboard-tab-component";
import * as AppConstants from "../../../../config/AppConstant";

class SupplyCalculatorListing extends React.Component {
    state = {
        currentPage: 1,
        count: 0,
        rowsPerPage: 100,
        rowsPerPageOptions: [50, 100, 250, 500, 1000],
        list: [],
        list1: [],
        from: 0,
        to: 100,
        progress: true,
        downloadList: [],
        LensesTypeData: [],
    };

    componentDidMount() {
        this.wsGetSupplyCostCalculatorLead(this.state.from, this.state.to, "", "", "", "", "", "", this.state.currentPage, this.state.rowsPerPage);
    }

    wsGetSupplyCostCalculatorLead(from, to, PatientName, PatientEmailAddress, PurchaseType, LeftLenseType, RightLenseType, LeadAt, CurrentPage, RecordPerPage) {
        this.setState({ progress: true, });
        APIController.GetSupplyCostCalculatorLead(PatientName, PatientEmailAddress, PurchaseType, LeftLenseType, RightLenseType, LeadAt, CurrentPage, RecordPerPage).then((response) => {
            if (response.error == null && response.object.data.length != 0) {
                let downloadData = []
                // "Patient Name": item.PatientName, "Patient Email Address": item.PatientEmailAddress, "Purchase Type": item.PurchaseType,
                response.object.data.map((item) => downloadData.push({ "Date": item.LeadAt, "Left Lens Type": item.LeftLenseType.toString(), "Right Lens Type": item.RightLenseType }))
                this.setState({
                    downloadList: downloadData,
                    to: isNaN(to) ? 1000000 : to,
                    from: from,
                    count: response.object.data[0].MaxRows,
                    list: response.object.data,
                    progress: false,
                });
            } else { this.setState({ list: [], progress: false }) }
            this.wsGetLensesTypeForDropdown()
        });
    }

    wsGetLensesTypeForDropdown() {
        APIController.GetLensesTypeForDropdown()
            .then((response) => {
                if (response.error == null && response.object.data.length != 0) {
                    this.setState({ LensesTypeData: response.object.data })
                }
            })
    }

    render() {
        return (
            <section className='content-right-main'>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h1><PageTitle Title={"Cost Estimates Created"} /></h1>
                    <div className='button-field'>
                        <CsvDownload data={this.state.downloadList} filename={"Cost Estimates Created Report.csv"}>Download to CSV</CsvDownload>
                    </div>
                </div>

                <SupplyCalculatorList
                    {...this.state}
                    history={this.props.history}
                    lensesTypeData={this.state.LensesTypeData}
                    handleChangeRowsPerPage={this.handleRowsPerPageChange}
                    handleChangePage={this.handlePageChange}
                    handleApplyFilter={this.handleApplyFilter}
                    handleNotification={this.handleNotification}
                />
            </section>
        );
    }

    handleRowsPerPageChange = (value, PatientName, PatientEmailAddress, PurchaseType, LeftLenseType, RightLenseType, LeadAt) => {
        let from = 0
        let to = value;
        this.setState({ rowsPerPage: value, currentPage: 1, to: to, from: from })
        this.wsGetSupplyCostCalculatorLead(from, to, PatientName, PatientEmailAddress, PurchaseType, LeftLenseType, RightLenseType, LeadAt, isNaN(value) ? '' : this.state.currentPage, isNaN(value) ? 1000000 : value);
    }

    handlePageChange = (values, PatientName, PatientEmailAddress, PurchaseType, LeftLenseType, RightLenseType, LeadAt) => {
        let from = values === 1 ? 0 * this.state.rowsPerPage : (values - 1) * this.state.rowsPerPage
        let to = values === 1 ? this.state.rowsPerPage : (from + this.state.rowsPerPage);
        this.setState({ currentPage: Number(values) })
        this.wsGetSupplyCostCalculatorLead(from, to, PatientName, PatientEmailAddress, PurchaseType, LeftLenseType, RightLenseType, LeadAt, Number(values), this.state.rowsPerPage);
    }

    handleApplyFilter = (PatientName, PatientEmailAddress, PurchaseType, LeftLenseType, RightLenseType, LeadAt) => {
        this.wsGetSupplyCostCalculatorLead(this.state.from, this.state.to, PatientName, PatientEmailAddress, PurchaseType, LeftLenseType, RightLenseType, LeadAt, this.state.currentPage, this.state.rowsPerPage);
    };
}

export default SupplyCalculatorListing;
