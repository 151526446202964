/*eslint-disable eqeqeq*/
import React from 'react';
import AssetTypesList from '../../component/componentAssetTypes/AssetTypeslist';
// import {data} from './data'
import * as APIController from '../../../../api/APIController';
// import * as APIController from '../../../../src/api/APIController';
import Notification from '../../../../shared/components/notification/notification';
class AdminAssetTypesContainer extends React.Component {

    state = {
        currentPage: 0,
        count: 0,
        rowsPerPage: 50,
        rowsPerPageOptions: [50, 100, 200],
        list: [],
        list1: [],
        from: 0,
        to: 50,
        progress: true,
        message: '',
        showNotification: false,
        isError: false,
        showMessage: this.props.history.location.state != null && this.props.history.location.state.showMessage
    }
    componentDidMount() {
        this.wsGetAssetTypes();
        this.state.showMessage != '' && this.handleNotification(this.state.showMessage)
        this.props.history.replace('/campaigns/AssetTypes', null);
    }
    handlebind() {
        this.wsGetAssetTypes()
    }
    wsGetAssetTypes() {
        this.setState({ progress: true });
        APIController.GetAssetTypes()
            .then((response) => {
                if (response.error == null) {
                    this.setState({
                        list: response.object.data,
                        list1: response.object.data,
                        count: response.object.data[0].MaxRows,
                        progress: false,
                    })
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.handleNotification(response.error.message)
                }
            })
    }
    render() {
        return (
            <>
                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    clearNotification={this.handleClearNotification}
                    closeNotification={this.handleClearNotification}
                />
                <section className="content-right-main">
                    <div className="button-field button-field-country">
                        <button onClick={() => {
                            this.props.history.push({
                                pathname: '/AssetTypes/add',
                                state: { AssetTypeIdEdit: '0', AssetTypeId: '', AssetTypeNameEdit: '', DescriptionEdit: '', FreeAssetTypeEdit: 0, DirectMailAssetTypeEdit: 0, AssetTypeActiveEdit: 1, }
                            })
                        }}>+ Add Asset Type</button>
                    </div>
                    <AssetTypesList {...this.state}
                        history={this.props.history}
                        handlebind={this.handlebind.bind(this)}
                        handleChangeRowsPerPage={this.handleRowsPerPageChange}
                        handleChangePage={this.handlePageChange}
                        applyFilter={this.handleApplyFilter}
                        handleNotification={this.handleNotification}
                    />
                </section>
            </>
        )
    }

    /**
    * handleRowsPerPageChange method is used to update rows per page
    * it will reset page to 1
    */

    handleRowsPerPageChange = (value) => {
        let from = 0
        let to = value;
        this.setState({ rowsPerPage: value, currentPage: 1, to: to, from: from })
    }

    /**
     * handlePageChange method is used to change page
     */

    handlePageChange = values => {
        let from = values === 1 ? 0 * this.state.rowsPerPage : (values - 1) * this.state.rowsPerPage
        let to = values == 1 ? this.state.rowsPerPage : parseInt(from) + parseInt(this.state.rowsPerPage);
        this.setState({ currentPage: Number(values), to: to, from: from })
    }


    /**
     * handleApplyFilter method is used to apply filter on every columns
     */
    handleApplyFilter = (tag, values, type) => {
        if (values !== "") {

            let searchAssetTypes = []
            /**
             * it will check if filter is apply from select than it will match exact keyword
             * else it will find nearest word
             */
            if (type === 'select') {
                searchAssetTypes = this.state.list1.filter(
                    (us) => us[tag].toLowerCase() === values.toLowerCase()
                );
            } else {
                searchAssetTypes = this.state.list.filter(
                    (us) => us[tag].toLowerCase().indexOf(values.toLowerCase()) > -1
                );
            }
            this.setState({
                list: searchAssetTypes,
                currentPage: 0,
                count: searchAssetTypes.length,
                rowsPerPage: 50,
                rowsPerPageOptions: [50, 100, 200],
                from: 0,
                to: 50,
                progress: false,
            });
        } else {
            this.wsGetAssetTypes();
            this.setState({
                currentPage: 0,
                rowsPerPage: 50,
                rowsPerPageOptions: [50, 100, 200],
                from: 0,
                to: 50
            });
        }
    }

    handleNotification = (message, isError) => {

        this.setState({
            message: message,
            showNotification: true,
            isError: isError
        })

        setTimeout(() => {
            this.handleClearNotification();
        }, 4000)
    }

    handleClearNotification = () => {
        this.setState({
            message: '',
            showNotification: false,
            isError: false
        })
    }

}

export default AdminAssetTypesContainer;