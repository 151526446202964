/*eslint-disable eqeqeq*/
import React from "react";
import Table from "../../../../shared/components/table/table";
import TableHead from "../../../../shared/components/table/tableHead";
import TableRow from "../../../../shared/components/table/tableRow";
import TableBody from "../../../../shared/components/table/tableBody";
import TableCell from "../../../../shared/components/table/tableCell";
import double_left_arrow from '../../../../assets/images/left-doble-arow.svg';
import left_arrow from '../../../../assets/images/left-arrow.svg';
import double_right_arrow from '../../../../assets/images/right-doble-arow.svg';
import right_arrow from '../../../../assets/images/right-arrow.svg';
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

import Notification from '../../../../shared/components/notification/notification';
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";
import * as AppConstants from "../../../../config/AppConstant";

const FreeAssetsReportList = (props) => {
	// const headerList = ["Expiration Date"]
	const headerList = ["Brand Name", "Campaign Name", "Asset Type", "Asset Name", "Segment", "Veeva Code"]

	const { currentPage, count, rowsPerPage, rowsPerPageOptions, list, to, from } = props;
	let countryList = list;
	// countryList = list.slice(from, to)

	const page = Math.floor(count / rowsPerPage) + 1;

	/**
	 * handleChangeInCurrentPage method is used to change page number
	 * it will check that page number does not exist last page
	 * @param {*} value 
	 */
	const handleChangeInCurrentPage = (value) => {
		if (value <= page && Number(parseInt(value))) {
			props.handleChangePage(Number(value), Name, CampaignName, BrandName, VeevaCode, AssetType, ExpirationDate, Segment);
		}
	}

	const [message, setMessage] = React.useState("");
	const [showNotification, setShowNotification] = React.useState(false);
	const [isError, setIsError] = React.useState(false);

	const [Name, setName] = React.useState("");
	const [CampaignName, setCampaignName] = React.useState("");
	const [BrandName, setBrandName] = React.useState("");
	const [VeevaCode, setVeevaCode] = React.useState("");
	const [AssetType, setAssetType] = React.useState("");
	const [ExpirationDate, setExpirationDate] = React.useState("");
	const [Segment, setSegment] = React.useState("");

	const handleClearNotification = () => {
		setMessage('')
		setShowNotification(false)
		setIsError(false)
	}

	const _handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			props.handleApplyFilter(Name, CampaignName, BrandName, VeevaCode, AssetType, ExpirationDate, Segment)
		}
	}

	return (
		<>
			<div className="table-main-wrap">
				<PerfectScrollbar component='div'>
					<Table>
						<TableHead>
							<TableRow> {headerList.map((obj, index) => { return <TableCell style={{ minWidth: '100px', padding: '5px' }} key={index}>{obj}</TableCell>; })} </TableRow> <TableRow>
								<TableCell style={{ minWidth: '210px', padding: '5px' }}> <input type="text" name="" placeholder="Brand Name" onKeyDown={_handleKeyDown} onChange={(e) => setBrandName(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: '150px', padding: '5px' }}> <input type="text" name="" placeholder="Campaign Name" onKeyDown={_handleKeyDown} onChange={(e) => setCampaignName(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: '210px', padding: '5px' }}> <input type="text" name="" placeholder="Asset Type" onKeyDown={_handleKeyDown} onChange={(e) => setAssetType(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: '260px', padding: '5px' }}> <input type="text" name="" placeholder="Asset Name" onKeyDown={_handleKeyDown} onChange={(e) => setName(e.target.value)} /> </TableCell>
								{/* <TableCell style={{ minWidth: '100px',  padding: '5px' }}> <input type="text" name="" placeholder="Expiration Date" onKeyDown={_handleKeyDown} onChange={(e) => setExpirationDate(e.target.value)} /> </TableCell> */}
								<TableCell style={{ minWidth: '140px', padding: '5px' }}>
									<select onChange={(e) => {
										setSegment(e.target.value)
										props.handleApplyFilter(Name, CampaignName, BrandName, VeevaCode, AssetType, ExpirationDate, e.target.value)
									}}>
										<option value={""}>All</option>
										<option value={"Standard"}>Standard</option>
										<option value={"Vision Source"}>Vision Source</option>
										<option value={"VSP Premier"}>VSP Premier</option>
									</select>
								</TableCell>
								<TableCell style={{ minWidth: '100px', padding: '5px' }}> <input type="text" name="" placeholder="Veeva Code" onKeyDown={_handleKeyDown} onChange={(e) => setVeevaCode(e.target.value)} /> </TableCell>

							</TableRow>
						</TableHead>
						<TableBody>
							{!!countryList.length ? countryList.map((obj, idnex) => {
								return (
									<TableRow key={idnex}>
										<TableCell style={{ minWidth: '210px', padding: '5px' }}>{obj.Brand} </TableCell>
										<TableCell style={{ minWidth: '150px', padding: '5px' }}>{obj.Campaign} </TableCell>
										<TableCell style={{ minWidth: '210px', padding: '5px' }}>{obj.AssetCategory} </TableCell>
										<TableCell style={{ minWidth: '260px', padding: '5px' }}>{obj.AssetName}</TableCell>
										{/* <TableCell style={{ minWidth: '100px',  padding: '5px' }}>{obj.ExpirationDate} </TableCell> */}
										<TableCell className={`${backgroundColor(obj.Segment)}`} style={{ minWidth: '140px' }}>
											<span>{obj.Segment}</span>
										</TableCell>
										{/* <TableCell style={{ minWidth: '100px', padding: '5px', textAlign: "center" }}> <span>{obj.Segment}</span> </TableCell> */}
										<TableCell style={{ minWidth: '100px', padding: '5px' }}>{obj.VeevaCode} </TableCell>
									</TableRow>
								);
							}) : <TableRow>
								<TableCell colSpan={headerList.length} className="not_found">
									{props.progress ? <CircularProgress /> : <label>Not Currently Available</label>}
								</TableCell>
							</TableRow>}
						</TableBody>
					</Table>
				</PerfectScrollbar>
			</div>
			<Notification
				isError={isError}
				message={message}
				showNotification={showNotification}
				closeNotification={handleClearNotification.bind(this)}
			/>

			<div className="table-pagination">
				<div className="pagination-info">
					<p>Showing {from + 1} - {to > count ? count : to} of {count}</p>
				</div>
				<div className="pagination-control">
					<a href="#!" title="" className={`double-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(1)
						}}
					><img src={double_left_arrow} alt="left" /></a>
					<a href="#!" title="" className={`simple-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage - 1)
						}}
					><img src={left_arrow} alt="single_left" /></a>
					<span>Page</span>
					<input type="text" name="textbox" value={currentPage === 0 ? currentPage + 1 : currentPage} onChange={(e) => handleChangeInCurrentPage(e.target.value)} />
					<span>of {page}</span>
					<a href="#!" title="" className={`simple-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage + 1)
						}}
					><img src={right_arrow} alt="right" /></a>
					<a href="#!" title="" className={`double-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(page)
						}}><img src={double_right_arrow} alt="single_right" /></a>
					<select onChange={(e) => props.handleChangeRowsPerPage(e.target.value, Name, CampaignName, BrandName, VeevaCode, AssetType, ExpirationDate, Segment)} defaultValue={rowsPerPage}>
						{rowsPerPageOptions.map((obj, index) => {
							return <option value={obj} key={index}>{obj}</option>
						})}
					</select>
				</div>
			</div>
		</>
	);
};

const backgroundColor = (status) => {
	let color = '';
	switch (status) {
		case "Standard":
			color = 'purple';
			break;
		case "Vision Source":
			color = 'blue';
			break;
		case 'VSP Premier':
			color = 'green';
			break;
		default:
			color = 'blue';
			break;
	}
	return color;
};

export default FreeAssetsReportList;
