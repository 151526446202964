import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';



const ExpansionPanelComponent = (props) => {


    const handleChange = (panel) => (event, isExpanded) => {
        props.setExpanded(isExpanded ? panel : false);
    };
    return (
        <div className={props.className}>
            <ExpansionPanel expanded={props.expanded === props.id} onChange={handleChange(props.id)}>
                <ExpansionPanelSummary
                    className={props.summaryClassName}
                    expandIcon={props.icon}
                    aria-controls={props.controls}
                    id={props.id}
                >
                    {props.title}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {props.children}
                </ExpansionPanelDetails>
            </ExpansionPanel>

        </div>
    )
}


export default ExpansionPanelComponent;