import React, { useState } from "react";
import TextFieldOld from "@material-ui/core/TextField";
import { Grid, ClickAwayListener, CircularProgress, MenuItem } from "@material-ui/core";
import { Formik, Field } from "formik";
import PerfectScrollbar from "react-perfect-scrollbar";

import * as yup from "yup";
import down_arrow from "../../../assets/images/down-arrow-select.svg";
import google_maps from "../../../assets/images/google-maps.png";
import checkmark from "../../../assets/images/icon/checkmark.png";
import fail from "../../../assets/images/icon/fail.png";

import uuid from 'react-uuid';
import "./location.css";
import MyMapComponent from "../../../shared/components/maps/google-maps";
import * as APIController from '../../../api/APIController.js';
import { AspectRatio } from 'react-aspect-ratio';

import TextField from "../../../shared/components/TextField/TextField";
import Dropdown from '../../../shared/components/Dropdown/Dropdown';
import * as AppConstants from "../../../config/AppConstant";
import moment from 'moment';

import ModalComponent from "../../../shared/components/modal/modal";
import close_icon from "../../../assets/images/gray-close-ic.svg";

import { URLs } from "../../../api/URLs";
import { usePlacesWidget } from "react-google-autocomplete";

// city: yup.string().required('City is required'),
// state: yup.string().required('State is required'),
// email: yup.string().required('Business email address is required'),
const validateProductForm = yup.object().shape({
	businessInfo: yup.object().shape({
		name: yup.string().required('Business Name is required'),
		address1: yup.string().required('Business Address 1 is required'),
		postCode: yup.string().required('Post Code is required'),
		businessPhone: yup.string().required('Business Phone Number is required'),
		website: yup.string().required('Business Website URL is required')
	})

});

const validateProductFormYomi = yup.object().shape({
	businessInfo: yup.object().shape({
		name: yup.string().required('Business Name is required'),
		address1: yup.string().required('Business Address 1 is required'),
		postCode: yup.string().required('Post Code is required'),
		businessPhone: yup.string().required('Business Phone Number is required'),
	})
});

const LocationValidationComponent = (props) => {

	const [finallatlng, setfinallatlng] = useState([]);
	const [selectedAddress, setselectedAddress] = useState([]);

	const [pincode, setpincode] = useState(props.locationDetail.campaignReach.zipCode);
	const [miles, setmiles] = useState(props.locationDetail.campaignReach.radius == null ||
		props.locationDetail.campaignReach.radius == undefined ||
		props.locationDetail.campaignReach.radius == '' ? 5 : props.locationDetail.campaignReach.radius);
	const [DataNotFound, setDataNotFound] = useState('');

	const [openZIPCodeSuggestion, setopenZIPCodeSuggestion] = useState(false);
	const [searchPostcodesList, setsearchPostcodesList] = useState([]);
	const [isPostcodeSelected, setisPostcodeSelected] = useState(true);

	const [currentCitylat, setcurrentCitylat] = useState('');
	const [currentCitylng, setcurrentCitylng] = useState('');

	const [mapProgress, setmapProgress] = useState(true);
	const [expandedMap, setExpandedMap] = React.useState(true);

	const [eventDateT, seteventDateT] = React.useState(new Date());
	const [rsvpDateT, setrsvpDateT] = React.useState(new Date());
	const [eventTimeT, seteventTimeT] = React.useState(new Date());

	const [uploadImageHeight, setuploadImageHeight] = React.useState(750);
	const [uploadImageWidth, setuploadImageWidth] = React.useState(1050);

	let finallatlng2 = [];
	let TempArray = [];

	React.useEffect(() => {
		if (props.locationDetail.campaignReach.selectedLocations != "[]") {
			setselectedAddress(JSON.parse(props.locationDetail.campaignReach.selectedLocations).selectedAddress)

			setcurrentCitylat(JSON.parse(props.locationDetail.campaignReach.selectedLocations).Latitude)
			setcurrentCitylng(JSON.parse(props.locationDetail.campaignReach.selectedLocations).Longitude)


			JSON.parse(props.locationDetail.campaignReach.selectedLocations).selectedAddress == '' ||
				JSON.parse(props.locationDetail.campaignReach.selectedLocations).selectedAddress == undefined
				? wsGetMappingData(props.locationDetail.campaignReach.zipCode, miles)
				: setDataMapInLocation(JSON.parse(props.locationDetail.campaignReach.selectedLocations))

		} else { wsGetMappingData(props.locationDetail.campaignReach.zipCode, miles) }

	}, []);

	let initialValues = props.locationDetail;
	const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

	const wsUploadPracticeLogo = (practiceId, name, value, isPracticeLogo) => {
		APIController.UploadPracticeLogo(practiceId, name, value, isPracticeLogo)
			.then((response) => { });
	}

	const wsGetMappingData = (Postcode, Distance) => {
		APIController.GetMappingData(Postcode, Distance * 0.65)
			.then((response) => {
				if (response.object.status != 0) {
					setDataMapLocation(response.object.data)
					setDataNotFound('')
				} else {
					setDataNotFound(response.object.message)
					setmapProgress(false)
				}
			});
	}

	const setDataMapLocation = (Data) => {
		Data.Data.map((item, index) => {
			TempArray.push({ 'name': item.City, 'IsSelected': true, Latitude: item.Latitude, Longitude: item.Longitude })
			finallatlng2.push({ lat: item.Latitude, lng: item.Longitude, selected: true })
			// return TempArray
		})
		setcurrentCitylat(Data.Latitude)
		setcurrentCitylng(Data.Longitude)

		finallatlng2.push({ lat: parseFloat(Data.Latitude), lng: parseFloat(Data.Longitude), selected: true })
		setselectedAddress(TempArray)
		setfinallatlng(finallatlng2)
		setmapProgress(false)
	}

	const setDataMapInLocation = (Locations) => {
		Locations.selectedAddress.map((item, index) => {
			TempArray.push({ 'name': item.name, 'IsSelected': item.IsSelected, Latitude: item.Latitude, Longitude: item.Longitude })
			finallatlng2.push({ lat: item.Latitude, lng: item.Longitude, selected: true })
			// return TempArray
		})
		setcurrentCitylat(Locations.Latitude)
		setcurrentCitylng(Locations.Longitude)

		finallatlng2.push({ lat: Locations.Latitude, lng: Locations.Longitude, selected: true })
		setselectedAddress(TempArray)
		setfinallatlng(finallatlng2)
		setmapProgress(false)
	}

	const handleValidateScroll = (val) => {
		let id;
		if (val.businessInfo.name == '') {
			id = 'businessInfo.name'
		}
		else if (val.businessInfo.address1 == '') {
			id = 'businessInfo.address1'
		}
		else if (val.businessInfo.city == '') {
			id = 'businessInfo.city'
		}
		else if (val.businessInfo.state == '') {
			id = 'businessInfo.state'
		}
		else if (val.businessInfo.postCode == '' || val.businessInfo.postCode.length < 5) {
			id = 'businessInfo.postCode'
		}
		else if (val.businessInfo.businessPhone == '' || val.businessInfo.businessPhone == undefined) {
			id = 'businessInfo.businessPhone'
		}
		// else if (val.businessInfo.businessPhone.length < 12) {
		// 	id = 'businessInfo.businessPhone'
		// }
		// else if (val.businessInfo.email == '') {
		// 	id = 'businessInfo.email'
		// }
		else if (val.businessInfo.website == '') {
			id = 'businessInfo.website'
		}

		var element = document.getElementById(id);
		id != undefined && props.handleTitle(id)
		id != undefined && element.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });
	}

	const formatPhoneNumber = (phoneNumberString) => {
		var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
		var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			var intlCode = (match[1] ? '' : '');
			return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
		}
		return null;
	}

	const wsSearchPostcodes = (postcode) => {
		APIController.SearchPostcodes(postcode)
			.then((response) => {
				// this.setState({ SearchPostcodes: response.object.data })
				setsearchPostcodesList(response.object.data)
			})
	}

	const handleUploadLogoSize = (image) => {
		let img = new Image()
		img.src = image
		img.onload = () => {
			setuploadImageHeight(img.height)
			setuploadImageWidth(img.width)
		}
	}

	const [ValueLoading, setvalueLoading] = useState(false);
	const { ref } = usePlacesWidget({
		apiKey: URLs.googlekey,
		options: {
			componentRestrictions: { country: "us" },
			types: ["address"],
			fields: ["address_component", "formatted_address", "plus_code", "geometry", "name"],
			strictBounds: true,
		},
		onPlaceSelected: (place) => {
			setvalueLoading(true)

			let initialValuesTemp = initialValues;

			initialValuesTemp.businessInfo.address1 = ""
			initialValuesTemp.businessInfo.address2 = ""
			initialValuesTemp.businessInfo.postCode = ""
			initialValuesTemp.businessInfo.city = ""
			initialValuesTemp.businessInfo.state = ""

			initialValuesTemp.businessInfo.latitude = place.geometry.location.lat()
			initialValuesTemp.businessInfo.longitude = place.geometry.location.lng()
			initialValuesTemp.businessInfo.googleaddress = place.formatted_address

			setcurrentCitylat(place.geometry.location.lat())
			setcurrentCitylng(place.geometry.location.lng())
			setfinallatlng([{ lat: place.geometry.location.lat(), lng: place.geometry.location.lng(), selected: true }])
			for (const component of place.address_components) {
				const componentType = component.types[0];

				switch (componentType) {
					case "street_number": {
						initialValuesTemp.businessInfo.address1 = `${component.long_name}`
						break;
					} case "route": {
						initialValuesTemp.businessInfo.address1 = initialValuesTemp.businessInfo.address1 + " " + `${component.long_name}`
						break;
					} case "subpremise": {
						initialValuesTemp.businessInfo.address2 = `${component.long_name}`
						break;
					} case "postal_code": {
						initialValuesTemp.businessInfo.postCode = `${component.long_name}`
						break;
					} case "locality": {
						initialValuesTemp.businessInfo.city = `${component.long_name}`
						break;
					} case "administrative_area_level_1": {
						initialValuesTemp.businessInfo.state = `${component.short_name}`
						break;
					}
				}
			}
			initialValues = initialValuesTemp
			setvalueLoading(false)
		},
	});


	return (
		ValueLoading ? <CircularProgress /> :
			<div className='business-info-form'>
				<Formik
					initialValues={initialValues}
					validationSchema={AppConstants.GetMainClassName() == "app-main" ? validateProductForm : validateProductFormYomi}
					validateOnMount={false}
					onSubmit={(values) => {
						if (values.businessInfo.postCode.length < 5) {
							handleValidateScroll(values)
						} else if (formatPhoneNumber(values.businessInfo.businessPhone).length < 14) {
							handleValidateScroll(values)
						} else {
							let TempArray1 = { selectedAddress, Latitude: parseFloat(currentCitylat), Longitude: parseFloat(currentCitylng) }
							setselectedAddress(TempArray1)
							values.campaignReach.selectedLocations = TempArray1
							if (values.businessInfo.appointmenturl.includes("http://") || values.businessInfo.appointmenturl.includes("https://")) {
								props.updateLocationDetails(values)
							} else {
								values.businessInfo.appointmenturl = 'http://' + values.businessInfo.appointmenturl
								props.updateLocationDetails(values)
							}
						}
					}}>
					{({ handleSubmit, values, setFieldValue, errors }) => {
						errors.businessInfo == undefined ? props.handleTitle('Valid') : props.handleTitle('inValid')
						return (
							<form onSubmit={handleSubmit}>
								<div className='business-info-wrap'>
									<div className='info-col-left'>
										<div className='white-box'>
											<div className='box-head'>
												<h4>Practice info</h4>
											</div>
											<div className='box-body'>
												<div id={'businessInfo.name'} className={`field-full ${values.businessInfo.name == '' ? 'error' : ''}`}>
													<Field as={TextField}
														error={values.businessInfo.name == '' ? true : false}
														label='Business Name' type='text' placeholder='Business Name' name='businessInfo.name' value={values.businessInfo.name} />
												</div>

												<div id={'businessInfo.address1'} className={`field-full ${values.businessInfo.address1 == '' ? 'error' : ''}`}>
													<Field as={TextField} inputRef={ref}
														error={values.businessInfo.address1 == '' || values.businessInfo.latitude == null || values.businessInfo.longitude == null ? true : false} maxLength="100"
														label='Business Address 1' type='text' placeholder='Business Address 1' id='businessInfo.address1'
														name='businessInfo.address1' value={values.businessInfo.address1} />
												</div>

												<div id={'businessInfo.address2'} className={`field-full`}>
													<Field as={TextField}
														label='Business Address 2' type='text' placeholder='Business Address 2' name='businessInfo.address2' value={values.businessInfo.address2} />
												</div>
												<div id={'businessInfo.city'} className={`field-full`}>
													<Field disabled as={TextField} error={values.businessInfo.city == '' ? true : false}
														label='City' type='text' placeholder='City' name='businessInfo.city' value={values.businessInfo.city} />
												</div>
												<div id={'businessInfo.state'} className={`field-full`}>
													<Field disabled as={TextField} error={values.businessInfo.state == '' ? true : false}
														label='State' type='text' placeholder='State' name='businessInfo.state' value={values.businessInfo.state} />
												</div>
												<div style={{ position: 'relative' }} className={`field-full ${values.businessInfo.postCode == '' || values.businessInfo.postCode.length < 5 ? 'error' : ''}`}>
													<div id={`businessInfo.postCode`} style={{ position: 'relative' }}>
														<Field as={TextField} error={values.businessInfo.postCode == '' || values.businessInfo.postCode.length < 5 ? true : false}
															label='Post Code' type='text' placeholder='Post Code' name='businessInfo.postCode' value={values.businessInfo.postCode}
															onChange={(e) => {
																setisPostcodeSelected(false)
																if (e.target.value != '') {
																	setopenZIPCodeSuggestion(true);
																	wsSearchPostcodes(e.target.value);
																	setFieldValue('businessInfo.postCode', e.target.value)
																} else {
																	setopenZIPCodeSuggestion(false);
																	setsearchPostcodesList([])
																	setFieldValue('businessInfo.city', "");
																	setFieldValue('businessInfo.state', "");
																	setFieldValue('businessInfo.postCode', '');
																}
															}} />

														{openZIPCodeSuggestion ? (
															<div className='Suggetion-wrap'>
																{searchPostcodesList.length == 0 ? <div style={{ textAlign: 'center' }}> {'No Suggetion Found'} </div>
																	: searchPostcodesList.map((item, index) => {
																		return (
																			<div style={{ cursor: 'pointer', padding: '5px' }}
																				onClick={() => {
																					setFieldValue('businessInfo.city', item.City);
																					setFieldValue('businessInfo.state', item.State);
																					setFieldValue('businessInfo.postCode', item.Postcode);
																					setopenZIPCodeSuggestion(false);
																					setisPostcodeSelected(true)
																				}} >
																				{item.Postcode}
																			</div>
																		)
																	})
																}
															</div>
														) : null}
													</div>
												</div>

												<div id={'businessInfo.businessPhone'} className={`field-full ${values.businessInfo.businessPhone == '' ? 'error' : ''}`}>
													<Field as={TextField}
														error={values.businessInfo.businessPhone == '' ? true : false}
														label='Business Phone Number' type='text' placeholder='Business Phone Number' name='businessInfo.businessPhone'
														value={formatPhoneNumber(values.businessInfo.businessPhone)} />
												</div>
												<div className={`field-full`}>
													<Field as={TextField}
														label='Business email address' type='text' placeholder='Business email address' name='businessInfo.email' value={values.businessInfo.email} />
												</div>
												{AppConstants.GetMainClassName() == "app-main" &&
													<div className={`field-full`}>
														<Field as={TextField}
															label='Email marketing address' type='text' placeholder='Email marketing address' name='businessInfo.email2' value={values.businessInfo.email2} />
													</div>}
												<div id={'businessInfo.website'} className={`field-full ${values.businessInfo.website == '' ? 'error' : ''}`}>
													<Field as={TextField}
														error={AppConstants.GetMainClassName() == "app-main" && values.businessInfo.website == '' ? true : false}
														label='Business Website URL' type='text' placeholder='Business Website URL' name='businessInfo.website' value={values.businessInfo.website} />
												</div>
												{AppConstants.GetMainClassName() == "app-main" &&
													<div className={`field-full`}>
														<Field as={TextField}
															label='Schedule an Appointment URL' type='text' placeholder='Schedule an Appointment URL' name='businessInfo.appointmenturl' value={values.businessInfo.appointmenturl} />
													</div>}
												{AppConstants.GetMainClassName() == "app-yomi" && <>
													<div className={`field-full`}>
														<Field as={TextField}
															label='Doctor Name' type='text' placeholder='Doctor Name' name='businessInfo.doctorname' value={values.businessInfo.doctorname} />
													</div>
													<div className={`field-full`}>
														<Field as={TextField} label='Event Date' type='date' placeholder='Event Date' name='businessInfo.eventdate'
															value={values.businessInfo.eventdate == '' ? new Date() : eventDateT}
															onChange={(e) => {
																seteventDateT(e.target.value)
																setFieldValue(`businessInfo.eventdate`, moment(new Date(Date.parse(e.target.value)).toUTCString()).utcOffset(0, false).format('dddd MMM DD, yy'))
															}} />
													</div>
													<div className={`field-full`}>
														<Field as={TextField} label='Event Time' type='time' value={values.businessInfo.eventtime == '' ? '00:00' : eventTimeT}
															onChange={(e) => {
																seteventTimeT(e.target.value)
																var time = e.target.value.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
																if (time.length > 1) { // If time format correct
																	time = time.slice(1);  // Remove full string match value
																	time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
																	time[0] = +time[0] % 12 || 12; // Adjust hours
																}
																setFieldValue(`businessInfo.eventtime`, time.join(''));
															}}
														/>
													</div>
													<div className={`field-full`}>
														<Field as={TextField} label='RSVP Deadline' type='date' placeholder='RSVP Deadline' name='businessInfo.rsvpdeadline'
															value={values.businessInfo.rsvpdeadline == '' ? new Date() : rsvpDateT}
															onChange={(e) => {
																setrsvpDateT(e.target.value)
																setFieldValue(`businessInfo.rsvpdeadline`, moment(new Date(Date.parse(e.target.value)).toUTCString()).utcOffset(0, false).format('dddd MMM DD, yy'));
															}} />
													</div>
												</>}
											</div>
										</div>

									</div>

									<div className='info-col-right'>
										<div className='white-box'>
											<div className='box-head'>
												<h4>Practice Logo</h4>
											</div>
											{handleUploadLogoSize(values.logo)}
											<div className='box-body'>
												<Grid container direction={'row'} style={{ alignSelf: 'center', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
													<Grid container direction={'row'} style={{ alignSelf: 'center', height: '200px', width: '400px', border: '2px solid #f5f7fa', justifyContent: 'center', alignItems: 'center', margin: '10px' }}>
														{values.logo ? <AspectRatio ratio="3/4" style={{ maxWidth: '400px' }} >
															<img style={{ maxHeight: '200px' }} src={values.logo} alt='preview-imag' /> </AspectRatio>
															: <p style={{ textAlign: 'center', margin: '0 10px', fontSize: '14px' }}> - Accepted files: PNG & JPG<br />
																- PNG with transparent background recommended<br />
																- Recommended logo size:<strong> 1050 pixels X 750 pixels</strong><br />
																- Smaller logos may appear pixelated in print products </p>}
													</Grid>
												</Grid>
												<div className='image-link-btn' style={{ textAlign: 'center' }}>
													{values.logo && <>
														<div className='box-head' style={{ border: 'none' }}> <h4 style={{ fontSize: '18px' }}>Uploaded Logo Size: {uploadImageWidth} pixels X {uploadImageHeight} pixels</h4> </div>
														<div style={{ display: 'flex', alignItems: 'center' }}>
															<img className="logo-icon-location" src={checkmark} alt="icon" />
															<p>Your uploaded logo size is optimized for digital (web)</p>
														</div>
														<div style={{ display: 'flex', alignItems: 'center' }}>

															<img className="logo-icon-location" src={uploadImageWidth > 1049 && uploadImageHeight > 749 ? checkmark : fail} alt="icon" />
															<div>
																<p>Your uploaded logo size is {uploadImageWidth > 1049 && uploadImageHeight > 749 ? "" : "NOT"} optimized for print.</p>
																{uploadImageWidth > 1049 && uploadImageHeight > 749 ? <></> : <p style={{ fontSize: '11px', marginBottom: '3%' }}>(Please upload a larger file if you wish to utilize the print products.)</p>}
															</div>
														</div>
													</>}
													<div className='button-field file-upload-btn'>
														<input type='file' id='upload-btn' name='files'
															onChange={(e) => {
																const file = e.target.files[0];
																if (file && (file.type.includes("png") || file.type.includes("jpeg") || file.type.includes("jpg"))) {
																	let file1 = e.target.files[0]
																	file1['preview'] = URL.createObjectURL(e.target.files[0]);

																	let img = new Image()
																	img.src = window.URL.createObjectURL(file)
																	img.onload = () => {
																		setFieldValue("logo", window.URL.createObjectURL(
																			new Blob([file], { type: file.type })))
																		setuploadImageHeight(img.height)
																		setuploadImageWidth(img.width)

																		const image2base64 = require('image-to-base64');
																		image2base64(file1.preview).then((response) => {
																			wsUploadPracticeLogo(props.practiceId, uuid() + "." + file.type.split('/')[1], response, true)
																		}).catch((error) => { })
																		return true;
																	}
																}
															}}
														/>
														<label htmlFor='upload-btn'>{AppConstants.ButtonText.Upload}</label>
													</div>
													<div className='button-field'>
														<input type="button" value={AppConstants.ButtonText.Delete} name="delete" onClick={() => setFieldValue("logo", "")} />
													</div>
												</div>
											</div>
										</div>

										{AppConstants.GetMainClassName() == "app-yomi" &&
											<div className='white-box' style={{ borderTop: '1px solid #d8dbe0' }}>
												<div className='box-head'> <h4>Doctor Photo</h4> </div>
												<div className='box-body'>
													<Grid container direction={'row'} style={{ alignSelf: 'center', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
														<Grid container direction={'row'} style={{ alignSelf: 'center', height: '200px', width: '400px', border: '2px solid #f5f7fa', justifyContent: 'center', alignItems: 'center', margin: '20px' }}>
															{values.doctorphotourl ? <AspectRatio ratio="3/4" style={{ maxWidth: '400px' }} >
																<img style={{ maxHeight: '200px' }} src={values.doctorphotourl} alt='preview-imag' />
															</AspectRatio>
																: <p style={{ textAlign: 'center' }}> Max image size: 250X250 px. Accepted files : .jpg, .jpeg, .png, .svg </p>}
														</Grid>
													</Grid>

													<div className='image-link-btn' style={{ textAlign: 'center' }}>
														<div className='button-field file-upload-btn'>
															<input type='file' id='upload-btn1' name='files'
																onChange={(e) => {
																	const file = e.target.files[0];
																	if (file && (file.type.includes("svg") || file.type.includes("png") || file.type.includes("jpeg") || file.type.includes("jpg"))) {

																		let file1 = e.target.files[0]
																		file1['preview'] = URL.createObjectURL(e.target.files[0]);

																		let img = new Image()
																		img.src = window.URL.createObjectURL(file)
																		img.onload = () => {
																			setFieldValue("doctorphotourl", window.URL.createObjectURL(new Blob([file], { type: file.type })))

																			const image2base64 = require('image-to-base64');
																			image2base64(file1.preview)
																				.then((response) => {
																					wsUploadPracticeLogo(props.practiceId, uuid() + "." + file.type.split('/')[1], response, false)
																				}).catch((error) => { })
																			return true;
																		}
																	}
																}}

															/>
															<label htmlFor='upload-btn1'>{AppConstants.ButtonText.Upload}</label>
														</div>
														<div className='button-field'>
															<button onClick={() => { setFieldValue("doctorphotourl", ""); }} type='button'>{AppConstants.ButtonText.Delete}</button>
														</div>
													</div>
												</div>
											</div>}

										{AppConstants.GetMainClassName() == "app-main" && props.budgetDetail[0].budget != "1" &&
											<div className='white-box'>
												<div className='box-head'>
													<h4>Social Media Links <i>(Not Required)</i></h4>
												</div>
												<div className='box-body'>
													<div className='field-full'>
														<Field as={TextField}
															label='Facebook Link'
															type='text'
															name='socialMedia.facebookUrl'
															placeholder='Facebook Link'
															value={values.socialMedia.facebookUrl}
														/>
													</div>
													<div className='field-full'>
														<Field as={TextField}
															label='Instagram Link'
															type='text'
															name='socialMedia.instagramUrl'
															placeholder='Instagram Link'
															value={values.socialMedia.instagramUrl}
														/>
													</div>
												</div>
											</div>}

										{/* AppConstants.GetMainClassName() == "app-main" && */}
										{AppConstants.GetMainClassName() == "app-main1" && props.budgetDetail[0].budget != "1" &&
											<div className='white-box'>
												<div className='box-head'>
													<h4>Hours Of Operation</h4>
												</div>
												<div className='box-body'>
													<div className='hours-table'>
														<div className='table-head'>
															<div className='head-col table-width-1'>
																<h5>Day</h5>
															</div>
															<div className='head-col table-width-2'>
																<h5>Open Time</h5>
															</div>
															<div className='head-col table-width-3'>
																<h5>Close Time</h5>
															</div>
															<div className='head-col table-width-4'>
																<h5>Closed</h5>
															</div>
														</div>
														<div className='table-body'>
															{days.map((obj, index) => {
																return (
																	<div className='table-body-row' key={index}>
																		<div className='table-data table-width-1'>
																			<h5>{obj}</h5>
																		</div>
																		<div className='table-data table-width-2'>
																			<TextFieldOld
																				id='time'
																				label=''
																				type='time'
																				InputLabelProps={{ shrink: true, }}
																				value={!values.hoursOfOperation[obj].closed ? values.hoursOfOperation[obj].open.split(' ')[0] : ""}
																				onChange={(e) => { !values.hoursOfOperation[obj].closed && setFieldValue(`hoursOfOperation.${obj}.open`, e.target.value); }}
																			/>
																		</div>
																		<div className='table-data table-width-3'>
																			<TextFieldOld
																				id='time'
																				label=''
																				type='time'
																				InputLabelProps={{ shrink: true, }}
																				value={!values.hoursOfOperation[obj].closed ? values.hoursOfOperation[obj].close.split(' ')[0] : ""}
																				onChange={(e) => { !values.hoursOfOperation[obj].closed && setFieldValue(`hoursOfOperation.${obj}.close`, e.target.value); }}
																			/>
																		</div>
																		<div className='table-data table-width-4'>
																			<div className='custom-checkbox'>
																				<Field
																					type='checkbox'
																					name=''
																					id={obj}
																					checked={!!values.hoursOfOperation[obj].closed}
																					onChange={(e) => {
																						if (e.target.checked) {
																							setFieldValue(`hoursOfOperation.${obj}.closed`, true);
																							setFieldValue(`hoursOfOperation.${obj}.open`, "");
																							setFieldValue(`hoursOfOperation.${obj}.close`, "");
																						} else {
																							setFieldValue(`hoursOfOperation.${obj}.closed`, false);
																							setFieldValue(`hoursOfOperation.${obj}.open`, "09:00");
																							setFieldValue(`hoursOfOperation.${obj}.close`, "17:00");
																						}
																					}}
																				/>
																				<label htmlFor={obj}></label>
																			</div>
																		</div>
																	</div>
																);
															})}
														</div>
													</div>
												</div>
											</div>}
									</div>
								</div>

								{AppConstants.GetMainClassName() == "app-main" && props.budgetDetail[0].budget != "1" &&
									<div className='info-col-full'>
										<div className='white-box'>
											<Grid container className='box-head' flexDirection='row' justify='space-between' alignItems='center'>
												<div >
													<h4>Preferred Campaign Reach</h4>
												</div>
												<Grid container flexDirection='row' justify='center' alignItems='center' style={{ width: "25%" }}
													onClick={() => setExpandedMap(expandedMap ? false : true)}>
													<img src={google_maps} alt='icon' style={{ height: '30px', width: "30px" }} />
													<h4 style={{ color: '#646464', margin: '5px', cursor: 'pointer' }}>{expandedMap ? "Hide Map" : "Show Map"}</h4>
													<img src={down_arrow} alt='icon' style={{ height: '10px', width: "10px", cursor: 'pointer' }} />
												</Grid>
											</Grid>
											<div className='box-body'>

												{expandedMap &&
													<div>
														{mapProgress ?
															<Grid container flexDirection='row' justify='center' alignItems='center'
																style={{ width: '100%' }}>
																<CircularProgress style={{ color: '#0a5c7f' }} />
															</Grid> : DataNotFound != '' ? <Grid container direction='row' style={{ justifyContent: 'center', alignItems: 'center', marginTop: '5%' }}>
																<h5>{DataNotFound}</h5>
															</Grid> :
																<div className='map-sec'>
																	<div className='map-sec-left' style={{ height: '500px' }}>
																		{/* <PerfectScrollbar>
																			{selectedAddress != undefined &&
																				!!selectedAddress.length &&
																				selectedAddress.map((obj, index) => {
																					return (
																						<div className='custom-checkbox' key={index}>
																							<input
																								type='checkbox'
																								name='map-location'
																								id={`map_loca_${index}`}
																								checked={obj.IsSelected}
																								onChange={(e) => {
																									setselectedAddress(selectedAddress.map((item, idx) => idx !== index ? item : { ...item, IsSelected: e.target.checked }))
																									setfinallatlng(finallatlng.map((item, idx) => idx !== index ? item : { ...item, selected: e.target.checked }))
																								}}
																							/>
																							<label htmlFor={`map_loca_${index}`}>
																								{obj.name}
																							</label>
																						</div>
																					);
																				})
																			}
																		</PerfectScrollbar> */}

																		<Field as={TextField}
																			label='Zip Code'
																			type='text'
																			maxLength='6'
																			placeholder='Zip Code'
																			name='campaignReach.zipCode'
																			value={values.campaignReach.zipCode}
																			onChange={(e) => {
																				setFieldValue("campaignReach.zipCode", e.target.value)
																				setpincode(e.target.value)
																			}}
																		/>
																		<Dropdown
																			style={{ margin: '7% 0' }}
																			label='Select Radius'
																			id={'radius'}
																			name='radius'
																			placeholder='Select Radius'
																			defaultValue={values.campaignReach.radius}
																			value={values.campaignReach.radius}
																			onChange={(e) => {
																				setFieldValue("campaignReach.radius", e.target.value);
																				setmiles(e.target.value)
																				wsGetMappingData(values.campaignReach.zipCode, e.target.value)
																			}}
																			select>
																			<MenuItem classes='' value={'5'}> {'5 miles'} </MenuItem>
																			<MenuItem classes='' value={'10'}> {'10 miles'} </MenuItem>
																			<MenuItem classes='' value={'15'}> {'15 miles'} </MenuItem>
																			<MenuItem classes='' value={'20'}> {'20 miles'} </MenuItem>
																			<MenuItem classes='' value={'25'}> {'25 miles'} </MenuItem>
																		</Dropdown>

																		<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
																			<div className={expandedMap && pincode.length == 5 || pincode.length == 6 ? "button-field file-upload-btn" : "button-field file-upload-btn disable-btn"}>
																				<input type='button' name='Update' value='Update' onClick={() => wsGetMappingData(values.campaignReach.zipCode, miles)} />
																			</div>
																		</div>

																	</div>
																	<div className='map-sec-right' style={{ height: '500px' }}>
																		{currentCitylat != '' && currentCitylng != '' && currentCitylat != undefined && currentCitylng != undefined ?
																			<MyMapComponent
																				isMarkerShown
																				currentZipCode={values.campaignReach.zipCode}
																				locationDataSet={finallatlng}
																				locationRadius={miles * 1000}
																				currentCityCord={{ lat: parseFloat(currentCitylat), lng: parseFloat(currentCitylng) }}
																			/> : <Grid container flexDirection='row' justify='center' alignItems='center'
																				style={{ width: '100%', height: '100%`' }}>
																				<CircularProgress style={{ color: '#0a5c7f' }} />
																			</Grid>}
																	</div>
																</div>
														}
													</div>
												}
											</div>
										</div>
									</div>
								}
								<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<div className={errors.businessInfo == undefined && isPostcodeSelected ? `button-field confirm-update` : `disable-btn button-field confirm-update`}>
										<input type='submit' name='submit' value={AppConstants.ButtonText.ConfirmUpdates}
											onClick={() => {
												handleSubmit();
												AppConstants.GetMainClassName() == "app-main" && handleValidateScroll(values)
											}}
										/>
									</div>
								</div>
							</form>
						);
					}}
				</Formik>
			</div>
	);
};

export default LocationValidationComponent;