/*eslint-disable eqeqeq*/
import React from "react";
import ContestSummaryReportList from "./contestSummaryReportList";
import * as APIController from "../../../../../api/APIController.js";
import Notification from "../../../../../shared/components/notification/notification";
import CsvDownload from 'react-json-to-csv'
import * as AppConstants from "../../../../../config/AppConstant";
import TextField from "../../../../../shared/components/TextField/TextField";
import PageTitle from "../../../../../config/pageTitle";
import moment from 'moment';

class ContestSummaryReport extends React.Component {
    state = {
        currentPage: 1,
        count: 0,
        rowsPerPage: 1000000,
        rowsPerPageOptions: ["All", 50, 100, 250, 500, 1000],
        list: [],
        list1: [],
        downloadlist: [],
        from: 0,
        to: 1000000,
        progress: true,
        message: "",
        showNotification: false,
        isError: false,
        showMessage:
            this.props.history.location.state != null &&
            this.props.history.location.state.showMessage,
        showOrderList: false,
        downloadDate: moment(new Date().toUTCString()).utcOffset(0, false).format('MMDDYY'),
        FromDate: moment(new Date().toUTCString()).utcOffset(0, false).format('YYYY-MM-DD'),
        ToDate: moment(new Date().toUTCString()).utcOffset(0, false).format('YYYY-MM-DD'),
    };

    componentDidMount() {
        this.wsAssetSummaryReport();
    }

    wsAssetSummaryReport(FromDate, ToDate) {
        this.setState({ progress: true, });
        APIController.AssetSummaryReport(FromDate, ToDate).then((response) => {
            if (response.error == null) {
                let downloaddata = []
                response.object.data.map((item) =>
                    downloaddata.push({
                        "Area": item.Area, "Region #": item.Region, "VTM #": item.Territory, "VTM Name": item.VTM, "New Registrations plus 1+ download (10 Points)": item.NewRegistrations.toString(),
                        "Prior Registrations plus 1+ download (10 Points)": item.PriorRegistrations.toString(), "Additional Asset Download (1 Points)": item.AssetDownload.toString(),
                        "Social Automation (25 Points)": item.SocialAutomation.toString(), "Campaign Order (25 Points)": item.CampaignOrder.toString(),
                        "Email Marketing (10 Points)": item.EmailMarketing.toString(), "Total Points": item.Total.toString()
                    })
                )
                this.setState({
                    list: response.object.data,
                    list1: response.object.data,
                    count: response.object.data.length,
                    downloadlist: downloaddata,
                    progress: false,
                });

            } else if (response.error.message && response.error.message.length > 0) {
                this.handleNotification(response.error.message, true);
            }
        });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        return (
            <>
                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    clearNotification={this.handleClearNotification}
                    closeNotification={this.handleClearNotification}
                />
                <section className='content-right-main'>
                    <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                        <h1><PageTitle Title={AppConstants.ContactDetais.ContestSummaryReport} /></h1>
                        <div style={{ display: 'flex', alignItems: 'baseline' }}>
                            <TextField style={{ margin: '10px' }} label='From Date' type="date" name="FromDate" value={this.state.FromDate} onChange={this.handleChange} />

                            <TextField label='To Date' type="date" name="ToDate" value={this.state.ToDate} onChange={this.handleChange} />

                            <div className='button-field button-field-country' style={{ height: '40px', minWidth: '100px', textAlign: 'center' }}>
                                <button onClick={() => { this.wsAssetSummaryReport(this.state.FromDate, this.state.ToDate); }}> Search </button>
                            </div>

                            <div className='button-field' style={{ height: '40px', width: '240px', textAlign: 'center' }}>
                                <CsvDownload data={this.state.downloadlist} filename={"REACH Contest Summary Download Report " + this.state.downloadDate + ".csv"}>Download to CSV</CsvDownload>
                            </div>
                        </div>
                    </div>

                    <ContestSummaryReportList
                        {...this.state}
                        history={this.props.history}
                        handleChangeRowsPerPage={this.handleRowsPerPageChange}
                        handleChangePage={this.handlePageChange}
                        applyFilter={this.handleApplyFilter}
                        handleNotification={this.handleNotification}
                        handleShowList={this.handleShowList}
                    />
                    {/*  */}
                </section>
            </>
        );
    }

    handleShowList = (value) => {
        this.setState({ showOrderList: value })
    }

    /**
     * handleRowsPerPageChange method is used to update rows per page
     * it will reset page to 1
     */

    handleRowsPerPageChange = (value) => {
        let from = 0;
        let to = value;
        this.setState({ rowsPerPage: value, currentPage: 1, to: to, from: from });
    };

    /**
     * handlePageChange method is used to change page
     */

    handlePageChange = (values) => {
        let from =
            values === 1
                ? 0 * this.state.rowsPerPage
                : (values - 1) * this.state.rowsPerPage;
        let to =
            values == 1
                ? this.state.rowsPerPage
                : parseInt(from) + parseInt(this.state.rowsPerPage);
        this.setState({ currentPage: Number(values), to: to, from: from });
    };

    /**
     * handleApplyFilter method is used to apply filter on every columns
     */
    handleApplyFilter = (tag, values, type) => {
        if (values !== "") {
            let searchCountrys = [];
            if (type === "select") {
                searchCountrys = this.state.list1.filter(
                    (us) => us[tag].toLowerCase() === values.toLowerCase()
                );
            } else {
                searchCountrys = this.state.list.filter(
                    (us) => us[tag].toString().toLowerCase().indexOf(values.toLowerCase()) > -1
                );
            }
            this.setState({
                list: searchCountrys,
                currentPage: 1,
                count: searchCountrys.length,
                rowsPerPage: 1000000,
                rowsPerPageOptions: ["All", 50, 100, 250, 500, 1000],
                from: 0,
                to: 1000000,
                progress: false,
            });
        } else {
            this.wsAssetSummaryReport();
            this.setState({
                currentPage: 1,
                rowsPerPage: 1000000,
                rowsPerPageOptions: ["All", 50, 100, 250, 500, 1000],
                from: 0,
                to: 1000000,
            });
        }
    };

    handleNotification = (message, isError) => {
        this.setState({
            message: message,
            showNotification: true,
            isError: isError,
        });

        setTimeout(() => {
            this.handleClearNotification();
        }, 4000);
    };

    handleClearNotification = () => {
        this.setState({
            message: "",
            showNotification: false,
            isError: false,
        });
    };
}

export default ContestSummaryReport;
