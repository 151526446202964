/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'
// import './stateForm.css'
import * as APIController from '../../../../api/APIController.js';
import * as yup from 'yup';
// vive 1909
import Notification from '../../../../shared/components/notification/notification';
import CircularProgress from '@material-ui/core/CircularProgress';

import MenuItem from '@material-ui/core/MenuItem';
import Dropdown from '../../../../shared/components/Dropdown/Dropdown';
import TextField from '../../../../shared/components/TextField/TextField';

const StateForm = props => {
    const initialValues = { country: '', countryName: props.history.location.state.countryIdEdit, statename: props.history.location.state.stateNameEdit, active: props.history.location.state.stateActiveEdit }
    const [isButtonValue, setButtonValue] = React.useState(false);

    const [dataSource, setdataSource] = React.useState([]);
    const [Loading, setLoading] = React.useState(true)
    const [IsLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        props.history.location.state.stateIdEdit != 0 ? wsGetStateByID(props.history.location.state.stateIdEdit)
            : wsGetCountryForDropdown();
        // if (Loading == false) { document.getElementById('country').focus() }
        // initialValues.country.focus();
    }, [props])

    const wsGetStateByID = (ID) => {
        APIController.GetStateByID(ID)
            .then((response) => {
                setLoading(false)
                if (response.error == null) {
                    initialValues.country = response.object.data[0].CountryID
                    setdataSource(JSON.parse(response.object.data[0].Countries));
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message);
                }
            })
    }

    const wsGetCountryForDropdown = (ID) => {
        APIController.GetCountryForDropdown(ID)
            .then((response) => {
                setLoading(false)
                if (response.error == null) {
                    setdataSource(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    // ToastsStore.warning(response.error.message)
                }
            })
    }

    const wsAddEditState = (ID, CountryID, Name, IsActive) => {
        setIsLoading(true)
        APIController.AddEditState(ID, CountryID, Name, IsActive)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    if (isButtonValue === true && response.object.status == 1) {
                        props.history.push({ pathname: '/state', state: { showMessage: response.object.message } })
                    } else if (response.object.status == 1) {
                        handleNotification(response.object.message);
                    } else {
                        handleNotification(response.object.message, true);
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const [message, setMessage] = React.useState("")
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }
    return (
        <>
            <section className="content-right-main">
                {props.history.location.state.stateIdEdit == 0 ? <h1>Add State</h1> : <h1>Edit State</h1>}
                <Formik
                    initialValues={initialValues}
                    validationSchema={validateStateForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        wsAddEditState(props.history.location.state.stateIdEdit, values.country, values.statename, values.active)
                        if (isButtonValue === false) {
                            values.statename = "";
                            values.active = true;
                        }
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        setFieldValue,
                        errors,
                        touched,
                    }) => {
                        return (
                            <div className="form-main">
                                <form onSubmit={handleSubmit}>
                                    {/* <div className="form_field"> */}
                                    <div
                                        className={`form_field ${errors &&
                                            touched.country &&
                                            errors.country &&
                                            errors.country !== ''
                                            ? 'error'
                                            : ''
                                            }`}>

                                        <div className="field-right" >

                                            <Dropdown
                                                label='Select Country'
                                                isError={errors &&
                                                    touched.country &&
                                                    errors.country &&
                                                    errors.country !== '' ? true : false}
                                                id={'country'}
                                                name='country'
                                                placeholder='Select Country'
                                                defaultValue={values.country}
                                                value={values.country}
                                                onChange={(e) => {
                                                    setFieldValue('country', e.target.value);
                                                }}
                                                select>
                                                <MenuItem classes='' value={''}>
                                                    {'Select Country'}
                                                </MenuItem>
                                                {
                                                    dataSource.map((obj, index) => {
                                                        return <MenuItem classes='' selected={obj.ID == values.country ? obj.ID : obj.Name} value={obj.ID} key={index}>{obj.Name}</MenuItem>
                                                    })
                                                }
                                            </Dropdown>

                                            {errors &&
                                                touched.country &&
                                                errors.country &&
                                                errors.country !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='country'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}

                                        </div>
                                    </div>



                                    <div
                                        className={`form_field ${errors &&
                                            touched.statename &&
                                            errors.statename &&
                                            errors.statename !== ''
                                            ? 'error'
                                            : ''
                                            }`}>

                                        <div className="field-right">
                                            <Field
                                                type="text"
                                                id={'statename'}
                                                as={TextField}
                                                error={touched.statename &&
                                                    errors.statename &&
                                                    errors.statename !== '' ? true : false}
                                                {...props}
                                                label='Enter State Name'
                                                placeholder="Enter State"
                                                name="statename"
                                                value={values.statename} maxLength="50" />
                                            {errors &&
                                                touched.statename &&
                                                errors.statename &&
                                                errors.statename !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='statename'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>

                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Is Active ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="yes-text"
                                                    defaultValue={values.active}
                                                    defaultChecked={values.active != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 1)} />
                                                <label htmlFor="yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="no-text"
                                                    defaultValue={values.active}
                                                    defaultChecked={values.active == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 0)} />
                                                <label htmlFor="no-text">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <Notification
                                        isError={isError}
                                        message={message}
                                        showNotification={showNotification}
                                        clearNotification={handleClearNotification.bind(this)}
                                        closeNotification={handleClearNotification.bind(this)}
                                    />
                                    <div className="form-submit">
                                        <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" onClick={() => setButtonValue(true)} />
                                        </div>
                                        {props.history.location.state.stateIdEdit == 0 ?
                                            <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                                <input type="submit" value={IsLoading ? "Saving..." : "save & add new"} name="save & add new" onClick={() => setButtonValue(false)} />
                                            </div> : null}
                                        <div className="button-field">
                                            <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )
                    }}
                </Formik>
            </section>
        </>
    )
}
const validateStateForm = yup.object().shape({
    country: yup.string().required('Country is required'),
    statename: yup.string().required('State is required'),
});
export default StateForm;