import React from 'react';
import * as APIController from '../../../../api/APIController';
import { ExcelRenderer } from 'react-excel-renderer';
import Notification from "../../../../shared/components/notification/notification";

class UploadExcelSheet extends React.Component {

    state = {
        PracticeDataList: [],
        UserDataList: [],
        IsLoading: false,
        message: "",
        showNotification: false,
        isError: false,
    }

    handelPracticeCSV = (event) => {
        this.setState({ IsLoading: true })
        let fileObj = event.target.files[0];
        var obj = {};
        var mainobj = []
        ExcelRenderer(fileObj, (err, resp) => {
            for (var i = 1; i < resp.rows.length; i++) {
                for (var j = 0; j < resp.cols.length; j++) {
                    obj[resp.rows[0][j].replaceAll(' ', '').replace('/', '_')] = resp.rows[i][j];
                }
                mainobj.push({
                    "NetSuiteID": obj.NetsuiteID, "CompanyName": obj.Companyname, "Speciality": obj.PracticeSpecialty, "StreetAddress1": obj.StreetAddress,
                    "StreetAddress2": obj.StreetAddress2, "City": obj.City, "State": obj.State_Region, "PostalCode": obj.PostalCode,
                    "PhoneNumber": obj.PhoneNumber, "Region": obj.Country_Region, "CSM_Name": obj.CSM, "CSM_EmailAddress": obj.CSMEmailAddress,
                    "CSM_PhoneNumber": obj.CSMPhoneNumber, "CSR_Name": obj.PrimaryCSR, "CSR_EmailAddress": obj.CSREmailAddress,
                    "CSR_PhoneNumber": obj.CSRPhoneNumber
                });
                obj = {};
            }
            this.setState({ PracticeDataList: mainobj })
            this.setState({ IsLoading: false })
        })
    }

    handelUserCSV = (event) => {
        this.setState({ IsLoading: true })
        let fileObj = event.target.files[0];
        var obj = {};
        var mainobj = []
        ExcelRenderer(fileObj, (err, resp) => {
            for (var i = 1; i < resp.rows.length; i++) {
                for (var j = 0; j < resp.cols.length; j++) {
                    obj[resp.rows[0][j].replaceAll(' ', '').replace('/', '_')] = resp.rows[i][j];
                }
                mainobj.push({
                    "EmailAddress": obj.UserEmail, "PracticeName": obj.PracticeName, "Password": obj.YOMIPassword, "UserName": obj.UserLogin,
                    "City": obj.City, "State": obj.State_Region, "PostalCode": obj.PostalCode, "CSR_Name": obj.PrimaryCSR, "NetsuiteID": obj.Netsuite,
                    "Region": obj.Country_Region, "FirstName": obj.UserFirstName, "LastName": obj.UserLastName
                });
                obj = {};
            }
            this.setState({ UserDataList: mainobj })
            this.setState({ IsLoading: false })
        })
    }

    wsImportPracticeAndUsers(PracticeData, UserData) {
        this.setState({ IsLoading: true })
        APIController.ImportPracticeAndUsers(PracticeData, UserData)
            .then((response) => {
                if (response.object.status == 1) {
                    this.handleNotification(response.object.message);
                } else {
                    this.handleNotification(response.error.message, true);
                }
                this.setState({ IsLoading: false })
            })
    }

    render() {
        return (
            <section className="content-right-main">
                <h1>Import Practices & Users</h1>
                <div style={{ display: 'grid' }}>

                    <div className="field-left">
                        <label>Import Practices</label>
                    </div>
                    <div className="field-right">
                        <div className='button-field' style={{ display: 'flex', float: 'left' }}>
                            <input type="file" accept=".xlsx,.xls,.csv" onChange={(e) => this.handelPracticeCSV(e)} style={{ "padding": "15px", }} />
                        </div>
                    </div>
                    <div className="field-left">
                        <label>Import Users</label>
                    </div>
                    <div className="field-right">
                        <div className='button-field' style={{ display: 'flex', float: 'left' }}>
                            <input type="file" accept=".xlsx,.xls,.csv" onChange={(e) => this.handelUserCSV(e)} style={{ "padding": "15px", }} />
                        </div>
                    </div>

                    <div className="field-left">
                        <div className={this.state.IsLoading ? `disable-btn button-field` : `button-field`}>
                            <button onClick={() => { this.wsImportPracticeAndUsers(this.state.PracticeDataList, this.state.UserDataList) }}> + Save Practices & Users </button>
                        </div>
                    </div>
                </div>
                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    clearNotification={this.handleClearNotification}
                    closeNotification={this.handleClearNotification}
                />
            </section>
        )
    }

    handleNotification = (message, isError) => {
        this.setState({
            message: message,
            showNotification: true,
            isError: isError,
        });

        setTimeout(() => {
            this.handleClearNotification();
        }, 4000);
    };

    handleClearNotification = () => {
        this.setState({
            message: "",
            showNotification: false,
            isError: false,
        });
    };
}

export default UploadExcelSheet;