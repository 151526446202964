/*eslint-disable eqeqeq*/
import React from "react";
import { NavLink } from "react-router-dom";
// import { jwt_decode } from 'jwt-decode';
import { Formik, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import Modal from "../../../shared/components/modal/modal";
import Slider from "../../../shared/components/slider/slick-slider";
import authService from "../../../shared/services/auth-service";
import VerifyOtp from "../component/verify-otp";
import ForgotPassword from "../component/forgot-password";
import "../component/login.css";
import { API, HTTPMethod } from "../../../api/API";
import { URLs } from "../../../api/URLs";
import * as APIController from '../../../api/APIController.js';
import * as AppConstants from "../../../config/AppConstant";
import TextField from "../../../shared/components/TextField/TextField";
import Notification from "../../../shared/components/notification/notification";
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";
import footerlogo from '../../../assets/images/footerlogo.svg'

class LoginContainer extends React.Component {
	state = {
		isModalOpen: false,
		tag: "",
		username: "",
		password: "",
		IsLoading: false,
		RememberMe: false,
		SliderImages: [],
		loading: true
	};

	componentDidMount() {
		this.wsGetScreenBannerForDropdown();
	}
	wsGetScreenBannerForDropdown() {
		APIController.GetScreenBannerForDropdown("1")
			.then((response) => {
				if (response.object.data != "") {
					this.setState({ SliderImages: response.object.data, loading: false })
				}
			})
	}

	render() {
		return (
			<>
				<section className='login_page'>
					<div className='main'>
						<div className='inner-screen'>
							<div className="login-header-gradient-line"></div>
							<div className='logo login-logo-new'>
								<img src={AppConstants.LoginLogo} alt={AppConstants.LoginLogo} />
							</div>
							<div className="login-page-background">
								<div className="login-header-text">
									<h2 className="login-text-h2">{AppConstants.BLLoginSlider.HeadingText}</h2>
									<h5 className="login-text-h5">{AppConstants.BLLoginSlider.PtagText}</h5>
								</div>

								<div className="login-from" >
									<h3 className="login-text-h3">Login</h3>
									<Formik
										initialValues={{ username: "", password: "" }}
										validationSchema={validateLoginForm}
										validateOnMount={false}
										onSubmit={(values) => {
											if (!values.username.trim().match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
												this.handleShowNotification("Please enter valid Email Address.", true);
											} else if (values.username.length > 100) {
												this.handleShowNotification("Email Address length should be 100 only", true);
											} else if (values.password.length > 50) {
												this.handleShowNotification("Password length should be 50 only", true);
											} else {
												this.handleLogin(values)
											}
										}}
									// onSubmit={(values) => this.handleLogin(values)}
									>
										{({ values, handleSubmit, errors, touched }) => (
											<form onSubmit={handleSubmit}>
												<div className={`form-field ${errors && touched.username && errors.username && errors.username !== "" ? "error" : ""}`}>
													{/* <label>Email Address</label> */}
													<div className='field_main'>
														<Field as={TextField}
															error={touched.username && errors.username && errors.username !== "" ? true : false}
															{...this.props}
															label='Email Address'
															placeholder='name@domain.com'
															name='username'
															size="small"
															className='user-field'
														/>
														{errors && touched.username && errors.username && errors.username !== ""
															&& (<span className='error-msg'>
																<ErrorMessage name='username' render={(err) => { return err; }} />
															</span>)}
													</div>
												</div>
												<div className={`form-field ${errors && touched.password && errors.password && errors.password !== "" ? "error" : ""}`}>
													{/* <label>Password</label> */}
													<div className='field_main'>
														<Field
															as={TextField}
															{...this.props}
															type='password'
															error={touched.password && errors.password && errors.password !== "" ? true : false}
															label={'Password'}
															placeholder='******'
															name='password'
															size="small"
															className='pwd-field'
														/>
														{errors && touched.password && errors.password && errors.password !== ""
															&& (<span className='error-msg'>
																<ErrorMessage name='password' render={(err) => { return err; }} />
															</span>)}
													</div>
												</div>
												<div className='form-field custom-checkbox'>
													<input type='checkbox' name='' id='checkme' value={this.state.RememberMe} onChange={(e) => this.setState({ RememberMe: e.target.checked })} />
													<label htmlFor='checkme'> Remember me </label>
												</div>
												<div className="form-field">
													<div className={this.state.IsLoading ? 'button-field disable-btn' : 'button-field'} >
														<input type='submit' value={this.state.IsLoading ? "Logging In..." : "Login"} name='sign-in' />
													</div>

													{AppConstants.GetMainClassName() == "app-main" &&
														<div className='button-field'>
															<input type='button' value='Register' name='Register' onClick={() => { window.open("/clinic-registrationTemp"); }} />
														</div>}
												</div>
											</form>
										)}
									</Formik>

									{AppConstants.GetMainClassName() == "app-main" &&
										<div className='bottom-links' style={{ paddingBottom: "15%" }}>
											<button onClick={() => this.handleOpenModal("password")}>Forgot Password?</button>
										</div>}
								</div>

							</div>

							<div className="login-footer-gradient-line">
								<div style={{ width: "45%", marginLeft: '13%', display: 'flex', alignItems: 'center' }} >
									<p className="login-footer-text">@{new Date().getFullYear()} Bausch+Lomb.VCCM.0033.USA.23</p>
									<p style={{ marginLeft: '3%', cursor: 'pointer' }} className="login-footer-text" onClick={() => window.open("/privacypolicy")}>{AppConstants.FooterText.LinkText2}</p>
								</div>
								<div style={{ width: '250px' }} >
									<img src={footerlogo} alt='' width="70%" />
								</div>
							</div>
						</div>
					</div>

				</section >
				<Notification
					isError={this.state.isError}
					message={this.state.message}
					showNotification={this.state.showNotification}
					MessageLocationLogin={"LoginToast"}
					closeNotification={this.handleClearNotification}
				/>
				{
					this.state.isModalOpen && (
						<Modal
							show={this.state.isModalOpen}
							handleClose={this.handleCloseModal}>
							{this.state.tag === "username" ? (
								<VerifyOtp
									{...this.props}
									closeModal={this.handleCloseModal}
									handleNotification={this.handleShowNotification}
								/>
							) : this.state.tag !== "" ? (
								<ForgotPassword
									{...this.props}
									closeModal={this.handleCloseModal}
									// sendUserName={this.handleSendUserName}
									handleNotification={this.handleShowNotification}
									UserID={0}
								/>
							) : null}
						</Modal>
					)
				}
			</>
		);
	}

	handleInputChange = (name, value) => {
		this.setState({ [name]: value });
	};

	handleLogin = (value) => {
		this.setState({ IsLoading: true });
		if (value.password.length <= 5) {
			this.setState({ IsLoading: false })
			this.handleShowNotification("Password should be minimum 6 characters long", true);
		} else {
			this.wsGetAccessToken(value);
		}
	};

	wsGetAccessToken(value) {
		var details = {
			username: value.username,
			password: value.password,
			grant_type: "password",
		};

		var formBody = [];
		for (var property in details) {
			var encodedKey = encodeURIComponent(property);
			var encodedValue = encodeURIComponent(details[property]);
			formBody.push(encodedKey + "=" + encodedValue);
		}
		formBody = formBody.join("&");

		API.shared.request(HTTPMethod.post, URLs.base + URLs.Gettoken, formBody)
			.then((response) => {

				if (response && response.StatusCode === 401) {
					this.handleShowNotification(response.StatusCode.toString() + " " + response.Message, true);
				} else if (response.error == undefined) {
					sessionStorage.setItem("Token", JSON.stringify(response));
					sessionStorage.setItem("Access_Token", response.access_token);

					authService.setAuthData('response');

					AppConstants.SetLocalStorage("sideBarMenuAdmin", 1);
					if (this.state.RememberMe == true) {
						AppConstants.SetLocalStorage("RememberMe", JSON.stringify(response))
					}

					const jwt_decode = require("jwt-decode");
					var decoded = jwt_decode(response.jwt);

					AppConstants.SetLocalStorage("profilelogo", decoded.ImageURL);
					AppConstants.SetLocalStorage("UserName", decoded.FullName);
					AppConstants.SetLocalStorage("ShowSMAuthentication", decoded.ShowSMAuthentication);
					AppConstants.SetLocalStorage("ShowInstaAutomation", decoded.ShowInstaAutomation);
					AppConstants.SetLocalStorage("TotalOrders", decoded.TotalOrders);
					AppConstants.SetLocalStorage("localStorageRoleID", decoded.RoleID);

					if (AppConstants.VTMRoleID == decoded.RoleID) {
						AppConstants.SetLocalStorage("localStorageIsVTM", 0);
						AppConstants.SetLocalStorage("localStorageLocationByPass", 1);
					} else {
						AppConstants.SetLocalStorage("localStorageIsVTM", 0);
						AppConstants.SetLocalStorage("localStorageLocationByPass", 0);
					}

					if (decoded.PopupTitle != "") {
						AppConstants.SetLocalStorage("PopupWelcomeShow", 1);
						AppConstants.SetLocalStorage("PopupTitle", decoded.PopupTitle);
						AppConstants.SetLocalStorage("PopupAssetURL", decoded.PopupAssetURL);
						AppConstants.SetLocalStorage("PopupDescription", decoded.PopupDescription);
					}

					if (decoded.IsCoOpEligible != 0) {
						AppConstants.SetLocalStorage("headerMenuCoOp", 1);
						AppConstants.SetLocalStorage("headerMenuCoOpAmount", decoded.CoOpAmount);
					} else {
						AppConstants.SetLocalStorage("headerMenuCoOp", 0);
					}

					if (AppConstants.RoleID == decoded.RoleID) {
						AppConstants.SetLocalStorage("isSocialMediaRequired", 1);
					} else {
						AppConstants.SetLocalStorage("isSocialMediaRequired", 0);
					}
					AppConstants.SetLocalStorage("headerMenuAdmin", 0);

					this.wsGetCart(decoded)

					AppConstants.LogEvent('Login', { 'id': decoded.ID, 'emailaddress': value.username })
				} else {
					this.handleShowNotification(response.error, true);
					this.setState({ IsLoading: false });
				}
			}).catch((error) => {
				this.handleShowNotification(error.toString(), true);
				this.setState({ IsLoading: false });
			});
	}

	wsGetCart(decoded) {
		APIController.GetCart()
			.then((response) => {
				if (response.object.message == "") {
					AppConstants.SetLocalStorage("localStorageCartCount", response.object.data.Products.length + response.object.data.Subscriptions.length)
				} else {
					AppConstants.SetLocalStorage("localStorageCartCount", 0)
				}
				this.wsGetNav(decoded)
			})
	}

	wsGetNav(decoded) {
		APIController.GetNav()
			.then((response) => {
				if (response.error == null) {
					AppConstants.SetLocalStorage('GetNav', JSON.stringify(response));

					response.object.data.AdminNav.AdminNav.length
						? AppConstants.SetLocalStorage("headerMenuAdmin", 1)
						: AppConstants.SetLocalStorage("headerMenuAdmin", 0)

					this.setState({ IsLoading: false });
					//Navigate home Screen When Api Call is Done
					AppConstants.SetLocalStorage('localStorageBrandID', response.object.data.BrandsNav[0].BrandID);
					AppConstants.SetLocalStorage('localStorageBrandName', response.object.data.BrandsNav[0].BrandName);
					AppConstants.SetLocalStorage('localStorageCampaignID', JSON.parse(response.object.data.BrandsNav[0].Campaigns)[0].CampaignID);
					AppConstants.SetLocalStorage('localStorageCampaignName', JSON.parse(response.object.data.BrandsNav[0].Campaigns)[0].CampaignName);
					AppConstants.SetLocalStorage('localStorageSubscriptions', JSON.stringify(response.object.data.Subscriptions));
					if (decoded.SendToOTP == 0) {
						if (AppConstants.GetMainClassName() == "app-main") {
							this.props.history.push("/home");
						} else {
							this.props.history.push("/digital-product/selection");
						}
					} else {
						this.handleOpenModal("username");
					}
				}
			}).catch((err) => { })
	}

	handleOpenModal = (tag) => {
		this.setState({ tag: tag, isModalOpen: true });
	};

	handleSendUserName = (username) => {
		if (username !== "") {
			this.setState({ tag: "", isModalOpen: false });
		}
	};

	handleVerifyOtp = (otp) => { if (otp !== "") { this.setState({ isModalOpen: false, tag: "" }); } };

	handleCloseModal = () => { this.setState({ isModalOpen: false }); };

	handleShowNotification = (message, isError) => {
		this.setState({ message: message, showNotification: true, isError: isError, });

		setTimeout(() => { this.handleClearNotification(); }, 4000);
	};

	handleClearNotification = () => {
		this.setState({ message: "", showNotification: false, isError: false });
	};
}

const validateLoginForm = yup.object().shape({
	username: yup.string().required("Email Address is required"),
	password: yup.string().required("Password is required"),
});

export default LoginContainer;
