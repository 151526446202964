import React from "react";
import Table from "../../../shared/components/table/table";
import TableHead from "../../../shared/components/table/tableHead";
import TableRow from "../../../shared/components/table/tableRow";
import TableBody from "../../../shared/components/table/tableBody";
import TableCell from "../../../shared/components/table/tableCell";
import PerfectScrollbar from "react-perfect-scrollbar";
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";
import { withRouter } from 'react-router-dom';
import "react-perfect-scrollbar/dist/css/styles.css";

const CoOpListComponent = (props) => {
	const list = props.list;
	const headerList = ['Date ', "Transaction ", "Credit", "Debit", "Product", "Remarks"]
	return (
		<div className='table-main-wrap users-class'>
			<div className="table-link">

				<a href="#!" title="Add Co-Op" onClick={(e) => {
					e.preventDefault();
					props.openAddressForm('coop1')
				}}>+ Add Co-Op</a>

				<a href="#!" title="Revoke CoOp" style={{ marginLeft: '20px' }} onClick={(e) => {
					e.preventDefault();
					props.openAddressForm('coop2')
				}}>- Revoke CoOp</a>
			</div>

			<PerfectScrollbar component='div'>

				<Table className='' stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							{headerList.map((obj, index) => {
								return <TableCell className={obj === "Is active ?" ? 'center' : ''} key={index}>{obj}</TableCell>;
							})}
						</TableRow>
						<TableRow style={{ top: 57 }}>
							<TableCell style={{ top: 63, width: '25%' }}>
								<input type='text' name='' placeholder="Date" onChange={(e) => props.applyFilter("TransactionDate", e.target.value, "input")} />
							</TableCell>
							<TableCell style={{ top: 63 }}>
								<input type='text' name='' placeholder="Transaction" onChange={(e) => props.applyFilter(" TransactionType", e.target.value, "input")} />
							</TableCell>
							<TableCell style={{ top: 63 }}>
								<input type='text' name='' placeholder=" Credit" onChange={(e) => props.applyFilter(" CreditAmount", e.target.value, "input")} />
							</TableCell>
							<TableCell style={{ top: 63 }}>
								<input type='text' name='' placeholder="Debit" onChange={(e) => props.applyFilter(" DebitAmount", e.target.value.toString(), "input")} />
							</TableCell>
							<TableCell style={{ top: 63 }}>
								<input type='text' name='' placeholder=" Product" onChange={(e) => props.applyFilter("Product", e.target.value.toString(), "input")} />
							</TableCell>
							<TableCell style={{ top: 63 }}>
								<input type='text' name='' placeholder="Remarks" onChange={(e) => props.applyFilter("Remarks", e.target.value, "input")} />
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{!!list.length ? list.map((obj, idnex) => {
							return (
								<TableRow key={idnex}>

									<TableCell>{obj.TransactionDate}</TableCell>
									<TableCell>{obj.TransactionType}</TableCell>
									<TableCell>{obj.CreditAmount}</TableCell>
									<TableCell>{obj.DebitAmount}</TableCell>
									<TableCell>{obj.Product}</TableCell>
									<TableCell>{obj.Remarks}</TableCell>

								</TableRow>
							);
						}) : (
							<TableRow>
								<TableCell colSpan={headerList.length}>
									<div className="not_found">
										{props.progress ?
											<CircularProgress /> :
											<label>Not Currently Available</label>}
									</div>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>

			</PerfectScrollbar>
		</div>
	);
};

export default withRouter(CoOpListComponent);
