import React from 'react'
import CampaignDetail from '../component/campaignDetail';

class CampaignDetailController extends React.Component {

    state = {
        campaignDetail: {
            approach: 1,
            message: 1
        },
    }

    handleSubmitCampaign = ({approach, message}) => {
        this.setState({
            campaignDetail: {
                approach,
                message
            }
        });
        // this.props.history.push('/digital-product/selection/adbreak')
    }
    render () {
        return (
            <>
            <CampaignDetail history={this.props.history} submitCampaign={this.handleSubmitCampaign} 
            campaignDetail={this.state.campaignDetail} back={() => this.props.history.goBack()}  />
            </>
        )
    }
}

export default CampaignDetailController;