/*eslint-disable eqeqeq*/
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import deleteIcon from "../../../../assets/images/delete.svg";
import editIcon from "../../../../assets/images/edit.svg";
import ModalComponent from '../../../../shared/components/modal/modal';
import ConfirmtionDialog from '../../../ConfirmDialog/ConfirmationDialog';
import UploadFreeAsset from '../../../../features/campaign/components/uploadAsset';
import previewIcon from "../../../../assets/images/blue-preview.svg";
import close_icon from "../../../../assets/images/close_icon.svg";
import '../../../campaign/components/campaign.css';
import { withRouter } from 'react-router-dom';
import * as APIController from '../../../../api/APIController.js';
import Notification from '../../../../shared/components/notification/notification';
import CircularProgress from '@material-ui/core/CircularProgress';
import PerfectScrollbar from "react-perfect-scrollbar";
import Table from "../../../../shared/components/table/table";
import TableHead from "../../../../shared/components/table/tableHead";
import TableRow from "../../../../shared/components/table/tableRow";
import TableCell from "../../../../shared/components/table/tableCell";

const ProductAssetFormTab = props => {
	const [Loading, setLoading] = React.useState(true);
	const [ProductID, setProductID] = React.useState(props.history.location.state.productIdEdit);

	const [ProductAssetID, setProductAssetID] = React.useState('');
	const [RecordPerPage, setRecordPerPage] = React.useState(150);

	const [modalAssetAddEdit, setModalAssetAddEdit] = React.useState(null);
	const [ModalImage, setModalImage] = React.useState(null);

	const [progress, setprogress] = React.useState(true);
	const [DeleteModel, setDeleteModel] = React.useState(false);
	const [DeleteAssetId, setDeleteAssetId] = React.useState('');

	const [CampaignFreeAssets, setCampaignFreeAssets] = React.useState([]);

	const [ProductAssetType, setProductAssetType] = React.useState([]);

	React.useEffect(() => {
		wsGetAssetTypeForDropdown(ProductID)
	}, [RecordPerPage])

	const wsGetAssetTypeForDropdown = (ProductID) => {
		APIController.GetAssetTypeForDropdown(ProductID)
			.then((response) => {
				setLoading(false)
				if (response.error == null) {
					setProductAssetType(response.object.data)
				}
				else if (response.error.message && response.error.message.length > 0) {
					handleNotification(response.error.message, true);
				}
			})
	}

	const wsGetCampaignFreeAssets = (ProductID, AssetTypeID, RecordPerPage) => {
		setprogress(true)
		setModalAssetAddEdit(false)
		APIController.GetCampaignFreeAssets2(ProductID, AssetTypeID, RecordPerPage)
			.then((response) => {
				setprogress(false)
				if (response.error == null) {
					let Temp = []
					response.object.data.map((item) => {
						Temp.push({ ...item, Checked: false })
					})
					setCampaignFreeAssets(Temp)
				}
				else if (response.error.message && response.error.message.length > 0) {
					handleNotification(response.error.message, true);
				}
			})
	}

	const wsDeleteCampaignFreeAsset = (ID) => {
		APIController.DeleteCampaignFreeAsset(ID)
			.then((response) => {
				if (response.error == null) {
					if (response.object.status == 1) {
						handleNotification(response.object.message)
						wsGetCampaignFreeAssets(ProductID, ProductAssetID, RecordPerPage);
					} else {
						handleNotification(response.object.message, true);
					}
				}
				else if (response.error.message && response.error.message.length > 0) {
					handleNotification(response.error.message)
				}
				setDeleteModel(false)
			})
	}

	const [message, setMessage] = React.useState("");
	const [showNotification, setShowNotification] = React.useState(false);
	const [isError, setIsError] = React.useState(false);

	const handleNotification = (message, isError) => {
		setMessage(message)
		setShowNotification(true)
		setIsError(isError)

		setTimeout(() => {
			handleClearNotification();
		}, 4000)
	}
	const handleClearNotification = () => {
		setMessage('')
		setShowNotification(false)
		setIsError(false)
	}

	const applyFilter = (tag, values, type) => {
		if (values != "") {
			let Temp = CampaignFreeAssets.filter((e) => e[tag].toString().toLowerCase().indexOf(values.toLowerCase()) > -1)
			setCampaignFreeAssets(Temp)
		} else {
			wsGetCampaignFreeAssets(ProductID, ProductAssetID, RecordPerPage)
		}
	}

	const headerList = ['action', "Brand", "Campaign", "Theme", "Name", "Segment", "Sequence #", "Modified Date"]

	return (
		<div className="tab-content asset-tab-wrapper">
			{Loading ? <div style={{ alignContent: 'center', textAlign: 'center' }}><CircularProgress style={{ color: '#0a5c7f' }} /></div> :
				!ProductAssetType.length ? <div style={{ alignContent: 'center', textAlign: 'center' }}><label>Not Currently Available</label></div> :
					!!ProductAssetType.length && ProductAssetType.map((obj, index) => {

						return (<>
							<ExpansionPanel>
								<ExpansionPanelSummary expandIcon={''} aria-controls="panel2a-content" id={"panel2a-header"} onClick={() => { wsGetCampaignFreeAssets(ProductID, obj.ID, RecordPerPage); setProductAssetID(obj.ID) }}>
									<div>{obj.Name}</div>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails>
									<div className="asset-tab-content" style={{ padding: '5px 10px 10px 10px' }}>
										<div style={{ width: '100%', textAlign: 'right', marginBottom: '5px' }}>
											<div className={"tab-submit-btn button-field"} >
												<input type="button" value={"Add " + obj.Name} name={"Add " + obj.Name} onClick={() => {
													setModalAssetAddEdit({
														isModalOpen: true,
														content: {
															titleName: "Add " + obj.Name,
															AssetEditID: 0,
															AssetTypeId: obj.ID,
															ProductID: ProductID,
														},
													})
												}} />
											</div>
											<div className={"tab-submit-btn button-field"} >
												<input type="button" value={"Re Sequence"} name={"Re Sequence"} onClick={() => {
													let TempData = [];
													CampaignFreeAssets.map((item) => {
														if (item.Checked) { TempData.push(item.ID); }
													})
													setModalAssetAddEdit({
														isModalOpen: true,
														content: {
															titleName: "Re Sequence",
															AssetEditID: 1,
															IDs: TempData,
															AssetTypeId: obj.ID,
															ProductID: ProductID,
														},
													})
												}} />
											</div>
										</div>
										<PerfectScrollbar style={{ maxWidth: '100%' }}>
											<div className="asset-tab-right" style={{ width: '120%' }}>
												<div className="asset-table-head" >
													<Table>
														<TableHead>
															<TableRow>
																{headerList.map((obj, index) => {
																	return <TableCell key={index}>{obj}</TableCell>;
																})}
															</TableRow>
															<TableRow>
																<TableCell style={{ width: '7%' }} className="asset-table-width-22">
																	<input style={{ cursor: 'pointer' }} type='checkbox' name={`action`} id={`action`}
																		onChange={(e) => {
																			setCampaignFreeAssets(CampaignFreeAssets.map((item, index) => {
																				return ({ ...item, Checked: item.Checked ? false : true })
																			}))
																		}}
																	/>
																	<label htmlFor={`action`} />
																</TableCell>
																<TableCell style={{ width: '15%' }} className="asset-table-width-22">
																	<input type="text" placeholder="Brand" onChange={(e) => applyFilter('BrandName', e.target.value, 'input')} />
																</TableCell>
																<TableCell style={{ width: '15%' }} className="asset-table-width-22">
																	<input type="text" placeholder="Campaign" onChange={(e) => applyFilter('CampaignName', e.target.value, 'input')} />
																</TableCell>
																<TableCell style={{ width: '15%' }} className="asset-table-width-22">
																	<input type="text" name="" placeholder="Theme" onChange={(e) => applyFilter('ThemeName', e.target.value, 'input')} />
																</TableCell>
																<TableCell style={{ width: '27%' }} className="asset-table-width-22">
																	<input type="text" name="" placeholder="Name" onChange={(e) => applyFilter('Name', e.target.value, 'input')} />
																</TableCell>
																<TableCell style={{ width: '9%' }} className="asset-table-width-17">
																	<input type="text" name="" placeholder="Segment" onChange={(e) => applyFilter('Segment', e.target.value, 'input')} />
																</TableCell>
																<TableCell style={{ width: '0%' }} className="asset-table-width-22">
																	<input type="text" name="" placeholder="Sequence #" onChange={(e) => applyFilter('OrderNo', e.target.value, 'input')} />
																</TableCell>
																<TableCell style={{ width: '20%' }} className="asset-table-width-17">
																	<input type="text" name="" placeholder="Last Modified Date" onChange={(e) => applyFilter('LastModifiedAt', e.target.value, 'input')} />
																</TableCell>
															</TableRow>
														</TableHead>
													</Table>
												</div>


												{<PerfectScrollbar style={{ maxHeight: '500px' }}>
													{!!CampaignFreeAssets.length ? CampaignFreeAssets.map((obj, index) => {
														return (
															<div className="asset-table-body" key={index}>
																<div style={{ width: '8%' }} className="asset-table-width-22">

																	<input
																		style={{ cursor: 'pointer' }}
																		type='checkbox'
																		name={`user-${obj.ID}`}
																		id={`user-${obj.ID}`}
																		checked={obj.Checked}
																		onChange={(e) => {
																			setCampaignFreeAssets(CampaignFreeAssets.map((item, index) => item.ID !== obj.ID ? item : { ...item, Checked: !item.Checked }))
																		}}
																	/>
																	<label htmlFor={`user-${obj.ID}`}></label>

																	<a style={{ cursor: 'pointer', paddingLeft: '10px' }} title="" className="small-ic" onClick={(e) => {
																		setModalAssetAddEdit({
																			isModalOpen: true,
																			content: {
																				titleName: "Edit " + obj.Name,
																				AssetEditID: obj.ID,
																				AssetTypeId: obj.ID,
																				ProductID: ProductID,
																			},
																		})
																	}}><img src={editIcon} alt="icon" /></a>

																	<a style={{ cursor: 'pointer' }} title="" className="small-ic" onClick={(e) => {
																		e.preventDefault();
																		setDeleteAssetId(obj.ID)
																		setDeleteModel(true)
																	}}><img src={deleteIcon} alt="icon" /></a>

																	<a style={{ cursor: 'pointer' }} title="" onClick={() => {
																		if (obj.ImageURL.indexOf('.jpg') != -1 || obj.ImageURL.indexOf('.jpeg') != -1 || obj.ImageURL.indexOf('.png') != -1 || obj.ImageURL.indexOf('.svg') != -1) {
																			setModalImage({
																				isModalOpen: true,
																				content: { image: obj.ImageURL }
																			})
																		} else {
																			setModalImage({
																				isModalOpen: true,
																				content: { image: obj.PreviewURL }
																			})
																		}
																	}}>
																		<img src={previewIcon} alt="icon" />
																	</a>

																</div>
																<div style={{ width: '14%' }} className="asset-table-width-22"><p>{obj.BrandName}</p></div>
																<div style={{ width: '13%' }} className="asset-table-width-22"><p>{obj.CampaignName}</p></div>
																<div style={{ width: '13%' }} className="asset-table-width-22"><p>{obj.ThemeName}</p></div>
																<div style={{ width: '21.5%' }} className="asset-table-width-22"><p>{obj.Name}</p></div>
																<div style={{ width: '9%' }} className="asset-table-width-17"><p>{obj.Segment}</p></div>
																<div style={{ width: '5%', textAlign: 'center' }} className="asset-table-width-22"><p>{obj.OrderNo}</p></div>
																<div style={{ width: '16%', textAlign: 'end' }} className="asset-table-width-17"><p>{obj.LastModifiedAt}</p></div>

															</div>

														);
													}) :
														<div style={{ textAlign: 'center' }}>
															{progress ?
																<CircularProgress style={{ color: '#0a5c7f', alignSelf: 'center' }} /> :
																<label>Not Currently Available</label>}
														</div>}
												</PerfectScrollbar>}
											</div>
										</PerfectScrollbar>
									</div>
								</ExpansionPanelDetails>
							</ExpansionPanel>
						</>)
					})
			}
			<Notification
				isError={isError}
				message={message}
				showNotification={showNotification}
				clearNotification={handleClearNotification.bind(this)}
				closeNotification={handleClearNotification.bind(this)}
			/>

			{ModalImage && ModalImage.isModalOpen && (
				<ModalComponent
					show={ModalImage.isModalOpen}
					handleClose={() => setModalImage(null)}>
					<div className='popup-wrap'>
						<img src={ModalImage.content.image} alt='place' />
						<img src={close_icon} alt='icon' className='gray-close-ic' onClick={() => setModalImage(null)} style={{ cursor: 'pointer' }} />
					</div>
				</ModalComponent>
			)}

			{modalAssetAddEdit && modalAssetAddEdit.isModalOpen && (
				<ModalComponent
					show={modalAssetAddEdit.isModalOpen}
					handleClose={() => setModalAssetAddEdit(null)}>
					<UploadFreeAsset
						closeModal={() => wsGetCampaignFreeAssets(ProductID, ProductAssetID, RecordPerPage)}
						campaignAsset={modalAssetAddEdit.content}
						ShowAssetsInSocialAutomation={props.ShowAssetsInSocialAutomation}
						titleName={modalAssetAddEdit.content.titleName}
					/>
				</ModalComponent>
			)}

			{DeleteModel &&
				<ModalComponent
					show={DeleteModel}
					handleClose={() => setDeleteModel(false)}>
					<ConfirmtionDialog
						title={"Delete Asset"}
						description={"Are you sure you want to delete this Asset?"}
						deletePress={() => wsDeleteCampaignFreeAsset(DeleteAssetId)}
						closeModal={() => setDeleteModel(false)}
					/>
				</ModalComponent>
			}
		</div>
	);
};

export default withRouter(ProductAssetFormTab);