/*eslint-disable eqeqeq*/
import React from 'react';
import ConfigurationList from '../../../component/componentSupplytool/Configuration/configurationList';
import * as APIController from '../../../../../api/APIController.js';
import Notification from '../../../../../shared/components/notification/notification';

class ConfigurationContainer extends React.Component {

    state = {
        currentPage: 1,
        count: 0,
        list: [],
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 'All'],
        from: 0,
        to: 10,
        progress: true,
        message: '',
        showNotification: false,
        isError: false,
        showMessage: this.props.history.location.state != null && this.props.history.location.state.showMessage
    }

    componentDidMount() {
        this.wsGetConfigurations(this.state.currentPage, this.state.rowsPerPage, this.state.from, this.state.to);
        this.props.history.replace('/supplytool/configuration/list', null);
    }

    handlebind() {
        this.wsGetConfigurations(this.state.currentPage, this.state.rowsPerPage)
    }

    wsGetConfigurations(CurrentPage, RecordPerPage, from, to, MinimumQuantityForAnnualSupply, PurchaseType, Segment, Brand, LenseType) {
        this.setState({ progress: true, });
        APIController.GetConfigurations(MinimumQuantityForAnnualSupply, PurchaseType, Segment, Brand, LenseType, CurrentPage, RecordPerPage, "Segment", "DESC")
            .then((response) => {
                if (response.object.status == 0 && response.object.data.length != 0) {
                    this.setState({
                        to: isNaN(to) ? 1000000 : to,
                        from: from,
                        count: response.object.data[0].MaxRows,
                        list: response.object.data,
                        progress: false,
                    })
                }
                else if (response.object.message && response.object.message.length > 0) {
                    // ToastsStore.warning(response.error.message)
                } else { this.setState({ list: [], progress: false }) }
            })
    }


    render() {
        return (
            <>
                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    clearNotification={this.handleClearNotification}
                    closeNotification={this.handleClearNotification}
                />
                <section className="content-right-main">
                    <div className="button-field button-field-country">
                        <button onClick={() => {
                            this.props.history.push({
                                pathname: '/supplytool/configuration/add',
                                state: { typeIdEdit: '0' }
                            })
                        }}>+ Add Configuration</button>
                    </div>

                    <ConfigurationList {...this.state}
                        history={this.props.history}
                        handlebind={this.handlebind.bind(this)}
                        handleChangeRowsPerPage={this.handleRowsPerPageChange}
                        handleChangePage={this.handlePageChange}
                        applyFilter={this.handleApplyFilter}
                    />
                </section>
            </>
        )
    }

    handleRowsPerPageChange = (value, MinimumQuantityForAnnualSupply, PurchaseType, Segment, Brand, LenseType) => {
        let from = 0
        let to = value;
        this.setState({ progress: true, rowsPerPage: value, currentPage: 1, to: to, from: from })
        this.wsGetConfigurations(isNaN(value) ? '' : this.state.currentPage, isNaN(value) ? 1000000 : value, from, to, MinimumQuantityForAnnualSupply, PurchaseType, Segment, Brand, LenseType);
    }

    handlePageChange = (values, MinimumQuantityForAnnualSupply, PurchaseType, Segment, Brand, LenseType) => {
        let from = values === 1 ? 0 * this.state.rowsPerPage : (values - 1) * this.state.rowsPerPage
        let to = values === 1 ? this.state.rowsPerPage : (from + this.state.rowsPerPage);
        this.setState({ currentPage: Number(values) })
        this.wsGetConfigurations(Number(values), this.state.rowsPerPage, from, to, MinimumQuantityForAnnualSupply, PurchaseType, Segment, Brand, LenseType);
    }

    handleApplyFilter = (MinimumQuantityForAnnualSupply, PurchaseType, Segment, Brand, LenseType) => {
        this.wsGetConfigurations(this.state.currentPage, this.state.rowsPerPage, this.state.from, this.state.to, MinimumQuantityForAnnualSupply, PurchaseType, Segment, Brand, LenseType)
    }
    handleNotification = (message, isError) => {
        this.setState({
            message: message,
            showNotification: true,
            isError: isError
        })

        setTimeout(() => {
            this.handleClearNotification();
        }, 4000)
    }

    handleClearNotification = () => {
        this.setState({
            message: '',
            showNotification: false,
            isError: false
        })
    }
}

export default ConfigurationContainer;