/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'
import * as APIController from '../../../../api/APIController.js';
import * as yup from 'yup';

import Notification from '../../../../shared/components/notification/notification';

import Dropdown from '../../../../shared/components/Dropdown/Dropdown';
import MenuItem from '@material-ui/core/MenuItem';
import file_upload_ic from "../../../../assets/images/file-upload-ic.svg";
import MyiFrame from "../../../../features/campaign/components/myiFrame";
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";

const MasterTemplateForm = props => {
    const initialValues = { name: props.history.location.state.EditName, brandID: props.history.location.state.EditBrandID, active: props.history.location.state.EditIsActive, finder: props.history.location.state.EditIsDocFinder }
    const [isButtonValue, setButtonValue] = React.useState(false);
    const [IsLoading, setIsLoading] = React.useState(false);
    const [brandProgress, setBrandProgress] = React.useState(false);
    const [brandListData, setBrandListData] = React.useState([]);
    const [content, setcontent] = React.useState("");
    const [previewContent, setPreviewContent] = React.useState("");

    React.useEffect(() => {
        // EditBrandID
        props.history.location.state.EditID != 0 ? wsGetLandingPageAutomationTemplateByID(props.history.location.state.EditID)
            : wsGetBrandForDropdown();
    }, [props])


    const wsGetLandingPageAutomationTemplateByID = (ID) => {
        setIsLoading(true)
        setBrandProgress(true)
        APIController.GetLandingPageAutomationTemplateByID(ID)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    initialValues.name = response.object.data[0].Name
                    initialValues.brandID = response.object.data[0].BrandID

                    setPreviewContent(response.object.data[0].HTMLContent)
                    setBrandListData(JSON.parse(response.object.data[0].Brands))
                    setBrandProgress(false)
                } else {
                    handleNotification(response.object.message, true);
                }
            })
    }
    const wsAddEditLandingPageTemplate = (ID, Name, BrandID, HTMLContent, IsActive, IsDocFinder) => {
        setIsLoading(true)
        APIController.AddEditLandingPageTemplate(ID, Name, BrandID, HTMLContent, IsActive, IsDocFinder)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    if (isButtonValue === true && response.object.status == 1) {
                        props.history.replace({
                            pathname: '/masterTemplate',
                            state: { showMessage: response.object.message }
                        })
                    }
                    else if (response.object.status == 1) {
                        handleNotification(response.object.message);
                    } else {
                        handleNotification(response.object.message, true);
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const wsGetBrandForDropdown = () => {
        setBrandProgress(true)
        APIController.GetBrandForDropdown()
            .then((response) => {
                if (response.error == null) {
                    setBrandListData(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    // ToastsStore.warning(response.error.message)
                }
                setBrandProgress(false)
            })
    }


    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    const getBase64 = file => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    return (
        <>
            <section className="content-right-main">
                {props.history.location.state.EditID == 0 ? <h2>Add Master Template</h2> : <h2>Edit Master Template</h2>}
                <Formik
                    // enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validateForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        wsAddEditLandingPageTemplate(props.history.location.state.EditID, values.name, values.brandID, content, values.active, values.finder)
                        if (isButtonValue === false) {
                            values.name = "";
                            values.active = 1;
                            values.finder = 1;
                        }
                    }} >
                    {({
                        values,
                        handleSubmit,
                        setFieldValue,
                        errors,
                        touched,
                    }) => {
                        return (
                            <div className="form-main">
                                <form onSubmit={handleSubmit}>
                                    {/* "Template Name", "Brand", "Created At", "Last Modified At", "Total Users" */}
                                    <div className={`form_field ${errors && touched.name && errors.name && errors.name !== '' ? 'error' : ''}`}>
                                        <div className="field-left"> <label>Template Name</label> </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Template Name" name="name" value={values.name} maxLength="50" id={'name'} />
                                            {errors && touched.name && errors.name && errors.name !== '' && (<span className='error-msg'> <ErrorMessage name='name' render={(err) => { return err; }} /> </span>)}
                                        </div>
                                    </div>

                                    <div className={`form_field ${errors && touched.brandID && errors.brandID && errors.brandID !== '' ? 'error' : ''}`}>
                                        <div className="field-left"> <label>Select Brands</label> </div>
                                        <div className="field-right">
                                            {brandProgress ? <CircularProgress /> :
                                                <Dropdown isError={errors && touched.brandID && errors.brandID && errors.brandID !== '' ? true : false}
                                                    label='Select Brand'
                                                    id={'brandID'}
                                                    name='brandID'
                                                    placeholder='Select Brands'
                                                    defaultValue={values.brandID}
                                                    value={values.brandID}
                                                    onChange={(e) => { setFieldValue('brandID', e.target.value); }}
                                                    select>
                                                    <MenuItem classes='' value={''}> {'Select Brand'} </MenuItem>
                                                    {brandListData.map((obj, index) => {
                                                        return <MenuItem classes='' selected={obj.ID == values.brandID ? obj.ID : obj.Name} value={obj.ID} key={index}>{obj.Name}</MenuItem>
                                                    })}
                                                </Dropdown>}

                                            {errors && touched.brandID && errors.brandID && errors.brandID !== '' && (
                                                <span className='error-msg'> <ErrorMessage name='brandID' render={(err) => { return err; }} /> </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form_field" style={{ width: '100%' }}>
                                        <div className="field-left">
                                            <div className="form-file-input asset-button">
                                                <input className="input-file" id="fileupload" name="files" type="file" accept=".html" onChange={(e) => {
                                                    const file = e.target.files[0];
                                                    if (file && file.type === "text/html") {

                                                        getBase64(file)
                                                            .then(result => {
                                                                file["base64"] = result;
                                                                setcontent(result.split(',').pop())
                                                            }).catch(err => { });

                                                        const reader = new window.FileReader();
                                                        reader.onload = (data) => { setPreviewContent(data.target.result); };
                                                        reader.readAsText(file);
                                                    }
                                                }} />
                                                <label htmlFor="fileupload" className="input-file-trigger" style={{ display: 'flex' }}>
                                                    <img src={file_upload_ic} alt="file-uplaod" /> Drag and drop your file here </label>
                                            </div>
                                        </div>

                                        <div className="field-right">
                                            <div className="asset-full">
                                                <MyiFrame HTMLContent={previewContent} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Is Active ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="yes-text"
                                                    defaultValue={values.active}
                                                    defaultChecked={values.active != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 1)} />
                                                <label htmlFor="yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="no-text"
                                                    defaultValue={values.active}
                                                    defaultChecked={values.active == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 0)} />
                                                <label htmlFor="no-text">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Is Doc Finder ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="finder" id="yes-txt"
                                                    defaultValue={values.finder}
                                                    defaultChecked={values.finder != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('finder', 1)} />
                                                <label htmlFor="yes-txt">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="finder" id="no-txt"
                                                    defaultValue={values.finder}
                                                    defaultChecked={values.finder == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('finder', 0)} />
                                                <label htmlFor="no-txt">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <Notification
                                        isError={isError}
                                        message={message}
                                        showNotification={showNotification}
                                        clearNotification={handleClearNotification.bind(this)}
                                        closeNotification={handleClearNotification.bind(this)}
                                    />
                                    <div className="form-submit">
                                        <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" onClick={() => { setButtonValue(true) }} />
                                        </div>
                                        {props.history.location.state.EditID == 0 ?
                                            <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                                <input type="submit" value={IsLoading ? "Saving..." : "save & add new"} name="save & add new" onClick={() => setButtonValue(false)} />
                                            </div> : null}
                                        <div className="button-field">
                                            <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )
                    }}
                </Formik>
            </section>
        </>
    )
}
const validateForm = yup.object().shape({
    name: yup.string().required('Template Name is required'),
    brandID: yup.string().required('Brand is required'),
});


export default MasterTemplateForm;