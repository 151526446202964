/*eslint-disable eqeqeq*/
import React from "react";
import Table from "../../../../shared/components/table/table";
import TableHead from "../../../../shared/components/table/tableHead";
import TableRow from "../../../../shared/components/table/tableRow";
import TableBody from "../../../../shared/components/table/tableBody";
import TableCell from "../../../../shared/components/table/tableCell";
import double_left_arrow from '../../../../assets/images/left-doble-arow.svg';
import left_arrow from '../../../../assets/images/left-arrow.svg';
import double_right_arrow from '../../../../assets/images/right-doble-arow.svg';
import right_arrow from '../../../../assets/images/right-arrow.svg';
import "react-perfect-scrollbar/dist/css/styles.css";
import PageTitle from "../../../../config/pageTitle";
import PerfectScrollbar from "react-perfect-scrollbar";
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";

const SubscriptionOrderList = (props) => {

	const headerList = ["Transaction At", "Amount", "Transaction ID", " Status"]
	const { currentPage, count, rowsPerPage, rowsPerPageOptions, list, to, from } = props;
	let countryList = list;

	countryList = list.slice(from, to)
	const page = Math.floor(count / rowsPerPage) + 1;

	const handleChangeInCurrentPage = (value) => {
		if (value <= page && Number(parseInt(value))) {
			props.handleChangePage(Number(value));
		}
	}

	return (
		<>
			<h1><PageTitle Title="View Subscriptions" /></h1> <div className="table-main-wrap">
				<PerfectScrollbar component='div'>
					<Table>
						<TableHead>
							<TableRow> {headerList.map((obj, index) => { return <TableCell style={index == 1 ? { textAlign: 'right', minWidth: '100px ' } : index == headerList.length - 1 ? { textAlign: 'center' } : { minWidth: '100px' }} key={index}>{obj}</TableCell>; })} </TableRow>
							<TableRow>
								<TableCell> <input type="text" name="" placeholder="Transaction At" onChange={(e) => props.applyFilter('TransactionAt', e.target.value, 'input')} /> </TableCell>
								<TableCell> <input type="text" name="" placeholder="Amount" onChange={(e) => props.applyFilter('{Amount', e.target.value, 'input')} style={{ textAlign: 'right' }} /> </TableCell>
								<TableCell> <input type="text" name="" placeholder="Transaction ID" onChange={(e) => props.applyFilter('TransactionID', e.target.value, 'input')} /> </TableCell>
								{/* <TableCell> <input type="text" name="" placeholder="Status" onChange={(e) => props.applyFilter('Status', e.target.value, 'input')} /> </TableCell> */}
								<TableCell>
									<select onChange={(e) => props.applyFilter('Status', e.target.value, 'select')}>
										<option value={""}>All</option>
										<option value={"ACTIVE"}>ACTIVE</option>
										<option value={"APPROVAL_PENDING"}>APPROVAL_PENDING</option>
									</select>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!!countryList.length ? countryList.map((obj, idnex) => {
								return (
									<TableRow key={idnex}>
										<TableCell style={{ minWidth: '300px' }}>{obj.TransactionAt}</TableCell>
										<TableCell style={{ minWidth: '100px', textAlign: 'right' }}>{obj.Amount}</TableCell>
										<TableCell style={{ minWidth: '300px' }}>{obj.TransactionID}</TableCell>
										<TableCell style={{ minWidth: '200px', textAlign: 'center' }} className={obj.Status === "ACTIVE" ? 'green' : obj.Status === "APPROVAL_PENDING" ? 'red' : ''}>
											<span>{obj.Status}</span></TableCell>
										{/* <TableCell>{obj.StartDate.split("-")[2]}</TableCell>
										<TableCell>{obj.StartDate.split("-")[1]}</TableCell> */}
									</TableRow>
								);
							}) : <TableRow>
								<TableCell colSpan={headerList.length} className="not_found">
									{props.progress ?
										<CircularProgress /> :
										<label>Not Currently Available</label>}
								</TableCell>
							</TableRow>}
						</TableBody>
					</Table>
				</PerfectScrollbar>
			</div>

			<div className="table-pagination">
				<div className="pagination-info">
					<p>Showing {from + 1} - {to > count ? count : to} of {count}</p>
				</div>
				<div className="pagination-control">
					<a href="#!" title="" className={`double-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(1)
						}}
					><img src={double_left_arrow} alt="left" /></a>
					<a href="#!" title="" className={`simple-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage - 1)
						}}
					><img src={left_arrow} alt="single_left" /></a>
					<span>Page</span>
					<input type="text" name="textbox" value={currentPage === 0 ? currentPage + 1 : currentPage} onChange={(e) => handleChangeInCurrentPage(e.target.value)} />
					<span>of {page}</span>
					<a href="#!" title="" className={`simple-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage + 1)
						}}
					><img src={right_arrow} alt="right" /></a>
					<a href="#!" title="" className={`double-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(page)
						}}><img src={double_right_arrow} alt="single_right" /></a>
					<select onChange={(e) => props.handleChangeRowsPerPage(e.target.value)} defaultValue={rowsPerPage}>
						{
							rowsPerPageOptions.map((obj, index) => {
								return <option value={obj} key={index}>{obj}</option>
							})
						}
					</select>
				</div>
			</div>
		</>
	);

};

export default SubscriptionOrderList;
