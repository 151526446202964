/*eslint-disable eqeqeq*/
import React from "react";

import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";
import * as APIController from '../../../api/APIController.js';

import ModalComponent from "../../../shared/components/modal/modal";
import close_icon from "../../../assets/images/gray-close-ic.svg";
import "./campaignDetail.css";

import PageTitle from "../../../config/pageTitle";
import * as AppConstants from "../../../config/AppConstant";

import '@fullcalendar/daygrid/main.css'

const CampaignDetail = (props) => {

	const [campaignThemes, setCampaignThemes] = React.useState([]);
	const [progress, setprogress] = React.useState(false);
	const [modal, setmodal] = React.useState(null);
	const [PageTitleText, setPageTitleText] = React.useState(AppConstants.GetLocalStorage('localStorageProductName').split(' ')[0][0]);
	const [PageTitleS, setPageTitleS] = React.useState(AppConstants.GetLocalStorage('localStorageProductName').split(' ')[0][0]);
	// AppConstants.GetLocalStorage('localStorageProductName').split(' ')[0][0]  + AppConstants.GetLocalStorage('localStorageProductName')
	// const [BudgetDetail, setBudgetDetail] = React.useState(JSON.stringify(AppConstants.GetLocalStorage('localStorageBudgetDetail'))[0]);

	React.useEffect(() => {
		wsGetCampaignThemes(AppConstants.GetLocalStorage("localStorageCampaignID"), AppConstants.GetLocalStorage('localStorageProductID'));

	}, [props])

	const wsGetCampaignThemes = (CampaignID, ProductID) => {
		setprogress(true)
		setCampaignThemes([])
		APIController.GetCampaignThemes(CampaignID, ProductID)
			.then((response) => {
				if (response.error == null && response.object.data.length != 0) {
					setCampaignThemes(response.object.data)
				}
				else if (response.object.message && response.object.message.length > 0) {
					alert(response.error.message)
				}
				setprogress(false)
			})
	}

	const renderEventContent = (eventInfo) => {
		return (
			<div className="two-col-sec-calendar digital-welcome">
				<div className="content-box-calendar">
					<div className="box-image"
						style={{
							backgroundImage: `url(${eventInfo.event.extendedProps.image} )`,
							backgroundOrigin: 'padding-box',
						}}>
						<div className="button-hover">
							<div className="button-field">
								<button onClick={() => alert("Click")}>{AppConstants.ButtonText.Preview}</button>
							</div>
							<div className="button-field">
								<button onClick={() => alert("Click")}>{AppConstants.ButtonText.Delete}</button>
							</div>
						</div>
					</div>
				</div>
				<div className="box-content-info" >
					<label htmlFor={eventInfo.event.defId} style={{ fontSize: '14px' }}>{eventInfo.event.title}</label>
				</div>
			</div>
		)
	}

	return (
		<section className='content-right-main'>
			{AppConstants.GetMainClassName() == "app-main" ? AppConstants.GetLocalStorage('localStorageISDirectMail') == 1 ?
				<PageTitle Title={AppConstants.ContactDetais.Step1Choose} />
				: <PageTitle Title={AppConstants.ContactDetais.Step1Select} />
				: <PageTitle Title={AppConstants.GetLocalStorage('localStorageProductName').split(' ')[0][0] == "E" ?
					AppConstants.ContactDetais.Step1Select + "an " + AppConstants.GetLocalStorage('localStorageProductName').split(' ')[0]
					: AppConstants.ContactDetais.Step1Select + "a " + AppConstants.GetLocalStorage('localStorageProductName')} />}
			<div className="two-col-sec digital-welcome">
				{!!campaignThemes.length ? campaignThemes.map((obj, index) => {
					return (
						<div className="content-box" key={index}>
							<div className="box-image"
								style={{
									backgroundImage: `url(${obj.PreviewURL + "?" + Math.floor(Math.random() * (999999 - 100000 + 1) + 100000)} )`,
									backgroundOrigin: 'padding-box',
								}}>
								<div className="button-hover" style={{ alignItems: 'center', justifyContent: 'center' }}>
									<div className="button-field" style={{ cursor: 'pointer' }}>
										<button onClick={() => {
											props.history.push(AppConstants.GetLocalStorage('localStorageMenuPath'))
											// props.history.push("/digital-product/selection/location-selection")
											AppConstants.SetLocalStorage("localStorageThemesID", obj.ID)
											AppConstants.SetLocalStorage("localStorageThemesName", obj.Name)
											AppConstants.SetLocalStorage("localStorageFreeAssetID", "")
										}} > {AppConstants.GetLocalStorage('localStorageShowCopyText') == 0 ? AppConstants.ButtonText.Order : AppConstants.ButtonText.Customize} </button>
									</div>
									<div className="button-field">
										<button onClick={() =>
											setmodal({
												isModalOpen: true,
												content: { image: obj.ImageURL + "?" + Math.floor(Math.random() * (999999 - 100000 + 1) + 100000) },
											})}>{AppConstants.ButtonText.Preview}</button>
									</div>
								</div>
							</div>
							<div className="box-content-info">
								{AppConstants.GetMainClassName() == "app-main" ?
									<>
										<p>{AppConstants.GetLocalStorage('localStorageBrandName')}</p>
										<label className="box-lable">{obj.Name}</label>
									</> :
									<>
										<p>{obj.Name}</p>
										<label className="box-lable">{obj.Description}</label>
									</>}
							</div>
						</div>
					)
				}) : <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
					{progress ?
						<CircularProgress /> :
						<label>Not currently available for this category</label>}
				</div>}
			</div>
			{AppConstants.GetMainClassName() == "app-main" && <div className='button-field back-btn'>
				<input type='button' name='button' value={AppConstants.ButtonText.Back} onClick={() => props.history.goBack()} />
			</div>}
			{modal && modal.isModalOpen && (
				<ModalComponent
					show={modal.isModalOpen}
					handleClose={() => setmodal(null)}>
					<div className="popup-wrap">
						<div style={{ display: 'grid' }}>
							<div className='box-head' style={{ marginBottom: '5px', padding: '8px' }}>
								<h4>{AppConstants.ButtonText.Preview}</h4>
							</div>
							<img src={modal.content.image} alt="place" />
						</div>
						<a title="" className="gray-close-ic">
							<img src={close_icon} alt="icon" onClick={() => setmodal(null)} />
						</a>
					</div>
				</ModalComponent>
			)}

			{/* <FullCalendar
				plugins={[dayGridPlugin]}
				initialView="dayGridMonth"
				eventContent={renderEventContent}
				events={[
					{ title: 'Test Dta text on biotrue  30-08-2021 Test Dta text on biotrue', date: '2021-08-30', image: asset3 },
					{ title: 'Test Dta text on biotrue 14-08-2021 Test Data text on biotrue', date: '2021-08-14', image: asset3 }
				]}
			/> */}

		</section>
	);


};

export default CampaignDetail;