/*eslint-disable eqeqeq*/
import React from 'react';
import SupplyLeadsList from '../../../component/componentSupplytool/SupplyLeads/supplyLeadsList';
import * as APIController from '../../../../../api/APIController.js';
import Notification from '../../../../../shared/components/notification/notification';

class SupplyLeadsContainer extends React.Component {

    state = {
        currentPage: 1,
        count: 0,
        list: [],
        rowsPerPage: 25,
        rowsPerPageOptions: [25, 50, 100, 'All'],
        from: 0,
        to: 25,
        progress: true,
        message: '',
        showNotification: false,
        isError: false,
        showMessage: this.props.history.location.state != null && this.props.history.location.state.showMessage
    }

    componentDidMount() {
        this.wsGetSupplyLeads(this.state.currentPage, this.state.rowsPerPage, this.state.from, this.state.to);
        this.props.history.replace('/supplytool/leads/list', null);
    }

    handlebind() {
        this.wsGetSupplyLeads(this.state.currentPage, this.state.rowsPerPage)
    }

    wsGetSupplyLeads(CurrentPage, RecordPerPage, from, to,
        LeadAt, PracticeName, PurchaseType, LeftLenseType, RightLenseType,
        L_RetailPrice, L_RebatePrice, L_AdditionalDiscount, L_InsuranceCoverage, L_PricePerBox, L_R_PricePerBox, L_YouPay,
        R_RetailPrice, R_RebatePrice, R_AdditionalDiscount, R_InsuranceCoverage, R_L_PricePerBox, R_PricePerBox, R_YouPay) {
        this.setState({ progress: true, });
        APIController.GetSupplyLeads(LeadAt, PracticeName, PurchaseType, LeftLenseType, RightLenseType,
            L_RetailPrice, L_RebatePrice, L_AdditionalDiscount, L_InsuranceCoverage, L_PricePerBox, L_R_PricePerBox, L_YouPay,
            R_RetailPrice, R_RebatePrice, R_AdditionalDiscount, R_InsuranceCoverage, R_L_PricePerBox, R_PricePerBox, R_YouPay,
            CurrentPage, RecordPerPage, "LeadAt", "DESC")
            .then((response) => {
                if (response.object.status == 0 && response.object.data.length != 0) {
                    this.setState({
                        to: isNaN(to) ? 1000000 : to,
                        from: from,
                        count: response.object.data[0].MaxRows,
                        list: response.object.data,
                        progress: false,
                    })
                } else { this.setState({ list: [], progress: false }) }
            })
    }


    render() {
        return (
            <>
                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    clearNotification={this.handleClearNotification}
                    closeNotification={this.handleClearNotification}
                />
                <section className="content-right-main">
                    {/* <div className="button-field button-field-country">
                        <button onClick={() => {
                            this.props.history.push({
                                pathname: '/supplytool/leads/add',
                                state: { lensestypeIdEdit: '0', brandNameEdit: '', sequenceNoEdit: '', lensestypeNameEdit: '', lensestypeActiveEdit: 1 }
                            })
                        }}>+ Add Lenses Type</button>
                    </div> */}

                    <SupplyLeadsList {...this.state}
                        history={this.props.history}
                        handlebind={this.handlebind.bind(this)}
                        handleChangeRowsPerPage={this.handleRowsPerPageChange}
                        handleChangePage={this.handlePageChange}
                        applyFilter={this.handleApplyFilter}
                    />
                </section>
            </>
        )
    }

    handleRowsPerPageChange = (value, LeadAt, PracticeName, PurchaseType, LeftLenseType, RightLenseType,
        L_RetailPrice, L_RebatePrice, L_AdditionalDiscount, L_InsuranceCoverage, L_PricePerBox, L_R_PricePerBox, L_YouPay,
        R_RetailPrice, R_RebatePrice, R_AdditionalDiscount, R_InsuranceCoverage, R_L_PricePerBox, R_PricePerBox, R_YouPay) => {
        let from = 0
        let to = value;
        this.setState({ progress: true, rowsPerPage: value, currentPage: 1, to: to, from: from })
        this.wsGetSupplyLeads(isNaN(value) ? '' : this.state.currentPage, isNaN(value) ? 1000000 : value, from, to,
            LeadAt, PracticeName, PurchaseType, LeftLenseType, RightLenseType,
            L_RetailPrice, L_RebatePrice, L_AdditionalDiscount, L_InsuranceCoverage, L_PricePerBox, L_R_PricePerBox, L_YouPay,
            R_RetailPrice, R_RebatePrice, R_AdditionalDiscount, R_InsuranceCoverage, R_L_PricePerBox, R_PricePerBox, R_YouPay);
    }

    handlePageChange = (values, LeadAt, PracticeName, PurchaseType, LeftLenseType, RightLenseType,
        L_RetailPrice, L_RebatePrice, L_AdditionalDiscount, L_InsuranceCoverage, L_PricePerBox, L_R_PricePerBox, L_YouPay,
        R_RetailPrice, R_RebatePrice, R_AdditionalDiscount, R_InsuranceCoverage, R_L_PricePerBox, R_PricePerBox, R_YouPay) => {
        let from = values === 1 ? 0 * this.state.rowsPerPage : (values - 1) * this.state.rowsPerPage
        let to = values === 1 ? this.state.rowsPerPage : (from + this.state.rowsPerPage);
        this.setState({ currentPage: Number(values) })
        this.wsGetSupplyLeads(Number(values), this.state.rowsPerPage, from, to, LeadAt, PracticeName, PurchaseType, LeftLenseType, RightLenseType,
            L_RetailPrice, L_RebatePrice, L_AdditionalDiscount, L_InsuranceCoverage, L_PricePerBox, L_R_PricePerBox, L_YouPay,
            R_RetailPrice, R_RebatePrice, R_AdditionalDiscount, R_InsuranceCoverage, R_L_PricePerBox, R_PricePerBox, R_YouPay);
    }

    handleApplyFilter = (LeadAt, PracticeName, PurchaseType, LeftLenseType, RightLenseType,
        L_RetailPrice, L_RebatePrice, L_AdditionalDiscount, L_InsuranceCoverage, L_PricePerBox, L_R_PricePerBox, L_YouPay,
        R_RetailPrice, R_RebatePrice, R_AdditionalDiscount, R_InsuranceCoverage, R_L_PricePerBox, R_PricePerBox, R_YouPay) => {
        this.wsGetSupplyLeads(this.state.currentPage, this.state.rowsPerPage, this.state.from, this.state.to,
            LeadAt, PracticeName, PurchaseType, LeftLenseType, RightLenseType,
            L_RetailPrice, L_RebatePrice, L_AdditionalDiscount, L_InsuranceCoverage, L_PricePerBox, L_R_PricePerBox, L_YouPay,
            R_RetailPrice, R_RebatePrice, R_AdditionalDiscount, R_InsuranceCoverage, R_L_PricePerBox, R_PricePerBox, R_YouPay)
    }
    handleNotification = (message, isError) => {
        this.setState({
            message: message,
            showNotification: true,
            isError: isError
        })

        setTimeout(() => {
            this.handleClearNotification();
        }, 4000)
    }

    handleClearNotification = () => {
        this.setState({
            message: '',
            showNotification: false,
            isError: false
        })
    }
}

export default SupplyLeadsContainer;