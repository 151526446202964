/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'
// import './countryForm.css'
import * as APIController from '../../../../api/APIController.js';
import * as yup from 'yup';

import Notification from '../../../../shared/components/notification/notification';
// paypalsubscriptionIdEdit: "0",
// 										paypalsubscriptionNameEdit: "",
// 										paypalsubscriptionProductIDEdit: "",
// 										paypalsubscriptionPlanIDEdit: "",
// 										paypalsubscriptionCostEdit: "",
// 										paypalsubscriptionActiveEdit: 1,
const PaypalSubscriptionPlanForm = props => {
    const initialValues = {
        name: props.history.location.state.paypalsubscriptionNameEdit,
        productid: props.history.location.state.paypalsubscriptionProductIDEdit,
        planid: props.history.location.state.paypalsubscriptionPlanIDEdit,
        cost: props.history.location.state.paypalsubscriptionCostEdit,
        active: props.history.location.state.paypalsubscriptionActiveEdit
    }
    const [isButtonValue, setButtonValue] = React.useState(false);
    const [IsLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        document.getElementById('name').focus();
    }, [props])


    const wsAddEditPaypalSubscriptionPlan = (ID, Name, ProductID, PlanID, Cost, IsActive) => {
        setIsLoading(true)
        APIController.AddEditPaypalSubscriptionPlan(ID, Name, ProductID, PlanID, Cost, IsActive)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    if (isButtonValue === true && response.object.status == 1) {
                        props.history.replace({
                            pathname: '/paypalsubscriptionplan',
                            state: { showMessage: response.object.message }
                        })
                    }
                    else if (response.object.status == 1) {
                        handleNotification(response.object.message);
                    } else {
                        handleNotification(response.object.message, true);
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }
    return (
        <>
            <section className="content-right-main">
                {props.history.location.state.paypalsubscriptionIdEdit == 0 ? <h1>Add Paypal Subscription Plan</h1> : <h1>Edit Paypal Subscription Plan</h1>}
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validatePaypalSubscriptionPlanForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        wsAddEditPaypalSubscriptionPlan(props.history.location.state.paypalsubscriptionIdEdit, values.name, values.productid, values.planid, values.cost, values.active)
                        if (isButtonValue === false) {
                            values.name = "";
                            values.productid = "";
                            values.planid = "";
                            values.cost = "";
                            values.active = 1;
                        }
                    }} >
                    {({ values, handleSubmit, setFieldValue, errors, touched, }) => {
                        return (
                            <div className="form-main">
                                <form onSubmit={handleSubmit}>
                                    {/* Name | Product ID | Plan ID | Cost | Is Active */}
                                    <div className={`form_field ${errors && touched.name && errors.name && errors.name !== '' ? 'error' : ''}`}>
                                        <div className="field-left"> <label>Name</label> </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Name" name="name" value={values.name} maxLength="50" id={'name'} />
                                            {errors && touched.name && errors.name && errors.name !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='name' render={(err) => { return err; }} />
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`form_field ${errors && touched.productid && errors.productid && errors.productid !== '' ? 'error' : ''}`}>
                                        <div className="field-left"> <label>Product ID</label> </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Product ID" name="productid" value={values.productid} id={'productid'} />
                                            {errors && touched.productid && errors.productid && errors.productid !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='productid' render={(err) => { return err; }} />
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`form_field ${errors && touched.planid && errors.planid && errors.planid !== '' ? 'error' : ''}`}>
                                        <div className="field-left"> <label>Plan ID</label> </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Plan ID" name="planid" value={values.planid} id={'planid'} />
                                            {errors && touched.planid && errors.planid && errors.planid !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='planid' render={(err) => { return err; }} />
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`form_field ${errors && touched.cost && errors.cost && errors.cost !== '' ? 'error' : ''}`}>
                                        <div className="field-left"> <label>Cost</label> </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Cost" name="cost" value={values.cost} maxLength="3" id={'cost'} />
                                            {errors && touched.cost && errors.cost && errors.cost !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='cost' render={(err) => { return err; }} />
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left"> <label>Is Active ?</label> </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="yes-text"
                                                    defaultValue={values.active}
                                                    defaultChecked={values.active != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 1)} />
                                                <label htmlFor="yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="no-text"
                                                    defaultValue={values.active}
                                                    defaultChecked={values.active == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 0)} />
                                                <label htmlFor="no-text">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <Notification
                                        isError={isError}
                                        message={message}
                                        showNotification={showNotification}
                                        clearNotification={handleClearNotification.bind(this)}
                                        closeNotification={handleClearNotification.bind(this)}
                                    />
                                    <div className="form-submit">
                                        <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" onClick={() => { setButtonValue(true) }} />
                                        </div>
                                        {props.history.location.state.paypalsubscriptionIdEdit == 0 ?
                                            <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                                <input type="submit" value={IsLoading ? "Saving..." : "save & add new"} name="save & add new" onClick={() => setButtonValue(false)} />
                                            </div> : null}
                                        <div className="button-field">
                                            <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )
                    }}
                </Formik>
            </section>
        </>
    )
}
const validatePaypalSubscriptionPlanForm = yup.object().shape({
    name: yup.string().required('Name is required'),
    productid: yup.string().required('Product ID is required'),
    planid: yup.string().required('Plan ID is required'),
    cost: yup.string().required('Cost is required'),
});


export default PaypalSubscriptionPlanForm;