/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'
import * as APIController from '../../../../../api/APIController.js';
import * as yup from 'yup';
import Notification from '../../../../../shared/components/notification/notification';
import CircularProgress from '@material-ui/core/CircularProgress';
import PageTitle from "../../../../../config/pageTitle";

const ConfigurationForm = props => {
    const initialValues = {
        brandID: '', segmentID: '', lensesTypeID: '',
        purchaseType: '', minimumQuantity: '', price: ''
    }
    const [isButtonValue, setButtonValue] = React.useState(false);

    const [dataSource, setdataSource] = React.useState([]);
    const [SegmentData, setSegmentData] = React.useState([]);
    const [LensesTypeData, setLensesTypeData] = React.useState([]);
    const [SequenceData, setSequenceData] = React.useState([]);

    const [MainLoading, setMainLoading] = React.useState(false);
    const [Loading, setLoading] = React.useState(true);
    const [SegmentLoading, setSegmentLoading] = React.useState(false);
    const [LensesTypeLoading, setLensesTypeLoading] = React.useState(false);
    const [IsLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        props.history.location.state.typeIdEdit != 0
            ? wsGetConfigurationByID(props.history.location.state.typeIdEdit)
            : wsGetBrandForDropdown();

        var orderSequenceNo = [];
        for (let i = -1; i < 10; i++) {
            orderSequenceNo.push({ ID: i + 1 });
        }
        setSequenceData(orderSequenceNo)
    }, [props])

    const wsGetConfigurationByID = (ID) => {
        APIController.GetConfigurationByID(ID)
            .then((response) => {
                if (response.error == null) {
                    initialValues.brandID = response.object.data[0].BrandID
                    initialValues.segmentID = response.object.data[0].SegmentID
                    initialValues.lensesTypeID = response.object.data[0].LenseTypeID
                    initialValues.purchaseType = response.object.data[0].PurchaseType
                    initialValues.minimumQuantity = response.object.data[0].MinimumQuantityForAnnualSupply
                    initialValues.price = response.object.data[0].Price
                    setdataSource(JSON.parse(response.object.data[0].Brands))
                    setSegmentData(JSON.parse(response.object.data[0].Segments))
                    setLensesTypeData(JSON.parse(response.object.data[0].LenseTypes))
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
                setLoading(false)
            })
    }

    const wsGetBrandForDropdown = () => {
        APIController.GetBrandForDropdown()
            .then((response) => {
                if (response.error == null) {
                    setdataSource(response.object.data)
                } else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
                setLoading(false)
            })
    }
    const wsGetSegmentForDropdown = () => {
        setSegmentLoading(true)
        APIController.GetSegmentForDropdown()
            .then((response) => {
                setLoading(false)
                if (response.error == null) {
                    setSegmentData(response.object.data)
                } else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
                setSegmentLoading(false)
            })
    }
    const wsGetLensesTypeForDropdown = () => {
        setLensesTypeLoading(true)
        APIController.GetLensesTypeForDropdown()
            .then((response) => {
                setLoading(false)
                if (response.error == null) {
                    setLensesTypeData(response.object.data)
                } else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
                setLensesTypeLoading(false)
            })
    }

    const wsAddEditConfiguration = (ID, BrandID, SegmentID, LenseTypeID, PurchaseType, MinimumQuantityForAnnualSupply, Price) => {
        setIsLoading(true)
        APIController.AddEditConfiguration(ID, BrandID, SegmentID, LenseTypeID, PurchaseType, MinimumQuantityForAnnualSupply, Price)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    handleNotification(response.object.message);
                    if (isButtonValue === true && response.object.status == 1) {
                        setTimeout(() => {
                            props.history.push({ pathname: '/supplytool/configuration/list', state: { showMessage: response.object.message } })
                        }, 4000)
                        handleNotification(response.object.message);
                    } else if (response.object.status == 1) {
                        handleNotification(response.object.message);
                    } else {
                        handleNotification(response.object.message, true);
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const [message, setMessage] = React.useState("")
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    return (
        <section className="content-right-main">
            <h1>{props.history.location.state.typeIdEdit == 0 ? <PageTitle Title="Add Configuration" /> : <PageTitle Title="Edit Configuration" />}</h1>
            <Formik
                // enableReinitialize={false}
                initialValues={initialValues}
                validationSchema={validateLensestypeForm}
                validateOnMount={false}
                onSubmit={(values) => {
                    wsAddEditConfiguration(props.history.location.state.typeIdEdit, values.brandID, values.segmentID, values.lensesTypeID, values.purchaseType, values.minimumQuantity, values.price)
                    if (isButtonValue === false) {
                        values.lensestypeName = "";
                        values.active = 1;
                    }
                }} >
                {({ values, handleSubmit, setFieldValue, errors, touched }) => {
                    return (
                        <div className="form-main">
                            <form onSubmit={handleSubmit}>
                                <div className={`form_field ${errors && touched.brandID && errors.brandID && errors.brandID !== '' ? 'error' : ''}`}>
                                    <div className="field-left">
                                        <label>Brand</label>
                                    </div>
                                    <div className="field-right">
                                        {Loading ? <CircularProgress style={{ alignItems: 'center' }} />
                                            : <select name='brandID'
                                                defaultValue={values.brandID}
                                                value={values.brandID}
                                                onChange={(e) => {
                                                    setFieldValue('brandID', e.target.value);
                                                    wsGetSegmentForDropdown();
                                                }}>
                                                <option value={''}>Select Brand</option>
                                                {dataSource.map((obj, index) => {
                                                    return <option key={index} selected={obj.ID == values.brandID ? obj.ID : obj.Name} value={obj.ID} >{obj.Name}</option>
                                                })}
                                            </select>}
                                        {errors && touched.brandID && errors.brandID && errors.brandID !== '' &&
                                            (<span className='error-msg'>
                                                <ErrorMessage name='brandID' render={(err) => { return err; }} />
                                            </span>)}
                                    </div>
                                </div>

                                <div className={`form_field ${errors && touched.segmentID && errors.segmentID && errors.segmentID !== '' ? 'error' : ''}`}>
                                    <div className="field-left">
                                        <label>Segment</label>
                                    </div>
                                    <div className="field-right">
                                        {SegmentLoading ? <CircularProgress style={{ alignItems: 'center' }} />
                                            : <select name='segmentID'
                                                defaultValue={values.segmentID}
                                                value={values.segmentID}
                                                onChange={(e) => {
                                                    setFieldValue('segmentID', e.target.value);
                                                    wsGetLensesTypeForDropdown();
                                                }}>
                                                <option value={''}>Select Segment</option>
                                                {SegmentData.map((obj, index) => {
                                                    return <option key={index} selected={obj.ID == values.segmentID ? obj.ID : obj.Name} value={obj.ID} >{obj.Name}</option>
                                                })}
                                            </select>}
                                        {errors && touched.segmentID && errors.segmentID && errors.segmentID !== '' &&
                                            (<span className='error-msg'>
                                                <ErrorMessage name='segmentID' render={(err) => { return err; }} />
                                            </span>)}
                                    </div>
                                </div>

                                <div className={`form_field ${errors && touched.lensesTypeID && errors.lensesTypeID && errors.lensesTypeID !== '' ? 'error' : ''}`}>
                                    <div className="field-left">
                                        <label>Lenses Type</label>
                                    </div>
                                    <div className="field-right">
                                        {LensesTypeLoading ? <CircularProgress style={{ alignItems: 'center' }} />
                                            : <select name='lensesTypeID'
                                                defaultValue={values.lensesTypeID}
                                                value={values.lensesTypeID}
                                                onChange={(e) => {
                                                    setFieldValue('lensesTypeID', e.target.value);
                                                }}>
                                                <option value={''}>Select Lenses Type</option>
                                                {LensesTypeData.map((obj, index) => {
                                                    return <option key={index} selected={obj.ID == values.lensesTypeID ? obj.ID : obj.Name} value={obj.ID} >{obj.Name}</option>
                                                })}
                                            </select>}
                                        {errors && touched.lensesTypeID && errors.lensesTypeID && errors.lensesTypeID !== '' &&
                                            (<span className='error-msg'>
                                                <ErrorMessage name='lensesTypeID' render={(err) => { return err; }} />
                                            </span>)}
                                    </div>
                                </div>

                                <div className={`form_field ${errors && touched.purchaseType && errors.purchaseType && errors.purchaseType !== '' ? 'error' : ''}`}>
                                    <div className="field-left">
                                        <label>Purchase Type</label>
                                    </div>
                                    <div className="field-right">
                                        <select name='purchaseType'
                                            defaultValue={values.purchaseType}
                                            value={values.purchaseType}
                                            onChange={(e) => {
                                                setFieldValue('purchaseType', e.target.value);
                                            }}>
                                            <option value={''}>Select Purchase Type</option>
                                            <option value={1}>New Purchase</option>
                                            <option value={2}>Repurchase</option>
                                        </select>
                                        {errors && touched.purchaseType && errors.purchaseType && errors.purchaseType !== '' &&
                                            (<span className='error-msg'>
                                                <ErrorMessage name='purchaseType' render={(err) => { return err; }} />
                                            </span>)}
                                    </div>
                                </div>

                                <div className={`form_field ${errors && touched.minimumQuantity && errors.minimumQuantity && errors.minimumQuantity !== '' ? 'error' : ''}`}>
                                    <div className="field-left">
                                        <label>Minimum Quantity</label>
                                    </div>
                                    <div className="field-right">
                                        <select name='minimumQuantity'
                                            defaultValue={values.minimumQuantity}
                                            value={values.minimumQuantity}
                                            onChange={(e) => {
                                                setFieldValue('minimumQuantity', e.target.value);
                                            }}>
                                            <option value={''}>Minimum Quantity For Annual Supply</option>
                                            {SequenceData.map((obj, index) => {
                                                return <option selected={obj.ID == values.minimumQuantity ? obj.ID : obj.ID} value={obj.ID} key={index}>{obj.ID}</option>
                                            })}
                                        </select>
                                        {errors && touched.minimumQuantity && errors.minimumQuantity && errors.minimumQuantity !== '' &&
                                            (<span className='error-msg'>
                                                <ErrorMessage name='minimumQuantity' render={(err) => { return err; }} />
                                            </span>)}
                                    </div>
                                </div>

                                <div className={`form_field ${errors && touched.price && errors.price && errors.price !== '' ? 'error' : ''}`}>
                                    <div className="field-left">
                                        <label>Price</label>
                                    </div>
                                    <div className="field-right">
                                        <Field type="text" placeholder="Enter price" name="price" value={values.price} maxLength="6" />
                                        {errors && touched.price && errors.price && errors.price !== '' &&
                                            (<span className='error-msg'>
                                                <ErrorMessage name='price' render={(err) => { return err; }} />
                                            </span>)}
                                    </div>
                                </div>

                                <Notification
                                    isError={isError}
                                    message={message}
                                    showNotification={showNotification}
                                    clearNotification={handleClearNotification.bind(this)}
                                    closeNotification={handleClearNotification.bind(this)}
                                />
                                <div className="form-submit">
                                    <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                        <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" onClick={() => setButtonValue(true)} />
                                    </div>
                                    {props.history.location.state.countryIdEdit == 0 ?
                                        <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type="submit" value={IsLoading ? "Saving..." : "save & add new"} name="save & add new" onClick={() => setButtonValue(false)} />
                                        </div> : null}
                                    <div className="button-field ">
                                        <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    )
                }}
            </Formik>
        </section>
    )
}
const validateLensestypeForm = yup.object().shape({
    brandID: yup.string().required('Brand is required'),
    segmentID: yup.string().required('Segment is required'),
    lensesTypeID: yup.string().required('Lenses Type is required'),
    purchaseType: yup.string().required('Purchase Type is required'),
    minimumQuantity: yup.string().required('Minimum Quantity is required'),
    price: yup.string().required('Price is required'),
});

export default ConfigurationForm;