/*eslint-disable eqeqeq*/
import React from 'react';
import * as APIController from '../../../../api/APIController.js';

import Notification from '../../../../shared/components/notification/notification';
import CircularProgress from '@material-ui/core/CircularProgress';

import PerfectScrollbar from "react-perfect-scrollbar";

import viewIcon from '../../../../assets/images/view_icon.png'
import settingIcon from "../../../../assets/images/settings.svg";

import Modal from '../../../../shared/components/modal/modal';
import ConfirmtionDialog from '../../../ConfirmDialog/ConfirmationDialog';
import close_icon from "../../../../assets/images/close_icon.svg";
import AssetViewer from './assetViewer';

const TemplatePopupList = props => {
    const [openDialog, setOpenDialog] = React.useState(false);
    const [viewDialog, setViewDialog] = React.useState(false);
    const [HTMLContent, setHTMLContent] = React.useState(false);

    const [childrensData, setchildrensData] = React.useState([]);

    React.useEffect(() => {
        setchildrensData(props.brandsData)
    }, [props])


    return (
        <section className="content-right-main campaign-page" style={{ width: '100%' }}>
            <h2>{props.title}</h2>
            <div className="upload-asset-main">
                <div className="asset-tab-right">
                    <div className="asset-table-head">
                        <div style={{ width: '12%', textAlign: 'center' }} className="asset-table-width-17"><h5>Action</h5></div>
                        <div style={{ width: '58%' }} className="asset-table-width-1"><h5>Template Name</h5></div>
                        <div style={{ width: '28%' }} className="asset-table-width-22"><h5>Brand</h5></div>
                    </div>
                    {<PerfectScrollbar style={{ maxHeight: '500px' }}>
                        {!!childrensData.length ? childrensData.map((obj, index) => {
                            return (
                                <div className="asset-table-body" key={index}>
                                    <div style={{ width: '13%', textAlign: 'center', paddingRight: '10px' }} className="asset-table-width-17">
                                        <a style={{ cursor: 'pointer' }} title="" className="small-ic"
                                            onClick={(e) => { setOpenDialog(true); setHTMLContent(obj.HTMLContent); }}>
                                            <img src={viewIcon} alt="icon" />
                                        </a>
                                    </div>
                                    <div style={{ width: '58%', textAlign: 'left' }} className="asset-table-width-1"><p>{obj.TemplateName}</p></div>
                                    <div style={{ width: '29%' }} className="asset-table-width-22"><p>{obj.Brand}</p></div>
                                </div>);
                        })
                            : <div style={{ textAlign: "center", marginTop: '2%' }}>
                                <label>Not Currently Available</label>
                            </div>}
                    </PerfectScrollbar>}
                </div>
            </div>
            {openDialog && (
                <Modal
                    show={openDialog}
                    handleClose={() => setOpenDialog(false)}>
                    <div className='popup-wrap'>
                        <AssetViewer
                            title={"View Content"}
                            HTMLContent={HTMLContent}
                            closeModal={() => setOpenDialog(false)} />
                        <img src={close_icon} alt='icon' className='gray-close-ic' onClick={() => setOpenDialog(false)} style={{ cursor: 'pointer' }} />
                    </div>
                </Modal>
            )}

        </section>

    )
}
export default TemplatePopupList;