/*eslint-disable eqeqeq*/
import React from 'react'
import BudgetCampaignDuration from '../component/budgetDetail';
import * as AppConstants from "../../../config/AppConstant";
import * as APIController from '../../../api/APIController.js';
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";

class BudgetDetailController extends React.Component {
    state = {
        budgetDetail: [{ budget: '', duration: '', }],
        MonthlyBudgetData: [],
        CampaignProducts: [],
        localCampaignProducts: AppConstants.GetLocalStorage("localStorageCampaignProducts"),
        isLoading: false
    }

    componentDidMount() {
        if (AppConstants.GetLocalStorage("localStorageBudgetDetail") != undefined) {
            let data = JSON.parse(AppConstants.GetLocalStorage("localStorageBudgetDetail"));
            this.setState(this.state.budgetDetail.map((item, index) => item.duration = data[0].duration))
            this.setState(this.state.budgetDetail.map((item, index) => item.budget = data[0].budget))
        }
        this.wsGetCampaignProducts(AppConstants.GetLocalStorage("localStorageCampaignID"))
    }

    wsGetCampaignProducts = (CampaignID) => {
        this.setState({ isLoading: true })
        APIController.GetCampaignProducts(CampaignID, 1)
            .then((response) => {
                let Temp = []
                let selectedIDs = AppConstants.GetLocalStorage("localStorageCampaignProducts") != "" ? JSON.parse(AppConstants.GetLocalStorage("localStorageCampaignProducts")) : []
                if (selectedIDs.length == 0) {
                    response.object.data.map((item) => {
                        Temp.push({ Checked: false, ID: item.ID, Name: item.Name, SelectedBudget: "", AllBudget: [] })
                    })
                } else {
                    response.object.data.map((item) => {
                        const dummyArray = selectedIDs.filter((e) => e.ProductID == item.ID);
                        Temp.push({
                            Checked: dummyArray.length ? true : false,
                            ID: item.ID,
                            Name: item.Name,
                            SelectedBudget: dummyArray.length ? dummyArray[0].MonthlyBudget == undefined ? dummyArray[0].SelectedBudget : dummyArray[0].MonthlyBudget : '',
                            AllBudget: dummyArray.length ? dummyArray[0].AllBudget : []
                        })
                    })
                }
                this.setState({ CampaignProducts: Temp, isLoading: false })
            })
    }

    wsGetMonthlyBudgetByProductID = (ProductID) => {
        this.setState({ isLoading: true })
        APIController.GetMonthlyBudgetByProductID(ProductID)
            .then((response) => { return response.object.data })
    }


    handleBudgetDetailSelection = (duration, budget, campaignProducts, campaignStartDate, campaignEndDate) => {
        //Set Value Of budget
        let data = this.state.budgetDetail;
        data[0].budget = budget
        data[0].duration = duration

        this.setState({ budgetDetail: data })
        AppConstants.SetLocalStorage("localStorageBudgetDetail", JSON.stringify(data))

        let tempData = []
        campaignProducts.map((item) => { item.Checked && tempData.push({ ProductID: item.ID, Budget: item.SelectedBudget.replace("$", "").replaceAll(",", ""), Checked: item.Checked, AllBudget: item.AllBudget, SelectedBudget: item.SelectedBudget }) })
        AppConstants.SetLocalStorage("localStorageCampaignProducts", JSON.stringify(tempData))
        AppConstants.SetLocalStorage("localStorageCampaignStartDate", campaignStartDate)
        AppConstants.SetLocalStorage("localStorageCampaignEndDate", campaignEndDate)

        this.props.history.push('/digital-product/selection/location-selection')

    }

    render() {
        return (
            <section className='content-right-main' >
                {
                    this.state.isLoading ? <CircularProgress />
                        : <BudgetCampaignDuration
                            budgetDetail={this.state.budgetDetail}
                            MonthlyBudgetData={this.state.MonthlyBudgetData}
                            CampaignProducts={this.state.CampaignProducts}
                            submitBudget={this.handleBudgetDetailSelection}
                            back={() => this.props.history.goBack()}
                        />
                }
            </section>
        )
    }
}

export default BudgetDetailController