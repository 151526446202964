/*eslint-disable eqeqeq*/
import React from "react";
import UserList from "../component/userlist";
// import {data} from './data'
import * as APIController from "../../../api/APIController";

import Notification from "../../../shared/components/notification/notification";

class UserContainer extends React.Component {
	state = {
		currentPage: 1,
		count: 0,
		rowsPerPage: 10,
		rowsPerPageOptions: [10, 25, 50, 'All'],
		list: [],
		list1: [],
		from: 0,
		to: 10,
		progress: true,
		message: '',
		showNotification: false,
		isError: false,
		showMessage: this.props.history.location.state != null && this.props.history.location.state.showMessage
	};
	componentDidMount() {
		this.wsGetUsers(this.state.from, this.state.to, "", "", "", "", "", "", "", this.state.currentPage, this.state.rowsPerPage, "FullName", "ASC");
		this.state.showMessage != "" && this.handleNotification(this.state.showMessage);
		this.props.history.replace("/users", null);
	}

	handlebind() {
		this.wsGetUsers(this.state.from, this.state.to, "", "", "", "", "", "", "", this.state.currentPage, this.state.rowsPerPage, "FullName", "ASC");
	}

	wsGetUsers(from, to, FullName, EmailAddress, PhoneNo, Role, Area, Region, Territory, CurrentPage, RecordPerPage, SortExpression, SortDirection) {
		this.setState({ progress: true, });
		APIController.GetUsers(2, FullName, EmailAddress, PhoneNo, Role, Area, Region, Territory, CurrentPage, RecordPerPage, SortExpression, SortDirection).then((response) => {
			if (response.error == null) {
				this.setState({
					to: isNaN(to) ? 1000000 : to,
					from: from,
					count: response.object.data[0].MaxRows,
					list: response.object.data,
					list1: response.object.data,
					progress: false,
				});
			} else if (response.error.message && response.error.message.length > 0) {
				this.handleNotification(response.error.message, true);
			} else { this.setState({ list: [], progress: false }) }
		});
	}
	render() {
		return (
			<>
				<section className='content-right-main'>
					<div className='button-field button-field-country'>
						<button onClick={() => { this.props.history.push({ pathname: "/users/add", state: { usersIdEdit: "0", usersFullNameEdit: "", usersEmailEdit: "", usersPhoneNoEdit: "", usersActiveEdit: 1, }, }); }}>
							+ Add User
						</button>
					</div>
					<UserList
						{...this.state}
						history={this.props.history}
						handlebind={this.handlebind.bind(this)}
						handleChangeRowsPerPage={this.handleRowsPerPageChange}
						handleChangePage={this.handlePageChange}
						applyFilter={this.handleApplyFilter}
					/>
				</section>
				<Notification
					isError={this.state.isError}
					message={this.state.message}
					showNotification={this.state.showNotification}
					clearNotification={this.handleClearNotification}
					closeNotification={this.handleClearNotification}
				/>
			</>
		);
	}

	handleRowsPerPageChange = (value, FullName, EmailAddress, PhoneNo, Role, Area, Region, Territory) => {
		let from = 0;
		let to = value;
		this.setState({ rowsPerPage: value, currentPage: 1, to: to, from: from });
		this.wsGetUsers(from, to, FullName, EmailAddress, PhoneNo, Role, Area, Region, Territory, isNaN(value) ? '' : this.state.currentPage, isNaN(value) ? 1000000 : value, "FullName", "ASC")
	};

	handlePageChange = (values,FullName, EmailAddress, PhoneNo, Role, Area, Region, Territory) => {
		let from = values === 1 ? 0 * this.state.rowsPerPage : (values - 1) * this.state.rowsPerPage;
		let to = values === 1 ? this.state.rowsPerPage : parseInt(from) + parseInt(this.state.rowsPerPage);
		this.setState({ currentPage: Number(values), to: to, from: from });
		this.wsGetUsers(from, to, FullName, EmailAddress, PhoneNo, Role, Area, Region, Territory, Number(values), this.state.rowsPerPage, "FullName", "ASC")
	};

	/**
	 * handleApplyFilter method is used to apply filter on every columns
	 */
	handleApplyFilter = (tag, values, type, FullName, EmailAddress, PhoneNo, Role, Area, Region, Territory) => {
		let searchUsers = [];

		if (type === "select") {
			searchUsers = this.state.list1.filter(
				(us) => us[tag].toLowerCase() === values.toLowerCase()
			);

			this.setState({
				list: searchUsers,
				// currentPage: 1,
				count: searchUsers.length,
				// rowsPerPage: 10,
				// rowsPerPageOptions: [10, 25, 50, 'All'],
				// from: 0,
				// to: 10,
				progress: false,
			});
		} else {
			this.wsGetUsers(this.state.from, this.state.to, FullName, EmailAddress, PhoneNo, Role, Area, Region, Territory, this.state.currentPage, this.state.rowsPerPage, "FullName", "ASC");
		}
	};
	handleNotification = (message, isError) => {
		this.setState({
			message: message,
			showNotification: true,
			isError: isError,
		});

		setTimeout(() => {
			this.handleClearNotification();
		}, 4000);
	};

	handleClearNotification = () => {
		this.setState({
			message: "",
			showNotification: false,
			isError: false,
		});
	};
}

export default UserContainer;
