/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'
import * as APIController from '../../../../api/APIController.js';
import * as yup from 'yup';

import uuid from 'react-uuid';
import file_upload_ic from "../../../../assets/images/file-upload-ic.svg";
import close_icon from "../../../../assets/images/close_icon.svg";
import CircularProgress from '@material-ui/core/CircularProgress';

import Editor from '../../../campaign/components/editor';

import Notification from '../../../../shared/components/notification/notification';

const BrandForm = props => {
    let initialValues = {
        brandname: "",
        // branddescription: props.history.location.state.brandDescriptionEdit,
        imagename: "",
        brandlogo: "",
        brandorderno: "",
        active: 1,
        imageconvert: "",
        showInTile: 0,
        showInAutomation: 0,
        automationOrderNo: 0,
        showInLeftNav: 0,
        landingPageOrderNo: 0,
        myLandingPagesName: ''

    }
    const [isButtonValue, setButtonValue] = React.useState(false);
    const [IsLoading, setIsLoading] = React.useState(false);
    const [IsLoadingData, setIsLoadingData] = React.useState(true);
    const [description, setDescription] = React.useState('');
    const [orderData, setOrderData] = React.useState([]);

    React.useEffect(() => {
        if (props.history.location.state.brandIdEdit != 0) {
            wsGetBrandByID(props.history.location.state.brandIdEdit)
        } else {
            setDescription(props.history.location.state.brandDescriptionEdit)
            setIsLoadingData(false)
        }
        var pairs = [];
        for (let i = 0; i < 100; i++) {
            pairs.push({ ID: i + 1 });
        }
        setOrderData(pairs)
    }, [props])

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    const wsAddEditBrand = (ID, Name, Description, ImageName, ImageData, OrderNo, IsActive, ShowInAutomation, AutomationOrderNo, ShowInLeftNav, LandingPageOrderNo, ShowInTile, MyLandingPagesName) => {
        setIsLoading(true)
        APIController.AddEditBrand(ID, Name, Description, ImageName, ImageData, OrderNo, IsActive, ShowInAutomation, AutomationOrderNo, ShowInLeftNav, LandingPageOrderNo, ShowInTile, MyLandingPagesName)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    if (isButtonValue === true && response.object.status == 1) {
                        props.history.push({ pathname: '/brand', state: { showMessage: response.object.message } })
                    } else if (response.object.status == 1) {
                        handleNotification(response.object.message);
                    } else {
                        handleNotification(response.object.message, true);
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }
    const wsGetBrandByID = (ID) => {
        setIsLoadingData(true)
        APIController.GetBrandByID(ID)
            .then((response) => {
                if (response.object.data.length) {
                    let initialValuesTemp = initialValues;

                    initialValuesTemp.brandname = response.object.data[0].Name
                    initialValuesTemp.imagename = response.object.data[0].ImageName
                    initialValuesTemp.brandlogo = response.object.data[0].ImageURL
                    initialValuesTemp.brandorderno = response.object.data[0].OrderNo
                    initialValuesTemp.active = response.object.data[0].IsActive ? 1 : 0
                    initialValuesTemp.imageconvert = response.object.data[0].ImageURL
                    initialValuesTemp.showInTile = response.object.data[0].ShowInTile ? 1 : 0
                    initialValuesTemp.showInAutomation = response.object.data[0].ShowInAutomation ? 1 : 0
                    initialValuesTemp.automationOrderNo = handleNull(response.object.data[0].AutomationOrderNo) ? response.object.data[0].AutomationOrderNo : 0
                    initialValuesTemp.showInLeftNav = response.object.data[0].ShowInLeftNav ? 1 : 0
                    initialValuesTemp.landingPageOrderNo = handleNull(response.object.data[0].LandingPageOrderNo) ? response.object.data[0].LandingPageOrderNo : 0
                    initialValuesTemp.myLandingPagesName = response.object.data[0].MyLandingPagesName
                    setDescription(response.object.data[0].Description)

                    initialValues = initialValuesTemp
                    setIsLoadingData(false)
                } else {
                    handleNotification(response.object.message, true);
                    setIsLoadingData(false)
                }
            })
    }

    const handleNull = (value) => {
        if (value != "" && value != null && value != undefined) {
            return true
        } else {
            return false
        }
    }

    return (
        <>
            <section className="content-right-main">
                {props.history.location.state.brandIdEdit != 0 ? <h1>Edit Brand</h1> : <h1>Add Brand</h1>}
                <Formik
                    enableReinitialize={false}
                    initialValues={initialValues}
                    validationSchema={validateProductForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        if (description == '') {
                            handleNotification("Description is required", true);
                        } else if (values.showInAutomation && values.automationOrderNo == 0) {
                            handleNotification("Automation Order No is required", true);
                        } else {
                            wsAddEditBrand(props.history.location.state.brandIdEdit, values.brandname, description, values.imagename,
                                values.brandlogo, values.brandorderno, values.active, values.showInAutomation, values.automationOrderNo,
                                values.showInLeftNav, values.landingPageOrderNo, values.showInTile, values.myLandingPagesName)
                            if (isButtonValue === false) {
                                values.brandname = "";
                                values.imageconvert = "";
                                values.brandlogo = "";
                                values.brandorderno = "";
                                values.active = true;
                                values.showInTile = 0;
                                values.showInAutomation = 0;
                                values.automationOrderNo = 0;
                                values.showInLeftNav = 0;
                                values.landingPageOrderNo = 0;
                                values.myLandingPagesName = '';
                                setDescription('')
                            }
                        }
                    }} >
                    {({ values, handleSubmit, setFieldValue, errors, touched }) => {
                        return (
                            IsLoadingData ?
                                <CircularProgress style={{ alignItems: 'center' }} />
                                : <div className="form-main">
                                    <form onSubmit={handleSubmit} style={{ maxWidth: '100%' }}>
                                        <div className={`form_field ${errors && touched.brandname && errors.brandname && errors.brandname !== '' ? 'error' : ''}`}>
                                            <div className="field-left">
                                                <label>Name</label>
                                            </div>
                                            <div className="field-right">
                                                <Field type="text" placeholder="Enter Brand" name="brandname" value={values.brandname} maxLength="50" />
                                                {errors && touched.brandname && errors.brandname && errors.brandname !== '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage name='brandname' render={(err) => { return err; }} />
                                                    </span>)}
                                            </div>
                                        </div>

                                        <div className='form_field'>
                                            <label style={{ marginBottom: '-40px' }}>Description</label>
                                            <div className="asset-full" style={{ padding: '0px', marginBottom: '-0px' }}>
                                                <Editor content={description} setContent={setDescription} />
                                            </div>
                                        </div>

                                        <div className={`form_field ${errors && touched.imageconvert && errors.imageconvert && errors.imageconvert !== '' ? 'error' : ''}`}>
                                            <div className='field-left'>
                                                <label> Logo</label>
                                            </div>
                                            <div className='field-right'>
                                                <div className='form-file-input'>
                                                    <input className='input-file' id='fileupload' name='files' type='file'
                                                        onChange={(e) => {
                                                            const file = e.target.files[0];
                                                            if (file && (file.type.includes("svg") || file.type.includes("png") || file.type.includes("jpeg") || file.type.includes("jpg"))) {
                                                                let file1 = e.target.files[0]
                                                                file1['preview'] = URL.createObjectURL(e.target.files[0]);

                                                                setFieldValue("imagename", values.imagename == "" ?
                                                                    uuid() + "." + file.type.split('/')[1] :
                                                                    values.imagename.split('.')[0] + "." + file.type.split('/')[1]);

                                                                const image2base64 = require('image-to-base64');
                                                                image2base64(file1.preview)
                                                                    .then((response) => { setFieldValue("brandlogo", response); })
                                                                    .catch((error) => { })
                                                                setFieldValue("imageconvert", file1.preview);
                                                            }
                                                        }}
                                                    />
                                                    {errors && touched.imageconvert && errors.imageconvert && errors.imageconvert !== '' && (
                                                        <span className='error-msg'>
                                                            <ErrorMessage name='imageconvert' render={(err) => { return err; }} />
                                                        </span>)}
                                                    {values.imageconvert == '' ?
                                                        <label htmlFor='fileupload' className='input-file-trigger'>
                                                            <img src={file_upload_ic} alt='file-uplaod' />
                                                            {'Drag and drop or Click here to upload file'}
                                                        </label> : <div className='logo-preview'>
                                                            <img src={close_icon} alt="close-ic" className="close-ic" onClick={() => setFieldValue("imageconvert", '')} style={{ cursor: 'pointer', marginRight: '3px' }} />
                                                            <img src={values.imageconvert} alt='file-uplaod' />
                                                        </div>}
                                                </div>
                                                <p> Min image size: 650X800 px. Accepted files : .jpg, .jpeg, .png, .svg </p>
                                            </div>
                                        </div>

                                        <div className={`form_field ${errors && touched.brandorderno && errors.brandorderno && errors.brandorderno !== '' ? 'error' : ''}`}>
                                            <div className="field-left">
                                                <label>Sequence #</label>
                                            </div>
                                            <div className="field-right">
                                                {/* <Field type="text" placeholder="Enter Sequence #" name="brandorderno" value={values.brandorderno} maxLength="50" /> */}
                                                <select
                                                    id={'brandorderno'}
                                                    name="reportType"
                                                    defaultValue={values.brandorderno}
                                                    value={values.brandorderno}
                                                    onChange={(e) => setFieldValue('brandorderno', e.target.value)}>
                                                    <option value={''}>Sequence #</option>
                                                    {orderData.map((obj, index) => {
                                                        return <option selected={obj.ID == values.brandorderno ? obj.ID : obj.ID} value={obj.ID} key={index}>{obj.ID}</option>
                                                    })}
                                                </select>
                                                {errors && touched.brandorderno && errors.brandorderno && errors.brandorderno !== '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage name='brandorderno' render={(err) => { return err; }} />
                                                    </span>)}
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ width: '50%' }}>
                                                <div className="form_field">
                                                    <div className="field-left" style={{ width: '50%' }}>
                                                        <label>Is Active ?</label>
                                                    </div>
                                                    <div className="field-right" style={{ width: '50%' }}>
                                                        <div className="custom-radio">
                                                            <input type="radio" name="active" id="yes-text"
                                                                defaultValue={values.active}
                                                                defaultChecked={values.active != 0 ? true : false}
                                                                onClick={(e) => setFieldValue('active', 1)} />
                                                            <label htmlFor="yes-text">Yes</label>
                                                        </div>
                                                        <div className="custom-radio">
                                                            <input type="radio" name="active" id="no-text"
                                                                defaultValue={values.active}
                                                                defaultChecked={values.active == 0 ? true : false}
                                                                onClick={(e) => setFieldValue('active', 0)} />
                                                            <label htmlFor="no-text">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <div className="form_field">
                                                    <div className="field-left" style={{ width: '50%' }}>
                                                        <label>Show In Tile ?</label>
                                                    </div>
                                                    <div className="field-right" style={{ width: '50%' }}>
                                                        <div className="custom-radio">
                                                            <input type="radio" name="showInTile" id="yes-textshowInTile"
                                                                defaultValue={values.showInTile}
                                                                defaultChecked={values.showInTile != 0 ? true : false}
                                                                onClick={(e) => setFieldValue('showInTile', 1)} />
                                                            <label htmlFor="yes-textshowInTile">Yes</label>
                                                        </div>
                                                        <div className="custom-radio">
                                                            <input type="radio" name="showInTile" id="no-textshowInTile"
                                                                defaultValue={values.showInTile}
                                                                defaultChecked={values.showInTile == 0 ? true : false}
                                                                onClick={(e) => setFieldValue('showInTile', 0)} />
                                                            <label htmlFor="no-textshowInTile">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ display: 'flex' }}>
                                            <div style={{ width: '50%' }}>
                                                <div className="form_field">
                                                    <div className="field-left" style={{ width: '50%' }}>
                                                        <label>Show In Automation ?</label>
                                                    </div>
                                                    <div className="field-right" style={{ width: '50%' }}>
                                                        <div className="custom-radio">
                                                            <input type="radio" name="showInAutomation" id="yes-textAutomation"
                                                                defaultValue={values.showInAutomation}
                                                                defaultChecked={values.showInAutomation != 0 ? true : false}
                                                                onClick={(e) => setFieldValue('showInAutomation', 1)} />
                                                            <label htmlFor="yes-textAutomation">Yes</label>
                                                        </div>
                                                        <div className="custom-radio">
                                                            <input type="radio" name="showInAutomation" id="no-textAutomation"
                                                                defaultValue={values.showInAutomation}
                                                                defaultChecked={values.showInAutomation == 0 ? true : false}
                                                                onClick={(e) => setFieldValue('showInAutomation', 0)} />
                                                            <label htmlFor="no-textAutomation">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {values.showInAutomation ?
                                                <div style={{ width: '50%' }}>
                                                    <div className={`form_field ${errors && touched.automationOrderNo && errors.automationOrderNo && errors.automationOrderNo !== '' ? 'error' : ''}`}>
                                                        <div className="field-left" style={{ width: '45%' }}>
                                                            <label>Automation Order No #</label>
                                                        </div>
                                                        <div className="field-right" style={{ width: '55%' }}>
                                                            {/* <Field type="text" placeholder="Enter Sequence #" name="automationOrderNo" value={values.automationOrderNo} maxLength="50" /> */}
                                                            <select
                                                                id={'automationOrderNo'}
                                                                name="automationOrder"
                                                                defaultValue={values.automationOrderNo}
                                                                value={values.automationOrderNo}
                                                                onChange={(e) => setFieldValue('automationOrderNo', e.target.value)}>
                                                                <option value={'0'}>Automation Order No #</option>
                                                                {orderData.map((obj, index) => {
                                                                    return <option selected={obj.ID == values.automationOrderNo ? obj.ID : obj.ID} value={obj.ID} key={index}>{obj.ID}</option>
                                                                })}
                                                            </select>
                                                            {errors && touched.automationOrderNo && errors.automationOrderNo && errors.automationOrderNo !== '' && (
                                                                <span className='error-msg'>
                                                                    <ErrorMessage name='automationOrderNo' render={(err) => { return err; }} />
                                                                </span>)}
                                                        </div>
                                                    </div>
                                                </div> : <></>}
                                        </div>

                                        <div style={{ display: 'flex' }}>
                                            <div style={{ width: '50%' }}>
                                                <div className="form_field">
                                                    <div className="field-left" style={{ width: '50%' }}>
                                                        <label>Show In LeftNav ?</label>
                                                    </div>
                                                    <div className="field-right" style={{ width: '50%' }}>
                                                        <div className="custom-radio">
                                                            <input type="radio" name="showInLeftNav" id="yes-textLeftNav"
                                                                defaultValue={values.showInLeftNav}
                                                                defaultChecked={values.showInLeftNav != 0 ? true : false}
                                                                onClick={(e) => setFieldValue('showInLeftNav', 1)} />
                                                            <label htmlFor="yes-textLeftNav">Yes</label>
                                                        </div>
                                                        <div className="custom-radio">
                                                            <input type="radio" name="showInLeftNav" id="no-textLeftNav"
                                                                defaultValue={values.showInLeftNav}
                                                                defaultChecked={values.showInLeftNav == 0 ? true : false}
                                                                onClick={(e) => setFieldValue('showInLeftNav', 0)} />
                                                            <label htmlFor="no-textLeftNav">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <div className={`form_field ${errors && touched.landingPageOrderNo && errors.landingPageOrderNo && errors.landingPageOrderNo !== '' ? 'error' : ''}`}>
                                                    <div className="field-left" style={{ width: '45%' }}>
                                                        <label>Landing Page Order No #</label>
                                                    </div>
                                                    <div className="field-right" style={{ width: '55%' }}>
                                                        <select
                                                            id={'landingPageOrderNo'}
                                                            name="landingPageOrder"
                                                            defaultValue={values.landingPageOrderNo}
                                                            value={values.landingPageOrderNo}
                                                            onChange={(e) => setFieldValue('landingPageOrderNo', e.target.value)}>
                                                            <option value={'0'}>Landing Page Order No #</option>
                                                            {orderData.map((obj, index) => {
                                                                return <option selected={obj.ID == values.landingPageOrderNo ? obj.ID : obj.ID} value={obj.ID} key={index}>{obj.ID}</option>
                                                            })}
                                                        </select>
                                                        {errors && touched.landingPageOrderNo && errors.landingPageOrderNo && errors.landingPageOrderNo !== '' && (
                                                            <span className='error-msg'>
                                                                <ErrorMessage name='landingPageOrderNo' render={(err) => { return err; }} />
                                                            </span>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`form_field ${errors && touched.myLandingPagesName && errors.myLandingPagesName && errors.myLandingPagesName !== '' ? 'error' : ''}`}>
                                            <div className="field-left">
                                                <label>My Landing Pages Name</label>
                                            </div>
                                            <div className="field-right">
                                                <Field type="text" placeholder="Enter My Landing Pages Name" name="myLandingPagesName" value={values.myLandingPagesName} maxLength="50" />
                                                {errors && touched.myLandingPagesName && errors.myLandingPagesName && errors.myLandingPagesName !== '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage name='myLandingPagesName' render={(err) => { return err; }} />
                                                    </span>)}
                                            </div>
                                        </div>

                                        <Notification
                                            isError={isError}
                                            message={message}
                                            showNotification={showNotification}
                                            clearNotification={handleClearNotification.bind(this)}
                                            closeNotification={handleClearNotification.bind(this)}
                                        />
                                        <div className="form-submit">
                                            <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                                <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" onClick={() => setButtonValue(true)} />
                                            </div>
                                            {props.history.location.state.brandIdEdit == 0 ?
                                                <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                                    <input type="submit" value={IsLoading ? "Saving..." : "save & add new"} name="save & add new" onClick={() => setButtonValue(false)} />
                                                </div> : null}
                                            <div className="button-field ">
                                                <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                        )
                    }}
                </Formik>

            </section >
        </>
    )
}
const validateProductForm = yup.object().shape({
    brandname: yup.string().required('Name is required'),
    imageconvert: yup.string().required('Logo is required'),
    brandorderno: yup.string().required('Sequence # is required'),
    automationOrderNo: yup.string().required('Automation Order No is required'),
    myLandingPagesName: yup.string().required('My Landing Pages Name is required'),
});

export default BrandForm;
