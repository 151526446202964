/*eslint-disable eqeqeq*/
import React from 'react';
import ImportedpracticesList from '../../component/componentImportedPractices/importedpracticeslist';
// import {data} from './data'
import * as APIController from '../../../../api/APIController';
// import * as APIController from '../../../../src/api/APIController';
import CircularProgress from '@material-ui/core/CircularProgress';

import Notification from "../../../../shared/components/notification/notification";
class AdminImportedPracticesContainer extends React.Component {

    state = {
        currentPage: 1,
        count: 0,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 'All'],
        list: [],
        from: 0,
        to: 10,
        progress: true,
        message: "",
        showNotification: false,
        isError: false,
        showMessage:
            this.props.history.location.state != null &&
            this.props.history.location.state.showMessage,
    }

    componentDidMount() {
        this.wsGetImportedPractices(this.state.currentPage, this.state.rowsPerPage, this.state.from, this.state.to);
        this.state.showMessage != "" &&
            this.handleNotification(this.state.showMessage);
        // this.props.history.replace("/clinic/importedpractices", null);
    }

    wsGetImportedPractices(CurrentPage, RecordPerPage, from, to, PracticeName, Address1, Address2, City, State, ZIPCode, BillTo, VTM, Territory, ShipTo, VisionSource, VSP, IsAccountCreated) {
        this.setState({ progress: true, });
        APIController.GetImportedPractices(CurrentPage, RecordPerPage, PracticeName, Address1, Address2, City, State, ZIPCode, BillTo, VTM, Territory, ShipTo, VisionSource, VSP, IsAccountCreated)
            .then((response) => {
                if (response.error == null && response.object.data.length != 0) {
                    this.setState({
                        to: isNaN(to) ? 1000000 : to,
                        // rowsPerPage: isNaN(to) ? 1000000 : to,
                        from: from,
                        count: response.object.data[0].MaxRows,
                        list: response.object.data,
                        progress: false,
                    })
                }
                else if (response.object.message && response.object.message.length > 0) {
                    // ToastsStore.warning(response.error.message)
                } else { this.setState({ list: [], progress: false }) }
            })
    }

    render() {
        return (
            <div>
                <section className="content-right-main">
                    <div className="button-field button-field-country">
                        <button onClick={() => {
                            this.props.history.push({
                                pathname: '/admin/uploadexcelsheet',
                                state: {
                                    ImportedPracticeIdEdit: '0',
                                    practiceNameEdit: '',
                                    Address1Edit: '',
                                    Address2Edit: '',
                                    CityEdit: '',
                                    StateEdit: '',
                                    BillToEdit: '',
                                    VTMEdit: '',
                                    TerritoryEdit: '',
                                    ShipToEdit: '',
                                    ZIPCodeEdit: '',
                                    CustomerEdit: '',
                                    VSPEdit: '',
                                    ISAccountCreatedLabelEdit: '',
                                    ImportedByEdit: '',
                                    ImportedAtEdit: '',
                                    MaxRowsEdit: '',
                                }
                            })
                        }}>+ Import Practices</button>
                    </div>
                    <ImportedpracticesList
                        {...this.state}
                        history={this.props.history}
                        handleChangeRowsPerPage={this.handleRowsPerPageChange}
                        handleChangePage={this.handlePageChange}
                        applyFilter={this.handleApplyFilter}
                    />
                </section>
                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    clearNotification={this.handleClearNotification}
                    closeNotification={this.handleClearNotification}
                />
            </div>
        )
    }

    handleRowsPerPageChange = (value, PracticeNameText, Address1Text, Address2Text, CityText, StateText, ZIPCodeText, BillToText, VTMText, TerritoryText, ShipToText, VisionSourceText, VSPText, IsAccountCreated) => {
        let from = 0
        let to = value;
        this.setState({ rowsPerPage: value, currentPage: 1, to: to, from: from })
        this.wsGetImportedPractices(isNaN(value) ? '' : this.state.currentPage, isNaN(value) ? 1000000 : value, from, to, PracticeNameText, Address1Text, Address2Text, CityText, StateText, ZIPCodeText, BillToText, VTMText, TerritoryText, ShipToText, VisionSourceText, VSPText, IsAccountCreated);
    }

    handlePageChange = (values, PracticeNameText, Address1Text, Address2Text, CityText, StateText, ZIPCodeText, BillToText, VTMText, TerritoryText, ShipToText, VisionSourceText, VSPText, IsAccountCreated) => {

        let from = values === 1 ? 0 * this.state.rowsPerPage : (values - 1) * this.state.rowsPerPage
        let to = values === 1 ? this.state.rowsPerPage : (from + this.state.rowsPerPage);
        this.setState({ currentPage: Number(values) })
        this.wsGetImportedPractices(Number(values), this.state.rowsPerPage, from, to, PracticeNameText, Address1Text, Address2Text, CityText, StateText, ZIPCodeText, BillToText, VTMText, TerritoryText, ShipToText, VisionSourceText, VSPText, IsAccountCreated);
    }

    handleApplyFilter = (PracticeNameText, Address1Text, Address2Text, CityText, StateText, ZIPCodeText, BillToText, VTMText, TerritoryText, ShipToText, VisionSourceText, VSPText, IsAccountCreated) => {
        this.wsGetImportedPractices(this.state.currentPage, this.state.rowsPerPage, this.state.from, this.state.to, PracticeNameText, Address1Text, Address2Text, CityText, StateText, ZIPCodeText, BillToText, VTMText, TerritoryText, ShipToText, VisionSourceText, VSPText, IsAccountCreated)
    }

    handleNotification = (message, isError) => {
        this.setState({
            message: message,
            showNotification: true,
            isError: isError,
        });

        setTimeout(() => {
            this.handleClearNotification();
        }, 4000);
    };

    handleClearNotification = () => {
        this.setState({
            message: "",
            showNotification: false,
            isError: false,
        });
    };
}

export default AdminImportedPracticesContainer;