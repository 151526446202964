/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'
// import './branchForm.css'
import * as APIController from '../../../../api/APIController';
import Notification from '../../../../shared/components/notification/notification';
import * as yup from 'yup';
const BranchForm = props => {
    const [IsLoading, setIsLoading] = React.useState(false);

    const initialValues = {
        branchesname: props.history.location.state.branchesNameEdit,
        active: props.history.location.state.branchesActiveEdit,
    }
    const [isButtonValue, setButtonValue] = React.useState(false);

    const wsAddEditCompanyBranch = (ID, CompanyID, Name, IsActive) => {
        setIsLoading(true)
        APIController.AddEditCompanyBranch(ID, CompanyID, Name, IsActive)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    if (isButtonValue === true && response.object.status == 1) {
                        props.history.push({ pathname: '/branches', state: { showMessage: response.object.message } })
                    } else if (response.object.status == 1) {
                        handleNotification(response.object.message);
                    } else {
                        handleNotification(response.object.message, true);
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message);
                }
            })
    }

    const [message, setMessage] = React.useState("")
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    return (
        <>
            <section className="content-right-main">
                {props.history.location.state.branchesdEdit == 0 ? <h1>Add Branch</h1> : <h1>Edit Branch</h1>}
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validateBranchForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        wsAddEditCompanyBranch(props.history.location.state.branchesdEdit, "2", values.branchesname, values.active)
                        if (isButtonValue === false) {
                            values.branchesname = "";
                            values.active = 1;
                        }
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        setFieldValue,
                        errors,
                        touched,
                    }) => {
                        return (
                            <div className="form-main">
                                <form onSubmit={handleSubmit}>
                                    <div
                                        className={`form_field ${errors &&
                                            touched.branchesname &&
                                            errors.branchesname &&
                                            errors.branchesname !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Branch</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Branch" name="branchesname" value={values.branchesname} maxLength="50" />
                                            {errors &&
                                                touched.branchesname &&
                                                errors.branchesname &&
                                                errors.branchesname !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='branchesname'
                                                            render={(err) => { return err; }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Is Active ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="yes-text"
                                                    defaultValue={values.active}
                                                    defaultChecked={values.active != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 1)} />
                                                <label htmlFor="yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="no-text"
                                                    defaultValue={values.active}
                                                    defaultChecked={values.active == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 0)} />
                                                <label htmlFor="no-text">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <Notification
                                        isError={isError}
                                        message={message}
                                        showNotification={showNotification}
                                        clearNotification={handleClearNotification.bind(this)}
                                        closeNotification={handleClearNotification.bind(this)}
                                    />
                                    <div className="form-submit">
                                        <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" onClick={() => setButtonValue(true)} />
                                        </div>
                                        {props.history.location.state.branchesdEdit == 0 ?
                                            <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                                <input type="submit" value={IsLoading ? "Saving..." : "save & add new"} name="save & add new" onClick={() => setButtonValue(false)} />
                                            </div> : null}
                                        <div className="button-field ">
                                            <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )
                    }}
                </Formik>
            </section>
        </>
    )
}
const validateBranchForm = yup.object().shape({
    branchesname: yup.string().required('Branch is required'),
});

export default BranchForm;