/*eslint-disable eqeqeq*/
import React from 'react';
import Table from "../../../../shared/components/table/table";
import TableHead from "../../../../shared/components/table/tableHead";
import TableRow from "../../../../shared/components/table/tableRow";
import TableBody from "../../../../shared/components/table/tableBody";
import TableCell from "../../../../shared/components/table/tableCell";
import * as APIController from "../../../../api/APIController.js";
import ic_ordersuccess from "../../../../assets/images/ic_ordersuccess.png";
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";
import { Typography, Grid, } from '@material-ui/core';

class OrderSuccess extends React.Component {
    state = {
        currentPage: 0,
        count: 0,
        rowsPerPage: 25,
        rowsPerPageOptions: [25, 10, 5],
        list: [],
        order: [],
        headerList: ["Order Date", "Order #", "Product(s) Ordered", "Product Cost"],
        from: 0,
        to: 25,
        progress: false,
        message: "",
        showNotification: false,
        isError: false,
        OrderBy: '',
        showMessage:
            this.props.history.location.state != null &&
            this.props.history.location.state.showMessage,
    };

    componentDidMount() {
        this.wsGetOrderDetails(this.props.history.location.state.OrderID);
    }

    wsGetOrderDetails(ID) {
        this.setState({ progress: true, });
        APIController.GetOrderDetails(ID).then((response) => {
            if (response.error == null) {
                this.setState({
                    list: response.object.data.OrderDetails,
                    order: response.object.data.Order,
                    OrderBy: response.object.data.Order[0].OrderBy,
                    count: response.object.data.length,
                    progress: false,
                });
            } else if (response.error.message && response.error.message.length > 0) {
                this.handleNotification(response.error.message, true);
            }
        });
    }

    render() {
        return (
            <>
                <section className='content-right-main'>
                    <h1>Thank you for your order!</h1>
                    <div className="table-main-wrap countrys-class">
                        <Grid container direction='row' justify='flex-start' alignItems='flex-start' style={{ marginBottom: '3%' }}>
                            <Grid style={{ width: '10%' }}>
                                <img src={ic_ordersuccess} alt='icon' />
                            </Grid>
                            <Grid container direction='column' justify='flex-start' alignItems='flex-start' style={{ width: '80%', marginLeft: '3%' }}>
                                <Typography style={{ color: '#0A0A0A', fontSize: '34px', fontWeight: 700, }}>Hey {this.state.OrderBy},</Typography>
                                <Typography style={{ color: '#0A0A0A', fontSize: '18px', fontWeight: 400, marginTop: '1%' }}>Thank You. Your order has been received.</Typography>
                            </Grid>
                        </Grid>
                        <Typography style={{ color: '#177BC9', fontSize: '16px', fontWeight: 700, textAlign: 'start', marginBottom: '1%' }}>ORDER DETAILS</Typography>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {this.state.headerList.map((obj, index) => {
                                        return <TableCell style={index == 0 ?
                                            { width: '20%', textAlign: 'start' } : index == this.state.headerList.length - 1 ?
                                                { width: '25%', textAlign: 'end' } : index == this.state.headerList.length - 2 ?
                                                    { width: '35%', textAlign: 'left' } : index == 2 ?
                                                        { width: '15%', textAlign: 'left' } :
                                                        { width: '10%', textAlign: 'center' }} key={index}>{obj}</TableCell>;
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.order != undefined && !!this.state.order.length ? this.state.order.map((obj, index) => {
                                    return (
                                        <TableRow>
                                            <TableCell>
                                                <Typography style={{ color: '#0A0A0A', fontSize: '13px', textAlign: 'start' }}>{obj.OrderDate}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography style={{ color: '#0A0A0A', fontSize: '13px', textAlign: 'center' }}>{obj.OrderNo}</Typography>
                                            </TableCell>
                                            {/* <TableCell>
                                                <Typography style={{ color: '#0A0A0A', fontSize: '13px', textAlign: 'start' }}>{obj.PaymentMode}</Typography>
                                            </TableCell> */}
                                            <TableCell>
                                                {this.state.list != undefined && !!this.state.list.length ? this.state.list.map((obj, index) => {
                                                    return (
                                                        <>
                                                            <Typography style={{ color: '#0A0A0A', fontSize: '13px', fontWeight: 500, }}>{obj.Product}</Typography>
                                                            <Typography style={{ color: '#0A0A0A', fontSize: '13px', fontWeight: 400, }}>Campaign : {obj.CampaignName}</Typography>
                                                            <Typography style={{ color: '#0A0A0A', fontSize: '13px', fontWeight: 400, }}>Campaign Budget : {obj.Budget}</Typography>
                                                            <Typography style={{ color: '#0A0A0A', fontSize: '13px', fontWeight: 400, marginBottom: '10px' }}>Duration: {obj.CampaignDurationInMonths}</Typography>
                                                        </>
                                                    );
                                                })
                                                    : null}
                                            </TableCell>
                                            <TableCell>
                                                <Typography style={{ color: '#0A0A0A', fontSize: '16px', textAlign: 'end', fontWeight: 700, }}>{obj.TotalAmount}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }) : <TableRow >
                                    <TableCell colSpan={this.state.list != undefined} className="not_found">
                                        {this.state.progress ?
                                            <CircularProgress /> :
                                            <label>Not Currently Available</label>
                                        }
                                    </TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </div>
                </section>
            </>
        )
    }
}

export default OrderSuccess;