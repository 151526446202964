/*eslint-disable eqeqeq*/
import React from "react";
import { Link } from "react-router-dom";
import { Formik, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import Slider from "../../../shared/components/slider/slick-slider";
import * as AppConstants from "../../../config/AppConstant";
import * as APIController from "../../../api/APIController.js";
import "../component/login.css";
import Notification from "../../../shared/components/notification/notification";
import TextField from "../../../shared/components/TextField/TextField";
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";
import footerlogo from '../../../assets/images/footerlogo.svg'
import background from '../../../assets/images/background.png'

class SetPassword extends React.Component {
	state = {
		password: "",
		newpassword: "",
		IsLoading: false,
		requestid: "",
		SliderImages: [],
		loading: true
	};
	componentDidMount() {
		this.setState({ requestid: this.props.location.search.split('=').pop() });
		this.wsGetScreenBannerForDropdown();
	}

	wsGetScreenBannerForDropdown() {
		APIController.GetScreenBannerForDropdown("1")
			.then((response) => {
				if (response.object.message == "") {
					this.setState({ SliderImages: response.object.data, loading: false })
				}
			})
	}

	wsSetPassword(RequestID, Password) {
		this.setState({ progress: true, });
		APIController.SetPassword(RequestID, Password).then((response) => {
			this.setState({ IsLoading: false });
			if (response.error == null) {
				if (response.object.status == 1) {
					this.handleShowNotification(response.object.message);
					setTimeout(() => {
						this.props.history.push("/login");
					}, 4000);
				} else {
					this.handleShowNotification(response.object.message, true);
				}
			} else if (response.error.message) {
				this.handleShowNotification(response.error.message, true);
			}
		});
	}

	render() {
		return (
			<>
<section className='login_page'>
					<div className='main'>
						<div className='inner-screen'>
							<div style={{ background: "linear-gradient(90deg, rgb(18, 117, 188) 24%, rgb(22, 65, 111) 100%)", padding: '17px', width: "100%", }}></div>
							<div className='logo' style={{ padding: "10px 100px", marginBottom: "0px", width: "100%" }}>
								{/* <img className='App-logo' src={AppConstants.HeaderLogo} alt={AppConstants.HeaderLogo} /> */}
								<Link to='/setpassword'> <img src={AppConstants.SetPasswordLogo} alt={AppConstants.SetPasswordLogo} /> </Link>
								{/* <img src={AppConstants.VSPPremierLogo} style={{ maxWidth: "120px", marginLeft: '5%' }} /> */}
							</div>
							<div className="login-page-background" style={{ borderTop: "1px solid #3f90db21", backgroundImage: `url(${background})`, width: "100%", position: "relative", backgroundPosition: 'right', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
								<div style={{ textAlign: "center", paddingTop: "20px" }} >
									<h2 style={{ margin: "0px", color: '#16416F', fontFamily: "Barlow", }}>WELCOME TO BAUSCH + LOMB REACH</h2>
									<h5 style={{ margin: "0px", color: '#16416F', fontFamily: "Lora", fontWeight: "400" }}> A practice marketing platform with tools to help you reach patients through innovative technology</h5>
								</div>

								<div className="login-from" style={{marginBottom:"70px"}} >
									<h3 style={{ fontFamily: "Barlow",fontSize:"25px" }}>Create New Password</h3>
									<Formik
										initialValues={{ password: "", newpassword: "", }}
										validationSchema={validateLoginForm}
										validateOnMount={false}
										onSubmit={(values) => {
											if (values.password.length <= 5) {
												this.handleShowNotification("Password should be minimum 6 characters long", true);
											} else if (values.newpassword.length <= 5) {
												this.handleShowNotification("Confirm Password should be minimum 6 characters long", true);
											} else if (values.password !== values.newpassword) {
												this.handleShowNotification("Password and Confirm Password does not Match", true);
											} else {
												this.setState({ IsLoading: true });
												this.wsSetPassword(this.state.requestid, values.newpassword)
											}
										}} >
										{({ values, handleSubmit, errors, touched }) => (
											<form onSubmit={handleSubmit}>
												<div className={`form-field ${errors && touched.password && errors.password && errors.password !== "" ? "error" : ""}`}>
													<div className='field_main'>
														<Field as={TextField}
															error={touched.password && errors.password && errors.password !== "" ? true : false}
															{...this.props}
															label='Password'
															type='password'
															placeholder='******'
															name='password'
															className='pwd-field'
														/>
														{errors && touched.password && errors.password && errors.password !== "" && (
															<span className='error-msg'>
																<ErrorMessage name='password' render={(err) => { return err; }} />
															</span>)}
													</div>
												</div>
												<div className={`form-field ${errors && touched.newpassword && errors.newpassword && errors.newpassword !== "" ? "error" : ""}`}>
													<div className='field_main'>
														<Field as={TextField}
															error={touched.newpassword && errors.newpassword && errors.newpassword !== "" ? true : false}
															{...this.props}
															label='Confirm Password'
															type='password'
															placeholder='******'
															name='newpassword'
															className='pwd-field'
														/>
														{errors && touched.newpassword && errors.newpassword && errors.newpassword !== "" && (
															<span className='error-msg'>
																<ErrorMessage name='newpassword' render={(err) => { return err; }} />
															</span>)}
													</div>
												</div>
												<div className={this.state.IsLoading ? 'button-field disable-btn' : 'button-field'}>
													<input type='submit' name='Create' value={this.state.IsLoading ? "Creating..." : "Create"} />
												</div>
											</form>
										)}
									</Formik>

									
								</div>

							</div>


							<div style={{ background: "linear-gradient(90deg, rgb(18, 117, 188) 24%, rgb(22, 65, 111) 100%)", display: "flex", justifyContent: 'space-between', alignItems: 'center', padding: "9px 30px", backgroundColor: '#16416F', width: "100%" }}>
								<div style={{width:"50%",textAlign:"center"}}>
									<p style={{ margin: '0px', color: "#e5e1e1", fontSize: "10px", paddingLeft: "20px", fontFamily: "Lora", }}>@{new Date().getFullYear()} Bausch+Lomb.VCCM.0033.USA.23</p>
								</div>
								<div style={{ width: '250px' }}>
								<img src={footerlogo} alt='' width="70%" />
								</div>
							</div>
						</div>
					</div>

				</section>



				{/* <section className='login_page'>
					<div className='main'>
						<div className='inner-screen'>
							<div className='left-column'>
								<div className='logo'>
									<Link to='/setpassword'> <img src={AppConstants.SetPasswordLogo} alt={AppConstants.SetPasswordLogo} /> </Link>
								</div>
								<div className='middle-content'>
									<h1>Create New Password</h1>
									<Formik
										initialValues={{ password: "", newpassword: "", }}
										validationSchema={validateLoginForm}
										validateOnMount={false}
										onSubmit={(values) => {
											if (values.password.length <= 5) {
												this.handleShowNotification("Password should be minimum 6 characters long", true);
											} else if (values.newpassword.length <= 5) {
												this.handleShowNotification("Confirm Password should be minimum 6 characters long", true);
											} else if (values.password !== values.newpassword) {
												this.handleShowNotification("Password and Confirm Password does not Match", true);
											} else {
												this.setState({ IsLoading: true });
												this.wsSetPassword(this.state.requestid, values.newpassword)
											}
										}} >
										{({ values, handleSubmit, errors, touched }) => (
											<form onSubmit={handleSubmit}>
												<div className={`form-field ${errors && touched.password && errors.password && errors.password !== "" ? "error" : ""}`}>
													<div className='field_main'>
														<Field as={TextField}
															error={touched.password && errors.password && errors.password !== "" ? true : false}
															{...this.props}
															label='Password'
															type='password'
															placeholder='******'
															name='password'
															className='pwd-field'
														/>
														{errors && touched.password && errors.password && errors.password !== "" && (
															<span className='error-msg'>
																<ErrorMessage name='password' render={(err) => { return err; }} />
															</span>)}
													</div>
												</div>
												<div className={`form-field ${errors && touched.newpassword && errors.newpassword && errors.newpassword !== "" ? "error" : ""}`}>
													<div className='field_main'>
														<Field as={TextField}
															error={touched.newpassword && errors.newpassword && errors.newpassword !== "" ? true : false}
															{...this.props}
															label='Confirm Password'
															type='password'
															placeholder='******'
															name='newpassword'
															className='pwd-field'
														/>
														{errors && touched.newpassword && errors.newpassword && errors.newpassword !== "" && (
															<span className='error-msg'>
																<ErrorMessage name='newpassword' render={(err) => { return err; }} />
															</span>)}
													</div>
												</div>
												<div className={this.state.IsLoading ? 'button-field disable-btn' : 'button-field'}>
													<input type='submit' name='Create' value={this.state.IsLoading ? "Creating..." : "Create"} />
												</div>
											</form>
										)}
									</Formik>
								</div>
							</div>
							<div className='right-column' style={{ padding: '0px', margin: '0px', position: 'relative' }}>
								{this.state.loading ? <CircularProgress /> :
									<div className='image-slider'>
										<Slider>
											{this.state.SliderImages.length && this.state.SliderImages.map((object, index) => {
												return (<div className='image-slider'>
													<img src={object.ImageURL} alt={object.ImageURL} />
												</div>)
											})}
										</Slider>
									</div>}
							</div>
						</div>
					</div>
				</section> */}
				<Notification
					isError={this.state.isError}
					message={this.state.message}
					showNotification={this.state.showNotification}
					MessageLocationLogin={"LoginToast"}
					closeNotification={this.handleClearNotification}
				/>
			</>
		);
	}

	handleShowNotification = (message, isError) => {
		this.setState({
			message: message,
			showNotification: true,
			isError: isError,
		});

		setTimeout(() => {
			this.handleClearNotification();
		}, 4000);
	};
	handleClearNotification = () => {
		this.setState({
			message: "",
			showNotification: false,
			isError: false,
		});
	};
}

const validateLoginForm = yup.object().shape({
	password: yup.string().required("Password is required"),
	newpassword: yup.string().required("Confirm Password is required"),
});

export default SetPassword;